import React from 'react';
import CustomTable from '../../../components/table-components/table';

function InvoiceTable({ items = [], setItems, ownerPasscodeVerified = false, originalPrices = {} }) {

  const columns = [
    { title: 'Order ID', renderValue: (item) => item.orderId || '-' },
    { title: 'Product', renderValue: (item) => item.productName || '-' },
    { title: 'Qty', renderValue: (item) => item.quantity || '-' },
    { 
      title: 'Price',
      renderValue: (item) => (
        <div className="flex flex-col">
          <div>
            {`$${item.price.toFixed(2)}`}
            {ownerPasscodeVerified && originalPrices[item.id] && (
              <span className="ml-2 text-red-500">
                (${originalPrices[item.id].toFixed(2)})
              </span>
            )}
          </div>
          {ownerPasscodeVerified && item.price === 0 && (
            <div className="text-xs text-gray-500 flex items-center gap-1">
              <span className="bg-hazel-green text-white px-1 rounded">Owner</span>
              <span>Released from payment</span>
            </div>
          )}
        </div>
      )
    },
    { 
      title: 'Total',
      renderValue: (item) => (
        <div className="flex flex-col">
          <div>
            {`$${(item.price * item.quantity).toFixed(2)}`}
            {ownerPasscodeVerified && originalPrices[item.id] && (
              <span className="ml-2 text-red-500">
                (${(originalPrices[item.id] * item.quantity).toFixed(2)})
              </span>
            )}
          </div>
          {ownerPasscodeVerified && item.price === 0 && (
            <div className="text-xs italic text-gray-500">
              Approved by owner
            </div>
          )}
        </div>
      )
    },
  ];

  const handleSearchTextChange = (text) => {
    console.log('Search text changed:', text);
  };

  return (
    <div className='bg-white shadow-md rounded-lg overflow-hidden'>
      <CustomTable
        rows={items}
        columns={columns}
        loading={false}
        showMoreActions={false}
        setSearchText={handleSearchTextChange}
      />
    </div>
  );
}

export default InvoiceTable;

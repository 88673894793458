import api from './api';

export const createRoomOrder = (orderData) =>
  api.post('/api/room-orders', orderData);

export const updateOrderStatus = (orderId, status, staffId) =>
  api.patch(`/api/room-orders/${orderId}/status`, { status, staffId });

export const getRoomOrders = (roomNumber) =>
  api.get(`/api/room-orders/room/${roomNumber}`);

export const getRoomOrderById = (roomOrderId) => 
  api.get(`/api/room-orders/${roomOrderId}`);
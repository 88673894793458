import { useState } from 'react';
import { 
  ChevronLeft, 
  ChevronRight, 
  Edit, 
  Printer, 
  MoreHorizontal,
  Calendar,
  Clock
} from 'lucide-react';
import { Button } from '../../../../../components/shadCN/Button.tsx';
import React from 'react';
const months = [
  'JAN 2024', 'FEB 2024', 'MAR 2024', 'APR 2024', 'MAY 2024', 'JUN 2024',
  'JUL 2024', 'AUG 2024', 'SEP 2024', 'OCT 2024', 'NOV 2024', 'DEC 2024'
];

interface BudgetRow {
  id: string;
  name: string;
  type: 'header' | 'item' | 'total';
  values: number[];
  indentation?: number;
  isExpanded?: boolean;
}

const budgetData: BudgetRow[] = [
  { id: '1', name: 'Profit and Loss', type: 'header', values: Array(12).fill(0) },
  { id: '2', name: 'Income', type: 'header', values: Array(12).fill(0) },
  { id: '3', name: 'General Income', type: 'item', values: Array(12).fill(2500), indentation: 1 },
  { id: '4', name: 'Total for Income', type: 'total', values: Array(12).fill(2500) },
  { id: '5', name: 'Other Income', type: 'header', values: Array(12).fill(0) },
  { id: '6', name: 'Interest Income', type: 'item', values: Array(12).fill(150), indentation: 1 },
  { id: '7', name: 'Total for Other Income', type: 'total', values: Array(12).fill(150) },
  { id: '8', name: 'Total Income', type: 'total', values: Array(12).fill(2650) },
  { id: '9', name: 'Expenses', type: 'header', values: Array(12).fill(0) },
  { id: '10', name: 'Operating Expenses', type: 'item', values: Array(12).fill(1800), indentation: 1 },
  { id: '11', name: 'Total Expenses', type: 'total', values: Array(12).fill(1800) },
  { id: '12', name: 'Net Profit/Loss', type: 'total', values: Array(12).fill(850) }
];

export default function BudgetDetails() {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;
  const visibleMonths = months.slice(currentPage, currentPage + itemsPerPage);

  return (
    <div className="min-h-screen bg-seashell p-8">
      <div className="mb-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold text-hazel-green">Annual Budget 2024</h1>
          <div className="flex items-center gap-3">
            <Button variant="outline" size="sm" className="text-bluish-grey">
              <Edit className="h-4 w-4 mr-2" />
              Edit
            </Button>
            <Button variant="outline" size="sm" className="text-bluish-grey">
              <Printer className="h-4 w-4 mr-2" />
              Print
            </Button>
            <Button className="bg-hazel-green hover:bg-sage text-white">
              View Budget vs Actuals
            </Button>
            <Button variant="ghost" size="sm">
              <MoreHorizontal className="h-4 w-4 text-bluish-grey" />
            </Button>
          </div>
        </div>

        <div className="flex items-center gap-6 text-bluish-grey">
          <div className="flex items-center gap-2">
            <Calendar className="h-4 w-4" />
            <span>Jan 2024 - Dec 2024</span>
          </div>
          <div className="flex items-center gap-2">
            <Clock className="h-4 w-4" />
            <span>Monthly</span>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm border border-mercury overflow-hidden">
        <div className="flex justify-between items-center p-4 border-b border-mercury">
          <div className="w-64"></div>
          <div className="flex-1 flex items-center justify-between px-8">
            <button
              onClick={() => setCurrentPage(Math.max(0, currentPage - 1))}
              disabled={currentPage === 0}
              className="text-bluish-grey disabled:opacity-50"
            >
              <ChevronLeft className="h-4 w-4" />
            </button>
            <div className="flex gap-4">
              {visibleMonths.map((month) => (
                <div key={month} className="w-32 text-center font-medium text-bluish-grey">
                  {month}
                </div>
              ))}
            </div>
            <button
              onClick={() => setCurrentPage(Math.min(months.length - itemsPerPage, currentPage + 1))}
              disabled={currentPage >= months.length - itemsPerPage}
              className="text-bluish-grey disabled:opacity-50"
            >
              <ChevronRight className="h-4 w-4" />
            </button>
          </div>
        </div>

        <div className="divide-y divide-mercury">
          {budgetData.map((row) => (
            <div
              key={row.id}
              className={`flex items-center p-4 ${
                row.type === 'header' ? 'bg-tealish-blue' : ''
              }`}
            >
              <div
                className={`w-64 ${
                  row.type === 'header'
                    ? 'font-medium text-hazel-green'
                    : 'text-bluish-grey'
                }`}
                style={{ paddingLeft: `${(row.indentation || 0) * 1.5}rem` }}
              >
                {row.name}
              </div>
              <div className="flex-1 grid grid-cols-4 gap-4">
                {row.values.slice(currentPage, currentPage + itemsPerPage).map((value, index) => (
                  <div
                    key={index}
                    className={`w-32 text-right ${
                      row.type === 'total' ? 'font-medium' : ''
                    } text-bluish-grey`}
                  >
                    €{value.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IconFileDollar } from '@tabler/icons-react';
import Loading from '../../components/loader';
import PageTitle from '../../components/page-title';
import InvoiceForm from './InvoiceForm';
import { getInvoice as getInvoiceApi } from '../../api/invoices';

function EditInvoice() {
  const params = useParams();
  const invoiceId = params.id;
  const [invoice, setInvoice] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getInvoice();
  }, []);

  const getInvoice = async () => {
    try {
      const response = await getInvoiceApi(invoiceId);
      setInvoice(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconFileDollar className='text-hazel-green mr-2' size={22} />}
        title='Edit Invoice'
      />
      <InvoiceForm isEdit invoice={invoice} />
    </div>
  );
}

export default EditInvoice;

// import React from "react";
// import { socialIcons } from "../../../components/data/Data";
// import { Link } from "react-router-dom";

// function SocialIcons() {
//   return (
//     <>
//       <div className="col-lg-3 px-5">
//         <div className="d-inline-flex align-items-center py-2">
//           {socialIcons.map((val, index) => (
//             <div key={index}>
//               <Link className="me-3" href="">
//                 {val.icon}
//               </Link>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// }

// export default SocialIcons;

import React from "react";
import { socialIcons } from "../../../components/data/Data";
import { Link } from "react-router-dom";

function SocialIcons() {
  return (
    // <div className="col-lg-3 px-5">
    //   <div className="d-inline-flex align-items-center py-2">
    //     {socialIcons.map((val, index) => (
    //       <Link key={index} to="#" className="me-3">
    //         {val.icon}
    //       </Link>
    //     ))}
    //   </div>
    // </div>

    <div className="lg:px-20 px-5">
    <div className="flex items-center py-2 space-x-3">
      {socialIcons.map((val, index) => (
        <Link key={index} to="#" className="text-gray-600 hover:text-gray-800">
          {val.icon}
        </Link>
      ))}
    </div>
  </div>

  );
}

export default SocialIcons;

import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { inviteUserToGroup } from '../../api/chat';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';

const UserInvitationForm = ({ groupId, onUserInvited, onCancel }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Username is required*'),
  });

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
      <Formik
        initialValues={{ name: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            await inviteUserToGroup(groupId, values.name);
            onUserInvited(); // Notify parent component of the successful invitation
            setSubmitting(false);
          } catch (err) {
            setErrors({ submit: err?.response?.data?.message || 'Error inviting user' });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit} className='px-7 py-5'>
            <div className='grid gap-y-3'>
              <CustomInput
                type='text'
                name='name'
                label='Name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.name && touched.name}
                errorMessage={errors.name}
              />
              {errors.submit && <p className='text-red-500'>{errors.submit}</p>}
            </div>
            <div className='flex justify-end items-center w-full mt-4 text-sm'>
              <CancelButton onCancel={onCancel} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/2 md:w-1/6'
                title='Invite User'
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UserInvitationForm;

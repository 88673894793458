import api from './api';

// Add a new stock item
export const addStockItem = (data) => api.post('/api/stock', data);

export const updateStockItem = (id, data) => api.put(`/api/stock/${id}`, data); // New endpoint for updating a stock item

// Update stock quantity
export const updateStockQuantity = (id, quantity) => api.put(`/api/stock/${id}/quantity`, { quantity });

// Get all stock items
export const getStockItems = () => api.get('/api/stock');

// Get stock item by ID
export const getStockItemById = (id) => api.get(`/api/stock/${id}`);

// Delete stock item
export const deleteStockItem = (id) => api.delete(`/api/stock/${id}`);

// Create a purchase order
export const createPurchaseOrder = (data) => api.post('/api/stock/purchase-order', data);

// Receive stock for a purchase order
export const receiveStock = (id, receivedItems) => api.post(`/api/stock/purchase-order/${id}/receive`, { receivedItems });

// Adjust stock levels
export const adjustStock = (id, adjustmentQuantity, reason) => api.post(`/api/stock/${id}/adjust`, { adjustmentQuantity, reason });

// Check for low stock items
export const checkLowStock = () => api.get('/api/stock/low-stock');






//API for low stock advanced and imprived functinality :

// Get stock items below a certain quantity
export const getStockItemsBelowQuantity = (threshold) => api.get(`/api/stock/below-quantity/${threshold}`);

// Get stock item quantity by ID
export const getStockItemQuantityById = (id) => api.get(`/api/stock/quantity/${id}`);

// Get stock items approaching reorder level
export const checkZeroOrApproachingStock = (threshold) => api.get(`/api/stock/zero-stock-or-approaching/${threshold}`);

// Notify when stock is zero
export const checkZeroStock = () => api.get('/api/stock/zero-stock');





import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Loading from '../../components/loader';
import { AddButton } from '../../components/buttons';
import CustomTable from '../../components/table-components/table';
import { getDishOrders as getOrders, deleteDishOrder as deleteOrder, completeDishOrder as completeDishOrder } from '../../api/orders';

function OrderList() {
  const [orderList, setOrderList] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    getOrderList();
  }, []);

  const getOrderList = useCallback(async () => {
    try {
      const response = await getOrders(rowsPerPage, currentPage + 1, searchText);
      setOrderList(response.data);
      setTotalCount(response.data.length);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, searchText]);

  useEffect(() => {
    getOrderList();
  }, [getOrderList]);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await deleteOrder(id);
      setOrderList((prevState) => [
        ...prevState.filter((item) => item.id !== id)
      ]);
      setTotalCount(totalCount - 1);
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  // const handleComplete = async (id) => {
  //   try {
  //     await completeOrder(id);
  //     navigate(`/orders/complete-order/${id}`);
  //   } catch (err) {
  //     console.error('Error completing order:', err);
  //   }
  // };
  const handleComplete = (id, totalPrice) => {
    navigate(`/orders/complete-order/${id}`, { state: { totalPrice } });
  };

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className='p-10'>
      <AddButton
        entity='orders'
        link='/orders/add-order'
        title='New Order'
      />
      <CustomTable
        rows={orderList}
        columns={[
          {
            title: 'Table ID',
            renderValue: (item) => item.tableId
          },
          {
            title: 'Dish Section ID',
            renderValue: (item) => item.dishSectionId
          },
          {
            title: 'Quantity',
            renderValue: (item) => item.quantity
          },
          {
            title: 'Total Price',
            renderValue: (item) => item.totalPrice
          },
          {
            title: 'Status',
            renderValue: (item) => (
              <span
                className={`px-3 py-1 rounded-lg ${
                  item.status === 'paid' ? 'bg-teal-300 text-white' : 'bg-red-300 text-white'
                }`}
              >
                {item.status}
              </span>
            )
          },
        //   {
        //     title: 'Status',
        //     renderValue: (item) => item.status
        //   },
          {
            title: 'Created At',
            renderValue: (item) => dayjs(item.createdAt).format('DD/MM/YYYY')
          },
          {
            title: 'Updated At',
            renderValue: (item) => dayjs(item.updatedAt).format('DD/MM/YYYY')
          },
          {
            title: 'Finish Order',
            renderValue: (item) => (
              <button
                onClick={() => handleComplete(item.id)}
                className='whitespace-nowrap rounded-md bg-sage transition-all duration-200 ease-out py-2 px-4 text-sm text-white shadow-sm hover:bg-hazel-green'
              >
                Complete Order
              </button>
            )
          },
        ]}
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={true}
        actionsType='dots'
        editLink='/orders'
        onDelete={handleDelete}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </div>
  );
}

export default OrderList;

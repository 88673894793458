import React from "react";
import { socialIcons, team } from "../../../components/data/Data";
import { Link } from "react-router-dom";
import CommonHeading from "../common/CommonHeading";

function Teams() {
 

  return (
    <>
      <div className="container mx-auto py-5">
        <div className="max-w-7xl mx-auto">
          <CommonHeading
            heading="Our Team"
            subtitle="Explore Our"
            title="Staffs"
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {team.map((item, index) => (
              <div
                className="relative group transition-transform duration-300 ease-in-out transform hover:scale-105"
                key={index}
              >
                <div className="rounded-lg shadow-lg overflow-hidden">
                  <div className="relative">
                    <img className="w-full h-auto object-cover" src={item.image} alt="img" />
                    <div className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      {socialIcons.slice(0, 3).map((val, index) => (
                        <a
                          className="bg-[#FEA116] text-white rounded-full p-2 mx-1 flex items-center justify-center"
                          href=""
                          key={index}
                        >
                          {val.icon}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="text-center p-4 mt-3">
                    <h5 className="font-bold mb-0">{item.name}</h5>
                    <small>{item.designation}</small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
  
}

export default Teams;
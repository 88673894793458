import { Search } from 'lucide-react';

import React from 'react';
import { TransactionAccordion } from './TransactionAccordion.tsx';

const invoicesData = [
  {
    id: 'INV-000001',
    date: '27 Oct 2024',
    reference: 'Project A',
    orderNumber: '1',
    amount: '€1,200.00',
    balanceDue: '€0.00',
    status: 'Draft'
  },
  {
    id: 'INV-000002',
    date: '26 Oct 2024',
    reference: 'Project B',
    orderNumber: '2',
    amount: '€2,500.00',
    balanceDue: '€2,500.00',
    status: 'Pending'
  }
];

const quotesData = [
  {
    id: 'QT-000001',
    date: '25 Oct 2024',
    reference: 'Service Package A',
    amount: '€3,000.00',
    status: 'Accepted'
  },
  {
    id: 'QT-000002',
    date: '24 Oct 2024',
    reference: 'Service Package B',
    amount: '€1,800.00',
    status: 'Pending'
  }
];

const billsData = [
  {
    id: 'BILL-000001',
    date: '23 Oct 2024',
    reference: 'Office Supplies',
    amount: '€500.00',
    balanceDue: '€500.00',
    status: 'Pending'
  }
];

const expensesData = [
  {
    id: 'EXP-000001',
    date: '22 Oct 2024',
    reference: 'Travel Expenses',
    amount: '€350.00',
    status: 'Paid'
  }
];

export const TransactionsTab = () => {
  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <div className="relative">
          <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" />
          <input
            type="text"
            placeholder="Search transactions..."
            className="pl-10 pr-4 py-2 border border-mercury rounded-lg text-sm focus:outline-none focus:border-hazel-green"
          />
        </div>
      </div>

      <TransactionAccordion
        title="Invoices"
        data={invoicesData}
        columns={['DATE', 'INVOICE NUMBER', 'ORDER NUMBER', 'REFERENCE', 'AMOUNT', 'STATUS']}
        showOrderNumber
        showBalanceDue
      />

      <TransactionAccordion
        title="Quotes"
        data={quotesData}
        columns={['DATE', 'QUOTE#', 'REFERENCE', 'AMOUNT', 'STATUS']}
      />

      <TransactionAccordion
        title="Bills"
        data={billsData}
        columns={['DATE', 'BILL#', 'REFERENCE', 'AMOUNT', 'BALANCE DUE', 'STATUS']}
        showBalanceDue
      />

      <TransactionAccordion
        title="Expenses"
        data={expensesData}
        columns={['DATE', 'EXPENSE#', 'REFERENCE', 'AMOUNT', 'STATUS']}
      />
    </div>
  );
};
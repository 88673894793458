export const entities = [
  { id: 'users', title: 'Users' },
  { id: 'roles', title: 'Roles' },
  { id: 'room_types', title: 'Room Types' },
  { id: 'rooms', title: 'Rooms' },
  { id: 'invoices', title: 'Invoices' },
  { id: 'products', title: 'Products' },
  { id: 'indoor_pools', title: 'Indoor Pools' },
  { id: 'outdoor_pools', title: 'Outdoor Pools' },
  {id: 'waiter', title: "Waiter"}
];

export const roomStatus = ['RESERVED', 'VACANT', 'DIRTY'];

export const invoiceTypes = ['Purchase Invoice', 'Payment Invoice'];

export const units = ['T', 'KG', 'G', 'L', 'ML'];

export const productTypes = ['DRINK', 'FOOD', 'OTHER'];

export const paymentStatus = ['UNPAID', 'HALF PAID', 'PAID'];

export const paymentMethods = ['CASH']; //'BANK'

export const titleTypes = ['MR', 'MRS', 'MISS'];

export const reservationStatus = [
  'CONFIRMED',
  'CHECKIN',
  'CHECKOUT',
  'CANCELLED'
];

export const dishTypes = [
  'Supat',
  'Mengjesi',
  'Parahaja e ngrohte',
  'Parahaja e ftohte',
  'Sallata',
  'Picat',
  'Pastat',
  'Rizoto',
  'Specialitete nga skara',
  'Specialitete nga furra',
  'Specialitete nga friteza',
  'Specialitete nga deti',
  'Embelsirat'
];

export const restaurants = ['Restaurant 1', 'Restaurant 2'];

export const pools = {
  outdoor: {
    women: 10,
    men: 12,
    kids: 8
  },
  indoor: {
    women: 15,
    men: 18,
    kids: 12
  }
};

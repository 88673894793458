import React from 'react';
import { MessageSquare } from 'lucide-react';

interface NotesProps {
  value: string;
  onChange: (value: string) => void;
}

export function Notes({ value, onChange }: NotesProps) {
  return (
    <div className="bg-seashell rounded-lg p-6 space-y-4">
      <h2 className="text-lg font-semibold text-bluish-grey flex items-center gap-2">
        <MessageSquare size={20} className="text-hazel-green" />
        Notes
      </h2>
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        rows={4}
        className="w-full p-3 border border-grey-goose rounded-lg focus:outline-none focus:border-sage bg-white text-bluish-grey resize-none transition-colors hover:border-sage"
        placeholder="Add any notes or additional information..."
      />
    </div>
  );
}
import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import store from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/global.css';
import ThemeProvider from './utils/ThemeContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { StateMachineProvider, createStore } from 'little-state-machine';

createStore({
  calculator: {
    firstValue: '',
    secondValue: '',
    result: '',
  },
  theme: {
    type: 1,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      
      <ThemeProvider>
        <DndProvider backend={HTML5Backend}>
          <StateMachineProvider>
            
            <App />

          </StateMachineProvider>
        </DndProvider>
      </ThemeProvider>

    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React , { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import DishSidebar from '../../components/DishSidebar';
import RestaurantBarOrderForm from './RestaurantBarOrderForm';

function AddRestaurantBarOrderForm() {

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [selectedBarItems, setSelectedBarItems] = useState([]);

  const handleBarItemDrop = (item) => {
    setSelectedBarItems((prev) => [...prev, item]);
    setSidebarVisible(false);
  };

  return (
    <div className='px-10 py-2'>
      <PageTitle
        
        icon={
          <FontAwesomeIcon
            icon={faBuilding}
            className='text-hazel-green mr-2'
          />
        }
        title='Create a new order'
      />
      <RestaurantBarOrderForm
            isEdit={false}
            // barOrder={barOrder}
            selectedBarItems={selectedBarItems}
            setSelectedBarItems={setSelectedBarItems}
            handleBarItemDrop={handleBarItemDrop}
      />
    </div>
  );
}

export default AddRestaurantBarOrderForm;

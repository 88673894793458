// import React, { useState, useEffect , useRef} from 'react';
// import { useParams } from 'react-router-dom';
// import CustomTable from '../../components/table-components/table';
// import { PrintButton } from './PrintButton';
// import { getPoolReservations } from '../../api/pools';
// import {createPoolReservation} from '../../api/pools';

// import ReactToPrint from 'react-to-print';

// function PoolHistory() {


//   // const createReservation = async (poolId, ticketType, price) => {
//   //   try {
//   //     const response = await fetch(`/api/pools/${poolId}/reservations`, {
//   //       method: 'POST',
//   //       headers: {
//   //         'Content-Type': 'application/json',
//   //       },
//   //       body: JSON.stringify({ ticketType, price }),
//   //     });
  
//   //     if (!response.ok) {
//   //       throw new Error('Failed to create reservation');
//   //     }
  
//   //     console.log('Reservation created successfully');
//   //   } catch (error) {
//   //     console.error('Error creating reservation:', error);
//   //   }
//   // };

//   const printRef = useRef();


//   const params = useParams();
//   const poolId = params.id;
//   const [poolReservations, setPoolReservations] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [currentPage, setCurrentPage] = useState(0);
//   const [searchText, setSearchText] = useState('');
//   const [totalCount, setTotalCount] = useState(null);

//   useEffect(() => {
//     async function fetchReservations() {
//       setLoading(true);
//       try {
//         const reservations = await getPoolReservations(poolId);
//         const groupedReservations = groupByDateAndType(reservations.data);
//         setPoolReservations(groupedReservations);
//         setTotalCount(groupedReservations.length);
//       } catch (error) {
//         console.error('Failed to fetch pool reservations:', error);
//       } finally {
//         setLoading(false);
//       }
//     }

//     fetchReservations();
//   }, [poolId]);

//   // const groupByDateAndType = (data) => {
//   //   const groupedData = data.reduce((acc, reservation) => {
//   //     const date = new Date(reservation.createdAt).toLocaleDateString();
//   //     if (!acc[date]) {
//   //       acc[date] = { date, woman: 0, man: 0, kid: 0 };
//   //     }
//   //     if (reservation.ticketType === 'WOMAN') {
//   //       acc[date].woman += 1;
//   //     } else if (reservation.ticketType === 'MAN') {
//   //       acc[date].man += 1;
//   //     } else if (reservation.ticketType === 'KID') {
//   //       acc[date].kid += 1;
//   //     }
//   //     return acc;
//   //   }, {});

//   //   return Object.values(groupedData);
//   // };


//   const groupByDateAndType = (data) => {
//     const groupedData = data.reduce((acc, reservation) => {
//       const date = new Date(reservation.createdAt).toLocaleDateString();
//       console.log(`Processing reservation on ${date}:`, reservation);
//       console.log("Reservation ticket price", reservation.price)


//       // if (!reservation.hasOwnProperty('ticketPrice') || reservation.ticketPrice === null || isNaN(parseFloat(reservation.ticketPrice))) {
//       //   console.warn(`Invalid or missing ticketPrice for reservation on ${date}:`, reservation);
//       //   return acc; // Skip this reservation if ticketPrice is not valid
//       // }
  
      
//       if (!acc[date]) {
//         acc[date] = { date, woman: { count: 0, totalPrice: 0 }, man: { count: 0, totalPrice: 0 }, kid: { count: 0, totalPrice: 0 } };
//       }
      
//       // Parse ticketPrice as float
//       const ticketPrice = parseFloat(reservation.price);

//       if (reservation.ticketType === 'WOMAN') {
//         console.log(reservation.price, "---WOMAN")

//         acc[date].woman.count += 1;
//         acc[date].woman.totalPrice += ticketPrice;
//         console.log(`Current totalPrice for WOMAN on ${date}:`, acc[date].woman.totalPrice);
//       } else if (reservation.ticketType === 'MAN') {
//         console.log(reservation.price, "---MAN")

//         acc[date].man.count += 1;
//         acc[date].man.totalPrice += ticketPrice;
//         console.log(`Current totalPrice for MAN on ${date}:`, acc[date].man.totalPrice);
//       } else if (reservation.ticketType === 'KID') {
//         console.log(reservation.price, "---KID")

//         acc[date].kid.count += 1;
//         acc[date].kid.totalPrice += ticketPrice;
//         console.log(`Current totalPrice for KID on ${date}:`, acc[date].kid.totalPrice);
//       }
//       return acc;
//     }, {});
  
//     console.log('Final groupedData:', groupedData);
//     return Object.values(groupedData);
//   };
  
  



//   const createReservation = async (poolId, ticketType, price) => {
//     try {
//       const response = await fetch(`/api/pools/${poolId}/reservations`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ ticketType, price }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to create reservation');
//       }

//       console.log('Reservation created successfully');
//     } catch (error) {
//       console.error('Error creating reservation:', error);
//     }
//   };


//   return (
//     // <div className='p-10'>
//     //   <CustomTable
//     //     rows={poolReservations}
//     //     columns={[
//     //       {
//     //         title: 'Date',
//     //         renderValue: (item) => item.date
//     //       },
//     //       {
//     //         title: 'Women',
//     //         renderValue: (item) => item.woman
//     //       },
//     //       {
//     //         title: 'Men',
//     //         renderValue: (item) => item.man
//     //       },
//     //       {
//     //         title: 'Kids',
//     //         renderValue: (item) => item.kid
//     //       }
//     //     ]}
//     //     setSearchText={setSearchText}
//     //     loading={loading}
//     //     showMoreActions={false}
//     //     currentPage={currentPage}
//     //     setCurrentPage={setCurrentPage}
//     //     rowsPerPage={rowsPerPage}
//     //     setRowsPerPage={setRowsPerPage}
//     //     totalCount={totalCount}
//     //   />
//     // </div>


//     <div className='p-10'>
//       <CustomTable
//         rows={poolReservations}
//         columns={[
//           {
//             title: 'Date',
//             renderValue: (item) => item.date
//           },
//           {
//             title: 'Women',
//             renderValue: (item) => 
//                 <span className="flex flex-row space-x-3 text-teal-600 font-bold">

//                   <span className='font-normal text-black'>
//                     {item.woman.count}
//                   </span>
//                   <span>
//                    ({item.woman.totalPrice}€)
//                    </span>

//                 </span>
//           },
//           {
//             title: 'Men',
//             renderValue: (item) => 
//                   <span className="flex flex-row space-x-3 text-teal-600 font-bold">
//                     <span className='font-normal text-black'>
//                       {item.man.count}
//                     </span>
//                     <span>
//                     ({item.man.totalPrice}€)
//                     </span>
//                     </span>
//           },
//           {
//             title: 'Kids',
//             renderValue: (item) => 
//                   <span className="flex flex-row space-x-3 text-teal-600 font-bold">
//                     <span className='font-normal text-black'>
//                       {item.kid.count}
//                     </span>
//                     <span>
//                     ({item.kid.totalPrice}€)
//                     </span>
//                   </span>
//           },

//           {
//             title: 'Actions',
//             renderValue: (item) => (
//               // <PrintButton item={item} small={false} gray={false} createReservation={createReservation}>Print</PrintButton>
//               <PrintButton item={{ ...item, poolId, ticketType: 'MAN', price: item.man.totalPrice }} createReservation={createReservation}>Print
// </PrintButton>
//             )          }

//         ]}
//         setSearchText={setSearchText}
//         loading={loading}
//         showMoreActions={false}
//         currentPage={currentPage}
//         setCurrentPage={setCurrentPage}
//         rowsPerPage={rowsPerPage}
//         setRowsPerPage={setRowsPerPage}
//         totalCount={totalCount}
//       />

//     </div>
//   );
// }

// export default PoolHistory;


import React, { useState, useEffect , useRef} from 'react';
import { useParams } from 'react-router-dom';
import CustomTable from '../../components/table-components/table';
import { PrintButton } from './PrintButton';
import { getPoolReservations } from '../../api/pools';
import {createPoolReservation} from '../../api/test';

import ReactToPrint from 'react-to-print';

function PoolHistory() {


  // const createReservation = async (poolId, ticketType, price) => {
  //   try {
  //     const response = await fetch(`/api/pools/${poolId}/reservations`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ ticketType, price }),
  //     });
  
  //     if (!response.ok) {
  //       throw new Error('Failed to create reservation');
  //     }
  
  //     console.log('Reservation created successfully');
  //   } catch (error) {
  //     console.error('Error creating reservation:', error);
  //   }
  // };

  const printRef = useRef();


  const params = useParams();
  const poolId = params.id;
  const [poolReservations, setPoolReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);

  useEffect(() => {
    async function fetchReservations() {
      setLoading(true);
      try {
        const reservations = await getPoolReservations(poolId);
        const groupedReservations = groupByDateAndType(reservations.data);
        setPoolReservations(groupedReservations);
        setTotalCount(groupedReservations.length);
      } catch (error) {
        console.error('Failed to fetch pool reservations:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchReservations();
  }, [poolId]);

  // const groupByDateAndType = (data) => {
  //   const groupedData = data.reduce((acc, reservation) => {
  //     const date = new Date(reservation.createdAt).toLocaleDateString();
  //     if (!acc[date]) {
  //       acc[date] = { date, woman: 0, man: 0, kid: 0 };
  //     }
  //     if (reservation.ticketType === 'WOMAN') {
  //       acc[date].woman += 1;
  //     } else if (reservation.ticketType === 'MAN') {
  //       acc[date].man += 1;
  //     } else if (reservation.ticketType === 'KID') {
  //       acc[date].kid += 1;
  //     }
  //     return acc;
  //   }, {});

  //   return Object.values(groupedData);
  // };


  const groupByDateAndType = (data) => {
    const groupedData = data.reduce((acc, reservation) => {
      const date = new Date(reservation.createdAt).toLocaleDateString();
      console.log(`Processing reservation on ${date}:`, reservation);
      console.log("Reservation ticket price", reservation.price)


      // if (!reservation.hasOwnProperty('ticketPrice') || reservation.ticketPrice === null || isNaN(parseFloat(reservation.ticketPrice))) {
      //   console.warn(`Invalid or missing ticketPrice for reservation on ${date}:`, reservation);
      //   return acc; // Skip this reservation if ticketPrice is not valid
      // }
  
      
      if (!acc[date]) {
        acc[date] = { date, woman: { count: 0, totalPrice: 0 }, man: { count: 0, totalPrice: 0 }, kid: { count: 0, totalPrice: 0 } };
      }
      
      // Parse ticketPrice as float
      const ticketPrice = parseFloat(reservation.price);

      if (reservation.ticketType === 'WOMAN') {
        console.log(reservation.price, "---WOMAN")

        acc[date].woman.count += 1;
        acc[date].woman.totalPrice += ticketPrice;
        console.log(`Current totalPrice for WOMAN on ${date}:`, acc[date].woman.totalPrice);
      } else if (reservation.ticketType === 'MAN') {
        console.log(reservation.price, "---MAN")

        acc[date].man.count += 1;
        acc[date].man.totalPrice += ticketPrice;
        console.log(`Current totalPrice for MAN on ${date}:`, acc[date].man.totalPrice);
      } else if (reservation.ticketType === 'KID') {
        console.log(reservation.price, "---KID")

        acc[date].kid.count += 1;
        acc[date].kid.totalPrice += ticketPrice;
        console.log(`Current totalPrice for KID on ${date}:`, acc[date].kid.totalPrice);
      }
      return acc;
    }, {});
  
    console.log('Final groupedData:', groupedData);
    return Object.values(groupedData);
  };
  
  



  const createReservation = async (poolId, ticketType, price) => {
    try {
      const response = await fetch(`/api/pools/${poolId}/reservations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ticketType, price }),
      });

      if (!response.ok) {
        throw new Error('Failed to create reservation');
      }

      console.log('Reservation created successfully');
    } catch (error) {
      console.error('Error creating reservation:', error);
    }
  };


  return (
    // <div className='p-10'>
    //   <CustomTable
    //     rows={poolReservations}
    //     columns={[
    //       {
    //         title: 'Date',
    //         renderValue: (item) => item.date
    //       },
    //       {
    //         title: 'Women',
    //         renderValue: (item) => item.woman
    //       },
    //       {
    //         title: 'Men',
    //         renderValue: (item) => item.man
    //       },
    //       {
    //         title: 'Kids',
    //         renderValue: (item) => item.kid
    //       }
    //     ]}
    //     setSearchText={setSearchText}
    //     loading={loading}
    //     showMoreActions={false}
    //     currentPage={currentPage}
    //     setCurrentPage={setCurrentPage}
    //     rowsPerPage={rowsPerPage}
    //     setRowsPerPage={setRowsPerPage}
    //     totalCount={totalCount}
    //   />
    // </div>


    <div className='p-10'>
      <CustomTable
        rows={poolReservations}
        columns={[
          {
            title: 'Date',
            renderValue: (item) => item.date
          },
          {
            title: 'Women',
            renderValue: (item) => 
                <span className="flex flex-row space-x-3 text-teal-600 font-bold">

                  <span className='font-normal text-black'>
                    {item.woman.count}
                  </span>
                  <span>
                   ({item.woman.totalPrice}€)
                   </span>

                </span>
          },
          {
            title: 'Men',
            renderValue: (item) => 
                  <span className="flex flex-row space-x-3 text-teal-600 font-bold">
                    <span className='font-normal text-black'>
                      {item.man.count}
                    </span>
                    <span>
                    ({item.man.totalPrice}€)
                    </span>
                    </span>
          },
          {
            title: 'Kids',
            renderValue: (item) => 
                  <span className="flex flex-row space-x-3 text-teal-600 font-bold">
                    <span className='font-normal text-black'>
                      {item.kid.count}
                    </span>
                    <span>
                    ({item.kid.totalPrice}€)
                    </span>
                  </span>
          },

          {
            title: 'Actions',
            renderValue: (item) => (
              // <PrintButton item={item} small={false} gray={false} createReservation={createReservation}>Print</PrintButton>
              <PrintButton item={{ ...item, poolId, ticketType: 'MAN', price: item.man.totalPrice }} createReservation={createReservation}>Print
</PrintButton>
            )          }

        ]}
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={false}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />

    </div>
  );
}

export default PoolHistory;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IconPropeller } from '@tabler/icons-react';
import Loading from '../../components/loader';
import PageTitle from '../../components/page-title';
import RoleForm from './RoleForm';
import { getRole } from '../../api/roles';

function EditRole() {
  const params = useParams();
  const roleId = params.id;
  const [role, setRole] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCurrentRole();
  }, []);

  const getCurrentRole = async () => {
    try {
      const response = await getRole(roleId);
      setRole(response.data.result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconPropeller className='text-hazel-green mr-2' size={22} />}
        title='Edit Role'
      />
      <RoleForm isEdit role={role} />
    </div>
  );
}

export default EditRole;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../../../components/loader';
import PageTitle from '../../../../../components/page-title';
import ClientForm from './ClientForm';
import { getClientById } from '../../../../../api/accounting';

function EditClient() {
  const params = useParams();
  const clientId = params.id;
  const [client, setClient] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchClient();
  }, []);

  const fetchClient = async () => {
    try {
      const response = await getClientById(clientId);
      setClient(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faBuilding}
            className='text-hazel-green mr-2'
          />
        }
        title='Edit Client'
      />
      <ClientForm
        isEdit
        client={{
          id: client.id,
          name: client.name,
          email: client.email,
          phone: client.phone,
          address: client.address || '',
          representative: client.representative || '',
          ubin: client.ubin || ''
        }}
      />
    </div>
  );
}

export default EditClient;

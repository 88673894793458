import React, { useState, useCallback, useEffect } from 'react';
import Loading from '../components/loader';
import CustomTable from '../components/table-components/table';
// import { getProducts, deleteProduct } from '../api/products';
import { getProducts, deleteProduct } from '../api/products';


function Inventory() {
  const [products, setProducts] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const getProductsList = useCallback(async () => {
    try {
      const response = await getProducts(
        rowsPerPage,
        currentPage + 1,
        searchText
      );
      setProducts(response.data.products);
      setTotalCount(response.data.count);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, searchText]);

  useEffect(() => {
    getProductsList();
  }, [getProductsList]);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  const handleDelete = async (id) => {
    try {
      await deleteProduct(id);
      setProducts((prevState) => [
        ...prevState.filter((item) => item.id !== id)
      ]);
      setTotalCount(totalCount - 1);
    } catch (err) {
      return err;
    }
  };

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className='p-10'>
      <CustomTable
        rows={products}
        columns={[
          {
            title: 'Name',
            renderValue: (item) => item.name
          },
          {
            title: 'Type',
            renderValue: (item) => item.type
          },
          {
            title: 'Quantity',
            renderValue: (item) => (
              <div
                className={`${
                  item.quantity > 30
                    ? 'bg-seashell text-bluish-grey'
                    : 'bg-dawn-pink text-salmon-pink'
                } rounded-md py-3`}
              >
                <p className='text-center'>{item.quantity}</p>
              </div>
            )
          },
          {
            title: 'Unit',
            renderValue: (item) => item.unit
          },
          {
            title: 'Unit Price',
            renderValue: (item) => item.unitPrice
          },
          {
            title: 'Total Price',
            renderValue: (item) => item.totalPrice
          },
          {
            title: 'Provider',
            renderValue: (item) => item.invoice.provider
          },
          {
            title: 'Invoice Number',
            renderValue: (item) => item.invoice.number
          },
          {},
          {}
        ]}
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={true}
        editLink='/invoices'
        onDelete={handleDelete}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </div>
  );
}

export default Inventory;

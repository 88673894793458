import React, { useState } from 'react';

// import Sidebar from '../partials/Sidebar';
// import Header from '../partials/Header';
import FilterButton from '../dash-component/DropdownFilter';
import Datepicker from '../dash-component/Datepicker.jsx';
import DashboardCard01 from '../partials/dashboard/DashboardCard01';
import DashboardCard02 from '../partials/dashboard/DashboardCard02';
import DashboardCard03 from '../partials/dashboard/DashboardCard03';
import DashboardCard04 from '../partials/dashboard/DashboardCard04';
import DashboardCard05 from '../partials/dashboard/DashboardCard05';
import DashboardCard06 from '../partials/dashboard/DashboardCard06';
import DashboardCard07 from '../partials/dashboard/DashboardCard07';
import DashboardCard08 from '../partials/dashboard/DashboardCard08';
import DashboardCard09 from '../partials/dashboard/DashboardCard09';
import DashboardCard10 from '../partials/dashboard/DashboardCard10';
import DashboardCard11 from '../partials/dashboard/DashboardCard11';
import DashboardCard12 from '../partials/dashboard/DashboardCard12';
import DashboardCard13 from '../partials/dashboard/DashboardCard13';
import Banner from '../partials/Banner';

import { Card, CardContent, CardHeader, CardTitle } from '../components/shadCN/Card.tsx';
import Tabs from '../components/shadCN/Tabs.jsx';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { ShoppingCart, TrendingUp, CreditCard, PieChart, Home, Settings, HelpCircle } from 'lucide-react'
import { Button } from '../components/shadCN/Button.tsx';

import ThemeToggle from '../dash-component/ThemeToggle';



const salesData = [
  { hour: '11', amount: 10 },
  { hour: '12', amount: 90 },
  { hour: '13', amount: 70 },
]

const productData = [
  { name: 'Espresso', quantity: 3 },
  { name: 'Macchiato e mesme', quantity: 2 },
  { name: 'Uje Rugove 0.33l', quantity: 2 },
  { name: 'Palici dhe Djegur', quantity: 2 },
  { name: 'Pomfrit', quantity: 1 },
  { name: 'Dhoma - 403', quantity: 1 },
  { name: 'Dhoma - 405', quantity: 1 },
  { name: 'Raki naka', quantity: 1 },
  { name: 'Red Bull', quantity: 1 },
  { name: 'Leng Pjeshke', quantity: 1 },
  { name: 'Birra Peja', quantity: 1 },
  { name: 'Kafe amerikane', quantity: 1 },
]

const dailySales = [
  { name: 'Isuf Arifaj (Isuf)', total: 23.10 },
  { name: 'Era Morina (Era)', total: 10.00 },
  { name: 'Detjon Pulaj (Detjon)', total: 138.00 },
]

function Dashboard() {

  const [activeTab, setActiveTab] = useState('Day');
  const tabs = ['Day', 'Month', 'Year'];

  return (
    <div className="z-2 flex h-screen overflow-hidden bg-slate-100 dark:bg-gray-900">

      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">


        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            <div className="sm:flex sm:justify-between sm:items-center mb-8">

              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 font-bold">Dashboard</h1>
              </div>

              <div className="z-2 grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

                <FilterButton align="right" />

                <Datepicker align="right" />
                <button className="px-6 py-2 bg-black text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">
                  Add View
                </button>

                <ThemeToggle />
              </div>

            </div>  


            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
              
            <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            <div className="mt-4">
              {activeTab === 'Day' && <div>Day Content</div>}
              {activeTab === 'Month' && <div>Month Content</div>}
              {activeTab === 'Year' && <div>Year Content</div>}
            </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                <StatCard title="Blerje" value="0.00 €" icon={<ShoppingCart className="h-6 w-6" />} color="bg-sage" />
                <StatCard title="Shitje" value="171.10 €" icon={<TrendingUp className="h-6 w-6" />} color="bg-bluish-grey" />
                <StatCard title="Shpenzime" value="0.00 €" icon={<CreditCard className="h-6 w-6" />} color="bg-salmon-pink" />
                <StatCard title="Totali" value="171.10 €" icon={<PieChart className="h-6 w-6" />} color="bg-hazel-green" />
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
                <Card className='shadow-lg cursor-pointer bg-white rounded-lg p-6 border border-gray-200 transition-transform transform hover:shadow-2xl hover:scale-105'>
                  <CardHeader>
                    <CardTitle>Shitjet sipas oreve</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={salesData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="hour" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="amount" fill="#90a68c" />
                      </BarChart>
                    </ResponsiveContainer>
                  </CardContent>
                </Card>

                <Card className='shadow-lg cursor-pointer bg-white rounded-lg p-6 border border-gray-200 transition-transform transform hover:shadow-2xl hover:scale-105'>
                  <CardHeader>
                    <CardTitle className="text-lg font-semibold text-gray-800">Pazari Ditorë</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <ul className="space-y-2">
                      {dailySales.map((sale, index) => (
                        <li key={index} className="flex justify-between items-center border-b border-gray-200 py-2">
                          <span className="text-gray-700">{sale.name}</span>
                          <span className="font-semibold text-gray-900">{sale.total.toFixed(2)} €</span>
                        </li>
                      ))}
                    </ul>
                    <div className="mt-4 pt-4 border-t border-gray-200">
                      <div className="flex justify-between items-center">
                        <span className="font-semibold text-gray-800">Total:</span>
                        <span className="font-bold text-xl text-gray-900">171.10 €</span>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <Card className='mb-8 shadow-lg cursor-pointer bg-white rounded-lg p-6 border border-gray-200 transition-transform transform hover:shadow-2xl hover:scale-105'>
                <CardHeader>
                  <CardTitle>Produktet më të shitura</CardTitle>
                </CardHeader>
                <CardContent>
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={productData} layout="vertical" margin={{ left: 100 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type="number" />
                      <YAxis dataKey="name" type="category" width={100} />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="quantity" fill="#7c8b93" />
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>

              <Card className='shadow-lg cursor-pointer bg-white rounded-lg p-6 border border-gray-200 transition-transform transform hover:shadow-2xl hover:scale-105'>
                <CardHeader>
                  <CardTitle>Shitjet me Faturë</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-grey-goose">No invoice sales data available for the selected period.</p>
                </CardContent>
              </Card>
            </main>


            <div className="grid grid-cols-12 gap-6">

              <DashboardCard01 />
              <DashboardCard02 />
              <DashboardCard03 />
              <DashboardCard04 />
              <DashboardCard05 />
              <DashboardCard06 />
              <DashboardCard07 />
              <DashboardCard08 />
              <DashboardCard09 />
              {/* <DashboardCard10 /> */}
              <DashboardCard11 />
              <DashboardCard12 />
              <DashboardCard13 />
              
            </div>

          </div>
        </main>

        <Banner />

      </div>
    </div>
  );
}

function StatCard({ title, value, icon, color }) {
  return (
    <Card>
      <CardContent className="shadow-lg cursor-pointer bg-white rounded-lg flex items-center justify-between p-6 border border-grey-goose transition-transform transform hover:scale-105 hover:shadow-xl">
        <div className={`rounded-full p-3 mr-4 ${color} text-white`}>
          {icon}
        </div>
        <div>
          <p className="text-sm font-medium text-grey-goose">{title}</p>
          <h3 className="text-2xl font-bold text-bluish-grey">{value}</h3>
        </div>
      </CardContent>
    </Card>
  )
}


export default Dashboard;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../components/loader';
import CustomInput from '../../../components/custom-input';
import SelectInput from '../../../components/select-input';
import { SubmitButton, CancelButton } from '../../../components/buttons';


import { addStockLossItem , updateStockLossItem } from '../../../api/stockLoss';
import { getStockItems } from '../../../api/stock';

function StockLossItemForm({ isEdit, stockLossItem }) {
  const navigate = useNavigate();
  const [stockItems, setStockItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAvailableStockItems();
  }, []);

  const getAvailableStockItems = async () => {
    try {
      const response = await getStockItems();
      setStockItems(response.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
      <Formik
        initialValues={{
          stockItemId: isEdit ? stockLossItem.stockItemId : '',
          quantity: isEdit ? stockLossItem.quantity : 0,
          reason: isEdit ? stockLossItem.reason : '',
        }}
        validationSchema={Yup.object().shape({
          stockItemId: Yup.string().required('Stock Item is required*'),
          quantity: Yup.number()
            .required('Quantity is required*')
            .min(1, 'Quantity must be at least 1'),
          reason: Yup.string().required('Reason is required*'),
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            console.log('Submitting values:', values); 
            const dataToSend = {
              stockItemId: values.stockItemId,
              quantity: values.quantity,
              reason: values.reason
            };
            console.log('Data to send:', dataToSend); 

            if (isEdit) {
              await updateStockLossItem(stockLossItem.id, dataToSend);
            } else {
              await addStockLossItem(dataToSend);
            }
            navigate('/stock-loss');
            setSubmitting(false);
          } catch (err) {
            console.error('Error submitting form:', err);
            const errorMessage = err?.response?.data?.message || err.message || 'An error occurred';
            setErrors({ submit: errorMessage });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-2 gap-x-6 gap-y-3'>
              <SelectInput
                value={values.stockItemId}
                label='Stock Item'
                options={stockItems.map(item => ({ id: item.id, title: item.name }))}
                onChange={(e) => setFieldValue('stockItemId', e.target.value)}
                onBlur={handleBlur}
                showError={errors.stockItemId && touched.stockItemId}
                errorMessage={errors.stockItemId}
              />

              <CustomInput
                type='number'
                name='quantity'
                label='Quantity Lost'
                value={values.quantity}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.quantity && touched.quantity}
                errorMessage={errors.quantity}
              />

              <CustomInput
                type='text'
                name='reason'
                label='Reason for Loss'
                value={values.reason}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.reason && touched.reason}
                errorMessage={errors.reason}
              />
            </div>

            {errors.submit && <p className='text-error'>{errors.submit}</p>}
            <div className='flex justify-end items-center w-full mt-7 text-sm'>
              <CancelButton onCancel={() => navigate('/stock-loss')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/5'
                title='Save'
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default StockLossItemForm;
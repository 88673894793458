import React from 'react';

export default function PageTitleWithButtons({ title, icon, buttons }) {
  return (
    <div className='flex items-center justify-between border border-gray-200 rounded-xl shadow-lg p-6'>
      {/* Icon and Title */}
      <div className="flex items-center">
        {icon && <div className='mr-4'>{icon}</div>}
        <p className='text-bluish-grey font-medium'>{title}</p>
      </div>

      {/* Buttons */}
      <div className='flex space-x-4'>
        {buttons && buttons.map((button, index) => (
          <div key={index}>{button}</div>
        ))}
      </div>
    </div>
  );
}

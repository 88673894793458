import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../api/api.js';
import {
  IconToolsKitchen2,
  IconCoffee,
  IconArrowLeft,
  IconTrash,
  IconPlus,
  IconPencil,
  IconBuilding,
  IconBed
} from '@tabler/icons-react';
import Modal from '../../../components/modal';
import {
  getCategoryOrderProducts,
  createCategoryOrderProduct,
  deleteCategoryOrderProduct,
  updateCategoryOrderProduct
} from '../../../api/categoryOrderProduct';
import { getBarProducts } from '../../../api/barProduct';
import { getDishSections } from '../../../api/dishSection';
// import { getConferenceRoomMenus } from '../../../api/conferenceRoomMenu';
import { getAllMenus as getConferenceRoomMenus } from '../../../api/conferenceRoomMenu';
import { dishTypes } from '../../../constants/entities';
import { toast } from 'react-toastify';

import Calculator from '../../../components/calculator/Calculator.tsx';
import { getPaginatedRooms } from '../../../api/rooms';
import axios from 'axios';
import { getMenuById } from '../../../api/conferenceRoomMenu';

function Sidebar({ onCategoryChange , menu}) {
  console.log("MENU",menu)
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({ name: '', id: null, categoryType: '' });
  const [categories, setCategories] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [barProducts, setBarProducts] = useState([]);
  const [dishSections, setDishSections] = useState([]);
  const [groupedDishSections, setGroupedDishSections] = useState({});
  const [conferenceRoomMenus, setConferenceRoomMenus] = useState([]); 
  const [expandedMealType, setExpandedMealType] = useState({});
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [activeReservations, setActiveReservations] = useState([]);
  // const [menu, setMenu] = useState(null);

  const fetchData = useCallback(async () => {
    const [categoriesResponse, barProductsResponse, dishSectionsResponse, conferenceRoomMenusResponse] = await Promise.all([
      getCategoryOrderProducts(),
      getBarProducts(),
      getDishSections(),
      getConferenceRoomMenus() // Fetch conferec mens
    ]);
    setCategories(categoriesResponse.data);
    setBarProducts(barProductsResponse.data);
    setDishSections(dishSectionsResponse.data);
    setConferenceRoomMenus(conferenceRoomMenusResponse.data); // Set conference enus
    console.log("Conference menus", conferenceRoomMenusResponse.data);

    const grouped = dishSectionsResponse.data.reduce((acc, section) => {
      if (!acc[section.type]) {
        acc[section.type] = [];
      }
      acc[section.type].push(section);
      return acc;
    }, {});
    setGroupedDishSections(grouped);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchActiveReservations = async () => {
      try {
        const response = await axios.get('/api/conferenceRoomReservation/active');
        const reservations = response.data.data;
        setActiveReservations(reservations);
      } catch (error) {
        console.error('Error fetching active reservations:', error);
      }
    };

    fetchActiveReservations();
  }, []);

  // useEffect(() => {
  //   const fetchMenu = async () => {
  //     try {
  //       const response = await getMenuById("45a75092-ff28-43af-a3e7-fd100b026cf8"); // Replace with actual menu ID
  //       setMenu(response.data);
  //     } catch (error) {
  //       console.error('Error fetching menu:', error);
  //     }
  //   };

  //   fetchMenu();
  // }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    onCategoryChange(category, selectedRoom);
  };

  const handleRoomSelect = (e) => {
    const roomValue = e.target.value;
    setSelectedRoom(roomValue);
    if (selectedCategory === 'room') {
      onCategoryChange('room', roomValue);
    }
  };

  const handleBackClick = () => {
    setSelectedCategory(null); 
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await deleteCategoryOrderProduct(categoryId);
      // Update your local state or refetch categories
      toast.success('Category and related dish sections deleted successfully');
    } catch (error) {
      console.error('Error deleting category:', error);
      toast.error('Failed to delete category');
    }
  };

  const handleDeleteDishSection = async (type) => {
    try {
      // Add your delete logic here
      setGroupedDishSections(prevGroupedDishSections => {
        const updatedGroupedDishSections = { ...prevGroupedDishSections };
        delete updatedGroupedDishSections[type];
        return updatedGroupedDishSections;
      });
    } catch (error) {
      console.error('Failed to delete dish section:', error);
    }
  };

  const handleCreateCategory = async () => {
    try {
      const response = await createCategoryOrderProduct({
        title: currentCategory.name,
        description: 'Description of a category',
        categoryType: selectedCategory === 'kitchen' ? 'KITCHEN' : 'BAR',
      });
      setCategories([...categories, response.data]);
      setShowCreateCategoryModal(false);
      setCurrentCategory({ name: '', id: null, categoryType: '' });
    } catch (error) {
      console.error('Failed to create category:', error);
    }
  };

  const handleEditCategory = async () => {
    try {
      const response = await updateCategoryOrderProduct(currentCategory.id, { title: currentCategory.name });
      setCategories(categories.map(category => (category.id === currentCategory.id ? response.data : category)));
      setShowEditCategoryModal(false);
      setCurrentCategory({ name: '', id: null, categoryType: '' });
    } catch (error) {
      console.error('Failed to edit category:', error);
    }
  };

  // const handleDragStart = (e, item, itemType) => {
  //   e.stopPropagation();
  //   const dragData = {
  //     id: item.id,
  //     name: item.name,
  //     productName: item.name,
  //     price: parseFloat(item.price),
  //     description: item.description || '',
  //     quantity: 1,
  //     type: itemType === 'bar' ? 'barItem' : 'dish',
  //     category: itemType === 'bar' ? 'BAR' : 'KITCHEN'
  //   };
  //   console.log('Drag data:', dragData);
  //   e.dataTransfer.setData('application/json', JSON.stringify(dragData));
  //   e.dataTransfer.effectAllowed = 'move';
  // };
  
  
  const handleDragStart = (e, item, itemType) => {
    e.stopPropagation();
    const dragData = {
      id: item.id,
      name: item.name,
      productName: item.name,
      price: parseFloat(item.price),
      description: item.description || '',
      quantity: 1,
      type: itemType === 'bar' ? 'barItem' : 'dish',
      category: itemType === 'bar' ? 'BAR' : 'KITCHEN'
    };
    console.log('Drag data:', dragData);
    e.dataTransfer.setData('application/json', JSON.stringify(dragData));
    e.dataTransfer.effectAllowed = 'move';
  };
  

  const toggleMealType = (mealType) => {
    setExpandedMealType(prev => ({
      ...prev,
      [mealType]: !prev[mealType],
    }));
  };

  const renderCategoryCards = () => (
    <div className='flex flex-col space-y-4'>
      <button
        onClick={() => handleCategoryClick('kitchen')}
        className='bg-hazel-green text-white p-6 rounded-lg shadow-lg hover:bg-sage transition duration-300 flex items-center justify-between'
      >
        <span className='text-xl font-semibold'>Kitchen</span>
        <IconToolsKitchen2 size={24} />
      </button>
      <button
        onClick={() => handleCategoryClick('bar')}
        className='bg-hazel-green text-white p-6 rounded-lg shadow-lg hover:bg-sage transition duration-300 flex items-center justify-between'
      >
        <span className='text-xl font-semibold'>Bar</span>
        <IconCoffee size={24} />
      </button>
      <button
        onClick={() => handleCategoryClick('room')}
        className='bg-hazel-green text-white p-6 rounded-lg shadow-lg hover:bg-sage transition duration-300 flex items-center justify-between'
      >
        <span className='text-xl font-semibold'>Room Service</span>
        <IconBed size={24} />
      </button>
      <button
        onClick={() => handleCategoryClick('conferenceRoom')}
        className='bg-hazel-green text-white p-6 rounded-lg shadow-lg hover:bg-sage transition duration-300 flex items-center justify-between'
      >
        <span className='text-xl font-semibold'>Conference Room</span>
        <IconBuilding size={24} />
      </button>
    </div>
  );

  const fetchAllRooms = useCallback(async () => {
    try {
      const response = await getPaginatedRooms(1, 100); // Get first 100 rooms
      if (response.data && response.data.rooms) {
        setRooms(response.data.rooms);
      }
    } catch (error) {
      console.error('Error fetching rooms:', error);
      toast.error('Failed to fetch rooms');
    }
  }, []);

  useEffect(() => {
    if (selectedCategory === 'room') {
      fetchAllRooms();
    }
  }, [selectedCategory, fetchAllRooms]);

  const renderSubCategories = () => (
    <div className='space-y-4'>
      <button onClick={handleBackClick} className='flex items-center text-bluish-grey mb-4'>
        <IconArrowLeft size={16} className='mr-2' />
        Back to Categories
      </button>

      {selectedCategory === 'room' && (
        <div className='space-y-4'>
          {/* Room Selection Dropdown */}
          <div className='bg-white rounded-lg shadow-md p-4'>
            <label htmlFor="roomSelect" className='block text-sm font-medium text-gray-700 mb-2'>
              Select Room
            </label>
            <select
              id="roomSelect"
              className='w-full p-2 border border-gray-300 rounded-md focus:ring-hazel-green focus:border-hazel-green'
              value={selectedRoom || ''}
              onChange={handleRoomSelect}
            >
              <option value="">Select a room...</option>
              {rooms.map((room) => (
                <option key={room.id} value={room.number}>
                  Room {room.number} - {room.status}
                </option>
              ))}
            </select>
          </div>

          {/* Kitchen Items Section */}
          <li className='rounded-lg bg-white shadow-md overflow-hidden'>
            <div 
              className='flex justify-between items-center p-4 cursor-pointer bg-seashell hover:bg-grey-goose transition duration-300'
              onClick={() => setExpandedCategory(expandedCategory === 'kitchen' ? null : 'kitchen')}
            >
              <span className='text-lg font-semibold'>Kitchen Items</span>
              <IconArrowLeft size={16} className={`transform transition-transform duration-200 ${expandedCategory === 'kitchen' ? 'rotate-90' : '-rotate-90'}`} />
            </div>
            <div className={`accordion-content ${expandedCategory === 'kitchen' ? 'expanded' : ''}`}>
              <div className='p-4 space-y-3 max-h-[300px] overflow-y-auto'>
                {Object.entries(groupedDishSections).map(([type, sections]) => (
                  <div key={type} className='mb-4'>
                    <h4 className='font-medium text-gray-700 mb-2'>{type}</h4>
                    {sections.map(section => (
                      <div 
                        key={section.id}
                        className='flex items-center space-x-3 p-2 rounded-md hover:bg-gray-100 cursor-move select-none'
                        draggable="true"
                        onDragStart={(e) => {
                          e.stopPropagation();
                          handleDragStart(e, { 
                            ...section, 
                            type: 'dish',
                            category: 'kitchen'
                          });
                        }}
                      >
                        {section.imageUrl && (
                          <img 
                            src={`http://localhost:3001${section.imageUrl}`}
                            alt={section.name}
                            className='w-12 h-12 object-cover rounded-md'
                            draggable="false"
                          />
                        )}
                        <div className='select-none'>
                          <p className='font-medium'>{section.name}</p>
                          <p className='text-sm text-gray-600'>${section.price.toFixed(2)}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </li>

          {/* Bar Items Section */}
          <li className='rounded-lg bg-white shadow-md overflow-hidden'>
            <div 
              className='flex justify-between items-center p-4 cursor-pointer bg-seashell hover:bg-grey-goose transition duration-300'
              onClick={() => setExpandedCategory(expandedCategory === 'bar' ? null : 'bar')}
            >
              <span className='text-lg font-semibold'>Bar Items</span>
              <IconArrowLeft size={16} className={`transform transition-transform duration-200 ${expandedCategory === 'bar' ? 'rotate-90' : '-rotate-90'}`} />
            </div>
            <div className={`accordion-content ${expandedCategory === 'bar' ? 'expanded' : ''}`}>
              <div className='p-4 space-y-3 max-h-[300px] overflow-y-auto'>
                {categories
                  .filter(cat => cat.categoryType === 'BAR')
                  .map(category => (
                    <div key={category.id} className='mb-4'>
                      <h4 className='font-medium text-gray-700 mb-2'>{category.title}</h4>
                      {barProducts
  .filter(product => product.categoryOrderProductId === category.id)
  .map(item => (
    <div 
      key={item.id}
      className='flex items-center space-x-3 p-2 rounded-md hover:bg-gray-100 cursor-move'
      draggable
      onDragStart={(e) => {
        e.stopPropagation();
        const dragData = {
          id: item.id,
          name: item.name,
          productName: item.name,
          price: parseFloat(item.price),
          description: item.description || '',
          quantity: 1,
          type: 'barItem',
          category: 'BAR'
        };
        console.log('Drag data:', dragData);
        e.dataTransfer.setData('application/json', JSON.stringify(dragData));
        e.dataTransfer.effectAllowed = 'move';
      }}
    >
      {item.imageUrl && (
        <img 
          src={`http://localhost:3001${item.imageUrl}`}
          alt={item.name}
          className='w-12 h-12 object-cover rounded-md'
        />
      )}
      <div>
        <p className='font-medium'>{item.name}</p>
        <p className='text-sm text-gray-600'>${item.price.toFixed(2)}</p>
      </div>
    </div>
  ))}
                    </div>
                  ))}
              </div>
            </div>
          </li>
        </div>
      )}

{selectedCategory === 'conferenceRoom' && menu && (
  <li key={menu.id} className='rounded-lg bg-white shadow-md overflow-hidden'>
    <div
      className='flex justify-between items-center p-4 cursor-pointer'
      onClick={() => setExpandedCategory(expandedCategory === menu.id ? null : menu.id)}
    >
      <h3 className='font-semibold text-bluish-grey'>{menu.name}</h3>
      <IconArrowLeft size={16} className={`transform transition-transform duration-200 ${expandedCategory === menu.id ? 'rotate-90' : '-rotate-90'}`} />
    </div>

    {expandedCategory === menu.id && (
      <div className='p-4 space-y-3 max-h-80 overflow-y-auto'>
        {['breakfastItems', 'lunchItems', 'dinnerItems', 'breakfastBarItems', 'lunchBarItems', 'dinnerBarItems'].map((mealType) => (
          <div key={mealType} className='border-b border-gray-200 pb-2'>
            <div
              className='flex justify-between items-center cursor-pointer py-2'
              onClick={() => toggleMealType(mealType)}
            >
              <h4 className='font-medium text-bluish-grey'>
                {mealType.replace(/([A-Z])/g, ' $1').replace('Items', '').trim()}
              </h4>
            </div>

            {expandedMealType[mealType] && (
              <div className='mt-2 space-y-2'>
                {menu[mealType]?.length > 0 ? (
                  menu[mealType].map((item) => (
                    <div
                      key={item.id}
                      className='flex items-center space-x-3 p-2 rounded-md hover:bg-gray-100 cursor-pointer'
                      onDragStart={(e) => handleDragStart(e, item, mealType.includes('Bar') ? 'bar' : 'kitchen')}
                      draggable
                    >
                      {item.imageUrl && (
                        <img
                          src={`http://localhost:3001${item.imageUrl}`}
                          alt={item.name}
                          className='w-12 h-12 object-cover rounded-md'
                        />
                      )}
                      <div>
                        <p className='font-medium'>{item.name}</p>
                        <p className='text-sm text-gray-600'>${item.price.toFixed(2)}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className='text-sm text-gray-500 italic'>No items available</p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    )}
  </li>
)}
      {selectedCategory === 'kitchen' ? (
        Object.entries(groupedDishSections).map(([type, sections], index) => (
          <li key={index} className='rounded-lg bg-white shadow-md overflow-hidden'>
            <div 
              className='flex justify-between items-center p-4 cursor-pointer bg-seashell hover:bg-grey-goose transition duration-300'
              onClick={() => setExpandedCategory(expandedCategory === type ? null : type)}
            >
              <span className='text-lg font-semibold'>{type}</span>
              <div className='flex space-x-2'>
                <IconTrash
                  className='text-hazel-green cursor-pointer hover:text-sage'
                  size={20}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteDishSection(type);
                  }}
                />
              </div>
            </div>
            <div className={`accordion-content ${expandedCategory === type ? 'expanded' : ''}`}>
              <div className='p-4 space-y-3'>
                {sections.map(section => (
                  <div 
                    key={section.id} 
                    className='flex items-center space-x-3 p-2 rounded-md hover:bg-gray-100 cursor-move' 
                    draggable 
                    onDragStart={(e) => {
                      e.stopPropagation();
                      handleDragStart(e, section, 'kitchen');
                    }}
                  >
                    {section.imageUrl && (
                      <img 
                        src={`http://localhost:3001${section.imageUrl}`}
                        alt={section.name} 
                        className='w-12 h-12 object-cover rounded-md'
                      />
                    )}
                    <div>
                      <p className='font-medium'>{section.name}</p>
                      <p className='text-sm text-gray-600'>${section.price.toFixed(2)}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </li>
        ))
      ) : selectedCategory === 'bar' ? (
        categories
          .filter(cat => cat.categoryType === 'BAR')
          .map(category => (
            <li key={category.id} className='rounded-lg bg-white shadow-md overflow-hidden'>
              <div 
                className='flex justify-between items-center p-4 cursor-pointer bg-seashell hover:bg-grey-goose transition duration-300'
                onClick={() => setExpandedCategory(expandedCategory === category.id ? null : category.id)}
              >
                <span className='text-lg font-semibold'>{category.title}</span>
                <div className='flex space-x-2'>
                  <IconPencil
                    className='text-hazel-green cursor-pointer hover:text-sage'
                    size={20}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentCategory({...category, name: category.title});
                      setShowEditCategoryModal(true);
                    }}
                  />
                  <IconTrash
                    className='text-hazel-green cursor-pointer hover:text-sage'
                    size={20}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(category.id);
                    }}
                  />
                </div>
              </div>
              <div className={`accordion-content ${expandedCategory === category.id ? 'expanded' : ''}`}>
                <div className='p-4 space-y-3'>
                  {/* {barProducts
                    .filter(product => product.categoryOrderProductId === category.id)
                    .map(product => (
                      <div key={product.id} className='flex items-center space-x-3 p-2 rounded-md hover:bg-gray-100 cursor-move' draggable onDragStart={(e) => handleDragStart(e, { ...product, type: 'bar' })}>
                        {product.imageUrl && (
                          <img 
                            src={`http://localhost:3001${product.imageUrl}`} 
                            alt={product.name} 
                            className='w-12 h-12 object-cover rounded-md'
                          />
                        )}
                        <div>
                          <p className='font-medium'>{product.name}</p>
                          <p className='text-sm text-gray-600'>${product.price.toFixed(2)}</p>
                        </div>
                      </div>
                    ))} */}

{barProducts
  .filter(product => product.categoryOrderProductId === category.id)
  .map(product => (
    <div 
      key={product.id} 
      className='flex items-center space-x-3 p-2 rounded-md hover:bg-gray-100 cursor-move' 
      draggable 
      onDragStart={(e) => {
        e.stopPropagation();
        handleDragStart(e, product, 'bar');
      }}
    >
      {product.imageUrl && (
        <img 
          src={`http://localhost:3001${product.imageUrl}`} 
          alt={product.name} 
          className='w-12 h-12 object-cover rounded-md'
        />
      )}
      <div>
        <p className='font-medium'>{product.name}</p>
        <p className='text-sm text-gray-600'>${product.price.toFixed(2)}</p>
      </div>
    </div>
  ))}

                </div>
              </div>
            </li>
          ))
      ) : null}
    </div>
  );

  return (
    <aside className='w-64 bg-tealish-blue text-bluish-grey min-h-screen p-5'>
      <div className="pt-16">
        <h2 className='text-2xl font-bold mb-6'>Categories</h2>
        {selectedCategory ? renderSubCategories() : renderCategoryCards()}
      </div>

      {/* Modal for creating a new category */}
      {showCreateCategoryModal && (
        <Modal onClose={() => setShowCreateCategoryModal(false)}>
          <div>
            <h2 className='text-lg font-bold mb-4'>Create New Category</h2>
            <input
              type='text'
              placeholder='Category Title'
              value={currentCategory.name}
              onChange={(e) => setCurrentCategory({ ...currentCategory, name: e.target.value })}
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            <input
              type='text'
              placeholder='Category Description'
              value={currentCategory.description || ''}
              onChange={(e) => setCurrentCategory({ ...currentCategory, description: e.target.value })}
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            <button
              onClick={handleCreateCategory}
              className='bg-sage text-white py-2 px-4 rounded-md'
            >
              Create Category
            </button>
          </div>
        </Modal>
      )}

      {/* Modal for editing a category */}
      {showEditCategoryModal && (
        <Modal onClose={() => setShowEditCategoryModal(false)}>
          <div>
            <h2 className='text-lg font-bold mb-4'>Edit Category</h2>
            <input
              type='text'
              value={currentCategory.name}
              onChange={(e) => setCurrentCategory({ ...currentCategory, name: e.target.value })}
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            <button
              onClick={handleEditCategory}
              className='bg-sage text-white py-2 px-4 rounded-md'
            >
              Save Changes
            </button>
          </div>
        </Modal>
      )}
    </aside>
  );
}

export default Sidebar;

import React from 'react';
import { Link } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';
import PermissionCheck from '../../utils/PermissionCheck';

export default function AddButton({ entity, link, title }) {
  return (
    <PermissionCheck action={`edit_${entity}`}>
      <div className='flex justify-end w-full mb-8'>
        <Link
          to={link}
          className='whitespace-nowrap flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5'
        >
          <IconPlus className='mr-2' size={20} />
          {title}
        </Link>
      </div>
    </PermissionCheck>
  );
}

import React, { useState, useEffect } from 'react';
import { Search, Plus, ChevronRight, DollarSign, ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getAllSalesReceipts } from '../../../../../api/salesReceipt';
import { getAllItemsOrServices } from '../../../../../api/itemOrService';
import { getClientById } from '../../../../../api/accounting';

interface Receipt {
  id: number;
  receiptNumber: string;
  clientId: string;
  receiptDate: string;
  notes: string;
  paymentMethod: string;
  reference: string;
  status: string;
  totalAmount: number;
  items: Item[];
}

interface Item {
  itemId: number;
  id: number;
  name: string;
}

function SalesReceiptList() {
  const [salesReceipts, setSalesReceipts] = useState<Receipt[]>([]);
  const [availableItems, setAvailableItems] = useState<Item[]>([]);
  const [clientNames, setClientNames] = useState<{ [key: string]: string }>({});
  const [currentPage, setCurrentPage] = useState(1);
  const receiptsPerPage = 6;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSalesReceipts = async () => {
      try {
        const response = await getAllSalesReceipts();
        setSalesReceipts(response.data);
        response.data.forEach((receipt: Receipt) => {
          fetchClientName(receipt.clientId);
        });
      } catch (error) {
        console.error('Error fetching sales receipts:', error);
      }
    };

    const fetchItems = async () => {
      try {
        const response = await getAllItemsOrServices();
        setAvailableItems(response.data);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchSalesReceipts();
    fetchItems();
  }, []);

  const fetchClientName = async (clientId: string) => {
    if (!clientNames[clientId]) {
      try {
        const response = await getClientById(clientId);
        setClientNames(prev => ({ ...prev, [clientId]: response.data.name }));
      } catch (error) {
        console.error(`Error fetching client name for ID ${clientId}:`, error);
      }
    }
  };

  const handleClickButton = () => {
    navigate('/accounting/sales/sale-receipt');
  };

  const getItemName = (itemId: number) => {
    const item = availableItems.find(item => item.id === itemId);
    return item ? item.name : 'Unknown Item';
  };

  const indexOfLastReceipt = currentPage * receiptsPerPage;
  const indexOfFirstReceipt = indexOfLastReceipt - receiptsPerPage;
  const currentReceipts = salesReceipts.slice(indexOfFirstReceipt, indexOfLastReceipt);
  const totalPages = Math.ceil(salesReceipts.length / receiptsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = (currentPage: number, totalPages: number): number[] => {
    const pageNumbers: number[] = [];
    const totalPagesToShow: number = 3;
    
    let startPage: number = Math.max(1, currentPage - 1);
    let endPage: number = Math.min(totalPages, startPage + totalPagesToShow - 1);

    if (endPage - startPage + 1 < totalPagesToShow) {
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    for (let i: number = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className="min-h-screen bg-seashell p-8">
      <div className="mb-8 flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-hazel-green">Sales Receipts</h1>
        <button 
          onClick={handleClickButton}
          className="inline-flex items-center px-4 py-2 bg-hazel-green hover:bg-sage text-white rounded-lg transition-colors duration-200"
        >
          <Plus className="h-4 w-4 mr-2" />
          New Receipt
        </button>
      </div>

      <div className="bg-white rounded-xl shadow-sm border border-mercury">
        <div className="p-4 border-b border-mercury">
          <div className="relative w-64">
            <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" />
            <input
              type="text"
              placeholder="Search receipts..."
              className="pl-10 pr-4 py-2 w-full border border-mercury rounded-lg text-sm focus:outline-none focus:border-hazel-green"
            />
          </div>
        </div>

        <div className="grid grid-cols-8 gap-4 p-4 bg-tealish-blue">
          <div className="text-sm font-medium text-bluish-grey">RECEIPT #</div>
          <div className="text-sm font-medium text-bluish-grey">CLIENT NAME</div>
          <div className="text-sm font-medium text-bluish-grey">DATE</div>
          <div className="text-sm font-medium text-bluish-grey">NOTES</div>
          <div className="text-sm font-medium text-bluish-grey">PAYMENT METHOD</div>
          <div className="text-sm font-medium text-bluish-grey">REFERENCE</div>
          <div className="text-sm font-medium text-bluish-grey">AMOUNT</div>
          <div className="text-sm font-medium text-bluish-grey">STATUS</div>
        </div>

        <div className="divide-y divide-mercury">
          {currentReceipts.map((receipt) => (
            <div
              key={receipt.id}
              className="grid grid-cols-8 gap-4 p-4 hover:bg-seashell transition-colors duration-200 group cursor-pointer"
            >
              <div className="flex items-center">
                <DollarSign className="h-4 w-4 mr-2 text-hazel-green" />
                <span className="text-hazel-green font-medium group-hover:text-sage">
                  {receipt.receiptNumber}
                </span>
                <ChevronRight className="h-4 w-4 ml-2 text-bluish-grey opacity-0 group-hover:opacity-100 transition-opacity" />
              </div>
              <div className="text-bluish-grey">{clientNames[receipt.clientId] || 'Loading...'}</div>
              <div className="text-bluish-grey">{new Date(receipt.receiptDate).toLocaleDateString()}</div>
              <div className="text-bluish-grey">{receipt.notes}</div>
              <div className="text-bluish-grey">{receipt.paymentMethod}</div>
              <div className="text-bluish-grey">{receipt.reference}</div>
              <div className="text-bluish-grey">
                ${receipt.totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </div>
              <div className="flex items-center">
                <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                  receipt.status === 'Paid' 
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-yellow-100 text-yellow-800'
                }`}>
                  {receipt.status}
                </span>
              </div>
              <div className="text-bluish-grey">
                <select className="border border-mercury rounded-lg text-sm">
                  {receipt.items.map(item => (
                    <option key={item.id} value={item.id}>
                      {getItemName(item.itemId)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center items-center gap-2 mt-6">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="p-2 rounded-lg border border-mercury disabled:opacity-50 disabled:cursor-not-allowed hover:bg-seashell transition-colors"
        >
          <ChevronLeft className="h-4 w-4 text-bluish-grey" />
        </button>
        
        {getPageNumbers(currentPage, totalPages).map((number) => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
              ${currentPage === number 
                ? 'bg-hazel-green text-white' 
                : 'bg-white text-bluish-grey border border-mercury hover:bg-seashell'
              }`}
          >
            {number}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="p-2 rounded-lg border border-mercury disabled:opacity-50 disabled:cursor-not-allowed hover:bg-seashell transition-colors"
        >
          <ChevronRight className="h-4 w-4 text-bluish-grey" />
        </button>
      </div>
    </div>
  );
}

export default SalesReceiptList;
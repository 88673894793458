import React from "react";
import Carousel from "./Carousel";
import Book from "./Book";
import About from "./About";
import Services from "./Service";
import Rooms from "./Rooms";
import Sliders from "./Slider";
import Teams from "./Team";
import Header from "../common/Header";
import Footer from "../common/Footer";

// import './css/animate.css';
// import './css/animate.min.css';
// import './css/bootstrap.min.css';
// import './css/style.css';

import BootstrapLoader from "./BootstrapLoader";

function Home() {
  return (
    <>
      <Header />
      <Carousel />
      <Book />
      <About />
      <Rooms />
      <Services />
      <Sliders />
      {/* <Teams /> */}
      <Footer />
    </>
  );
}

export default Home;
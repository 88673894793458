import React from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { checkUserHasPermission } from '../helpers/user-helper';

const PermissionCheck = (props) => {
  const { action, children } = props;
  const { userPermissions, userRole } = useAuth();

  if (checkUserHasPermission(userRole, userPermissions, [`${action}`])) {
    return <>{children}</>;
  }
  return null;
};

PermissionCheck.propTypes = {
  children: PropTypes.node
};

export default PermissionCheck;

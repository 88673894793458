import React, { useEffect, useState } from 'react';
import { Building2, Key, LogOut, ShoppingCart, Star, Calendar, Users, Clock } from 'lucide-react';
import StatCard from './StatCard.tsx';
import StatusBadgeElement from './StatusBadgeElement.jsx';
import { getReservationStatistics } from '../../api/reservationsv2';

const Dashboard = () => {
  const [stats, setStats] = useState({
    totalReservations: 0,
    activeReservations: 0,
    monthlyReservations: 0,
    weeklyReservations: 0,
    currentRevenue: 0,
    monthlyRevenue: 0,
    occupancyRate: 0
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await getReservationStatistics();
        console.log('API Response:', response); // Debug log
        
        if (response?.data?.message) {
          setStats(response.data.message);
        } else {
          console.error('Invalid response format:', response);
          setError('Invalid data format received');
        }
      } catch (error) {
        console.error('Error fetching reservation statistics:', error);
        setError('Failed to load statistics');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
    const interval = setInterval(fetchStats, 300000);
    return () => clearInterval(interval);
  }, []);

  // Safe access function
  const getSafeValue = (value: number | undefined) => {
    return value !== undefined ? value : 0;
  };

  if (error) {
    return (
      <div className="text-red-500 p-4">
        Error: {error}
      </div>
    );
  }

  return (
    <div className="bg-seashell min-h-screen">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Status badges */}
        <div className="mb-8">
          <div className="flex flex-wrap space-x-0 sm:space-x-4 mt-4">
            <StatusBadgeElement label="New Bookings This Month" color="bg-hazel-green" />
            <StatusBadgeElement label="Do Not Reserve This Month" color="bg-hazel-green" />
            <StatusBadgeElement label="Temp Bookings This Month" color="bg-hazel-green" />
          </div>
        </div>

        {/* Stats cards */}
        <div className="mb-8">
          <div className="flex flex-col sm:flex-row items-center justify-between mb-6">
            <h2 className="text-2xl font-semibold text-bluish-grey">What's happening today</h2>
            <div className="flex flex-wrap space-x-0 sm:space-x-4 mt-4 sm:mt-0">
              <button className="bg-watermelon text-white px-4 py-2 rounded-md hover:bg-opacity-90 transition-colors mb-2 sm:mb-0">
                Open PMS Calendar
              </button>
              <button className="bg-watermelon text-white px-4 py-2 rounded-md hover:bg-opacity-90 transition-colors">
                ADD NEW PROPERTY IN PMS
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6">
            <StatCard 
              icon={Users} 
              value={getSafeValue(stats?.activeReservations)} 
              label="Active Reservations" 
              loading={loading}
            />
            <StatCard 
              icon={Calendar} 
              value={getSafeValue(stats?.monthlyReservations)} 
              label="This Month" 
              loading={loading}
            />
            <StatCard 
              icon={Clock} 
              value={getSafeValue(stats?.weeklyReservations)} 
              label="Last 7 Days" 
              loading={loading}
            />
            <StatCard 
              icon={Building2} 
              value={getSafeValue(stats?.totalReservations)} 
              label="All Time" 
              loading={loading}
            />
            <StatCard 
              icon={Star} 
              value={`${getSafeValue(stats?.occupancyRate).toFixed(1)}%`} 
              label="Occupancy Rate" 
              loading={loading}
            />
          </div>
        </div>

        {/* Account Statistics */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h3 className="text-xl font-semibold text-bluish-grey mb-4">Account Statistics</h3>
            <table className="w-full">
              <thead>
                <tr className="text-left">
                  <th className="pb-4 text-sage">Name</th>
                  <th className="pb-4 text-sage">Today</th>
                  <th className="pb-4 text-sage">MTD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-2 text-bluish-grey">Current Revenue</td>
                  <td className="py-2 text-bluish-grey">€{getSafeValue(stats?.currentRevenue).toFixed(2)}</td>
                  <td className="py-2 text-bluish-grey">€{getSafeValue(stats?.monthlyRevenue).toFixed(2)}</td>
                </tr>
                {['ARR', 'REVPAR'].map((item) => (
                  <tr key={item}>
                    <td className="py-2 text-bluish-grey">{item}</td>
                    <td className="py-2 text-bluish-grey">€0</td>
                    <td className="py-2 text-bluish-grey">€0</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Quick Links section remains unchanged */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h3 className="text-xl font-semibold text-bluish-grey mb-4">Quick Links</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {[
                'CREATE NEW PROPERTY',
                'VIEW ALL PROPERTIES',
                'ARRIVAL REVENUE REPORT',
                'BOOKED REVENUE REPORT',
                'SETTINGS'
              ].map((link) => (
                <button
                  key={link}
                  className="bg-dawn-pink text-watermelon px-4 py-2 rounded-md hover:bg-opacity-90 transition-colors text-sm"
                >
                  {link}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
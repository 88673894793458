import api from './api';

// Function to create a new sales receipt
export const createSalesReceipt = (salesReceiptData) => 
  api.post('/api/salesReceipts', salesReceiptData);

// Function to get all sales receipts
export const getAllSalesReceipts = () => 
  api.get('/api/salesReceipts');

// Function to get a sales receipt by ID
export const getSalesReceiptById = (id) => 
  api.get(`/api/salesReceipts/${id}`);

// Function to update a sales receipt
export const updateSalesReceipt = (id, salesReceiptData) => 
  api.put(`/api/salesReceipts/${id}`, salesReceiptData);

// Function to delete a sales receipt
export const deleteSalesReceipt = (id) => 
  api.delete(`/api/salesReceipts/${id}`);



// import React, { useState, useEffect } from 'react';
// import { getOrderById } from '../../api/restaurantInterface';
// import { useParams } from 'react-router-dom';

// function OrderDetails() {
//   const { id } = useParams();
//   const [order, setOrder] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchOrder = async () => {
//       try {
//         const response = await getOrderById(id);
//         setOrder(response.data);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchOrder();
//   }, [id]);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

//   return (
//     <div className="max-w-3xl mx-auto p-4 bg-white shadow-md rounded-lg">
//       <h1 className="text-2xl font-semibold mb-4">Order Details</h1>
//       {order ? (
//         <>
//           <div className="mb-4">
//             <h2 className="text-xl font-semibold">Order ID: {order.id}</h2>
//             <p><span className="font-semibold">Status:</span> {order.status || 'N/A'}</p>
//             <p><span className="font-semibold">Table:</span> {order.table?.number || 'N/A'}</p>
//             <p><span className="font-semibold">Environment:</span> {order.environment?.name || 'N/A'}</p>
//             <p><span className="font-semibold">Total Price:</span> ${order.totalPrice || 'N/A'}</p>
//           </div>
//           <div className="mb-4">
//             <h3 className="text-lg font-semibold">Dishes:</h3>
//             <ul className="list-disc ml-5">
//               {order.OrderDishSection?.length > 0 ? (
//                 order.OrderDishSection.map((section) => (
//                   <li key={section.id} className="mb-2">
//                     <div className="flex items-center">
//                       {section.dishSection?.imageUrl && (
//                         <img 
//                             // src={section.dishSection.imageUrl}
//                             src={`http://localhost:3001${section.dishSection.imageUrl}`}

//                             alt={section.dishSection.name}
//                             className="w-16 h-16 object-cover mr-4" 
//                         />
//                       )}
//                       <div>
//                         <p>{section.dishSection?.name || 'Unknown Dish'} (Quantity: {section.quantity})</p>
//                         <p>{section.dishSection?.description || ''}</p>
//                       </div>
//                     </div>
//                   </li>
//                 ))
//               ) : (
//                 <li>No dishes available</li>
//               )}
//             </ul>
//           </div>
//           <div className="mb-4">
//             <h3 className="text-lg font-semibold">Payment:</h3>
//             {order.Payment.length > 0 ? (
//               order.Payment.map((payment, index) => (
//                 <p key={index}>Amount: ${payment.amount}</p>
//               ))
//             ) : (
//               <p>No payment information available</p>
//             )}
//           </div>
//         </>
//       ) : (
//         <p>No order details available.</p>
//       )}
//     </div>
//   );
// }

// export default OrderDetails;

import { AiOutlineHeart } from "react-icons/ai";
import { BiShoppingBag } from "react-icons/bi";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// import Rater from "react-rater";
// import "react-rater/lib/react-rater.css";
import React, { useState, useEffect } from 'react';
import { getOrderById } from '../../api/restaurantInterface';
import { useParams } from 'react-router-dom';

const OrderDetails = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await getOrderById(id);
        setOrder(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const baseURL = 'http://localhost:3001'; 

  const truncateDescription = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };


  const images = (order?.OrderDishSection || []).map((section) => ({
    original: section.dishSection?.imageUrl ? `${baseURL}${section.dishSection.imageUrl}` : 'https://via.placeholder.com/600',
    thumbnail: section.dishSection?.imageUrl ? `${baseURL}${section.dishSection.imageUrl}` : 'https://via.placeholder.com/150',
  }));

  const productDetailItem = {
    images,
    title: `Order ID: ${order.id}`,
    // reviews: order.OrderDishSection?.length || 0,
    availability: order.status !== 'cancelled',
    table_number: order.table?.number || 'N/A',
    environment: order.environment?.name || 'N/A',
    sku: `Table: ${order.table?.number || 'N/A'}`,
    price: order.totalPrice,
    previousPrice: order.totalPrice + 50, // example previous price
    description: `Status: ${order.status}`,
  };

  return (
    <section className="border border-black border-2 border-dashed rounded-xl bg-slate-100 container mx-auto max-w-6xl py-5 lg:py-10 px-6 lg:grid lg:grid-cols-2 gap-8">
      {/* Image Gallery */}
      <div className="overflow-hidden rounded-lg shadow-lg">
        <ReactImageGallery
          showBullets={true}
          showFullscreenButton={false}
          showPlayButton={false}
          items={productDetailItem.images}
          additionalClass="rounded-lg"
          thumbnailPosition="bottom"
          lazyLoad
          slideDuration={450}
          renderItem={(item) => (
            <div className="relative w-full h-[500px] lg:h-[600px] bg-gray-100 rounded-lg overflow-hidden">
              <img src={item.original} alt="" className="object-cover w-full h-full" />
            </div>
          )}
        />
      </div>

      {/* Description */}
      <div className="space-y-6 max-h-[80vh] lg:max-h-[90vh] overflow-y-auto">
        <h2 className="text-2xl font-bold">{productDetailItem.title}</h2>

        <p className="font-bold">
          Availability:{" "}
          {productDetailItem.availability ? (
            <span className="text-green-600">In Stock</span>
          ) : (
            <span className="text-red-600">Expired</span>
          )}
        </p>
        <p className="font-bold">
          Table Number: <span className="font-normal">{productDetailItem.table_number}</span>
        </p>
        <p className="font-bold">
          Environment: <span className="font-normal">{productDetailItem.environment}</span>
        </p>
        <p className="font-bold">
          SKU: <span className="font-normal">{productDetailItem.sku}</span>
        </p>
        <p className="text-4xl font-bold text-violet-900">
          ${productDetailItem.price}{" "}
        </p>
        <p className="text-sm leading-5 text-gray-500">
          {productDetailItem.description}
        </p>
        <div>
          <h3 className="text-lg font-semibold">Dishes:</h3>
          <ul className="ml-5">
            {order.OrderDishSection?.length > 0 ? (
              order.OrderDishSection.map((section) => (
                <li key={section.id} className="mb-4">
                  <div className="flex items-center space-x-4">
                    {section.dishSection?.imageUrl && (
                      <img 
                        src={`${baseURL}${section.dishSection.imageUrl}`}
                        alt={section.dishSection.name}
                        className="w-16 h-16 object-cover" />
                    )}
                    <div>
                      <p className="font-semibold text-lg">{section.dishSection?.name || 'Unknown Dish'} (Quantity: {section.quantity})</p>
                      <p>{truncateDescription(section.dishSection?.description || '', 120)}</p>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <li>No dishes available</li>
            )}
          </ul>
        </div>
        {/* <div className="flex flex-col gap-4 lg:flex-row lg:gap-6">
          <button className="flex h-12 w-full items-center justify-center bg-violet-900 text-white rounded-lg hover:bg-violet-800 transition">
            <BiShoppingBag className="mr-2" />
            Add to Cart
          </button>
          <button className="flex h-12 w-full items-center justify-center bg-amber-400 rounded-lg hover:bg-amber-300 transition">
            <AiOutlineHeart className="mr-2" />
            Wishlist
          </button>
        </div> */}
      </div>
    </section>
  );
};

export default OrderDetails;

import React, { useState, useEffect } from 'react';


import { dishTypes } from '../constants/entities';

function Food({ dishSections }) {
  const [foods, setFoods] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);


  useEffect(() => {

    setFoods(dishSections);

    const types = [...new Set(dishSections.map(section => section.type))];
    setUniqueTypes(types);

  }, [dishSections]);

  const filterType = (category) => {
    const filtered = (dishSections || []).filter(
      (section) => section.type === category
    );
    setFoods(filtered);
  };

  const filterPrice = (price) => {
    const filtered = (dishSections || []).filter(
      (section) => section.price === price
    );
    setFoods(filtered);
  };

  const truncateDescription = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <div className='m-auto px-6 py-12'>
      <h1 className='italic text-hazel-green font-bold text-4xl text-center'>
        Our Menu
      </h1>

      {/* Filter Row */}
      <div className='flex flex-col lg:flex-row justify-between'>
        {/* Filter Type */}
        <div>
          <p className='font-bold text-gray-700'>Filter Type</p>
          <div className='flex justify-between flex-wrap'>
            <button
              onClick={() => setFoods(dishSections || [])}
              className='px-2 py-0.5 rounded-lg transition-all duration-300 ease-in-out m-1 border-hazel-green text-hazel-green hover:bg-hazel-green hover:text-white'
            >
              All
            </button>
             {uniqueTypes.map((type) => (
              <button
                key={type}
                onClick={() => filterType(type)}
                className='px-2 py-0.5 rounded-lg transition-all duration-300 ease-in-out m-1 border-hazel-green text-hazel-green hover:bg-hazel-green hover:text-white'
              >
                {type}
              </button>
            ))}

            {/* <button
              onClick={() => filterType('burger')}
              className='px-2 py-0.5 rounded-lg transition-all duration-300 ease-in-out m-1 border-hazel-green text-hazel-green hover:bg-hazel-green hover:text-white'
            >
              Burgers
            </button>
            <button
              onClick={() => filterType('pizza')}
              className='px-2 py-0.5 rounded-lg transition-all duration-300 ease-in-out m-1 border-hazel-green text-hazel-green hover:bg-hazel-green hover:text-white'
            >
              Pizza
            </button>
            <button
              onClick={() => filterType('salad')}
              className='px-2 py-0.5 rounded-lg transition-all duration-300 ease-in-out m-1 border-hazel-green text-hazel-green hover:bg-hazel-green hover:text-white'
            >
              Salads
            </button>
            <button
              onClick={() => filterType('chicken')}
              className='px-2 py-0.5 rounded-lg transition-all duration-300 ease-in-out m-1 border-hazel-green text-hazel-green hover:bg-hazel-green hover:text-white'
            >
              Chicken
            </button> */}
          </div>
        </div>

      </div>

      <div className='grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4'>
        {foods.map((section) => (
          <div
          key={section.id}
          className='border shadow-lg rounded-lg hover:scale-105 duration-300'
        >
          <img
            src={
              section.imageUrl
                ? `http://localhost:3001${section.imageUrl}`
                : '/images/default-placeholder.png'
            }
            alt={section.name || 'Dish Section'}
            className='w-full h-[200px] object-cover rounded-t-lg'
          />
          <div className='flex flex-col px-4 py-4 gap-2'>
            <p className='font-bold text-xl'>{section.name}</p>
            <p className='text-gray-600 text-sm'>{truncateDescription(section.description, 70)}</p>
            <p className='text-gray-600 text-sm'><strong>Type:</strong> {section.type}</p>
            <p className='text-gray-600 text-sm'><strong>Available:</strong> {section.isAvailable ? 'Yes' : 'No'}</p>
            <p className='font-bold'>Menu: {section.menu.name}</p>
            <p>
              <span className='bg-hazel-green text-white p-1 px-4 rounded-full'>
                {section.price}$
              </span>
            </p>
          </div>
        </div>
        ))}
      </div>
    </div>
  );
}

export default Food;
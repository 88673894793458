import React from 'react';
import { Trash2 } from 'lucide-react';

interface Item {
    id: number;
    name: string;
    sellingPrice: number;
  }
  
  interface ItemRow {
    id: string;
    details: string;
    quantity: number;
    rate: number;
    discount: number;
    tax: string;
    amount: number;
    itemId?: number;
  }
  

  interface ItemTableProps {
    items: ItemRow[];
    availableItems: Item[]; // Change this line
    onItemChange: (items: ItemRow[]) => void;
    onRowSelect: (index: number) => void;
}
  

export function ItemTable({ items, onItemChange, onRowSelect }: ItemTableProps) {
  const [isItemModalOpen, setIsItemModalOpen] = React.useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(0);

  const handleItemSelect = (selectedItem: any) => {
    const newItems = [...items];
    newItems[selectedRowIndex] = {
      ...newItems[selectedRowIndex],
      details: selectedItem.name,
      rate: selectedItem.sellingPrice,
      amount: selectedItem.sellingPrice * newItems[selectedRowIndex].quantity,
      itemId: selectedItem.id,
    };
    onItemChange(newItems);
    setIsItemModalOpen(false);
  };

  const handleUpdateItem = (index: number, field: keyof ItemRow, value: any) => {
    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      [field]: value,
    };

    if (field === 'quantity' || field === 'rate' || field === 'discount') {
      const quantity = field === 'quantity' ? value : newItems[index].quantity;
      const rate = field === 'rate' ? value : newItems[index].rate;
      const discount = field === 'discount' ? value : newItems[index].discount;
      newItems[index].amount = quantity * rate * (1 - discount / 100);
    }

    onItemChange(newItems);
  };

  const handleDeleteItem = (index: number) => {
    onItemChange(items.filter((_, i) => i !== index));
  };

  const handleAddRow = () => {
    onItemChange([
      ...items,
      {
        id: `${Date.now()}`,
        details: '',
        quantity: 1.0,
        rate: 0.0,
        discount: 0,
        tax: '',
        amount: 0.0,
      },
    ]);
  };

  return (
    <div className="space-y-4">
      <div className="border border-grey-goose rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full min-w-[800px]">
            <thead className="bg-gray-50">
              <tr>
                <th className="text-left p-4 text-sm font-medium text-bluish-grey">ITEM DETAILS</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-24">QUANTITY</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-32">RATE</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-24">DISCOUNT</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-32">TAX</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-32">AMOUNT</th>
                <th className="w-16"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-grey-goose">
              {items.map((item, index) => (
                <tr key={item.id} onClick={() => onRowSelect(index)}>
                  <td className="p-4">
                    <input
                      type="text"
                      value={item.details}
                      readOnly
                      placeholder="Click to select an item"
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage cursor-pointer"
                    />
                  </td>
                  <td className="p-4">
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleUpdateItem(index, 'quantity', Number(e.target.value))}
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage text-right"
                    />
                  </td>
                  <td className="p-4">
                    <input
                      type="number"
                      value={item.rate}
                      onChange={(e) => handleUpdateItem(index, 'rate', Number(e.target.value))}
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage text-right"
                    />
                  </td>
                  <td className="p-4">
                    <input
                      type="number"
                      value={item.discount}
                      onChange={(e) => handleUpdateItem(index, 'discount', Number(e.target.value))}
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage text-right"
                    />
                  </td>
                  <td className="p-4">
                    <select
                      value={item.tax}
                      onChange={(e) => handleUpdateItem(index, 'tax', e.target.value)}
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage"
                    >
                      <option value="">No Tax</option>
                      <option value="VAT@20">VAT@20%</option>
                    </select>
                  </td>
                  <td className="p-4 text-right text-sm text-bluish-grey font-medium">
                    €{item.amount.toFixed(2)}
                  </td>
                  <td className="p-4">
                    <button
                      onClick={() => handleDeleteItem(index)}
                      className="text-red-500 hover:text-red-600 transition-colors"
                    >
                      <Trash2 size={16} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex gap-2">
        <button
          type="button"
          onClick={handleAddRow}
          className="text-blue-500 hover:text-blue-600 text-sm px-4 py-2 bg-blue-50 rounded-md transition-colors"
        >
          + Add New Row
        </button>
      </div>
    </div>
  );
}
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import VenueMenuForm from './VenueMenuForm';

function VenueMenuFormContainer() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={
            <FontAwesomeIcon icon="fa-solid fa-utensils" className='text-hazel-green mr-2'/>
        }
        title='Create the menu'
      />
      <VenueMenuForm
        // isEdit={false}
      />
    </div>
  );
}

export default VenueMenuFormContainer;

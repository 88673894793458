import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';

import { getMenuById, updateMenu } from '../../api/conferenceRoomMenu';
import { getAllConferenceRooms } from '../../api/conferenceRoom';
import { getBarProducts } from '../../api/barProduct';
import { getDishSections } from '../../api/dishSection';

import CustomInput from '../../components/custom-input';
import SelectInput from '../../components/select-input';
import Loading from '../../components/loader';
import { CancelButton, SubmitButton } from '../../components/buttons';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function EditConferenceRoomMenuForm() {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the menu ID from the URL
  const [loading, setLoading] = useState(true);
  const [conferenceRooms, setConferenceRooms] = useState([]);
  const [dishSections, setDishSections] = useState([]);
  const [barProductOptions, setBarProductOptions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: '',
    conferenceRoomId: '',
    price: 0,
    breakfastItems: [],
    lunchItems: [],
    dinnerItems: [],
    breakfastBarProducts: [],
    lunchBarProducts: [],
    dinnerBarProducts: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [menuResponse, roomsResponse, dishesResponse, productsResponse] = await Promise.all([
          getMenuById(id),
          getAllConferenceRooms(),
          getDishSections(),
          getBarProducts()
        ]);

        const menuData = menuResponse.data;
        setConferenceRooms(roomsResponse.data);
        setDishSections(dishesResponse.data);
        setBarProductOptions(productsResponse.data);

        // Map the initial values correctly based on the menu relations
        setInitialValues({
          name: menuData.name,
          conferenceRoomId: menuData.conferenceRoomId.toString(),
          price: menuData.price,
          // Regular menu items - map from their specific relations
          breakfastItems: menuData.breakfastItems?.map(item => ({
            id: item.itemId,
            name: item.name,
            description: item.description,
            price: item.price,
            type: 'BREAKFAST',
            isBarProduct: false
          })) || [],
          lunchItems: menuData.lunchItems?.map(item => ({
            id: item.itemId,
            name: item.name,
            description: item.description,
            price: item.price,
            type: 'LUNCH',
            isBarProduct: false
          })) || [],
          dinnerItems: menuData.dinnerItems?.map(item => ({
            id: item.itemId,
            name: item.name,
            description: item.description,
            price: item.price,
            type: 'DINNER',
            isBarProduct: false
          })) || [],
          // Bar products - map from their specific relations
          breakfastBarProducts: menuData.breakfastBarItems?.map(item => ({
            id: item.barProductId,
            name: item.name,
            description: item.description,
            price: item.price,
            type: 'BREAKFAST',
            isBarProduct: true
          })) || [],
          lunchBarProducts: menuData.lunchBarItems?.map(item => ({
            id: item.barProductId,
            name: item.name,
            description: item.description,
            price: item.price,
            type: 'LUNCH',
            isBarProduct: true
          })) || [],
          dinnerBarProducts: menuData.dinnerBarItems?.map(item => ({
            id: item.barProductId,
            name: item.name,
            description: item.description,
            price: item.price,
            type: 'DINNER',
            isBarProduct: true
          })) || []
        });
      } catch (error) {
        console.error('Failed to fetch data:', error);
        toast.error('Failed to load menu data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleSubmit = async (values) => {
    try {
      const updatedData = {
        ...values,
        breakfastItems: values.breakfastItems.map(item => ({
          ...item,
          type: 'BREAKFAST',
          isBarProduct: false
        })),
        lunchItems: values.lunchItems.map(item => ({
          ...item,
          type: 'LUNCH',
          isBarProduct: false
        })),
        dinnerItems: values.dinnerItems.map(item => ({
          ...item,
          type: 'DINNER',
          isBarProduct: false
        })),
        breakfastBarProducts: values.breakfastBarProducts.map(item => ({
          ...item,
          type: 'BREAKFAST',
          isBarProduct: true
        })),
        lunchBarProducts: values.lunchBarProducts.map(item => ({
          ...item,
          type: 'LUNCH',
          isBarProduct: true
        })),
        dinnerBarProducts: values.dinnerBarProducts.map(item => ({
          ...item,
          type: 'DINNER',
          isBarProduct: true
        }))
      };

      await updateMenu(id, updatedData);
      console.log(updatedData)
      toast.success('Menu updated successfully');
      navigate('/conferenceRoom');
    } catch (error) {
      console.error('Error updating menu:', error);
      toast.error('Failed to update menu');
    }
  };

  if (loading) {
    return <Loading />;
  }

  const mealTypes = [
    {
      title: 'Breakfast Menu',
      items: 'breakfastItems',
      barProducts: 'breakfastBarProducts',
      type: 'BREAKFAST'
    },
    {
      title: 'Lunch Menu',
      items: 'lunchItems',
      barProducts: 'lunchBarProducts',
      type: 'LUNCH'
    },
    {
      title: 'Dinner Menu',
      items: 'dinnerItems',
      barProducts: 'dinnerBarProducts',
      type: 'DINNER'
    }
  ];

  return (
    <div className='bg-white border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Menu name is required'),
          conferenceRoomId: Yup.string().required('Conference room is required'),
          price: Yup.number().min(0, 'Price must be non-negative'),
          breakfastItems: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Dish section is required')
            })
          ),
          lunchItems: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Dish section is required')
            })
          ),
          dinnerItems: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Dish section is required')
            })
          ),
          breakfastBarProducts: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Bar product is required')
            })
          ),
          lunchBarProducts: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Bar product is required')
            })
          ),
          dinnerBarProducts: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Bar product is required')
            })
          )
        })}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
              <CustomInput
                type='text'
                name='name'
                label='Menu Name'
                value={values.name}
                onChange={(e) => setFieldValue('name', e.target.value)}
              />
              <SelectInput
                name='conferenceRoomId'
                label='Conference Room'
                value={values.conferenceRoomId}
                options={conferenceRooms.map(room => ({
                  id: room.id,
                  title: room.name
                }))}
                onChange={(e) => setFieldValue('conferenceRoomId', e.target.value)}
                showError={false}
              />
              <CustomInput
                type='number'
                name='price'
                label='Menu Price'
                value={values.price}
                onChange={(e) => setFieldValue('price', parseFloat(e.target.value) || 0)}
              />
            </div>

            <div className="mt-8 space-y-8">
              {mealTypes.map(({ title, items, barProducts, type }) => (
                <div key={title} className="mb-8">
                  <h3 className="text-lg font-semibold mb-4">{title}</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Dish Sections */}
                    <div>
                      <h4 className="font-medium mb-2">Menu Items</h4>
                      <FieldArray
                        name={items}
                        render={arrayHelpers => (
                          <div className="space-y-4">
                            {values[items]?.filter(item => item.id).map((item, index) => (
                              <div key={index} className='bg-white border border-gray-200 rounded-lg p-4 shadow-sm'>
                                <SelectInput
                                  name={`${items}.${index}.id`}
                                  label='Dish Section'
                                  value={item.id || ''}
                                  options={dishSections.map(dish => ({
                                    id: dish.id,
                                    title: dish.name,
                                  }))}
                                  onChange={(e) => {
                                    const selectedDish = dishSections.find(dish => dish.id === e.target.value);
                                    if (selectedDish) {
                                      setFieldValue(`${items}.${index}`, {
                                        id: selectedDish.id,
                                        name: selectedDish.name,
                                        description: selectedDish.description,
                                        price: selectedDish.price,
                                        type: type,
                                        isBarProduct: false
                                      });
                                    }
                                  }}
                                />
                                <button
                                  type="button"
                                  className="mt-2 text-red-600"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            ))}
                            <button
                              type='button'
                              className='bg-hazel-green text-white px-4 py-2 rounded-md hover:bg-sage transition duration-150'
                              onClick={() => arrayHelpers.push({ id: '' })}
                            >
                              Add Menu Item
                            </button>
                          </div>
                        )}
                      />
                    </div>

                    {/* Bar Products */}
                    <div>
                      <h4 className="font-medium mb-2">Bar Products</h4>
                      <FieldArray
                        name={barProducts}
                        render={arrayHelpers => (
                          <div className="space-y-4">
                            {(values[barProducts] || []).map((item, index) => (
                              <div key={index} className='bg-white border border-gray-200 rounded-lg p-4 shadow-sm'>
                                <SelectInput
                                  name={`${barProducts}.${index}.id`}
                                  label='Bar Product'
                                  value={item.id}
                                  options={barProductOptions.map(product => ({
                                    id: product.id,
                                    title: product.name,
                                  }))}
                                  onChange={(e) => {
                                    const selectedProduct = barProductOptions.find(product => product.id === e.target.value);
                                    if (selectedProduct) {
                                      setFieldValue(`${barProducts}.${index}`, {
                                        id: selectedProduct.id,
                                        name: selectedProduct.name,
                                        description: selectedProduct.description,
                                        price: selectedProduct.price,
                                        type: type,
                                        isBarProduct: true
                                      });
                                    }
                                  }}
                                />
                                <button
                                  type="button"
                                  className="mt-2 text-red-600"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            ))}
                            <button
                              type='button'
                              className='bg-hazel-green text-white px-4 py-2 rounded-md hover:bg-sage transition duration-150'
                              onClick={() => arrayHelpers.push({ id: '' })}
                            >
                              Add Bar Product
                            </button>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className='flex justify-end items-center w-full mt-8 text-sm'>
              <CancelButton onCancel={() => navigate('/conferenceRoom')} />
              <SubmitButton width='w-1/2 md:w-1/4' title={'Update Menu'} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditConferenceRoomMenuForm;
import React from 'react';
import { IconMoneybag } from '@tabler/icons-react'; // Assuming you have a money bag icon
import PayrollForm from './PayrollForm';
import PageTitle from '../../../../../components/page-title';

function AddPayroll() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconMoneybag className='text-hazel-green mr-2' size={22} />}
        title='Add New Payroll'
      />
      <PayrollForm isEdit={false} />
    </div>
  );
}

export default AddPayroll;

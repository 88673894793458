import React from 'react';
import { FileText, CheckCircle, XCircle, AlertTriangle, User } from 'lucide-react';

import { Card } from "../../../../shadCN/Card.tsx";

interface FinalReportProps {
  roomNumber: string;
  fromDate: string;
  toDate: string;
  guestName: string;
  cleaningReport?: {
    roomCondition: string;
    cleanlinessLevel: string;
    issuesDescription: string;
    additionalNotes: string;
  };
}


export default function FinalReport({
  roomNumber,
  fromDate,
  toDate,
  guestName,
  cleaningReport
}: FinalReportProps) {

  console.log("Passed cleaning reprt to the final reportCleaning Report:", cleaningReport);

  const getRoomStatusIcon = (condition: string) => {
    switch (condition) {
      case 'excellent':
      case 'good':
        return <CheckCircle className="text-green-500" size={18} />;
      case 'fair':
        return <AlertTriangle className="text-yellow-500" size={18} />;
      case 'poor':
      case 'maintenance':
        return <XCircle className="text-red-500" size={18} />;
      default:
        return <AlertTriangle className="text-yellow-500" size={18} />;
    }
  };


  return (
    <div className="space-y-6">
      <div className="bg-seashell rounded-lg p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <FileText className="text-hazel-green" size={24} />
            <h3 className="text-lg font-semibold text-hazel-green">Checkout Summary Report</h3>
          </div>
          <span className="px-3 py-1 bg-hazel-green/10 text-hazel-green rounded-full text-sm">
            Room {roomNumber}
          </span>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div className="space-y-4">



              {/*This is the other version of the guest details is it good*/}

              <Card className="p-4 bg-white">
                <div className="flex items-center gap-3 mb-4">
                  <User className="h-5 w-5 text-bluish-grey" />
                  <h3 className="font-medium">Guest Details</h3>
                </div>
                <div className="space-y-3">
                  <div>
                    <p className="text-sm text-bluish-grey my-2">Name</p>
                    <p className="text-xs font-medium">
                      {guestName}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-bluish-grey my-2">Time:</p>
                    <p className="text-xs mb-2">
                      <span className="font-medium mx-4">Check-in:</span> {fromDate ? new Date(fromDate).toLocaleDateString("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }) + ' ' + new Date(fromDate).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' }) : ""}
                    </p>
                    <p className="text-xs">
                      <span className="font-medium mx-4">Check-out:</span> {toDate ? new Date(toDate).toLocaleDateString("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }) + ' ' + new Date(toDate).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' }) : ""}
                    </p>
                  </div>

                </div>
              </Card>
              <div>
              <h4 className="text-sm font-medium text-bluish-grey mb-2">Room Status</h4>
              <div className="space-y-2">
                <div className="flex items-center justify-between bg-white p-3 rounded-lg border border-grey-goose">
                  <span className="text-sm">Room Condition</span>
                  {getRoomStatusIcon(cleaningReport?.roomCondition || '')}
                </div>
                <div className="flex items-center justify-between bg-white p-3 rounded-lg border border-grey-goose">
                  <span className="text-sm">Cleanliness Level</span>
                  {getRoomStatusIcon(cleaningReport?.cleanlinessLevel || '')}
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <h4 className="text-sm font-medium text-bluish-grey mb-2">Financial Summary</h4>
              <div className="bg-white rounded-lg p-4 border border-grey-goose space-y-2">
                <div className="flex justify-between text-sm">
                  <span>Room Charges</span>
                  <span className="font-medium">$500.00</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span>Extra Services</span>
                  <span className="font-medium">$150.00</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span>Minibar</span>
                  <span className="font-medium">$45.00</span>
                </div>
                <div className="border-t border-grey-goose pt-2 flex justify-between text-sm font-medium">
                  <span>Total</span>
                  <span>$695.00</span>
                </div>
              </div>
            </div>

            <div>
              <h4 className="text-sm font-medium text-bluish-grey mb-2">Notes & Comments</h4>
              <textarea
                rows={4}
                className="w-full px-3 py-2 text-sm rounded-lg border border-grey-goose focus:ring-1 focus:ring-hazel-green"
                placeholder="Add any final notes or comments..."
                value={cleaningReport?.additionalNotes || ''}

              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center pt-4 border-t border-grey-goose">
        <div className="text-sm text-bluish-grey">
          Generated on {new Date().toLocaleDateString()}
        </div>
        <div className="space-x-3">
          <button className="px-4 py-2 text-sm border border-hazel-green text-hazel-green rounded-md hover:bg-hazel-green/5 transition-colors">
            Download Report
          </button>
          <button className="px-4 py-2 text-sm bg-hazel-green text-white rounded-md hover:bg-sage transition-colors">
            Complete Checkout
          </button>
        </div>
      </div>
    </div>
  );
}
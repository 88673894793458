import React from 'react';

const Navigation = () => {
  const links = ['Default Dashboard', 'Getting Started', 'Announcements', 'Recent Updates'];

  return (
    <nav className="bg-white border-b border-mercury">
      <div className="container mx-auto px-4">
        <div className="flex space-x-8">
          {links.map((link, index) => (
            <button
              key={link}
              className={`py-4 px-1 mx-4 border-b-2 font-medium transition-colors ${
                index === 0
                  ? 'border-hazel-green text-hazel-green'
                  : 'border-transparent text-bluish-grey hover:text-hazel-green hover:border-grey-goose'
              }`}
            >
              {link}
            </button>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
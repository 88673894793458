import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../../../components/loader';
import { SubmitButton, CancelButton } from '../../../../../components/buttons';
import CustomInput from '../../../../../components/custom-input';
import { createClient, updateClient } from '../../../../../api/accounting';
import { scrapeBusinessData } from '../../../../../api/scraperBusinesses'; 

function ClientForm({ isEdit, client }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showFullForm, setShowFullForm] = useState(false);

  const validationSchema = Yup.object().shape({
    ubin: Yup.string().length(9, 'UBIN must be 9 digits').required('UBIN is required*'),
    name: Yup.string().when('showFullForm', {
      is: true,
      then: Yup.string().min(3).required('Client name is required*'),
    }),
    email: Yup.string().when('showFullForm', {
      is: true,
      then: Yup.string().email('Invalid email format').required('Email is required*'),
    }),
    phone: Yup.string().when('showFullForm', {
      is: true,
      then: Yup.string().required('Phone number is required*'),
    }),
    address: Yup.string().when('showFullForm', {
      is: true,
      then: Yup.string().required('Address is required*'),
    }),
    representative: Yup.string().when('showFullForm', {
      is: true,
      then: Yup.string().required('Representative is required*'),
    }),
  });

  // Display loading spinner if form is in a loading state
  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
      <div className="flex justify-between rounded-t-xl items-center p-5 bg-seashell border-b">
        <h3 className="text-lg font-semibold text-hazel-green">
          {isEdit ? 'Edit Client' : 'Create Client'}
        </h3>
        <button
          className="text-sage hover:text-hazel-green focus:outline-none"
          onClick={() => setShowFullForm(!showFullForm)}
        >
          {showFullForm ? 'Switch to UBIN Only' : 'Switch to Full Form'}
        </button>
      </div>

      {/* If anyone is reading this, dont even try to understand anything this is madnedss that wil never end and we willl always suffer, please never become a programmer */}
      
      <Formik
        initialValues={client || {
          ubin: '',
          name: '',
          email: '',
          phone: '',
          address: '',
          representative: ''
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting, setFieldValue }) => {
          setLoading(true);

          try {
            // Check if we're in UBIN-only mode
            if (!isEdit && !showFullForm) {
              const scrapedResult = await scrapeBusinessData(values.ubin);
              
              if (scrapedResult && scrapedResult.data && scrapedResult.data.length > 0) {
                const businessData = scrapedResult.data.reduce((acc, row) => {
                  const [label, value] = row;
                  acc[label] = value;
                  return acc;
                }, {});

                // Auto-fill form fields based on scraped data
                setFieldValue('name', businessData['Emri i biznesit'] || values.name);
                setFieldValue('email', businessData['E-mail'] || values.email);
                setFieldValue('phone', businessData['Telefoni'] || values.phone);
                setFieldValue('address', businessData['Adresa'] || values.address);
                setFieldValue('representative', businessData['Përfaqësuesi'] || values.representative);

                // Automatically submit the form to create a new client
                await createClient({
                  ubin: values.ubin,
                  name: businessData['Emri i biznesit'],
                  email: businessData['E-mail'],
                  phone: businessData['Telefoni'],
                  address: businessData['Adresa'],
                  representative: businessData['Përfaqësuesi'],
                });
                
                navigate('/clients');
              } else {
                setErrors({ ubin: 'No business data found for this UBIN' });
                setSubmitting(false);
                setLoading(false);
                return;
              }
            }

            if (isEdit || showFullForm) {
              if (isEdit) {
                await updateClient(client.id, values);
              } else {
                await createClient(values);
                navigate('/clients');
              }
            }

            setSubmitting(false);
          } catch (err) {
            setErrors({
              submit: err?.response?.data || 'An error occurred. Please try again.'
            });
            setSubmitting(false);
          } finally {
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue // Added to programmatically set form fields
        }) => (
          <form onSubmit={handleSubmit} className='px-7 py-5'>
            <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
              {/* UBIN Field: Always visible */}
              <CustomInput
                type='text'
                name='ubin'
                label='UBIN'
                value={values.ubin}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.ubin && touched.ubin}
                errorMessage={errors.ubin}
              />

              {/* Conditionally render the full form based on the showFullForm state */}
              {showFullForm && (
                <>
                  <CustomInput
                    type='text'
                    name='name'
                    label='Client Name'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.name && touched.name}
                    errorMessage={errors.name}
                  />
                  <CustomInput
                    type='email'
                    name='email'
                    label='Email'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.email && touched.email}
                    errorMessage={errors.email}
                  />
                  <CustomInput
                    type='text'
                    name='phone'
                    label='Phone Number'
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.phone && touched.phone}
                    errorMessage={errors.phone}
                  />
                  <CustomInput
                    type='text'
                    name='address'
                    label='Address'
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.address && touched.address}
                    errorMessage={errors.address}
                  />
                  <CustomInput
                    type='text'
                    name='representative'
                    label='Representative'
                    value={values.representative}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.representative && touched.representative}
                    errorMessage={errors.representative}
                  />
                </>
              )}
            </div>

            {errors.submit && (
              <p className='text-red-500 text-sm mt-2'>{errors.submit}</p>
            )}

            <div className='flex justify-end gap-x-2'>
              <CancelButton
                type='button'
                title='Cancel'
                onClick={() => navigate('/clients')}
              />
              <SubmitButton
                type='submit'
                title={isEdit ? 'Update Client' : 'Create Client'}
                disabled={isSubmitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ClientForm;
// import { motion } from 'framer-motion';
// import React from 'react';

// interface Invoice {
//   id: string;
//   customer: string;
//   date: string;
//   amount: string;
//   status: 'DRAFT' | 'PENDING' | 'PAID';
//   orderNumber: string;
// }

// const invoices: Invoice[] = [
//   {
//     id: 'INV-000001',
//     customer: 'sdsjkfsd,f',
//     date: '27 Oct 2024',
//     amount: '€0,00',
//     status: 'DRAFT',
//     orderNumber: '1'
//   },
//   {
//     id: 'INV-000002',
//     customer: 'Tech Solutions Inc',
//     date: '26 Oct 2024',
//     amount: '€2,500.00',
//     status: 'PENDING',
//     orderNumber: '2'
//   },
//   {
//     id: 'INV-000003',
//     customer: 'Global Systems Ltd',
//     date: '25 Oct 2024',
//     amount: '€1,800.00',
//     status: 'PAID',
//     orderNumber: '3'
//   }
// ];

// interface InvoiceSidebarProps {
//   selectedInvoiceId: string;
//   onInvoiceSelect: (id: string) => void;
// }

// export function InvoiceSidebar({ selectedInvoiceId, onInvoiceSelect }: InvoiceSidebarProps) {
//   return (
//     <div className="divide-y divide-mercury">
//       {invoices.map((invoice) => (
//         <motion.button
//           key={invoice.id}
//           onClick={() => onInvoiceSelect(invoice.id)}
//           className={`w-full p-4 text-left hover:bg-seashell transition-colors ${
//             selectedInvoiceId === invoice.id ? 'bg-seashell' : ''
//           }`}
//           whileTap={{ scale: 0.98 }}
//         >
//           <div className="flex items-center gap-3 mb-2">
//             <input
//               type="checkbox"
//               className="rounded border-mercury"
//               onClick={(e) => e.stopPropagation()}
//             />
//             <span className="font-medium text-hazel-green">{invoice.customer}</span>
//           </div>
//           <div className="flex items-center text-sm text-bluish-grey gap-2">
//             <span>{invoice.id}</span>
//             <span>•</span>
//             <span>{invoice.date}</span>
//             <span>•</span>
//             <span>{invoice.orderNumber}</span>
//           </div>
//           <div className="flex items-center justify-between mt-2">
//             <span className="text-sm font-medium text-bluish-grey">{invoice.amount}</span>
//             <span className={`text-xs px-2 py-1 rounded-full ${
//               invoice.status === 'DRAFT'
//                 ? 'bg-[#EDF2F7] text-[#2D3748]'
//                 : invoice.status === 'PENDING'
//                 ? 'bg-[#FFF4E5] text-[#663C00]'
//                 : 'bg-[#EDF7ED] text-[#1E4620]'
//             }`}>
//               {invoice.status}
//             </span>
//           </div>
//         </motion.button>
//       ))}
//     </div>
//   );
// }

import { motion } from 'framer-motion';
import React from 'react';

interface Invoice {
  id: string;
  customer: string;
  date: string;
  amount: string;
  status: 'DRAFT' | 'PENDING' | 'PAID';
  orderNumber: string;
}

const invoices: Invoice[] = [
  {
    id: 'INV-000001',
    customer: 'sdsjkfsd,f',
    date: '27 Oct 2024',
    amount: '€0,00',
    status: 'DRAFT',
    orderNumber: '1'
  },
  {
    id: 'INV-000002',
    customer: 'Tech Solutions Inc',
    date: '26 Oct 2024',
    amount: '€2,500.00',
    status: 'PENDING',
    orderNumber: '2'
  },
  {
    id: 'INV-000003',
    customer: 'Global Systems Ltd',
    date: '25 Oct 2024',
    amount: '€1,800.00',
    status: 'PAID',
    orderNumber: '3'
  }
];

interface InvoiceSidebarProps {
  selectedInvoiceId: string;
  onInvoiceSelect: (id: string) => void;
}

export function InvoiceSidebar({ selectedInvoiceId, onInvoiceSelect }: InvoiceSidebarProps) {
  return (
    <div className="divide-y divide-mercury">
      {invoices.map((invoice) => (
        <motion.button
          key={invoice.id}
          onClick={() => onInvoiceSelect(invoice.id)}
          className={`w-full p-4 text-left hover:bg-seashell transition-colors ${
            selectedInvoiceId === invoice.id ? 'bg-seashell' : ''
          }`}
          whileTap={{ scale: 0.98 }}
        >
          <div className="flex items-center gap-3 mb-2">
            <input
              type="checkbox"
              className="rounded border-mercury"
              onClick={(e) => e.stopPropagation()}
            />
            <span className="font-medium text-hazel-green">{invoice.customer}</span>
          </div>
          <div className="flex items-center text-sm text-bluish-grey gap-2">
            <span>{invoice.id}</span>
            <span>•</span>
            <span>{invoice.date}</span>
            <span>•</span>
            <span>{invoice.orderNumber}</span>
          </div>
          <div className="flex items-center justify-between mt-2">
            <span className="text-sm font-medium text-bluish-grey">{invoice.amount}</span>
            <span className={`text-xs px-2 py-1 rounded-full ${
              invoice.status === 'DRAFT'
                ? 'bg-[#EDF2F7] text-[#2D3748]'
                : invoice.status === 'PENDING'
                ? 'bg-[#FFF4E5] text-[#663C00]'
                : 'bg-[#EDF7ED] text-[#1E4620]'
            }`}>
              {invoice.status}
            </span>
          </div>
        </motion.button>
      ))}
    </div>
  );
}

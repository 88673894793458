import React, { useState, useRef } from 'react';
import Draggable from "react-draggable";
import { IconRepeat, IconTrash, IconPencil, IconCirclePlus, IconX, IconLink, IconUnlink } from '@tabler/icons-react';
import { deleteTable } from '../../api/restaurantInterface';
import { useNavigate } from 'react-router-dom';

function Table({ table, onDelete, onMerge, onUnmerge, isAssigned, position, onMove }) {
    const navigate = useNavigate();
    const [isDragging, setIsDragging] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const dragStartPosRef = useRef({ x: 0, y: 0 });
    const isMerged = table.mergedFromIds && JSON.parse(table.mergedFromIds).length > 1;

    // Utility functions remain the same
    const getResponsiveClasses = () => {
        return 'w-32 h-32 sm:w-36 sm:h-36';
    };

    const getTableColor = () => {
        if (table.isStaffTable) return 'bg-purple-500';
        if (isMerged) return 'bg-blue-500';
        return table.isAvailable ? 'bg-green-500' : 'bg-red-500';
    };

    const handleTableClick = (e) => {
        if (isDragging) return;
        const tableWrapper = e.target.closest('#table-wrapper');
        const managementSection = e.target.closest('.table-management');
        if (tableWrapper && !managementSection) {
            navigate(`/restaurant-interface/order/${table.id}`);
        }
    };

    const onStart = () => {
        setIsDragging(false);
        dragStartPosRef.current = { x: 0, y: 0 };
    };

    const onDrag = (e, data) => {
        const dx = data.x - dragStartPosRef.current.x;
        const dy = data.y - dragStartPosRef.current.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance > 5) {
            setIsDragging(true);
        }
    };

    const onStop = (e, data) => {
        if (isDragging) {
            setTimeout(() => {
                onMerge(table, data);
                onMove?.(table.id, { x: data.x, y: data.y });
            }, 50);
        } else {
            handleTableClick(e);
        }
        setIsDragging(false);
    };

    return (
        <Draggable
            defaultPosition={position}
            onStart={onStart}
            onDrag={onDrag}
            onStop={onStop}
            bounds="parent"
            grid={[20, 20]}
        >
            <div className={`absolute ${isDragging ? 'z-50 dragging' : 'z-0'} ${isMerged ? 'merged-table' : ''}`}>
                {isMerged && (
                    <div className="mb-2 flex items-center gap-2 p-1 bg-blue-100 text-blue-800 text-xs sm:text-sm rounded-full shadow-md">
                        <IconLink size={16} className="text-blue-500" />
                        <span>Merged</span>
                        <button 
                            className="bg-red-200 text-red-600 rounded-full px-1 sm:px-2 py-0.5 text-xs ml-2 flex items-center gap-1" 
                            onClick={(e) => {
                                e.stopPropagation();
                                onUnmerge(table.id);
                            }}
                        >
                            <IconUnlink size={14} /> Unmerge
                        </button>
                    </div>
                )}
                <div 
                    id="table-wrapper"
                    data-table-id={table.id}
                    className={`${getResponsiveClasses()} ${getTableColor()} rounded-lg shadow-lg 
                        flex items-center justify-center cursor-move relative transition-all duration-200
                        ${isAssigned ? 'opacity-100' : 'opacity-70'}`}
                >
                    <div className="transform rotate-90 flex flex-col items-center justify-between p-2 sm:p-3">
                        <div className="text-xs sm:text-sm text-white font-semibold truncate max-w-full">
                            Table {table.number}
                            {isMerged && ' (M)'}
                        </div>
                        <div className="text-2xl sm:text-4xl text-white font-bold">{table.number}</div>
                        <div className="w-full flex justify-between items-center text-xs sm:text-sm">
                            <span className="text-white truncate">Seats: {table.capacity}</span>
                            {isMerged && (
                                <IconRepeat
                                    size={18}
                                    className="text-white cursor-pointer hover:text-gray-200"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onUnmerge(table.id);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Draggable>
    );
}

export default Table;


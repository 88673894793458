import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import BookingSearch from '../../components/hotelInterfaceComponents/damageComponents/BookingSearch.tsx';
import { mockBookings } from '../../components/data/damagesData.ts';
import Navbar from '../../components/hotelInterfaceComponents/Navbar.tsx';


export default function NewDamage() {
  const navigate = useNavigate();

  const handleBookingSelect = (booking: any) => {
    // Handle booking selection
    console.log('Selected booking:', booking);
  };

  return (
    <div className="min-h-screen bg-tealish-blue">
        <Navbar />
      <div className="max-w-7xl mx-auto my-6">
        <div className="flex items-center gap-4 mb-8">
          <button
            onClick={() => navigate('/hotelInterface/damages')}
            className="flex items-center gap-2 text-hazel-green hover:text-sage transition-colors"
          >
            <ArrowLeft className="h-5 w-5" />
            Back to Damages
          </button>
          <h1 className="text-2xl font-bold text-hazel-green">Damage Tracker</h1>
        </div>

        <BookingSearch 
          bookings={mockBookings}
          onSelect={handleBookingSelect}
        />
      </div>
    </div>
  );
}
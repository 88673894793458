import axios from 'axios';

// const BASE_URL = 'http://3.79.80.215';
const BASE_URL = "https://api-staff.lakesideks.com"
// const BASE_URL = "http://localhost:3001"

// export const login = (email, password) =>
//   axios.post(`${BASE_URL}/api/auth/login`, { email, password });



// Update login function to handle passcode response
// export const login = async (email, password, passcode = '') => {
//   try {
//     const response = await axios.post(`${BASE_URL}/api/auth/login-passcode`, { email, password, passcode });
//     if (response.data.passcode) {
//       return { token: response.data.token, passcode: response.data.passcode };
//     }
//     return { token: response.data };
//   } catch (error) {
//     throw new Error(error.response?.data.message || 'Login failed');
//   }
// };


export const login = (email, password, ownerPasscode = '') =>
  axios.post(`${BASE_URL}/api/auth/login`, { email, password, ownerPasscode });



export const forgotPassword = (values) =>
  axios.post(`${BASE_URL}/api/auth/forgot-password`, values);

export const resetPassword = (values) =>
  axios.post(`${BASE_URL}/api/auth/reset-password`, values);

export const getLatestOwnerPasscode = () =>
  axios.get(`${BASE_URL}/api/auth/latest-passcode`);


export const logout = (token) =>
  axios.post(`${BASE_URL}/api/auth/logout`, { token });

export const verifyOwnerPasscode = (passcode) =>
  axios.post(`${BASE_URL}/api/auth/verify-owner-passcode`, { passcode });
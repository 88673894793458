import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';  // Icon for assets
import Loading from '../../../../../components/loader';
import PageTitle from '../../../../../components/page-title';
import AssetForm from './AssetsForm';
import { getAssetById } from '../../../../../api/accounting';

function EditAsset() {
  const params = useParams();
  const assetId = params.id;  // Get the asset ID from the URL params
  const [asset, setAsset] = useState({});
  const [loading, setLoading] = useState(true);

  // Fetch asset data by its ID
  useEffect(() => {
    fetchAsset();
  }, []);

  const fetchAsset = async () => {
    try {
      const response = await getAssetById(assetId);
      setAsset(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  // Display loading spinner if the asset data is still being fetched
  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faBoxOpen}  // Asset icon
            className='text-hazel-green mr-2'
          />
        }
        title='Edit Asset'
      />
      <AssetForm
        isEdit
        asset={{
          id: asset.id,
          name: asset.name,
          value: asset.value,
          dateAcquired: asset.dateAcquired.split('T')[0],  // Format the date for the input field
        }}
      />
    </div>
  );
}

export default EditAsset;

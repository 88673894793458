// accounting.js
import api from "./api";

// --- Clients Endpoints ---

// Fetch all clients
export const getClients = () => api.get('/api/accounting/clients');

// Fetch a single client by ID
export const getClientById = (id) => api.get(`/api/accounting/clients/${id}`);

// Create a new client
export const createClient = (values) => api.post('/api/accounting/clients', values);

// Update a client by ID
export const updateClient = (id, values) => api.put(`/api/accounting/clients/${id}`, values);

// Delete a client by ID
export const deleteClient = (id) => api.delete(`/api/accounting/clients/${id}`);

// --- Suppliers Endpoints ---

// Fetch all suppliers
export const getSuppliers = () => api.get('/api/accounting/suppliers');

// Fetch a single supplier by ID
export const getSupplierById = (id) => api.get(`/api/accounting/suppliers/${id}`);

// Create a new supplier
export const createSupplier = (values) => api.post('/api/accounting/suppliers', values);

// Update a supplier by ID
export const updateSupplier = (id, values) => api.put(`/api/accounting/suppliers/${id}`, values);

// Delete a supplier by ID
export const deleteSupplier = (id) => api.delete(`/api/accounting/suppliers/${id}`);

// --- Transactions Endpoints ---

// export const getTransactions = () => api.get('/api/accounting/transactions');
export const getTransactions = (limit = 5, page = 1, search = '') => {
  return api.get('/api/accounting/transactions', {
    params: {
      limit,
      page,
      search
    }
  });
};

// Fetch a single transaction by ID
export const getTransactionById = (id) => api.get(`/api/accounting/transactions/${id}`);

// Create a new transaction
export const createTransaction = (values) => api.post('/api/accounting/transactions', values);

// Update a transaction by ID
export const updateTransaction = (id, values) => api.put(`/api/accounting/transactions/${id}`, values);

// Delete a transaction by ID
export const deleteTransaction = (id) => api.delete(`/api/accounting/transactions/${id}`);

// --- Journal Entries Endpoints ---

// Fetch all journal entries
export const getJournalEntries = () => api.get('/api/accounting/journal-entries');

// Fetch a single journal entry by ID
export const getJournalEntryById = (id) => api.get(`/api/accounting/journal-entries/${id}`);

// Create a new journal entry
export const createJournalEntry = (values) => api.post('/api/accounting/journal-entries', values);

// Update a journal entry by ID
export const updateJournalEntry = (id, values) => api.put(`/api/accounting/journal-entries/${id}`, values);

// Delete a journal entry by ID
export const deleteJournalEntry = (id) => api.delete(`/api/accounting/journal-entries/${id}`);

// --- Ledger Endpoints ---

// Fetch all ledgers
export const getLedgers = () => api.get('/api/accounting/ledgers');

// Fetch a single ledger by ID
export const getLedgerById = (id) => api.get(`/api/accounting/ledgers/${id}`);


// export const createOrUpdateLedger = (values) => api.post('/api/accounting/ledgers', values);
// --- Ledger Endpoints ---
// Create a ledger

export const getFilteredLedgers = (transactionType, month, year) =>
    api.get('/api/accounting/ledgers', {
      params: {
        transactionType,
        month,
        year,
      },
    });

export const getTransactionTypes = () => {
    return api.get('/api/accounting/transaction-types'); // Adjust the endpoint as needed
};

    
export const createLedger = (values) => api.post('/api/accounting/ledgers', values);

// Update a ledger by ID
export const updateLedger = (id, values) => api.put(`/api/accounting/ledgers/${id}`, values);

// Delete a ledger by ID
export const deleteLedger = (id) => api.delete(`/api/accounting/ledgers/${id}`);

// --- Balance Sheet Endpoints ---

// Generate balance sheet
export const generateBalanceSheet = () => api.get('/api/accounting/balance-sheet');


// --- Assets Endpoints ---

// Fetch all assets
export const getAssets = () => api.get('/api/accounting/assets');

// Fetch a single asset by ID
export const getAssetById = (id) => api.get(`/api/accounting/assets/${id}`);

// Create a new asset
export const createAsset = (values) => api.post('/api/accounting/assets', values);

// Update an asset by ID
export const updateAsset = (id, values) => api.put(`/api/accounting/assets/${id}`, values);

// Delete an asset by ID
export const deleteAsset = (id) => api.delete(`/api/accounting/assets/${id}`);

// --- Daily Cash Register Endpoints ---

// Fetch all daily cash registers
export const getDailyCashRegisters = () => api.get('/api/accounting/daily-cash-registers');

// Fetch a single daily cash register by ID
export const getDailyCashRegisterById = (id) => api.get(`/api/accounting/daily-cash-registers/${id}`);

// Create a new daily cash register
export const createDailyCashRegister = (values) => api.post('/api/accounting/daily-cash-registers', values);

// Update a daily cash register by ID
export const updateDailyCashRegister = (id, values) => api.put(`/api/accounting/daily-cash-registers/${id}`, values);

// Delete a daily cash register by ID
export const deleteDailyCashRegister = (id) => api.delete(`/api/accounting/daily-cash-registers/${id}`);
import React from "react";
import CommonHeading from "../common/CommonHeading";
import { services } from "../../../components/data/Data";

function Services() {

return (
  <div className="py-12 bg-gray-100">
    <div className="container mx-auto px-4">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold mb-2 text-gray-800">Our Services</h2>
        <p className="text-lg text-gray-600">Explore Our Services</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {services.map((item, index) => (
          <div key={index} className="bg-white border rounded-lg shadow-md p-6 hover:bg-[#FEA116] hover:text-white transition-all duration-200 ">
            <a href="" className="block text-center">
              <div className="bg-transparent border rounded-full p-4 mx-auto mb-4 flex items-center justify-center">
                <div className="w-16 h-16 border rounded-full flex items-center justify-center">
                  {item.icon}
                </div>
              </div>
              <h5 className="text-xl font-semibold mb-3">{item.name}</h5>
              <p className="hover:text-white transition-all duration-300">{item.discription}</p>
            </a>
          </div>
        ))}
      </div>
    </div>
  </div>
);
};


export default Services;
import api from "./api"; // Assuming api is configured for your base URL and Axi



export const createAccount = (accountData) => api.post("/api/accounts", accountData);

// Get all accounts
export const getAccounts = () => api.get("/api/accounts");

// Get an account by ID
export const getAccountById = (id) => api.get(`/api/accounts/${id}`);

// Update an account
export const updateAccount = (id, accountData) => api.put(`/api/accounts/${id}`, accountData);

// Delete an account
export const deleteAccount = (id) => api.delete(`/api/accounts/${id}`);

// Adjust the value of a specific account
export const adjustAccountValue = (id, adjustment) => 
  api.patch(`/api/accounts/${id}/value`, { adjustment });


export const getTransactionsByAccountId = (id) =>
  api.get(`/api/accounts/${id}/transactions`)

// Get account categories
export const getAccountCategories = () => api.get("/api/accounts/categories");

// Get subcategories for a specific category
export const getSubCategories = (category) => api.get(`/api/accounts/categories/${category}/subcategories`);


export const getIncomeAccounts = () => api.get("/api/accounts/type/income");

// Get expense accounts
export const getExpenseAccounts = () => api.get("/api/accounts/type/expense");
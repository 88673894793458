import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@mui/material';
import Loading from '../../components/loader';
import CustomInput from '../../components/custom-input';
import { SubmitButton, CancelButton } from '../../components/buttons';
import { getUserById, updateUser } from '../../api/users';
import store from '../../store';
import { setUser } from '../../store/slices/user-slice';

function GeneralSettings() {
  const { user } = useSelector((state) => state.user);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const response = await getUserById(user.id);
      setUserData(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='w-full mt-2'>
      <Formik
        initialValues={{
          name: userData.name,
          surname: userData.surname,
          email: userData.email,
          jobRole: userData.jobRole,
          phoneNumber: userData.phoneNumber,
          address: userData.address
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().min(3).required('Name is required*'),
          surname: Yup.string().min(4).required('Surname is required*'),
          email: Yup.string()
            .email('Invalid email format')
            .required('Email is required*'),
          jobRole: Yup.string().required('Job is required*'),
          phoneNumber: Yup.string().nullable(),
          address: Yup.string().nullable()
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            const response = await updateUser(user.id, values);
            const updatedUserData = {
              ...user,
              name: response.data.name,
              surname: response.data.surname
            };
            store.dispatch(setUser(updatedUserData));
            localStorage.setItem('user', JSON.stringify(updatedUserData));
            setSubmitting(false);
          } catch (err) {
            setErrors({
              submit: err?.response?.data?.message || err.message
            });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm
        }) => (
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-2 gap-x-6 gap-y-3'>
              <CustomInput
                type='text'
                name='name'
                label='Name'
                value={values.name}
                onChange={handleChange('name')}
                onBlur={handleBlur}
                showError={errors.name && touched.name}
                errorMessage={errors.name}
              />
              <CustomInput
                type='text'
                name='surname'
                label='Surname'
                value={values.surname}
                onChange={handleChange('surname')}
                onBlur={handleBlur}
                showError={errors.surname && touched.surname}
                errorMessage={errors.surname}
              />
              <TextField
                id='outlined-basic'
                label='Email'
                value={values.email}
                variant='outlined'
                className='border boder-hazel-green rounded-md w-full'
                disabled={true}
              />
              <TextField
                id='outlined-basic'
                label='Job Role'
                value={values.jobRole}
                variant='outlined'
                className='border boder-hazel-green rounded-md w-full'
                disabled={true}
              />
              <CustomInput
                type='text'
                name='phoneNumber'
                label='Phone Number'
                value={values.phoneNumber}
                onChange={handleChange('phoneNumber')}
                onBlur={handleBlur}
                showError={errors.phoneNumber && touched.phoneNumber}
                errorMessage={errors.phoneNumber}
              />
              <CustomInput
                type='text'
                name='address'
                label='Address'
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.address && touched.address}
                errorMessage={errors.address}
              />
              {errors.submit && <p className='text-error'>{errors.submit}</p>}
            </div>
            <div className='flex justify-end items-center w-full mt-4  text-sm'>
              <CancelButton onCancel={() => resetForm()} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/6'
                title='Update profile'
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default GeneralSettings;

import React from "react";
import { useCallback } from "react";
import { IconX } from "@tabler/icons-react";
import CustomTable from "../../../components/table-components/table";

function OrderTable({ 
  items, 
  setItems, 
  onItemDrop, 
  selectedCategory, 
  tableId, 
  selectedRoom, 
  setBarOrderItems, 
  setKitchenOrderItems, 
  setConferenceRoomBarItems, 
  setConferenceRoomOrderItems 
}) {
  // For room service, combine both kitchen and bar items
  const displayItems = items || [];
  
  console.log("Display Items:", displayItems); // Debug log

  const columns = [
    { title: 'Product', renderValue: (item) => item.productName || '-' },
    { title: 'Qty', renderValue: (item) => item.quantity || '-' },
    { title: 'Price', renderValue: (item) => item.price ? `$${Number(item.price).toFixed(2)}` : '-' },
    { title: 'Total', renderValue: (item) => `$${(item.price * item.quantity).toFixed(2)}` },
    { 
      title: selectedCategory === 'room' ? 'Room' : 'Table ID', 
      renderValue: (item) => {
        if (selectedCategory === 'room' && item.roomNumber) {
          return `Room ${item.roomNumber}`;
        }
        return item.tableId || '-';
      }
    },
    {
      title: 'Remove',
      renderValue: (item) => (
        <button
          onClick={() => handleRemoveItem(item)}
          className="text-red-500 hover:text-red-700"
        >
          <IconX size={20} />
        </button>
      ),
    },
  ];

  const handleRemoveItem = (itemToRemove) => {
    if (selectedCategory === 'conferenceRoom') {
      if (itemToRemove.type === 'barItem') {
        setConferenceRoomBarItems(prev => prev.filter(item => item.id !== itemToRemove.id));
      } else {
        setConferenceRoomOrderItems(prev => prev.filter(item => item.id !== itemToRemove.id));
      }
    } else if (itemToRemove.type === 'barItem') {
      setBarOrderItems(prev => prev.filter(item => item.id !== itemToRemove.id));
    } else {
      setKitchenOrderItems(prev => prev.filter(item => item.id !== itemToRemove.id));
    }
  };

  const handleSearchTextChange = (text) => {
    console.log('Search text changed:', text);
  };

  // const handleDrop = useCallback((e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
    
  //   try {
  //     const data = e.dataTransfer.getData('application/json');
  //     const dragData = JSON.parse(data);
  //     console.log('Dropped item in OrderTable:', dragData);
  
  //     const newItem = {
  //       id: dragData.id,
  //       productName: dragData.name || dragData.productName,
  //       price: parseFloat(dragData.price),
  //       quantity: 1,
  //       type: dragData.type,
  //       category: dragData.category,
  //       tableId: selectedCategory === 'room' ? null : tableId,
  //       roomNumber: selectedCategory === 'room' ? selectedRoom : null
  //     };
  
  //     // Add item to the appropriate state based on its type
  //     if (dragData.type === 'barItem') {
  //       setBarOrderItems(prevItems => {
  //         const existingItem = prevItems.find(item => item.id === newItem.id);
  //         if (existingItem) {
  //           return prevItems.map(item => 
  //             item.id === newItem.id 
  //               ? { ...item, quantity: item.quantity + 1 }
  //               : item
  //           );
  //         }
  //         return [...prevItems, newItem];
  //       });
  //     } else {
  //       setKitchenOrderItems(prevItems => {
  //         const existingItem = prevItems.find(item => item.id === newItem.id);
  //         if (existingItem) {
  //           return prevItems.map(item => 
  //             item.id === newItem.id 
  //               ? { ...item, quantity: item.quantity + 1 }
  //               : item
  //           );
  //         }
  //         return [...prevItems, newItem];
  //       });
  //     }
  //   } catch (error) {
  //     console.error('Error handling drop:', error);
  //   }
  // }, [selectedCategory, selectedRoom, tableId, setBarOrderItems, setKitchenOrderItems]);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    
    try {
      const data = e.dataTransfer.getData('application/json');
      const dragData = JSON.parse(data);
      console.log('Dropped item:', dragData);
  
      const newItem = {
        id: dragData.id,
        productName: dragData.name || dragData.productName,
        price: parseFloat(dragData.price),
        quantity: 1,
        type: dragData.type,
        category: dragData.category,
        tableId: selectedCategory === 'room' ? null : tableId,
        roomNumber: selectedCategory === 'room' ? selectedRoom : null
      };
  
      if (dragData.type === 'barItem') {
        setBarOrderItems(prevItems => {
          const existingItem = prevItems.find(item => item.id === newItem.id);
          if (existingItem) {
            return prevItems.map(item => 
              item.id === newItem.id 
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
          }
          return [...prevItems, newItem];
        });
      } else if (dragData.type === 'dish') {
        setKitchenOrderItems(prevItems => {
          const existingItem = prevItems.find(item => item.id === newItem.id);
          if (existingItem) {
            return prevItems.map(item => 
              item.id === newItem.id 
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
          }
          return [...prevItems, newItem];
        });
      }
    } catch (error) {
      console.error('Error handling drop:', error);
    }
  }, [selectedCategory, selectedRoom, tableId, setBarOrderItems, setKitchenOrderItems]);

  return (
    <div 
      className="bg-white shadow-md rounded-lg overflow-hidden"
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDrop={handleDrop}
    >
      <CustomTable
        rows={displayItems}
        columns={columns}
        showMoreActions={false}
        setSearchText={handleSearchTextChange}
      />
    </div>
  );
}

export default OrderTable;
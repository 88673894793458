import { NavLink } from 'react-router-dom';

const navItems = [
  { path: '/accounting/sales/invoices', label: 'Invoices' },
  { path: '/accounting/sales/sales-receipt-list', label: 'Sales Receipt' },
  { path: '/accounting/sales/quotes', label: 'Quotes' },
  { path: '/accounting/sales/credit-notes-list', label: 'Credit Note' },
  { path: '/accounting/sales/payments', label: 'Payments Received' },

];

export default function SalesNavigation() {
  return (
    <nav className="flex space-x-8">
      {navItems.map((item) => (
        <NavLink
          key={item.path}
          to={item.path}
          className={({ isActive }) =>
            `py-4 px-1 border-b-2 font-medium transition-colors ${
              isActive
                ? 'border-hazel-green text-hazel-green'
                : 'border-transparent text-bluish-grey hover:text-hazel-green hover:border-grey-goose'
            }`
          }
        >
          {item.label}
        </NavLink>
      ))}
    </nav>
  );
}
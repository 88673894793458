// import React, { useState, useEffect } from 'react';
// import DishCard from '../views/restaurantInterface/DishCard';
// import { useDrop } from 'react-dnd';
// import { getAllDishes } from '../api/restaurantInterface';
// import { useDrag } from 'react-dnd';

// const DraggableDishCard = ({ id, index, name, imageUrl, description, price }) => {
//   const [, drag] = useDrag({
//     type: 'dish',
//     item: { id, index, name, imageUrl, description, price },
//   });

//   return (
//     <div ref={drag}>
//       <DishCard
//         name={name}
//         imageUrl={imageUrl}
//         description={description}
//         price={price}
//       />
//     </div>
//   );
// };

// const DishSidebar = ({ show, onClose, onDishDrop }) => {
//   const [dishes, setDishes] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const [, drop] = useDrop({
//     accept: 'dish',
//     drop: (item) => {
//       onDishDrop(item);
//     },
//   });

//   useEffect(() => {
//     const fetchDishes = async () => {
//       try {
//         const response = await getAllDishes();
//         console.log(response.data);
//         setDishes(response.data || []);
//       } catch (error) {
//         console.error('Failed to fetch dishes:', error);
//         setError('Failed to fetch dishes');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchDishes();
//   }, []);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>{error}</p>;

//   return (
//     <div className={`fixed top-0 right-0 w-64 h-full bg-white shadow-lg transform ${show ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300`}>
//       <div className="flex justify-between items-center p-4 border-b">
//         <h2 className="text-lg font-bold">Order Products</h2>
//         <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
//           <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
//             <path stroke="none" d="M0 0h24v24H0z" />
//             <line x1={18} y1={6} x2={6} y2={18} />
//             <line x1={6} y1={6} x2={18} y2={18} />
//           </svg>
//         </button>
//       </div>
//       <div ref={drop} className="overflow-y-auto h-[calc(100%-56px)] p-4">

//         {(dishes || []).map((dish) => (
//                     <DraggableDishCard
//                       key={dish.id}
//                       id={dish.id}
//                       index={dish.id}
//                       name={dish.name}
//                       imageUrl={dish.imageUrl}
//                       description={dish.description}
//                       price={dish.price}
//                     />
//         ))}
//         {/* {dishes.map((dish) => (
//           <DraggableDishCard
//             key={dish.id}
//             id={dish.id}
//             index={dish.id}
//             name={dish.name}
//             imageUrl={dish.imageUrl}
//             description={dish.description}
//             price={dish.price}
//           />
//         ))} */}
//       </div>
//     </div>
//   );
// };

// export default DishSidebar;


import React, { useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import DishCard from '../views/restaurantInterface/DishCard';
import { getAllDishes } from '../api/restaurantInterface';
import { useDrag } from 'react-dnd';

const DraggableDishCard = ({ id, index, name, imageUrl, description, price }) => {
  const [, drag] = useDrag({
    type: 'dish',
    item: { id, index, name, imageUrl, description, price },
  });

  return (
    <div ref={drag}>
      <DishCard
        name={name}
        imageUrl={imageUrl}
        description={description}
        price={price}
      />
    </div>
  );
};

const DishSidebar = ({ show, onClose, onDishDrop }) => {
  const [dishes, setDishes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [, drop] = useDrop({
    accept: 'dish',
    drop: (item) => {
      onDishDrop(item);
    },
  });

  useEffect(() => {
    const fetchDishes = async () => {
      try {
        const response = await getAllDishes();
        setDishes(response.data || []);
      } catch (error) {
        setError('Failed to fetch dishes');
      } finally {
        setLoading(false);
      }
    };

    fetchDishes();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className={`fixed top-0 right-0 w-64 h-full bg-white shadow-lg z-32 transform ${show ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300`}>
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-bold">Order Products</h2>
        <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1={18} y1={6} x2={6} y2={18} />
            <line x1={6} y1={6} x2={18} y2={18} />
          </svg>
        </button>
      </div>
      <div ref={drop} className="z-32 overflow-y-auto h-[calc(100%-56px)] p-4">
        {dishes.map((dish, index) => (
          <DraggableDishCard
            key={dish.id}
            id={dish.id}
            index={index}
            name={dish.name}
            imageUrl={dish.imageUrl}
            description={dish.description}
            price={dish.price}
          />
        ))}
      </div>
    </div>
  );
};

export default DishSidebar;

import React from "react";

function Book() {
//   return (
//     <>
//       <div
//         className="container-fluid booking pb-5 wow fadeIn"
//         data-wow-delay="0.1s"
//       >
//         <div className="container">
//           <div className="bg-white shadow" style={{ padding: "35px" }}>
//             <div className="row g-2">
//               <div className="col-md-10">
//                 <div className="row g-2">
//                   <div className="col-md-3">
//                     <div className="date" id="date1" data-target-input="nearest">
//                       <input
//                         type="text"
//                         className="form-control datetimepicker-input"
//                         placeholder="Check in"
//                         data-target="#date1"
//                         data-toggle="datetimepicker"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-md-3">
//                     <div className="date" id="date2" data-target-input="nearest">
//                       <input
//                         type="text"
//                         className="form-control datetimepicker-input"
//                         placeholder="Check out"
//                         data-target="#date2"
//                         data-toggle="datetimepicker"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-md-3">
//                     <select className="form-select">
//                       <option selected>Adult</option>
//                       <option value="1">Adult 1</option>
//                       <option value="2">Adult 2</option>
//                       <option value="3">Adult 3</option>
//                     </select>
//                   </div>
//                   <div className="col-md-3">
//                     <select className="form-select">
//                       <option selected>Child</option>
//                       <option value="1">Child 1</option>
//                       <option value="2">Child 2</option>
//                       <option value="3">Child 3</option>
//                     </select>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-md-2">
//                 <button className="btn btn-primary w-100">Submit</button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
return (
  <div className="bg-gray-100 py-5">
    <div className="container mx-auto px-4">
      <div className="bg-white shadow-lg p-8 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="md:col-span-10">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div className="col-span-1">
                <input
                  type="text"
                  className="w-full border rounded-md px-4 py-2 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Check in"
                />
              </div>
              <div className="col-span-1">
                <input
                  type="text"
                  className="w-full border rounded-md px-4 py-2 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Check out"
                />
              </div>
              <div className="col-span-1">
                <select className="w-full border rounded-md px-4 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                  <option>Adult</option>
                  <option value="1">Adult 1</option>
                  <option value="2">Adult 2</option>
                  <option value="3">Adult 3</option>
                </select>
              </div>
              <div className="col-span-1">
                <select className="w-full border rounded-md px-4 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                  <option>Child</option>
                  <option value="1">Child 1</option>
                  <option value="2">Child 2</option>
                  <option value="3">Child 3</option>
                </select>
              </div>
            </div>
          </div>
          <div className="md:col-span-2 flex items-center">
            <button className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default Book;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { createPasscode } from '../../api/passcode';
import CustomInput from '../../components/custom-input';
import SelectInput from '../../components/select-input';
import { SubmitButton, CancelButton } from '../../components/buttons';
import { Link } from 'react-router-dom';
import {
    IconLockSquareRoundedFilled
} from '@tabler/icons-react';
import Loading from '../../components/loader';
import { getAllRoles } from '../../api/passcode';

const PasscodeForm = () => {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getAllRoles();
        console.log(response.data);
        setRoles(response.data || []);
      } catch (error) {
        console.error('Failed to fetch roles:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchRoles();
  }, []);


  const validationSchema = Yup.object().shape({
    passcode: Yup.string()
      .matches(/^\d{4}$/, 'Passcode must be exactly 4 digits')
      .required('Passcode is required*'),

    name: Yup.string().required('Username is required*'),
    roleId: Yup.string().required('Role is required*'),
  });


  if (loading) {
    return <Loading />;
  }

  const getRedirectPath = (roleTitle) => {

    const roleRedirectPaths = {
      'Admin': '/',
      'Pishina': '/passcode/pool',
      'Restauranti': '/restaurant-interface/tables',
      'Hotel': '/hotelInterface/',

    };

    return roleRedirectPaths[roleTitle] || '/';
  };

  return (
    <div className='flex flex-col gap-6 items-center justify-center bg-hazel-green bg-loginBgImage bg-no-repeat bg-center bg-cover min-h-screen p-4 sm:p-6'>
        <div className='bg-white rounded-lg w-full max-w-md sm:max-w-lg md:max-w-2xl lg:max-w-3xl shadow-md px-6 sm:px-10 pt-8 sm:pt-10 pb-6 sm:pb-8'>
            <div className="flex flex-col items-center justify-center gap-4">
                
                <h3 className='text-hazel-green text-3xl sm:text-4xl font-bold'>Create Passcode</h3>

                <div className="my-6 rounded-full bg-hazel-green flex items-center justify-center p-8 sm:p-14">
                        <IconLockSquareRoundedFilled className='w-16 h-16 sm:w-24 sm:h-24 text-white'/>
                </div>

                <div className='border border-gray-200 rounded-xl shadow-lg mt-5 w-full'>
                    


                    <Formik
                      initialValues={{ passcode: '', roleId: '' , roleTitle: ""}}
                      validationSchema={validationSchema}
                      onSubmit={async (values, { setErrors, setSubmitting }) => {
                        try {
                          await createPasscode(values);
                          const redirectPath = getRedirectPath(values.roleTitle); // Use roleTitle for redirection
                          navigate(redirectPath);
                        } catch (error) {
                          const errorMessage = error.response?.data?.error || 'Submission failed';
                          setErrors({ submit: errorMessage });
                          setSubmitting(false);
                        }
                      }}
                    >
                      {({
                        errors,
                        values,
                        touched,
                        isSubmitting,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit} className='p-4 sm:p-6'>
                          <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2'>
                            <CustomInput
                              type='text'
                              name='passcode'
                              label='Passcode'
                              value={values.passcode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              showError={errors.passcode && touched.passcode}
                              errorMessage={errors.passcode}
                            />

                            <CustomInput
                              type='text'
                              name='name'
                              label='Name'
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              showError={errors.name && touched.name}
                              errorMessage={errors.name}
                            />

                            <div className='col-span-1 md:col-span-2'>
                              <SelectInput
                                value={values.roleId} 
                                label='Role'
                                options={roles.map((role) => ({
                                  id: role.id,
                                  title: role.title,
                                }))}
                                onChange={(e) => {
                                  const selectedRole = roles.find(role => role.id === e.target.value);
                                  setFieldValue('roleId', selectedRole.id);
                                  setFieldValue('roleTitle', selectedRole.title);
                                }}
                                onBlur={handleBlur}
                                showError={errors.roleId && touched.roleId}
                                errorMessage={errors.roleId}
                              />
                              {errors.submit && <p className='text-error'>{errors.submit}</p>}
                            </div>

                          </div>
                          <div className='flex flex-col md:flex-row justify-end items-center w-full mt-6 text-sm'>
                            <CancelButton onCancel={() => navigate('/')} />
                            <SubmitButton
                              isSubmitting={isSubmitting}
                              width='w-full md:w-1/2'
                              title='Create Passcode'
                            />
                          </div>
                        </form>
                      )}
                    </Formik>



                </div>
            </div>
        </div>
    </div>
  );
};

export default PasscodeForm;

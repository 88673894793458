import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IconCalendarMonth, IconConfetti } from '@tabler/icons-react';
import PageTitle from '../../components/page-title';

import VenueForm from './venueForm';

function AddVenueForm() {
  const search = useLocation().search;
//   const [roomType, setRoomType] = useState({});
  const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     getRoomType();
//   }, []);

//   const getRoomType = async () => {
//     try {
//       const response = await getRoomTypeApi(search?.replace('?', ''));
//       setRoomType(response.data);
//       setLoading(false);
//     } catch (err) {
//       setLoading(false);
//       return err;
//     }
//   };

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconConfetti className='text-hazel-green mr-2' size={22} />}
        title='Weeding Venue'
      />
      <VenueForm
        // room={{
        //   number: null,
        //   floor: 0,
        //   status: '',
        //   roomTypeId: roomType.id,
        //   minibar: []
        // }}
        // roomType={roomType}
        loading={loading}
      />
    </div>
  );
}

export default AddVenueForm;

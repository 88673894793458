import React from 'react';
import Navbar from '../../components/hotelInterfaceComponents/Navbar.tsx';
import PMSPage from '../../components/hotelInterfaceComponents/pms/PMSPage.tsx';

function PMSmanagementPage() {
  return (
    <div className="min-h-screen bg-seashell">
      <Navbar />
      <PMSPage />
    </div>
  );
}

export default PMSmanagementPage;
import { useState } from 'react';
import { reportCategories } from '../data/reportData.ts';
import SearchBar from './SearchBar';
import Sidebar from './Sidebar';
import ReportList from './ReportList';

import useLocalStorage from '../../hooks/useLocalStorage.js';

export default function ReportsCenter() {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState('Business Overview');
  const [favorites, setFavorites] = useLocalStorage('favorites', []); // Use local storage for favorites
  const [showFavorites, setShowFavorites] = useState(false);

//   const toggleFavorite = (reportName) => {
//     setFavorites((prevFavorites) => 
//       prevFavorites.includes(reportName)
//         ? prevFavorites.filter((name) => name !== reportName)
//         : [...prevFavorites, reportName]
//     );
//   };

const toggleFavorite = (reportName) => {
    const newFavorites = favorites.includes(reportName)
      ? favorites.filter((name) => name !== reportName)
      : [...favorites, reportName];
  
    setFavorites(newFavorites); // Now we’re saving the actual array to localStorage
  };

  const reports = showFavorites
    ? Object.values(reportCategories).flat().filter((report) => favorites.includes(report.name))
    : reportCategories[activeCategory] || [];

  return (
    <div className="min-h-screen bg-seashell">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-3xl font-semibold text-center mb-8 text-hazel-green">Reports Center</h1>
        <SearchBar searchTerm={searchTerm} onSearchChange={setSearchTerm} />
        <div className="flex gap-8">
          <Sidebar
            categories={reportCategories}
            activeCategory={activeCategory}
            onCategoryChange={setActiveCategory}
            showFavorites={showFavorites}
            setShowFavorites={setShowFavorites}
          />
          <ReportList
            reports={reports}
            category={showFavorites ? 'Favorites' : activeCategory}
            toggleFavorite={toggleFavorite}
            favorites={favorites}
          />
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Building2, Key, LogOut, ShoppingCart, Star } from 'lucide-react';
import Navbar from '../../components/hotelInterfaceComponents/Navbar.tsx';
import { QuickLink } from '../../components/hotelInterfaceComponents/QuickLink.tsx';
import Dashboard from '../../components/hotelInterfaceComponents/Dashboard.tsx';

function HotelHome() {
  const stats = [
    { icon: Building2, label: 'Active Properties', value: '0' },
    { icon: Key, label: 'Check-ins', value: '0' },
    { icon: LogOut, label: 'Checkouts', value: '0' },
    { icon: ShoppingCart, label: 'Online Orders', value: '0' },
    { icon: Star, label: 'Reviews', value: '0' },
  ];

  const quickLinks = [
    { label: 'CREATE NEW PROPERTY', primary: true },
    { label: 'VIEW ALL PROPERTIES' },
    { label: 'ARRIVAL REVENUE REPORT' },
    { label: 'BOOKED REVENUE REPORT' },
    { label: 'SETTINGS' },
  ];

  const navItems = ['Dashboard', 'PMS', 'Manage Properties', 'Room Operations', 'Reports', 'Integrations', 'Manage'];

  return (
    <div className="min-h-screen bg-seashell">
      <Navbar />
      <div className="px-4 sm:px-6 lg:px-8 py-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {stats.map((stat, index) => (
            <div key={index} className="flex items-center p-4 bg-white rounded-lg shadow-md">
              <stat.icon className="w-6 h-6 text-sage mr-3" />
              <div>
                <p className="text-sm font-medium text-gray-700">{stat.label}</p>
                <p className="text-lg font-semibold text-gray-900">{stat.value}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {quickLinks.map((link, index) => (
            <QuickLink key={index} label={link.label} primary={link.primary} />
          ))}
        </div>
      </div>
      <Dashboard />
    </div>
  );
}

export default HotelHome;

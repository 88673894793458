import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { createMenu } from '../../api/conferenceRoomMenu';
import { getAllConferenceRooms } from '../../api/conferenceRoom';
import { getDishSections } from '../../api/dishSection';
import { getBarProducts } from '../../api/barProduct';
import Loading from '../../components/loader';
import CustomInput from '../../components/custom-input';
import SelectInput from '../../components/select-input';
import { SubmitButton, CancelButton } from '../../components/buttons';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ConferenceRoomMenuForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [conferenceRooms, setConferenceRooms] = useState([]);
  const [dishSections, setDishSections] = useState([]);
  const [barProductOptions, setBarProductOptions] = useState([]); // Renamed from barProducts

  const fetchConferenceRooms = useCallback(async () => {
    try {
      const response = await getAllConferenceRooms();
      setConferenceRooms(response.data);
    } catch (error) {
      console.error('Failed to fetch conference rooms:', error);
    }
  }, []);

  const fetchDishSections = useCallback(async () => {
    try {
      const response = await getDishSections();
      setDishSections(response.data);
    } catch (error) {
      console.error('Failed to fetch dish sections:', error);
    }
  }, []);

  const fetchBarProducts = useCallback(async () => {
    try {
      const response = await getBarProducts();
      setBarProductOptions(response.data); // Updated to use new state variable name
    } catch (error) {
      console.error('Failed to fetch bar products:', error);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        await Promise.all([fetchConferenceRooms(), fetchDishSections(), fetchBarProducts()]);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [fetchConferenceRooms, fetchDishSections, fetchBarProducts]);

  if (loading) {
    return <Loading />;
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const menuItems = [
        ...(values.breakfastItems || []).map(item => ({ ...item, type: 'BREAKFAST' })),
        ...(values.lunchItems || []).map(item => ({ ...item, type: 'LUNCH' })),
        ...(values.dinnerItems || []).map(item => ({ ...item, type: 'DINNER' })),
        ...(values.breakfastBarProducts || []).map(item => ({ ...item, type: 'BREAKFAST', isBarProduct: true })),
        ...(values.lunchBarProducts || []).map(item => ({ ...item, type: 'LUNCH', isBarProduct: true })),
        ...(values.dinnerBarProducts || []).map(item => ({ ...item, type: 'DINNER', isBarProduct: true }))
      ];
  
      const menuData = {
        name: values.name,
        conferenceRoomId: values.conferenceRoomId,
        price: parseFloat(values.price) || 0,
        menuItems
      };
  
      await createMenu(menuData);
      toast.success("Menu created sucessfuly");
      navigate('/conferenceRoom');
    } catch (error) {
      toast.success("There was a problem creating the menu");
      console.error('Failed to create menu:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className='bg-white border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
      <Formik
        initialValues={{
          name: '',
          conferenceRoomId: '',
          price: 0,
          breakfastItems: [],
          lunchItems: [],
          dinnerItems: [],
          breakfastBarProducts: [],
          lunchBarProducts: [],
          dinnerBarProducts: []
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Menu name is required'),
          conferenceRoomId: Yup.string().required('Conference room is required'),
          price: Yup.number().min(0, 'Price must be non-negative'),
          breakfastItems: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Dish section is required'),
              type: Yup.string().oneOf(['BREAKFAST'])
            })
          ),
          lunchItems: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Dish section is required'),
              type: Yup.string().oneOf(['LUNCH'])
            })
          ),
          dinnerItems: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Dish section is required'),
              type: Yup.string().oneOf(['DINNER'])
            })
          ),
          breakfastBarProducts: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Bar product is required'),
              type: Yup.string().oneOf(['BREAKFAST']),
              isBarProduct: Yup.boolean().oneOf([true])
            })
          ),
          lunchBarProducts: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Bar product is required'),
              type: Yup.string().oneOf(['LUNCH']),
              isBarProduct: Yup.boolean().oneOf([true])
            })
          ),
          dinnerBarProducts: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Bar product is required'),
              type: Yup.string().oneOf(['DINNER']),
              isBarProduct: Yup.boolean().oneOf([true])
            })
          )
        })}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
              <CustomInput
                type='text'
                name='name'
                label='Menu Name'
                value={values.name}
                onChange={(e) => setFieldValue('name', e.target.value)}
              />
              <SelectInput
                name='conferenceRoomId'
                label='Conference Room'
                value={values.conferenceRoomId}
                options={conferenceRooms.map(room => ({
                  id: room.id,
                  title: room.name
                }))}
                onChange={(e) => setFieldValue('conferenceRoomId', e.target.value)}
                showError={false}
              />
              <CustomInput
                type='number'
                name='price'
                label='Menu Price'
                value={values.price}
                onChange={(e) => setFieldValue('price', parseFloat(e.target.value) || 0)}
              />
            </div>

            <div className="mt-8 space-y-8">
              {[
                { title: 'Breakfast', items: 'breakfastItems', barProducts: 'breakfastBarProducts' },
                { title: 'Lunch', items: 'lunchItems', barProducts: 'lunchBarProducts' },
                { title: 'Dinner', items: 'dinnerItems', barProducts: 'dinnerBarProducts' },
              ].map(({ title, items, barProducts }) => (
                <div key={title} className="bg-gray-50 rounded-lg p-6">
                  <h2 className='text-2xl font-bold mb-4 text-gray-800'>{title} Menu</h2>
                  <div className="grid md:grid-cols-2 gap-6">
                    <div>
                      <h3 className='text-lg font-semibold mb-3 text-gray-700'>Dish Sections</h3>
                      <FieldArray
                        name={items}
                        render={arrayHelpers => (
                          <div className="space-y-4">
                            {values[items].map((item, index) => (
                              <div key={index} className='bg-white border border-gray-200 rounded-lg p-4 shadow-sm'>
                                <h4 className='font-semibold mb-2 text-gray-600'>Item {index + 1}</h4>
                                <SelectInput
                                  name={`${items}.${index}.id`}
                                  label='Item'
                                  value={item.id}
                                  options={dishSections.map(dish => ({
                                    id: dish.id,
                                    title: dish.name,
                                  }))}
                                  onChange={(e) => {
                                    const selectedDish = dishSections.find(dish => dish.id === e.target.value);
                                    setFieldValue(`${items}.${index}`, {
                                      id: selectedDish.id,
                                      name: selectedDish.name,
                                      description: selectedDish.description,
                                      price: selectedDish.price,
                                      type: title.toUpperCase()
                                    });
                                  }}
                                  showError={false}
                                />
                                <button
                                  type='button'
                                  className='text-red-500 mt-3 hover:text-red-700 transition duration-150'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  Remove Item
                                </button>
                              </div>
                            ))}
                            <button
                              type='button'
                              className='bg-hazel-green text-white px-4 py-2 rounded-md hover:bg-sage transition duration-150'
                              onClick={() => arrayHelpers.push({ id: '' })}
                            >
                              Add Item
                            </button>
                          </div>
                        )}
                      />
                    </div>
                    <div>
                      <h3 className='text-lg font-semibold mb-3 text-gray-700'>Bar Products</h3>
                      <FieldArray
                        name={barProducts}
                        render={arrayHelpers => (
                          <div className="space-y-4">
                            {values[barProducts].map((item, index) => (
                              <div key={index} className='bg-white border border-gray-200 rounded-lg p-4 shadow-sm'>
                                <h4 className='font-semibold mb-2 text-gray-600'>Bar Product {index + 1}</h4>
                                <SelectInput
                                  name={`${barProducts}.${index}.id`}
                                  label='Bar Product'
                                  value={item.id}
                                  options={barProductOptions.map(product => ({ // Updated to use barProductOptions
                                    id: product.id,
                                    title: product.name,
                                  }))}
                                  onChange={(e) => {
                                    const selectedProduct = barProductOptions.find(product => product.id === e.target.value); // Updated to use barProductOptions
                                    setFieldValue(`${barProducts}.${index}`, {
                                      id: selectedProduct.id,
                                      name: selectedProduct.name,
                                      description: selectedProduct.description,
                                      price: selectedProduct.price,
                                      type: title.toUpperCase(),
                                      isBarProduct: true
                                    });
                                  }}
                                  showError={false}
                                />
                                <button
                                  type='button'
                                  className='text-red-500 mt-3 hover:text-red-700 transition duration-150'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  Remove Bar Product
                                </button>
                              </div>
                            ))}
                            <button
                              type='button'
                              className='bg-hazel-green text-white px-4 py-2 rounded-md hover:bg-sage transition duration-150'
                              onClick={() => arrayHelpers.push({ id: '' })}
                            >
                              Add Bar Product
                            </button>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className='flex justify-end items-center w-full mt-8 text-sm'>
              <CancelButton onCancel={() => navigate('/conferenceRoom')} />
              <SubmitButton width='w-1/2 md:w-1/4' title={'Create Menu'} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ConferenceRoomMenuForm;
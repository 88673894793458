import React from 'react';
import { CreditCard, DollarSign } from 'lucide-react';

interface PaymentDetailsProps {
  subTotal: number;
  paymentMethod: string;
  onPaymentMethodChange: (method: string) => void;
}

export function PaymentDetails({ subTotal, paymentMethod, onPaymentMethodChange }: PaymentDetailsProps) {
  const tax = subTotal * 0.20; // 20% VAT
  const total = subTotal + tax;

  return (
    <div className="bg-seashell rounded-lg p-6 space-y-6">
      <h2 className="text-lg font-semibold text-bluish-grey flex items-center gap-2">
        <CreditCard size={20} className="text-hazel-green" />
        Payment Details
      </h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-bluish-grey">Payment Method</label>
          <select
            value={paymentMethod}
            onChange={(e) => onPaymentMethodChange(e.target.value)}
            className="w-full p-2.5 border border-grey-goose rounded-lg focus:outline-none focus:border-sage bg-white text-bluish-grey transition-colors hover:border-sage"
          >
            <option value="">Select Payment Method</option>
            <option value="bank_transfer">Bank Transfer</option>
            <option value="credit_card">Credit Card</option>
            <option value="paypal">PayPal</option>
          </select>
        </div>
        
        <div className="bg-white rounded-lg border border-grey-goose p-4 space-y-3">
          <div className="flex justify-between items-center text-sm">
            <span className="text-bluish-grey">Sub Total:</span>
            <span className="font-medium text-bluish-grey">€{subTotal.toFixed(2)}</span>
          </div>
          <div className="flex justify-between items-center text-sm">
            <span className="text-bluish-grey">VAT (20%):</span>
            <span className="font-medium text-bluish-grey">€{tax.toFixed(2)}</span>
          </div>
          <div className="flex justify-between items-center text-lg font-semibold pt-3 border-t border-grey-goose">
            <span className="text-bluish-grey flex items-center gap-2">
              <DollarSign size={20} className="text-hazel-green" />
              Total:
            </span>
            <span className="text-hazel-green">€{total.toFixed(2)}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
import React from "react";

import Header from "../common/Header";
import Footer from "../common/Footer";
import Heading from "../common/Heading";

import AddRoomType from "../home/room-types/AddRoomType";

function AddRoomTypePage() {

    return (
        <>
            <Header />
            <Heading heading="Create Room Type" title="New" subtitle="Room Type" />
            <div className="my-24">
                <AddRoomType />
            </div>
            <Footer />
        </>
    );
}

export default AddRoomTypePage;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getAllRoles } from '../../api/passcode.js';
import CustomInput from './hotelInterfaceFA/CustomInput.tsx';
import SelectInput from './hotelInterfaceFA/SelectInput.tsx';
import { SubmitButton, CancelButton } from '../../components/buttons';
import { IconLockSquareRoundedFilled } from '@tabler/icons-react';
import Loading from '../../components/loader.js';
import { loginWithPasscode } from '../../api/passcode.js';
import AuthContext from '../../contexts/auth-context.js';
import { useContext } from 'react';
import { loginWithRoleAndPassword as authenticateUserWithRoleAndPassword  } from "../../api/passcode.js";

function HotelInterfaceFApage() {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const { loginWithPasscode } = useContext(AuthContext);

  // Fetch roles
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getAllRoles();

        const filteredRoles = response.data.filter(role => 
          ['Pastruese', 'Hotel'].includes(role.title) // Filtering for specific roles
        );

        console.log(response)
        setRoles(filteredRoles); // Assuming response.data contains the roles
      } catch (error) {
        console.error('Failed to fetch roles:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchRoles();
  }, []);

  const validationSchema = Yup.object().shape({
    roleId: Yup.string().required('Role is required*'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required*')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
        'Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character.'
      ),
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col gap-6 items-center justify-center bg-hazel-green bg-loginBgImage bg-no-repeat bg-center bg-cover min-h-screen p-4">
      <div className="bg-white rounded-lg w-full max-w-sm md:max-w-2xl lg:max-w-1/2 shadow-md px-6 sm:px-10 md:px-14 pt-8 sm:pt-10 md:pt-12 pb-6 sm:pb-8 md:pb-10">
        <div className="flex flex-col items-center justify-center gap-4">
          <h3 className="text-hazel-green text-3xl sm:text-4xl font-bold">Welcome Back</h3>
          <div className="my-6 rounded-full bg-hazel-green flex items-center justify-center p-8 sm:p-12">
            <IconLockSquareRoundedFilled className="w-16 h-16 sm:w-24 sm:h-24 text-white" />
          </div>

          <Formik
            initialValues={{ roleId: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setErrors, setSubmitting }) => {
              try {
                console.log('Form submitted:', values);
                const response = await authenticateUserWithRoleAndPassword(values.roleId, values.password);
                console.log("Response from authentication:", response);
                
                if (response.data.statusCode === 200) {
                  console.log("Login successful:", response.data.data);
                  const { token, user } = response.data.data;
                  await loginWithPasscode({ user, token });
                  navigate("/hotelInterface");
                } else {
                  setErrors({ submit: response.data.message || 'Unknown error occurred' });
                }
              } catch (error) {
                console.error('Authentication error:', error);
                setErrors({ submit: 'Authentication failed. Please try again.' });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              values,
              touched,
              isSubmitting,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className="p-4 sm:p-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                  <SelectInput
                    value={values.roleId}
                    label="Role"
                    options={roles.map((role) => ({ id: role.id, title: role.title }))}
                    onChange={(e) => {
                      const selectedRole = roles.find((role) => role.id === e.target.value);
                      setFieldValue('roleId', selectedRole?.id || '');
                    }}
                    onBlur={handleBlur}
                    showError={errors.roleId && touched.roleId}
                    errorMessage={errors.roleId}
                  />

                  <CustomInput
                    type="password"
                    name="password"
                    label="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.password && touched.password}
                    errorMessage={errors.password}
                  />
                </div>

                {errors.submit && <p className="text-red-600 text-sm">{errors.submit}</p>}

                <div className="flex flex-col md:flex-row justify-end items-center w-full mt-6 text-sm">
                  <CancelButton onCancel={() => navigate('/')} />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    width="w-full md:w-1/2"
                    title="Sign In"
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default HotelInterfaceFApage;

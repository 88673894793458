import React, { useState, useEffect, useCallback } from 'react';
import CustomTable from '../../../../../components/table-components/table';
import Loading from '../../../../../components/loader';
import { getTransactions, deleteTransaction } from '../../../../../api/finances'; 
import { IconTrash } from '@tabler/icons-react';

function TransactionList() {
    const [transactionsList, setTransactionsList] = useState([]);
    const [fetchingInitialData, setFetchingInitialData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [totalCount, setTotalCount] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const getTransactionsList = useCallback(async () => {
        try {
            const response = await getTransactions();
            const transactions = response.data || [];
            setTotalCount(transactions.length || 0);
            setTransactionsList(transactions);
        } catch (err) {
            setErrorMessage(err?.response?.data?.message || err.message);
        } finally {
            setFetchingInitialData(false);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        getTransactionsList().finally(() => setLoading(false));
    }, [getTransactionsList]);

    useEffect(() => {
        setLoading(true);
        getTransactionsList().finally(() => setLoading(false));
    }, [currentPage, rowsPerPage, searchText]);

    const handleDelete = async (id) => {
        try {
            setLoading(true);
            await deleteTransaction(id);
            setTransactionsList((prevState) => prevState.filter((item) => item.id !== id));
            setTotalCount(totalCount - 1);
        } catch (err) {
            setErrorMessage(err?.response?.data?.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    if (fetchingInitialData) {
        return <Loading />;
    }

    if (!fetchingInitialData && errorMessage) {
        return (
            <div className='text-center'>
                <h1>{errorMessage}</h1>
            </div>
        );
    }

    return (
        <div className='p-10'>
            <div className='flex justify-between items-center mb-8'>
                <h1 className='text-2xl font-bold text-hazel-green'>Transactions</h1>
            </div>
            <CustomTable
                rows={transactionsList}
                columns={[
                    {
                        title: 'Description',
                        renderValue: (item) => item.description || 'No description available',
                    },
                    {
                        title: 'Amount',
                        renderValue: (item) => `$${item.amount}`,
                    },
                    {
                        title: 'Payment Type',
                        renderValue: (item) => item.paymentType || 'Not specified',
                    },
                    {
                        title: 'Date',
                        renderValue: (item) => new Date(item.transactionDate).toLocaleDateString(),
                    },
                    {
                        title: 'Is Paid',
                        renderValue: (item) => item.isPaid ? 'Yes' : 'No',
                    },
                ]}
                entity='transactions'
                setSearchText={setSearchText}
                loading={loading}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                totalCount={totalCount}
                onDelete={handleDelete}
                showMoreActions={true}
            />
        </div>
    );
}

export default TransactionList;

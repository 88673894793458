import React from 'react';
import { IconBox } from '@tabler/icons-react'; // Assuming you have an icon for stock items
import StockItemForm from './StockItemForm';
import PageTitle from '../../components/page-title';

function AddStockItem() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconBox className='text-hazel-green mr-2' size={22} />}
        title='Add New Stock Item'
      />
      <StockItemForm isEdit={false} />
    </div>
  );
}

export default AddStockItem;

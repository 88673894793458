import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { X, Search, Settings } from 'lucide-react';
import { FormField } from '../../../../../components/creditNote/FormField.tsx';
import { ItemTable } from '../../../../../components/creditNote/ItemTable.tsx';

import { ItemRow } from '../../../../../gobal';

function CreditNote() {
  const [items, setItems] = useState<ItemRow[]>([{
    id: '1',
    details: '',
    account: '',
    quantity: 1.00,
    rate: 0.00,
    discount: 0,
    tax: '',
    amount: 0.00
  }]);

  const [shippingCharges, setShippingCharges] = useState(0);
  const [adjustment, setAdjustment] = useState(0);

  const calculateSubTotal = () => items.reduce((sum, item) => sum + item.amount, 0);
  const calculateTotal = () => calculateSubTotal() + shippingCharges + adjustment;

  return (
    <motion.div 
      className="min-h-screen p-4 md:p-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-grey-goose">
          <h1 className="text-xl font-medium text-bluish-grey">New Credit Note</h1>
          <button className="text-silver hover:text-bluish-grey transition-colors">
            <X size={20} />
          </button>
        </div>

        {/* Form Content */}
        <div className="p-6 space-y-6">
          {/* Customer Details */}
          <div className="space-y-4">
            <FormField label="Customer Name" required>
              <div className="relative">
                <select className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage">
                  <option>Select or add a customer</option>
                </select>
                <Search className="absolute right-2 top-2.5 text-silver" size={16} />
              </div>
            </FormField>

            <FormField label="Credit Note#" required>
              <div className="relative">
                <input 
                  type="text" 
                  value="CN-00001"
                  className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage"
                  readOnly
                />
                <Settings className="absolute right-2 top-2.5 text-silver" size={16} />
              </div>
            </FormField>

            <FormField label="Reference#">
              <input 
                type="text" 
                className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage"
              />
            </FormField>

            <FormField label="Credit Note Date" required>
              <input 
                type="date" 
                className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage"
              />
            </FormField>

            <FormField label="Salesperson">
              <select className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage">
                <option>Select or Add Salesperson</option>
              </select>
            </FormField>

            <FormField label="Subject" info>
              <textarea 
                className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage"
                placeholder="Let your customer know what this Credit Note is for"
                rows={2}
              />
            </FormField>
          </div>

          {/* Item Table */}
          <ItemTable items={items} onItemChange={setItems} />

          {/* Totals */}
          <div className="flex justify-end">
            <div className="w-72 space-y-3">
              <div className="flex justify-between text-sm">
                <span className="text-bluish-grey">Sub Total</span>
                <span className="font-medium">€ {calculateSubTotal().toFixed(2)}</span>
              </div>

              <div className="flex justify-between items-center">
                <span className="text-sm text-bluish-grey">Shipping</span>
                <input 
                  type="number"
                  value={shippingCharges}
                  onChange={(e) => setShippingCharges(Number(e.target.value))}
                  className="w-28 p-1.5 text-sm border border-grey-goose rounded text-right focus:outline-none focus:border-sage"
                />
              </div>

              <div className="flex justify-between items-center">
                <span className="text-sm text-bluish-grey">Adjustment</span>
                <input 
                  type="number"
                  value={adjustment}
                  onChange={(e) => setAdjustment(Number(e.target.value))}
                  className="w-28 p-1.5 text-sm border border-grey-goose rounded text-right focus:outline-none focus:border-sage"
                />
              </div>

              <div className="flex justify-between pt-3 border-t border-grey-goose">
                <span className="font-medium text-bluish-grey">Total</span>
                <span className="font-medium">€ {calculateTotal().toFixed(2)}</span>
              </div>
            </div>
          </div>

          {/* Customer Notes */}
          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-2">Customer Notes</label>
            <textarea 
              className="w-full p-3 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage"
              placeholder="Will be displayed on the credit note"
              rows={3}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default CreditNote;
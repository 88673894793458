import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import SelectInput from '../../components/select-input';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import DateInput from '../../components/date-input';
import ProductsList from '../../components/products/products-list';
import { invoiceTypes, paymentMethods } from '../../constants/entities';
import { createInvoice, updateInvoice } from '../../api/invoices';
import { createProduct, updateProduct } from '../../api/products';

function InvoiceForm({ isEdit, invoice, order, onSubmit }) {
  const navigate = useNavigate();
  const [initialProducts, setInitialProducts] = useState([]);

  useEffect(() => {
    if (isEdit) {
      setInitialProducts(invoice.products);
    }
  }, [isEdit, invoice]);

  const calculateInvoiceVATPrice = (products, setFieldValue) => {
    let totalPrice = 0;
    let totalVatPrice = 0;

    products.forEach((product) => {
      totalPrice += product.totalPrice || 0;
      if (product.vat) {
        totalVatPrice += (product.totalPrice * product.vat) / 100;
      }
    });

    setFieldValue(
      'vatPrice',
      parseFloat((Math.round(totalVatPrice * 100) / 100).toFixed(2))
    );
  };

  const calculateVatPrice = (products, setFieldValue) => {
    let vatPrice = 0;
    products.forEach((product) => {
      if (product.vat) {
        vatPrice += product.vat * product.quantity;
      }
    });
    setFieldValue('vatPrice', parseFloat(vatPrice));
  };

  const calculateTotalPrice = (products, setFieldValue) => {
    let totalPrice = 0;
    let totalVatPrice = 0;

    products.forEach((product) => {
      totalPrice += product.totalPrice || 0;
      if (product.vat) {
        totalVatPrice += (product.totalPrice * product.vat) / 100;
      }
    });

    let calculatedTotalPrice = totalPrice + totalVatPrice;

    setFieldValue(
      'totalPrice',
      parseFloat((Math.round(calculatedTotalPrice * 100) / 100).toFixed(2))
    );
  };

  const handleProductChange = (products) => {
    const productsChanged =
      initialProducts.length !== products.length ||
      initialProducts.some((initialProduct, index) => {
        const currentProduct = products[index];
        return (
          initialProduct.name !== currentProduct.name ||
          initialProduct.type !== currentProduct.type ||
          initialProduct.quantity !== currentProduct.quantity ||
          initialProduct.unit !== currentProduct.unit ||
          initialProduct.unitPrice !== currentProduct.unitPrice ||
          initialProduct.discountPercentage !==
            currentProduct.discountPercentage ||
          initialProduct.vat !== currentProduct.vat ||
          initialProduct.totalPrice !== currentProduct.totalPrice ||
          initialProduct.description !== currentProduct.description
        );
      });

    return productsChanged;
  };

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg py-10 px-8 mt-5'>
      <Formik
        initialValues={{
          number: isEdit ? invoice.number : '',
          date: isEdit ? dayjs(invoice.date) : null,
          type: isEdit ? invoice.type : '',
          dueDate: isEdit && invoice.dueDate ? dayjs(invoice.dueDate) : null,
          vatPrice: isEdit ? invoice.vatPrice : 0,
          totalPrice: isEdit ? invoice.totalPrice : 0,
          paymentType: isEdit ? invoice.paymentType : '',
          isPaid: isEdit ? invoice.isPaid : false,
          acceptedBy: isEdit ? invoice.acceptedBy : '',
          provider: isEdit ? invoice.provider : '',
          providerNumber: isEdit ? invoice.providerNumber : '',
          providerAddress: isEdit ? invoice.providerAddress : '',
          providerPhoneNumber: isEdit ? invoice.providerPhoneNumber : '',
          products: isEdit
            ? invoice.products
            : [
                {
                  name: '',
                  type: '',
                  quantity: null,
                  unit: '',
                  unitPrice: null,
                  discountPercentage: 0,
                  vat: null,
                  totalPrice: null,
                  description: '',
                  soldPrice: null
                }
              ]
        }}
        validationSchema={Yup.object().shape({
          number: Yup.string().min(2).required('Number is required*'),
          date: Yup.string().required('Date is required'),
          type: Yup.string().required('Type is required*'),
          dueDate: Yup.string().nullable(),
          vatPrice: Yup.number().nullable(),
          totalPrice: Yup.number().nullable(),
          paymentType: Yup.string().required('Payment Method is required*'),
          isPaid: Yup.bool().required('Payment status is required*'),
          acceptedBy: Yup.string().required(
            'Please provide the name of the person who accepted this invoice*'
          ),
          provider: Yup.string().required('Provider is required*'),
          providerNumber: Yup.string().required('NIF is required*'),
          providerAddress: Yup.string(),
          providerPhoneNumber: Yup.string(),
          products: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required('Product name is required'),
              type: Yup.string().required('Product type is required'),
              quantity: Yup.number().required('Quantity is required'),
              unit: Yup.string().required('Unit is required'),
              soldPrice: Yup.number(),
              unitPrice: Yup.number().required('Unit price is required'),
              discountPercentage: Yup.number().nullable(),
              vat: Yup.number().nullable(),
              totalPrice: Yup.number(),
              description: Yup.string()
            })
          )
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            const { products, ...invoiceData } = values;
            if (isEdit) {
              try {
                await updateInvoice(invoice.id, invoiceData).then(
                  async (res) => {
                    if (handleProductChange(products)) {
                      await Promise.all(
                        products.map(async (product, index) => {
                          if (
                            !initialProducts[index] ||
                            product.id === undefined
                          ) {
                            const productValues = {
                              ...product,
                              invoiceId: invoice.id
                            };
                            await createProduct(productValues).catch(
                              (err) => err
                            );
                          } else {
                            const { id, ...productValues } = product;
                            await updateProduct(id, productValues).catch(
                              (err) => err
                            );
                          }
                        })
                      );
                      toast.success('Invoice updated successfully!');
                    }
                  }
                );
              } catch (err) {
                toast.error('An error occurred while updating invoice.');
              }
            } else {
              await createInvoice(invoiceData)
                .then(async (res) => {
                  await Promise.all(
                    products.map(async (product) => {
                      const productValues = {
                        ...product,
                        invoiceId: res.data.id
                      };
                      await createProduct(productValues)
                        .then((res) => console.log('product', res.data))
                        .catch((err) => console.log('product err', err));
                    })
                  );
                  navigate('/invoices');
                })
                .catch((err) => err);
            }
            setSubmitting(false);
          } catch (err) {
            setErrors({
              submit: err?.response?.data
            });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <h3 className='text-bluish-grey font-medium uppercase mb-3'>
              Invoice detail
            </h3>
            <div className='grid grid-cols-3 gap-x-3'>
              <div className='col-span-2 gap-x-3 border-r pr-3 boder-hazel-green'>
                <div className='grid grid-cols-4 gap-x-3'>
                  <DateInput
                    name='date'
                    label='Date'
                    value={values.date}
                    onChange={(date) => {
                      setFieldValue('date', date);
                    }}
                    onBlur={handleBlur}
                    showError={errors.date && touched.date}
                    errorMessage={errors.date}
                  />
                  <CustomInput
                    type='text'
                    name='number'
                    label='Invoice Number'
                    value={values.number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.number && touched.number}
                    errorMessage={errors.number}
                  />
                  <SelectInput
                    value={values.type}
                    label='Type'
                    options={invoiceTypes}
                    onChange={handleChange('type')}
                    onBlur={handleBlur}
                    showError={errors.type && touched.type}
                    errorMessage={errors.type}
                    isEntity={true}
                  />
                  <DateInput
                    name='dueDate'
                    label='Due Date'
                    value={values.dueDate}
                    onChange={(date) => {
                      setFieldValue('dueDate', date);
                    }}
                    onBlur={handleBlur}
                    showError={errors.dueDate && touched.dueDate}
                    errorMessage={errors.dueDate}
                  />
                  <div className='col-span-2'>
                    <CustomInput
                      type='text'
                      name='acceptedBy'
                      label='Accepted By'
                      value={values.acceptedBy}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      showError={errors.acceptedBy && touched.acceptedBy}
                      errorMessage={errors.acceptedBy}
                    />
                  </div>
                  <div />
                  <div className='flex justify-end items-center'>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.isPaid}
                            onChange={(event) =>
                              setFieldValue('isPaid', event.target.checked)
                            }
                            inputProps={{ 'aria-label': 'controlled' }}
                            color='success'
                          />
                        }
                        label='Is Paid'
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
              <div className='col-span-1'>
                <div className='grid md:grid-cols-2 gap-x-3'>
                  <CustomInput
                    type='text'
                    name='vatPrice'
                    label='VAT Price'
                    value={values.vatPrice}
                    disabled
                  />
                  <CustomInput
                    type='number'
                    name='totalPrice'
                    label='Total Price'
                    value={values.totalPrice}
                    disabled
                  />
                </div>
                <SelectInput
                  value={values.paymentType}
                  label='Payment Type'
                  options={paymentMethods}
                  onChange={handleChange('paymentType')}
                  onBlur={handleBlur}
                  showError={errors.paymentType && touched.paymentType}
                  errorMessage={errors.paymentType}
                  isEntity={true}
                />
              </div>
            </div>
            <h3 className='text-bluish-grey font-medium uppercase mt-3'>
              Provider
            </h3>
            <div className='border-b boder-hazel-green my-2' />
            <div className='grid grid-cols-2 gap-x-3 mt-4'>
              <CustomInput
                type='text'
                name='provider'
                label='Provider'
                value={values.provider}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.provider && touched.provider}
                errorMessage={errors.provider}
              />
              <CustomInput
                type='text'
                name='providerNumber'
                label='NIF'
                value={values.providerNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.providerNumber && touched.providerNumber}
                errorMessage={errors.providerNumber}
              />
            </div>
            <div className='grid grid-cols-2 gap-x-3 mt-2'>
              <CustomInput
                type='text'
                name='providerPhoneNumber'
                label='Phone Number'
                value={values.providerPhoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={
                  errors.providerPhoneNumber && touched.providerPhoneNumber
                }
                errorMessage={errors.providerPhoneNumber}
              />
              <CustomInput
                type='text'
                name='providerAddress'
                label='Address'
                value={values.providerAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.providerAddress && touched.providerAddress}
                errorMessage={errors.providerAddress}
              />
            </div>

            <ProductsList
              values={values}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              calculateVatPrice={calculateVatPrice}
              calculateInvoiceVATPrice={calculateInvoiceVATPrice}
              calculateTotalPrice={calculateTotalPrice}
              isEdit={isEdit}
              errors={errors?.products || []}
              touched={touched?.products || []}
            />

            {errors.submit && <p className='text-error'>{errors.submit}</p>}
            <div className='flex justify-end items-center w-full mt-14 text-sm'>
              <CancelButton onCancel={() => navigate('/invoices')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/5'
                title={`${isEdit ? 'Edit invoice' : 'Create invoice'}`}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default InvoiceForm;




//second version
import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IconHistory } from '@tabler/icons-react';
import AddTicket from './AddTicket';
import CustomInput from '../../components/custom-input';
import PermissionCheck from '../../utils/PermissionCheck';
import { SubmitButton } from '../../components/buttons';
import { getPools, updatePool } from '../../api/pools';
import useAuth from '../../hooks/useAuth';
import ReactToPrint from 'react-to-print';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { generateINPFile } from '../../api/inpFile';
import { getPort, closePort } from './serialPortManager';


function PoolsList() {
  const { userPermissions } = useAuth();
  const [pools, setPools] = useState({});
  const [activePool, setActivePool] = useState('OUTDOOR');
  const [activePoolId, setActivePoolId] = useState(null);
  const printRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPools = async () => {
      try {
        const response = await getPools();
        const poolsData = response.data.reduce((acc, pool) => {
          acc[pool.type] = {
            id: pool.id,
            man: pool.tickets.find((ticket) => ticket.title === 'man').price,
            woman: pool.tickets.find((ticket) => ticket.title === 'woman').price,
            kid: pool.tickets.find((ticket) => ticket.title === 'kid').price,
          };
          return acc;
        }, {});

        if (!userPermissions.includes('read_indoor_pools')) {
          delete poolsData['INDOOR'];
        }

        if (!userPermissions.includes('read_outdoor_pools')) {
          delete poolsData['OUTDOOR'];
        }
        setPools(poolsData);
        const firstPoolType = Object.keys(poolsData)[0];
        setActivePool(firstPoolType);
        setActivePoolId(poolsData[firstPoolType].id);
      } catch (error) {
        console.error('Failed to fetch pools data', error);
      }
    };
    fetchPools();

    
  }, []);

  const handleTabChange = (tab, setFieldValue) => {
    setActivePool(tab);
    setActivePoolId(pools[tab].id);
    setFieldValue('man', pools[tab].man);
    setFieldValue('woman', pools[tab].woman);
    setFieldValue('kid', pools[tab].kid);
  };



  
  const handleLinkClick = async (event, url) => {
    event.preventDefault();
    await closePort();
    navigate(url);
  };

  //correct

  // const handlePrint = async (item) => {
  //   const saleData = { product: 'Ticket', price: 99 };

  //   console.log('Sending data to generate INP file:', saleData);

  //   try {
  //     // Call the API to generate the INP file
  //     const response = await generateINPFile(saleData);
  //     console.log('API response:', response.data);
  //     printRef.current.setItem(item);
  //     setTimeout(() => {
  //       printRef.current.triggerPrint();
  //     }, 500); // Give some time for the state to update
  //   } catch (error) {
  //     console.error('Error generating INP file:', error);
  //   }
  // };

  const handlePrint = async (item) => {
    // await generateINPFile(item);
    const saleData = { product: 'Ticket', price: item.totalPrice };

    console.log('Sending data to generate INP file:', saleData);

    // Call the API to generate the INP file
    await axios.post('http://localhost:3001/generate-sale', saleData);


  // Call the API to generate the INP file
    printRef.current.setItem(item);
    setTimeout(() => {
      printRef.current.triggerPrint();
    }, 500); // Give some time for the state to update
  };

  if (!pools[activePool]) return null;

  const poolPrices = pools[activePool];

  const disableInputs =
    activePool === 'INDOOR'
      ? !userPermissions.includes('edit_indoor_pools')
      : !userPermissions.includes('edit_outdoor_pools');

  return (
    <div className='p-10'>
      <div className='border border-gray-200 rounded-xl shadow-lg px-3 mt-5'>
        <Formik
          initialValues={{
            man: poolPrices.man,
            woman: poolPrices.woman,
            kid: poolPrices.kid,
          }}
          validationSchema={Yup.object().shape({
            man: Yup.number().required('Price is required*'),
            woman: Yup.number().required('Price is required*'),
            kid: Yup.number().required('Price is required*'),
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              const updatedData = {
                manTicketPrice: values.man,
                womanTicketPrice: values.woman,
                kidTicketPrice: values.kid,
              };

              await updatePool(activePoolId, updatedData);
              toast.success('Pool updated successfully!');
              setSubmitting(false);
            } catch (error) {
              setErrors({ submit: 'Failed to update pool data' });
              toast.error('An error occurred while updating pools.');
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            values,
            touched,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <div className='flex items-center justify-between w-full border-b border-gray-200'>
                <div className='flex items-center'>
                  {Object.keys(pools).map((tab) => (
                    <div key={tab}>
                      <div
                        className={`flex items-center px-5 py-3.5 ${
                          tab === activePool
                            ? 'bg-seashell bg-opacity-30 rounded-md'
                            : ''
                        } cursor-pointer`}
                        onClick={() => handleTabChange(tab, setFieldValue)}
                      >
                        <p className='text-bluish-grey ml-2'>{`${tab} Swimming Pool`}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <PermissionCheck
                  action={
                    activePool === 'INDOOR'
                      ? 'read_indoor_pools'
                      : 'read_outdoor_pools'
                  }
                >
                  <Link to={`/pools/history/${activePoolId}`} onClick={(event) => handleLinkClick(event, `/pools/history/${activePoolId}`)}>
                    <IconHistory
                      className='text-bluish-grey cursor-pointer'
                      size={18}
                      onClick={(event) => handleLinkClick(event, `/pools/history/${activePoolId}`)}
                    />
                  </Link>
                </PermissionCheck>
              </div>

              <AddTicket poolType={activePool} poolId={activePoolId} onClick={handlePrint} />

              <h3 className='text-bluish-grey font-medium uppercase mt-5'>
                New Swimming Deal
              </h3>
              <div className='border-b my-2' />
              <form onSubmit={handleSubmit}>
                <div className='grid grid-cols-3 gap-3 mt-5'>
                  <CustomInput
                    type='number'
                    name='man'
                    label='Man Ticket Price'
                    value={values.man}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.man && touched.man}
                    errorMessage={errors.man}
                    disabled={disableInputs}
                  />
                  <CustomInput
                    type='number'
                    name='woman'
                    label='Woman Ticket Price'
                    value={values.woman}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.woman && touched.woman}
                    errorMessage={errors.woman}
                    disabled={disableInputs}
                  />
                  <CustomInput
                    type='number'
                    name='kid'
                    label='Kid Ticket Price'
                    value={values.kid}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.kid && touched.kid}
                    errorMessage={errors.kid}
                    disabled={disableInputs}
                  />
                </div>
                {!disableInputs && (
                  <div className='flex justify-end items-center w-full mt-14 text-sm mb-4'>
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      width='w-1/5'
                      title='Save'
                    />
                  </div>
                )}
              </form>
            </>
          )}
        </Formik>
      </div>
      <TicketPrintComponent ref={printRef} />
    </div>
  );
}

const TicketPrintComponent = React.forwardRef((props, ref) => {
  const [item, setItem] = useState(null);
  const printContentRef = useRef();
  const printTriggerRef = useRef();

  useImperativeHandle(ref, () => ({
    setItem: (item) => setItem(item),
    triggerPrint: () => {
      if (printTriggerRef.current) {
        printTriggerRef.current.click();
      }
    },
  }));

  if (!item) return null;

  return (
    <>
      <ReactToPrint
        trigger={() => <button ref={printTriggerRef} style={{ display: 'none' }}>Print</button>}
        content={() => printContentRef.current}
      />
      <div style={{ display: 'none' }}>
        <PrintContent ref={printContentRef} item={item} />
      </div>
    </>
  );
});

const PrintContent = React.forwardRef(({ item }, ref) => {
  const { user } = useSelector((state) => state.user);

  return (
    <div ref={ref} className="bg-white rounded-lg shadow-lg px-8 py-10 max-w-xl mx-auto">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center">
          <img className="h-8 w-8 mr-2" src="/images/lake-side-logo.png" alt="Logo" style={{ maxWidth: '40px' }} />
          <div className="text-gray-700 font-semibold text-lg">LAKESIDE HOTEL</div>
        </div>
        <div className="text-gray-700">
          <div className="font-bold text-xl mb-2">INVOICE</div>
          <div className="text-sm">Date: {new Date().toLocaleDateString()}</div>
          <div className="text-sm">NR#: {Math.floor(Math.random() * 10000)}</div>
        </div>
      </div>
      <div className="border-b-2 border-gray-300 pb-8 mb-8">
        <h2 className="text-2xl font-bold mb-4">Bill To:</h2>
        <div className="text-gray-700 mb-2">{user.name} {user.surname}</div>
        <div className="text-gray-700 mb-2">{user.email}</div>
        <div className="text-gray-700 mb-2">{item.customerCityStatePostal}</div>
        <div className="text-gray-700">{item.customerEmail}</div>
      </div>
      <table className="w-full text-left mb-8">
        <thead>
          <tr>
            <th className="text-gray-700 font-bold uppercase py-2">Description</th>
            <th className="text-gray-700 font-bold uppercase py-2">Quantity</th>
            <th className="text-gray-700 font-bold uppercase py-2">Price</th>
            <th className="text-gray-700 font-bold uppercase py-2">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="py-4 text-gray-700">{item.ticketTitle}</td>
            <td className="py-4 text-gray-700">{item.count}</td>
            <td className="py-4 text-gray-700">{item.totalPrice}€</td>
            <td className="py-4 text-gray-700">{item.totalPrice}€</td>
          </tr>
        </tbody>
      </table>
      <div className="flex justify-end mb-8">
        <div className="text-gray-700 mr-2">Total:</div>
        <div className="text-gray-700">{item.totalPrice.toFixed(2)}€</div>
      </div>

      <div className="border-t-2 border-gray-300 pt-8 mb-8">
        <div className='my-6 text-2xl italic text-center'>THANK YOU</div>
      </div>
    </div>
  );
});

export default PoolsList;
import api from './api';

// Create a new blocked room
export const createBlockedRoom = (values) =>
  api.post(`/api/blocked-rooms`, values);

// Get all blocked rooms
export const getAllBlockedRooms = (page = 1, limit = 10) =>
  api.get(`/api/blocked-rooms?page=${page}&limit=${limit}`);

// Get a blocked room by ID
export const getBlockedRoomById = (id) =>
  api.get(`/api/blocked-rooms/${id}`);

// Update a blocked room
export const updateBlockedRoom = (id, values) =>
  api.patch(`/api/blocked-rooms/${id}`, values);

// Delete a blocked room
export const deleteBlockedRoom = (id) =>
  api.delete(`/api/blocked-rooms/${id}`);

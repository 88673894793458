import api from './api';

export const createConferenceRoomOrder = (orderData) => 
  api.post('/api/conference-room-orders', orderData);

export const getAllConferenceRoomOrders = () => 
  api.get('/api/conference-room-orders');

export const getConferenceRoomOrderById = (id) => 
  api.get(`/api/conference-room-orders/${id}`);

export const updateConferenceRoomOrder = (id, orderData) => 
  api.put(`/api/conference-room-orders/${id}`, orderData);

export const deleteConferenceRoomOrder = (id) => 
  api.delete(`/api/conference-room-orders/${id}`);

export const completeConferenceRoomOrder = (id, userId, amount) => 
  api.post(`/api/conference-room-orders/${id}/complete`, { userId, amount });
import React, { useEffect, useState } from 'react';
import { getUserGroups } from '../../api/chat';

const GroupList = ({ userId, onSelectGroup, onFirstGroupSelected }) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await getUserGroups(userId);
        console.log('Groups fetched:', response.data); 
        setGroups(response.data);
        if (response.data.length > 0) {
          onFirstGroupSelected(response.data[0].id);
        }
      } catch (error) {
        console.error('Error fetching user groups:', error);
      }
    };

    if (userId) {
      fetchGroups();
    }
  }, [userId, onFirstGroupSelected]);

  // return (
  //   <div className="p-4 border border-gray-300">
  //     <h2 className="text-lg font-semibold mb-4">Groups</h2>
  //     <ul>
  //       {groups.length === 0 ? (
  //         <li>No groups available</li>
  //       ) : (
  //         groups.map((group) => (
  //           <li
  //             key={group.id}
  //             onClick={() => onSelectGroup(group.id)}
  //             className="cursor-pointer p-2 hover:bg-gray-100"
  //           >
  //             {group.name}
  //           </li>
  //         ))
  //       )}
  //     </ul>
  //   </div>
  // );


  return (
    <div className="p-4 border-r border-gray-300 bg-gray-50 h-full overflow-y-auto">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">Groups</h2>
      {groups.length === 0 ? (
        <div className="flex items-center justify-center h-full text-gray-500">
          <span>No groups available</span>
        </div>
      ) : (
        <ul className="space-y-2">
          {groups.map((group) => (
            <li
              key={group.id}
              onClick={() => onSelectGroup(group.id)}
              className="cursor-pointer flex items-center p-3 rounded-lg hover:bg-blue-100 transition-colors duration-150 ease-in-out"
            >
              <span className="text-gray-700 font-medium">{group.name}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default GroupList;

import React from 'react';
import { Sliders } from 'lucide-react';

const ThresholdInput = ({ threshold, setThreshold }) => {
  const handleChange = (e) => {
    const value = Number(e.target.value);
    setThreshold(value);
  };

  return (
    <div className="mb-8 p-6 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg shadow-md">
      <div className="flex items-center mb-4">
        <Sliders className="w-6 h-6 text-indigo-600 mr-2" />
        <label className="text-lg font-semibold text-indigo-800">Reorder Threshold</label>
      </div>
      <div className="flex items-center">
        <input
          type="range"
          min="0"
          max="100"
          value={threshold}
          onChange={handleChange}
          className="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer"
        />
        <input
          type="number"
          value={threshold}
          onChange={handleChange}
          className="ml-4 w-16 px-2 py-1 text-center border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          min="0"
        />
      </div>
      <p className="mt-2 text-sm text-indigo-600">
        Items with stock below this threshold will be considered low stock.
      </p>
    </div>
  );
};

export default ThresholdInput;
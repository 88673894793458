import React from 'react';
import LoadingCircle from '../loading-circle';

export default function SubmitButton({ isSubmitting, width, title }) {
  return (
    <button
      type='submit'
      className={`bg-sage ${width} text-white rounded-md py-3 z-50`}
      disabled={isSubmitting}
    >
      {isSubmitting ? <LoadingCircle /> : title}
    </button>
  );
}

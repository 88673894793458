import api from './api';

// Create a new menu
export const createMenu = (menuData) => 
  api.post('/api/conferenceRoomMenu/menu', menuData);

// Get a menu by ID
export const getMenuById = (id) => 
  api.get(`/api/conferenceRoomMenu/menu/${id}`, {
    params: {
      include: [
        'breakfastItems',
        'lunchItems',
        'dinnerItems',
        'breakfastBarItems',
        'lunchBarItems',
        'dinnerBarItems'
      ].join(',')
    }
  });

// Update a menu
export const updateMenu = (id, menuData) => {
  // Format the data to match the new schema
  const formatMenuItems = (items, type, isBar = false) => {
    if (!items) return [];
    
    return items
      .filter(item => item && item.id)
      .map(item => ({
        id: item.id,
        name: item.name,
        description: item.description || '',
        price: parseFloat(item.price),
        type: type,
        isBarProduct: isBar,
        menuItemId: item.menuItemId,
        itemId: isBar ? null : item.id,
        barProductId: isBar ? item.id : null
      }));
  };

  const formattedData = {
    name: menuData.name,
    conferenceRoomId: parseInt(menuData.conferenceRoomId),
    price: parseFloat(menuData.price),
    // Regular menu items
    breakfastItems: formatMenuItems(menuData.breakfastItems, 'BREAKFAST', false),
    lunchItems: formatMenuItems(menuData.lunchItems, 'LUNCH', false),
    dinnerItems: formatMenuItems(menuData.dinnerItems, 'DINNER', false),
    // Bar menu items
    breakfastBarProducts: formatMenuItems(menuData.breakfastBarProducts, 'BREAKFAST', true),
    lunchBarProducts: formatMenuItems(menuData.lunchBarProducts, 'LUNCH', true),
    dinnerBarProducts: formatMenuItems(menuData.dinnerBarProducts, 'DINNER', true)
  };

  return api.put(`/api/conferenceRoomMenu/menu/${id}`, formattedData);
};

// Delete a menu
export const deleteMenu = (id) => 
  api.delete(`/api/conferenceRoomMenu/menu/${id}`);

// Add a menu item
export const addMenuItem = (menuId, name, description, price, type) => 
  api.post(`/api/conferenceRoomMenu/menu/${menuId}/item`, { name, description, price, type });

// Update a menu item
export const updateMenuItem = (id, name, description, price, type) => 
  api.put(`/api/conferenceRoomMenu/menu/item/${id}`, { name, description, price, type });

// Delete a menu item
export const deleteMenuItem = (id) => 
  api.delete(`/api/conferenceRoomMenu/menu/item/${id}`);

// Get all menus
export const getAllMenus = () => 
  api.get('/api/conferenceRoomMenu');

// Get conference room menus with menu items
export const getConferenceRoomMenus = () => 
  api.get('/api/conferenceRoomMenu', {
    params: {
      include: 'breakfastItems,lunchItems,dinnerItems'
    }
  });
import axios from 'axios';
import api from './api';


const BASE_URL = "https://api-staff.lakesideks.com";
// const BASE_URL = "http://localhost:3001";


export const verifyPasscode = (userId, passcode) =>
    api.post(`/api/passcode/verify`, { userId, passcode });


export const loginWithRoleAndPassword = (role, password) => {
    console.log(`Sending login request with role: ${role}`);
    return axios.post(`${BASE_URL}/api/passcode/login-with-role-and-password`, { role, password });
};


export const createPasscode = (data) => {
    return api.post('/api/passcode/passcodes', data);
};


export const loginWithPasscode = (passcode, urlSegment) => {
    console.log(`Sending login request with passcode: ${passcode}`);
    return axios.post(`${BASE_URL}/api/passcode/login-passcode`, { passcode, urlSegment });
  };

export const getAllRoles = () => api.get("/api/passcode/roles");
import React from "react";
import { TodoList } from "../../components/hotelInterfaceComponents/task/TodoList.tsx";

import Navbar from "../../components/hotelInterfaceComponents/Navbar.tsx";

function TodoListPage() {
  return (
    <div className="min-h-screen bg-seashell">
        <Navbar />
        <TodoList />
    </div>

);
}

export default TodoListPage;
import React, { useState, useEffect } from 'react';
import { ChevronRight } from 'lucide-react';
import { format } from 'date-fns';
import useSWR from 'swr';
import ReservationModal from '../ReservationModal.tsx';
import { fetcher } from '../../../api/api';
import ReservationDetailsModal from './reservationDetailsModal/ReservationDetailsModal.tsx';
import { getRoom } from '../../../api/rooms'; // Import the API function to get room details
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import api from "../../../api/api.js";
import { getReservationById } from '../../../api/reservationsv2.js';
import CheckOutModal from './checkOutModal/CheckOutModal.tsx';
import { getAllBlockedRooms } from '../../../api/blockedRooms'; // Import the API function to get blocked rooms
import BlockedRoomModal from './blockedRoomModal/BlockedRoomModal.tsx';
import { useSelector } from 'react-redux';
import RoomCleaningModal from '../../../views/hotelMangement/RoomCleaningModal.tsx';

// Update the import statement to use the correct path
import { getLatestCleaningReport } from '../../../api/cleaning.js'; 

// Add this new import statement
import { getReservationByRoomAndDate } from '../../../api/reservationsv2.js';

interface RoomGridProps {
  dates: Date[];
}

interface ReservationStatus {
  isReserved: boolean;
  isCheckIn: boolean;
  isCheckOut: boolean;
  name?: string;
  surname?: string;
  totalPrice?: number;
  paidPrice?: number;
  fromDate?: string;
  toDate?: string;
}

interface Reservation {
  id: string;
  roomNumber: string; // Adjust based on your actual reservation data structure
  fromDate: string;
  toDate: string;
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  adults: number;
  children: number;
  totalPrice: number;
  paidPrice: number;
  // Add any other relevant properties as needed
}

const RoomGrid = ({ dates }: RoomGridProps) => {
  
  const { user } = useSelector((state: { user: any }) => state.user);
  const isPastruseRole = user?.jobRole?.toLowerCase() === 'pastruese';

  console.log("pastruesee",isPastruseRole)


  const [isCleaningModalOpen, setIsCleaningModalOpen] = useState(false);
  const [cleaningRoomDetails, setCleaningRoomDetails] = useState<any>(null);


  const [isModalOpen, setIsModalOpen] = useState(true);
  const [selectedReservation, setSelectedReservation] = useState<Reservation | null>(null);
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<{ name: string; date: Date; id: string } | null>(null);
  const [activeRoomType, setActiveRoomType] = useState('');
  
  const [isCheckOutModalOpen, setIsCheckOutModalOpen] = useState(false);
  const [checkOutDetails, setCheckOutDetails] = useState<any>(null); // Adjust type as needed

  const [isBlockedRoomModalOpen, setIsBlockedRoomModalOpen] = useState(false);
  const [blockedRoomDetails, setBlockedRoomDetails] = useState(null);


  // Updated type definition for roomDetails
  const [roomDetails, setRoomDetails] = useState<{
    number: string;
    type: string;
    price: number;
    minibar: any[];
    inventory: any[]; // Add inventory to the type
  } | null>(null);

  // Fetch room types using SWR
  const { data: roomTypes, error: roomTypesError, isValidating: isLoadingRoomTypes } = useSWR('/api/room-types', fetcher);

  // Set initial active room type
  useEffect(() => {
    if (roomTypes && roomTypes.length > 0) {
      setActiveRoomType(roomTypes[0].id);
      setExpandedCategories([roomTypes[0].title]);
    }
  }, [roomTypes]);

  const toggleCategory = (category: string) => {
    setExpandedCategories(prev =>
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };
  
  const isReserved = (room: any, date: Date) => {
    if (!room || !room.reservations) return false; // for missong data
 
    const dateStr = format(date, 'yyyy-MM-dd');
    return room.reservations.some((reservation: any) => {
        const reservationStart = format(new Date(reservation.fromDate), 'yyyy-MM-dd');
        const reservationEnd = format(new Date(reservation.toDate), 'yyyy-MM-dd');
        return reservationStart <= dateStr && reservationEnd >= dateStr;
    });
};


const getReservationDetails = (room: any, date: Date) => {
  const dateStr = format(date, 'yyyy-MM-dd');
  const reservation = room.reservations?.find(
      (reservation: any) =>
          reservation.fromDate <= dateStr && reservation.toDate >= dateStr
  );

  if (reservation) {
      const fromDate = new Date(reservation.fromDate);
      const toDate = new Date(reservation.toDate);
      const duration = (toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24); // Calculate duration in days

      return {
          isReserved: true,
          fromDate,
          toDate,
          duration,
      };
  }

  return { isReserved: false };
};

  const getReservation = (room: any, date: Date) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    return room.reservations?.find(
      (reservation: any) =>
        reservation.startDate <= dateStr && reservation.endDate >= dateStr
    );
  };


  const isCheckInOrOut = (room: any, date: Date): ReservationStatus => {
    const dateStr = format(date, 'yyyy-MM-dd');
    const reservation = room.reservations?.find((res: any) => {
      const fromDate = new Date(res.fromDate);
      const toDate = new Date(res.toDate);
      return (
        format(fromDate, 'yyyy-MM-dd') === dateStr || format(toDate, 'yyyy-MM-dd') === dateStr
      );
    });

    if (reservation) {
      const isCheckIn = format(new Date(reservation.fromDate), 'yyyy-MM-dd') === dateStr;
      const isCheckOut = format(new Date(reservation.toDate), 'yyyy-MM-dd') === dateStr;
      return {
        isReserved: true,
        isCheckIn,
        isCheckOut,
        name: reservation.name,
        surname: reservation.surname,
        totalPrice: reservation.totalPrice,
        paidPrice: reservation.paidPrice,
        fromDate: reservation.fromDate,
        toDate: reservation.toDate,
      };
    }
    return { isReserved: false, isCheckIn: false, isCheckOut: false };
  };


  // Function to get cell classes based on reservation status
  const getCellClass = (reservationStatus) => {
    if (reservationStatus.isCheckIn) return 'bg-red-600 text-white font-semibold'; // Check-in style
    if (reservationStatus.isCheckOut) return 'bg-red-600 text-white font-semibold'; // Check-out style
    if (reservationStatus.isReserved) return 'bg-hazel-green text-white';           // Standard reserved style
    return 'hover:bg-silver';                                                        // Default style
  };

  const isCheckOutCell = (displayText: string): boolean => {
    return displayText === 'Checkout';
  };

  const handleCellClick = async (room: any, date: Date) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    const isRoomReserved = roomStatus[`${room.id}-${dateStr}`];


    // const reservationStatus = roomStatus[`${room.id}-${dateStr}`];
    const reservationStatus: ReservationStatus | any = roomStatus[`${room.id}-${dateStr}`];

    const blockedReason = getBlockedReason(room.id, date);

    let displayText = blockedReason || 
                      (reservationStatus?.isCheckIn ? 'Checkin' :
                      reservationStatus?.isCheckOut ? 'Checkout' :
                      reservationStatus?.isReserved ? 'Reserved' : 'Available');

    if (blockedReason) {
      const blockedRoom = blockedRooms.find(blockedRoom => 
          blockedRoom.roomId === room.id && 
          new Date(blockedRoom.startDate) <= date && 
          new Date(blockedRoom.endDate) >= date
      );

      if (blockedRoom) {
          
          setBlockedRoomDetails({
              roomId: blockedRoom.room.number,
              fromDate: blockedRoom.startDate, // Ensure this is the correct property
              toDate: blockedRoom.endDate, // Ensure this is the correct property
              reason: blockedRoom.reason,
              notes: blockedRoom.notes || '', // Ensure notes are included
          });
          setIsBlockedRoomModalOpen(true); // Open the blocked room modal
      }
      return; 
  }

    else if (displayText === 'Checkout') {
      try {
        // Get room details
        const roomResponse = await getRoom(room.id);
        const roomData = roomResponse.data;

        // Get reservation details including prices
        const reservationResponse = await getReservationByRoomAndDate(roomData.number, dateStr);
        const reservationData = reservationResponse.data;
        console.log('Reservation response', reservationResponse);


        // Get cleaning report
        const cleaningReportResponse = await getLatestCleaningReport(roomData.number);

        if (isPastruseRole) {
          // Logic for opening the Room Cleaning Modal
          const cleaningReportResponse = await getLatestCleaningReport(room.number);
          setCleaningRoomDetails({
            roomNumber: roomData.number,
            roomType: roomData.roomType.title,
            inventory: roomData.inventory || [],
            minibar: roomData.minibar?.products || [],
            date: date,
          });
          setIsCleaningModalOpen(true);
        }

        console.log("cleaning reposrt response",cleaningReportResponse);

        setCheckOutDetails({
          roomNumber: roomData.number,
          roomType: roomData.roomType?.title || "Standard",
          inventory: roomData.inventory || [],
          minibar: roomData.minibar?.products || [],
          date: date,
          fromDate: reservationData.reservation.fromDate,
          toDate: reservationData.reservation.toDate,
          name: reservationData.reservation.name,
          surname: reservationData.reservation.surname,
          totalPrice: reservationData.reservation.totalPrice || 0, // Now we get the correct price
          paidPrice: reservationData.reservation.paidPrice || 0,   // Now we get the correct paid amount
          cleaningReport: cleaningReportResponse?.data ? {
            roomChecklist: cleaningReportResponse.data.roomChecklist || [],
            minibarChecklist: cleaningReportResponse.data.minibarChecklist || [],
            inventoryChecklist: cleaningReportResponse.data.inventoryChecklist || [],
            cleaningDate: cleaningReportResponse.data.cleaningDate || '',
            roomCondition: cleaningReportResponse.data.roomCondition || '' ,
            cleanlinessLevel: cleaningReportResponse.data.cleanlinessLevel || '',
            issuesFound: cleaningReportResponse.data.issuesFound || '',
            issuesDescription: cleaningReportResponse.data.issuesDescription || '',
            additionalNotes: cleaningReportResponse.data.additionalNotes || '',
            cleaner: cleaningReportResponse.data.cleaner || ''
          } : undefined
        });

        setIsCheckOutModalOpen(true);

        
      } catch (error) {
        console.error('Error fetching checkout details:', error);
        // Handle error appropriately
      }
    }
    else if (isRoomReserved) {
        console.log("ROOM RESERVED");
        
        try {
            const response = await getRoom(room.id);
            const roomData = response.data;

            console.log('Room Data:', roomData);

            if (!roomData.reservations || roomData.reservations.length === 0) {
                console.error('No reservations found for room:', roomData);
                return;
            }

            // Set the room details including minibar
            setRoomDetails({ 
                number: roomData.number, 
                type: roomData.roomType.title, 
                price: roomData.roomType.price,
                minibar: roomData.minibar.products || [],
                inventory: roomData.inventory || []
            });

            // Find the relevant reservation based on the selected date
            const reservation = roomData.reservations.find((reservation: any) => {
                const reservationStart = format(new Date(reservation.fromDate), 'yyyy-MM-dd');
                const reservationEnd = format(new Date(reservation.toDate), 'yyyy-MM-dd');
                return reservationStart <= dateStr && reservationEnd >= dateStr;
            });

            if (reservation) {
                // Open the reservation details modal with the fetched data
                setSelectedReservation({
                    id: reservation.id,
                    roomNumber: roomData.number,
                    fromDate: reservation.fromDate,
                    toDate: reservation.toDate,
                    name: reservation.name,
                    surname: reservation.surname,
                    email: reservation.email,
                    phoneNumber: reservation.phoneNumber,
                    adults: reservation.adults,
                    children: reservation.children,
                    totalPrice: reservation.totalPrice,
                    paidPrice: reservation.paidPrice,
                });
                setIsModalOpen(true); // Open the modal
            } else {
                console.error('No reservation found for the selected room and date.');
            }
        } catch (error) {
            console.error('Error fetching room details:', error);
        }
    } else {
        // Existing logic for handling non-reserved rooms
        try {
            const response = await getRoom(room.id); // Ensure this API call returns the room with reservations
            const roomData = response.data;

            // Log the room data to check if reservations are included
            console.log('Room Data:', roomData);

            // Check if roomData has reservations
            if (!roomData.reservations) {
                console.error('No reservations found for room:', roomData);
                return;
            }

            // Extract relevant information from the room data
            const roomNumber = roomData.number;
            const roomType = roomData.roomType.title;
            const roomPrice = roomData.roomType.price;

            setSelectedRoom({ name: roomData.name || `Room ${roomNumber}`, date, id: room.id });
            setModalOpen(true);
            setRoomDetails({ 
                number: roomNumber, 
                type: roomType, 
                price: roomPrice,
                minibar: roomData.minibar.products || [],
                inventory: roomData.inventory || []
            });
        } catch (error) {
            console.error('Error fetching room details:', error);
        }
    }
};

  const [roomStatus, setRoomStatus] = useState<{ [key: string]: boolean }>({}); // Store room reservation status

  const [blockedRooms, setBlockedRooms] = useState<{ roomId: string; fromDate: string; toDate: string; reason: string }[]>([]); // State for blocked rooms with reason

  // Fetch blocked rooms using SWR
  useEffect(() => {
    const fetchBlockedRooms = async () => {
      try {
        const response = await getAllBlockedRooms(); // Fetch blocked rooms
        console.log(response)
        setBlockedRooms(response.data); // Assuming response.data contains the blocked rooms
      } catch (error) {
        console.error('Error fetching blocked rooms:', error);
      }
    };

    fetchBlockedRooms();
  }, []);

  // Function to check if a room is blocked and get the reason
  const getBlockedReason = (roomId: string, date: Date) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    const blockedRoom = blockedRooms.find(blockedRoom => {
      const blockedStart = new Date(blockedRoom.startDate);
      const blockedEnd = new Date(blockedRoom.endDate);
      return blockedRoom.roomId === roomId && blockedStart <= date && blockedEnd >= date;
    });
    return blockedRoom ? blockedRoom.reason : null; // Return the reason if found
  };

  useEffect(() => {
    // Function to fetch reservation status for rooms and populate roomStatus map
    const fetchRoomStatus = async () => {
      try {
        const response = await api.get(`/api/reservationsv2/rooms/status`, {
          params: {
            startDate: dates[0].toISOString(),
            endDate: dates[dates.length - 1].toISOString(),
          },
        });
        const reservations = response.data;

        const statusMap = {}; // Reservation status map

        reservations.forEach((reservation) => {
          reservation.rooms.forEach((room) => {
            const start = new Date(reservation.fromDate);
            const end = new Date(reservation.toDate);
            const duration = differenceInCalendarDays(end, start) + 1;

            for (let d = 0; d < duration; d++) {
              const currentDate = new Date(start);
              currentDate.setDate(start.getDate() + d);
              const dateStr = format(currentDate, 'yyyy-MM-dd');

              statusMap[`${room.roomId}-${dateStr}`] = {
                isReserved: true,
                isCheckIn: d === 0 && duration >= 3,
                isCheckOut: d === duration - 1 && duration >= 3,
              };
            }
          });
        });

        setRoomStatus(statusMap);
      } catch (error) {
        console.error('Error fetching room status:', error);
      }
    };

    fetchRoomStatus();
  }, [dates]);
  
  

  if (roomTypesError) {
    return (
      <div className="flex items-center justify-center h-64 text-watermelon">
        Failed to load room data
      </div>
    );
  }

  if (isLoadingRoomTypes || !roomTypes) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-hazel-green"></div>
      </div>
    );
  }

  return (
    <div className="overflow-auto custom-scrollbar bg-seashell rounded-lg shadow-md">
      <table className="w-full border-collapse bg-white">
        <thead className="sticky top-0 z-20 bg-tealish-blue text-bluish-grey shadow-sm border-b border-grey-goose">
          <tr>
            <th className="sticky left-0 z-30 bg-tealish-blue px-4 py-3 text-left min-w-[200px] border-r border-grey-goose">
              <span className="font-semibold text-lg">Apartment</span>
            </th>
            {dates.map((date) => (
              <th
                key={date.toISOString()}
                className="px-4 py-3 min-w-[100px] text-center border-l border-b border-grey-goose"
              >
                <div className="text-sm font-medium">{format(date, 'EEE d')}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {roomTypes.map((category: any) => (
            <React.Fragment key={category.id}>
              <tr className="bg-mercury hover:bg-heather transition-colors border-b border-grey-goose">
                <td
                  className="sticky left-0 z-20 px-4 py-4 text-center cursor-pointer bg-mercury border-r border-grey-goose"
                  onClick={() => toggleCategory(category.title)}
                >
                  <div className="flex items-center justify-center space-x-2 text-bluish-grey">
                    <ChevronRight
                      size={18}
                      className={`transform transition-transform ${
                        expandedCategories.includes(category.title) ? 'rotate-90' : ''
                      }`}
                    />
                    <span className="font-semibold text-base">{category.title}</span>
                  </div>
                </td>
                {dates.map((date) => (
                  <td
                    key={date.toISOString()}
                    className="px-4 py-4 text-center border-l border-b border-grey-goose"
                  >
                    <div className="text-base font-semibold text-bluish-grey">
                      {category.totalRooms}
                    </div>
                    <div className="text-sm text-hazel-green font-semibold">
                      {category.availableRooms}
                    </div>
                  </td>
                ))}
              </tr>

              {expandedCategories.includes(category.title) &&
                category.rooms?.map((room) => (
                  <tr key={room.id} className="hover:bg-tealish-blue transition-colors border-b border-grey-goose">
                    <td className="sticky left-0 z-10 px-4 py-4 bg-white border-r border-grey-goose text-bluish-grey">
                      {room.name || `Room ${room.number}`}
                    </td>
                    {dates.map((date) => {
                      const dateStr = format(date, 'yyyy-MM-dd');
                      const reservationStatus = roomStatus[`${room.id}-${dateStr}`] || {};
                      const blockedReason = getBlockedReason(room.id, date); // Get the reason for the blocked room

                      // Updated cell styling for consistency
                      const cellClass = blockedReason
                        ? 'bg-salmon-pink text-white font-semibold' // Blocked rooms in yellow with white text
                        : reservationStatus.isReserved
                        ? reservationStatus.isCheckIn || reservationStatus.isCheckOut
                          ? 'bg-sage text-white font-semibold' // Check-in and Check-out days in red with white text
                          : 'bg-hazel-green text-white font-semibold' // Other reserved days in green with white text
                        : 'hover:bg-silver';

                      // Display text based on reservation status
                      const displayText = blockedReason || (reservationStatus.isCheckIn ? 'Checkin' : reservationStatus.isCheckOut ? 'Checkout' : reservationStatus.isReserved ? 'Reserved' : '');

                      return (
                        <td
                          key={date.toISOString()}
                          onClick={() => handleCellClick(room, date)}
                          className={`px-4 py-4 text-center border-l border-b border-grey-goose cursor-pointer transition-colors ${cellClass}`}
                        >
                          {displayText && (
                            <div className="bg-white rounded px-2 py-1 text-xs font-semibold text-hazel-green">
                              {displayText}
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>


      {/* {isCheckOutModalOpen && checkOutDetails && (
        <CheckOutModal
          isOpen={isCheckOutModalOpen}
          onClose={() => setIsCheckOutModalOpen(false)}
          roomNumber={checkOutDetails.roomNumber}
          fromDate={checkOutDetails.fromDate}
          toDate={checkOutDetails.toDate}
          name={checkOutDetails.name}
          surname={checkOutDetails.surname}
          totalPrice={checkOutDetails.totalPrice}
          paidPrice={checkOutDetails.paidPrice}
        />
      )} */}

{isCheckOutModalOpen && checkOutDetails && !isPastruseRole && (
        <CheckOutModal
          isOpen={isCheckOutModalOpen}
          onClose={() => setIsCheckOutModalOpen(false)}
          roomNumber={checkOutDetails.roomNumber}
          fromDate={checkOutDetails.fromDate}
          toDate={checkOutDetails.toDate}
          name={checkOutDetails.name}
          surname={checkOutDetails.surname}
          totalPrice={checkOutDetails.totalPrice}
          paidPrice={checkOutDetails.paidPrice}
          cleaningReport={checkOutDetails.cleaningReport} // Ensure this is passed
        />
      )}

      {selectedRoom && roomDetails && ( 
        <ReservationModal
          isOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
            setSelectedRoom(null);
          }}
          roomName={selectedRoom.name}
          selectedDate={selectedRoom.date}
          roomNumber={roomDetails.number} 
          roomType={roomDetails.type}     
          price={roomDetails.price}     
          roomId={selectedRoom.id}        
          minibar={roomDetails.minibar}
          inventory={roomDetails.inventory}
        />
      )}

      {isModalOpen && selectedReservation && (
          <ReservationDetailsModal
              isOpen={isModalOpen}
              onClose={() => {
                  setIsModalOpen(false);
                  setSelectedReservation(null); 
              }}
              roomNumber={selectedReservation.roomNumber} 
              fromDate={selectedReservation.fromDate} 
              toDate={selectedReservation.toDate} 
              name={selectedReservation.name} 
              surname={selectedReservation.surname} 
              email={selectedReservation.email} 
              phoneNumber={selectedReservation.phoneNumber} 
              adults={selectedReservation.adults} 
              children={selectedReservation.children} 
              totalPrice={selectedReservation.totalPrice}
              paidPrice={selectedReservation.paidPrice} 
          />
      )}

{isBlockedRoomModalOpen && blockedRoomDetails && (
    <BlockedRoomModal
        isOpen={isBlockedRoomModalOpen}
        onClose={() => setIsBlockedRoomModalOpen(false)}
        roomNumber={blockedRoomDetails.roomId}
        fromDate={blockedRoomDetails.fromDate}
        toDate={blockedRoomDetails.toDate}
        reason={blockedRoomDetails.reason}
        notes={blockedRoomDetails.notes}
        status="BLOCKED"
    />
)}

{isCleaningModalOpen && cleaningRoomDetails && isPastruseRole && (
        <RoomCleaningModal
          isOpen={isCleaningModalOpen}
          onClose={() => setIsCleaningModalOpen(false)}
          roomNumber={cleaningRoomDetails.roomNumber}
          roomType={cleaningRoomDetails.roomType}
          inventory={cleaningRoomDetails.inventory}
          minibar={cleaningRoomDetails.minibar}
          date={cleaningRoomDetails.date}
        />
      )}

    </div>
  );
};

export default RoomGrid;
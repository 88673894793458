import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#90a68c',
    },
  },
});

const CustomSelectInput = ({
  name,
  value,
  label,
  options,
  onChange,
  onBlur,
  showError,
  errorMessage,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <div className='flex flex-col mb-3'>
        <FormControl fullWidth>
          <InputLabel htmlFor={name}>{label}</InputLabel>
          <Select
            id={name}
            name={name}
            value={value}
            label={label}
            onChange={onChange}
            onBlur={onBlur}
          >
            {options.map((option) => (
              <MenuItem value={option.id} key={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {showError && <p className='text-sm text-error'>{errorMessage}</p>}
      </div>
    </ThemeProvider>
  );
};

export default CustomSelectInput;

import React, { useState, useEffect, useRef } from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import { IconPlus, IconPencil, IconTrash, IconRefresh, IconBuildingWarehouse } from '@tabler/icons-react'; // Import IconRefresh for the refresh button
import Modal from '../../components/modal';
import ConfirmMessage from '../../components/alerts/alert-message';
import Loading from '../../components/loader';
import { fetcher } from '../../api/api';
import { getStockItems, deleteStockItem } from '../../api/stock';
import { getCategories, addCategory, deleteCategory, updateCategory as editCategory } from '../../api/stockCategories'; // Import API function to create categories
import { getStockLossItems, deleteStockLossItem, addStockLossItem } from '../../api/stockLoss';
import { updateStockLossQuantity } from '../../api/stockLoss';
import { updateStockItem } from '../../api/stock';
import { mutate } from 'swr';

import { useNavigate } from 'react-router-dom';


function StockManagement() {
  
  const navigate = useNavigate();

  const [selectedStockItemId, setSelectedStockItemId] = useState(''); // State for selected stock item
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showStockItemModal, setShowStockItemModal] = useState(false);
  const [newStockItem, setNewStockItem] = useState({ name: '', quantity: 0 });
  const [activeCategory, setActiveCategory] = useState(''); // New state for selected category
  const [newCategoryName, setNewCategoryName] = useState(''); // State for new category input
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [editCategoryId, setEditCategoryId] = useState(null); // State for editing category
  const [editCategoryName, setEditCategoryName] = useState(''); // State for edited category name

  // New state variables for stock losses
  const [activeLossCategory, setActiveLossCategory] = useState('');
  const [showStockLossModal, setShowStockLossModal] = useState(false);
  // const [newStockLossItem, setNewStockLossItem] = useState({ name: '', quantity: 0 });
  const [newStockLossItem, setNewStockLossItem] = useState({ quantity: 0, reason: '' });

  const [showTransferModal, setShowTransferModal] = useState(false);
  const [transferItem, setTransferItem] = useState(null);
  const [transferQuantity, setTransferQuantity] = useState(1);
  const [transferReason, setTransferReason] = useState('');

  const [stockItems, setStockItems] = useState([]); // State for stock items
  const [stockLossItems, setStockLossItems] = useState([]); // State for stock loss items
  const [filteredStockItems, setFilteredStockItems] = useState([]); // State for filtered stock items

  const transferQuantityRef = useRef(1); // Use a ref for transfer quantity

  const { data: stockCategories = [], error: categoryError, isValidating: isValidatingCategories, mutate: mutateCategories } = useSWR(
    '/api/categories',
    () => getCategories().then(res => res.data)
  );

  // Fetch stock items
  const { data: fetchedStockItems = [], error: stockError, isValidating: isValidatingStockItems, mutate: mutateStockItems } = useSWR(
    activeCategory ? `/api/stock?category=${activeCategory}` : null,
    fetcher
  );

  // Fetch stock loss items
  const { data: fetchedStockLossItems = [], error: stockLossError, isValidating: isValidatingStockLossItems, mutate: mutateStockLossItems } = useSWR(
    '/api/stock-loss',
    fetcher
  );

  // Update state when data is fetched
  useEffect(() => {
    if (fetchedStockItems) {
      setStockItems(fetchedStockItems);
    }
    if (fetchedStockLossItems) {
      setStockLossItems(fetchedStockLossItems);
    }
  }, [fetchedStockItems, fetchedStockLossItems]);

  useEffect(() => {
    if (stockCategories.length) {
      setActiveCategory(stockCategories[0]?.id || ''); // Set the default active category
    }
  }, [stockCategories]);

  useEffect(() => {
    if (stockCategories.length) {
      setActiveLossCategory(stockCategories[0]?.id || '');
    }
  }, [stockCategories]);

  // Effect to filter stock items based on the selected category
  useEffect(() => {
    if (activeCategory) {
      const itemsInCategory = stockItems.filter(item => item.categoryId === activeCategory);
      setFilteredStockItems(itemsInCategory);
    } else {
      setFilteredStockItems(stockItems); // Reset if no category is selected
    }
  }, [activeCategory, stockItems]); // Run this effect when activeCategory or stockItems change

  const handleDeleteStockItem = async () => {
    try {
      await deleteStockItem(selectedStockItemId);
      setShowDeleteAlert(false);
      setSelectedStockItemId(null);
      mutateStockItems(); // Refresh stock items
    } catch (err) {
      console.error('Failed to delete stock item:', err);
    }
  };

  const handleAddStockItem = async () => {
    // Add logic to add a new stock item
    // Use the API for adding stock items
    setShowStockItemModal(false);
  };

  const handleEditCategory = async () => {
    try {
      if (editCategoryName.trim()) {
        await editCategory(editCategoryId, { name: editCategoryName }); // Use API to edit category
        setEditCategoryId(null); // Reset edit category id
        setEditCategoryName(''); // Reset edit category name
        mutateCategories(); // Re-fetch categories
      }
    } catch (err) {
      console.error('Failed to edit category:', err);
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      await deleteCategory(id); // Use API to delete category
      mutateCategories(); // Re-fetch categories
    } catch (err) {
      console.error('Failed to delete category:', err);
    }
  };

  const handleAddStockLossItem = async () => {
    try {
      // Find an existing stock loss item with the same stockItemId and reason
      const existingStockLossItem = stockLossItems.find(
        lossItem => lossItem.stockItemId === selectedStockItemId && lossItem.reason === newStockLossItem.reason
      );
  
      if (existingStockLossItem) {
        // If the item with the same stockItemId and reason exists, update its quantity
        await updateStockLossQuantity(
          existingStockLossItem.id, 
          existingStockLossItem.quantity + newStockLossItem.quantity
        );
      } else {
        // If there's no existing item with the same stockItemId and reason, create a new one
        await addStockLossItem({
          stockItemId: selectedStockItemId, // Ensure the correct stockItemId is sent
          quantity: newStockLossItem.quantity,
          reason: newStockLossItem.reason, // Include reason to differentiate
        });
      }
  
      // Reset the modal and state after the operation
      setNewStockLossItem({ name: '', quantity: 0, reason: '' }); // Reset reason as well
      setShowStockLossModal(false);
      mutateStockLossItems(); // Refresh stock loss items
    } catch (err) {
      console.error('Failed to add or update stock loss item:', err);
    }
  };
  
  



  const handleTransferSubmit = async () => {
    if (!transferItem) {
        console.error('No item selected for transfer.');
        return;
    }

    if (transferQuantity > transferItem.stockQuantity) {
        alert('Not enough stock available for transfer.');
        return;
    }

    const dataToSend = {
        stockItemId: transferItem.id,
        quantity: transferQuantity,
        reason: transferReason,
    };

    console.log("Data from the transfer reason", dataToSend)

    try {
        // Check if stock loss item already exists
        const existingStockLossItems = stockLossItems.filter(lossItem => lossItem.stockItemId === transferItem.id);
        const existingItemWithSameReason = existingStockLossItems.find(lossItem => lossItem.reason === transferReason);

        // Handle stock loss item update or addition
        if (existingItemWithSameReason) {
            await updateStockLossQuantity(existingItemWithSameReason.id, existingItemWithSameReason.quantity + transferQuantity);
        } else {
            await addStockLossItem(dataToSend);
        }

        // Update stock quantity in the database
        const updatedQuantity = transferItem.stockQuantity - transferQuantity;
        await updateStockItem(transferItem.id, { stockQuantity: updatedQuantity });

        // Update local state for stock items immediately
        setStockItems(prevItems => 
            prevItems.map(item => 
                item.id === transferItem.id ? { ...item, stockQuantity: updatedQuantity } : item
            )
        );

        // Use SWR's mutate function to ensure the updated state is reflected without fetching from the server
        mutate(`/api/stock?category=${activeCategory}`, prevItems => 
            prevItems.map(item => 
                item.id === transferItem.id ? { ...item, stockQuantity: updatedQuantity } : item
            ), false
        );

        // Reset modal state
        setTransferQuantity(1);
        setTransferReason('');
        setShowTransferModal(false);
        mutateStockLossItems(); // Refresh stock loss items

        // Debugging logs
        console.log("Stock items after transfer:", stockItems);
    } catch (error) {
        console.error('Error transferring stock to loss:', error);
    }
};

  const openTransferModal = (item) => {
    setTransferQuantity(1); // Reset quantity
    setTransferReason(''); // Reset reason
    setTransferItem(item); // Set the current item to transfer
    setShowTransferModal(true);
  };

  // Ensure transfer quantity is updated in the ref
  const handleTransferQuantityChange = (e) => {
    const value = Number(e.target.value);
    setTransferQuantity(value);
    transferQuantityRef.current = value; // Update the ref
  };

  if (categoryError || stockError) return <div>Error loading data...</div>;
  if (isValidatingCategories || isValidatingStockItems) return <Loading />;

  // Filter stock loss items based on the active category
  const filteredStockLossItems = stockLossItems.filter(item => item.stockItem.categoryId === activeLossCategory);



  const handleClickStockStateButton = () => {
    navigate("/stock-state")
  }
  return (
    <div className='p-4 md:p-10'>
      {/* Title and Buttons Section */}
      <div className='flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8 gap-4'>
        <h1 className='text-2xl font-bold text-hazel-green'>Stock Management</h1>
        <div className='flex flex-wrap gap-3 w-full sm:w-auto'>
          <button onClick={() => mutateStockItems()} className='flex items-center text-white rounded-md bg-sage px-4 py-2.5 text-sm md:text-base w-full sm:w-auto'>
            <IconRefresh className='mr-2' size={20} />
            Refresh
          </button>
          <button onClick={handleClickStockStateButton} className='flex items-center text-white rounded-md bg-sage px-4 py-2.5 text-sm md:text-base w-full sm:w-auto'>
            <IconBuildingWarehouse className='mr-2' size={20} />
            Stock State
          </button>
          <Link to='/stock/create-product' className='flex items-center text-white rounded-md bg-sage px-4 py-2.5 text-sm md:text-base w-full sm:w-auto'>
            <IconPlus className='mr-2' size={20} />
            Add Stock Item
          </Link>
          <button
            onClick={() => setShowStockItemModal(true)}
            className='flex items-center text-white rounded-md bg-sage px-4 py-2.5 text-sm md:text-base w-full sm:w-auto'
          >
            <IconPlus className='mr-2' size={20} />
            Create Purchase Order
          </button>
        </div>
      </div>

      {/* Category Tabs Section */}
      <div className='border border-gray-200 rounded-xl shadow-lg px-2 md:px-3 mt-5'>
        <div className='flex flex-wrap md:flex-nowrap items-center justify-between w-full border-b border-gray-200 overflow-x-auto py-2'>
          <div className='flex flex-wrap md:flex-nowrap items-center gap-2 w-full md:w-auto'>
            {stockCategories.map(category => (
              <div
                key={category.id}
                className='relative flex-shrink-0'
                onMouseEnter={() => setHoveredCategory(category.id)}
                onMouseLeave={() => setHoveredCategory(null)}
              >
                <div
                  className={`relative flex items-center px-5 py-3.5 whitespace-nowrap ${category.id === activeCategory ? 'bg-seashell bg-opacity-30 rounded-md' : ''} cursor-pointer`}
                  onClick={() => setActiveCategory(category.id)}
                >
                  <p className='text-bluish-grey capitalize ml-2'>{category.name}</p>
                  {hoveredCategory === category.id && (
                    <div className='absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 rounded-md'>
                      <div className='flex gap-x-2'>
                        <button
                          onClick={() => {
                            setEditCategoryId(category.id);
                            setEditCategoryName(category.name);
                          }}
                          className='text-bluish-grey'
                        >
                          <IconPencil size={20} />
                        </button>
                        <button
                          onClick={() => {
                            if (window.confirm('Are you sure you want to delete this category?')) {
                              handleDeleteCategory(category.id);
                            }
                          }}
                          className='text-bluish-grey'
                        >
                          <IconTrash size={20} />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <Link
            to='/stock/new-category'
            className='flex items-center text-white rounded-md bg-sage px-4 py-2.5 mt-2 md:mt-0 w-full md:w-auto justify-center'
          >
            <IconPlus className='mr-2' size={20} />
            Add Category
          </Link>
        </div>

        {/* Stock Items Grid */}
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-4 px-2 md:px-3'>
          {filteredStockItems.length ? (
            filteredStockItems.map((item) => (
              <div key={item.id} className='bg-seashell p-4 rounded-md shadow-sm'>
                <h3 className='text-xl md:text-2xl font-semibold text-bluish-grey mb-2'>{item.name}</h3>
                
                <div className='space-y-2'>
                  <p className='flex justify-between items-center text-gray-600 text-sm md:text-md'>
                    <span className='font-medium'>Quantity:</span>
                    <span className='font-bold'>{item.stockQuantity} units</span>
                  </p>

                  <p className='flex justify-between items-center text-gray-600 text-sm md:text-md'>
                    <span className='font-medium'>Reorder Level:</span>
                    <span className='font-bold'>{item.reorderLevel || 'Not set'} units</span>
                  </p>

                  <div className='flex justify-between items-center text-gray-600 text-sm md:text-md pt-2 border-t border-gray-300'>
                    <span className='font-medium'>Stock After Reorder:</span>
                    <span className='font-bold text-hazel-green'>
                      {item.stockQuantity - (item.reorderLevel || 0)} units
                    </span>
                  </div>
                </div>

                <div className='flex flex-wrap gap-3 mt-3'>
                  <Link to={`/stock/${item.id}`}>
                    <IconPencil className='text-sage cursor-pointer' size={18} />
                  </Link>
                  <IconTrash
                    className='text-sage cursor-pointer'
                    size={18}
                    onClick={() => {
                      setSelectedStockItemId(item.id);
                      setShowDeleteAlert(true);
                    }}
                  />
                  <button
                    onClick={() => openTransferModal(item)}
                    className='text-sage text-sm'
                  >
                    Transfer to Loss
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className='text-sage font-bold py-10 px-8 text-center col-span-full'>
              No stock items available for this category
            </p>
          )}
        </div>
      </div>

      {/* Stock Loss Section */}
      <div className='mt-8 md:mt-16'>
        <div className='flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8'>
          <h2 className='text-xl md:text-2xl font-bold text-hazel-green'>Stock Loss Management</h2>
          <button
            onClick={() => setShowStockLossModal(true)}
            className='flex items-center text-white rounded-md bg-sage px-4 py-2.5 w-full sm:w-auto justify-center'
          >
            <IconPlus className='mr-2' size={20} />
            Add Stock Loss Item
          </button>
        </div>

        {/* Stock Loss Items Grid */}
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-4 px-2 md:px-3'>
          {filteredStockLossItems.length ? (
            filteredStockLossItems.map((item) => (
              <div key={item.id} className='bg-dawn-pink p-4 md:p-6 rounded-lg shadow-md'>
                <h3 className='text-xl md:text-2xl font-semibold text-bluish-grey mb-3'>
                  {item.stockItem.name}
                </h3>
                
                <div className='space-y-2 text-sm md:text-base'>
                  <p className='text-gray-700'>
                    <span className='font-bold'>Quantity Lost:</span> {item.quantity}
                  </p>
                  <p className='text-gray-700'>
                    <span className='font-bold'>Reason:</span> {item.reason}
                  </p>
                  <p className='text-gray-700'>
                    <span className='font-bold'>Date:</span> {new Date(item.createdAt).toLocaleDateString()}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p className='text-sage font-bold py-10 px-8 text-center col-span-full'>
              No stock loss items available for this category
            </p>
          )}
        </div>
      </div>

      {/* Modal for Adding Stock Item */}
      {showStockItemModal && (
        <Modal onClose={() => setShowStockItemModal(false)}>
          <div>
            <h2 className='text-lg font-bold mb-4'>Create New Purchase Order</h2>
            <input
              type='text'
              placeholder='Stock Item Name'
              value={newStockItem.name}
              onChange={(e) => setNewStockItem({ ...newStockItem, name: e.target.value })}
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            <input
              type='number'
              placeholder='Quantity'
              value={newStockItem.quantity}
              onChange={(e) => setNewStockItem({ ...newStockItem, quantity: Number(e.target.value) })}
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            <button
              onClick={handleAddStockItem}
              className='bg-sage text-white py-2 px-4 rounded-md'
            >
              Add Stock Item
            </button>
          </div>
        </Modal>
      )}

      {/* Modal for Editing Category */}
      {editCategoryId && (
        <Modal onClose={() => setEditCategoryId(null)}>
          <div>
            <h2 className='text-lg font-bold mb-4'>Edit Category</h2>
            <input
              type='text'
              value={editCategoryName}
              onChange={(e) => setEditCategoryName(e.target.value)}
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            <button
              onClick={handleEditCategory}
              className='bg-sage text-white py-2 px-4 rounded-md'
            >
              Save Changes
            </button>
          </div>
        </Modal>
      )}

{/* Modal for Adding Stock Loss Item */}
{showStockLossModal && (
  <Modal onClose={() => setShowStockLossModal(false)}>
    <div>
      <h2 className='text-lg font-bold mb-4'>Add Stock Loss Item</h2>
      
      <select
        value={activeLossCategory}
        onChange={(e) => {
          setActiveLossCategory(e.target.value);
          setSelectedStockItemId(''); // Reset selected stock item when category changes
        }}
        className='border border-gray-300 p-2 mb-4 w-full'
      >
        <option value="">Select Category</option>
        {stockCategories.map(category => (
          <option key={category.id} value={category.id}>{category.name}</option>
        ))}
      </select>
      
      <select
        value={selectedStockItemId}
        onChange={(e) => setSelectedStockItemId(e.target.value)}
        className='border border-gray-300 p-2 mb-4 w-full'
      >
        <option value="">Select Stock Item</option>
        {/* Filter items based on the activeLossCategory */}
        {filteredStockItems
          .filter(item => item.categoryId === activeLossCategory) // Filter items by category
          .map(item => (
            <option key={item.id} value={item.id}>{item.name}</option>
          ))}
      </select>
      
      <input
        type='number'
        placeholder='Quantity Lost'
        value={newStockLossItem.quantity}
        onChange={(e) => setNewStockLossItem({ ...newStockLossItem, quantity: Number(e.target.value) })}
        className='border border-gray-300 p-2 mb-4 w-full'
      />
      
      {/* New input field for reason */}
      <input
        type='text'
        placeholder='Reason for Loss'
        value={newStockLossItem.reason}
        onChange={(e) => setNewStockLossItem({ ...newStockLossItem, reason: e.target.value })}
        className='border border-gray-300 p-2 mb-4 w-full'
      />
      
      <button
        onClick={handleAddStockLossItem}
        className='bg-sage text-white py-2 px-4 rounded-md'
      >
        Add Stock Loss Item
      </button>
    </div>
  </Modal>
)}


      {showDeleteAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this stock item?'
          onCancel={() => setShowDeleteAlert(false)}
          onConfirm={handleDeleteStockItem}
        />
      )}

      {showTransferModal && (
        <Modal onClose={() => setShowTransferModal(false)}>
          <div>
            <h2 className='text-lg font-bold mb-4'>Transfer to Stock Loss</h2>
            <input
              type='number'
              placeholder='Quantity'
              value={transferQuantity}
              onChange={handleTransferQuantityChange} // Use the new handler
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            <input
              type='text'
              placeholder='Reason for Loss'
              value={transferReason}
              onChange={(e) => setTransferReason(e.target.value)}
              className='border border-gray-300 p-2 mb-4 w-full'
            />
            <button
              onClick={handleTransferSubmit}
              className='bg-sage text-white py-2 px-4 rounded-md'
            >
              Submit Transfer
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default StockManagement;
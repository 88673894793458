

import React, { useState, useEffect } from 'react';
import {
  Cut,
  Line,
  Printer,
  Text,
  render,
  QRCode,
  Image
} from "react-thermal-printer";
import { Formik } from 'formik';
import * as Yup from 'yup';
// import moment from 'moment';
import { SubmitButton } from '../../components/buttons';
import { getPools, createPoolReservation} from '../../api/pools';
import {  generateINPFile } from  '../../api/inpFile';

import { getPort, closePort } from './serialPortManager';


const AddTicket = ({ poolType, poolId }) => {
  const [pools, setPools] = useState({});

  useEffect(() => {
    async function fetchPools() {
      try {
        const response = await getPools();
        const poolsData = response.data.reduce((acc, pool) => {
          acc[pool.type] = {
            id: pool.id,
            tickets: pool.tickets
          };
          return acc;
        }, {});
        setPools(poolsData);
      } catch (error) {
        console.error('Failed to fetch pools:', error);
      }
    }

    fetchPools();
  }, []);

  const getTicketImage = (type) => {
    if (type === 'WOMAN') {
      return 'https://scontent.fprn4-1.fna.fbcdn.net/v/t1.15752-9/448838960_838378207849442_8537274201646011122_n.png?_nc_cat=101&ccb=1-7&_nc_sid=9f807c&_nc_ohc=B4WyjUUjFbwQ7kNvgEMJeak&_nc_ht=scontent.fprn4-1.fna&oh=03_Q7cD1QGXzq0LnRoAs-6-61QscPkNlKM54Sjzvm5sgRJeh8bRFQ&oe=66B74829';
    } else if (type === 'MAN') {
      return 'https://scontent.fprn4-1.fna.fbcdn.net/v/t1.15752-9/449683173_1667776560672670_756979275964704068_n.png?_nc_cat=110&ccb=1-7&_nc_sid=9f807c&_nc_ohc=c64DeVc3c6AQ7kNvgHF-kqo&_nc_ht=scontent.fprn4-1.fna&oh=03_Q7cD1QE-04KPjZjaryYqyQuEhL1j6M1ngdTVANefv77jVqzvGg&oe=66B734BC';
    } else return 'https://scontent.fprn4-1.fna.fbcdn.net/v/t1.15752-9/448905717_1245950660148007_8607451711283834830_n.png?_nc_cat=111&ccb=1-7&_nc_sid=9f807c&_nc_ohc=zv088r--zAUQ7kNvgEqAM54&_nc_ht=scontent.fprn4-1.fna&oh=03_Q7cD1QHLdf6zt-DKFI_9IXNwfmCxfHc_PqlKwVQnKq28n9_3hw&oe=66B73F87';
  };

  const handlePrint = async (data) => {

    // const reservationData = { price: data.price };
    // await generateINPFile(reservationData);

    const { id, price, ticketType } = data; 
    const reservationData = { id, price, ticketType };
    await generateINPFile(reservationData);

    const receiptContent = await render(


      <Printer type="epson">
        <Image align="center" src="https://scontent.fprn4-1.fna.fbcdn.net/v/t1.15752-9/448909287_2753223888169196_8294231871019942389_n.png?_nc_cat=107&ccb=1-7&_nc_sid=9f807c&_nc_ohc=kRZLE2CpIIoQ7kNvgG6yT1M&_nc_ht=scontent.fprn4-1.fna&oh=03_Q7cD1QF3sALjRmEr7_HfRKC4hW1sIF0FwdL1lkkFi49tCtB5ug&oe=66B71D73" />
        <Line />
        <Text bold={true} size={{ width: 1.8, height: 1.8 }}>ÇMIMI: {data.price} Euro</Text>
        <Text bold={true} size={{ width: 1.8, height: 1.8 }}>DATA:ORA:</Text>
        <Line />
        <Image align="center" src={getTicketImage(data.ticketType)} />
        <QRCode align="center" size={{ width: 1.8, height: 1.8 }} content="https://lakesideks.com/" />
        <Cut lineFeeds={7} />
      </Printer>
    );

  //   try {
  //     const port = await navigator.serial.getPorts();
  //     console.log(port);
  //     if (!port[0].readable && !port[0].writable) {
  //       await port[0].open({ baudRate: 9600 });
  //     }

  //     const writer = port[0].writable?.getWriter();

  //     if (writer) {
  //       const encodedData = new Uint8Array(receiptContent);
  //       await writer.write(encodedData);
  //       await writer.releaseLock(); // Release the writer lock
  //       await port[0].close(); // Close the port after use
  //     } else {
  //       console.error("Failed to get writer from serial port.");
  //     }
  //   } catch (error) {
  //     console.error("Failed to print receipt", error);
  //   }

  //   try {
  //     // Request a port and grant permission
  //     const port = await navigator.serial.requestPort();
  //     console.log('Port requested:', port);
  
  //     // Get all ports the user has previously granted the app access to
  //     const ports = await navigator.serial.getPorts();
  //     console.log('Available ports:', ports);
  
  //     if (ports.length === 0) {
  //       console.log('No serial ports available.');
  //     } else {
  //       console.log('Serial ports found:', ports);
  //     }

  //     console.log(port);
  //   if (!port[0].readable && !port[0].writable) {
  //     await port[0].open({ baudRate: 9600 });
  //   }

    // const writer = port[0].writable?.getWriter();

    // if (writer) {
    //   const encodedData = new Uint8Array(receiptContent);
    //   await writer.write(encodedData);
    //   await writer.releaseLock(); // Release the writer lock
    //   await port[0].close(); // Close the port after use
    // } else {
    //   console.error("Failed to get writer from serial port.");
    // }
  //   } catch (error) {
  //     console.error('Error requesting or getting ports:', error);
  //   }

      // Request a port
      const port = await navigator.serial.requestPort();
      console.log('Port requested:', port);
  
      // Open the port with the desired configuration
      await port.open({ baudRate: 9600 });
      console.log('Port opened:', port);


      
      // Check if the port has readable and writable streams
      if (port.readable && port.writable) {
        console.log('Port is ready for reading and writing');
        
        // Set up a reader
        const reader = port.readable.getReader();


        const writer = port.writable?.getWriter();

        if (writer) {
          const encodedData = new Uint8Array(receiptContent);
          await writer.write(encodedData);
          await writer.releaseLock(); // Release the writer lock

          
          setTimeout(async () => {
            await closePort();
            port.close();
            console.log('Port closed after timeout');
          }, 2000);

          // await port.close(); 
        } else {
          console.error("Failed to get writer from serial port.");
        }
  
        // Read data from the port
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            // Allow the serial port to be closed later.
            reader.releaseLock();
            break;
          }
          // Process the data read from the port
          console.log('Data received:', new TextDecoder().decode(value));
        }
      } else {
        throw new Error('Port is not readable or writable');
      }
  
  // async function handlePrint() {
  //   try {
  //     await requestAndOpenPort();
  //     console.log('Port connected and ready to print');
  //     // Add your printing logic here
  //   } catch (error) {
  //     console.error('Error during printing:', error);
  //   }
  // }
  
  // Example function call
  
  };

  return (
    <div className='grid grid-cols-3 gap-4 my-4'>
      {pools[poolType]?.tickets?.map((item, index) => (
        <Formik
          key={index}
          initialValues={{
            ticketTitle: item.title,
            price: item.price
          }}
          validationSchema={Yup.object().shape({
            ticketTitle: Yup.string(),
            price: Yup.number()
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              const payload = {
                poolId,
                ticketType: values.ticketTitle.toUpperCase(),
                price: values.price
              };

              const response = await createPoolReservation(payload);
              const { data } = response;

              await handlePrint(data);
              // handlePrint(data);

              setSubmitting(false);
            } catch (err) {
              setErrors({
                submit: err?.response?.data
              });
              setSubmitting(false);
            }
          }}
        >
          {({ values, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className='flex justify-center items-center p-5 bg-seashell rounded-md h-24'>
                <SubmitButton
                  isSubmitting={isSubmitting}
                  width='w-1/2'
                  title={`Add ${values.ticketTitle} ticket`}
                />
              </div>
            </form>
          )}
        </Formik>
      ))}
    </div>
  );
};

export default AddTicket;
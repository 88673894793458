// import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import { Formik } from 'formik';
// import * as Yup from 'yup';
// import { IconHistory } from '@tabler/icons-react';
// import AddTicket from './AddTicketInterface';
// import CustomInput from '../../components/custom-input';
// import PermissionCheck from '../../utils/PermissionCheck';
// import { SubmitButton } from '../../components/buttons';
// import { getPools, updatePool } from '../../api/pools';
// import useAuth from '../../hooks/useAuth';
// import ReactToPrint from 'react-to-print';
// import { useSelector } from 'react-redux';
// import axios from 'axios';
// import { closePort } from '../pools/serialPortManager';
// import { SlidingTabBar } from '../../components/SlidingTabBar.js';

// import { cn } from '../../utils/cn.ts';
// import { GridPattern } from './GridPattern';

// function PoolsInterfaceList() {
//   const { userPermissions } = useAuth();
//   const [pools, setPools] = useState({});
//   const [activePool, setActivePool] = useState('OUTDOOR');
//   const [activePoolId, setActivePoolId] = useState(null);
//   const printRef = useRef();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchPools = async () => {
//       try {
//         const response = await getPools();
//         const poolsData = response.data.reduce((acc, pool) => {
//           acc[pool.type] = {
//             id: pool.id,
//             man: pool.tickets.find((ticket) => ticket.title === 'man').price,
//             woman: pool.tickets.find((ticket) => ticket.title === 'woman').price,
//             kid: pool.tickets.find((ticket) => ticket.title === 'kid').price,
//           };
//           return acc;
//         }, {});

//         if (!userPermissions.includes('read_indoor_pools')) {
//           delete poolsData['INDOOR'];
//         }

//         if (!userPermissions.includes('read_outdoor_pools')) {
//           delete poolsData['OUTDOOR'];
//         }
//         setPools(poolsData);
//         const firstPoolType = Object.keys(poolsData)[0];
//         setActivePool(firstPoolType);
//         setActivePoolId(poolsData[firstPoolType].id);
//       } catch (error) {
//         console.error('Failed to fetch pools data', error);
//       }
//     };
//     fetchPools();
//   }, [userPermissions]);

//   const handleTabChange = (tab, setFieldValue) => {
//     setActivePool(tab);
//     setActivePoolId(pools[tab].id);
//     setFieldValue('man', pools[tab].man);
//     setFieldValue('woman', pools[tab].woman);
//     setFieldValue('kid', pools[tab].kid);
//   };

//   const handleLinkClick = async (event, url) => {
//     event.preventDefault();
//     await closePort();
//     navigate(url);
//   };

//   const handlePrint = async (item) => {
//     const saleData = { product: 'Ticket', price: item.totalPrice };

//     console.log('Sending data to generate INP file:', saleData);

//     await axios.post('http://localhost:3001/generate-sale', saleData);

//     printRef.current.setItem(item);
//     setTimeout(() => {
//       printRef.current.triggerPrint();
//     }, 500);
//   };

//   if (!pools[activePool]) return null;

//   const poolPrices = pools[activePool];

//   const disableInputs =
//     activePool === 'INDOOR'
//       ? !userPermissions.includes('edit_indoor_pools')
//       : !userPermissions.includes('edit_outdoor_pools');

//   return (
//     <div className="p-6 relative bg-slate-100 min-h-screen w-full flex flex-col items-center justify-center gap-6">

//             <SlidingTabBar className="z-20"/>
//             <div className="absolute inset-0 z-1">
//                 <GridPattern
//                     width={20}
//                     height={20}
//                     x={-1}
//                     y={-1}
//                     className={cn(
//                     "[mask-image:linear-gradient(to_bottom_right,white,transparent,transparent)]"
//                     )}
//                 />
//             </div>
//             <div className="max-w-6xl mx-auto bg-white border border-gray-200 rounded-xl shadow-lg p-6">
//         <div className="flex items-center justify-between mb-4 border-b pb-4">
//           <div className="flex space-x-4">
//             {Object.keys(pools).map((tab) => (
//               <button
//                 key={tab}
//                 className={`py-2 px-4 rounded-lg text-sm font-medium ${
//                   tab === activePool
//                     ? 'bg-blue-500 text-white'
//                     : 'text-gray-600 hover:bg-gray-100'
//                 }`}
//                 onClick={() => setActivePool(tab)}
//               >
//                 {`${tab} Pool`}
//               </button>
//             ))}
//           </div>
//           <PermissionCheck
//             action={
//               activePool === 'INDOOR'
//                 ? 'read_indoor_pools'
//                 : 'read_outdoor_pools'
//             }
//           >
//             <Link to={`/pools/history/${activePoolId}`} onClick={(event) => handleLinkClick(event, `/pools/history/${activePoolId}`)}>
//               <IconHistory
//                 className="text-gray-600 hover:text-blue-700 cursor-pointer"
//                 size={24}
//               />
//             </Link>
//           </PermissionCheck>
//         </div>

//         <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//           <div className="p-6 bg-blue-50 rounded-lg shadow-inner">
//             <h2 className="text-xl font-bold text-blue-700 mb-4">{activePool} Pool Prices</h2>
//             <Formik
//               initialValues={{
//                 man: poolPrices.man,
//                 woman: poolPrices.woman,
//                 kid: poolPrices.kid,
//               }}
//               validationSchema={Yup.object().shape({
//                 man: Yup.number().required('Price is required*'),
//                 woman: Yup.number().required('Price is required*'),
//                 kid: Yup.number().required('Price is required*'),
//               })}
//               onSubmit={async (values, { setErrors, setSubmitting }) => {
//                 try {
//                   const updatedData = {
//                     manTicketPrice: values.man,
//                     womanTicketPrice: values.woman,
//                     kidTicketPrice: values.kid,
//                   };

//                   await updatePool(activePoolId, updatedData);
//                   toast.success('Pool updated successfully!');
//                   setSubmitting(false);
//                 } catch (error) {
//                   setErrors({ submit: 'Failed to update pool data' });
//                   toast.error('An error occurred while updating pools.');
//                   setSubmitting(false);
//                 }
//               }}
//             >
//               {({
//                 errors,
//                 values,
//                 touched,
//                 isSubmitting,
//                 handleBlur,
//                 handleChange,
//                 handleSubmit,
//                 setFieldValue,
//               }) => (
//                 <form onSubmit={handleSubmit}>
//                   <div className="grid grid-cols-1 gap-6">
//                     <CustomInput
//                       type="number"
//                       name="man"
//                       label="Man Ticket Price"
//                       value={values.man}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       showError={errors.man && touched.man}
//                       errorMessage={errors.man}
//                       disabled={disableInputs}
//                     />
//                     <CustomInput
//                       type="number"
//                       name="woman"
//                       label="Woman Ticket Price"
//                       value={values.woman}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       showError={errors.woman && touched.woman}
//                       errorMessage={errors.woman}
//                       disabled={disableInputs}
//                     />
//                     <CustomInput
//                       type="number"
//                       name="kid"
//                       label="Kid Ticket Price"
//                       value={values.kid}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       showError={errors.kid && touched.kid}
//                       errorMessage={errors.kid}
//                       disabled={disableInputs}
//                     />
//                   </div>
//                   {!disableInputs && (
//                     <div className="flex justify-end items-center mt-6">
//                       <SubmitButton
//                         isSubmitting={isSubmitting}
//                         width="w-full sm:w-auto"
//                         title="Save"
//                       />
//                     </div>
//                   )}
//                 </form>
//               )}
//             </Formik>
//           </div>

//           <div className="p-6 bg-green-50 rounded-lg shadow-inner">
//             <h2 className="text-xl font-bold text-green-700 mb-4">Add Tickets</h2>
//             <AddTicket poolType={activePool} poolId={activePoolId} onClick={handlePrint} />
//           </div>
//         </div>
//       </div>
//       <TicketPrintComponent ref={printRef} />
//     </div>
//   );
// }

// const TicketPrintComponent = React.forwardRef((props, ref) => {
//   const [item, setItem] = useState(null);
//   const printContentRef = useRef();
//   const printTriggerRef = useRef();

//   useImperativeHandle(ref, () => ({
//     setItem: (item) => setItem(item),
//     triggerPrint: () => {
//       if (printTriggerRef.current) {
//         printTriggerRef.current.click();
//       }
//     },
//   }));

//   if (!item) return null;

//   return (
//     <>
//       <ReactToPrint
//         trigger={() => <button ref={printTriggerRef} style={{ display: 'none' }}>Print</button>}
//         content={() => printContentRef.current}
//       />
//       <div style={{ display: 'none' }}>
//         <PrintContent ref={printContentRef} item={item} />
//       </div>
//     </>
//   );
// });

// const PrintContent = React.forwardRef(({ item }, ref) => {
//   const { user } = useSelector((state) => state.user);

//   return (
//     <div ref={ref} className="bg-white rounded-lg shadow-lg p-8 max-w-xl mx-auto">
//       <div className="flex items-center justify-between mb-6">
//         <div className="flex items-center">
//           <img className="h-8 w-8 mr-2" src="/images/lake-side-logo.png" alt="Logo" />
//           <div className="text-gray-700 font-semibold text-lg">LAKESIDE HOTEL</div>
//         </div>
//         <div className="text-gray-700">
//           <div className="font-bold text-xl mb-2">INVOICE</div>
//           <div className="text-sm">Date: {new Date().toLocaleDateString()}</div>
//           <div className="text-sm">NR#: {Math.floor(Math.random() * 10000)}</div>
//         </div>
//       </div>
//       <div className="border-b-2 border-gray-300 pb-6 mb-6">
//         <h2 className="text-2xl font-bold mb-4">Bill To:</h2>
//         <div className="text-gray-700 mb-2">{user.name} {user.surname}</div>
//         <div className="text-gray-700 mb-2">{user.email}</div>
//         <div className="text-gray-700 mb-2">{item.customerCityStatePostal}</div>
//         <div className="text-gray-700">{item.customerEmail}</div>
//       </div>
//       <table className="w-full text-left mb-6">
//         <thead>
//           <tr>
//             <th className="text-gray-700 font-bold uppercase py-2">Description</th>
//             <th className="text-gray-700 font-bold uppercase py-2">Quantity</th>
//             <th className="text-gray-700 font-bold uppercase py-2">Price</th>
//             <th className="text-gray-700 font-bold uppercase py-2">Total</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="py-4 text-gray-700">{item.ticketTitle}</td>
//             <td className="py-4 text-gray-700">{item.count}</td>
//             <td className="py-4 text-gray-700">{item.totalPrice}€</td>
//             <td className="py-4 text-gray-700">{item.totalPrice}€</td>
//           </tr>
//         </tbody>
//       </table>
//       <div className="flex justify-end mb-6">
//         <div className="text-gray-700 mr-2">Total:</div>
//         <div className="text-gray-700">{item.totalPrice.toFixed(2)}€</div>
//       </div>
//       <div className="border-t-2 border-gray-300 pt-6 mb-6">
//         <div className="my-4 text-2xl italic text-center">THANK YOU</div>
//       </div>
//     </div>
//   );
// });

// export default PoolsInterfaceList;









// import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import { Formik } from 'formik';
// import * as Yup from 'yup';
// import { IconHistory } from '@tabler/icons-react';
// import AddTicket from './AddTicketInterface';
// import CustomInput from '../../components/custom-input';
// import PermissionCheck from '../../utils/PermissionCheck';
// import { SubmitButton } from '../../components/buttons';
// import { getPools, updatePool } from '../../api/pools';
// import useAuth from '../../hooks/useAuth';
// import ReactToPrint from 'react-to-print';
// import { useSelector } from 'react-redux';
// import axios from 'axios';
// import { closePort } from '../pools/serialPortManager';
// import { SlidingTabBar } from '../../components/SlidingTabBar.js';

// import { cn } from '../../utils/cn.ts';
// import { GridPattern } from './GridPattern';
// import './animation.css'; // Ensure you import your CSS file

// function PoolsInterfaceList() {
//   const { userPermissions } = useAuth();
//   const [pools, setPools] = useState({});
//   const [activePool, setActivePool] = useState('OUTDOOR');
//   const [activePoolId, setActivePoolId] = useState(null);
//   const [animationClass, setAnimationClass] = useState('');
//   const printRef = useRef();
//   const navigate = useNavigate();


//   useEffect(() => {
//     const fetchPools = async () => {
//       try {
//         const response = await getPools();
//         const poolsData = response.data.reduce((acc, pool) => {
//           acc[pool.type] = {
//             id: pool.id,
//             man: pool.tickets.find((ticket) => ticket.title === 'man').price,
//             woman: pool.tickets.find((ticket) => ticket.title === 'woman').price,
//             kid: pool.tickets.find((ticket) => ticket.title === 'kid').price,
//           };
//           return acc;
//         }, {});

//         if (!userPermissions.includes('read_indoor_pools')) {
//           delete poolsData['INDOOR'];
//         }

//         if (!userPermissions.includes('read_outdoor_pools')) {
//           delete poolsData['OUTDOOR'];
//         }
//         setPools(poolsData);
//         const firstPoolType = Object.keys(poolsData)[0];
//         setActivePool(firstPoolType);
//         setActivePoolId(poolsData[firstPoolType].id);
//       } catch (error) {
//         console.error('Failed to fetch pools data', error);
//       }
//     };
//     fetchPools();

    
//   }, []);

//   // useEffect(() => {
//   //   const fetchPools = async () => {
//   //     try {
//   //       const response = await getPools();
//   //       const poolsData = response.data.reduce((acc, pool) => {
//   //         acc[pool.type] = {
//   //           id: pool.id,
//   //           man: pool.tickets.find((ticket) => ticket.title === 'man').price,
//   //           woman: pool.tickets.find((ticket) => ticket.title === 'woman').price,
//   //           kid: pool.tickets.find((ticket) => ticket.title === 'kid').price,
//   //         };
//   //         return acc;
//   //       }, {});

//   //       if (!userPermissions.includes('read_indoor_pools')) {
//   //         delete poolsData['INDOOR'];
//   //       }

//   //       if (!userPermissions.includes('read_outdoor_pools')) {
//   //         delete poolsData['OUTDOOR'];
//   //       }
//   //       setPools(poolsData);
//   //       const firstPoolType = Object.keys(poolsData)[0];
//   //       setActivePool(firstPoolType);
//   //       setActivePoolId(poolsData[firstPoolType].id);
//   //     } catch (error) {
//   //       console.error('Failed to fetch pools data', error);
//   //     }
//   //   };
//   //   fetchPools();
//   // }, [userPermissions]);

//   const handleTabChange = (tab, setFieldValue) => {
//     const currentTab = Object.keys(pools).indexOf(activePool);
//     const newTab = Object.keys(pools).indexOf(tab);

//     if (currentTab < newTab) {
//       setAnimationClass('slide-out-left');
//     } else {
//       setAnimationClass('slide-out-right');
//     }

//     setTimeout(() => {
//       setActivePool(tab);
//       setActivePoolId(pools[tab].id);
//       if (setFieldValue) {
//         setFieldValue('man', pools[tab].man);
//         setFieldValue('woman', pools[tab].woman);
//         setFieldValue('kid', pools[tab].kid);
//       }
//       if (currentTab < newTab) {
//         setAnimationClass('slide-in-right');
//       } else {
//         setAnimationClass('slide-in-left');
//       }
//     }, 500); // Match this timeout with the animation duration
//   };

//   const handleLinkClick = async (event, url) => {
//     event.preventDefault();
//     await closePort();
//     navigate(url);
//   };

//   const handlePrint = async (item) => {
//     const saleData = { product: 'Ticket', price: item.totalPrice };

//     console.log('Sending data to generate INP file:', saleData);

//     await axios.post('http://localhost:3001/generate-sale', saleData);

//     printRef.current.setItem(item);
//     setTimeout(() => {
//       printRef.current.triggerPrint();
//     }, 500);
//   };

//   if (!pools[activePool]) return null;

//   const poolPrices = pools[activePool];

//   const disableInputs =
//     activePool === 'INDOOR'
//       ? !userPermissions.includes('edit_indoor_pools')
//       : !userPermissions.includes('edit_outdoor_pools');

//   return (
//     <div className="p-6 relative bg-slate-100 min-h-screen w-full flex flex-col items-center justify-center gap-6">
//       <SlidingTabBar className="z-20" onTabChange={(tab) => handleTabChange(tab)} />
//       <div className="absolute inset-0 z-1">
//         <GridPattern
//           width={20}
//           height={20}
//           x={-1}
//           y={-1}
//           className={cn(
//             "[mask-image:linear-gradient(to_bottom_right,white,transparent,transparent)]"
//           )}
//         />
//       </div>
//       <div className={`max-w-6xl mx-auto bg-white border border-gray-200 rounded-xl shadow-lg p-6 ${animationClass}`}>
//         <div className="flex items-center justify-between mb-4 border-b pb-4">
//           <div className="flex space-x-4">
//             {Object.keys(pools).map((tab) => (
//               <button
//                 key={tab}
//                 className={`py-2 px-4 rounded-lg text-sm font-medium ${
//                   tab === activePool
//                     ? 'bg-blue-500 text-white'
//                     : 'text-gray-600 hover:bg-gray-100'
//                 }`}
//                 onClick={() => handleTabChange(tab)}
//               >
//                 {`${tab} Pool`}
//               </button>
//             ))}
//           </div>
//           <PermissionCheck
//             action={
//               activePool === 'INDOOR'
//                 ? 'read_indoor_pools'
//                 : 'read_outdoor_pools'
//             }
//           >
//             <Link to={`/pools/history/${activePoolId}`} onClick={(event) => handleLinkClick(event, `/pools/history/${activePoolId}`)}>
//               <IconHistory
//                 className="text-gray-600 hover:text-blue-700 cursor-pointer"
//                 size={24}
//               />
//             </Link>
//           </PermissionCheck>
//         </div>

//         <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//           <div className="p-6 bg-blue-50 rounded-lg shadow-inner">
//             <h2 className="text-xl font-bold text-blue-700 mb-4">{activePool} Pool Prices</h2>
//             <Formik
//               initialValues={{
//                 man: poolPrices.man,
//                 woman: poolPrices.woman,
//                 kid: poolPrices.kid,
//               }}
//               validationSchema={Yup.object().shape({
//                 man: Yup.number().required('Price is required*'),
//                 woman: Yup.number().required('Price is required*'),
//                 kid: Yup.number().required('Price is required*'),
//               })}
//               onSubmit={async (values, { setErrors, setSubmitting }) => {
//                 try {
//                   const updatedData = {
//                     manTicketPrice: values.man,
//                     womanTicketPrice: values.woman,
//                     kidTicketPrice: values.kid,
//                   };

//                   await updatePool(activePoolId, updatedData);
//                   toast.success('Pool updated successfully!');
//                   setSubmitting(false);
//                 } catch (error) {
//                   setErrors({ submit: 'Failed to update pool data' });
//                   toast.error('An error occurred while updating pools.');
//                   setSubmitting(false);
//                 }
//               }}
//             >
//               {({
//                 errors,
//                 values,
//                 touched,
//                 isSubmitting,
//                 handleBlur,
//                 handleChange,
//                 handleSubmit,
//                 setFieldValue,
//               }) => (
//                 <form onSubmit={handleSubmit}>
//                   <div className="grid grid-cols-1 gap-6">
//                     <CustomInput
//                       type="number"
//                       name="man"
//                       label="Man Ticket Price"
//                       value={values.man}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       showError={errors.man && touched.man}
//                       errorMessage={errors.man}
//                       disabled={disableInputs}
//                     />
//                     <CustomInput
//                       type="number"
//                       name="woman"
//                       label="Woman Ticket Price"
//                       value={values.woman}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       showError={errors.woman && touched.woman}
//                       errorMessage={errors.woman}
//                       disabled={disableInputs}
//                     />
//                     <CustomInput
//                       type="number"
//                       name="kid"
//                       label="Kid Ticket Price"
//                       value={values.kid}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       showError={errors.kid && touched.kid}
//                       errorMessage={errors.kid}
//                       disabled={disableInputs}
//                     />
//                   </div>
//                   {!disableInputs && (
//                     <div className="flex justify-end items-center mt-6">
//                       <SubmitButton
//                         isSubmitting={isSubmitting}
//                         width="w-full sm:w-auto"
//                         title="Save"
//                       />
//                     </div>
//                   )}
//                 </form>
//               )}
//             </Formik>
//           </div>

//           <div className="p-6 bg-green-50 rounded-lg shadow-inner">
//             <h2 className="text-xl font-bold text-green-700 mb-4">Add Tickets</h2>
//             <AddTicket poolType={activePool} poolId={activePoolId} onClick={handlePrint} />
//           </div>
//         </div>
//       </div>
//       <TicketPrintComponent ref={printRef} />
//     </div>
//   );
// }

// const TicketPrintComponent = React.forwardRef((props, ref) => {
//   const [item, setItem] = useState(null);
//   const printContentRef = useRef();
//   const printTriggerRef = useRef();

//   useImperativeHandle(ref, () => ({
//     setItem: (item) => setItem(item),
//     triggerPrint: () => {
//       if (printTriggerRef.current) {
//         printTriggerRef.current.click();
//       }
//     },
//   }));

//   if (!item) return null;

//   return (
//     <>
//       <ReactToPrint
//         trigger={() => <button ref={printTriggerRef} style={{ display: 'none' }}>Print</button>}
//         content={() => printContentRef.current}
//       />
//       <div style={{ display: 'none' }}>
//         <PrintContent ref={printContentRef} item={item} />
//       </div>
//     </>
//   );
// });

// const PrintContent = React.forwardRef(({ item }, ref) => {
//   const { user } = useSelector((state) => state.user);

//   return (
//     <div ref={ref} className="bg-white rounded-lg shadow-lg p-8 max-w-xl mx-auto">
//       <div className="flex items-center justify-between mb-6">
//         <div className="flex items-center">
//           <img className="h-8 w-8 mr-2" src="/images/lake-side-logo.png" alt="Logo" />
//           <div className="text-gray-700 font-semibold text-lg">LAKESIDE HOTEL</div>
//         </div>
//         <div className="text-gray-700">
//           <div className="font-bold text-xl mb-2">INVOICE</div>
//           <div className="text-sm">Date: {new Date().toLocaleDateString()}</div>
//           <div className="text-sm">NR#: {Math.floor(Math.random() * 10000)}</div>
//         </div>
//       </div>
//       <div className="border-b-2 border-gray-300 pb-6 mb-6">
//         <h2 className="text-2xl font-bold mb-4">Bill To:</h2>
//         <div className="text-gray-700 mb-2">{user.name} {user.surname}</div>
//         <div className="text-gray-700 mb-2">{user.email}</div>
//         <div className="text-gray-700 mb-2">{item.customerCityStatePostal}</div>
//         <div className="text-gray-700">{item.customerEmail}</div>
//       </div>
//       <table className="w-full text-left mb-6">
//         <thead>
//           <tr>
//             <th className="text-gray-700 font-bold uppercase py-2">Description</th>
//             <th className="text-gray-700 font-bold uppercase py-2">Quantity</th>
//             <th className="text-gray-700 font-bold uppercase py-2">Price</th>
//             <th className="text-gray-700 font-bold uppercase py-2">Total</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="py-4 text-gray-700">{item.ticketTitle}</td>
//             <td className="py-4 text-gray-700">{item.count}</td>
//             <td className="py-4 text-gray-700">{item.totalPrice}€</td>
//             <td className="py-4 text-gray-700">{item.totalPrice}€</td>
//           </tr>
//         </tbody>
//       </table>
//       <div className="flex justify-end mb-6">
//         <div className="text-gray-700 mr-2">Total:</div>
//         <div className="text-gray-700">{item.totalPrice.toFixed(2)}€</div>
//       </div>
//       <div className="border-t-2 border-gray-300 pt-6 mb-6">
//         <div className="my-4 text-2xl italic text-center">THANK YOU</div>
//       </div>
//     </div>
//   );
// });

// export default PoolsInterfaceList;






import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IconHistory } from '@tabler/icons-react';
import AddTicket from './AddTicketInterface';
import CustomInput from '../../components/custom-input';
import PermissionCheck from '../../utils/PermissionCheck';
import { SubmitButton } from '../../components/buttons';

// import { getPools, updatePools } from '../../api/pools';

import { getPools, updatePool } from '../../api/poolsInterface.js';


import useAuth from '../../hooks/useAuth';
import ReactToPrint from 'react-to-print';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { closePort } from '../pools/serialPortManager';
import { SlidingTabBar } from '../../components/SlidingTabBar.js';

import { cn } from '../../utils/cn.ts';
import { GridPattern } from './GridPattern';
import './animation.css'; // Ensure you import your CSS file

function PoolsInterfaceList() {
  const { userPermissions } = useAuth();
  const [pools, setPools] = useState({});
  const [activePool, setActivePool] = useState('OUTDOOR');
  const [activePoolId, setActivePoolId] = useState(null);
  const [animationClass, setAnimationClass] = useState('');
  const printRef = useRef();
  const navigate = useNavigate();


  useEffect(() => {
    const fetchPools = async () => {
      try {
        console.log('Fetching pools...');
        const response = await getPools();
        console.log('Pools fetched:', response.data);
        const poolsData = response.data.reduce((acc, pool) => {
          acc[pool.type] = {
            id: pool.id,
            man: pool.tickets.find((ticket) => ticket.title === 'man').price,
            woman: pool.tickets.find((ticket) => ticket.title === 'woman').price,
            kid: pool.tickets.find((ticket) => ticket.title === 'kid').price,
          };
          return acc;
        }, {});
  
        // if (!userPermissions.includes('read_indoor_pools')) {
        //   delete poolsData['INDOOR'];
        // }
  
        // if (!userPermissions.includes('read_outdoor_pools')) {
        //   delete poolsData['OUTDOOR'];
        // }

        console.log('Processed pools data:', poolsData);
        setPools(poolsData);
        const firstPoolType = Object.keys(poolsData)[0];
        setActivePool(firstPoolType);
        // setActivePoolId(poolsData[firstPoolType].id);
      } catch (error) {
        console.error('Failed to fetch pools data', error);
      }
    };
    fetchPools();
  }, []);
  

  // useEffect(() => {
  //   const fetchPools = async () => {
  //     try {
  //       const response = await getPools();
  //       const poolsData = response.data.reduce((acc, pool) => {
  //         acc[pool.type] = {
  //           id: pool.id,
  //           man: pool.tickets.find((ticket) => ticket.title === 'man').price,
  //           woman: pool.tickets.find((ticket) => ticket.title === 'woman').price,
  //           kid: pool.tickets.find((ticket) => ticket.title === 'kid').price,
  //         };
  //         return acc;
  //       }, {});

  //       if (!userPermissions.includes('read_indoor_pools')) {
  //         delete poolsData['INDOOR'];
  //       }

  //       if (!userPermissions.includes('read_outdoor_pools')) {
  //         delete poolsData['OUTDOOR'];
  //       }
  //       setPools(poolsData);
  //       const firstPoolType = Object.keys(poolsData)[0];
  //       setActivePool(firstPoolType);
  //       // setActivePoolId(poolsData[firstPoolType].id);
  //     } catch (error) {
  //       console.error('Failed to fetch pools data', error);
  //     }
  //   };
  //   fetchPools();
  // }, [userPermissions]);

  const handleTabChange = (tab, setFieldValue) => {
    const currentTab = Object.keys(pools).indexOf(activePool);
    const newTab = Object.keys(pools).indexOf(tab);

    if (currentTab < newTab) {
      setAnimationClass('slide-out-left');
    } else {
      setAnimationClass('slide-out-right');
    }

    setTimeout(() => {
      if (pools[tab]) {
        setActivePool(tab);
        setActivePoolId(pools[tab].id);
        if (setFieldValue) {
          setFieldValue('man', pools[tab].man);
          setFieldValue('woman', pools[tab].woman);
          setFieldValue('kid', pools[tab].kid);
        }
        if (currentTab < newTab) {
          setAnimationClass('slide-in-right');
        } else {
          setAnimationClass('slide-in-left');
        }
        setTimeout(() => {
          setAnimationClass('');
        }, 500); // Match this timeout with the animation duration
      } else {
        console.error(`Pool type "${tab}" not found.`);
        setAnimationClass('');
      }
    }, 500); // Match this timeout with the animation duration
  };

  const handleLinkClick = async (event, url) => {
    event.preventDefault();
    await closePort();
    navigate(url);
  };

  const handlePrint = async (item) => {
    const saleData = { product: 'Ticket', price: item.totalPrice };

    console.log('Sending data to generate INP file:', saleData);

    await axios.post('http://localhost:3001/generate-sale', saleData);

    printRef.current.setItem(item);
    setTimeout(() => {
      printRef.current.triggerPrint();
    }, 500);
  };

  if (!pools[activePool]) return null;

  const poolPrices = pools[activePool];

  const disableInputs =
    activePool === 'INDOOR'
      ? !userPermissions.includes('edit_indoor_pools')
      : !userPermissions.includes('edit_outdoor_pools');

  return (
    <div className="z-32 p-6 relative bg-slate-100 min-h-screen w-full flex flex-col items-center justify-center gap-6">
      <SlidingTabBar className="z-20" onTabChange={(tab) => handleTabChange(tab)} />
      <div className="absolute inset-0 z-1">
        <GridPattern
          width={20}
          height={20}
          x={-1}
          y={-1}
          className={cn(
            "[mask-image:linear-gradient(to_bottom_right,white,transparent,transparent)]"
          )}
        />
      </div>
      <div className={`max-w-6xl mx-auto bg-white border border-gray-200 rounded-xl shadow-lg p-6 ${animationClass}`}>
        <div className="flex items-center justify-between mb-4 border-b pb-4">
          <div className="flex space-x-4">
            {Object.keys(pools).map((tab) => (
              <button
                key={tab}
                className={`py-2 px-4 rounded-lg text-sm font-medium ${
                  tab === activePool
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
                onClick={() => handleTabChange(tab)}
              >
                {`${tab} Pool`}
              </button>
            ))}
          </div>
          <PermissionCheck
            action={
              activePool === 'INDOOR'
                ? 'read_indoor_pools'
                : 'read_outdoor_pools'
            }
          >
            <Link to={`/pools/history/${activePoolId}`} onClick={(event) => handleLinkClick(event, `/pools/history/${activePoolId}`)}>
              <IconHistory
                className="text-gray-600 hover:text-blue-700 cursor-pointer"
                size={24}
              />
            </Link>
          </PermissionCheck>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="p-6 bg-blue-50 rounded-lg shadow-inner">
            <h2 className="text-xl font-bold text-blue-700 mb-4">{activePool} Pool Prices</h2>
            <Formik
              initialValues={{
                man: poolPrices.man,
                woman: poolPrices.woman,
                kid: poolPrices.kid,
              }}
              validationSchema={Yup.object().shape({
                man: Yup.number().required('Price is required*'),
                woman: Yup.number().required('Price is required*'),
                kid: Yup.number().required('Price is required*'),
              })}
              onSubmit={async (values, { setErrors, setSubmitting }) => {
                try {
                  const updatedData = {
                    manTicketPrice: values.man,
                    womanTicketPrice: values.woman,
                    kidTicketPrice: values.kid,
                  };

                  await updatePool(activePoolId, updatedData);
                  toast.success('Pool updated successfully!');
                  setSubmitting(false);
                } catch (error) {
                  setErrors({ submit: 'Failed to update pool data' });
                  toast.error('An error occurred while updating pools.');
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                values,
                touched,
                isSubmitting,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 gap-6">
                    <CustomInput
                      type="number"
                      name="man"
                      label="Man Ticket Price"
                      value={values.man}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      showError={errors.man && touched.man}
                      errorMessage={errors.man}
                      disabled={disableInputs}
                    />
                    <CustomInput
                      type="number"
                      name="woman"
                      label="Woman Ticket Price"
                      value={values.woman}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      showError={errors.woman && touched.woman}
                      errorMessage={errors.woman}
                      disabled={disableInputs}
                    />
                    <CustomInput
                      type="number"
                      name="kid"
                      label="Kid Ticket Price"
                      value={values.kid}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      showError={errors.kid && touched.kid}
                      errorMessage={errors.kid}
                      disabled={disableInputs}
                    />
                  </div>
                  {!disableInputs && (
                    <div className="flex justify-end items-center mt-6">
                      <SubmitButton
                        isSubmitting={isSubmitting}
                        width="w-full sm:w-auto"
                        title="Save"
                      />
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>

          <div className="p-6 bg-green-50 rounded-lg shadow-inner">
            <h2 className="text-xl font-bold text-green-700 mb-4">Add Tickets</h2>
            <AddTicket poolType={activePool} poolId={activePoolId} onClick={handlePrint} />
          </div>
        </div>
      </div>
      <TicketPrintComponent ref={printRef} />
    </div>
  );
}

const TicketPrintComponent = React.forwardRef((props, ref) => {
  const [item, setItem] = useState(null);
  const printContentRef = useRef();
  const printTriggerRef = useRef();

  useImperativeHandle(ref, () => ({
    setItem: (item) => setItem(item),
    triggerPrint: () => {
      if (printTriggerRef.current) {
        printTriggerRef.current.click();
      }
    },
  }));

  if (!item) return null;

  return (
    <>
      <ReactToPrint
        trigger={() => <button ref={printTriggerRef} style={{ display: 'none' }}>Print</button>}
        content={() => printContentRef.current}
      />
      <div style={{ display: 'none' }}>
        <PrintContent ref={printContentRef} item={item} />
      </div>
    </>
  );
});

const PrintContent = React.forwardRef(({ item }, ref) => {
  const { user } = useSelector((state) => state.user);

  return (
    <div ref={ref} className="bg-white rounded-lg shadow-lg p-8 max-w-xl mx-auto">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <img className="h-8 w-8 mr-2" src="/images/lake-side-logo.png" alt="Logo" />
          <div className="text-gray-700 font-semibold text-lg">LAKESIDE HOTEL</div>
        </div>
        <div className="text-gray-700">
          <div className="font-bold text-xl mb-2">INVOICE</div>
          <div className="text-sm">Date: {new Date().toLocaleDateString()}</div>
          <div className="text-sm">NR#: {Math.floor(Math.random() * 10000)}</div>
        </div>
      </div>
      <div className="border-b-2 border-gray-300 pb-6 mb-6">
        <h2 className="text-2xl font-bold mb-4">Bill To:</h2>
        <div className="text-gray-700 mb-2">{user.name} {user.surname}</div>
        <div className="text-gray-700 mb-2">{user.email}</div>
        <div className="text-gray-700 mb-2">{item.customerCityStatePostal}</div>
        <div className="text-gray-700">{item.customerEmail}</div>
      </div>
      <table className="w-full text-left mb-6">
        <thead>
          <tr>
            <th className="text-gray-700 font-bold uppercase py-2">Description</th>
            <th className="text-gray-700 font-bold uppercase py-2">Quantity</th>
            <th className="text-gray-700 font-bold uppercase py-2">Price</th>
            <th className="text-gray-700 font-bold uppercase py-2">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="py-4 text-gray-700">{item.ticketTitle}</td>
            <td className="py-4 text-gray-700">{item.count}</td>
            <td className="py-4 text-gray-700">{item.totalPrice}€</td>
            <td className="py-4 text-gray-700">{item.totalPrice}€</td>
          </tr>
        </tbody>
      </table>
      <div className="flex justify-end mb-6">
        <div className="text-gray-700 mr-2">Total:</div>
        <div className="text-gray-700">{item.totalPrice.toFixed(2)}€</div>
      </div>
      <div className="border-t-2 border-gray-300 pt-6 mb-6">
        <div className="my-4 text-2xl italic text-center">THANK YOU</div>
      </div>
    </div>
  );
});

export default PoolsInterfaceList;

import React from "react";
import Heading from "../common/Heading";
import CommonHeading from "../common/CommonHeading";
import { contact } from "../../../components/data/Data";

import Header from "../common/Header";
import Footer from "../common/Footer";

export default function Contact() {
  return (
    <>
      <Header />
      <Heading heading="Contact" title="Home" subtitle="Contact" />

      {/* <div class="container-xxl py-5">
        <div class="container">
          <CommonHeading
            heading="Contact Us"
            subtitle="Contact "
            title="For Any Query"
          />
          <div class="row g-4">
            <div class="col-12">
              <div class="row gy-4">
                {contact.map((item, index) => (
                  <div class="col-md-4">
                    <h6 class="section-title text-start text-[#FEA116] text-uppercase">
                      {item.title}
                    </h6>
                    <p>
                      {item.icon}
                      {item.email}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div class="col-md-6 wow fadeIn" data-wow-delay="0.1s">
              <iframe
                class="position-relative rounded w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                frameborder="0"
                style={{ minHeight: "350px", border: "0" }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
            <div class="col-md-6">
              <div class="wow fadeInUp" data-wow-delay="0.2s">
                <form>
                  <div class="row g-3">
                    <div class="col-md-6">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          placeholder="Your Name"
                        />
                        <label for="name">Your Name</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-floating">
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="Your Email"
                        />
                        <label for="email">Your Email</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="subject"
                          placeholder="Subject"
                        />
                        <label for="subject">Subject</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-floating">
                        <textarea
                          class="form-control"
                          placeholder="Leave a message here"
                          id="message"
                          style={{ height: "150px" }}
                        ></textarea>
                        <label for="message">Message</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <button class="btn btn-[#FEA116] w-100 py-3" type="submit">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}

<div className="py-16 bg-gray-100">
  <div className="max-w-7xl mx-auto px-4">
    {/* Common Heading */}
    <CommonHeading
      heading="Contact Us"
      subtitle="Contact"
      title="For Any Query"
    />
    
    {/* Grid Container */}
    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
      {/* Contact Information */}
      <div className="bg-white rounded-lg shadow-lg p-6 space-y-6">
        <div>
          <h6 className="text-[#FEA116] text-lg font-semibold uppercase mb-2">
            Email
          </h6>
          <p className="text-gray-700">lakeside@gmail.com</p>
        </div>
        <div>
          <h6 className="text-[#FEA116] text-lg font-semibold uppercase mb-2">
            Phone
          </h6>
          <p className="text-gray-700">+1 (234) 567-890</p>
        </div>
        <div>
          <h6 className="text-[#FEA116] text-lg font-semibold uppercase mb-2">
            Address
          </h6>
          <p className="text-gray-700">123 Lakeside Drive, City, Country</p>
        </div>
      </div>

      {/* Google Map */}
      <div className="relative aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
        <iframe
          className="w-full h-full"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1000!2d20.5637293!3d42.1661427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f12!3m3!1m2!1s0x135375c9f27b2135%3A0x46c2121ae02af649!2sLakeside%20Hotel!5e0!3m2!1sen!2sus!4v1603794290143"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>


      <div className="bg-white rounded-lg shadow-lg p-6">
        <h6 className="text-[#FEA116] text-lg font-semibold uppercase mb-4">
          Send Us a Message
        </h6>
        <form>
          <div className="grid gap-6 md:grid-cols-2">
            <div>
              <div className="relative">
                <input
                  type="text"
                  id="name"
                  // placeholder="Your Name"
                  className="peer w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FEA116]"
                />
                <label
                  htmlFor="name"
                  className="absolute top-0 left-0 px-3 py-2 text-gray-500 peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-4 peer-placeholder-shown:left-3 peer-placeholder-shown:text-base peer-placeholder-shown:font-normal peer-focus:-top-2 peer-focus:left-1 peer-focus:text-[#FEA116] peer-focus:text-xs transition-all duration-300"
                >
                  Your Name
                </label>
              </div>
            </div>
            <div>
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  // placeholder="Your Email"
                  className="peer w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FEA116]"
                />
                <label
                  htmlFor="email"
                  className="absolute top-0 left-0 px-3 py-2 text-gray-500 peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-4 peer-placeholder-shown:left-3 peer-placeholder-shown:text-base peer-placeholder-shown:font-normal peer-focus:-top-2 peer-focus:left-1 peer-focus:text-[#FEA116] peer-focus:text-xs transition-all duration-300"
                >
                  Your Email
                </label>
              </div>
            </div>
            <div className="col-span-2">
              <div className="relative">
                <input
                  type="text"
                  id="subject"
                  // placeholder="Subject"
                  className="peer w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FEA116]"
                />
                <label
                  htmlFor="subject"
                  className="absolute top-0 left-0 px-3 py-2 text-gray-500 peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-4 peer-placeholder-shown:left-3 peer-placeholder-shown:text-base peer-placeholder-shown:font-normal peer-focus:-top-2 peer-focus:left-1 peer-focus:text-[#FEA116] peer-focus:text-xs transition-all duration-300"
                >
                  Subject
                </label>
              </div>
            </div>
            <div className="col-span-2">
              <div className="relative">
                <textarea
                  id="message"
                  // placeholder="Leave a message here"
                  className="peer w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FEA116]"
                  style={{ height: '150px' }}
                ></textarea>
                <label
                  htmlFor="message"
                  className="absolute top-0 left-0 px-3 py-2 text-gray-500 peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-4 peer-placeholder-shown:left-3 peer-placeholder-shown:text-base peer-placeholder-shown:font-normal peer-focus:-top-2 peer-focus:left-1 peer-focus:text-[#FEA116] peer-focus:text-xs transition-all duration-300"
                >
                  Message
                </label>
              </div>
            </div>
            <div className="col-span-2">
              <button
                type="submit"
                className="bg-[#FEA116] text-white rounded-md py-3 px-6 w-full hover:bg-[#f89a04] transition-colors duration-300"
              >
                Send Message
              </button>
            </div>
          </div>
        </form>
      </div> 
      
    </div>
  </div>
</div>
  <Footer />
    </>
  );
}

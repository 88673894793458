import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import Loading from '../../../components/loader';
import CustomTable from '../../../components/table-components/table';
import { getAllReservations } from '../../../api/venueReservation';

function ReservationList() {
  const [reservationList, setReservationList] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getReservationList();
  }, []);

  const getReservationList = useCallback(async () => {
    try {
      const response = await getAllReservations();
      setReservationList(response.data);
      setTotalCount(response.data.length);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getReservationList();
  }, [getReservationList]);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className='p-10'>
      <CustomTable
        rows={reservationList}
        columns={[
          {
            title: 'Reservation ID',
            renderValue: (item) => item.id
          },
          {
            title: 'Venue Name',
            renderValue: (item) => item.venue.name
          },

          {
            title: 'Start Date',
            renderValue: (item) => dayjs(item.startDate).format('DD/MM/YYYY')
          },
          {
            title: 'End Date',
            renderValue: (item) => dayjs(item.endDate).format('DD/MM/YYYY')
          },
          {
            title: 'Number of Guests',
            renderValue: (item) => item.numberOfGuests
          },
          {
            title: 'Menu Name',
            renderValue: (item) => item.menu ? item.menu.name : 'N/A'
          },
          {
            title: 'Products',
            renderValue: (item) => item.products.map(p => p.product.name).join(', ')
          },
          {
            title: 'Created At',
            renderValue: (item) => dayjs(item.createdAt).format('DD/MM/YYYY')
          },
        ]}
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={false}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </div>
  );
}

export default ReservationList;

// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import useSWR from 'swr';
// import { IconPlus, IconPencil, IconTrash } from '@tabler/icons-react';
// import Modal from '../../components/modal';
// import Loading from '../../components/loader';
// import ConfirmMessage from '../../components/alerts/alert-message';
// import { fetcher } from '../../api/api';
// import { getAllReservations } from '../../api/venueReservation';
// import { getAllVenues } from '../../api/venue';
// import { deleteVenue as deleteVenueApi } from '../../api/venue';



// function MainVenuePage() {

//   const [selectedVenue, setSelectedVenue] = useState(null);
//   const [showDeleteAlert, setShowDeleteAlert] = useState(false);
//   const [venueToDeleteId, setVenueToDeleteId] = useState('');
//   const [showReservationModal, setShowReservationModal] = useState(false);
//   const [latestReservations, setLatestReservations] = useState([]);
//   const [venues, setVenues] = useState([]);


//   const { data: initialVenues, error: venuesError, isValidating: venuesValidating } = useSWR('/api/venue/venues', fetcher);


//   const { data: reservations, error: reservationsError } = useSWR('/api/venueReservations/reservations', fetcher);

//   useEffect(() => {
//     if (initialVenues) {
//       setVenues(initialVenues);
//     }
//   }, [initialVenues]);

//   useEffect(() => {
//     if (reservations) {
//       const latest = reservations.reduce((acc, res) => {
//         if (!acc[res.venueId] || new Date(res.startDate) > new Date(acc[res.venueId].startDate)) {
//           acc[res.venueId] = res;
//         }
//         return acc;
//       }, {});
//       setLatestReservations(Object.values(latest));
//     }
//   }, [reservations]);

//   if (venuesError || reservationsError) return <div>Error loading data...</div>;
//   if (venuesValidating) return <Loading />;

//   const handleDeleteVenue = async () => {
//     try {
//       await deleteVenueApi(venueToDeleteId);
//       // Update state by filtering out the deleted venue
//       setVenues(venues.filter(venue => venue.id !== venueToDeleteId));
//       setSelectedVenue(null);
//       setShowDeleteAlert(false);
//     } catch (err) {
//       console.error('Failed to delete venue:', err);
//     }
//   };

//   return (
//     <div className='p-10'>
//       <div className='flex justify-end gap-x-3 mb-8'>
//         <Link
//           to='/venue/add-venue'
//           className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5'
//         >
//           <IconPlus className='mr-2' size={20} />
//           New Venue
//         </Link>
//         <Link
//           to='/venue-reservations/add-new-reservation'
//           className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5'
//         >
//           <IconPlus className='mr-2' size={20} />
//           New Reservation
//         </Link>
//       </div>
//       <div className='border border-gray-200 rounded-xl shadow-lg px-3 mt-5'>
//         {venues && venues.length > 0 ? (
//           <>
//             <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
//               {venues.map((venue) => {
//                 const latestReservation = latestReservations.find(res => res.venueId === venue.id);
//                 return (
//                   <div key={venue.id} className='bg-seashell p-5 rounded-md shadow-lg'>
//                     <img src={`http://localhost:3001${venue.imagePath}`} alt={venue.name} className='w-full h-40 mb-6 object-cover rounded-md' />
//                     <h3 className='text-hazel-green text-xl font-semibold mt-3'>{venue.name}</h3>
//                     <p className='text-hazel-green'>{venue.location}</p>
//                     {latestReservation ? (
//                       <div className='text-sm mt-3 p-4 border border-gray-200 rounded-md text-bluish-grey'>
//                         <h4 className='text-md font-semibold mb-2'>Latest Reservation</h4>
//                         <p>Date: {new Date(latestReservation.startDate).toLocaleDateString()}</p>
//                         <p>Number of Guests: {latestReservation.numberOfGuests}</p>
//                       </div>
//                     ) : (
//                       <p className='mt-3 text-gray-600'>No reservations</p>
//                     )}
//                     <div className='flex justify-end gap-x-2 mt-3'>
//                       <Link to={`/venues/edit/${venue.id}`}>
//                         <IconPencil className='text-sage cursor-pointer' size={20} />
//                       </Link>
//                       <IconTrash
//                         className='text-sage cursor-pointer'
//                         size={20}
//                         onClick={() => {
//                           setVenueToDeleteId(venue.id);
//                           setShowDeleteAlert(true);
//                         }}
//                       />
//                     </div>
//                   </div>
//                 );
//               })}
//             </div>
//           </>
//         ) : (
//           <p className='text-sage font-bold py-10 px-8'>There are no venues to show!</p>
//         )}
//       </div>
//       {showDeleteAlert && (
//         <ConfirmMessage
//           title='Are you sure you want to delete this venue?'
//           onCancel={() => setShowDeleteAlert(false)}
//           onConfirm={() => handleDeleteVenue()}
//         />
//       )}
//     </div>
//   );
// }

// export default MainVenuePage;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { IconPlus, IconPencil, IconTrash } from '@tabler/icons-react';
import Loading from '../../components/loader';
import ConfirmMessage from '../../components/alerts/alert-message';
import { fetcher } from '../../api/api';
import { deleteVenue as deleteVenueApi, getAllVenues } from '../../api/venue';
import { getAllReservations } from '../../api/venueReservation';
import { getAllMenus, deleteMenu as deleteMenuApi } from '../../api/venueMenus';

function MainVenuePage() {
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [venueToDeleteId, setVenueToDeleteId] = useState('');
  const [showMenuDeleteAlert, setShowMenuDeleteAlert] = useState(false); // Added
  const [menuToDeleteId, setMenuToDeleteId] = useState(''); // Added
  const [latestReservations, setLatestReservations] = useState([]);
  const [venues, setVenues] = useState([]);
  const [menus, setMenus] = useState([]);

  // Fetch venues and reservations
  const { data: initialVenues, error: venuesError, isValidating: venuesValidating } = useSWR('/api/venue/venues', fetcher);
  const { data: reservations, error: reservationsError } = useSWR('/api/venueReservations/reservations', fetcher);

  // Fetch menus
  const { data: initialMenus, error: menusError } = useSWR('/api/venueMenu/', fetcher);

  useEffect(() => {
    if (initialVenues) {
      setVenues(initialVenues);
    }
  }, [initialVenues]);

  useEffect(() => {
    if (reservations) {
      const latest = reservations.reduce((acc, res) => {
        if (!acc[res.venueId] || new Date(res.startDate) > new Date(acc[res.venueId].startDate)) {
          acc[res.venueId] = res;
        }
        return acc;
      }, {});
      setLatestReservations(Object.values(latest));
    }
  }, [reservations]);

  useEffect(() => {
    if (initialMenus) {
      setMenus(initialMenus);
    }
  }, [initialMenus]);

  if (venuesError || reservationsError || menusError) return <div>Error loading data...</div>;
  if (venuesValidating) return <Loading />;

  const handleDeleteVenue = async () => {
    try {
      await deleteVenueApi(venueToDeleteId);
      // Update state by filtering out the deleted venue
      setVenues(venues.filter(venue => venue.id !== venueToDeleteId));
      setSelectedVenue(null);
      setShowDeleteAlert(false);
    } catch (err) {
      console.error('Failed to delete venue:', err);
    }
  };

  const handleDeleteMenu = async () => {
    try {
      await deleteMenuApi(menuToDeleteId);
      // Update state by filtering out the deleted menu
      setMenus(menus.filter(menu => menu.id !== menuToDeleteId));
      setMenuToDeleteId('');
      setShowMenuDeleteAlert(false);
    } catch (err) {
      console.error('Failed to delete menu:', err);
    }
  };

  return (
    <div className='p-10'>
      {/* Venues Section */}
      <div className='mb-10'>
        <div className='flex justify-end gap-x-3 mb-8'>
          <Link
            to='/venue/add-venue'
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5'
          >
            <IconPlus className='mr-2' size={20} />
            New Venue
          </Link>
          <Link
            to='/venue-reservations/add-new-reservation'
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5'
          >
            <IconPlus className='mr-2' size={20} />
            New Reservation
          </Link>
          <Link
            to='/venue/reservations-list'
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5'
          >
            <IconPlus className='mr-2' size={20} />
            Reservations List
          </Link>
        </div>
        <div className='border border-gray-200 rounded-xl shadow-lg px-3 mt-5'>
          {venues && venues.length > 0 ? (
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
              {venues.map((venue) => {
                const latestReservation = latestReservations.find(res => res.venueId === venue.id);
                return (
                  <div key={venue.id} className='bg-seashell p-5 rounded-md shadow-lg'>
                    <img src={`http://localhost:3001${venue.imagePath}`} alt={venue.name} className='w-full h-40 mb-6 object-cover rounded-md' />
                    <h3 className='text-hazel-green text-xl font-semibold mt-3'>{venue.name}</h3>
                    <p className='text-hazel-green'>{venue.location}</p>
                    {latestReservation ? (
                      <div className='text-sm mt-3 p-4 border border-gray-200 rounded-md text-bluish-grey'>
                        <h4 className='text-md font-semibold mb-2'>Latest Reservation</h4>
                        <p>Date: {new Date(latestReservation.startDate).toLocaleDateString()}</p>
                        <p>Number of Guests: {latestReservation.numberOfGuests}</p>
                      </div>
                    ) : (
                      <p className='mt-3 text-gray-600'>No reservations</p>
                    )}
                    <div className='flex justify-end gap-x-2 mt-3'>
                      <Link to={`/venues/edit/${venue.id}`}>
                        <IconPencil className='text-sage cursor-pointer' size={20} />
                      </Link>
                      <IconTrash
                        className='text-sage cursor-pointer'
                        size={20}
                        onClick={() => {
                          setVenueToDeleteId(venue.id);
                          setShowDeleteAlert(true);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className='text-sage font-bold py-10 px-8'>There are no venues to show!</p>
          )}
        </div>
      </div>

      {/* Menus Section */}
      <div>
        <div className='flex justify-end gap-x-3 mb-8'>
          <Link
            to='/venue/menu'
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5'
          >
            <IconPlus className='mr-2' size={20} />
            Create Menu
          </Link>
        </div>
        <div className='border border-gray-200 rounded-xl shadow-lg px-3 mt-5'>
          {menus && menus.length > 0 ? (
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
              {menus.map((menu) => (
                <div key={menu.id} className='bg-seashell p-5 rounded-md shadow-lg'>
                  <h3 className='text-hazel-green text-xl font-semibold mt-3'>{menu.name}</h3>
                  <div className='flex justify-end gap-x-2 mt-3'>
                    <Link to={`/menus/edit/${menu.id}`}>
                      <IconPencil className='text-sage cursor-pointer' size={20} />
                    </Link>
                    <IconTrash
                      className='text-sage cursor-pointer'
                      size={20}
                      onClick={() => {
                        setMenuToDeleteId(menu.id);
                        setShowMenuDeleteAlert(true);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className='text-sage font-bold py-10 px-8'>There are no menus to show!</p>
          )}
        </div>
      </div>

      {showDeleteAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this venue?'
          onCancel={() => setShowDeleteAlert(false)}
          onConfirm={() => handleDeleteVenue()}
        />
      )}

      {showMenuDeleteAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this menu?'
          onCancel={() => setShowMenuDeleteAlert(false)}
          onConfirm={() => handleDeleteMenu()}
        />
      )}
    </div>
  );
}

export default MainVenuePage;


import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IconBedFilled } from '@tabler/icons-react';
import RoomTypeForm from '../room-types/RoomTypeForm';
import Loading from '../../../components/loader';
import PageTitle from '../../../components/page-title';
import { getRoomType } from '../../../api/rooms';

function EditRoomType() {
  const params = useParams();
  const roomTypeId = params.id;
  const [roomType, setRoomType] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRoomTypeData();
  }, []);

  const getRoomTypeData = async () => {
    try {
      const response = await getRoomType(roomTypeId);
      setRoomType(response.data);
    } catch (err) {
      console.error('Error fetching room type:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!roomType) {
    return <div>Error: Room type not found.</div>;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconBedFilled className='text-hazel-green mr-2' size={22} />}
        title='Room Type'
      />
      <RoomTypeForm isEdit roomTypeData={roomType} />
    </div>
  );
}

export default EditRoomType;

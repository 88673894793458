import React from 'react';
import { HelpCircle, Plus } from 'lucide-react';

interface FinancialCardProps {
  title: string;
  total: string;
  current: string;
  overdue: string;
  subtitle: string;
}

const FinancialCard = ({ title, total, current, overdue, subtitle }: FinancialCardProps) => {
  return (
    <div className="card p-6">
      <div className="flex justify-between items-center mb-4">
        <div className="card-header">
          <h2 className="text-xl">{title}</h2>
          <HelpCircle className="help-icon" />
        </div>
        <button className="flex items-center gap-1.5 text-hazel-green hover:text-sage transition-colors">
          <Plus className="w-4 h-4" />
          <span className="text-sm font-medium">New</span>
        </button>
      </div>
      
      <p className="text-bluish-grey text-sm mb-8">{subtitle}</p>
      
      <div className="grid grid-cols-2 gap-8">
        <div className="p-4 rounded-lg bg-tealish-blue">
          <p className="text-hazel-green text-sm font-medium mb-2">CURRENT</p>
          <p className="stat-value text-sage">{current}</p>
        </div>
        <div className="p-4 rounded-lg bg-dawn-pink">
          <p className="text-watermelon text-sm font-medium mb-2">OVERDUE</p>
          <p className="stat-value text-salmon-pink">{overdue}</p>
        </div>
      </div>
    </div>
  );
};

export default FinancialCard;
import React from 'react';

export default function PageTitle({ title, icon }) {
  return (
    <div className='flex items-center border border-gray-200 rounded-xl shadow-lg p-6'>
      {icon}
      <p className='text-bluish-grey font-medium'>{title}</p>
    </div>
  );
}

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import Loading from '../../components/loader';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import CustomSelectInput from '../../components/custom-select-input';
import SelectInput from '../../components/select-input';
import { getBarProducts, createBarProduct } from '../../api/barProduct';
import { getAvailableProducts } from '../../api/products';
import { uploadImage } from '../../api/api';
import { IconUpload, IconX } from '@tabler/icons-react';
import { units } from '../../constants/entities';


import { getDrinks } from '../../api/barProduct';
import { getMenu } from '../../api/menus';
import { productTypes } from '../../constants/entities';
import { getCategoryOrderProducts } from '../../api/categoryOrderProduct';


import { updateBarProduct } from '../../api/barProduct';

function BarProductForm({ isEdit, barProduct = {} }) {
  const navigate = useNavigate();
  const [drinks, setDrinks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const fileInputRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const [menus, setMenus] = useState([]);

const getMenusList = useCallback(async () => {
  try {
    const response = await getMenu();  // Assuming `getMenu()` is available
    const formattedMenus = response.data.map((menu) => ({
      id: menu.id,
      name: menu.name
    }));
    setMenus(formattedMenus);
  } catch (err) {
    console.error('Failed to fetch menus:', err);
  }
}, []);


  

  const getDrinksList = useCallback(async () => {
    try {
      const response = await getDrinks();
      const formattedDrinks = response.data.map((drink) => ({
        id: drink.id,
        name: drink.name,
        unit: drink.unit
      }));
      setDrinks(formattedDrinks);
    } catch (err) {
      console.error('Failed to fetch drinks:', err);
    }
  }, []);

  const getCategoriesList = useCallback(async () => {
    try {
      const response = await getCategoryOrderProducts();
      const barCategories = response.data.filter(category => category.categoryType === 'BAR');
      setCategories(barCategories);
    } catch (err) {
      console.error('Failed to fetch categories:', err);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([getDrinksList(), getCategoriesList(), getMenusList()]);
      setLoading(false);
    };
    fetchData();
  }, [getDrinksList, getCategoriesList, getMenusList]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required('Name is required*'),
    price: Yup.number().required('Price is required*').positive(),
    description: Yup.string().min(4).required('Description is required*'),
    imageUrl: Yup.string().required('Image is required*'),
    categoryOrderProductId: Yup.string().required('Category is required*'),
    menuId: Yup.string().required('Menu is required*'),
    products: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required('Product is required*'),
        quantity: Yup.number().required('Quantity is required*').positive(),
        unit: Yup.string().required('Unit is required*')
      })
    ).required('Products are required*')
  });

  const initialValues = {
    name: barProduct.name || '',
    price: barProduct.price || '',
    description: barProduct.description || '',
    imageUrl: barProduct.imageUrl || '',
    categoryOrderProductId: barProduct.categoryOrderProductId || '',
    menuId: barProduct.menuId || '',
    products: barProduct.products || [{ id: '', quantity: 1, unit: '' }]
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='bg-white border border-gray-300 rounded-2xl shadow-2xl mt-8 p-6'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            let imageUrl = values.imageUrl;
        
            if (uploadedFile) {
              const formData = new FormData();
              formData.append('file', uploadedFile);
              
              const uploadResponse = await uploadImage(formData);
              imageUrl = uploadResponse.data.url;
            }
        
            await createBarProduct({ ...values, imageUrl });
        
            navigate('/bar-products/bar_products_list');
            setSubmitting(false);
          } catch (error) {
            
            console.error('Error submitting form:', error);
            setErrors({ submit: error?.response?.data?.message || 'An error occurred' });
            setSubmitting(false);
          }
        }}
        
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <CustomInput
                type='text'
                name='name'
                label='Name'
                placeholder='Enter bar product name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.name && touched.name}
                errorMessage={errors.name}
              />
              <CustomInput
                type='number'
                name='price'
                label='Price'
                placeholder='Enter price'
                value={values.price}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.price && touched.price}
                errorMessage={errors.price}
              />
              <CustomInput
                type='text'
                name='description'
                label='Description'
                placeholder='Enter description'
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.description && touched.description}
                errorMessage={errors.description}
              />

              <CustomSelectInput
                name='menuId'
                value={values.menuId}
                label='Menu'
                options={menus.map(menu => ({ id: menu.id, title: menu.name }))}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.menuId && touched.menuId}
                errorMessage={errors.menuId}
              />

              <SelectInput
                name='categoryOrderProductId'
                label='Category'
                value={values.categoryOrderProductId}
                options={categories.map(category => ({ id: category.id, title: category.title }))}
                onChange={(event) => {
                  setFieldValue('categoryOrderProductId', event.target.value); // Update the form state
                }}
                onBlur={handleBlur}
                showError={errors.categoryOrderProductId && touched.categoryOrderProductId}
                errorMessage={errors.categoryOrderProductId}
              />

              <div className='col-span-2'>
                      {uploadedFile || values.imageUrl ? (
                        <div className='relative mb-3'>
                          <img
                            src={uploadedFile ? URL.createObjectURL(uploadedFile) : `http://localhost:3001${values.imageUrl}`}
                            alt='bar-product'
                            className='w-full h-32 rounded-md object-cover'
                          />
                          <button
                            type='button'
                            className='absolute top-0 right-0 border-sage rounded-tr-md rounded-bl-md bg-white p-1'
                            onClick={() => {
                              setUploadedFile(null);
                              setFieldValue('imageUrl', '');  // Reset imageUrl when removing image
                            }}
                          >
                            <IconX />
                          </button>
                        </div>
                      ) : (
                        <>
                          <input
                            type='file'
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={(event) => {
                              handleFileChange(event);
                              setFieldValue('imageUrl', URL.createObjectURL(event.target.files[0]));  // Update the form with new image URL
                            }}
                          />
                          <div
                            className='flex items-center justify-center bg-mercury w-full h-32 rounded-md cursor-pointer mb-3'
                            onClick={() => fileInputRef.current.click()} 
                          >
                            <IconUpload className='text-hazel-green' size={30} />
                          </div>
                        </>
                      )}
                    </div>
              <FieldArray
                name="products"
                render={arrayHelpers => (
                  <div className='col-span-2'>
                    {values.products.map((product, index) => (
                      <div key={index} className="grid md:grid-cols-4 xl:grid-cols-8 gap-x-3">
                        <div className='col-span-2'>
                          <SelectInput
                            value={product.id}
                            label="Product"
                            options={drinks.map(p => ({
                              id: p.id,
                              title: p.name
                            }))}
                            onChange={e => setFieldValue(`products.${index}.id`, e.target.value)}
                            onBlur={handleBlur}
                            showError={errors.products && touched.products && errors.products[index] && touched.products[index]?.id}
                            errorMessage={errors.products && touched.products && errors.products[index] && touched.products[index]?.id}
                          />
                        </div>
                        <div className='col-span-2'>
                          <CustomInput
                            type="number"
                            name={`products.${index}.quantity`}
                            label="Quantity"
                            value={product.quantity}
                            onChange={(event) => {
                              setFieldValue(`products.${index}.quantity`, event.target.value);
                            }}
                            onBlur={handleBlur}
                            showError={errors.products && touched.products && errors.products[index] && touched.products[index]?.quantity}
                            errorMessage={errors.products && touched.products && errors.products[index] && touched.products[index]?.quantity}
                          />
                        </div>
                        <div className='col-span-2'>
                          <CustomSelectInput
                            name={`products.${index}.unit`}
                            value={product.unit}
                            label="Unit"
                            options={units.map(unit => ({ id: unit, title: unit }))}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showError={errors.products && touched.products && errors.products[index] && touched.products[index]?.unit}
                            errorMessage={errors.products && touched.products && errors.products[index] && touched.products[index]?.unit}
                          />
                        </div>

                        <div className='col-span-2 flex items-center'>
                          <button
                            type="button"
                            className="text-center text-red-500"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <IconX />
                          </button>
                        </div>
                      </div>
                    ))}




                    <button
                      type="button"
                      className="mt-3 text-sm text-blue-500"
                      onClick={() => arrayHelpers.push({ id: '', unit: '', quantity: 1 })}
                    >
                      Add product
                    </button>
                  </div>
                )}
              />
              {errors.submit && <p className='text-error'>{errors.submit}</p>}
            </div>

            <div className='flex justify-end items-center w-full mt-4 text-sm'>
              <CancelButton onCancel={() => navigate('/bar-products')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/2 md:w-1/6'
                title={`${isEdit ? 'Edit Bar Product' : 'Create Bar Product'}`}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default BarProductForm;
  

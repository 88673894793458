import React, { useState, useEffect } from 'react';
import { getEarnings } from '../../api/poolsInterface';

function Earnings() {
    const [earnings, setEarnings] = useState({
        dailyEarnings: 0,
        allTimeEarnings: 0,
    });

    useEffect(() => {
        const fetchEarnings = async () => {
            try {
                const response = await getEarnings();
                console.log(response.data);
                setEarnings(response.data);
            } catch (error) {
                console.error('Error fetching earnings:', error);
            }
        };

        fetchEarnings();
    }, []);

    return (

        <div className="z-50 max-w-sm items-center justify-center text-center">
            <div className="p-6 bg-white rounded-lg shadow-lg">
                <h2 className="text-lg font-bold mb-4">Earnings</h2>
                <div className='flex flex-row gap-4 justify-center items-center'>
                    <div className="text-sm">
                        <span className="font-semibold">Today's Earnings: </span>
                        <span>{earnings.dailyEarnings.toFixed(2)}€</span>
                    </div>
                    <div className='text-sm'>
                        <span className="font-semibold">All-Time Earnings: </span>
                        <span>{earnings.allTimeEarnings.toFixed(2)}€</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Earnings;

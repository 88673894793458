import React, { useState, useEffect, useCallback } from 'react';
import CustomTable from '../../components/table-components/table';
import Loading from '../../components/loader';
import { getReservations, deleteReservation } from '../../api/restaurant';

function ReservationList() {
  const [reservations, setReservations] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const getReservationsList = useCallback(async () => {
    try {
      const response = await getReservations();
      setReservations(response.data || []); // Ensure it's an array
      setTotalCount(response.data.length || 0); // Ensure count is set
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getReservationsList();
  }, [getReservationsList]);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await deleteReservation(id);
      setReservations((prevState) => prevState.filter((item) => item.id !== id));
      setTotalCount(totalCount - 1);
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className='p-10'>
      <CustomTable
        rows={reservations}
        columns={[
          {
            title: 'Table Number',
            renderValue: (item) => item.table.number
          },
          {
            title: 'Environment',
            renderValue: (item) => item.enviroment.name
          },
          {
            title: 'Number of Persons',
            renderValue: (item) => item.numberOfPersons
          },
          {
            title: 'Reservation Date',
            renderValue: (item) => new Date(item.reservationDate).toLocaleString()
          },
          {
            title: 'Active',
            renderValue: (item) => item.isActive ? 'Yes' : 'No'
          }
        ]}
        entity='reservations'
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={true}
        editLink='/restaurant'
        onDelete={handleDelete}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </div>
  );
}

export default ReservationList;

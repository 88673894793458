import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { getSubCategories} from '../../api/account'; // Import the createBudget function

import { createBudget } from '../../api/budget';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Account {
  id: number;
  name: string;
}

interface CreateBudgetFormProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function BudgetForm({ isOpen, onClose }: CreateBudgetFormProps) {
  const [name, setName] = useState('');
  const [fiscalYear, setFiscalYear] = useState('');
  const [budgetPeriod, setBudgetPeriod] = useState('');
  
  // State for subcategories
  const [incomeSubcategories, setIncomeSubcategories] = useState<Account[]>([]);
  const [expenseSubcategories, setExpenseSubcategories] = useState<Account[]>([]);
  const [assetSubcategories, setAssetSubcategories] = useState<Account[]>([]);
  const [liabilitySubcategories, setLiabilitySubcategories] = useState<Account[]>([]);
  const [equitySubcategories, setEquitySubcategories] = useState<Account[]>([]);

  // State to hold selected accounts
  const [selectedIncomeAccount, setSelectedIncomeAccount] = useState<number | null>(null);
  const [selectedExpenseAccount, setSelectedExpenseAccount] = useState<number | null>(null);
  const [selectedAssetAccount, setSelectedAssetAccount] = useState<number | null>(null);
  const [selectedLiabilityAccount, setSelectedLiabilityAccount] = useState<number | null>(null);
  const [selectedEquityAccount, setSelectedEquityAccount] = useState<number | null>(null);

  // Loading and error state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Fetch subcategories when the form is opened
  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const incomeResponse = await getSubCategories('INCOME');
        const expenseResponse = await getSubCategories('EXPENSE');
        const assetResponse = await getSubCategories('ASSET');
        const liabilityResponse = await getSubCategories('LIABILITY');
        const equityResponse = await getSubCategories('EQUITY');
        
        setIncomeSubcategories(incomeResponse.data);
        setExpenseSubcategories(expenseResponse.data);
        setAssetSubcategories(assetResponse.data);
        setLiabilitySubcategories(liabilityResponse.data);
        setEquitySubcategories(equityResponse.data);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    if (isOpen) {
      fetchSubcategories();
    }
  }, [isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const budgetData = {
      name,
      fiscalYear,
      budgetPeriod,
      incomeAccounts: selectedIncomeAccount ? [selectedIncomeAccount] : [],
      expenseAccounts: selectedExpenseAccount ? [selectedExpenseAccount] : [],
      assetAccounts: selectedAssetAccount ? [selectedAssetAccount] : [],
      liabilityAccounts: selectedLiabilityAccount ? [selectedLiabilityAccount] : [],
      equityAccounts: selectedEquityAccount ? [selectedEquityAccount] : [],
    };

    setLoading(true);
    setError(null);

    try {
      await createBudget(budgetData);
      toast.success("Budget created successfully!"); // Success toast
      onClose();
    } catch (err) {
      console.error("Error creating budget:", err);
      setError("Failed to create budget. Please try again.");
      toast.error("Failed to create budget. Please try again."); // Error toast
    } finally {
      setLoading(false);
    }
  };


  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        transition={{ type: "spring", duration: 0.3 }}
        className="bg-white rounded-lg w-full max-w-4xl shadow-xl"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center px-6 py-4 border-b border-mercury bg-seashell rounded-t-lg">
          <h2 className="text-xl font-semibold text-bluish-grey">New Budget</h2>
          <button 
            onClick={onClose}
            className="text-silver hover:text-bluish-grey transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          <div className="grid grid-cols-2 gap-6 mb-6">
            <div className="col-span-2">
              <label className="block text-watermelon text-sm mb-1.5">
                Name<span className="text-watermelon">*</span>
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green transition-shadow"
                required
              />
            </div>

            <div>
              <label className="block text-watermelon text-sm mb-1.5">
                Fiscal Year<span className="text-watermelon">*</span>
              </label>
              <select
                className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green transition-shadow bg-white"
                value={fiscalYear}
                onChange={(e) => setFiscalYear(e.target.value)}
                required
              >
                <option value="">Select Fiscal Year</option>
                <option>Jan 2024 - Dec 2024</option>
              </select>
            </div>

            <div>
              <label className="block text-watermelon text-sm mb-1.5">
                Budget Period<span className="text-watermelon">*</span>
              </label>
              <select
                className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green transition-shadow bg-white"
                value={budgetPeriod}
                onChange={(e) => setBudgetPeriod(e.target.value)}
                required
              >
                <option value="">Select Budget Period</option>
                <option>Monthly</option>
                <option>Quarterly</option>
                <option>Yearly</option>
              </select>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-bluish-grey uppercase tracking-wide mb-3 pb-2 border-b border-mercury">
              Accounts
            </h3>
            
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h4 className="text-sm font-medium text-bluish-grey mb-3">Income & Expenses</h4>
                <div className="space-y-3">
                  <div>
                    <label className="block text-gray-700 text-sm mb-1">Income Accounts</label>
                    <select
                      className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green transition-shadow bg-white"
                      onChange={(e) => setSelectedIncomeAccount(Number(e.target.value))}
                    >
                      <option value="">Select Income Account</option>
                      {incomeSubcategories.map((sub) => (
                        <option key={sub.id} value={sub.id}>{sub.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm mb-1">Expense Accounts</label>
                    <select
                      className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green transition-shadow bg-white"
                      onChange={(e) => setSelectedExpenseAccount(Number(e.target.value))}
                    >
                      <option value="">Select Expense Account</option>
                      {expenseSubcategories.map((sub) => (
                        <option key={sub.id} value={sub.id}>{sub.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div>
                <h4 className="text-sm font-medium text-bluish-grey mb-3">Balance Sheet</h4>
                <div className="space-y-3">
                  <div>
                    <label className="block text-gray-700 text-sm mb-1">Asset Accounts</label>
                    <select
                      className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green transition-shadow bg-white"
                      onChange={(e) => setSelectedAssetAccount(Number(e.target.value))}
                    >
                      <option value="">Select Asset Account</option>
                      {assetSubcategories.map((sub) => (
                        <option key={sub.id} value={sub.id}>{sub.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm mb-1">Liability Accounts</label>
                    <select
                      className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green transition-shadow bg-white"
                      onChange={(e) => setSelectedLiabilityAccount(Number(e.target.value))}
                    >
                      <option value="">Select Liability Account</option>
                      {liabilitySubcategories.map((sub) => (
                        <option key={sub.id} value={sub.id}>{sub.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm mb-1">Equity Accounts</label>
                    <select
                      className="w-full p-2.5 border border-mercury rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green transition-shadow bg-white"
                      onChange={(e) => setSelectedEquityAccount(Number(e.target.value))}
                    >
                      <option value="">Select Equity Account</option>
                      {equitySubcategories.map((sub) => (
                        <option key={sub.id} value={sub.id}>{sub.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Display error message if there's an error */}
          {error && <div className="text-red-600 mt-2">{error}</div>}

          <div className="flex justify-end space-x-3 mt-6 pt-4 border-t border-mercury">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm text-bluish-grey hover:bg-seashell rounded-md transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 text-sm ${loading ? 'bg-gray-400' : 'bg-hazel-green text-white'} rounded-md hover:bg-sage transition-colors`}
              disabled={loading} // Disable button while loading
            >
              {loading ? 'Creating...' : 'Create Budget'}
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { navList } from "../../../components/data/Data";
import SocialIcons from "./SocialIcons";

import store from "../../../store";
import { setUser } from "../../../store/slices/user-slice";

import {
  IconLogout,
  IconSettings,
  IconChevronDown
} from '@tabler/icons-react';

function Header() {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);
  const [showDropDown, setShowDropDown] = useState(false);

  const [navbarCollapse, setNavbarCollapse] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const logout = () => {
    localStorage.clear();
    store.dispatch(setUser(null));
    navigate('/');
  };

  const handleMouseEnter = (itemId) => {
    setActiveDropdown(itemId);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  return (


<div className="bg-gray-900 px-4 py-2 lg:px-8">
  <div className="flex flex-wrap items-center h-full"> 
    {/* Logo Section */}
    <div className="hidden lg:flex lg:w-1/4 bg-gray-900 items-center justify-center">
      <Link to="/" className="flex items-center justify-center w-full h-full">
        <h1 className="text-[#ffc107] text-3xl font-semibold text-uppercase">
          Lake Side Hotel
        </h1>
      </Link>
    </div>

    {/* Navbar Section */}
    <div className="lg:w-3/4 w-full lg:pl-4">
      <nav className="bg-gray-900 text-white p-4 lg:p-0 h-full flex items-center">
        {/* Mobile Logo */}
        <Link to="/" className="block lg:hidden mb-4 flex items-center">
          <h1 className="text-[#ffc107] text-2xl font-semibold text-uppercase">
            Lake Side Hotel
          </h1>
        </Link>

        {/* Mobile Menu Button */}
        <button
          type="button"
          className="lg:hidden text-white focus:outline-none ml-auto"
          onClick={() => setNavbarCollapse(!navbarCollapse)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>

        <div
          className={`lg:flex lg:justify-center lg:space-x-8 ${navbarCollapse ? "block" : "hidden"} lg:h-full`}
        >
          <div className="flex flex-col lg:flex-row lg:items-center lg:space-x-8 lg:py-2 h-full w-full">
            {navList.map((item) => (
              <div
                key={item.id}
                onMouseEnter={() => handleMouseEnter(item.id)}
                onMouseLeave={handleMouseLeave}
                className="relative flex items-center h-full w-full"
              >
                {item.subItems ? (
                  <div className="relative group flex items-center h-full">
                    <Link
                      className="block px-4 py-2 text-white h-full flex items-center justify-center transition-colors duration-300 hover:bg-gray-700"
                      to="#"
                    >
                      {item.text}
                    </Link>
                  </div>
                ) : (
                  <Link
                    to={item.path}
                    className="block px-4 py-2 h-full flex items-center justify-center transition-colors duration-300 hover:bg-gray-700"
                  >
                    {item.text}
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>

            <div className="ml-4">

              <div className='flex justify-center items-center'>
                <img
                  src='/images/profile-placeholder.png'
                  alt='logo'
                  className='w-10 h-10'
                />
                <div className='flex'>
                  <p className='capitalize mx-3 whitespace-nowrap'>
                    {user.name} {user.surname}
                  </p>
                  <IconChevronDown
                    className='text-heather cursor-pointer'
                    onClick={() => setShowDropDown(!showDropDown)}
                  />
                </div>
              </div>
              <div
                className={`${
                  showDropDown ? 'z-50 flex flex-col' : 'hidden'
                } z-50 text-black absolute top-14 ml-auto right-20 justify-end p-4 w-60 bg-white border border-light-gray rounded-md shadow-lg`}
              >
                <Link
                  to='/account-settings'
                  className='flex p-3 border-b border-light-gray'
                  onClick={() => setShowDropDown(false)}
                >
                  <IconSettings className='mr-2' size={22} />
                  Account Settings
                </Link>
                <p className='flex p-3 cursor-pointer' onClick={logout}>
                  <IconLogout className='mr-2' size={22} />
                  Logout
                </p>
              </div>
            </div>
        <div className="lg:flex lg:items-center lg:ml-auto lg:mt-0 mt-4 hidden">
          <SocialIcons />
        </div>
      </nav>
    </div>
  </div>
</div>

  );
}

export default Header;

import React from 'react';
import { Search } from 'lucide-react';

const SearchBar = () => {
  return (
    <div className="flex items-center space-x-4">
      <div className="relative">
        <input
          type="text"
          placeholder="Search Property"
          className="pl-10 pr-4 py-2 border border-grey-goose rounded-md w-64 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" size={16} />
      </div>
      <input
        type="text"
        placeholder="Booking ID - Quick Search"
        className="px-4 py-2 border border-grey-goose rounded-md w-64 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
      />
    </div>
  );
};

export default SearchBar;
// import React, { useState } from 'react';
// import DishCard from '../views/restaurantInterface/DishCard';
// import { useDrop } from 'react-dnd';
// import { v4 as uuidv4 } from 'uuid';


// const OrderAccordion = ({ order, onDishDrop, onPay }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [droppedDishes, setDroppedDishes] = useState(order.dishes);

//   const [{ isOver }, drop] = useDrop({
//     accept: 'dish',
//     drop: (item) => {
//       const newItem = { ...item, uniqueId: uuidv4() };
//       setDroppedDishes((prevDishes) => [...prevDishes, newItem]);
//       onDishDrop(order.id, newItem);
//     },
//     collect: (monitor) => ({
//       isOver: monitor.isOver(),
//     }),
//   });

//   const handleDelete = (id) => {
//     const updatedDishes = droppedDishes.filter((dish) => dish.uniqueId !== id);
//     setDroppedDishes(updatedDishes);
//     onDishDrop(order.id, null, id);
//   };

//   const handlePay = () => {
//     onPay(order.id);
//   };

//   return (
//     <div className="mb-2 border border-gray-300 rounded-lg">
//       <div
//         className="p-4 flex justify-between items-center bg-gray-100 cursor-pointer"
//         onClick={() => setIsOpen(!isOpen)}
//       >
//         <h3 className="text-lg font-bold">Order #{order.id}</h3>
//         <span>{isOpen ? '-' : '+'}</span>
//       </div>
//       {isOpen && (
//         <div className="p-4" ref={drop}>
//           <div className={isOver ? 'bg-green-100 p-2' : 'bg-white p-2'}>
//             {droppedDishes.length === 0 ? (
//               <p className="text-gray-600">Drag dishes here</p>
//             ) : (
//               <div className="grid grid-cols-1 gap-2">
//                 {droppedDishes.map((dish) => (
//                   <div key={dish.uniqueId} className="relative">
//                     <button
//                       onClick={() => handleDelete(dish.uniqueId)}
//                       className="absolute top-0 right-0 p-1 text-red-600 hover:text-red-800"
//                     >
//                       ❌
//                     </button>
//                     <DishCard
//                       name={dish.name}
//                       imageUrl={dish.imageUrl}
//                       description={dish.description}
//                       price={dish.price}
//                     />
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>
//           <button
//             onClick={handlePay}
//             className="mt-2 bg-green-500 text-white p-2 rounded"
//           >
//             Pay Order
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default OrderAccordion;







import React, { useState, useEffect } from 'react';
import DishCard from '../views/restaurantInterface/DishCard';
import { useDrop } from 'react-dnd';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';


const OrderAccordion = ({ order, onDishDrop, onPay }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [droppedDishes, setDroppedDishes] = useState(order.dishes || []);

  useEffect(() => {
    setDroppedDishes(order.dishes || []);
  }, [order.dishes]);

  const [{ isOver }, drop] = useDrop({
    accept: 'dish',
    drop: item => {
      const newItem = { ...item, uniqueId: uuidv4() };
      setDroppedDishes(prevDishes => [...prevDishes, newItem]);
      onDishDrop(order.id, newItem);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleDelete = id => {
    const updatedDishes = droppedDishes.filter(dish => dish.uniqueId !== id);
    setDroppedDishes(updatedDishes);
    onDishDrop(order.id, null, id);
  };

  const handlePay = () => {
    onPay(order.id);
  };

  return (
    <div className="mb-2 border border-gray-300 rounded-lg shadow-md">
      <div
        className="p-3 flex flex-col justify-between items-center text-center bg-gray-100 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-sm font-semibold">
            Order <Link to={`/restaurant-interface/orders/${order.id}`}> #{order.id} </Link>
            - {' '}
            {   order.OrderDishSection
                  ? order.OrderDishSection.map(ds => ds.dishSection.name).join(', ')
                  : 'No dish sections'
            }

            {/* {
                order.OrderDishSection.map(order => (
                    <div key={order.id}>
                        <Link to={`/restaurant-interface/orders/${order.id}`}>
                            Order #{order.id}  {order.dishes.map(dish => dish.name).join(', ')}
                        </Link>
                    </div>
                ))
            } */}
        </h3>
        <div className="text-xs text-gray-700">
          {order.dishes && order.dishes.length > 0
            ? order.dishes.map(dish => dish.name).join(', ')
            : 'No dishes yet'}
        </div>
        <span className="text-lg">{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && (
        <div className="p-4" ref={drop}>
          <div className={isOver ? 'bg-green-100 p-2' : 'bg-white p-2'}>
            {droppedDishes.length === 0 ? (
              <p className="text-gray-600">Drag dishes here</p>
            ) : (
              <div className="grid grid-cols-1 gap-2">
                {droppedDishes.map(dish => (
                  <div key={dish.uniqueId} className="relative">
                    <button
                      onClick={() => handleDelete(dish.uniqueId)}
                      className="absolute top-0 right-0 p-1 text-red-600 hover:text-red-800"
                    >
                      ❌
                    </button>
                    <DishCard
                      name={dish.name}
                      imageUrl={dish.imageUrl}
                      description={dish.description}
                      price={dish.price}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <button
            onClick={handlePay}
            className="mt-2 bg-green-500 text-white p-2 rounded"
          >
            Pay Order
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderAccordion;





// import React, { useState, useEffect } from 'react';
// import DishCard from '../views/restaurantInterface/DishCard';
// import { useDrop } from 'react-dnd';
// import { v4 as uuidv4 } from 'uuid';

// const OrderAccordion = ({ order, onDishDrop, onPay }) => {
//   // Ensure droppedDishes is initialized correctly
//   const [isOpen, setIsOpen] = useState(false);
//   const [droppedDishes, setDroppedDishes] = useState(order.dishes || []);

//   // Use effect to update droppedDishes if order.dishes changes
//   useEffect(() => {
//     setDroppedDishes(order.dishes || []);
//   }, [order.dishes]);

//   const [{ isOver }, drop] = useDrop({
//     accept: 'dish',
//     drop: (item) => {
//       const newItem = { ...item, uniqueId: uuidv4() };
//       setDroppedDishes((prevDishes) => [...prevDishes, newItem]);
//       onDishDrop(order.id, newItem);
//     },
//     collect: (monitor) => ({
//       isOver: monitor.isOver(),
//     }),
//   });

//   const handleDelete = (id) => {
//     const updatedDishes = droppedDishes.filter((dish) => dish.uniqueId !== id);
//     setDroppedDishes(updatedDishes);
//     onDishDrop(order.id, null, id);
//   };

//   const handlePay = () => {
//     onPay(order.id);
//   };

//   return (
//     <div className="mb-2 border border-gray-300 rounded-lg">
//       <div
//         className="p-4 flex justify-between items-center bg-gray-100 cursor-pointer"
//         onClick={() => setIsOpen(!isOpen)}
//       >
//         <h3 className="text-lg font-bold">Order #{order.id}</h3>
//         <span>{isOpen ? '-' : '+'}</span>
//       </div>
//       {isOpen && (
//         <div className="p-4" ref={drop}>
//           <div className={isOver ? 'bg-green-100 p-2' : 'bg-white p-2'}>
//             {droppedDishes.length === 0 ? (
//               <p className="text-gray-600">Drag dishes here</p>
//             ) : (
//               <div className="grid grid-cols-1 gap-2">
//                 {droppedDishes.map((dish) => (
//                   <div key={dish.uniqueId} className="relative">
//                     <button
//                       onClick={() => handleDelete(dish.uniqueId)}
//                       className="absolute top-0 right-0 p-1 text-red-600 hover:text-red-800"
//                     >
//                       ❌
//                     </button>
//                     <DishCard
//                       name={dish.name}
//                       imageUrl={dish.imageUrl}
//                       description={dish.description}
//                       price={dish.price}
//                     />
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>
//           <button
//             onClick={handlePay}
//             className="mt-2 bg-green-500 text-white p-2 rounded"
//           >
//             Pay Order
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default OrderAccordion;



import api from './api';

export const createUser = (values) => api.post(`/api/users`, values);

export const getUserById = (id) => api.get(`/api/users/${id}`);

export const updateUser = (id, values) => api.patch(`/api/users/${id}`, values);

export const deleteUser = (id) => api.delete(`/api/users/${id}`);

export const getUsers = (rowsPerPage, currentPage, searchText) =>
  api.get(
    `/api/users?limit=${rowsPerPage}&page=${currentPage}&searchText=${searchText}`
  );

export const changePassword = (id, values) =>
  api.patch(`/api/users/${id}/password`, values);

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { X, FileText, Save } from 'lucide-react';
import { BasicDetails } from '../../../../../components/salesReceipt/BasicDetails.tsx';
import ItemTable from '../../../../../components/salesReceipt/ItemTable.tsx';
import { Notes } from '../../../../../components/salesReceipt/Notes.tsx';
import { PaymentDetails } from '../../../../../components/salesReceipt/PaymentDetails.tsx';
import { getClients } from '../../../../../api/accounting';
import { getAllItemsOrServices } from '../../../../../api/itemOrService';
import { createSalesReceipt } from '../../../../../api/salesReceipt'; // Import the API function
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles

interface ItemRow {
  id: string;
  details: string;
  quantity: number;
  rate: number;
  discount: number;
  tax: string;
  amount: number;
  itemId?: number;
}

interface Client {
  id: string;
  name: string;
}

interface Item {
  id: number;
  name: string;
  description: string;
  sellingPrice: number;
}

function SalesReceipt() {
  const [items, setItems] = useState<ItemRow[]>([{
    id: '1',
    details: '',
    quantity: 1.00,
    rate: 0.00,
    discount: 0,
    tax: '',
    amount: 0.00
  }]);
  const [availableItems, setAvailableItems] = useState<Item[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);
  const [selectedClient, setSelectedClient] = useState('');
  const [receiptDate, setReceiptDate] = useState(new Date().toISOString().split('T')[0]);
  const [receiptNumber, setReceiptNumber] = useState('');
  const [notes, setNotes] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [reference, setReference] = useState('');
  const [status, setStatus] = useState('draft');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientsResponse, itemsResponse] = await Promise.all([
          getClients(),
          getAllItemsOrServices()
        ]);
        setClients(clientsResponse.data);
        setAvailableItems(itemsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleItemSelect = (selectedItem: Item) => {
    setItems(currentItems => {
      const newItems = [...currentItems];
      newItems[selectedRowIndex] = {
        ...newItems[selectedRowIndex],
        details: selectedItem.name,
        rate: selectedItem.sellingPrice,
        amount: selectedItem.sellingPrice * newItems[selectedRowIndex].quantity,
        itemId: selectedItem.id,
      };
      return newItems;
    });
    setIsItemModalOpen(false);
  };

  const calculateSubTotal = () => items.reduce((sum, item) => sum + item.amount, 0);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const salesReceiptData = {
      receiptNumber,
      clientId: selectedClient,
      receiptDate,
      notes,
      paymentMethod,
      reference,
      status,
      items: items.map(item => ({
        quantity: item.quantity,
        discount: item.discount,
        tax: item.tax,
        rate: item.rate,
        itemId: item.itemId,
        amount: item.amount,
      })),
    };

    try {
      const response = await createSalesReceipt(salesReceiptData);
      toast.success('Sales receipt created successfully!');
      console.log('Sales receipt created successfully:', response.data);

      // Clear form fields by resetting state to initial values
      setReceiptNumber('');
      setSelectedClient('');
      setReceiptDate(new Date().toISOString().split('T')[0]);
      setNotes('');
      setPaymentMethod('');
      setReference('');
      setStatus('draft');
      setItems([{
        id: '1',
        details: '',
        quantity: 1.00,
        rate: 0.00,
        discount: 0,
        tax: '',
        amount: 0.00
      }]);
    } catch (error) {
      toast.error('Error creating sales receipt.');
      console.error('Error creating sales receipt:', error);
    }
  };

  return (
    <motion.div 
      className="min-h-screen p-4 md:p-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <ToastContainer /> {/* Add ToastContainer to render toasts */}
      <form onSubmit={handleSubmit}>
        <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="flex justify-between items-center p-4 border-b border-grey-goose bg-seashell">
            <div className="flex items-center space-x-2">
              <FileText className="text-hazel-green" size={24} />
              <h1 className="text-xl font-medium text-bluish-grey">New Sales Receipt</h1>
            </div>
            <button type="button" className="text-silver hover:text-bluish-grey transition-colors">
              <X size={20} />
            </button>
          </div>

          <div className="p-6 space-y-8">
            <BasicDetails
              clients={clients}
              selectedClient={selectedClient}
              onClientChange={setSelectedClient}
              receiptDate={receiptDate}
              onDateChange={setReceiptDate}
              receiptNumber={receiptNumber}
              onNumberChange={setReceiptNumber}
              reference={reference}
              onReferenceChange={setReference}
            />
            
            <ItemTable
              items={items}
              onItemChange={setItems}
              availableItems={availableItems}
              isModalOpen={isItemModalOpen}
              onModalOpen={setIsItemModalOpen}
              selectedRowIndex={selectedRowIndex}
              onRowSelect={setSelectedRowIndex}
              onItemSelect={handleItemSelect}
            />

            <Notes value={notes} onChange={setNotes} />
            
            <PaymentDetails
              subTotal={calculateSubTotal()}
              paymentMethod={paymentMethod}
              onPaymentMethodChange={setPaymentMethod}
            />

            {/* Footer */}
            <div className="flex justify-between items-center pt-6 border-t border-grey-goose">
              <div className="flex items-center gap-4">
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="p-2.5 border border-grey-goose rounded-lg focus:outline-none focus:border-sage bg-white text-bluish-grey text-sm transition-colors hover:border-sage"
                >
                  <option value="draft">Draft</option>
                  <option value="paid">Paid</option>
                  <option value="pending">Pending</option>
                </select>
              </div>
              <div className="flex gap-3">
                <button
                  type="submit"
                  className="px-6 py-2.5 bg-hazel-green text-white rounded-lg hover:bg-sage transition-colors duration-200 flex items-center gap-2"
                >
                  <Save size={18} />
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </motion.div>
  );
}

export default SalesReceipt;
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#90a68c'
    }
  }
});

const SelectInput = ({
  value,
  label,
  options,
  onChange,
  onBlur,
  showError,
  errorMessage,
  isEntity
}) => {
  return (
    <ThemeProvider theme={theme}>
      <div className='flex flex-col mb-3'>
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            value={value}
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            MenuProps={MenuProps}
            sx={{
              borderColor: 'primary.main'
            }}
          >
            {options.map((option) => {
              const value = isEntity ? option : option.id;
              return (
                <MenuItem value={value} key={option.id}>
                  {isEntity
                    ? option
                    : option.title
                    ? option.title
                    : option.number}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {showError && <p className='text-sm text-error'>{errorMessage}</p>}
      </div>
    </ThemeProvider>
  );
};

export default SelectInput;

import React from 'react';
import { HelpCircle } from 'lucide-react';

const ProjectsCard = () => {
  return (
    <div className="card p-6">
      <div className="card-header mb-8">
        <h2 className="text-xl">Projects</h2>
        <HelpCircle className="help-icon" />
      </div>

      <div className="flex flex-col items-center justify-center h-48 text-bluish-grey bg-tealish-blue/50 rounded-lg">
        <p className="mb-3">Add Project(s) to this watchlist</p>
        <button className="text-hazel-green hover:text-sage transition-colors font-medium">
          Add Project
        </button>
      </div>
    </div>
  );
};

export default ProjectsCard;
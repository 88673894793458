

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { createMenu, addDishToMenu } from '../../api/venueMenus';
import { getDishSections } from '../../api/dishSection';
import { getAllVenues } from '../../api/venue';
import Loading from '../../components/loader';
import CustomInput from '../../components/custom-input';
import SelectInput from '../../components/select-input';
import { SubmitButton, CancelButton } from '../../components/buttons';
import { getBarProducts, updateBarProduct } from '../../api/barProduct';
import { addBarProductToMenu } from '../../api/venueMenus';
import { updateDishSection } from '../../api/venueMenus';

// const DishSchema = Yup.object().shape({
//   name: Yup.string().required('Required'),
//   price: Yup.number().required('Required'),
//   type: Yup.string().required('Required'),
//   isAvailable: Yup.boolean().required('Required'),
//   description: Yup.string().optional(),
// });

// function VenueMenuForm() {
//   const navigate = useNavigate();
//   const [menu, setMenu] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [venues, setVenues] = useState([]);
//   const [dishSections, setDishSections] = useState([]);
//   const [barProducts, setBarProducts] = useState([]);

//   const fetchVenues = useCallback(async () => {
//     try {
//       const response = await getAllVenues();
//       setVenues(response.data);
//     } catch (error) {
//       console.error('Failed to fetch venues:', error);
//     }
//   }, []);

//   const fetchDishSections = useCallback(async () => {
//     try {
//       const response = await getDishSections();
//       setDishSections(response.data);
//     } catch (error) {
//       console.error('Failed to fetch dish sections:', error);
//     }
//   }, []);

//   const fetchBarProducts = useCallback(async () => {
//     try {
//       const response = await getBarProducts();
//       setBarProducts(response.data);
//     } catch (error) {
//       console.error('Failed to fetch bar products:', error);
//     }
//   }, []);

//   useEffect(() => {
//     async function fetchData() {
//       setLoading(true);
//       try {
//         await fetchVenues();
//         await fetchDishSections();
//         await fetchBarProducts();
//       } catch (error) {
//         console.error('Failed to fetch data:', error);
//       } finally {
//         setLoading(false);
//       }
//     }
//     fetchData();
//   }, [fetchVenues, fetchDishSections, fetchBarProducts]);


//   // const handleSubmit = async (values, { setSubmitting }) => {
//   //   setSubmitting(true);
    
//   //   try {
//   //     const menuData = {
//   //       name: values.name,
//   //       price: parseFloat(values.price),
//   //       venueId: values.venueId,
//   //       dishSections: values.dishSections.map(d => ({ id: d.id })),
//   //       barProducts: values.barProducts.map(b => ({ id: b.id }))
//   //     };
  
//   //     const createdMenu = await createMenu(menuData);
  
//   //     for (const dishSection of values.dishSections) {
//   //       if (dishSection.id) {
//   //         // await updateDishSection(createdMenu.id, dishSection.id, dishSection);
//   //         await addDishToMenu(createdMenu.id, dishSection);

//   //       } 
//   //       // else {
//   //       //   await addDishToMenu(createdMenu.id, dishSection);
//   //       // }
//   //     }
  
//   //     for (const barProduct of values.barProducts) {
//   //       if (barProduct.id) {
//   //         // await updateBarProduct(barProduct.id, barProduct);
//   //         await addBarProductToMenu(createdMenu.id, barProduct);

//   //       } 
//   //       // else {
//   //       //   await addBarProductToMenu(createdMenu.id, barProduct);
//   //       // }
//   //     }
  
//   //     navigate('/menu');
//   //   } catch (error) {
//   //     console.error('Failed to save menu or dish section:', error);
//   //   } finally {
//   //     setSubmitting(false);
//   //   }
//   // };
  

//   const handleSubmit = async (values, { setSubmitting }) => {
//     setSubmitting(true);
//     try {
//       const menuData = {
//         name: values.name,
//         price: parseFloat(values.price),
//         venueId: parseInt(values.venueId, 10),
//         dishSections: values.dishSections.map(d => ({ id: d.id })),
//         barProducts: values.barProducts.map(b => ({ id: b.id }))
//       };
  
//       const response = await createMenu(menuData);
//       const createdMenu = response.data;
//       const createdMenuId = createdMenu.id;
  
//       console.log("Created Menu ID:", createdMenuId);
  
//       for (const dishSection of values.dishSections) {
//         if (dishSection.id) {
//           try {
//             await addDishToMenu(createdMenuId, dishSection);
//           } catch (error) {
//             console.error(`Failed to add dish section ${dishSection.id}:`, error.response ? error.response.data : error.message);
//           }
//         }
//       }
  
//       for (const barProduct of values.barProducts) {
//         if (barProduct.id) {
//           try {
//             await addBarProductToMenu(createdMenuId, barProduct);
//           } catch (error) {
//             console.error(`Failed to add bar product ${barProduct.id}:`, error.response ? error.response.data : error.message);
//           }
//         }
//       }
  
//       navigate('/venue');
//     } catch (error) {
//       console.error('Failed to save menu or dish section:', error.response ? error.response.data : error.message);
//     } finally {
//       setSubmitting(false);
//     }
//   };
  
  
  
  
  
//   // const handleSubmit = async (values) => {
//   //   console.log('Form submitted with values:', values);
//   //   try {
//   //     const menuData = { name: values.name, venueId: values.venueId, price: values.price };
//   //     const createdMenu = await createMenu(menuData);
      
//   //     for (const dishSection of values.dishSections) {
//   //       if (dishSection.id) {
//   //         await updateDishSectionSection(createdMenu.id, dishSection.id, dishSection);
//   //       } else {
//   //         await addDishToMenu(createdMenu.id, dishSection);
//   //       }
//   //     }
      
//   //     for (const barProduct of values.barProducts) {
//   //       if (barProduct.id) {
//   //         await updateBarProduct(barProduct.id, barProduct);
//   //       } else {
//   //         await addBarProductToMenu(createdMenu.id, barProduct);
//   //       }
//   //     }
      
//   //     navigate('/menu');
//   //   } catch (error) {
//   //     console.error('Failed to save menu or dish section:', error);
//   //   }
//   // };
  
  

//   if (loading) {
//     return <Loading />;
//   }

//   return (
//     <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
//       <Formik
//         initialValues={menu || { name: '', venueId: '', price: 0, dishSections: [], barProducts: [] }}

//         validationSchema={Yup.object({
//           name: Yup.string().required('Required'),
//           venueId: Yup.string().required('Required'),
//           price: Yup.number().required('Required'),
//           dishSections: Yup.array().of(
//             Yup.object().shape({
//               id: Yup.string().required('Required'),
//               // Other fields
//             })
//           ),
//           barProducts: Yup.array().of(
//             Yup.object().shape({
//               id: Yup.string().required('Required'),
//               // Other fields
//             })
//           ),
//         })}
//         onSubmit={handleSubmit}
//       >
//         {({ values, setFieldValue }) => (
//           <Form className='px-7 py-5'>
//             <h1 className='text-xl font-semibold mb-5'>Manage Menu</h1>
//             <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
//               <CustomInput
//                 type='text'
//                 name='name'
//                 label='Menu Name'
//                 value={values.name}
//                 onChange={(e) => setFieldValue('name', e.target.value)}
//               />
//               <SelectInput
//                 value={values.venueId}
//                 label='Venue'
//                 options={venues.map(venue => ({
//                   id: venue.id,
//                   title: venue.name
//                 }))}
//                 onChange={(e) => setFieldValue('venueId', e.target.value)}
//                 onBlur={(e) => setFieldValue('venueId', e.target.value)}
//                 showError={false}
//               />
//               <CustomInput
//                 type='number'
//                 name='price'
//                 label='Menu Price'
//                 value={values.price}
//                 onChange={(e) => setFieldValue('price', e.target.value)}
//               />
//             </div>

//             <h2 className='text-lg font-semibold mt-6 mb-2'>Dish Sections</h2>
//   {values.dishSections.map((dishSection, index) => (
//     <div key={index} className='border border-gray-200 rounded-lg p-4 mb-3'>
//       <h3 className='font-semibold mb-2'>Dish Section {index + 1}</h3>
//       <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
//         <SelectInput
//           name={`dishSections.${index}.id`}
//           label='Dish Section'
//           options={dishSections.map(dish => ({
//             id: dish.id,
//             title: dish.name,
//           }))}
//           value={dishSection.id}
//           onChange={(e) => setFieldValue(`dishSections.${index}.id`, e.target.value)}
//           showError={false}
//         />
//       </div>
//       <button
//         type='button'
//         className='text-red-500 mt-3'
//         onClick={() => setFieldValue('dishSections', values.dishSections.filter((_, i) => i !== index))}
//       >
//         Remove Dish Section
//       </button>
//     </div>
//   ))}
//   <button
//     type='button'
//     className='text-blue-500 mt-4'
//     onClick={() =>
//       setFieldValue('dishSections', [
//         ...values.dishSections,
//         { id: '' }
//       ])
//     }
//   >
//     Add Dish Section
//   </button>

//   <h2 className='text-lg font-semibold mt-6 mb-2'>Bar Products</h2>
//   {values.barProducts.map((barProduct, index) => (
//     <div key={index} className='border border-gray-200 rounded-lg p-4 mb-3'>
//       <h3 className='font-semibold mb-2'>Bar Product {index + 1}</h3>
//       <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
//         <SelectInput
//           name={`barProducts.${index}.id`}
//           label='Bar Product'
//           options={barProducts.map(product => ({
//             id: product.id,
//             title: product.name,
//           }))}
//           value={barProduct.id}
//           onChange={(e) => setFieldValue(`barProducts.${index}.id`, e.target.value)}
//           showError={false}
//         />
//       </div>
//       <button
//         type='button'
//         className='text-red-500 mt-3'
//         onClick={() => setFieldValue('barProducts', values.barProducts.filter((_, i) => i !== index))}
//       >
//         Remove Bar Product
//       </button>
//     </div>
//   ))}

//             <button
//               type='button'
//               className='text-blue-500 mt-4'
//               onClick={() =>
//                 setFieldValue('barProducts', [
//                   ...values.barProducts,
//                   { id: '' }
//                 ])
//               }
//             >
//               Add Bar Product
//             </button>

//             <div className='flex justify-end items-center w-full mt-4 text-sm'>
//               <CancelButton onCancel={() => navigate('/menus/menu-page')} />
//               <SubmitButton width='w-1/2 md:w-1/6' title='Submit' />
//             </div>
//           </Form>
//         )}
//       </Formik>
//     </div>
//   );
// }

// export default VenueMenuForm;







// import React, { useState, useEffect, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
import {  FieldArray} from 'formik';
// import * as Yup from 'yup';
// import CustomInput from '../../components/custom-input';
// import SelectInput from '../../components/select-input';
// import { createMenu } from '../../api/menu';
// import { getAllVenues } from '../../api/venue';
// import { getDishSections } from '../../api/dishSection';
// import { getBarProducts } from '../../api/barProduct';
// import { CancelButton, SubmitButton } from '../../components/buttons';

function VenueMenuForm() {
  const navigate = useNavigate();
  const [venues, setVenues] = useState([]);
  const [dishSections, setDishSections] = useState([]);
  const [barProducts, setBarProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchVenues = useCallback(async () => {
    try {
      const response = await getAllVenues();
      setVenues(response.data);
    } catch (error) {
      console.error('Failed to fetch venues:', error);
    }
  }, []);

  const fetchDishSections = useCallback(async () => {
    try {
      const response = await getDishSections();
      setDishSections(response.data);
    } catch (error) {
      console.error('Failed to fetch dish sections:', error);
    }
  }, []);

  const fetchBarProducts = useCallback(async () => {
    try {
      const response = await getBarProducts();
      setBarProducts(response.data);
    } catch (error) {
      console.error('Failed to fetch bar products:', error);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        await Promise.all([fetchVenues(), fetchDishSections(), fetchBarProducts()]);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [fetchVenues, fetchDishSections, fetchBarProducts]);

  if (loading) {
    return <div>Loading...</div>; // Add your loading spinner or component
  }

  
  // const calculateTotalPrice = (barProducts, allProducts) => {
  //   if (!Array.isArray(barProducts)) {
  //     return 0;
  //   }
  
  //   return barProducts.reduce((sum, item) => {
  //     // Find the selected product in the allProducts list
  //     const product = allProducts.find((p) => p.id === item.id);
      
  //     if (product) {
  //       console.log(`Product found: ${JSON.stringify(product)}`); // Debugging statement
  
  //       if (!isNaN(product.price)) {
  //         const quantity = parseInt(item.quantity, 10) || 1; // Default to 1 if quantity is not defined
  //         return sum + (product.price * quantity);
  //       }
  //     }
  //     return sum;
  //   }, 0);
  // };


  const calculateTotalPrice = (dishSections, barProducts, allDishSections, allBarProducts) => {
    let dishSectionTotal = 0;
    let barProductTotal = 0;
  
    // Calculate total for dish sections
    dishSectionTotal = dishSections.reduce((sum, item) => {
      const dish = allDishSections.find(d => d.id === item.id);
      if (dish && !isNaN(dish.price)) {
        return sum + dish.price;
      }
      return sum;
    }, 0);
  
    // Calculate total for bar products
    barProductTotal = barProducts.reduce((sum, item) => {
      const product = allBarProducts.find(p => p.id === item.id);
      if (product && !isNaN(product.price)) {
        const quantity = parseInt(item.quantity, 10) || 1; // Default to 1 if quantity is not defined
        return sum + (product.price * quantity);
      }
      return sum;
    }, 0);
  
    return dishSectionTotal + barProductTotal;
  };
  
  
  
  
  
  
  
  
  

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const menuData = {
        name: values.name,
        price: parseFloat(values.price),
        venueId: parseInt(values.venueId, 10),
        dishSections: values.dishSections.map(d => ({ id: d.id })),
        barProducts: values.barProducts.map(b => ({ id: b.id }))
      };

      await createMenu(menuData);

      navigate('/venue');
    } catch (error) {
      console.error('Failed to save menu:', error.response ? error.response.data : error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
      <Formik
        initialValues={{
          name: '',
          venueId: '',
          price: 0,
          dishSections: [],
          barProducts: []
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Required'),
          venueId: Yup.string().required('Required'),
          price: Yup.number().required('Required').positive('Price must be positive'),
          dishSections: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Required'),
            })
          ),
          barProducts: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required('Required'),
            })
          ),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => {
          // const handleFieldChange = (index, field, value) => {
          //   const updatedBarProducts = [...values.barProducts];
          //   updatedBarProducts[index] = { ...updatedBarProducts[index], [field]: value };
          
          //   // Update barProducts with the new state
          //   setFieldValue('barProducts', updatedBarProducts);
          
          //   // Calculate total price based on updated bar products
          //   const total = calculateTotalPrice(updatedBarProducts, barProducts);
          //   console.log('Total Price after Field Change:', total); // Debugging statement
          //   setFieldValue('price', total); // Update the total price field
          // };
          

          const handleFieldChange = (index, field, value) => {
            const updatedBarProducts = [...values.barProducts];
            updatedBarProducts[index] = { ...updatedBarProducts[index], [field]: value };
            
            // Update barProducts with the new state
            setFieldValue('barProducts', updatedBarProducts);
            
            // Calculate total price based on updated bar products and dish sections
            const total = calculateTotalPrice(values.dishSections, updatedBarProducts, dishSections, barProducts);
            console.log('Total Price after Field Change:', total); // Debugging statement
            setFieldValue('price', total); // Update the total price field
          };
          
          // Handle dish section changes similarly
          const handleDishChange = (index, value) => {
            const updatedDishSections = [...values.dishSections];
            updatedDishSections[index] = { id: value };
            
            // Update dishSections with the new state
            setFieldValue('dishSections', updatedDishSections);
            
            // Calculate total price based on updated dish sections and bar products
            const total = calculateTotalPrice(updatedDishSections, values.barProducts, dishSections, barProducts);
            console.log('Total Price after Dish Change:', total); // Debugging statement
            setFieldValue('price', total); // Update the total price field
          };
          

          return (
            <Form className='px-7 py-5'>
              <h1 className='text-xl font-semibold mb-5'>Manage Menu</h1>
              <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
                <CustomInput
                  type='text'
                  name='name'
                  label='Menu Name'
                  value={values.name}
                  onChange={(e) => setFieldValue('name', e.target.value)}
                />
                <SelectInput
                  name='venueId'
                  label='Venue'
                  value={values.venueId}
                  options={venues.map(venue => ({
                    id: venue.id,
                    title: venue.name
                  }))}
                  onChange={(e) => setFieldValue('venueId', e.target.value)}
                  showError={false}
                />
                <CustomInput
                  type='number'
                  name='price'
                  label='Menu Price'
                  value={values.price}
                  disabled
                />
              </div>

              <h2 className='text-lg font-semibold mt-6 mb-2'>Dish Sections</h2>
              <FieldArray
  name="dishSections"
  render={arrayHelpers => (
    <div>
      {values.dishSections.map((dishSection, index) => (
        <div key={index} className='border border-gray-200 rounded-lg p-4 mb-3'>
          <h3 className='font-semibold mb-2'>Dish Section {index + 1}</h3>
          <SelectInput
            name={`dishSections.${index}.id`}
            label='Dish Section'
            value={dishSection.id}
            options={dishSections.map(dish => ({
              id: dish.id,
              title: dish.name,
            }))}
            onChange={(e) => handleDishChange(index, e.target.value)}
            showError={false}
          />
          <button
            type='button'
            className='text-red-500 mt-3'
            onClick={() => arrayHelpers.remove(index)}
          >
            Remove Dish Section
          </button>
        </div>
      ))}
      <button
        type='button'
        className='text-blue-500 mt-4'
        onClick={() => arrayHelpers.push({ id: '' })}
      >
        Add Dish Section
      </button>
    </div>
  )}
/>

<FieldArray
  name="barProducts"
  render={arrayHelpers => (
    <div>
      {values.barProducts.map((barProduct, index) => (
        <div key={index} className='border border-gray-200 rounded-lg p-4 mb-3'>
          <h3 className='font-semibold mb-2'>Bar Product {index + 1}</h3>
          <SelectInput
            name={`barProducts.${index}.id`}
            label='Bar Product'
            value={barProduct.id}
            options={barProducts.map(product => ({
              id: product.id,
              title: product.name,
            }))}
            onChange={(e) => handleFieldChange(index, 'id', e.target.value)}
            showError={false}
          />
          <CustomInput
            type='number'
            name={`barProducts.${index}.quantity`}
            label='Quantity'
            value={barProduct.quantity || 1} // Default to 1 if quantity is not defined
            onChange={(e) => handleFieldChange(index, 'quantity', e.target.value)}
          />
          <button
            type='button'
            className='text-red-500 mt-3'
            onClick={() => arrayHelpers.remove(index)}
          >
            Remove Bar Product
          </button>
        </div>
      ))}
      <button
        type='button'
        className='text-blue-500 mt-4'
        onClick={() => arrayHelpers.push({ id: '', quantity: 1 })} // Default quantity to 1
      >
        Add Bar Product
      </button>
    </div>
  )}
/>


              <div className='flex justify-end items-center w-full mt-4 text-sm'>
                <CancelButton onCancel={() => navigate('/menus/menu-page')} />
                <SubmitButton width='w-1/2 md:w-1/4' />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default VenueMenuForm;



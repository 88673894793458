import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

export default function SearchBar({ searchTerm, onSearchChange }) {
  return (
    <div className="max-w-3xl mx-auto mb-8">
      <div className="relative">
        <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-bluish-grey" />
        <input
          type="text"
          placeholder="Search reports"
          className="w-full pl-10 pr-4 py-2 border border-grey-goose rounded-lg focus:ring-2 focus:ring-sage focus:border-transparent bg-white"
          value={searchTerm}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </div>
    </div>
  );
}
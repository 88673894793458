import React, { useState, useEffect } from "react";
import BarCard from "./BarCard";
import { getAllBarItems } from "../../api/restaurantInterface";
import { useDrag } from 'react-dnd';


const DraggableBarCard = ({ id, index, name, imageUrl, description, price }) => {
  const [, drag] = useDrag({
    type: "bar",
    item: { id, index, name, imageUrl, description, price },
  });

  return (
    <div ref={drag}>
      <BarCard
        name={name}
        imageUrl={imageUrl}
        description={description}
        price={price}
      />
    </div>
  );
};

const BarPage = ({ onDrop }) => {
  const [barItems, setBarItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBarItems = async () => {
      try {
        const response = await getAllBarItems();
        setBarItems(response.data || []);
      } catch (error) {
        console.error('Failed to fetch bar items:', error);
        setError('Failed to fetch bar items');
      } finally {
        setLoading(false);
      }
    };

    fetchBarItems();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="flex flex-wrap gap-2">
      {barItems.map((item, index) => (
        <DraggableBarCard
          key={item.id}
          id={item.id}
          index={index}
          name={item.name}
          imageUrl={item.imageUrl}
          description={item.description}
          price={item.price}
        />
      ))}
    </div>
  );
}

export default BarPage;

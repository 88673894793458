import api from './api';

export const getAllMenus = () => api.get('/api/venueMenu');
export const getMenu = (id) => api.get(`/api/venueMenu/${id}`);


export const createMenu = (values) =>
  api.post('/api/venueMenu/create-menu', {
    ...values,
    price: parseFloat(values.price), // Convert to float
    venueId: parseInt(values.venueId, 10)
  });


// export const createMenu = (values) =>
//   api.post('/api/venueMenu/create-menu', values);


export const addDishToMenu = (menuId, values) =>
  api.post(`/api/venueMenu/${menuId}/dishSections`, values);



// export const addDishToMenu = (menuId, values) =>
//   // api.post(`/api/venueMenu/${venueMenuId}/dishSections`, values);
//   api.post(`/api/venueMenu/${menuId}/dishSections`, values);

export const updateDishSection = (venueMenuId, dishSectionId, values) =>
  api.put(`/api/venueMenu/${venueMenuId}/dishSections/${dishSectionId}`, values);
export const deleteMenu = (id) =>
  api.delete(`/api/venueMenu/${id}`);

export const addBarProductToMenu = (menuId, values) => 
  api.post(`/api/venueMenu/${menuId}/barProducts`, values);


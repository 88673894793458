import { 
    BookOpen, 
    Receipt, 
    CreditCard, 
    DollarSign, 
    Building2, 
    FileSpreadsheet,
    PiggyBank,
    Wallet,
    Scale
  } from 'lucide-react';
  
  export const accountingBooks = [
    {
      title: "Purchase Ledger",
      description: "Track all purchase transactions and supplier accounts",
      icon: Receipt,
      accentColor: "text-hazel-green",
      borderColor: "border-hazel-green/20",
      bgColor: "bg-tealish-blue",
      link: "/accounting/purchases"
    },
    {
      title: "Sales Book",
      description: "Monitor revenue streams and customer transactions",
      icon: DollarSign,
      accentColor: "text-sage",
      borderColor: "border-sage/20",
      bgColor: "bg-seashell",
      link: "/accounting/sales"
    },
    {
      title: "General Ledger",
      description: "Master book of all financial records and entries",
      icon: BookOpen,
      accentColor: "text-bluish-grey",
      borderColor: "border-bluish-grey/20",
      bgColor: "bg-mercury",
      link: "/accounting/sales"
    },
    {
      title: "Bank Book",
      description: "Manage and reconcile all banking transactions",
      icon: Building2,
      accentColor: "text-salmon-pink",
      borderColor: "border-salmon-pink/20",
      bgColor: "bg-dawn-pink",
      link: "/accounting/sales"
    },
    {
      title: "Expense Ledger",
      description: "Track operational and administrative expenses",
      icon: CreditCard,
      accentColor: "text-watermelon",
      borderColor: "border-watermelon/20",
      bgColor: "bg-dawn-pink",
      link: "/accounting/sales"
    },
    {
      title: "Items Register",
      description: "Monitor fixed and current asset accounts",
      icon: FileSpreadsheet,
      accentColor: "text-heather",
      borderColor: "border-heather/20",
      bgColor: "bg-tealish-blue",
      link: "/accounting/items"
    },
    {
      title: "Investment Book",
      description: "Track hotel investments and returns",
      icon: PiggyBank,
      accentColor: "text-sage",
      borderColor: "border-sage/20",
      bgColor: "bg-seashell",
      link: "/accounting/sales"
    },
    {
      title: "Cash Book",
      description: "Monitor daily cash flows and balances",
      icon: Wallet,
      accentColor: "text-hazel-green",
      borderColor: "border-hazel-green/20",
      bgColor: "bg-tealish-blue",
      link: "/accounting/sales"
    },
    {
      title: "Budget",
      description: "View balanced debit and credit entries",
      icon: Scale,
      accentColor: "text-bluish-grey",
      borderColor: "border-bluish-grey/20",
      bgColor: "bg-mercury",
      link: "/accounting/budgets"
    }
  ];
import React from 'react';

interface TabNavigationProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
}

function TabNavigation({ activeTab, onTabChange }: TabNavigationProps) {
  return (
    <div className="flex space-x-1 mb-6 border-b border-gray-200">
      <button
        onClick={() => onTabChange('conference')}
        className={`px-6 py-3 font-medium transition-colors relative ${
          activeTab === 'conference'
            ? 'text-hazel-green border-hazel-green'
            : 'text-gray-500 hover:text-gray-700'
        }`}
      >
        Conference Room Reservation
        {activeTab === 'conference' && (
          <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-hazel-green" />
        )}
      </button>
      <button
        onClick={() => onTabChange('room')}
        className={`px-6 py-3 font-medium transition-colors relative ${
          activeTab === 'room'
            ? 'text-hazel-green border-hazel-green'
            : 'text-gray-500 hover:text-gray-700'
        }`}
      >
        Room Reservation
        {activeTab === 'room' && (
          <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-hazel-green" />
        )}
      </button>
    </div>
  );
}

export default TabNavigation;
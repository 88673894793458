// PurchasesNavigation.js
import { NavLink } from 'react-router-dom';
import React from 'react';

const navItems = [
  { path: '/accounting/budgets/budget-list', label: 'Budgets' },
  { path: '/accounting/purchases/payments', label: 'Payments Made' },
];

export default function BudgetNavigation() {
  return (
    <nav className="flex space-x-8">
      {navItems.map((item) => (
        <NavLink
          key={item.path}
          to={item.path}
          className={({ isActive }) =>
            `py-4 px-1 border-b-2 font-medium transition-colors ${
              isActive
                ? 'border-hazel-green text-hazel-green'
                : 'border-transparent text-bluish-grey hover:text-hazel-green hover:border-grey-goose'
            }`
          }
        >
          {item.label}
        </NavLink>
      ))}
    </nav>
  );
}

// PurchasesLayout.js
import { Outlet } from 'react-router-dom';
import PurchasesNavigation from './PurchasesNavigation';
import { Plus } from 'lucide-react';
import { Button } from '../../../../../components/shadCN/Button.tsx';

export default function PurchasesLayout() {
  return (
    <div className="min-h-screen bg-seashell">
      <div className="bg-white border-b border-mercury px-8 py-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold text-hazel-green">Purchases</h1>
          <Button className="bg-hazel-green hover:bg-sage text-white">
            <Plus className="h-4 w-4 mr-2" />
            New Transaction
          </Button>
        </div>
        <PurchasesNavigation />
      </div>
      <div className="container mx-auto py-8 px-4">
        <Outlet />
      </div>
    </div>
  );
}

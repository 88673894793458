import api from './api';

// Fetch all conference rooms
export const getAllConferenceRooms = () => api.get('/api/conferenceRoom/conference-rooms');

// Fetch a single conference room by ID
export const getConferenceRoom = (id) => api.get(`/api/conferenceRoom/conference-rooms/${id}`);

// Create a new conference room
export const createConferenceRoom = (values) => 
  api.post('/api/conferenceRoom/create-conference-room', {
    ...values,
    pricePerHour: parseFloat(values.pricePerHour), // Convert to float
    capacity: parseInt(values.capacity, 10),       // Convert to integer
  });

// Update an existing conference room
export const updateConferenceRoom = (id, values) => 
  api.patch(`/api/conferenceRoom/conference-rooms/${id}`, {
    ...values,
    pricePerHour: parseFloat(values.pricePerHour), // Convert to float
    capacity: parseInt(values.capacity, 10),       // Convert to integer
  });

// Delete a conference room
export const deleteConferenceRoom = (id) => api.delete(`/api/conferenceRoom/conference-rooms/${id}`);

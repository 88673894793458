import React from 'react';

interface QuickLinkProps {
  label: string;
  primary?: boolean;
}

export function QuickLink({ label, primary }: QuickLinkProps) {
  return (
    <button
      className={`
        p-4 rounded-lg text-center transition-all duration-200 font-medium
        ${primary 
          ? 'bg-watermelon text-white hover:bg-salmon-pink shadow-lg hover:shadow-xl' 
          : 'bg-dawn-pink text-watermelon hover:bg-salmon-pink hover:text-white shadow-sm hover:shadow-md'
        }
      `}
    >
      {label}
    </button>
  );
}
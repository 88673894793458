import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Checkbox, FormControlLabel } from '@mui/material';
import Loading from '../../components/loader';
import SelectInput from '../../components/select-input';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import { getPermissions } from '../../api/permissions';
import { createRole, updateRole } from '../../api/roles';
import { entities } from '../../constants/entities';

function RoleForm({ isEdit, role }) {
  const navigate = useNavigate();
  const [entityPermissions, setEntityPermissions] = useState([]);
  const [currentPermissions, setCurrentPermissions] = useState([]);
  const [loading, setLoading] = useState(true);

  const getEntityPermissions = async (entity) => {
    try {
      const response = await getPermissions(entity);
      if (isEdit) {
        const permissions = response.data
          .filter((item) => role.permissions.includes(item.permission))
          .map((item) => item.id);

        setCurrentPermissions((prevPermissions) => [
          ...prevPermissions,
          ...permissions.filter(
            (permission) => !prevPermissions.includes(permission)
          )
        ]);
      }
      setEntityPermissions(response.data);
      setLoading(false);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getEntityPermissions('users');
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg py-6 sm:py-10 px-4 sm:px-8 mt-5'>
      <Formik
        initialValues={{
          ...role,
          entity: 'users'
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().min(2).required('Title is required*'),
          entity: Yup.string().required('Entity is required*')
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          values.permissionIds = currentPermissions;
          try {
            if (isEdit) {
              const { id, entity, ...valuesWithoutPermissions } = values;

              await updateRole(role.id, {
                ...valuesWithoutPermissions
              });
              toast.success('Role updated successfully!');
            } else {
              await createRole(values);
              navigate('/roles');
            }
            setSubmitting(false);
          } catch (err) {
            setErrors({
              submit: err?.response?.data
            });
            setSubmitting(false);
            toast.error('An error occurred while updating role.');
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} className='w-full'>
            <div className='mb-4'>
              <CustomInput
                type='text'
                name='title'
                label='Title'
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.title && touched.title}
                errorMessage={errors.title}
              />
            </div>
            <div className='mb-4'>
              <SelectInput
                value={values.entity}
                label='Entity'
                options={entities}
                onChange={async (event) => {
                  setFieldValue('entity', event.target.value);
                  getEntityPermissions(event.target.value);
                }}
                onBlur={handleBlur}
                showError={errors.entity && touched.entity}
                errorMessage={errors.entity}
              />
            </div>
            <div className='mb-6'>
              <p className='text-sage font-medium w-full pb-0.5 border-b border-gray-200 mb-4'>
                Permissions
              </p>
              <div className='grid grid-cols-1 w-full gap-2 p-2'>
                {entityPermissions.map((permission) => {
                  const hasRole = currentPermissions.includes(permission.id);
                  return (
                    <FormControlLabel
                      key={permission.id}
                      control={
                        <Checkbox
                          checked={hasRole}
                          onChange={() => {
                            setCurrentPermissions(
                              hasRole
                                ? currentPermissions.filter(
                                    (id) => id !== permission.id
                                  )
                                : [...currentPermissions, permission.id]
                            );
                          }}
                          style={{
                            color: hasRole && '#90a68c'
                          }}
                          className="p-2"
                        />
                      }
                      label={
                        <p className='capitalize text-sm sm:text-base whitespace-normal break-words'>
                          {permission.permission.replaceAll('_', ' ')}
                        </p>
                      }
                      className='m-0 w-full bg-seashell bg-opacity-50 rounded-md px-2 py-1.5'
                    />
                  );
                })}
              </div>
            </div>
            {errors.permissionIds && touched.permissionIds && (
              <p className='text-sm text-error mb-4'>{errors.permissionIds}</p>
            )}
            {errors.submit && (
              <p className='text-error mb-4'>{errors.submit}</p>
            )}
            <div className='mt-8 sm:mt-10'>
              <div className='flex flex-col sm:flex-row justify-end items-stretch sm:items-center w-full gap-3 sm:gap-4'>
                <CancelButton 
                  onCancel={() => navigate('/roles')} 
                  className="w-full sm:w-[120px] py-3 sm:py-2"
                />
                <SubmitButton
                  isSubmitting={isSubmitting}
                  width='w-full sm:w-[120px]'
                  title={`${isEdit ? 'Edit role' : 'Create role'}`}
                  className="py-3 sm:py-2"
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default RoleForm;

import React, { useState, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { uploadImage } from '../../api/api'; 
import CustomInput from '../../components/custom-input';
import { SubmitButton, CancelButton } from '../../components/buttons';
import { IconUpload, IconX } from '@tabler/icons-react';
import { createConferenceRoom, updateConferenceRoom } from '../../api/conferenceRoom';

function ConferenceRoomForm({ isEdit, conferenceRoomData }) {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(isEdit ? `http://localhost:3001/${conferenceRoomData.imagePath}` : null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required*'),
    location: Yup.string().required('Location is required*'),
    capacity: Yup.number().required('Capacity is required*').min(1, 'Capacity must be at least 1'),
    pricePerHour: Yup.number().required('Price per hour is required*').min(0, 'Price cannot be negative'),
    description: Yup.string(),
    availability: Yup.boolean(),
    imagePath: Yup.string().nullable()
  });

  const initialValues = {
    name: isEdit ? conferenceRoomData.name : '',
    location: isEdit ? conferenceRoomData.location : '',
    capacity: isEdit ? conferenceRoomData.capacity : '',
    pricePerHour: isEdit ? conferenceRoomData.pricePerHour : '',
    description: isEdit ? conferenceRoomData.description : '',
    availability: isEdit ? conferenceRoomData.availability : true,
    imagePath: isEdit ? conferenceRoomData.imagePath : null
  };

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            let imageUrl = values.imagePath;
            if (uploadedFile) {
              const formData = new FormData();
              formData.append('file', uploadedFile);
              const uploadResponse = await uploadImage(formData);
              console.log('Upload Response:', uploadResponse);

              imageUrl = uploadResponse.data.url;
            }

            const dataToSend = {
              ...values,
              imagePath: imageUrl
            };

            console.log('Data to Send:', dataToSend);

            if (isEdit) {
              await updateConferenceRoom(conferenceRoomData.id, dataToSend);
            } else {
              await createConferenceRoom(dataToSend);
              navigate('/conference-rooms'); // Update the route if necessary
            }
            setSubmitting(false);
          } catch (err) {
            setErrors({ submit: err?.response?.data?.message || 'An error occurred' });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <h3 className='text-bluish-grey font-medium uppercase mt-3'>
              Conference Room Details
            </h3>
            <div className='border-b border-hazel-green py-1' />
            <div className='grid grid-cols-2 gap-x-6 gap-y-3 mt-4'>
              <div className='col-span-1'>
                <CustomInput
                  type='text'
                  name='name'
                  label='Room Name'
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.name && touched.name}
                  errorMessage={errors.name}
                />
              </div>
              <div className='col-span-1'>
                <CustomInput
                  type='text'
                  name='location'
                  label='Location'
                  value={values.location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.location && touched.location}
                  errorMessage={errors.location}
                />
              </div>
              <div className='col-span-1'>
                <CustomInput
                  type='number'
                  name='capacity'
                  label='Capacity'
                  value={values.capacity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.capacity && touched.capacity}
                  errorMessage={errors.capacity}
                />
              </div>
              <div className='col-span-1'>
                <CustomInput
                  type='number'
                  name='pricePerHour'
                  label='Price per Hour'
                  value={values.pricePerHour}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.pricePerHour && touched.pricePerHour}
                  errorMessage={errors.pricePerHour}
                />
              </div>
              <div className='col-span-2'>
                <CustomInput
                  type='text'
                  name='description'
                  label='Description'
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  multiline={true}
                  showError={errors.description && touched.description}
                  errorMessage={errors.description}
                />
              </div>
              <div className='col-span-2'>
                <p className='text-bluish-grey my-3'>Image</p>
                <div>
                  <input
                    type='file'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <div className='flex flex-wrap gap-x-4'>
                    <div
                      className='flex items-center justify-center bg-mercury w-full h-32 rounded-md cursor-pointer'
                      onClick={() => fileInputRef.current.click()}
                    >
                      <IconUpload className='text-hazel-green' size={30} />
                    </div>
                    {previewUrl && (
                      <div className='relative mr-2 mb-2'>
                        <img
                          src={previewUrl}
                          alt='Conference Room'
                          className='w-full h-32 rounded-md'
                        />
                        <button
                          type='button'
                          className='absolute top-0 right-0 border-sage rounded-tr-md rounded-bl-md bg-white p-1'
                          onClick={() => {
                            setUploadedFile(null);
                            setPreviewUrl(null);
                            setFieldValue('imagePath', null);
                          }}
                        >
                          <div className='rounded-full w-4 h-4 border border-sage flex items-center justify-center text-sage'>
                            <IconX />
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-span-2'>
                <label className='block text-sm font-medium text-gray-700'>Availability</label>
                <div className='flex items-center mt-1'>
                  <input
                    type='checkbox'
                    name='availability'
                    checked={values.availability}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className='form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out'
                  />
                  <span className='ml-2 text-sm text-gray-600'>Available</span>
                </div>
                {errors.availability && touched.availability && (
                  <p className='text-error mt-1'>{errors.availability}</p>
                )}
              </div>
            </div>
            {errors.submit && <p className='text-error mt-4'>{errors.submit}</p>}
            <div className='flex justify-end items-center w-full mt-7 text-sm'>
              <CancelButton onCancel={() => navigate('/conference-rooms')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/5'
                title={isEdit ? 'Update Conference Room' : 'Save'}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ConferenceRoomForm;

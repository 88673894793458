import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLarge } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../components/loader';
import PageTitle from '../../components/page-title';
import UserForm from './UserForm';
import { getUserById } from '../../api/users';

function EditUser() {
  const params = useParams();
  const userId = params.id;
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const response = await getUserById(userId);
      setUser(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faUserLarge}
            className='text-hazel-green mr-2'
          />
        }
        title='Edit User'
      />
      <UserForm
        isEdit
        user={{
          id: user.id,
          personalNumber: user.personalNumber,
          name: user.name,
          surname: user.surname,
          email: user.email,
          jobRole: user.jobRole,
          wage: user.wage || '',
          roleId: user.role?.id || '',
          address: user?.address || '',
          phoneNumber: user?.phoneNumber || '',
          secondaryPhoneNumber: user?.secondaryPhoneNumber || ''
        }}
      />
    </div>
  );
}

export default EditUser;

// import React from 'react';
// import { IconCalendarMonth } from '@tabler/icons-react';
// import PageTitle from '../../../components/page-title';

// import ConferenceRoomReservationForm from './ConferenceRoomReservationForm';

// function AddConferenceRoomReservationForm() {
//   return (
//     <div className='p-10'>
//       <PageTitle
//         icon={<IconCalendarMonth className='text-hazel-green mr-2' size={22} />}
//         title='Add new Conference Room reservation'
//       />
//       <ConferenceRoomReservationForm isEdit={false}/>
//     </div>
//   );
// }

// export default AddConferenceRoomReservationForm;

import React, { useState } from 'react';
import { IconCalendarMonth } from '@tabler/icons-react';
import PageTitle from '../../../components/page-title';
import TabNavigation from './TabNavigation.tsx';
import ConferenceRoomReservationForm from './ConferenceRoomReservationForm';

function AddConferenceRoomReservationForm() {
  const [activeTab, setActiveTab] = useState('conference');

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconCalendarMonth className='text-hazel-green mr-2' size={22} />}
        title={`Add new ${activeTab === 'conference' ? 'Conference Room' : 'Room'} reservation`}
      />
      <TabNavigation activeTab={activeTab} onTabChange={setActiveTab} />
      <ConferenceRoomReservationForm isEdit={false} formType={activeTab} />
    </div>
  );
}

export default AddConferenceRoomReservationForm;
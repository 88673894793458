import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { getMenu, createOrUpdateMenu, addDishToMenu, updateDish } from '../../api/menus';
import { getDishSections } from '../../api/dishSection';
import { getRestaurants } from '../../api/restaurant';
import Loading from '../../components/loader';
import CustomInput from '../../components/custom-input';
import SelectInput from '../../components/select-input';
import { SubmitButton, CancelButton } from '../../components/buttons';

const DishSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  price: Yup.number().required('Required'),
  type: Yup.string().required('Required'),
  isAvailable: Yup.boolean().required('Required'),
  owner: Yup.string().optional(),
  imageUrl: Yup.string().optional(),
  description: Yup.string().optional(),
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Menu name is required*'),
  restaurantId: Yup.string().required('Restaurant is required*'),
  dishSections: Yup.array().notRequired()
});

function MenuForm() {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(null);
  const [loading, setLoading] = useState(true);
  const [restaurants, setRestaurants] = useState([]);
  const [dishSections, setDishSections] = useState([]);

  const fetchRestaurants = useCallback(async () => {
    try {
      const response = await getRestaurants();
      setRestaurants(response.data);
    } catch (error) {
      console.error('Failed to fetch restaurants:', error);
    }
  }, []);

  const fetchDishSections = useCallback(async () => {
    try {
      const response = await getDishSections();
      setDishSections(response.data);
    } catch (error) {
      console.error('Failed to fetch dish sections:', error);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        await fetchRestaurants();
        await fetchDishSections();
        const response = await getMenu();
        setMenu({
          ...response.data,
          dishSections: response.data.dishSections || [],
        });
      } catch (error) {
        console.error('Failed to fetch menu:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [fetchRestaurants, fetchDishSections]);

  const initialValues = {
    name: '',
    restaurantId: '',
    dishSections: []
  };

  const handleSubmit = async (values,{ setSubmitting }) => {
    setSubmitting(true);

    try {
      console.log('Form values:', values); 
      const menuData = { name: values.name, restaurantId: values.restaurantId };
      const createdMenu = await createOrUpdateMenu(menuData);
      for (const dishSection of values.dishSections) {
        if (dishSection.id) {
          await updateDish(dishSection.id, dishSection);
        } else {
          await addDishToMenu(createdMenu.id, dishSection);
        }
      }
      navigate('/menu');
    } catch (error) {
      console.error('Failed to save menu or dish section:', error);
    }finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!menu) {
    return <div>No menu data found</div>;
  }

  return (
    <div className='border bg-seashell border-gray-200 rounded-xl shadow-lg mt-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className='px-7 py-5'>
            <h1 className='text-hazel-green text-xl font-semibold mb-5'>Manage Menu</h1>
            <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
              <CustomInput
                type='text'
                name='name'
                label='Menu Name'
                value={values.name}
                onChange={(e) => setFieldValue('name', e.target.value)}
              />
              <SelectInput
                value={values.restaurantId}
                label='Restaurant'
                options={restaurants.map(restaurant => ({
                  id: restaurant.id,
                  title: restaurant.name
                }))}
                onChange={(e) => setFieldValue('restaurantId', e.target.value)}
                onBlur={(e) => setFieldValue('restaurantId', e.target.value)}
                showError={false}
              />
            </div>

            <h2 className='text-lg text-hazel-green font-semibold mt-6 mb-2'>Dish Sections</h2>
            {values.dishSections.map((dishSection, index) => (
              <div key={index} className='text-hazel-green border border-gray-200 rounded-lg p-4 mb-3'>
                <h3 className='font-semibold mb-2'>Dish Section {index + 1}</h3>
                <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
                  <SelectInput
                    value={dishSection.id}
                    label='Dish Section'
                    options={dishSections.map(d => ({
                      id: d.id,
                      title: d.name
                    }))}
                    onChange={(e) => {
                      const selectedDishSection = dishSections.find(d => d.id === e.target.value);
                      setFieldValue(`dishSections.${index}`, { ...selectedDishSection, quantity: dishSection.quantity || 1 });
                    }}
                    onBlur={(e) => {
                      const selectedDishSection = dishSections.find(d => d.id === e.target.value);
                      setFieldValue(`dishSections.${index}`, { ...selectedDishSection, quantity: dishSection.quantity || 1 });
                    }}
                    showError={false}
                  />
                  <CustomInput
                    type='number'
                    name={`dishSections.${index}.quantity`}
                    label='Quantity'
                    value={dishSection.quantity}
                    onChange={(e) => setFieldValue(`dishSections.${index}.quantity`, e.target.value)}
                    onBlur={(e) => setFieldValue(`dishSections.${index}.quantity`, e.target.value)}
                    showError={false}
                  />
                </div>
                <button
                  type='button'
                  className='text-red-500 mt-3'
                  onClick={() => setFieldValue('dishSections', values.dishSections.filter((_, i) => i !== index))}
                >
                  Remove Dish Section
                </button>
              </div>
            ))}

            <button
              type='button'
              className='text-watermelon mt-4'
              onClick={() =>
                setFieldValue('dishSections', [
                  ...values.dishSections,
                  { name: '', price: '', type: '', isAvailable: false, owner: '', imageUrl: '', description: '' },
                ])
              }
            >
              Add Dish Section
            </button>

            <div className='flex justify-end items-center w-full mt-4 text-sm'>
              <CancelButton onCancel={() => navigate('/menus/menu-page')} />
              <SubmitButton isSubmitting={false} width='w-1/2 md:w-1/6' title='Submit' />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default MenuForm;

import React, { useState, useEffect } from 'react';
import { lowStockItems as getLowStockItems } from '../api/stockState';
import { AlertTriangle, ChevronLeft, ChevronRight } from 'lucide-react';

const LowStockTable = () => {
  const [lowStockItems, setLowStockItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  useEffect(() => {
    fetchLowStockItems();
  }, []);

  const fetchLowStockItems = async () => {
    try {
      const response = await getLowStockItems();
      console.log("RESPONSE OF LOW STOCK ITEMS", response.data);
      setLowStockItems(response.data);
    } catch (error) {
      console.error('Error fetching low stock items:', error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = lowStockItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(lowStockItems.length / itemsPerPage);


  const getRowBackgroundColor = (index) => {
    if (currentPage === 1) {  // Apply only on the first page
      if (index === 0) return 'bg-red-100';
      if (index === 1) return 'bg-red-50';
      if (index === 2) return 'bg-red-25';
    }
    return 'bg-white';  // Default color for other pages or rows
  };

//this is mad said ot
  return (
    <div className="bg-seashell rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-semibold text-hazel-green">Low Stock Items</h2>
        <div className="flex items-center text-salmon-pink">
          <AlertTriangle className="w-5 h-5 mr-2" />
          <span className="text-sm font-medium">Attention Required</span>
        </div>
      </div>
      {/*nothgmankessense */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg overflow-hidden">
          <thead>
            <tr className="bg-grey-goose text-bluish-grey">
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Item Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Stock Quantity</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Reorder Level</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Difference</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-mercury">
            {currentItems.map((item, index) => (
              <tr 
                key={item.id} 
                // className="hover:bg-tealish-blue transition-colors duration-150 noclass-600 ui"
                className={`${getRowBackgroundColor(index)} hover:bg-tealish-blue transition-colors duration-150`}
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-hazel-green">{item.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{item.stockQuantity}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-bluish-grey">{item.reorderLevel}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <span className={`px-2 py-1 rounded-full ${
                    item.reorderLevel - item.stockQuantity > 5 ? 'bg-dawn-pink text-watermelon' : 'bg-tealish-blue text-sage'
                  }`}>
                    {item.reorderLevel - item.stockQuantity}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-6 flex justify-center items-center">
        <button
          onClick={() => paginate(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
          className="mr-2 p-1 rounded-full bg-grey-goose text-bluish-grey hover:bg-sage hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>
        <span className="text-sm text-bluish-grey">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => paginate(Math.min(totalPages, currentPage + 1))}
          disabled={currentPage === totalPages}
          className="ml-2 p-1 rounded-full bg-grey-goose text-bluish-grey hover:bg-sage hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default LowStockTable;
import React, { useState, useEffect } from 'react';
import { PlusIcon, CalendarIcon, ArrowUpIcon, ArrowDownIcon, HistoryIcon } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "../../../../../components/shadCN/Card.tsx";
import { Input } from "../../../../../components/shadCN/Input.tsx";
import { Button } from '../../../../../components/shadCN/Button.tsx';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../../components/shadCN/Select.tsx";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../../components/shadCN/Tabs.tsx";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { getDailyCashRegisters, createDailyCashRegister } from "../../../../../api/accounting"; // Import your API methods

import { getDailyCashRegisterById } from '../../../../../api/accounting';

import { BanknoteIcon } from 'lucide-react';

const DailyCashLog = () => {
  const [date, setDate] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [transactionType, setTransactionType] = useState('Hyrje');
  const [dailyCashRegisters, setDailyCashRegisters] = useState([]);
  
  useEffect(() => {
    const fetchDailyCashRegisters = async () => {
      try {
        const response = await getDailyCashRegisters();
        setDailyCashRegisters(response.data);
      } catch (error) {
        console.error("Error fetching daily cash registers:", error);
      }
    };
    fetchDailyCashRegisters();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newTransaction = { date, amount: parseFloat(amount), description, transactionType };
    try {
      await createDailyCashRegister(newTransaction);
      setDailyCashRegisters([...dailyCashRegisters, newTransaction]);
      setDate('');
      setAmount('');
      setDescription('');
    } catch (error) {
      console.error("Error creating daily cash register:", error);
    }
  };

  const totalIncome = dailyCashRegisters.reduce((sum, transaction) => 
    transaction.transactionType === 'Hyrje' ? sum + transaction.amount : sum, 0
  );
  
  const totalExpenses = dailyCashRegisters.reduce((sum, transaction) => 
    transaction.transactionType === 'Dalje' ? sum + transaction.amount : sum, 0
  );
  
  const balance = totalIncome - totalExpenses;

  return (
    <div className="p-6 bg-gradient-to-br from-gray-50 to-gray-100 min-h-screen">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center space-x-3">
            <BanknoteIcon className="h-8 w-8 text-hazel-green" />
            <h1 className="text-3xl font-bold bg-gradient-to-r from-hazel-green to-teal-800 bg-clip-text text-transparent">
              ARKA DITORE
            </h1>
          </div>
        </div>
        
        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <Card className="bg-white/50 backdrop-blur-sm border border-teal-100 shadow-sm hover:shadow-md transition-shadow">
            <CardHeader className="pb-2">
              <CardTitle className="text-teal-700 flex items-center text-lg">
                <ArrowUpIcon className="mr-2 h-5 w-5 text-teal-500" />
                Total Income
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-3xl font-bold text-teal-600">{totalIncome.toFixed(2)} €</p>
            </CardContent>
          </Card>

          <Card className="bg-white/50 backdrop-blur-sm border border-red-100 shadow-sm hover:shadow-md transition-shadow">
            <CardHeader className="pb-2">
              <CardTitle className="text-red-700 flex items-center text-lg">
                <ArrowDownIcon className="mr-2 h-5 w-5 text-red-500" />
                Total Expenses
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-3xl font-bold text-red-600">{totalExpenses.toFixed(2)} €</p>
            </CardContent>
          </Card>

          <Card className="bg-white/50 backdrop-blur-sm border border-emerald-100 shadow-sm hover:shadow-md transition-shadow">
            <CardHeader className="pb-2">
              <CardTitle className="text-emerald-700 flex items-center text-lg">
                <HistoryIcon className="mr-2 h-5 w-5 text-emerald-500" />
                Balance
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-3xl font-bold text-emerald-600">{balance.toFixed(2)} €</p>
            </CardContent>
          </Card>
        </div>

        {/* Transaction Form */}
        <Card className="mb-8 bg-white/70 backdrop-blur-sm border border-gray-200 shadow-lg">
          <CardHeader>
            <CardTitle className="text-gray-700 flex items-center">
              <PlusIcon className="mr-2 h-5 w-5 text-teal-600" />
              New Transaction
            </CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">Type</label>
                  <Select onValueChange={setTransactionType} defaultValue={transactionType}>
                    <SelectTrigger className="w-full border-gray-200 focus:ring-teal-500 focus:border-teal-500">
                      <SelectValue placeholder="Select type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Hyrje">Hyrje</SelectItem>
                      <SelectItem value="Dalje">Dalje</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">Date</label>
                  <div className="relative">
                    <Input
                      type="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      className="w-full border-gray-200 focus:ring-teal-500 focus:border-teal-500"
                    />
                    <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">Amount</label>
                  <div className="relative">
                    <Input
                      type="number"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      className="w-full border-gray-200 focus:ring-teal-500 focus:border-teal-500"
                      placeholder="0.00"
                    />
                    <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">€</span>
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">Notes</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={3}
                  className="w-full rounded-lg border-gray-200 focus:ring-teal-500 focus:border-teal-500 resize-none"
                  placeholder="Enter transaction details..."
                />
              </div>

              <div className="flex justify-end">
                <Button
                  type="submit"
                  className="bg-teal-600 hover:bg-teal-700 text-white px-6"
                >
                  Save Transaction
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>

        {/* Transactions Table */}
        <Card className="bg-white/70 backdrop-blur-sm border border-gray-200 shadow-lg">
          <CardHeader>
            <CardTitle className="text-gray-700">Recent Transactions</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-gray-200">
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">#</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Transaction</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {dailyCashRegisters.map((transaction, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          transaction.transactionType === 'Hyrje' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                        }`}>
                          {transaction.transactionType}
                        </span>
                        <span className="ml-2">{transaction.description}</span>
                      </td>
                      <td className={`px-6 py-4 whitespace-nowrap text-sm font-medium ${
                        transaction.transactionType === 'Hyrje' ? 'text-green-600' : 'text-red-600'
                      }`}>
                        {transaction.amount.toFixed(2)} €
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default DailyCashLog;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import DishForm from './DishForm';

function EditDish() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faUtensils}
            className='text-hazel-green mr-2'
            size={22}
          />
        }
        title='Dish'
      />
      {/* TODO: Add dish data */}
      <DishForm />
    </div>
  );
}

export default EditDish;

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ["./src/**/*.{js,jsx}"],
  
  // darkMode: 'class',

  theme: {
    extend: {
      animation: {
        "spin-around": "spin-around calc(var(--speed) * 2) infinite linear",
        slide: "slide var(--speed) ease-in-out infinite alternate",
      },
      keyframes: {
        "spin-around": {
          "0%": {
            transform: "translateZ(0) rotate(0)",
          },
          "15%, 35%": {
            transform: "translateZ(0) rotate(90deg)",
          },
          "65%, 85%": {
            transform: "translateZ(0) rotate(270deg)",
          },
          "100%": {
            transform: "translateZ(0) rotate(360deg)",
          },
        },
        slide: {
          to: {
            transform: "translate(calc(100cqw - 100%), 0)",
          },
        },
      },
      colors: {
        sage: "#90a68c",
        "bluish-grey": "#7c8b93",
        "grey-goose": "#c9d3cc",
        "hazel-green": "#61805b",
        "tealish-blue": "#f1f2f7",
        seashell: "#F0F3EF",
        mercury: "#E3E7E7",
        heather: "#B0C3CC",
        "salmon-pink": "#FF7777",
        "dawn-pink": "#FAE6E6",
        watermelon: "#EC5252",
        error: "#f30000",
        silver: "#bdbdbd",
      },
      backgroundImage: {
        loginBgImage: "url('../assets/login-background-image.png')",
      },
    },
  },
  plugins: [],
};
import api from "./customAPI";

export const getDishSections = () => api.get('/api/dishSection');
export const getDishSectionById = (id) => api.get(`/api/restaurant/dish-sections/${id}`);
export const updateDishSection = (id, values) => api.patch(`/api/restaurant/dish-sections/${id}`, values);

export const createOrUpdateDishSection = (dishSectionData) => {
  return api.post('/api/dishSection/create', dishSectionData);
};

export const deleteDishSection = (id) => {
  return api.delete(`/api/dishSection/${id}`);
};



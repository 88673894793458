import React from 'react';
import { createTheme, ThemeProvider, TextField } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#90a68c'
    }
  }
});

const CustomInput = ({
  label,
  type,
  name,
  value,
  onChange,
  onBlur,
  multiline,
  disabled,
  showError,
  errorMessage
}) => {
  return (
    <ThemeProvider theme={theme}>
      <div className='mb-3'>
        <TextField
          id='outlined-basic'
          label={label}
          variant='outlined'
          className='border boder-hazel-green rounded-md w-full'
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          multiline={multiline}
          disabled={disabled}
          rows={4}
          sx={{
            color: 'primary.main'
          }}
        />
        {showError && <p className='text-sm text-error'>{errorMessage}</p>}
      </div>
    </ThemeProvider>
  );
};

export default CustomInput;

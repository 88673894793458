import React, { useState, useEffect, useCallback } from 'react';
import { AddButton } from '../../../../../components/buttons';
import CustomTable from '../../../../../components/table-components/table';
import Loading from '../../../../../components/loader';
import { getPayrolls, deletePayroll } from '../../../../../api/finances';
import { getJobTitles } from '../../../../../api/jobTitles';

import { processPayrollPayment } from '../../../../../api/finances';

import { Link } from 'react-router-dom';
import { IconPlus, IconFileTime } from '@tabler/icons-react';

function PayrollList() {
  const [payrollsList, setPayrollsList] = useState([]);
  const [jobTitles, setJobTitles] = useState([]); // State to hold job titles
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const getPayrollsList = useCallback(async () => {
    try {
      const response = await getPayrolls(rowsPerPage, currentPage + 1, searchText);
      console.log('API Response:', response.data);
      
      if (Array.isArray(response.data)) {
        // setPayrollsList(response.data);
        // setTotalCount(response.data.length);
        setPayrollsList(response.data.payrolls || []);
        setTotalCount(response.data.count || 0);
        
      } else {
        setPayrollsList(response.data.payrolls || []);
        setTotalCount(response.data.count || 0);
      }
      
      console.log('Updated Payrolls List:', response.data);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, searchText]);

  const getJobTitlesList = useCallback(async () => {
    try {
      const response = await getJobTitles();
      setJobTitles(response.data); 
      console.log('Job Titles:', response.data);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    }
  }, []);


  useEffect(() => {
    setLoading(true);
    getPayrollsList().finally(() => setLoading(false));
    getJobTitlesList();
  }, [getPayrollsList, getJobTitlesList]);
  
  useEffect(() => {
    setLoading(true);
    getPayrollsList().finally(() => setLoading(false));
  }, [currentPage, rowsPerPage, searchText]);
  
  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await deletePayroll(id);
      setPayrollsList((prevState) => prevState.filter((item) => item.id !== id));
      setTotalCount(totalCount - 1);
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  const getJobTitleById = (id) => {
    const jobTitle = jobTitles.find(title => title.id === id);
    return jobTitle ? jobTitle.title : 'N/A'; // Return 'N/A' if no title found
  };

  const handlePayrollPayment = async (payrollId, employeeId, amountPaid) => {
    try {
      await processPayrollPayment(payrollId, { employeeId, amountPaid });
      alert('Payroll payment processed successfully!');
      // Optionally refresh the payroll list
      getPayrollsList();
    } catch (error) {
      alert('Failed to process payroll payment.');
    }
  };
  

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className='p-10'>
      
    <div className='flex justify-between items-center mb-8'>
      <h1 className='text-2xl font-bold text-hazel-green'>Payroll</h1>
      <div className='flex gap-x-3'>
          
          <Link to='/finances/payrolls/add-payroll' className='flex items-center text-white rounded-md bg-sage px-4 py-2.5'>
            <IconPlus className='mr-2' size={20} />
            New Payroll
          </Link>
          <Link to='/finances/payrolls/payroll-tracking' className='flex items-center text-white rounded-md bg-sage px-4 py-2.5'>
            <IconFileTime className='mr-2' size={24} />
            Payments history
          </Link>
      </div>
    </div>
      <CustomTable
        rows={payrollsList}
        columns={[
          {
            title: 'Employee Name',
            renderValue: (item) => `${item.employee?.name} ${item.employee?.surname}`
          },
          {
            title: 'Job Title', // New column for Job Title
            renderValue: (item) => getJobTitleById(item.employee?.jobTitleId) // Use helper function to get job title
          },
          {
            title: 'Salary',
            renderValue: (item) => `$${item.salary}`
          },
          {
            title: 'Bonus',
            renderValue: (item) => `$${item.bonus}`
          },
          {
            title: 'Deductions',
            renderValue: (item) => `$${item.deductions}`
          },
          {
            title: 'Total Pay',
            renderValue: (item) => `$${item.totalPay}`
          },
          {
            title: 'Pay Period',
            renderValue: (item) => item.payPeriod
          },
          {
            title: 'Status',
            renderValue: (item) => item.status 
          },

          {
            title: 'Actions',
            renderValue: (item) => (
              <>
                {/* Only show "Pay" button if the employee is eligible for payment */}
                {
                    
                    item.status === 'UNPAID' && item.isEligibleForPayment ? (
                        <button 
                          onClick={() => handlePayrollPayment(item.id, item.employeeId, item.totalPay)}
                          className='px-4 py-1 text-center text-white rounded-full bg-hazel-green'
                        >
                            Pay
                        </button>
                    ) : (
                        <button 
                          disabled
                          className='px-4 py-1 text-center text-white rounded-full bg-sage'

                        >
                            Paid
                        </button>
                    )
                }

              </>
            )
          }
          
          
          
        ]}
        entity='payrolls'
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={true}
        editLink='/finances/payrolls'
        onDelete={handleDelete}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </div>
  );
}

export default PayrollList;
import api from './api'; // Assuming the base Axios instance is in `api.js`


// Get stock items with stockQuantity and reorderLevel difference below a given threshold
export const getStockItemsBelowReorderLevel = (threshold) => api.get(`/api/stock-state/below-reorder-level/${threshold}`);

// Get stock item quantity and reorder level by ID
export const getStockItemQuantityAndReorderById = (id) => api.get(`/api/stock-state/quantity-reorder/${id}`);

// Check if the stock item is approaching its reorder level (difference below a given threshold)
export const checkApproachingReorderLevel = (id, threshold) => api.get(`/api/stock-state/check-approaching-reorder/${id}/${threshold}`);

// Get stock items where stockQuantity equals reorderLevel (zero difference)
export const getItemsWithZeroDifference = () => api.get('/api/stock-state/zero-difference');

// Get stock items where stockQuantity is zero
export const getItemsWithZeroQuantity = () => api.get('/api/stock-state/zero-quantity');

export const lowStockItems = () => api.get('/api/stock-state/low-stock-items');
import React from "react";
import { testimonial } from "../../../components/data/Data";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {

} from '@tabler/icons-react';

function Sliders() {
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  // };
  const settings = {
    dots: true, // Show dots for navigation
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 3000,
    arrows: false, 
  };

//   return (
//     <>
//       <div
//         className="container-xxl testimonial my-5 py-5 bg-dark wow zoomIn"
//         data-wow-delay="0.1s"
//       >
//         <div className="container">
//           <div className="owl-carousel testimonial-carousel py-5">
//             <Slider {...settings}>
//               {testimonial.map((item, key) => (
//                 <div
//                   key={key}
//                   className="testimonial-item position-relative bg-white rounded overflow-hidden"
//                 >
//                   <p>{item.description}</p>
//                   <div className="d-flex align-items-center">
//                     <img
//                       className="img-fluid flex-shrink-0 rounded"
//                       src={item.img}
//                       style={{ width: "45px", height: "45px" }}
//                     />
//                     <div className="ps-3">
//                       <h6 className="fw-bold mb-1">{item.name}</h6>
//                       <small>{item.profession}</small>
//                     </div>
//                   </div>
//                   {item.icon}
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
return (
  <div className="bg-gray-900 py-12 px-6 rounded-lg">
    <div className="container mx-auto px-6">
      <Slider {...settings}>
        {testimonial.map((item, index) => (
          <div
            key={index}
            className="bg-white rounded-lg overflow-hidden shadow-lg p-6 transition-transform transform hover:scale-105"
          >
            <div className="relative">
              <div className="absolute top-4 right-4 text-gray-600">
                {item.icon}
              </div>
              {/* <img
                className="w-12 h-12 rounded-full object-cover mb-4 mx-auto"
                src={item.img}
                alt={item.name}
              /> */}
              <img
                  src='/images/profile-placeholder.png'
                  alt='logo'
                  // className='w-10 h-10'
                  className="w-12 h-12 rounded-full object-cover mb-4 mx-auto"
                />
              
              <p className="text-gray-800 mb-4 text-center">
                {item.description}
              </p>
              <div className="text-center">
                <h6 className="text-lg font-semibold text-gray-900">
                  {item.name}
                </h6>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  </div>
);
}

export default Sliders;
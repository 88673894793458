import React, { useState, useEffect, useCallback } from 'react';
import { AddButton } from '../../../../../components/buttons'; // Adjust path as needed
import CustomTable from '../../../../../components/table-components/table'; // Adjust path as needed
import Loading from '../../../../../components/loader'; // Adjust path as needed
import { getTransactions, deleteTransaction } from '../../../../../api/accounting'; // API methods

function TransactionAccountingList() {
    const [transactionList, setTransactionList] = useState([]);
    const [fetchingInitialData, setFetchingInitialData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
  
    const getTransactionList = useCallback(async () => {
      try {
        setLoading(true);
        const response = await getTransactions(
          rowsPerPage,
          currentPage + 1,
          searchText
        );
        console.log(response);
        setTransactionList(response.data?.transactions || []);
        setTotalCount(response.data?.count || 0);
      } catch (err) {
        setErrorMessage(err?.response?.data?.message || err.message);
      } finally {
        setFetchingInitialData(false);
        setLoading(false);
      }
    }, [currentPage, rowsPerPage, searchText]);
    
    useEffect(() => {
      getTransactionList();
    }, [getTransactionList]);
  
    const handleDelete = async (id) => {
      try {
        setLoading(true);
        await deleteTransaction(id);
        setTransactionList((prevState) => prevState.filter((transaction) => transaction.id !== id));
        setTotalCount(totalCount - 1);
      } catch (err) {
        return err;
      } finally {
        setLoading(false);
      }
    };
  
    if (fetchingInitialData) {
      return <Loading />;
    }
  
    if (!fetchingInitialData && errorMessage) {
      return (
        <div className='text-center'>
          <h1>{errorMessage}</h1>
        </div>
      );
    }
  
    return (
      <div className='p-10'>
        <AddButton entity='transactions' link='/accounting/transactions/' title='New Transaction' />
  
        <CustomTable
          rows={transactionList || []}
          columns={[
            {
              title: 'Transaction Date',
              renderValue: (item) => new Date(item.transactionDate).toLocaleDateString(),
            },
            {
              title: 'Amount',
              renderValue: (item) => `$${item.amount.toFixed(2)}`,
            },
            {
              title: 'Payment Type',
              renderValue: (item) => item.paymentType,
            },
            {
              title: 'Description',
              renderValue: (item) => item.description || 'N/A',
            },
            {
              title: 'Is Paid?',
              renderValue: (item) => (item.isPaid ? 'Yes' : 'No'),
            },
            {
              title: 'Transaction Type',
              renderValue: (item) => item.transactionType || 'N/A',
            },
            {
              title: 'Client',
              renderValue: (item) => item.client?.name || 'N/A',
            },
            {
              title: 'Supplier',
              renderValue: (item) => item.supplier?.name || 'N/A',
            },
            {
              title: 'Due Date',  // New field
              renderValue: (item) => item.dueDate ? new Date(item.dueDate).toLocaleDateString() : 'N/A',
            },
            {
              title: 'Transaction Number',  // New field
              renderValue: (item) => item.transactionNumber || 'N/A',
            },
            {
              title: 'Accepted By',  // New field
              renderValue: (item) => item.acceptedBy || 'N/A',
            },
          ]}
          entity='transactions'
          setSearchText={setSearchText}
          loading={loading}
          showMoreActions={true}
          editLink='/transactions'
          onDelete={handleDelete}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={totalCount}
        />
      </div>
    );
  }

export default TransactionAccountingList;

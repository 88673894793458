import React, { useState } from 'react';
import { MapPin } from 'lucide-react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import store from '../../store';
import { setUser } from '../../store/slices/user-slice';

import {
  IconLogout,
  IconSettings,
  IconChevronDown
} from '@tabler/icons-react';
import SocialIcons from '../../views/hotelInterface/common/SocialIcons';
import { useSelector } from 'react-redux';

const Navbar = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);
  
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const logout = () => {
    localStorage.clear();
    store.dispatch(setUser(null));
    navigate('/');
  };

  const handleMouseEnter = (itemId) => {
    setActiveDropdown(itemId);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  // Toggle the dropdown on click
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <nav className="bg-white shadow-sm px-6 py-4">
      <div className="flex items-center justify-between">
        {/* Logo Section */}
        <div className="flex items-center space-x-2">
          <MapPin className="text-salmon-pink" size={24} />
          <span className="text-2xl font-semibold">
            <span className="text-watermelon">Lake</span>
            <span className="text-bluish-grey">Side</span>
          </span>
        </div>

        {/* Links Section */}
        <div className="flex items-center space-x-8">
          <CustomNavLink to="/hotelInterface" label="Dashboard" />
          <CustomNavLink to="/hotelInterface/pms-management" label="PMS" />
          <CustomNavLink to="/hotelInterface/roomsList" label="Manage Properties" />
          <CustomNavLink to="/hotelInterface/todoList" label="Room Operations" />
          <CustomNavLink to="/hotelInterface/damages" label="Damages" />

          {/* Dropdown for Bookings Related Links */}
          <div className="relative">
            <button
              onClick={toggleDropdown}
              className="text-bluish-grey hover:text-watermelon transition-colors"
            >
              Bookings
            </button>
            {dropdownOpen && (
              <div className="absolute mt-2 bg-white shadow-lg rounded-md w-48 py-2 z-10">
                <DropdownLink to="/hotelInterface/checkedIn" label="Checked In" />
                <DropdownLink to="/hotelInterface/checkedOut" label="Checked Out" />
                <DropdownLink to="/hotelInterface/reservationsList" label="Reservations" />
                <DropdownLink to="/hotelInterface/cancelledBookings" label="Cancelled Bookings" />
              </div>
            )}
          </div>
        </div>

        {/* User Profile Section */}
        {/* <div className="flex items-center space-x-3">
          <div className="w-10 h-10 bg-hazel-green rounded-full flex items-center justify-center">
            <span className="text-white font-medium">EG</span>
          </div>
          <span className="text-bluish-grey">Hi Enis Gjocaj</span>
        </div>
      </div> */}
            <div className="ml-4">

                  <div className='flex justify-center items-center'>
                    <img
                      src='/images/profile-placeholder.png'
                      alt='logo'
                      className='w-10 h-10'
                    />
                    <div className='flex'>
                      <p className='capitalize mx-3 whitespace-nowrap'>
                        {user.name} {user.surname}
                      </p>
                      <IconChevronDown
                        className='text-heather cursor-pointer'
                        onClick={() => setShowDropDown(!showDropDown)}
                      />
                    </div>
                  </div>
                  <div
                    className={`${
                      showDropDown ? 'z-50 flex flex-col' : 'hidden'
                    } z-50 text-black absolute top-14 ml-auto right-20 justify-end p-4 w-60 bg-white border border-light-gray rounded-md shadow-lg`}
                  >
                    <Link
                      to='/account-settings'
                      className='flex p-3 border-b border-light-gray'
                      onClick={() => setShowDropDown(false)}
                    >
                      <IconSettings className='mr-2' size={22} />
                      Account Settings
                    </Link>
                    <p className='flex p-3 cursor-pointer' onClick={logout}>
                      <IconLogout className='mr-2' size={22} />
                      Logout
                    </p>
                    </div>
                  </div>
                </div>

    </nav>

    
  );
};

// Custom NavLink component to manage active styles
const CustomNavLink = ({ to, label }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `${isActive ? 'text-watermelon' : 'text-bluish-grey'} hover:text-watermelon transition-colors`
    }
  >
    {label}
  </NavLink>
);

// Dropdown links for bookings
const DropdownLink = ({ to, label }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `${isActive ? 'bg-gray-100' : ''} block px-4 py-2 text-bluish-grey hover:text-watermelon`
    }
  >
    {label}
  </NavLink>
);

export default Navbar;

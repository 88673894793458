import React, { useState, useEffect } from 'react';
import { CalendarIcon, PlusIcon, MinusIcon, DollarSign, AppleIcon } from 'lucide-react';

import { createTransaction } from '../../../../../api/accounting';

import { productTypes, units } from '../../../../../constants/entities.js';

import { MinusCircle } from 'lucide-react';
import { Separator } from '../../../../../components/shadCN/Seperator.tsx';

import DateInput from '../../../../../components/date-input.js';

import SimpleDateTimePicker from '../../../../../components/SimpleDateTimePicker.js';

import api from "../../../../../api/api.js";

import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table,
  TableHeader,
  TableBody,
  TableFooter, 
  TableHead,
  TableRow,
  TableCell,
  TableCaption, } from '../../../../../components/shadCN/Table.tsx';

  import { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent } from '../../../../../components/shadCN/Card.tsx';
  import { Input } from '../../../../../components/shadCN/Input.tsx';

  import { Button } from '../../../../../components/shadCN/Button.tsx';
import { Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
  SelectScrollUpButton,
  SelectScrollDownButton  } from '../../../../../components/shadCN/Select.tsx';

  import { getClients, getSuppliers } from '../../../../../api/accounting';
import { fromPairs } from 'lodash';
import axios from 'axios';

  const PaymentType = {
    CASH: 'CASH',
    BANK: 'BANK',
    INCOME: 'INCOME',
    EXPENSE: 'EXPENSE',
    PAYROLL: 'PAYROLL',
  };
  
  const TransactionType = {
    PAYMENT: 'PAYMENT',
    PURCHASE: 'PURCHASE',
  };

export default function InvoiceTable() {
  const [lineItems, setLineItems] = useState([{ id: 1 }]);
  
  const [invoiceData, setInvoiceData] = useState({
    clientName: '',
    invoiceNumber: '',
    date: null,
    reference: '',
    paymentDue: null,
    acceptedBy: '',
    total: 0,
    discount: 0,
    vat: 0,
    description: '',
  });
  const [paymentType, setPaymentType] = useState(PaymentType.CASH); // Default to CASH
  const [transactionType, setTransactionType] = useState(TransactionType.PAYMENT); // Default to PAYMENT
  const [clients, setClients] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedEntityId, setSelectedEntityId] = useState('');

  const addLineItem = () => {
    setLineItems([...lineItems, { id: lineItems.length + 1 }]);
  };

  const removeLineItem = (id) => {
    setLineItems(lineItems.filter(item => item.id !== id));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData({ ...invoiceData, [name]: value });
  };

  // Update this function to handle date changes from SimpleDateTimePicker
  const handleDateChange = (newDate, field) => {
    setInvoiceData({ ...invoiceData, [field]: newDate });
  };

  const handleSubmit = async () => {
    try {

      // const transactionValues = {
      //   amount: invoiceData.total,
      //   paymentType: paymentType,
      //   transactionType: transactionType,
      //   clientId: transactionType === TransactionType.PAYMENT ? selectedEntityId : undefined,
      //   supplierId: transactionType === TransactionType.PURCHASE ? selectedEntityId : undefined,
      //   dueDate: new Date(invoiceData.paymentDue).toISOString(),
      //   transactionNumber: transactionNumber, // Use the fetched transaction number
      //   acceptedBy: invoiceData.acceptedBy,
      //   description: invoiceData.description,
      //   TransactionProducts: lineItems.map(item => ({
      //     item: item.item,
      //     type: item.type || 'FOOD',  // Make sure type is always provided
      //     unit: item.unit,
      //     discount: item.discount || 0,
      //     quantity: item.quantity || 1,
      //     unitPrice: item.unitPrice || 0,
      //     soldPrice: item.soldPrice || 0,
      //   })),
      // };

      const transactionValues = {
        amount: invoiceData.total,
        paymentType: paymentType,
        transactionType: transactionType,
        clientId: transactionType === TransactionType.PAYMENT ? selectedEntityId : undefined,
        supplierId: transactionType === TransactionType.PURCHASE ? selectedEntityId : undefined,
        dueDate: new Date(invoiceData.paymentDue).toISOString(),
        acceptedBy: invoiceData.acceptedBy,
        description: invoiceData.description,
        TransactionProducts: lineItems.map(item => ({
          item: item.item,
          type: item.type || 'FOOD',
          unit: item.unit,
          discount: item.discount || 0,
          quantity: item.quantity || 1,
          unitPrice: item.unitPrice || 0,
          soldPrice: item.soldPrice || 0,
        })),
      };

      console.log("transaction data",transactionValues);

      const createResponse = await createTransaction(transactionValues);

      toast.success('Transaction created successfully!');
      console.log('Transaction created successfully:', createResponse.data);
    } catch (error) {
      toast.error('Failed to create transaction');
      console.error('Error creating transaction:', error);
    }
  };
const calculateTotals = () => {
  const total = lineItems.reduce((acc, item) => {
    const itemTotal = (parseFloat(item.unitPrice) || 0) * (parseFloat(item.quantity) || 1) - (parseFloat(item.discount) || 0);
    return acc + itemTotal;
  }, 0);

  const discountTotal = lineItems.reduce((acc, item) => acc + (parseFloat(item.discount) || 0), 0);
  const vat = total * 0.15; // Assuming a VAT rate of 15%
  const grandTotal = total + vat; // Grand total calculation

  setInvoiceData(prevData => ({
    ...prevData,
    total: parseFloat(total.toFixed(2)), 
    discount: parseFloat(discountTotal.toFixed(2)), 
    vat: parseFloat(vat.toFixed(2)),
    grandTotal: parseFloat(grandTotal.toFixed(2))
  }));
};


const updateLineItem = (index, field, value) => {
  console.log(`Updating item at index ${index}, field: ${field}, value: ${value}`);
  setLineItems(prevItems => {
      const newItems = [...prevItems];
      newItems[index] = {
          ...newItems[index],
          [field]: ['unitPrice', 'quantity', 'discount'].includes(field) 
              ? parseFloat(value) || 0 
              : value
      };
      console.log('Updated lineItems:', newItems);
      return newItems;
  });
  calculateTotals(); // Call to recalculate totals after line item update
};

  useEffect(() => {
    console.log('Current lineItems:', lineItems);
  }, [lineItems]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientsResponse = await getClients();
        const suppliersResponse = await getSuppliers();
  
        console.log(clientsResponse.data); // Check for valid clients
        console.log(suppliersResponse.data); // Check for valid suppliers
  
        setClients(clientsResponse.data);
        setSuppliers(suppliersResponse.data);
      } catch (error) {
        console.error('Error fetching clients or suppliers:', error);
      }
    };
    fetchData();
  }, []);

  // Reset selectedEntityId whenever the transactionType changes
  useEffect(() => {
    setSelectedEntityId('');
  }, [transactionType]);

  return (
    <div className='p-8 bg-seashell min-h-screen'>
      <div className='bg-white rounded-lg shadow-lg p-8'>
        <div className=' flex justify-between items-center mb-8'>
          <h1 className='text-3xl font-bold text-hazel-green'>Invoice Registration</h1>
          <Link to="/accounting/transaction-accounting-list" className='flex items-center text-white rounded-md bg-sage px-5 py-3 shadow-md hover:bg-hazel-green transition'>
            <PlusIcon className='mr-2' size={20} />
            Transactions List
          </Link>
        </div>

        
  
        <div className='bg-seashell border border-gray-200 rounded-xl shadow-lg p-6'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mb-8'>
          <div>
              <label className='block text-sm font-medium text-gray-600 mb-2'>Invoice to</label>
              <Select onValueChange={setSelectedEntityId}>
                <SelectTrigger className="w-full rounded-md border border-gray-300 shadow-md hover:shadow-lg transition-all duration-200">
                  <SelectValue placeholder={transactionType === TransactionType.PAYMENT ? "Select client" : "Select supplier"} />
                </SelectTrigger>
                <SelectContent className='bg-white'>
                  {transactionType === TransactionType.PAYMENT ? (
                    clients.length > 0 ? clients.map(client => (
                      <SelectItem key={client.id} value={client.id}>
                        {client.name}
                      </SelectItem>
                    )) : <SelectItem disabled>No clients available</SelectItem>
                  ) : (
                    suppliers.length > 0 ? suppliers.map(supplier => (
                      <SelectItem key={supplier.id} value={supplier.id}>
                        {supplier.businessName}
                      </SelectItem>
                    )) : <SelectItem disabled>No suppliers available</SelectItem>
                  )}
                </SelectContent>
              </Select>
            </div>
  
            <div>
              <label className='block text-sm font-medium text-gray-600 mb-2'>Invoice No</label>
              <input 
                type='text' 
                readOnly
                value="Will be generated automatically"
                className='text-xs mt-1 block w-full shadow-sm border border-gray-300 rounded-md py-2 px-6 bg-gray-50 text-gray-500' 
              />
            </div>
  
            <div>
              <label className='block text-sm font-medium text-gray-600 mb-2'>Date</label>

                <SimpleDateTimePicker 
                  invoiceData={{ date: invoiceData.date }} 
                  handleChange={(e) => handleDateChange(e.target.value, 'date')}
                />
              </div>
          </div>
  
          <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mb-8'>
            <div>
              <label className='block text-sm font-medium text-gray-600 mb-2'>Payment Due</label>

              <SimpleDateTimePicker 
                invoiceData={{ date: invoiceData.paymentDue }} 
                handleChange={(e) => handleDateChange(e.target.value, 'paymentDue')}
              />
            </div>
  
            <div>
              <label className='block text-sm font-medium text-gray-600 mb-2'>Accepted By</label>
              <input 
                type='text'
                name='acceptedBy'
                value={invoiceData.acceptedBy}
                onChange={handleChange}
                className='mt-1 focus:ring-sage focus:border-sage block w-full shadow-sm border border-gray-300 rounded-md py-2 px-3 placeholder-gray-400'
                placeholder='Enter the name of the person who accepted the invoice'
              />
            </div>
          </div>
  
          {/* New Select Fields for Payment Type and Transaction Type */}
          <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mb-8'>
            <div>
              <label className='block text-sm font-medium text-gray-600 mb-2'>Payment Type</label>
              <Select onValueChange={setPaymentType}>
                <SelectTrigger className="w-full rounded-md border border-gray-300 shadow-md hover:shadow-lg transition-all duration-200">
                  <SelectValue placeholder="Select payment type" />
                </SelectTrigger>
                <SelectContent className='bg-white'>
                  <SelectItem value="CASH">Cash</SelectItem>
                  <SelectItem value="BANK">Bank Transfer</SelectItem>
                  <SelectItem value="CHECK">Check</SelectItem>
                  <SelectItem value="INCOME">Income</SelectItem>
                  <SelectItem value="EXPENSE">Expense</SelectItem>
                  <SelectItem value="PAYROLL">Payroll</SelectItem>
                </SelectContent>
              </Select>
            </div>
  
            <div>
              <label className='block text-sm font-medium text-gray-600 mb-2'>Transaction Type</label>
              <Select onValueChange={setTransactionType}>
                <SelectTrigger className="w-full rounded-md border border-gray-300 shadow-md hover:shadow-lg transition-all duration-200">
                  <SelectValue placeholder="Select transaction type" />
                </SelectTrigger>
                <SelectContent className='bg-white'>
                  <SelectItem value={TransactionType.PAYMENT}>Payment</SelectItem>
                  <SelectItem value={TransactionType.PURCHASE}>Purchase</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          {/* New Description Field */}
          <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mb-8'>
            <div>
              <label className='block text-sm font-medium text-gray-600 mb-2'>Description</label>
              <textarea 
                name='description'
                value={invoiceData.description}
                onChange={handleChange}
                className='mt-1 focus:ring-sage focus:border-sage block w-full shadow-sm border border-gray-300 rounded-md py-2 px-3 placeholder-gray-400 resize-none' 
                placeholder='Enter additional details about the transaction'
                style={{ height: '100px' }} // Adjust height as needed
              />
            </div>
          </div>

  
          <div className='border border-gray-200 rounded-lg shadow-lg p-4 mb-8 bg-white'>
            <h2 className='text-lg font-semibold mb-4'>Line Items</h2>
            <Table>
  <TableHeader>
    <TableRow>
      <TableHead className="w-[150px] bg-gray-200 text-gray-700 font-semibold">Item</TableHead>
      <TableHead className="bg-gray-200 text-gray-700 font-semibold">Type</TableHead>
      <TableHead className="bg-gray-200 text-gray-700 font-semibold">Unit</TableHead>
      <TableHead className="bg-gray-200 text-gray-700 font-semibold">Unit Price</TableHead>
      <TableHead className="bg-gray-200 text-gray-700 font-semibold">Quantity</TableHead>
      <TableHead className="bg-gray-200 text-gray-700 font-semibold">Discount</TableHead>
      <TableHead className="bg-gray-200 text-gray-700 font-semibold">Sold Price</TableHead>
      <TableHead className="w-[50px] bg-gray-200 text-gray-700 font-semibold">Remove</TableHead>
    </TableRow>
  </TableHeader>
  <TableBody>
    {lineItems.map((item, index) => (
      <TableRow key={item.id} className="border-b border-gray-300 even:bg-gray-100">
        <TableCell className="p-3">
          <Input
            type="text"
            value={item.item} // Controlled input value
            onChange={(e) => updateLineItem(index, 'item', e.target.value)} // Ensure this is working correctly
            className="h-12 text-xs px-1"
            placeholder="Enter item name"
          />
        </TableCell>
        <TableCell className="p-3">
                  <Select 
                    value={item.type || ''} 
                    onValueChange={(value) => updateLineItem(index, 'type', value)}
                  >
                    <SelectTrigger className="h-12 text-sm border rounded-md p-2 hover:bg-gray-100 transition duration-200">
                      <SelectValue placeholder="Select type" />
                    </SelectTrigger>
                    <SelectContent className="bg-white rounded-md shadow-lg">
                      <SelectGroup>
                        {productTypes.map((type) => (
                          <SelectItem key={type} value={type} className="px-4 py-2 hover:bg-gray-200 transition duration-200">
                            {type}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
        </TableCell>
        <TableCell className="p-3">
        <Select 
                    value={item.unit || ''} 
                    onValueChange={(value) => updateLineItem(index, 'unit', value)}
                  >
                    <SelectTrigger className="h-12 text-sm border rounded-md p-2 hover:bg-gray-100 transition duration-200">
                      <SelectValue placeholder="Select unit" />
                    </SelectTrigger>
                    <SelectContent className="bg-white rounded-md shadow-lg">
                      <SelectGroup>
                        {units.map((unit) => (
                          <SelectItem key={unit} value={unit} className="px-4 py-2 hover:bg-gray-200 transition duration-200">
                            {unit}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>

        </TableCell>
        <TableCell className="p-3 text-right">
          <Input
            type="number"
            value={item.unitPrice}
            onChange={(e) => updateLineItem(index, 'unitPrice', e.target.value)}
            className="h-8 text-sm"
            placeholder="0.00"
          />
        </TableCell>
        <TableCell className="p-3 text-right">
          <Input
            type="number"
            value={item.quantity}
            onChange={(e) => updateLineItem(index, 'quantity', e.target.value)}
            className="h-8 text-sm"
            placeholder="1"
          />
        </TableCell>
        <TableCell className="p-3 text-right">
          <Input
            type="number"
            value={item.discount}
            onChange={(e) => updateLineItem(index, 'discount', e.target.value)}
            className="h-8 text-sm"
            placeholder="0.00"
          />
        </TableCell>
        <TableCell className="p-3 text-right">
          <Input
            type="number"
            readOnly
            value={((item.unitPrice || 0) * (item.quantity || 1)) - (item.discount || 0)}
            className="h-8 text-sm bg-muted"
          />
        </TableCell>
        <TableCell className="p-3">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => removeLineItem(item.id)}
            className="h-8 w-8"
          >
            <MinusCircle className="h-4 w-4 text-destructive" />
          </Button>
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
</Table>
            <button 
              type='button' 
              onClick={addLineItem} 
              className='mt-4 inline-flex items-center rounded-md bg-sage text-white px-4 py-2 shadow-md hover:bg-hazel-green transition'>
              <PlusIcon className='mr-2' />
              Add Item
            </button>
          </div>
<Card className="w-full max-w-xl mx-auto bg-white">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-center text-primary">Invoice Summary</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex justify-between items-center text-sm">
          <span className="text-muted-foreground">Total:</span>
          <span className="font-medium">{invoiceData.total}$</span>
        </div>
        <div className="flex justify-between items-center text-sm">
          <span className="text-muted-foreground">Discount:</span>
          <span className="font-medium text-destructive">{invoiceData.discount}$</span>
        </div>
        <div className="flex justify-between items-center text-sm">
          <span className="text-muted-foreground">VAT (15%):</span>
          <span className="font-medium">{invoiceData.vat}$</span>
        </div>
        <Separator className="my-2" />
        <div className="flex justify-between items-center text-lg font-bold">
          <span className="text-primary">Grand Total:</span>
          <span className="text-primary">{invoiceData.grandTotal}$</span>
        </div>
      </CardContent>
    </Card>
        </div>
        
  
        <div className='mt-8 flex justify-end'>
          <button onClick={handleSubmit} className='flex items-center text-white rounded-md bg-sage px-5 py-3 shadow-md hover:bg-hazel-green transition'>
            <DollarSign className='mr-2' size={20} /> Submit Invoice
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { CleaningScheduleTable } from '../../components/hotelInterfaceComponents/roomsList/CleaningSchelduleTable.tsx';
import { RoomsTable } from '../../components/hotelInterfaceComponents/roomsList/RoomsTable.tsx';
import Navbar from '../../components/hotelInterfaceComponents/Navbar.tsx';
import { getPaginatedRooms } from '../../api/rooms';
import { toast } from 'react-toastify';
import Loading from '../../components/loader.js';

function RoomsListPage() {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hoveredRoom, setHoveredRoom] = useState(null);

  useEffect(() => {
    fetchRooms();
  }, []);

  const fetchRooms = async () => {
    try {
      setLoading(true);
      const response = await getPaginatedRooms(1, 100);
      console.log(response)
      if (response.data?.rooms) {
        setRooms(response.data.rooms);
      }
    } catch (error) {
      console.error('Error fetching rooms:', error);
      toast.error('Failed to fetch rooms');
    } finally {
      setLoading(false);
    }
  };

  if(loading) {
    return <Loading />
  }

  // Transform rooms data for cleaning schedule
  const generateCleaningSchedule = () => {
    return rooms.sort((a, b) => a.number - b.number).map(room => ({
      roomNumber: room.number,
      monday: '-',
      tuesday: '-',
      wednesday: room.status === 'VACANT' ? '✓' : '-',
      thursday: '-',
      friday: '-',
      saturday: '-',
      sunday: '-',
    }));
  };

  return (
    <div className="min-h-screen bg-tealish-blue">
      <Navbar />
      <div className="max-w-[1400px] mx-auto space-y-8 my-8 p-8">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-semibold text-hazel-green">
            Housekeeping Dashboard
          </h1>
        </div>
        <div className="space-y-8">
          <section>
            <h2 className="text-xl font-semibold text-sage mb-4">
              Apartment Cleaning Schedule
            </h2>
            <CleaningScheduleTable schedule={generateCleaningSchedule()} />
          </section>

          <section>
            <h2 className="text-xl font-semibold text-sage mb-4">
              Room Status Overview
            </h2>
            <RoomsTable 
              rooms={rooms}
              loading={loading}
              hoveredRoom={hoveredRoom}
              setHoveredRoom={setHoveredRoom}
              onStatusChange={fetchRooms}
            />
          </section>
        </div>
      </div>
    </div>
  );
}

export default RoomsListPage;
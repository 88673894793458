// AddCategory.js
import React from 'react';
import { IconBox } from '@tabler/icons-react'; // Adjust the icon as needed
import CategoryForm from './CategoryForm';
import PageTitle from '../../../components/page-title';

function AddCategory() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconBox className='text-hazel-green mr-2' size={22} />}
        title='Add New Category'
      />
      <CategoryForm />
    </div>
  );
}

export default AddCategory;

import React from 'react';
import { LucideIcon } from 'lucide-react';

import { useNavigate } from 'react-router-dom';

interface AccountingCardProps {
  title: string;
  description: string;
  icon: LucideIcon;
  bgColor?: string;
  accentColor: string;
  borderColor: string;
  link: string;
}

const AccountingCard = ({ 
  title, 
  description, 
  icon: Icon, 
  bgColor = 'bg-white', 
  accentColor,
  borderColor,
  link,
}: AccountingCardProps) => {


  const navigate = useNavigate();

  const handleLinkClick = () => {
    navigate(link); // Use the link prop to navigate
  }

  return (
    <div className={`relative overflow-hidden ${bgColor} rounded-2xl p-6 shadow-lg transition-all duration-300 
      hover:shadow-2xl hover:-translate-y-1 group cursor-pointer border ${borderColor}`}>
      
      {/* Decorative Background Accent */}
      <div className={`absolute inset-0 pointer-events-none opacity-10 group-hover:opacity-20 transition-opacity ${accentColor} blur-lg`} />

      {/* Icon and Badge */}
      <div className="flex items-start justify-between">
        <div className={`p-3 rounded-lg ${accentColor} bg-opacity-20 group-hover:bg-opacity-30 transition-colors shadow-sm`}>
          <Icon className={`w-8 h-8 ${accentColor}`} strokeWidth={1.5} />
        </div>
        <span className="text-xs font-medium px-3 py-1 rounded-full bg-gray-100 text-gray-600 shadow-sm">
          Accounting Book
        </span>
      </div>

      {/* Title and Description */}
      <div className="mt-5">
        <h3 className={`text-xl font-semibold ${accentColor} group-hover:text-opacity-80 transition-colors`}>
          {title}
        </h3>
        <p className="mt-2 text-sm leading-relaxed text-gray-700">
          {description}
        </p>
      </div>

      {/* Call to Action */}
      <div className="mt-6 flex items-center text-sm font-medium">
        <div onClick={handleLinkClick}  className={`${accentColor} group-hover:translate-x-1 transition-transform duration-200`}>
          View Details →
        </div>
      </div>
    </div>
  );
};

export default AccountingCard;

import { Task, Tag } from './types';

export const tags: Tag[] = [
  { id: '1', name: 'Urgent', color: 'watermelon' },
  { id: '2', name: 'Maintenance', color: 'bluish-grey' },
  { id: '3', name: 'Cleaning', color: 'hazel-green' },
  { id: '4', name: 'Reception', color: 'sage' },
];

export const tasks: Task[] = [
  {
    id: '1',
    title: 'Clean Room 301',
    description: 'Deep cleaning required after guest checkout',
    deadline: '2024-02-01T10:00:00',
    tag: 'Cleaning',
    assignedBy: 'John Manager',
    assignedTo: 'Alice Cleaner',
    status: 'open',
    emailNotification: 'immediate',
    createdAt: '2024-01-28T09:00:00',
  },
  {
    id: '2',
    title: 'Fix AC in Room 205',
    description: 'AC not cooling properly',
    deadline: '2024-02-02T14:00:00',
    tag: 'Maintenance',
    assignedBy: 'John Manager',
    assignedTo: 'Bob Technician',
    status: 'open',
    emailNotification: 'daily',
    createdAt: '2024-01-28T10:30:00',
  },
  {
    id: '3',
    title: 'Guest Check-in Room 401',
    description: 'VIP guest arriving',
    deadline: '2024-01-29T15:00:00',
    tag: 'Reception',
    assignedBy: 'Sarah Supervisor',
    assignedTo: 'Emma Receptionist',
    status: 'closed',
    emailNotification: 'none',
    createdAt: '2024-01-27T11:00:00',
  },
];
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import  EnvironmentForm  from './EnvironmentForm';

function AddEnvironment() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faBuilding}
            className='text-hazel-green mr-2'
          />
        }
        title='Create a new environment'
      />
      <EnvironmentForm
        isEdit={false}
        environment={{
          name: '',
          restaurantId: ''
        }}
      />
    </div>
  );
}

export default AddEnvironment;

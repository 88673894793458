import React, { useState, useEffect } from "react";
import useAuth from '../../hooks/useAuth';
import { useSelector } from 'react-redux';
import PermissionCheck from '../../utils/PermissionCheck';
import { FunctionButton } from "../restaurant/FunctionButtons";
import TableGrid from './TableGrid';
import Loading from '../../components/loader';
import { AddButton } from '../../components/buttons';
import { getAllEnvironments } from '../../api/restaurantInterface';
import { SlidingBtnTabEnvironments } from "./SlidingBtnTabEnviroments";



import { useHeaderContext } from "../../components/layout/RestaurantLayout";

export default function TablePage() {
    const [loading, setLoading] = useState(false);
    const [activeButtonReservatinon, setActiveButtonReservatinon] = useState("buttonReservation1");
    const [environments, setEnvironments] = useState([]);
    const [activeEnvironment, setActiveEnvironment] = useState(null);
    const { setHeaderElements } = useHeaderContext();

    useEffect(() => {
        const fetchEnvironments = async () => {
            setLoading(true);
            try {
                const response = await getAllEnvironments();
                setEnvironments(response.data);
                if (response.data.length > 0) {
                    setActiveEnvironment(response.data[0]); // Set the first environment as active initially
                }
            } catch (err) {
                console.error('Failed to fetch environments:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchEnvironments();
    }, []);

    useEffect(() => {
        setHeaderElements(
            <div className="flex flex-col w-full items-start justify-center gap-10 my-6 p-6">
                <div className="w-full flex flex-row justify-between items-center">

                    <div className="flex flex-row gap-4">
                        <AddButton
                            link='/restaurant/add-environment'
                            title='New environment'
                        />

                        <AddButton
                            link='/restaurant/add-table'
                            title='New table'
                        />
                    </div>
                </div>
            </div>
        );

        // Clean up the header elements when the component is unmounted
        return () => setHeaderElements(null);
    }, [setHeaderElements, activeButtonReservatinon]);

    const handleTabChange = (environmentId) => {
        const selectedEnvironment = environments.find(env => env.id === environmentId);
        setActiveEnvironment(selectedEnvironment);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="flex flex-col h-full">
            <div className="flex flex-col justify-center items-center gap-2 sm:gap-4 p-2 sm:p-4">
                {environments.length > 0 && (
                    <div className="max-w-sm overflow-x-auto">
                        <SlidingBtnTabEnvironments
                            environments={environments}
                            onTabChange={handleTabChange}
                        />
                    </div>
                )}
            </div>

            <div className="flex-1 min-h-0">
                {activeButtonReservatinon === "buttonReservation1" ? (
                    activeEnvironment ? (
                        <TableGrid environment={activeEnvironment} />
                    ) : (
                        <p className="p-4 text-center">No environment selected.</p>
                    )
                ) : (
                    <div className="p-4">List of reservation here</div>
                )}
            </div>
        </div>
    );
}



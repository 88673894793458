import React from "react";

import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell } from "../../../../../components/shadCN/Table.tsx";

import { Calendar } from "lucide-react";

const CashFlow = ({ data, date }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-5xl mx-auto my-4">
      {/* Header Section */}
      <div className="flex flex-row items-center justify-between mb-6 bg-sage rounded-lg px-8 py-4 text-white font-bold cursor-pointer transition-transform duration-200 transform hover:scale-105">
        <div className="flex flex-col gap-4">
          <h2 className="text-2xl font-semibold">Pasqyra e rrjedhës së parasë</h2>
          <div className="py-2 flex flex-row items-center justify-between text-lg bg-hazel-green text-white font-semibold mt-1 px-4 py-1 rounded-lg">
            <span>{date}</span>
            <Calendar className="text-white" size={28} />
          </div>
        </div>
        <img src="/images/lake-side-logo.png" alt="Lake Side Logo" className="w-32" />
      </div>

      {/* Table Section */}
      <Table className="min-w-full text-sm border border-gray-200">
        <TableHeader>
          <TableRow className="bg-gray-200">
            <TableHead className="text-left px-4 py-2">Kategoritë</TableHead>
            <TableHead className="text-right px-4 py-2">Tremujori paraprak</TableHead>
            <TableHead className="text-right px-4 py-2">Tremujori vijues</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {data.map((section, sectionIndex) => (
            <React.Fragment key={sectionIndex}>
              {/* Category Heading */}
              <TableRow>
                <TableCell colSpan={3} className="font-bold bg-gray-100 text-gray-800 px-4 py-2">
                  {section.category}
                </TableCell>
              </TableRow>

              {/* Individual Items */}
              {section.items.map((item, itemIndex) => (
                <TableRow key={itemIndex} className={itemIndex % 2 === 0 ? "bg-gray-50" : ""}>
                  <TableCell className="text-gray-700 px-4 py-2">{item.name}</TableCell>
                  <TableCell className="text-right text-gray-700 px-4 py-2">{item.prev}</TableCell>
                  <TableCell className="text-right bg-seashell text-gray-700 px-4 py-2">{item.current}</TableCell>
                </TableRow>
              ))}

              {/* Section Total */}
              <TableRow className="bg-hazel-green font-bold">
                <TableCell className="text-gray-100 px-4 py-2">{section.total.name}</TableCell>
                <TableCell className="text-right text-gray-100 px-4 py-2">{section.total.prev}</TableCell>
                <TableCell className="text-right bg-sage text-gray-800 px-4 py-2">{section.total.current}</TableCell>
              </TableRow>
            </React.Fragment>
          ))}

          {/* Final Total for Cash Flow */}
          <TableRow className="bg-hazel-green font-bold">
            <TableCell className="text-gray-100 px-4 py-2">Gjithsej Rrjedha e Parasë</TableCell>
            <TableCell className="text-right text-gray-100 px-4 py-2">400,000</TableCell>
            <TableCell className="text-right bg-sage text-gray-800 px-4 py-2">420,000</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default CashFlow;

import React , { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import OrderForm from './OrderForm';
import DishSidebar from '../../components/DishSidebar';


function AddOrderForm() {

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [selectedDishes, setSelectedDishes] = useState([]);

  const handleDishDrop = (dish) => {
    setSelectedDishes((prev) => [...prev, dish]);
    setSidebarVisible(false);
  };

  return (
    <div className='px-10 py-2'>
      <PageTitle
        
        icon={
          <FontAwesomeIcon
            icon={faBuilding}
            className='text-hazel-green mr-2'
          />
        }
        title='Create a new order'
      />
      <OrderForm
        // isEdit={false}
        isEdit={false}
        selectedDishes={selectedDishes}
        setSelectedDishes={setSelectedDishes}
        handleDishDrop={handleDishDrop}
      />
    </div>
  );
}

export default AddOrderForm;

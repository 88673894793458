import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../shadCN/Table.tsx';

interface Room {
  id: string;
  number: number;
  status: string;
}

type CleaningScheduleTableProps = {
  schedule: Array<{
    roomNumber: number;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
  }>;
};

export function CleaningScheduleTable({ schedule }: CleaningScheduleTableProps) {
  return (
    <div className="rounded-lg border border-grey-goose bg-white">
      <Table>
        <TableHeader className="bg-tealish-blue">
          <TableRow>
            <TableHead className="text-sage">Room</TableHead>
            <TableHead className="text-sage">Monday</TableHead>
            <TableHead className="text-sage">Tuesday</TableHead>
            <TableHead className="text-sage bg-hazel-green text-white">Wednesday</TableHead>
            <TableHead className="text-sage">Thursday</TableHead>
            <TableHead className="text-sage">Friday</TableHead>
            <TableHead className="text-sage">Saturday</TableHead>
            <TableHead className="text-sage">Sunday</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {schedule.map((row, i) => (
            <TableRow key={row.roomNumber} className={i % 2 === 0 ? 'bg-seashell' : 'bg-white'}>
              <TableCell className="font-medium">{row.roomNumber}</TableCell>
              <TableCell>{row.monday}</TableCell>
              <TableCell>{row.tuesday}</TableCell>
              <TableCell className="bg-grey-goose bg-opacity-10">{row.wednesday}</TableCell>
              <TableCell>{row.thursday}</TableCell>
              <TableCell>{row.friday}</TableCell>
              <TableCell>{row.saturday}</TableCell>
              <TableCell>{row.sunday}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
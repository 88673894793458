import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWifi,
  faUtensils,
  faBed,
  faWaterLadder
} from '@fortawesome/free-solid-svg-icons';

export const icons = [
  {
    title: 'wifi',
    icon: <FontAwesomeIcon icon={faWifi} className='text-hazel-green mr-2' />
  },
  {
    title: 'bed',
    icon: <FontAwesomeIcon icon={faBed} className='text-hazel-green mr-2' />
  },
  {
    title: 'breakfast',
    icon: (
      <FontAwesomeIcon icon={faUtensils} className='text-hazel-green mr-2' />
    )
  },
  {
    title: 'jakuzi',
    icon: (
      <FontAwesomeIcon icon={faWaterLadder} className='text-hazel-green mr-2' />
    )
  }
];

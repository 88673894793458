// import { useState } from 'react';
// import React from 'react';
// import { 
//   Plus, 
//   Search, 
//   Edit, 
//   Send, 
//   Share, 
//   Printer, 
//   MoreHorizontal,
//   PaperclipIcon,
//   MessageSquare,
//   X,
//   ChevronDown
// } from 'lucide-react';

// import { Button } from '../../../../components/shadCN/Button.tsx';
// import { InvoiceSidebar } from '../../../../components/invoice/InvoiceSidebar.tsx';
// import { InvoiceDocument } from '../../../../components/invoice/InvoiceDocument.tsx';

// import { useParams } from 'react-router-dom';

// const invoices = [
//     { id: 'INV-001', customer: 'Acme Corp', date: '2024-03-15', amount: '€2,500.00', status: 'Paid' },
//     { id: 'INV-002', customer: 'TechStart Inc', date: '2024-03-14', amount: '€1,800.00', status: 'Pending' },
//     { id: 'INV-003', customer: 'Global Solutions', date: '2024-03-13', amount: '€3,200.00', status: 'Overdue' },
//     { id: 'INV-004', customer: 'Digital Dynamics', date: '2024-03-12', amount: '€950.00', status: 'Paid' },
//   ];


// export default function InvoiceDetails() {
//   const [selectedInvoiceId, setSelectedInvoiceId] = useState('INV-000001');

//   const { invoiceId } = useParams();
//   const [selectedInvoice] = invoices.filter((invoice) => invoice.id === invoiceId);

//   return (
//     <div className="flex h-screen bg-seashell">
//       {/* Sidebar */}
//       <div className="w-96 border-r border-mercury bg-white">
//         <div className="p-4 border-b border-mercury">
//           <div className="flex items-center justify-between mb-4">
//             <div className="flex items-center gap-2">
//               <h2 className="text-lg font-semibold text-hazel-green">All Invoices</h2>
//               <Button variant="ghost" size="sm">
//                 <ChevronDown className="h-4 w-4 text-bluish-grey" />
//               </Button>
//             </div>
//             <div className="flex items-center gap-2">
//               <Button className="bg-hazel-green hover:bg-sage text-white">
//                 <Plus className="h-4 w-4 mr-2" />
//                 New
//               </Button>
//               <Button variant="ghost" size="sm">
//                 <MoreHorizontal className="h-4 w-4 text-bluish-grey" />
//               </Button>
//             </div>
//           </div>
//           <div className="relative">
//             <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" />
//             <input
//               type="text"
//               placeholder="Search invoices..."
//               className="w-full pl-10 pr-4 py-2 border border-mercury rounded-lg text-sm focus:outline-none focus:border-hazel-green"
//             />
//           </div>
//         </div>
//         <InvoiceSidebar 
//           selectedInvoiceId={selectedInvoiceId}
//           onInvoiceSelect={setSelectedInvoiceId}
//         />
//       </div>

//       {/* Main Content */}
//       <div className="flex-1 overflow-auto">
//         <div className="p-6">
//           <div className="flex items-center justify-between mb-6">
//             <h1 className="text-xl font-semibold text-hazel-green">{selectedInvoiceId}</h1>
//             <div className="flex items-center gap-3">
//               <Button variant="outline" size="sm" className="text-bluish-grey">
//                 <PaperclipIcon className="h-4 w-4 mr-2" />
//                 Attach Files
//               </Button>
//               <Button variant="outline" size="sm" className="text-bluish-grey">
//                 <MessageSquare className="h-4 w-4 mr-2" />
//                 Comments & History
//               </Button>
//               <Button variant="ghost" size="sm">
//                 <X className="h-4 w-4 text-bluish-grey" />
//               </Button>
//             </div>
//           </div>

//           <div className="bg-white rounded-lg shadow-sm border border-mercury p-6">
//             <div className="flex items-center justify-between mb-6">
//               <div className="flex items-center gap-2">
//                 <input type="checkbox" className="rounded border-mercury" />
//                 <span className="text-bluish-grey">sdsjkfsd,f</span>
//                 <span className="text-bluish-grey">€0,00</span>
//               </div>
//               <div className="flex items-center gap-3">
//                 <Button variant="outline" size="sm">
//                   <Edit className="h-4 w-4 mr-2" />
//                   Edit
//                 </Button>
//                 <Button variant="outline" size="sm">
//                   <Send className="h-4 w-4 mr-2" />
//                   Send
//                 </Button>
//                 <Button variant="outline" size="sm">
//                   <Share className="h-4 w-4 mr-2" />
//                   Share
//                 </Button>
//                 <Button variant="outline" size="sm">
//                   <Printer className="h-4 w-4 mr-2" />
//                   PDF/Print
//                 </Button>
//                 <Button variant="ghost" size="sm">
//                   <MoreHorizontal className="h-4 w-4 text-bluish-grey" />
//                 </Button>
//               </div>
//             </div>

//             <InvoiceDocument />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
import { useState } from 'react';
import React from 'react';
import {
  Plus,
  Search,
  Edit,
  Send,
  Share,
  Printer,
  MoreHorizontal,
  PaperclipIcon,
  MessageSquare,
  X,
  ChevronDown
} from 'lucide-react';

import { Button } from '../../../../components/shadCN/Button.tsx';
import { InvoiceSidebar } from '../../../../components/invoice/InvoiceSidebar.tsx';
import { InvoiceDocument } from '../../../../components/invoice/InvoiceDocument.tsx';
import { useParams } from 'react-router-dom';

const invoices = [
  { id: 'INV-001', customer: 'Acme Corp', date: '2024-03-15', amount: '€2,500.00', status: 'Paid' },
  { id: 'INV-002', customer: 'TechStart Inc', date: '2024-03-14', amount: '€1,800.00', status: 'Pending' },
  { id: 'INV-003', customer: 'Global Solutions', date: '2024-03-13', amount: '€3,200.00', status: 'Overdue' },
  { id: 'INV-004', customer: 'Digital Dynamics', date: '2024-03-12', amount: '€950.00', status: 'Paid' },
];

export default function InvoiceDetails() {
  const { invoiceId } = useParams();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>(invoiceId || invoices[0].id);

  const selectedInvoice = invoices.find((invoice) => invoice.id === selectedInvoiceId);

  return (
    <div className="flex h-screen bg-seashell">
      {/* Sidebar */}
      <div className="w-96 border-r border-mercury bg-white">
        <div className="p-4 border-b border-mercury">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <h2 className="text-lg font-semibold text-hazel-green">All Invoices</h2>
              <Button variant="ghost" size="sm">
                <ChevronDown className="h-4 w-4 text-bluish-grey" />
              </Button>
            </div>
            <div className="flex items-center gap-2">
              <Button className="bg-hazel-green hover:bg-sage text-white">
                <Plus className="h-4 w-4 mr-2" />
                New
              </Button>
              <Button variant="ghost" size="sm">
                <MoreHorizontal className="h-4 w-4 text-bluish-grey" />
              </Button>
            </div>
          </div>
          <div className="relative">
            <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" />
            <input
              type="text"
              placeholder="Search invoices..."
              className="w-full pl-10 pr-4 py-2 border border-mercury rounded-lg text-sm focus:outline-none focus:border-hazel-green"
            />
          </div>
        </div>
        <InvoiceSidebar
          selectedInvoiceId={selectedInvoiceId}
          onInvoiceSelect={setSelectedInvoiceId}
        />
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-auto">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-xl font-semibold text-hazel-green">{selectedInvoice?.id}</h1>
            <div className="flex items-center gap-3">
              <Button variant="outline" size="sm" className="text-bluish-grey">
                <PaperclipIcon className="h-4 w-4 mr-2" />
                Attach Files
              </Button>
              <Button variant="outline" size="sm" className="text-bluish-grey">
                <MessageSquare className="h-4 w-4 mr-2" />
                Comments & History
              </Button>
              <Button variant="ghost" size="sm">
                <X className="h-4 w-4 text-bluish-grey" />
              </Button>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-sm border border-mercury p-6">
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center gap-4">
                <input type="checkbox" className="rounded border-mercury" />
                <span className="text-bluish-grey font-medium">{selectedInvoice?.customer}</span>
                <span className="text-bluish-grey font-semibold">{selectedInvoice?.amount}</span>
              </div>
              <div className="flex items-center gap-3">
                <Button variant="outline" size="sm">
                  <Edit className="h-4 w-4 mr-2" />
                  Edit
                </Button>
                <Button variant="outline" size="sm">
                  <Send className="h-4 w-4 mr-2" />
                  Send
                </Button>
                <Button variant="outline" size="sm">
                  <Share className="h-4 w-4 mr-2" />
                  Share
                </Button>
                <Button variant="outline" size="sm">
                  <Printer className="h-4 w-4 mr-2" />
                  PDF/Print
                </Button>
                <Button variant="ghost" size="sm">
                  <MoreHorizontal className="h-4 w-4 text-bluish-grey" />
                </Button>
              </div>
            </div>

            {/* Render InvoiceDocument with selectedInvoice */}
            {selectedInvoice && <InvoiceDocument invoice={selectedInvoice} />}
          </div>
        </div>
      </div>
    </div>
  );
}

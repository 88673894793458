import React, { useState, useRef } from 'react';
import './table.css';
import Draggable from "react-draggable";
import { IconRepeat, IconTrash, IconLink, IconUnlink } from '@tabler/icons-react';
import { deleteTable } from '../../../api/restaurant';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';

const Table = ({ table, onDelete, onMerge, onUnmerge, isAssigned }) => {
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false); 
  const dragStartPosRef = useRef({ x: 0, y: 0 });
  const isMerged = table.mergedFromIds && JSON.parse(table.mergedFromIds).length > 1;

  const handleTableClick = (e) => {
    const tableWrapper = e.target.closest('#table-wrapper');
    const managementSection = e.target.closest('.table-management');
    if (tableWrapper && !managementSection) {
      navigate(`/orders/new-order/${table.id}`);
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    if (isDeleting) return;
    setIsDeleting(true);
    onDelete(table.id);
    setIsDeleting(false);
  };

  const renderChairs = () => {
    const chairs = [];
    const capacity = table.capacity || 6;
    const sideCapacity = Math.floor(capacity / 2);

    for (let i = 0; i < capacity; i++) {
      const position = i < sideCapacity ? 'left' : 'right';
      const size = i === 0 || i === capacity - 1 ? 'chair-lg' : 'chair';
      chairs.push(
        <div
          key={i}
          className={`chair ${size} ${position} ${table.isAvailable ? 'available' : 'occupied opacity-70'}`}
        ></div>
      );
    }
    return chairs;
  };

  const renderPlates = () => {
    return [...Array(table.capacity)].map((_, i) => (
      <div key={i} className="plate">
        <div className="knife"></div>
        <div className="fork"></div>
      </div>
    ));
  };

  const onStart = (e, data) => {
    dragStartPosRef.current = { x: data.x, y: data.y };
    setIsDragging(false);
  };

  const onDrag = (e, data) => {
    const dx = data.x - dragStartPosRef.current.x;
    const dy = data.y - dragStartPosRef.current.y;
    const distance = Math.sqrt(dx * dx + dy * dy);

    if (distance > 5) {
      setIsDragging(true);
    }
  };

  const onStop = (e, data) => {
    if (isDragging) {
      setTimeout(() => onMerge(table, data), 50);
    } else {
      handleTableClick(e);
    }
    setIsDragging(false);
  };

  return (
    <Draggable onStart={onStart} onDrag={onDrag} onStop={onStop}>
      <div className={`table-container relative ${isDragging ? 'dragging' : ''} ${isMerged ? 'merged-table' : ''}`} style={{ padding: '0.5rem' }}>
        
        {/* Flex container for dynamic positioning */}
        <div className="flex flex-col items-center scale-90">
          {/* Responsive Badge container positioned at the top */}
          {isMerged && (
            <div className="mb-2 flex items-center gap-2 p-1 bg-blue-100 text-blue-800 text-xs sm:text-sm rounded-full shadow-md">
              <IconLink size={16} className="text-blue-500" />
              <span>Merged</span>
              <button 
                className="bg-red-200 text-red-600 rounded-full px-1 sm:px-2 py-0.5 text-xs ml-2 flex items-center gap-1" 
                onClick={(e) => {
                  e.stopPropagation();
                  onUnmerge(table.id);
                }}
              >
                <IconUnlink size={14} /> Unmerge
              </button>
            </div>
          )}
          
          <div
            id="table-wrapper"
            data-table-id={table.id}
            className={`scale-90 transform ${table.isStaffTable ? 'staff-table' : ''} 
            ${isAssigned ? 'opacity-100' : 'opacity-70'} relative`}
          >
            {renderChairs()}
            <div className="the-table">
              {renderPlates()}
            </div>
          </div>

          {/* Status Text Section */}
          <div className="mt-2 text-center">
            <p className="text-sm font-medium">Table {table.number}</p>
            <p
              className={`text-xs font-semibold ${
                table.isAvailable 
                  ? 'text-green-600' 
                  : 'text-red-600'
              }`}
            >
              {isMerged
                ? `Merged Table (${table.capacity})`
                : table.isStaffTable
                ? 'Staff Table'
                : table.isAvailable
                ? 'Available'
                : 'Reserved'}
            </p>
            <p className="text-xs text-gray-500">
              Capacity: {table.capacity}
            </p>
          </div>
        </div>
        
        <div
  className={`table-management p-3 border rounded-lg mt-1 shadow-sm transition-transform duration-200 ${
    table.isAvailable ? 'bg-green-50 border-green-200' : 'bg-red-50 border-red-200'
  }`}
>
  <div className="flex flex-row items-center justify-between text-sm space-x-3">
    {/* Table Information */}
    <div>
      <p className="font-medium text-gray-800">Table {table.number}</p>
      <p
        className={`text-xs font-semibold ${
          table.isAvailable ? 'text-green-600' : 'text-red-600'
        }`}
      >
        {isMerged
          ? `Merged Table (${table.capacity})`
          : table.isStaffTable
          ? 'Staff Table'
          : table.isAvailable
          ? 'Available'
          : 'Occupied'}
      </p>
    </div>
    
    {/* Divider */}
    <div className="h-6 w-px bg-gray-200"></div>
    
    {/* Action Icons */}
    <div className="flex items-center space-x-2">
      <Link to="/restaurant/table-reservation" >
        <IconRepeat
          size={18}
          className="cursor-pointer text-gray-500 hover:text-blue-500 transition-colors duration-150"
          onClick={(e) => e.stopPropagation()}
        />
      </Link>
      <IconTrash
        size={18}
        className={`cursor-pointer ${
          isDeleting ? 'text-gray-400' : 'text-gray-500 hover:text-red-500'
        } transition-colors duration-150`}
        onClick={handleDelete}
        disabled={isDeleting}
        style={{ opacity: isDeleting ? 0.5 : 1 }}
      />
    </div>
  </div>
</div>


      </div>
    </Draggable>
  );
}

export default Table;


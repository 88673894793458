import React, { useEffect, useState } from 'react';
import { X, Search, Settings } from 'lucide-react';
import { createQuote } from '../../../../../api/quote';
import { getClients } from '../../../../../api/accounting';
import { getAllItemsOrServices } from '../../../../../api/itemOrService';
import ItemTable from '../../../../../components/quotes/ItemTable.tsx';
import ItemSelectionModal from '../../../../../components/quotes/ItemSelectionModal.tsx';

interface ItemRow {
  id: string;
  details: string;
  quantity: number;
  rate: number;
  discount: number;
  tax: string;
  amount: number;
  itemId?: number; // Add itemId as an optional property
}

interface Client {
  id: string;
  name: string;
}

interface Item {
  id: number;
  name: string;
  description: string;
  sellingPrice: number;
}

export default function QuotesForm() {
  const [items, setItems] = useState<ItemRow[]>([
    {
      id: '1',
      details: '',
      quantity: 1.0,
      rate: 0.0,
      discount: 0,
      tax: '',
      amount: 0.0,
    },
  ]);
  const [availableItems, setAvailableItems] = useState<Item[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);
  const [shippingCharges, setShippingCharges] = useState(0);
  const [adjustment, setAdjustment] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const [quoteDate, setQuoteDate] = useState(new Date().toISOString().split('T')[0]);
  const [expiryDate, setExpiryDate] = useState('');
  const [reference, setReference] = useState('');
  const [salesperson, setSalesperson] = useState('');
  const [projectName, setProjectName] = useState('');
  const [subject, setSubject] = useState('');
  const [customerNotes, setCustomerNotes] = useState('');
  const [terms, setTerms] = useState('');
  const [status, setStatus] = useState('draft');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientsResponse, itemsResponse] = await Promise.all([
          getClients(),
          getAllItemsOrServices()
        ]);
        setClients(clientsResponse.data);
        setAvailableItems(itemsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  
const handleItemSelect = (selectedItem: Item) => {
  setItems(currentItems => {
    const newItems = [...currentItems];
    newItems[selectedRowIndex] = {
      ...newItems[selectedRowIndex],
      details: selectedItem.name,
      rate: selectedItem.sellingPrice,
      amount: selectedItem.sellingPrice * newItems[selectedRowIndex].quantity,
      itemId: selectedItem.id, // Ensure itemId is set correctly
    };
    return newItems;
  });
  setIsItemModalOpen(false);
};

  const handleUpdateItem = (index: number, field: keyof ItemRow, value: any) => {
    setItems(currentItems => {
      const newItems = [...currentItems];
      newItems[index] = {
        ...newItems[index],
        [field]: value
      };
      
      if (field === 'quantity' || field === 'rate' || field === 'discount') {
        const quantity = field === 'quantity' ? value : newItems[index].quantity;
        const rate = field === 'rate' ? value : newItems[index].rate;
        const discount = field === 'discount' ? value : newItems[index].discount;
        newItems[index].amount = quantity * rate * (1 - discount / 100);
      }
      
      return newItems;
    });
  };

  const handleDeleteItem = (index: number) => {
    setItems(currentItems => currentItems.filter((_, i) => i !== index));
  };

  const handleAddRow = () => {
    setItems(currentItems => [
      ...currentItems,
      {
        id: `${Date.now()}`,
        details: '',
        quantity: 1.0,
        rate: 0.0,
        discount: 0,
        tax: '',
        amount: 0.0,
      }
    ]);
  };

  const calculateSubTotal = () => items.reduce((sum, item) => sum + item.amount, 0);
  const calculateTotal = () => calculateSubTotal() + shippingCharges + adjustment;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const quoteData = {
      customerName,
      quoteNumber: 'QT-000001',
      reference,
      quoteDate,
      expiryDate,
      salesperson,
      projectName,
      subject,
      items,
      shippingCharges,
      adjustment,
      totalAmount: calculateTotal(),
      customerNotes,
      terms,
      status
    };

    try {
      await createQuote(quoteData);
      alert('Quote created successfully!');
    } catch (error) {
      console.error('Error creating quote:', error);
      alert('There was an error creating the quote.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="min-h-screen p-4 md:p-6 max-w-[1400px] mx-auto">
      <div className="space-y-6">
        {/* Header Section */}
        <div className="bg-white rounded-lg shadow-md p-6 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-bluish-grey mb-1">Customer Name</label>
              <select
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                className="w-full p-2 border border-grey-goose rounded-md focus:outline-none focus:border-hazel-green"
              >
                <option value="">Select Customer</option>
                {clients.map((client) => (
                  <option key={client.id} value={client.name}>
                    {client.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-bluish-grey mb-1">Reference #</label>
              <input
                type="text"
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                className="w-full p-2 border border-grey-goose rounded-md focus:outline-none focus:border-hazel-green"
                placeholder="REF-0001"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-bluish-grey mb-1">Quote Date</label>
              <input
                type="date"
                value={quoteDate}
                onChange={(e) => setQuoteDate(e.target.value)}
                className="w-full p-2 border border-grey-goose rounded-md focus:outline-none focus:border-hazel-green"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-bluish-grey mb-1">Expiry Date</label>
              <input
                type="date"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                className="w-full p-2 border border-grey-goose rounded-md focus:outline-none focus:border-hazel-green"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-bluish-grey mb-1">Salesperson</label>
              <input
                type="text"
                value={salesperson}
                onChange={(e) => setSalesperson(e.target.value)}
                className="w-full p-2 border border-grey-goose rounded-md focus:outline-none focus:border-hazel-green"
                placeholder="John Doe"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-bluish-grey mb-1">Project Name</label>
              <input
                type="text"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                className="w-full p-2 border border-grey-goose rounded-md focus:outline-none focus:border-hazel-green"
                placeholder="Project ABC"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">Subject</label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="w-full p-2 border border-grey-goose rounded-md focus:outline-none focus:border-hazel-green"
              placeholder="Quote for Project ABC"
            />
          </div>
        </div>
  
        {/* Items Section */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-medium text-bluish-grey">Items</h2>
              <button type="button" className="text-blue-500 hover:text-blue-600 text-sm flex items-center">
                <span className="mr-1">⚡</span> Bulk Actions
              </button>
            </div>
  
            <div className="overflow-x-auto">
              <ItemTable
                items={items}
                onOpenModal={(index: number) => {
                  setSelectedRowIndex(index);
                  setIsItemModalOpen(true);
                }}
                onUpdateItem={handleUpdateItem}
                onDeleteItem={handleDeleteItem}
                onAddRow={handleAddRow}
              />
            </div>
  
            {/* Totals Section */}
            <div className="flex justify-end mt-6">
              <div className="w-full max-w-md space-y-3">
                <div className="flex justify-between text-sm">
                  <span className="text-bluish-grey">Sub Total:</span>
                  <span className="font-medium">€{calculateSubTotal().toFixed(2)}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-bluish-grey">Shipping Charges:</span>
                  <input
                    type="number"
                    value={shippingCharges}
                    onChange={(e) => setShippingCharges(Number(e.target.value))}
                    className="w-24 text-right border border-grey-goose rounded-md p-1 focus:outline-none focus:border-hazel-green"
                  />
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-bluish-grey">Adjustment:</span>
                  <input
                    type="number"
                    value={adjustment}
                    onChange={(e) => setAdjustment(Number(e.target.value))}
                    className="w-24 text-right border border-grey-goose rounded-md p-1 focus:outline-none focus:border-hazel-green"
                  />
                </div>
                <div className="flex justify-between text-lg font-medium pt-3 border-t border-grey-goose">
                  <span className="text-bluish-grey">Total:</span>
                  <span>€{calculateTotal().toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* Additional Information Section */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="space-y-4">
            <h2 className="text-lg font-medium text-bluish-grey">Additional Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1">Customer Notes</label>
                <textarea
                  value={customerNotes}
                  onChange={(e) => setCustomerNotes(e.target.value)}
                  rows={4}
                  className="w-full p-2 border border-grey-goose rounded-md focus:outline-none focus:border-hazel-green"
                  placeholder="Notes visible to customer"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-bluish-grey mb-1">Terms & Conditions</label>
                <textarea
                  value={terms}
                  onChange={(e) => setTerms(e.target.value)}
                  rows={4}
                  className="w-full p-2 border border-grey-goose rounded-md focus:outline-none focus:border-hazel-green"
                  placeholder="Standard terms and conditions"
                />
              </div>
            </div>
          </div>
        </div>
  
        {/* Footer Section */}
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="p-2 border border-grey-goose rounded-md focus:outline-none focus:border-hazel-green"
            >
              <option value="draft">Draft</option>
              <option value="sent">Sent</option>
              <option value="accepted">Accepted</option>
              <option value="declined">Declined</option>
            </select>
          </div>
          <div className="flex gap-3">
            <button
              type="button"
              className="px-6 py-2 border border-grey-goose text-bluish-grey rounded-md hover:bg-gray-50 transition-colors"
            >
              Save as Draft
            </button>
            <button
              type="submit"
              className="px-6 py-2 bg-hazel-green text-white rounded-md hover:bg-sage transition-colors"
            >
              Save and Send
            </button>
          </div>
        </div>
      </div>
  
      <ItemSelectionModal
        isOpen={isItemModalOpen}
        onClose={() => setIsItemModalOpen(false)}
        items={availableItems}
        onSelectItem={handleItemSelect}
      />
    </form>
  );
  
}

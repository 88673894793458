import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../components/loader';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import SelectInput from '../../components/select-input';
import { getTablesByEnvironment, getEnvironments, createReservation, updateReservation, getTableById, checkTableAvailability } from '../../api/restaurant';

function ReservationForm({ isEdit, reservation, userId }) {
  const navigate = useNavigate();
  const [tables, setTables] = useState([]);
  const [environments, setEnvironments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableCapacity, setTableCapacity] = useState(null);

  const getEnvironmentList = useCallback(async () => {
    try {
      const response = await getEnvironments();
      setEnvironments(response.data);
    } catch (err) {
      console.error('Failed to fetch environments:', err);
    }
  }, []);

  const getTableList = useCallback(async (enviromentId) => {
    try {
      const response = await getTablesByEnvironment(enviromentId);
      setTables(response.data);
    } catch (err) {
      console.error('Failed to fetch tables:', err);
    }
  }, []);

  const getTableCapacity = useCallback(async (tableId) => {
    try {
      const response = await getTableById(tableId);
      setTableCapacity(response.data.capacity);
    } catch (err) {
      console.error('Failed to fetch table capacity:', err);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getEnvironmentList();
      if (reservation && reservation.enviromentId) {
        await getTableList(reservation.enviromentId);
        if (reservation.tableId) {
          await getTableCapacity(reservation.tableId);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [getEnvironmentList, getTableList, getTableCapacity, reservation]);

  const validationSchema = Yup.object().shape({
    tableId: Yup.number().required('Table is required*'),
    enviromentId: Yup.number().required('Environment is required*'),
    numberOfPersons: Yup.number()
      .min(1, 'Number of persons must be at least 1')
      .test(
        'max-capacity',
        'Number of persons exceeds table capacity',
        function (value) {
          return value <= tableCapacity;
        }
      )
      .required('Number of persons is required*'),
    reservationDate: Yup.date().required('Reservation date is required*'),
    isActive: Yup.boolean().required('Active status is required*'),
  });

  if (loading) {
    return <Loading />;
  }

  const initialValues = isEdit
    ? reservation
    : {
        tableId: '',
        enviromentId: '',
        numberOfPersons: 1,
        reservationDate: '',
        isActive: true,
      };

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            const formattedDate = new Date(values.reservationDate).toISOString();
            
            // Check availability first
            const availabilityResponse = await checkTableAvailability(
              values.tableId, 
              formattedDate
            );
            
            if (!availabilityResponse.data.isAvailable) {
              setErrors({ 
                reservationDate: 'This table is already reserved for this time' 
              });
              setSubmitting(false);
              return;
            }

            const payload = { 
              ...values, 
              adminUserId: userId, 
              reservationDate: formattedDate 
            };

            if (isEdit) {
              await updateReservation(reservation.id, payload);
            } else {
              await createReservation(payload);
            }
            navigate('/restaurant/');
            setSubmitting(false);
          } catch (err) {
            setErrors({ submit: err?.response?.data });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} className='px-4 sm:px-7 py-5'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-3'>
              <SelectInput
                value={values.enviromentId}
                label='Environment'
                options={environments.map(env => ({
                  id: env.id,
                  title: env.name
                }))}
                onChange={async (e) => {
                  const envId = e.target.value;
                  setFieldValue('enviromentId', envId);
                  await getTableList(envId);
                }}
                onBlur={handleBlur}
                showError={errors.enviromentId && touched.enviromentId}
                errorMessage={errors.enviromentId}
              />
              <SelectInput
                value={values.tableId}
                label='Table'
                options={tables.map(table => ({
                  id: table.id,
                  title: `Table ${table.number}`
                }))}
                onChange={async (e) => {
                  const tableId = e.target.value;
                  setFieldValue('tableId', tableId);
                  await getTableCapacity(tableId);
                }}
                onBlur={handleBlur}
                showError={errors.tableId && touched.tableId}
                errorMessage={errors.tableId}
              />
              <CustomInput
                type='number'
                name='numberOfPersons'
                label='Number of Persons'
                value={values.numberOfPersons}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.numberOfPersons && touched.numberOfPersons}
                errorMessage={errors.numberOfPersons}
              />
              <CustomInput
                type='datetime-local'
                name='reservationDate'
                label='Reservation Date'
                value={values.reservationDate}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.reservationDate && touched.reservationDate}
                errorMessage={errors.reservationDate}
              />
              {errors.submit && <p className='text-error col-span-full'>{errors.submit}</p>}
            </div>
            <div className='flex flex-col sm:flex-row justify-end items-center w-full mt-4 text-sm gap-3'>
              <CancelButton onCancel={() => navigate('/reservations')} className="w-full sm:w-auto" />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-full sm:w-auto md:w-1/6'
                title={`${isEdit ? 'Edit Reservation' : 'Create Reservation'}`}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ReservationForm;


import React, { useState, useEffect } from 'react';

 // Adjust Card imports if needed
import { Card , CardContent, CardHeader, CardTitle} from '../../../../../components/shadCN/Card.tsx';

import { IconSearch, IconFileSpreadsheet } from '@tabler/icons-react'; // Import Tabler icons

import Modal from '../../../../../components/modal.js';
import Loading from '../../../../../components/loader.js';
import CustomTable from '../../../../../components/table-components/table.js';

import { Input } from '../../../../../components/shadCN/Input.tsx';
import { debounce } from 'lodash';

import { Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
  SelectScrollUpButton,
  SelectScrollDownButton  } from '../../../../../components/shadCN/Select.tsx';

import MonthSelect from '../../../../../components/MonthSelect.js';

import { getTransactionTypes } from '../../../../../api/accounting.js';
import { getFilteredLedgers } from '../../../../../api/accounting.js';

const LedgerGeneration = () => {
  const [selectedBook, setSelectedBook] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [books, setBooks] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
      const fetchTransactionTypes = async () => {
          try {
              const response = await getTransactionTypes();
              const titles = response.data;

              if (Array.isArray(titles)) {
                  const formattedBooks = titles.map(title => ({
                      value: title,
                      label: title,
                  }));
                  setBooks(formattedBooks);
              } else {
                  console.error('Fetched transaction types are not an array');
              }
          } catch (error) {
              console.error('Failed to fetch transaction types:', error);
              setErrorMessage('Failed to load transaction types.');
          }
      };

      fetchTransactionTypes();
  }, []);

  const months = Array.from({ length: 12 }, (_, index) => ({
      value: (index + 1).toString(),
      label: new Intl.DateTimeFormat('default', { month: 'long' }).format(new Date(0, index)),
  }));

  // Debounced search function
  const handleSearch = async () => {
    setTransactions([]); // Clear transactions
    setErrorMessage('');

    if (!selectedMonth || !selectedYear || !selectedBook) {
        setErrorMessage('Please select a valid book, month, and year.');
        return;
    }

    setLoading(true);
    try {
        console.log('Fetching ledgers with:', { selectedBook, selectedMonth, selectedYear });
        const response = await getFilteredLedgers(selectedBook, selectedMonth, selectedYear);
        console.log(response)
        if (response.data && Array.isArray(response.data)) {
            setTransactions(response.data);
            console.log('Transactions set:', response.data); // Log transactions set
        } else {
            console.error('Invalid data structure from API:', response);
        }
          
    } catch (error) {
        console.error('API call error:', error);
        setErrorMessage('Failed to fetch ledger entries.');
    } finally {
        setLoading(false);
    }
};


  const handleYearChange = (e) => {
      const value = e.target.value;
      setSelectedYear(value);

      // Optionally validate year here, e.g., to ensure it's a valid number
      if (value && isNaN(value)) {
          setErrorMessage('Year must be a number.');
      } else {
          setErrorMessage('');
      }
  };

  const handleExportExcel = () => {
      console.log('Exporting ledger to Excel');
  };

  // Filter transactions based on searchText
  const filteredTransactions = transactions.filter((transaction) => {
      const descriptionMatch = transaction.description?.toLowerCase().includes(searchText.toLowerCase());
      const clientMatch = transaction.client?.name?.toLowerCase().includes(searchText.toLowerCase());
      const supplierMatch = transaction.supplier?.name?.toLowerCase().includes(searchText.toLowerCase());
      return descriptionMatch || clientMatch || supplierMatch;
  });

  console.log("filtered",filteredTransactions)

  return (
      <div className="p-6 min-h-screen">
          <Card className="mb-6 bg-seashell">
              <CardHeader>
                  <CardTitle className="text-3xl font-bold text-hazel-green">Ledger Generation</CardTitle>
              </CardHeader>
              <CardContent>
                  <div className="space-y-4">
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                          <div>
                              <label htmlFor="book" className="block text-sm font-medium text-bluish-grey mb-1">
                                  Select Book
                              </label>
                              <Select onValueChange={setSelectedBook} value={selectedBook || ''}>
                                  <SelectTrigger className="w-full rounded-md border border-gray-300 shadow-md hover:shadow-lg transition-all duration-200">
                                      <SelectValue placeholder="Select a book" />
                                  </SelectTrigger>
                                  <SelectContent className="bg-white">
                                      {books.length > 0 ? (
                                          books.map(book => (
                                              <SelectItem key={book.value} value={book.value}>
                                                  {book.label}
                                              </SelectItem>
                                          ))
                                      ) : (
                                          <SelectItem disabled>No books available</SelectItem>
                                      )}
                                  </SelectContent>
                              </Select>
                          </div>

                          <div>
                              <label htmlFor="month" className="block text-sm font-medium text-bluish-grey mb-1">
                                  Select Month
                              </label>
                              <Select onValueChange={setSelectedMonth} value={selectedMonth || ''}>
                                  <SelectTrigger className="w-full rounded-md border border-gray-300 shadow-md hover:shadow-lg transition-all duration-200">
                                      <SelectValue placeholder="Select a month" />
                                  </SelectTrigger>
                                  <SelectContent className="bg-white">
                                      {months.map(month => (
                                          <SelectItem key={month.value} value={month.value}>
                                              {month.label}
                                          </SelectItem>
                                      ))}
                                  </SelectContent>
                              </Select>
                          </div>

                          <div>
                              <label htmlFor="year" className="block text-sm font-medium text-bluish-grey mb-1">
                                  Select Year
                              </label>
                              <Input
                                  type="text"
                                  value={selectedYear}
                                  onChange={handleYearChange}
                                  placeholder="Enter year (e.g., 2024)"
                                  className="w-full rounded-md border border-gray-300 shadow-md hover:shadow-lg transition-all duration-200"
                              />
                          </div>
                      </div>
                      <div className="flex gap-x-3">
                          <button
                              className="flex items-center text-white rounded-md bg-sage px-4 py-2.5"
                              onClick={handleSearch}
                          >
                              <IconSearch className="mr-2" size={20} />
                              Search
                          </button>
                          <button
                              onClick={handleExportExcel}
                              className="flex items-center text-white rounded-md bg-sage px-4 py-2.5"
                          >
                              <IconFileSpreadsheet className="mr-2" size={20} />
                              Excel
                          </button>
                      </div>
                  </div>
              </CardContent>
          </Card>

          {loading && <Loading />}
          {errorMessage && <div className="text-red-500 text-center">{errorMessage}</div>}

          {modalVisible && (
              <Modal onClose={() => setModalVisible(false)}>
                  <h2>No ledger entries found</h2>
                  <p>There were no results for the selected filters.</p>
              </Modal>
          )}

{transactions.length > 0 ? (
    <CustomTable
        rows={transactions}
        columns={[
            { title: 'Transaction Date', renderValue: (item) => new Date(item.transactionDate).toLocaleDateString() },
            { title: 'Amount', renderValue: (item) => `$${item.amount.toFixed(2)}` },
            { title: 'Payment Type', renderValue: (item) => item.paymentType },
            { title: 'Description', renderValue: (item) => item.description || 'N/A' },
            { title: 'Client', renderValue: (item) => item.Client.length > 0 ? item.Client[0].name : 'N/A' },
            { title: 'Supplier', renderValue: (item) => item.Supplier.length > 0 ? item.Supplier[0].name : 'N/A' },
        ]}
        loading={loading} 
        setSearchText={setSearchText}
    />
) : (
    <p className="text-red-500 text-center">No transactions found.</p>
)}



      </div>
  );
};

export default LedgerGeneration;



import cn from 'classnames'
import { keys } from './data.ts'
import React from 'react'

export type KeyColor = 'standard' | 'mark' | 'red'
export interface KeyProps {
  label: number | string
  color: KeyColor
  action?: string
  span?: boolean
}
const Keypad = ({ handleKeyPress , handleSubmitInvoice, handleReleaseForPayment, handleInvoicePush }) => {
    return (
        <div className="grid grid-cols-4 gap-2 mt-2">
            {keys.map((key, idx) => (
                <Key key={idx} {...key} handleKeyPress={handleKeyPress} />
            ))}
            <div className="col-span-4 grid grid-cols-3 gap-2 mt-2">
                <button className="bg-hazel-green text-white rounded-lg py-3 text-sm font-medium" onClick={() => handleKeyPress('', 'sell')}>
                    SELL
                </button>
                <button className="bg-hazel-green text-white rounded-lg py-3 text-sm font-medium" onClick={() => handleKeyPress('', 'x rpt')}>
                    X RPT
                </button>
                <button className="bg-red-600 hover:bg-red-500 text-white rounded-lg py-3 text-sm font-medium" onClick={() => handleKeyPress('', 'z rpt')}>
                    Z RPT
                </button>

                <button
                    className="bg-hazel-green text-white rounded-lg py-3 text-sm font-medium"
                    // onClick={handleSubmitInvoice}
                    onClick={handleInvoicePush}
                >
                    Submit Invoice
                </button>
                <button
                    className="bg-hazel-green text-white rounded-lg py-3 text-sm font-medium"
                    onClick={handleReleaseForPayment}
                >
                    Release for Payment
                </button>
            </div>
        </div>
    );
};
  
  export default Keypad;
  



const setResult = (state: any, payload: string) => {
  return {
    ...state,
    calculator: {
      ...state.calculator,
      result: payload,
    },
  }
}

const setOperator = (state: any, payload: string) => {
  return {
    ...state,
    calculator: {
      ...state.calculator,
      operator: payload,
    },
  }
}

const setFirstValue = (state: any, payload: string) => {
  return {
    ...state,
    calculator: {
      ...state.calculator,
      firstValue: payload,
    },
  }
}

const setSecondValue = (state: any, payload: string) => {
  return {
    ...state,
    calculator: {
      ...state.calculator,
      secondValue: payload,
    },
  }
}

const calculate = (num1: string, operator: string, num2: string) => {
  let leftNum = parseFloat(num1)
  let rightNum = parseFloat(num2)

  if (operator === '') {
    return leftNum.toString()
  }
  if (num2 === '') {
    rightNum = leftNum
  }
  switch (operator) {
    case 'add':
      return (leftNum + rightNum).toString()
    case 'subtract':
      return (leftNum - rightNum).toString()
    case 'multiply':
      return (leftNum * rightNum).toString()
    case 'divide':
      return (leftNum / rightNum).toString()
    default:
      return leftNum.toString()
  }
}

const Key = ({ label, color, action, handleKeyPress }:KeyProps & { handleKeyPress: (label: string | number, action?: string) => void }) => {
    const onKeyClick = () => {
        handleKeyPress(label, action);
    };

    return (
        <button
            className={cn(
                'rounded-lg h-12 flex items-center justify-center',
                'transition-colors duration-150',
                'text-base font-medium',
                getKeyColor(color)
            )}
            onClick={onKeyClick}
        >
            {label}
        </button>
    );
};

const getKeyColor = (color: KeyColor) => {
  switch (color) {
    case 'standard':
      return 'bg-tealish-blue border-b-mercury text-silver'
    case 'mark':
      return 'bg-hazel-green border-b-grey-goose text-white'
    case 'red':
      return 'bg-watermelon border-b-salmon-pink text-white'
    default:
      return ''
  }
}

import React from 'react';
import { Link } from 'react-router-dom';
import { IconHistory, IconPencil, IconTrash } from '@tabler/icons-react';

interface Room {
  id: string;
  number: number;
  floor: number;
  status: string;
  inventory: Array<{
    item: string;
    quantity: string | number;
    goodCondition: boolean;
  }> | null;
  roomTypeId: string;
}

interface Props {
  rooms: Room[];
  loading: boolean;
  hoveredRoom: string | null;
  setHoveredRoom: (id: string | null) => void;
  onStatusChange: () => void;
}

export const RoomsTable: React.FC<Props> = ({ 
  rooms, 
  loading, 
  hoveredRoom,
  setHoveredRoom,
  onStatusChange 
}) => {
  if (loading) {
    return <div>Loading rooms...</div>;
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'VACANT':
        return 'bg-seashell';
      case 'RESERVED':
        return 'bg-dawn-pink';
      case 'OCCUPIED':
        return 'bg-salmon-pink';
      default:
        return 'bg-seashell';
    }
  };

  // Sort rooms by number
  const sortedRooms = [...rooms].sort((a, b) => a.number - b.number);

  return (
    <div className="grid grid-cols-4 gap-4">
      {sortedRooms.map((room) => (
        <div
          key={room.id}
          className={`flex justify-center items-center p-5 rounded-md ${getStatusColor(room.status)}`}
          onMouseEnter={() => setHoveredRoom(room.id)}
          onMouseLeave={() => setHoveredRoom(null)}
        >
          {hoveredRoom === room.id ? (
            <div className="flex justify-center items-center bg-white py-2.5 px-5 gap-x-5 rounded">
              <IconHistory
                className="text-bluish-grey cursor-pointer"
                size={18}
                onClick={() => {/* Add history view logic */}}
              />
              <Link to={`/rooms/${room.id}`}>
                <IconPencil className="text-bluish-grey" size={18} />
              </Link>
              <IconTrash
                className="text-bluish-grey cursor-pointer"
                size={18}
                onClick={() => {/* Add delete logic */}}
              />
            </div>
          ) : (
            <div className="text-center">
              <p className="text-bluish-grey text-2xl font-medium py-2.5">
                Room {room.number}
              </p>
              <p className="text-sm text-gray-500">Floor {room.floor}</p>
              <p className="text-sm text-gray-500">{room.status}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

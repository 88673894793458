import React, { useState, useEffect, useRef } from "react";

// SlidingTabBar component which receives dynamic tabs from props
export const SlidingBtnTabEnvironments = ({ environments, onTabChange }) => {
  const tabsRef = useRef([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0); // Set default active tab
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  useEffect(() => {
    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => {
      window.removeEventListener("resize", setTabPosition);
    };
  }, [activeTabIndex]);

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
    onTabChange(environments[index].id);
  };

  return (
    <div className="relative z-20 mx-auto flex h-12 rounded-3xl border border-black/40 bg-neutral-800 px-2 backdrop-blur-sm">
      <span
        className="absolute bottom-0 top-0 z-10 flex overflow-hidden rounded-3xl py-2 transition-all duration-300"
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      >
        <span className="h-full w-full rounded-3xl bg-gray-200/30" />
      </span>
      {environments.map((env, index) => {
        const isActive = activeTabIndex === index;

        return (
          <button
            key={env.id}
            ref={(el) => (tabsRef.current[index] = el)}
            className={`${
              isActive ? "text-neutral-300" : "hover:text-neutral-300"
            } z-30 my-auto cursor-pointer select-none rounded-full px-4 text-center font-light text-white transition-all duration-300`}
            onClick={() => handleTabClick(index)}
          >
            {env.name}
          </button>
        );
      })}
    </div>
  );
};

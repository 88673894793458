// import React from 'react';
// import { Search, Settings } from 'lucide-react';
// import { FormField } from './FormField.tsx';
// import { format } from 'date-fns';

// export function BasicDetails() {
//   const today = format(new Date(), 'yyyy-MM-dd');

//   return (
//     <div className="space-y-4">
//       <FormField label="Customer Name" required>
//         <div className="relative">
//           <input 
//             type="text"
//             placeholder="Type the customer's name"
//             className="w-full p-2.5 text-sm border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-sage focus:border-sage pr-10"
//           />
//           <Search className="absolute right-3 top-3 text-silver cursor-pointer hover:text-sage transition-colors" size={16} />
//         </div>
//       </FormField>

//       <FormField label="Receipt Date" required>
//         <input 
//           type="date" 
//           defaultValue={today}
//           className="w-full p-2.5 text-sm border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-sage focus:border-sage"
//         />
//       </FormField>

//       <FormField label="Sales Receipt#" required>
//         <div className="relative">
//           <input 
//             type="text" 
//             value="SR-00001"
//             className="w-full p-2.5 text-sm border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-sage focus:border-sage"
//             readOnly
//           />
//           <Settings className="absolute right-3 top-3 text-silver hover:text-sage transition-colors cursor-pointer" size={16} />
//         </div>
//       </FormField>

//       <FormField label="Salesperson">
//         <select className="w-full p-2.5 text-sm border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-sage focus:border-sage appearance-none bg-white">
//           <option value="">Select or Add Salesperson</option>
//           <option value="1">John Doe</option>
//           <option value="2">Jane Smith</option>
//         </select>
//       </FormField>
//     </div>
//   );
// }

import { Search, User } from 'lucide-react';
import React, { useEffect, useState } from 'react';

interface Client {
  id: string;
  name: string;
}
interface BasicDetailsProps {
  clients: Client[];
  selectedClient: string;
  onClientChange: React.Dispatch<React.SetStateAction<string>>;
  receiptDate: string;
  onDateChange: React.Dispatch<React.SetStateAction<string>>;
  receiptNumber: string;
  onNumberChange: React.Dispatch<React.SetStateAction<string>>;
  reference: string;  // Assuming you will use reference in the component
  onReferenceChange: React.Dispatch<React.SetStateAction<string>>;
}
export function BasicDetails({
  clients,
  selectedClient,
  onClientChange,
  receiptDate,
  onDateChange,
  receiptNumber,
  onNumberChange,
  reference,
  onReferenceChange,
}: BasicDetailsProps) {

  return (
    <div className="bg-seashell rounded-lg p-6 space-y-6">
      <h2 className="text-lg font-semibold text-bluish-grey flex items-center gap-2">
        <User size={20} className="text-hazel-green" />
        Basic Details
      </h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-bluish-grey">Customer</label>
          <div className="relative">
            <select
              value={selectedClient}
              onChange={(e) => onClientChange(e.target.value)}
              className="w-full p-2.5 pl-10 border border-grey-goose rounded-lg focus:outline-none focus:border-sage bg-white text-bluish-grey transition-colors hover:border-sage"
            >
              <option value="">Select Customer</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-silver" size={18} />
          </div>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-bluish-grey">Receipt Date</label>
          <input
            type="date"
            value={receiptDate}
            onChange={(e) => onDateChange(e.target.value)}
            className="w-full p-2.5 border border-grey-goose rounded-lg focus:outline-none focus:border-sage bg-white text-bluish-grey transition-colors hover:border-sage"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-bluish-grey">Receipt Number</label>
          <input
            type="text"
            value={receiptNumber}
            onChange={(e) => onNumberChange(e.target.value)}
            placeholder="SR-0001"
            className="w-full p-2.5 border border-grey-goose rounded-lg focus:outline-none focus:border-sage bg-white text-bluish-grey transition-colors hover:border-sage"
          />
        </div>
      </div>
    </div>
  );
}
import React, { useState, useEffect, useCallback } from 'react';
import { AddButton } from '../../../../../components/buttons';
import CustomTable from '../../../../../components/table-components/table';
import Loading from '../../../../../components/loader';
import { getPayrollTracking } from '../../../../../api/finances'; // API function to fetch payroll tracking
import { getJobTitles } from '../../../../../api/jobTitles';
import { getEmployeeById } from '../../../../../api/employee';

function PayrollTrackingList() {
  const [payrollTrackingList, setPayrollTrackingList] = useState([]);
  const [jobTitles, setJobTitles] = useState([]); // State to hold job titles
  const [employees, setEmployees] = useState({}); // State to hold fetched employee data
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const getPayrollTrackingList = useCallback(async () => {
    try {
      const response = await getPayrollTracking(rowsPerPage, currentPage + 1, searchText);
      console.log('API Response:', response.data);
      
      // Accessing trackingRecords correctly
      const trackingRecords = response.data.trackingRecords || [];
      setPayrollTrackingList(trackingRecords);
      setTotalCount(response.data.count || 0);
      
      // Fetch employee details for each tracking record
      const employeePromises = trackingRecords.map(record =>
        getEmployeeById(record.employeeId).then(res => ({
          id: record.employeeId,
          name: `${res.data.name} ${res.data.surname}`, // Assuming your API returns name and surname
        }))
      );
      
      // Wait for all employee data to be fetched
      const employeeData = await Promise.all(employeePromises);
      // Create a mapping for employee names
      const employeeMap = {};
      employeeData.forEach(emp => {
        employeeMap[emp.id] = emp.name;
      });
      setEmployees(employeeMap); // Store the employee data
      
      console.log('Updated Payroll Tracking List:', response.data);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, searchText]);

  const getJobTitlesList = useCallback(async () => {
    try {
      const response = await getJobTitles();
      setJobTitles(response.data); 
      console.log('Job Titles:', response.data);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getPayrollTrackingList().finally(() => setLoading(false));
    getJobTitlesList();
  }, [getPayrollTrackingList, getJobTitlesList]);
  
  useEffect(() => {
    setLoading(true);
    getPayrollTrackingList().finally(() => setLoading(false));
  }, [currentPage, rowsPerPage, searchText]);
  
  const getJobTitleById = (id) => {
    const jobTitle = jobTitles.find(title => title.id === id);
    return jobTitle ? jobTitle.title : 'N/A'; // Return 'N/A' if no title found
  };

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className='p-10'>
      <AddButton entity='payroll tracking' link='/finances/payroll-tracking/add-tracking' title='New Payroll Tracking' />
      <CustomTable
        rows={payrollTrackingList}
        columns={[
          {
            title: 'Employee Name',
            renderValue: (item) => employees[item.employeeId] || 'Loading...' // Use the fetched employee names
          },
          {
            title: 'Payroll ID',
            renderValue: (item) => item.payrollId
          },
          {
            title: 'Payment Date',
            renderValue: (item) => new Date(item.paymentDate).toLocaleDateString() // Format the date
          },
          {
            title: 'Amount Paid',
            renderValue: (item) => `$${item.amountPaid}`
          },
          {
            title: 'Deductions',
            renderValue: (item) => `$${item.payroll.deductions}` // Access deductions from payroll
          },
          {
            title: 'Bonus',
            renderValue: (item) => `$${item.payroll.bonus}` // Access bonus from payroll
          },
          {
            title: 'Pay Period',
            renderValue: (item) => item.payroll.payPeriod // Access pay period from payroll
          },
          {
            title: 'Actions',
            renderValue: (item) => (
              <button 
                className='px-4 py-1 text-center text-white rounded-full bg-hazel-green'
                onClick={() => alert(`Viewing details for tracking ID: ${item.id}`)}
              >
                View
              </button>
            )
          }
        ]}
        entity='payroll tracking'
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={true}
        editLink='/finances/payroll-tracking'
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </div>
  );
}

export default PayrollTrackingList;





import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Loading from '../../components/loader';
import PageTitle from '../../components/page-title';
import InvoiceForm from '../../components/InvoiceForm';
import { createPayment } from '../../api/payments';
import { getDishOrderById as getOrderById, updateDishOrder as updateOrder } from '../../api/orders';
import { IconReceipt } from '@tabler/icons-react';

function CompleteOrder() {
  const { id } = useParams();
  const location = useLocation();
  const { totalPrice } = location.state || {};
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchOrder() {
      try {
        const response = await getOrderById(id);
        setOrder(response.data);
      } catch (error) {
        console.error('Error fetching order:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchOrder();
  }, [id]);

  const handlePaymentSubmit = async (paymentData) => {
    try {
      await createPayment({
        orderId: order.id,
        amount: totalPrice || order.totalPrice,
      });
      await updateOrder(order.id, { status: 'paid' });
      navigate('/orders');
    } catch (error) {
      console.error('Error processing payment:', error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconReceipt color='#617C58' size={24} />}
        title='Complete Order'
      />
      <InvoiceForm order={order} onSubmit={handlePaymentSubmit} />
    </div>
  );
}

export default CompleteOrder;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../components/loader';
import SelectInput from '../../components/select-input';
import CustomInput from '../../components/custom-input';
import { SubmitButton, CancelButton } from '../../components/buttons';
import { createEmployee, updateEmployee } from '../../api/employee';
import { getJobTitles } from '../../api/jobTitles';

import DateInput from '../../components/date-input';
import dayjs from 'dayjs';


function EmployeeForm({ isEdit, employee }) {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [jobTitles, setJobTitles] = useState([]);
 
  useEffect(() => {
    const fetchJobTitles = async () => {
      try {
        const response = await getJobTitles();
        const titles = response.data; // Adjust based on the actual structure of the API response
        if (Array.isArray(titles)) {
          setJobTitles(titles);
          console.log('Fetched job titles:', titles);
        } else {
          console.error('Fetched job titles are not an array');
        }
      } catch (err) {
        console.error('Error fetching job titles:', err);
      }
    };

    fetchJobTitles();
  }, []);

  const initialValues = isEdit
  ? {
      id: employee.id,
      name: employee.name,
      surname: employee.surname,
      email: employee.email,
      phoneNumber: employee.phoneNumber || '',
      jobTitle: employee.jobTitleId || '', // Use jobTitleId if that’s what you are storing
      department: employee.department,
      wage: employee.wage || '',
      hireDate: dayjs(employee.hireDate), // Convert to Day.js object
      terminationDate: employee.terminationDate ? dayjs(employee.terminationDate) : null, // Convert to Day.js object
    }
  : {
      name: '',
      surname: '',
      email: '',
      phoneNumber: '',
      jobTitle: '',
      department: '',
      wage: '',
      hireDate: null,
      terminationDate: null,
    };


//   useEffect(() => {
//     const fetchJobTitles = async () => {
//       try {
//         const response = await getJobTitles();
//         const titles = response.data; // Adjust based on the actual structure of the API response
//         if (Array.isArray(titles)) {
//           setJobTitles(titles);
//           console.log('Fetched job titles:', titles);
//         } else {
//           console.error('Fetched job titles are not an array');
//         }
//       } catch (err) {
//         console.error('Error fetching job titles:', err);
//       }
//     };

//     fetchJobTitles();
//   }, []);

//   const initialValues = isEdit
//     ? {
//         id: employee.id,
//         name: employee.name,
//         surname: employee.surname,
//         email: employee.email,
//         phoneNumber: employee.phoneNumber || '',
//         jobTitle: employee.jobTitle.id,
//         department: employee.department,
//         wage: employee.wage || '',
//         hireDate: employee.hireDate.split('T')[0], // Formatting date for input
//         terminationDate: employee.terminationDate ? employee.terminationDate.split('T')[0] : '',
//       }
//     : {
//         name: '',
//         surname: '',
//         email: '',
//         phoneNumber: '',
//         jobTitle: '',
//         department: '',
//         wage: '',
//         hireDate: '',
//         terminationDate: '',
//       };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required*'),
    surname: Yup.string().required('Surname is required*'),
    email: Yup.string().email('Invalid email format').required('Email is required*'),
    phoneNumber: Yup.string(),
    jobTitle: Yup.string().required('Job Title is required*'),
    department: Yup.string().required('Department is required*'),
    wage: Yup.number().required('Wage is required*').positive('Wage must be a positive number'),
    hireDate: Yup.date().required('Hire Date is required*'),
    terminationDate: Yup.date().nullable(),
  });

  const handleSubmit = async (values, { setErrors, setSubmitting }) => {
    setLoading(true);
    try {
      if (isEdit) {
        await updateEmployee(values.id, {
          ...values,
          hireDate: new Date(values.hireDate),
          terminationDate: values.terminationDate ? new Date(values.terminationDate) : null,
        });
      } else {
        await createEmployee({
          ...values,
          hireDate: new Date(values.hireDate),
          terminationDate: values.terminationDate ? new Date(values.terminationDate) : null,
        });
      }
      navigate('/employees');
    } catch (err) {
      setErrors({ submit: err?.response?.data });
    }
    setSubmitting(false);
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <div>
            <form onSubmit={handleSubmit}>
              <h3 className='text-bluish-grey font-medium uppercase mt-3'>Employee Info</h3>
              <div className='border-b border-hazel-green py-1' />
              <div className='grid grid-cols-2 gap-3 mt-4'>
                <div>
                  <CustomInput
                    type='text'
                    name='name'
                    label='Name'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.name && touched.name}
                    errorMessage={errors.name}
                  />
                </div>
                <div>
                  <CustomInput
                    type='text'
                    name='surname'
                    label='Surname'
                    value={values.surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.surname && touched.surname}
                    errorMessage={errors.surname}
                  />
                </div>
                <div>
                  <CustomInput
                    type='text'
                    name='email'
                    label='Email'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.email && touched.email}
                    errorMessage={errors.email}
                  />
                </div>
                <div>
                  <CustomInput
                    type='text'
                    name='phoneNumber'
                    label='Phone Number'
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.phoneNumber && touched.phoneNumber}
                    errorMessage={errors.phoneNumber}
                  />
                </div>
                <div>
                  <SelectInput
                    name='jobTitle'
                    label='Job Title'
                    options={jobTitles} // Assuming jobTitles is an array of { id, title }
                    value={values.jobTitle}
                    onChange={(event) => setFieldValue('jobTitle', event.target.value)}
                    onBlur={handleBlur}
                    showError={errors.jobTitle && touched.jobTitle}
                    errorMessage={errors.jobTitle}
                    formatOption={(option) => ({ value: option.id, label: option.title })} // Adjust according to your SelectInput implementation
                  />
                </div>
                <div>
                  <CustomInput
                    type='text'
                    name='department'
                    label='Department'
                    value={values.department}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.department && touched.department}
                    errorMessage={errors.department}
                  />
                </div>
                <div>
                  <CustomInput
                    type='number'
                    name='wage'
                    label='Wage'
                    value={values.wage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.wage && touched.wage}
                    errorMessage={errors.wage}
                  />
                </div>
                <div>
                    <DateInput
                        name='hireDate'
                        label='Hire Date'
                        value={values.hireDate}
                        onChange={(date) => {
                        setFieldValue('hireDate', date);
                        }}
                        onBlur={handleBlur}
                        showError={errors.hireDate && touched.hireDate}
                        errorMessage={errors.hireDate}
                    />
                    </div>
                    <div>
                    <DateInput
                        name='terminationDate'
                        label='Termination Date'
                        value={values.terminationDate || null} 
                        onChange={(date) => {
                        setFieldValue('terminationDate', date);
                        }}
                        onBlur={handleBlur}
                        showError={errors.terminationDate && touched.terminationDate}
                        errorMessage={errors.terminationDate}
                    />
                    </div>

              </div>
              {errors.submit && <p className='text-error'>{errors.submit}</p>}
              <div className='flex justify-end items-center w-full mt-7 text-sm'>
                <CancelButton onCancel={() => navigate('/employees')} />
                <SubmitButton
                  isSubmitting={isSubmitting}
                  width='w-1/5'
                  title='Save'
                />
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default EmployeeForm;

// import React from 'react';

// const StatisticCard = ({ title, value, Icon, bgColor = 'bg-white', textColor = 'text-bluish-grey' }) => {
//   return (
//     <div className={`shadow-lg rounded-lg p-6 flex items-center justify-between border border-grey-goose transition-transform transform hover:scale-105 hover:shadow-xl ${bgColor}`}>
//       <div className={`${textColor}`}>
//         <h2 className="text-lg font-medium">{title}</h2>
//         <p className="text-3xl font-bold mt-2">{value}</p>
//       </div>
//       <div className="bg-sage p-3 rounded-full">
//         <Icon className="w-10 h-10 text-white" />
//       </div>
//     </div>
//   );
// };

// export default StatisticCard;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StatisticCard = ({ title, value, Icon, bgColor = 'bg-white', textColor = 'text-bluish-grey' }) => {
  return (
    <div className={`shadow-lg rounded-lg p-6 flex items-center justify-between border border-grey-goose transition-transform transform hover:scale-105 hover:shadow-xl ${bgColor}`}>
      <div className={`${textColor}`}>
        <h2 className="text-lg font-medium">{title}</h2>
        <p className="text-3xl font-bold mt-2">{value}</p>
      </div>
      <div className="bg-sage p-3 rounded-full">
        {/* Render the icon correctly */}
        <FontAwesomeIcon icon={Icon} className="w-10 h-10 text-white" />
      </div>
    </div>
  );
};

export default StatisticCard;

// import React, { useEffect, useState } from 'react';
// import { ClipboardCheck, AlertTriangle, ChevronDown, ChevronRight } from 'lucide-react';

// interface CleaningTasksProps {
//   roomNumber: string;
//   cleaningReport?: {
//     roomChecklist: Array<{
//       category: string;
//       items: Array<{
//         name: string;
//         checked: boolean;
//         status: string;
//       }>;
//     }>;
//     issuesDescription?: string;
//     issuesFound: string[];
//     cleaningDate: string;
//     roomCondition: string;
//     cleaner: {
//       name: string;
//       surname: string;
//     };
//   };
// }

// export default function CleaningTasks({ roomNumber, cleaningReport }: CleaningTasksProps) {
//   const [expandedCategories, setExpandedCategories] = useState<string[]>([]);

//   const toggleCategory = (category: string) => {
//     setExpandedCategories(prev =>
//       prev.includes(category)
//         ? prev.filter(c => c !== category)
//         : [...prev, category]
//     );
//   };

//   const roomChecklist = cleaningReport?.roomChecklist || [];

//   // Calculate statistics
//   const totalItems = roomChecklist.reduce((sum, category) => sum + category.items.length, 0);
//   const checkedItems = roomChecklist.reduce((sum, category) => 
//     sum + category.items.filter(item => item.checked).length, 0
//   );
//   const completionPercentage = totalItems > 0 ? Math.round((checkedItems / totalItems) * 100) : 0;

//   return (
//     <div className="space-y-6">
//       {/* Progress Summary */}
//       <div className="bg-seashell p-4 rounded-lg">
//         <div className="flex justify-between items-center mb-2">
//           <h4 className="text-sm font-medium text-bluish-grey">Cleaning Progress</h4>
//           <span className="text-sm font-medium text-hazel-green">{completionPercentage}% Complete</span>
//         </div>
//         <div className="w-full bg-grey-goose rounded-full h-2">
//           <div 
//             className="bg-hazel-green h-2 rounded-full transition-all duration-300"
//             style={{ width: `${completionPercentage}%` }}
//           />
//         </div>
//       </div>

//       <div className="grid grid-cols-2 gap-6">
//         {/* Checklist Column */}
//         <div className="space-y-4">
//           <h3 className="text-lg font-semibold text-hazel-green flex items-center gap-2">
//             <ClipboardCheck size={20} />
//             Room Inspection Checklist
//           </h3>
          
//           <div className="space-y-2">
//             {roomChecklist.map((category, index) => {
//               const categoryItemsChecked = category.items.filter(item => item.checked).length;
//               const categoryProgress = Math.round((categoryItemsChecked / category.items.length) * 100);
//               const isExpanded = expandedCategories.includes(category.category);

//               return (
//                 <div 
//                   key={`${category.category}-${index}`} 
//                   className="border border-grey-goose rounded-lg overflow-hidden"
//                 >
//                   <button
//                     onClick={() => toggleCategory(category.category)}
//                     className="w-full px-4 py-3 flex items-center justify-between bg-seashell hover:bg-mint-cream transition-colors"
//                   >
//                     <div className="flex items-center gap-2">
//                       {isExpanded ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
//                       <span className="font-medium text-sm">{category.category}</span>
//                       <span className="text-xs text-bluish-grey">
//                         ({categoryItemsChecked}/{category.items.length} items)
//                       </span>
//                     </div>
//                     <div className="flex items-center gap-2">
//                       <div className="w-20 h-1.5 bg-grey-goose rounded-full">
//                         <div 
//                           className="h-1.5 bg-hazel-green rounded-full transition-all duration-300"
//                           style={{ width: `${categoryProgress}%` }}
//                         />
//                       </div>
//                       <span className="text-xs text-bluish-grey">{categoryProgress}%</span>
//                     </div>
//                   </button>

//                   {isExpanded && (
//                     <div className="p-3 space-y-2 bg-white">
//                       {category.items.map((item, itemIndex) => (
//                         <div
//                           key={`${item.name}-${itemIndex}`}
//                           className="flex items-center justify-between p-2 rounded-md bg-seashell"
//                         >
//                           <div className="flex items-center gap-3">
//                             <input
//                               type="checkbox"
//                               checked={item.checked}
//                               disabled
//                               className="h-4 w-4 text-hazel-green border-grey-goose rounded focus:ring-hazel-green"
//                             />
//                             <span className="text-sm">{item.name}</span>
//                           </div>
//                           <span
//                             className={`text-xs px-2 py-1 rounded-full ${
//                               item.status === 'Good'
//                                 ? 'bg-mint-cream text-hazel-green'
//                                 : 'bg-dawn-pink text-salmon-pink'
//                             }`}
//                           >
//                             {item.status}
//                           </span>
//                         </div>
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               );
//             })}
//           </div>
//         </div>

//         {/* Issues Column */}
//         <div className="space-y-4">
//           <h3 className="text-lg font-semibold text-hazel-green flex items-center gap-2">
//             <AlertTriangle size={20} />
//             Damage Assessment
//           </h3>

//           <div className="space-y-4">
//             <div>
//               <label className="block text-sm font-medium text-bluish-grey mb-1">
//                 Issues Found
//               </label>
//               <div className="mb-3">
//                 {cleaningReport?.issuesFound?.map((issue, index) => (
//                   <span key={index} className="inline-block px-2 py-1 bg-dawn-pink text-salmon-pink rounded-full text-xs mr-2 mb-2">
//                     {issue}
//                   </span>
//                 ))}
//               </div>
//               <label className="block text-sm font-medium text-bluish-grey mb-1">
//                 Issues Description
//               </label>
//               <textarea
//                 rows={4}
//                 className="w-full px-3 py-2 rounded-md border border-grey-goose focus:ring-1 focus:ring-hazel-green"
//                 value={cleaningReport?.issuesDescription || ''}
//                 disabled
//                 placeholder="No issues reported"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useState } from 'react';
import { ChevronDown, ChevronRight, ClipboardCheck, AlertTriangle } from 'lucide-react';

interface CleaningTasksProps {
  roomNumber: string;
  cleaningReport?: {
    roomChecklist: Array<{
      category: string;
      items: Array<{
        name: string;
        checked: boolean;
        status: string;
      }>;
    }>;
    issuesDescription?: string;
    issuesFound: string[];
    cleaningDate: string;
    roomCondition: string;
    cleaner: {
      name: string;
      surname: string;
    };
  };
}

export default function CleaningTasks({ roomNumber, cleaningReport }: CleaningTasksProps) {
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
  const roomChecklist = cleaningReport?.roomChecklist || [];

  const toggleCategory = (category: string) => {
    setExpandedCategories(prev =>
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  // Calculate statistics
  const totalItems = roomChecklist.reduce((sum, category) => sum + category.items.length, 0);
  const checkedItems = roomChecklist.reduce(
    (sum, category) => sum + category.items.filter(item => item.checked).length,
    0
  );
  const completionPercentage = totalItems > 0 ? Math.round((checkedItems / totalItems) * 100) : 0;

  return (
    <div className="max-w-7xl mx-auto p-6 bg-tealish-blue min-h-5/6">
      {/* Header Stats */}
      <div className="bg-white rounded-xl p-4 mb-6 shadow-sm">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <ClipboardCheck className="text-hazel-green h-5 w-5" />
            <h2 className="text-lg font-semibold text-hazel-green">Room {roomNumber} Inspection</h2>
          </div>
          <div className="flex items-center gap-6">
            <div className="text-right">
              <p className="text-sm text-bluish-grey">Tasks Completed</p>
              <p className="text-lg font-semibold text-hazel-green">{checkedItems}/{totalItems}</p>
            </div>
            <div className="text-right">
              <p className="text-sm text-bluish-grey">Issues Found</p>
              <p className="text-lg font-semibold text-salmon-pink">{cleaningReport?.issuesFound?.length || 0}</p>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm text-bluish-grey">Overall Progress</span>
            <span className="text-sm font-medium text-hazel-green">{completionPercentage}%</span>
          </div>
          <div className="w-full bg-grey-goose rounded-full h-2">
            <div
              className="bg-hazel-green h-2 rounded-full transition-all duration-300"
              style={{ width: `${completionPercentage}%` }}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Checklist Section */}
        <div className="bg-white rounded-xl p-6 shadow-sm">
          <h3 className="text-lg font-semibold text-bluish-grey mb-4">Cleaning Checklist</h3>
          <div className="space-y-3">
            {roomChecklist.map((category, index) => {
              const isExpanded = expandedCategories.includes(category.category);
              const categoryItemsChecked = category.items.filter(item => item.checked).length;
              const categoryProgress = Math.round((categoryItemsChecked / category.items.length) * 100);

              return (
                <div key={index} className="border border-grey-goose rounded-lg overflow-hidden">
                  <button
                    onClick={() => toggleCategory(category.category)}
                    className="w-full px-4 py-3 flex items-center justify-between bg-seashell hover:bg-grey-goose/20 transition-colors"
                  >
                    <div className="flex items-center gap-3">
                      {isExpanded ? (
                        <ChevronDown className="h-4 w-4 text-bluish-grey" />
                      ) : (
                        <ChevronRight className="h-4 w-4 text-bluish-grey" />
                      )}
                      <span className="font-medium text-sm">{category.category}</span>
                      <span className="text-xs text-bluish-grey">
                        ({categoryItemsChecked}/{category.items.length})
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="w-20 h-1.5 bg-grey-goose rounded-full">
                        <div
                          className="h-1.5 bg-hazel-green rounded-full transition-all duration-300"
                          style={{ width: `${categoryProgress}%` }}
                        />
                      </div>
                      <span className="text-xs text-bluish-grey">{categoryProgress}%</span>
                    </div>
                  </button>

                  {isExpanded && (
                    <div className="p-3 space-y-2">
                      {category.items.map((item, itemIndex) => (
                        <div
                          key={itemIndex}
                          className="flex items-center justify-between p-3 rounded-md bg-seashell"
                        >
                          <div className="flex items-center gap-3">
                            <input
                              type="checkbox"
                              checked={item.checked}
                              disabled
                              className="h-4 w-4 text-hazel-green border-grey-goose rounded focus:ring-hazel-green"
                            />
                            <span className="text-sm">{item.name}</span>
                          </div>
                          <span
                            className={`text-xs px-2 py-1 rounded-full ${
                              item.status === 'Good'
                                ? 'bg-[#EDF2ED] text-hazel-green'
                                : 'bg-dawn-pink text-salmon-pink'
                            }`}
                          >
                            {item.status}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {/* Issues Section */}
        <div className="bg-white rounded-xl p-6 shadow-sm">
          <div className="flex items-center gap-2 mb-6">
            <AlertTriangle className="text-salmon-pink h-5 w-5" />
            <h3 className="text-lg font-semibold text-bluish-grey">Issues & Damage Report</h3>
          </div>

          <div className="space-y-6">
            <div>
              <h4 className="text-sm font-medium text-bluish-grey mb-3">Issues Found</h4>
              <div className="flex flex-wrap gap-2">
                {cleaningReport?.issuesFound?.map((issue, index) => (
                  <span
                    key={index}
                    className="inline-block px-3 py-1.5 bg-dawn-pink text-salmon-pink rounded-full text-xs"
                  >
                    {issue}
                  </span>
                ))}
              </div>
            </div>

            <div>
              <h4 className="text-sm font-medium text-bluish-grey mb-3">Description</h4>
              <div className="p-4 rounded-lg bg-seashell min-h-[100px] text-sm whitespace-pre-wrap">
                {cleaningReport?.issuesDescription || "No issues reported"}
              </div>
            </div>

            <div className="pt-4 border-t border-grey-goose">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-xs text-bluish-grey mb-1">Cleaner</p>
                  <p className="text-hazel-green text-sm font-bold">
                    {cleaningReport?.cleaner.name} {cleaningReport?.cleaner.surname}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-bluish-grey mb-1">Date</p>
                  <p className="text-hazel-green text-sm font-bold">
                    {cleaningReport?.cleaningDate ? new Date(cleaningReport?.cleaningDate).toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "numeric"
                    }) : "No date available"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
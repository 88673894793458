import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { MuiOtpInput } from 'mui-one-time-password-input';
import ConfirmMessage from '../../components/alerts/alert-message';
import CustomInput from '../../components/custom-input';
import { resetPassword } from '../../api/auth.api';
import SubmitButton from '../../components/buttons/submit-button';

function NewPassword() {
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);

  return (
    <div className='flex items-center justify-center bg-hazel-green bg-loginBgImage bg-no-repeat bg-center bg-cover h-screen'>
      <div className='bg-white rounded-lg md:w-1/2 lg:w-1/3 shadow-md px-10 md:px-14 pt-16 pb-12'>
        <h3 className='text-hazel-green text-3xl font-bold'>Reset Password</h3>
        <Formik
          initialValues={{
            email: localStorage.getItem('email'),
            token: '',
            password: '',
            confirmPassword: ''
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string(),
            token: Yup.string()
              .required()
              .matches(/^[0-9]+$/, 'Verification code must be only digits')
              .min(6, 'Verification code must be exactly 6 digits')
              .max(6, 'Verification code must be exactly 6 digits'),
            password: Yup.string()
              .min(8, 'Password must be at least 8 characters.')
              .required('Password is required*')
              .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
                'Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character.'
              ),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Passwords should match!')
              .required('Confirm Password is required*')
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              const response = await resetPassword(values);
              if (response.status === 200) {
                setShowAlert(true);
                localStorage.removeItem('email');
              }
            } catch (err) {
              setErrors({
                submit: err.response.data
              });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            values,
            touched,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit
          }) => (
            <form onSubmit={handleSubmit}>
              <div className='mt-6'>
                <div className='mb-3'>
                  <p className='text-[#666666]'>Verification Code</p>
                  <MuiOtpInput
                    value={values.token}
                    length={6}
                    onChange={handleChange('token')}
                    onBlur={handleBlur}
                  />
                  {errors.token && touched.token && (
                    <p className='text-sm text-error'>{errors.token}</p>
                  )}
                </div>
                <CustomInput
                  type='password'
                  name='password'
                  label='Password'
                  value={values.password}
                  onChange={handleChange('password')}
                  onBlur={handleBlur}
                  showError={errors.password && touched.password}
                  errorMessage={errors.password}
                />
                <CustomInput
                  type='password'
                  name='confirmPassword'
                  label='Confirm Password'
                  value={values.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  onBlur={handleBlur}
                  showError={errors.confirmPassword && touched.confirmPassword}
                  errorMessage={errors.confirmPassword}
                />
                <hr className='mt-6 mb-4' />
                {errors.submit && <p className='text-error'>{errors.submit}</p>}
                <div className='flex justify-end mb-4'>
                  <Link to='/' className='text-hazel-green text-sm'>
                    Back to login
                  </Link>
                </div>
                <div className='flex justify-center items-center'>
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    width='w-3/5'
                    title='Reset password'
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      {showAlert && (
        <ConfirmMessage
          text='Please use your new password to login'
          onConfirm={() => {
            setShowAlert(false);
            navigate('/');
          }}
        />
      )}
    </div>
  );
}

export default NewPassword;



import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../components/loader';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import SelectInput from '../../components/select-input';
import {createDishOrder as createOrder, updateDishOrder as updateOrder } from '../../api/orders';
import { getDishSections } from '../../api/dishSection';

function OrderForm({ isEdit, order, tableId }) {
  const navigate = useNavigate();
  const [dishSections, setDishSections] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDishSectionsList = useCallback(async () => {
    try {
      const response = await getDishSections();
      const formattedDishSections = response.data.map((section) => ({
        id: section.id,
        name: section.name,
        price: section.price,
      }));
      setDishSections(formattedDishSections);
    } catch (err) {
      console.error('Failed to fetch dish sections:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getDishSectionsList();
  }, [getDishSectionsList]);

  const initialValues = {
    tableId: tableId ? parseInt(tableId, 10) : '',
    dishSectionId: '',
    quantity: '',
    totalPrice: '',
  };

  const validationSchema = Yup.object().shape({
    tableId: Yup.number().required('Table ID is required*'),
    dishSectionId: Yup.string().required('Dish Section is required*'),
    quantity: Yup.number().min(1).required('Quantity is required*'),
    totalPrice: Yup.number().min(0).required('Total Price is required*'),
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            if (isEdit) {
              const { id, ...editValues } = values;
              await updateOrder(order.id, {
                ...editValues,
                quantity: parseInt(editValues.quantity, 10), // Convert quantity to integer
              });
            } else {
              await createOrder({
                ...values,
                quantity: parseInt(values.quantity, 10), // Convert quantity to integer
              });
              navigate('/orders');
            }
            setSubmitting(false);
          } catch (err) {
            setErrors({
              submit: err?.response?.data,
            });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          // Function to update total price
          const updateTotalPrice = (dishSectionId, quantity) => {
            const selectedDishSection = dishSections.find((section) => section.id === dishSectionId);
            if (selectedDishSection) {
              const totalPrice = selectedDishSection.price * quantity;
              setFieldValue('totalPrice', totalPrice);
            }
          };

          // Handle change for dish section selection
          const handleDishSectionChange = (e) => {
            const { value } = e.target;
            setFieldValue('dishSectionId', value);
            updateTotalPrice(value, values.quantity);
          };

          // Handle change for quantity
          const handleQuantityChange = (e) => {
            const { value } = e.target;
            setFieldValue('quantity', value);
            updateTotalPrice(values.dishSectionId, value);
          };

          return (
            <form onSubmit={handleSubmit} className='px-7 py-5'>
              <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
                <CustomInput
                  type='number'
                  name='tableId'
                  label='Table ID'
                  value={values.tableId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.tableId && touched.tableId}
                  errorMessage={errors.tableId}
                  disabled
                />
                <SelectInput
                  value={values.dishSectionId}
                  label='Dish Section'
                  options={dishSections.map((section) => ({
                    id: section.id,
                    title: section.name,
                  }))}
                  onChange={handleDishSectionChange}
                  onBlur={handleBlur}
                  showError={errors.dishSectionId && touched.dishSectionId}
                  errorMessage={errors.dishSectionId}
                />
                <CustomInput
                  type='number'
                  name='quantity'
                  label='Quantity'
                  value={values.quantity}
                  onChange={handleQuantityChange}
                  onBlur={handleBlur}
                  showError={errors.quantity && touched.quantity}
                  errorMessage={errors.quantity}
                />
                <CustomInput
                  type='number'
                  name='totalPrice'
                  label='Total Price'
                  value={values.totalPrice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.totalPrice && touched.totalPrice}
                  errorMessage={errors.totalPrice}
                  disabled
                />
                {errors.submit && <p className='text-error'>{errors.submit}</p>}
              </div>
              <div className='flex justify-end items-center w-full mt-4 text-sm'>
                <CancelButton onCancel={() => navigate('/orders')} />
                <SubmitButton
                  isSubmitting={isSubmitting}
                  width='w-1/2 md:w-1/6'
                  title={`${isEdit ? 'Edit Order' : 'Create Order'}`}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default OrderForm;

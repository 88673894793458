// import React, { useState, useEffect } from 'react';
// import dayjs from 'dayjs';
// import useAuth from '../../../hooks/useAuth';
// import { checkUserHasPermission } from '../../../helpers/user-helper';
// // import { getRoomHistory as getRoomHistoryApi } from '../../../api/reservations';
// import { getAllReservationsHistory as getRoomHistoryApi } from '../../../api/reservations';
// import Loading from '../../../components/loader';
// import { Link } from 'react-router-dom';

// function RoomHistory({ roomId }) {
//   const { userPermissions, userRole } = useAuth();
//   const [loading, setLoading] = useState(true);
//   const [reservationsHistory, setReservationsHistory] = useState([]);

//   useEffect(() => {
//     getRoomHistory();
//   }, []);

//   const getRoomHistory = async () => {
//     try {
//       const response = await getRoomHistoryApi(roomId);
//       console.log(response.data);
//       setReservationsHistory(response.data);
//       setLoading(false);
//     } catch (err) {
//       setLoading(false);
//       return err;
//     }
//   };

//   if (loading) {
//     return <Loading />;
//   }

//   const renderReservation = (item) => (
//     <div className='flex justify-between items-center text-hazel-green py-2 border-b'>
//       <p>
//         {item.name} {item.surname}
//       </p>
//       <div>{`${dayjs(item.fromDate).format('DD/MM/YYYY')} - ${dayjs(
//         item.toDate
//       ).format('DD/MM/YYYY')}`}</div>
//     </div>
//   );

//   return (
//     <div>
//       {reservationsHistory.length > 0 ? (
//         <>
//           {Object.entries(reservationsHistory).map(([status, reservations]) => (
//             <div key={status}>
//               <p className='text-bluish-grey capitalize mb-3'>{status}</p>
//               {reservations.map((item) => (
//                 <div key={item.id}>
//                   {checkUserHasPermission(userRole, userPermissions, [
//                     'edit_rooms'
//                   ]) ? (
//                     <Link to={`/reservations/${item.id}`}>
//                       {renderReservation(item)}
//                     </Link>
//                   ) : (
//                     <div>{renderReservation(item)}</div>
//                   )}
//                 </div>
//               ))}
//             </div>
//           ))}
//         </>
//       ) : (
//         <p className='text-sage font-bold py-6 px-4'>
//           There is no data to show!
//         </p>
//       )}
//     </div>
//   );
// }

// export default RoomHistory;


// import React, { useState, useEffect } from 'react';
// import dayjs from 'dayjs';
// import useAuth from '../../../hooks/useAuth';
// import { checkUserHasPermission } from '../../../helpers/user-helper';
// import { getAllReservationsHistory as getRoomHistoryApi } from '../../../api/reservations';
// import Loading from '../../../components/loader';
// import { Link } from 'react-router-dom';

// function RoomHistory() {
//   const { userPermissions, userRole } = useAuth();
//   const [loading, setLoading] = useState(true);
//   const [reservationsHistory, setReservationsHistory] = useState({});

//   useEffect(() => {
//     getRoomHistory();
//   }, []);

//   const getRoomHistory = async () => {
//     try {
//       const response = await getRoomHistoryApi();
//       console.log(response.data);
//       setReservationsHistory(response.data); // Set the state with the object
//       setLoading(false);
//     } catch (err) {
//       setLoading(false);
//       console.error(err);
//     }
//   };

//   if (loading) {
//     return <Loading />;
//   }

//   const renderReservation = (item) => (
//     <div className='flex justify-between items-center text-hazel-green py-2 border-b'>
//       <p>
//         {item.name} {item.surname}
//       </p>
//       <div>{`${dayjs(item.fromDate).format('DD/MM/YYYY')} - ${dayjs(
//         item.toDate
//       ).format('DD/MM/YYYY')}`}</div>
//     </div>
//   );

//   return (
//     <div>
//       {Object.keys(reservationsHistory).length > 0 ? (
//         <>
//           {Object.entries(reservationsHistory).map(([reservations, id]) => (
//             <div key={id}>
//               <p className='text-bluish-grey capitalize mb-3'>{id}</p>
//               {Array.isArray(reservations) ? (
//                 reservations.map((item) => (
//                   <div key={item.id}>
//                     {/* {checkUserHasPermission(userRole, userPermissions, [
//                       'edit_rooms'
//                     ]) ? ( */}
//                       <Link to={`/reservations/${item.id}`}>
//                         {renderReservation(item)}
//                       </Link>
//                     {/* ) : ( */}
//                       <div>{renderReservation(item)}</div>
//                     {/* )} */}
//                   </div>
//                 ))
//               ) : (
//                 <p>No reservations found for this status</p>
//               )}
//             </div>
//           ))}
//         </>
//       ) : (
//         <p className='text-sage font-bold py-6 px-4'>
//           There is no data to show!
//         </p>
//       )}
//     </div>
//   );
// }

// export default RoomHistory;




// import React, { useState, useEffect } from 'react';
// import dayjs from 'dayjs';
// import useAuth from '../../../hooks/useAuth';
// import { checkUserHasPermission } from '../../../helpers/user-helper';
// import { getAllReservationsHistory as getRoomHistoryApi } from '../../../api/reservations';
// import Loading from '../../../components/loader';
// import { Link } from 'react-router-dom';

// function RoomHistory() {
//   const { userPermissions, userRole } = useAuth();
//   const [loading, setLoading] = useState(true);
//   const [reservations, setReservations] = useState([]);

//   useEffect(() => {
//     getRoomHistory();
//   }, []);

//   const getRoomHistory = async () => {
//     try {
//       const response = await getRoomHistoryApi();
//       console.log(response.data);
//       setReservations(response.data); // Set the state with the array of reservations
//       setLoading(false);
//     } catch (err) {
//       setLoading(false);
//       console.error(err);
//     }
//   };

//   if (loading) {
//     return <Loading />;
//   }

//   const renderReservation = (item) => (
//     <div className='flex justify-between items-center text-hazel-green py-2 border-b'>
//       <p>
//         {item.name} {item.surname}
//       </p>
//       <div>{`${dayjs(item.fromDate).format('DD/MM/YYYY')} - ${dayjs(
//         item.toDate
//       ).format('DD/MM/YYYY')}`}</div>
//     </div>
//   );

//   return (
//     <div>
//       {reservations.length > 0 ? (
//         reservations.map((item) => (
//           <div key={item.id}>
//             {checkUserHasPermission(userRole, userPermissions, ['edit_rooms']) ? (
//               <Link to={`/reservations/${item.id}`}>
//                 {renderReservation(item)}
//               </Link>
//             ) : (
//               <div>{renderReservation(item)}</div>
//             )}
//           </div>
//         ))
//       ) : (
//         <p className='text-sage font-bold py-6 px-4'>
//           There is no data to show!
//         </p>
//       )}
//     </div>
//   );
// }

// export default RoomHistory;


import React, { useState, useEffect } from 'react';
import { getAllReservationsHistory } from '../../../api/hotelInterface';
import Loading from '../../../components/loader';
import dayjs from 'dayjs';

function RoomHistory() {
  const [loading, setLoading] = useState(true);
  const [reservations, setReservations] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    fetchReservations(page, limit);
  }, [page]);

  const fetchReservations = async (page, limit) => {
    try {
      setLoading(true);
      const response = await getAllReservationsHistory(page, limit);
      console.log("API response:", response); // Log the whole response
      console.log("Response data:", response.data); // Log response data specifically

      const reservationsList = response.data?.reservations?.reservations || []; // Access nested reservations array
      const total = response.data?.totalCount || 0;

      setReservations(reservationsList);
      setTotalCount(total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleFirstPage = () => setPage(1);
  const handlePreviousPage = () => setPage((prevPage) => Math.max(prevPage - 1, 1));
  const handleNextPage = () => setPage((prevPage) => prevPage + 1);
  const handleLastPage = () => setPage(Math.ceil(totalCount / limit));

  if (loading) {
    return <Loading />;
  }

  const renderReservation = (item) => (
    <tr key={item.id} className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600'>
      <td className='px-6 py-4 text-xs'>{item.id}</td>
      <td className='px-6 py-4'>{dayjs(item.fromDate).format('MMM DD, YYYY')}</td>
      <td className='px-6 py-4'>{dayjs(item.toDate).format('MMM DD, YYYY')}</td>
      <td className='px-6 py-4'>{item.title} {item.name} {item.surname}</td>
      <td className='px-6 py-4'>{item.email}</td>
      <td className='px-6 py-4'>{item.phoneNumber}</td>
      <td className='px-6 py-4'>{item.totalPrice}</td>
      <td className='px-6 py-4'>{item.status}</td>
      <td className='px-6 py-4 text-xs whitespace-nowrap'>{new Date(item.createdAt).toLocaleString()}</td>
    </tr>
  );

  return (
    <div className="px-6 py-3 relative bg-slate-100 min-h-screen w-full flex flex-col items-center justify-center gap-6">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">ID</th>
              <th scope="col" className="px-6 py-3">From Date</th>
              <th scope="col" className="px-6 py-3">To Date</th>
              <th scope="col" className="px-6 py-3">Name</th>
              <th scope="col" className="px-6 py-3">Email</th>
              <th scope="col" className="px-6 py-3">Phone Number</th>
              <th scope="col" className="px-6 py-3">Total Price</th>
              <th scope="col" className="px-6 py-3">Status</th>
              <th scope="col" className="px-6 py-3">Created At</th>
            </tr>
          </thead>
          <tbody>
            {reservations.length > 0 ? (
              reservations.map(renderReservation)
            ) : (
              <tr>
                <td colSpan="9" className="py-2 px-4 border-b text-center text-gray-500">No reservations found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col items-center z-10 mt-4">
        <div className="inline-flex space-x-4 mt-2 xs:mt-0">
          <button onClick={handleFirstPage} disabled={page === 1} className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900">First</button>
          <button onClick={handlePreviousPage} disabled={page === 1} className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900">Prev</button>
          <span className="flex items-center justify-center px-4 h-10 text-base font-medium text-gray-900">Page {page} of {Math.ceil(totalCount / limit)}</span>
          <button onClick={handleNextPage} disabled={page >= Math.ceil(totalCount / limit)} className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900">Next</button>
          <button onClick={handleLastPage} disabled={page >= Math.ceil(totalCount / limit)} className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-e hover:bg-gray-900">Last</button>
        </div>
      </div>
    </div>
  );
}

export default RoomHistory;

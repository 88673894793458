
// export default BarOrderForm;
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import Loading from '../../components/loader';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import SelectInput from '../../components/select-input';
import { createBarOrder, updateBarOrder } from '../../api/barProduct';
import { getBars } from '../../api/bar';
import { getBarProducts } from '../../api/barProduct';
import { getAllEnvironments } from '../../api/restaurantInterface';
import { getTablesByEnvironmentId } from '../../api/restaurantInterface';

function BarOrderForm({ isEdit, barOrder, tableId }) {
  const navigate = useNavigate();
  const [barProducts, setBarProducts] = useState([]);
  const [bars, setBars] = useState([]);
  const [environments, setEnvironments] = useState([]);
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(true);

  const getBarProductsList = useCallback(async () => {
    try {
      const response = await getBarProducts();
      const formattedBarProducts = response.data.map((product) => ({
        id: product.id,
        name: product.name,
        price: product.price,
      }));
      setBarProducts(formattedBarProducts);
    } catch (err) {
      console.error('Failed to fetch bar products:', err);
    }
  }, []);

  const getBarsList = useCallback(async () => {
    try {
      const response = await getBars();
      const formattedBars = response.data.map((bar) => ({
        id: bar.id,
        name: bar.name,
      }));
      setBars(formattedBars);
    } catch (err) {
      console.error('Failed to fetch bars:', err);
    }
  }, []);

  const getEnvironments = useCallback(async () => {
    try {
      const response = await getAllEnvironments();
      const formattedEnvironments = response.data.map((env) => ({
        id: env.id,
        name: env.name,
      }));
      setEnvironments(formattedEnvironments);
    } catch (err) {
      console.error('Failed to fetch environments:', err);
    }
  }, []);

  const fetchTables = useCallback(async (environmentId) => {
    try {
      if (!environmentId) {
        setTables([]);
        return;
      }
      const response = await getTablesByEnvironmentId(environmentId);
      setTables(response.data);
    } catch (err) {
      console.error('Failed to fetch tables:', err);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([
          getBarProductsList(),
          getBarsList(),
          getEnvironments()
        ]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [getBarProductsList, getBarsList, getEnvironments]);

  const initialValues = {
    tableId: tableId ? parseInt(tableId, 10) : '',
    barId: '',
    environmentId: '', 
    barOrderProducts: [
      {
        barProductId: '',
        quantity: '',
      }
    ],
    totalPrice: 0,
  };

  const validationSchema = Yup.object().shape({
    tableId: Yup.number().required('Table ID is required*'),
    barId: Yup.string().required('Bar ID is required*'),
    environmentId: Yup.string().required('Environment is required*'),
    barOrderProducts: Yup.array().of(
      Yup.object().shape({
        barProductId: Yup.string().required('Bar Product is required*'),
        quantity: Yup.number().min(1).required('Quantity is required*'),
      })
    ),
    totalPrice: Yup.number().min(0).required('Total Price is required*'),
  });

  if (loading) {
    return <Loading />;
  }

  const calculateTotalPrice = (barOrderProducts) => {
    return barOrderProducts.reduce((sum, item) => {
      const product = barProducts.find((p) => p.id === item.barProductId);
      if (product) {
        return sum + (product.price * (parseInt(item.quantity, 10) || 0));
      }
      return sum;
    }, 0);
  };

  return (
    <div className='border border-gray-300 rounded-xl shadow-lg mt-5 p-6 bg-white'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            if (isEdit) {
              const { id, ...editValues } = values;
              await updateBarOrder(barOrder.id, editValues);
            } else {
              await createBarOrder(values);
              navigate('/barProducts/orders-list');
            }
            setSubmitting(false);
          } catch (err) {
            setErrors({
              submit: err?.response?.data,
            });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          const handleFieldChange = (index, field, value) => {
            const updatedOrderProducts = [...values.barOrderProducts];
            updatedOrderProducts[index][field] = value;
            setFieldValue('barOrderProducts', updatedOrderProducts);
            const total = calculateTotalPrice(updatedOrderProducts);
            setFieldValue('totalPrice', total);
          };

          return (
            <form onSubmit={handleSubmit}>
              <div className='grid md:grid-cols-2 gap-6'>
                <SelectInput
                  name='environmentId'
                  label='Environment'
                  value={values.environmentId}
                  options={environments.map((env) => ({
                    id: env.id,
                    title: env.name,
                  }))}
                  onChange={(e) => {
                    const environmentId = e.target.value;
                    setFieldValue('environmentId', environmentId);
                    fetchTables(environmentId);
                  }}
                  onBlur={handleBlur}
                  showError={errors.environmentId && touched.environmentId}
                  errorMessage={errors.environmentId}
                  className='col-span-1'
                />
                <SelectInput
                  name='tableId'
                  label='Table ID'
                  value={values.tableId}
                  options={tables.map((table) => ({
                    id: table.id,
                    title: `Table ${table.number}`,
                  }))}
                  onChange={(e) => setFieldValue('tableId', e.target.value)}
                  onBlur={handleBlur}
                  showError={errors.tableId && touched.tableId}
                  errorMessage={errors.tableId}
                  disabled={!values.environmentId}
                  className='col-span-1'
                />
                <SelectInput
                  name='barId'
                  label='Bar'
                  value={values.barId}
                  options={bars.map((bar) => ({
                    id: bar.id,
                    title: bar.name,
                  }))}
                  onChange={(e) => setFieldValue('barId', e.target.value)}
                  onBlur={handleBlur}
                  showError={errors.barId && touched.barId}
                  errorMessage={errors.barId}
                  className='col-span-2'
                />
                
                <FieldArray
                  name="barOrderProducts"
                  render={arrayHelpers => (
                    <div className='col-span-2'>
                      {values.barOrderProducts.map((product, index) => (
                        <div key={index} className='flex flex-col md:flex-row items-center justify-between gap-6 p-4 mb-4'>
                          <div className='flex-1'>
                            <SelectInput
                              name={`barOrderProducts[${index}].barProductId`}
                              value={product.barProductId}
                              label={`Bar Product ${index + 1}`}
                              options={barProducts.map((p) => ({
                                id: p.id,
                                title: p.name,
                              }))}
                              onChange={(e) => handleFieldChange(index, 'barProductId', e.target.value)}
                              onBlur={handleBlur}
                              showError={errors.barOrderProducts?.[index]?.barProductId && touched.barOrderProducts?.[index]?.barProductId}
                              errorMessage={errors.barOrderProducts?.[index]?.barProductId}
                            />
                          </div>
                          <div className='flex-1'>
                            <CustomInput
                              type='number'
                              name={`barOrderProducts[${index}].quantity`}
                              label='Quantity'
                              value={product.quantity || ''}
                              onChange={(e) => handleFieldChange(index, 'quantity', e.target.value)}
                              onBlur={handleBlur}
                              showError={errors.barOrderProducts?.[index]?.quantity && touched.barOrderProducts?.[index]?.quantity}
                              errorMessage={errors.barOrderProducts?.[index]?.quantity}
                            />
                          </div>
                          <button
                            type='button'
                            className='bg-red-500 text-white px-3 py-1 rounded-md'
                            onClick={() => {
                              arrayHelpers.remove(index);
                              const updatedProducts = values.barOrderProducts.filter((_, i) => i !== index);
                              const total = calculateTotalPrice(updatedProducts);
                              setFieldValue('barOrderProducts', updatedProducts);
                              setFieldValue('totalPrice', total);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        type='button'
                        onClick={() => {
                          arrayHelpers.push({ barProductId: '', quantity: '' });
                          const updatedProducts = [...values.barOrderProducts, { barProductId: '', quantity: '' }];
                          const total = calculateTotalPrice(updatedProducts);
                          setFieldValue('barOrderProducts', updatedProducts);
                          setFieldValue('totalPrice', total);
                        }}
                        className='bg-blue-500 text-white px-3 py-1 rounded-md'
                      >
                        Add Product
                      </button>
                    </div>
                  )}
                />
                
                <div className='col-span-2 mt-4'>
                  <CustomInput
                    type='number'
                    name='totalPrice'
                    label='Total Price'
                    value={values.totalPrice || 0}
                    readOnly
                  />
                </div>
              </div>

              <div className='flex justify-end items-center w-full mt-6 space-x-4'>
                <CancelButton onCancel={() => navigate('/bar-orders')} />
                <SubmitButton
                  isSubmitting={isSubmitting}
                  width='w-1/2 md:w-1/6'
                  title={`${isEdit ? 'Edit Bar Order' : 'Create Bar Order'}`}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default BarOrderForm;




// import React, { useState, useEffect, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Formik, FieldArray } from 'formik';
// import * as Yup from 'yup';
// import Loading from '../../components/loader';
// import { SubmitButton, CancelButton } from '../../components/buttons';
// import CustomInput from '../../components/custom-input';
// import SelectInput from '../../components/select-input';
// import { createBarOrder, updateBarOrder } from '../../api/barProduct';
// import { getBars } from '../../api/bar';
// import { getBarProducts } from '../../api/barProduct';
// import { getAllEnvironments } from '../../api/restaurantInterface';
// import { getTablesByEnvironmentId } from '../../api/restaurantInterface';

// function BarOrderForm({ isEdit, barOrder, tableId }) {
//   const navigate = useNavigate();
//   const [barProducts, setBarProducts] = useState([]);
//   const [bars, setBars] = useState([]);
//   const [environments, setEnvironments] = useState([]);
//   const [tables, setTables] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const getBarProductsList = useCallback(async () => {
//     try {
//       const response = await getBarProducts();
//       const formattedBarProducts = response.data.map((product) => ({
//         id: product.id,
//         name: product.name,
//         price: product.price,
//       }));
//       setBarProducts(formattedBarProducts);
//     } catch (err) {
//       console.error('Failed to fetch bar products:', err);
//     }
//   }, []);

//   const getBarsList = useCallback(async () => {
//     try {
//       const response = await getBars();
//       const formattedBars = response.data.map((bar) => ({
//         id: bar.id,
//         name: bar.name,
//       }));
//       setBars(formattedBars);
//     } catch (err) {
//       console.error('Failed to fetch bars:', err);
//     }
//   }, []);

//   const getEnvironments = useCallback(async () => {
//     try {
//       const response = await getAllEnvironments();
//       const formattedEnvironments = response.data.map((env) => ({
//         id: env.id,
//         name: env.name,
//       }));
//       setEnvironments(formattedEnvironments);
//     } catch (err) {
//       console.error('Failed to fetch environments:', err);
//     }
//   }, []);

//   const fetchTables = useCallback(async (environmentId) => {
//     try {
//       if (!environmentId) {
//         setTables([]);
//         return;
//       }
//       const response = await getTablesByEnvironmentId(environmentId);
//       setTables(response.data);
//     } catch (err) {
//       console.error('Failed to fetch tables:', err);
//     }
//   }, []);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setLoading(true);
//         await Promise.all([
//           getBarProductsList(),
//           getBarsList(),
//           getEnvironments()
//         ]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [getBarProductsList, getBarsList, getEnvironments]);

//   const initialValues = {
//     tableId: tableId ? parseInt(tableId, 10) : '',
//     barId: '',
//     environmentId: '', // New field
//     barOrderProducts: [
//       {
//         barProductId: '',
//         quantity: '',
//       }
//     ],
//     totalPrice: '',
//   };

//   const validationSchema = Yup.object().shape({
//     tableId: Yup.number().required('Table ID is required*'),
//     barId: Yup.string().required('Bar ID is required*'),
//     environmentId: Yup.string().required('Environment is required*'), // New validation
//     barOrderProducts: Yup.array().of(
//       Yup.object().shape({
//         barProductId: Yup.string().required('Bar Product is required*'),
//         quantity: Yup.number().min(1).required('Quantity is required*'),
//       })
//     ),
//     totalPrice: Yup.number().min(0).required('Total Price is required*'),
//   });

//   if (loading) {
//     return <Loading />;
//   }

//   return (
//     <div className='border border-gray-300 rounded-xl shadow-lg mt-5 p-6 bg-white'>
//       <Formik
//         initialValues={initialValues}
//         validationSchema={validationSchema}
//         onSubmit={async (values, { setErrors, setSubmitting }) => {
//           try {
//             if (isEdit) {
//               const { id, ...editValues } = values;
//               await updateBarOrder(barOrder.id, editValues);
//             } else {
//               await createBarOrder(values);
//               navigate('/barProducts/orders-list');
//             }
//             setSubmitting(false);
//           } catch (err) {
//             setErrors({
//               submit: err?.response?.data,
//             });
//             setSubmitting(false);
//           }
//         }}
//       >
//         {({
//           errors,
//           values,
//           touched,
//           isSubmitting,
//           handleBlur,
//           handleChange,
//           handleSubmit,
//           setFieldValue,
//         }) => {
//           const updateTotalPrice = () => {
//             const total = values.barOrderProducts.reduce((sum, item) => {
//               const product = barProducts.find((p) => p.id === item.barProductId);
//               return product ? sum + (product.price * item.quantity) : sum;
//             }, 0);
//             setFieldValue('totalPrice', total);
//           };

//           const handleBarProductChange = (index, e) => {
//             const { name, value } = e.target;
//             const updatedOrderProducts = [...values.barOrderProducts];
//             updatedOrderProducts[index][name] = value;
//             setFieldValue('barOrderProducts', updatedOrderProducts);
//             updateTotalPrice();
//           };

//           const addBarProduct = () => {
//             setFieldValue('barOrderProducts', [
//               ...values.barOrderProducts,
//               { barProductId: '', quantity: '' },
//             ]);
//           };

//           const removeBarProduct = (index) => {
//             const updatedOrderProducts = [...values.barOrderProducts];
//             updatedOrderProducts.splice(index, 1);
//             setFieldValue('barOrderProducts', updatedOrderProducts);
//             updateTotalPrice();
//           };

//           return (
//             <form onSubmit={handleSubmit}>
//               <div className='grid md:grid-cols-2 gap-6'>
//                 <SelectInput
//                   name='environmentId'
//                   label='Environment'
//                   value={values.environmentId}
//                   options={environments.map((env) => ({
//                     id: env.id,
//                     title: env.name,
//                   }))}
//                   onChange={(e) => {
//                     const environmentId = e.target.value;
//                     setFieldValue('environmentId', environmentId);
//                     fetchTables(environmentId);
//                   }}
//                   onBlur={handleBlur}
//                   showError={errors.environmentId && touched.environmentId}
//                   errorMessage={errors.environmentId}
//                   className='col-span-1'
//                 />
//                 <SelectInput
//                   name='tableId'
//                   label='Table ID'
//                   value={values.tableId}
//                   options={tables.map((table) => ({
//                     id: table.id,
//                     title: `Table ${table.number}`,
//                   }))}
//                   onChange={(e) => setFieldValue('tableId', e.target.value)}
//                   onBlur={handleBlur}
//                   showError={errors.tableId && touched.tableId}
//                   errorMessage={errors.tableId}
//                   disabled={!values.environmentId}
//                   className='col-span-1'
//                 />
//                 <SelectInput
//                   name='barId'
//                   label='Bar'
//                   value={values.barId}
//                   options={bars.map((bar) => ({
//                     id: bar.id,
//                     title: bar.name,
//                   }))}
//                   onChange={(e) => setFieldValue('barId', e.target.value)}
//                   onBlur={handleBlur}
//                   showError={errors.barId && touched.barId}
//                   errorMessage={errors.barId}
//                   className='col-span-2'
//                 />
                
//                 <FieldArray
//                   name="barOrderProducts"
//                   render={arrayHelpers => (
//                     <div className='col-span-2'>
//                       {values.barOrderProducts.map((product, index) => (
//                         <div key={index} className='flex flex-col md:flex-row items-center justify-between gap-6 p-4 mb-4'>
//                           <div className='flex-1'>
//                             <SelectInput
//                               name={`barOrderProducts[${index}].barProductId`}
//                               value={product.barProductId}
//                               label={`Bar Product ${index + 1}`}
//                               options={barProducts.map((p) => ({
//                                 id: p.id,
//                                 title: p.name,
//                               }))}
//                               onChange={(e) => {
//                                 const value = e.target.value;
//                                 setFieldValue(`barOrderProducts[${index}].barProductId`, value);
//                                 updateTotalPrice(); // Make sure this recalculates after updating the value
//                               }}
//                               onBlur={handleBlur}
//                               showError={errors.barOrderProducts?.[index]?.barProductId && touched.barOrderProducts?.[index]?.barProductId}
//                               errorMessage={errors.barOrderProducts?.[index]?.barProductId}
//                             />
//                           </div>
//                           <div className='flex-1'>
//                             <CustomInput
//                               type='number'
//                               name={`barOrderProducts[${index}].quantity`}
//                               label={`Quantity ${index + 1}`}
//                               value={product.quantity}
//                               onChange={(e) => {
//                                 const value = e.target.value;
//                                 setFieldValue(`barOrderProducts[${index}].quantity`, value);
//                                 updateTotalPrice(); // Make sure this recalculates after updating the value
//                               }}
//                               onBlur={handleBlur}
//                               showError={errors.barOrderProducts?.[index]?.quantity && touched.barOrderProducts?.[index]?.quantity}
//                               errorMessage={errors.barOrderProducts?.[index]?.quantity}
//                             />
//                           </div>
//                           <div className='flex-[0.5] flex items-center justify-center self-center'>
//                             <button
//                               type="button"
//                               onClick={() => removeBarProduct(index)}
//                               className='leading-tight py-4 px-3 text-sm font-medium bg-red-600 text-white rounded-md hover:bg-red-700 transition-all'
//                             >
//                               Remove Product
//                             </button>
//                           </div>
//                         </div>
//                       ))}
//                       <button
//                         type="button"
//                         onClick={addBarProduct}
//                         className='text-blue-500 hover:text-blue-700 mt-4'
//                       >
//                         Add another product
//                       </button>
//                     </div>
//                   )}
//                 />
//                 <CustomInput
//                   type='number'
//                   name='totalPrice'
//                   label='Total Price'
//                   value={values.totalPrice}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   showError={errors.totalPrice && touched.totalPrice}
//                   errorMessage={errors.totalPrice}
//                   disabled
//                   className='col-span-2 mt-4'
//                 />
//                 {errors.submit && <p className='text-red-500 col-span-2'>{errors.submit}</p>}
//               </div>
              // <div className='flex justify-end items-center w-full mt-6 space-x-4'>
              //   <CancelButton onCancel={() => navigate('/bar-orders')} />
              //   <SubmitButton
              //     isSubmitting={isSubmitting}
              //     width='w-1/2 md:w-1/6'
              //     title={`${isEdit ? 'Edit Bar Order' : 'Create Bar Order'}`}
              //   />
              // </div>
//             </form>
//           );
//         }}
//       </Formik>
//     </div>
//   );
// }

// export default BarOrderForm;




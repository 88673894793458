import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carouselData } from "../../../components/data/Data";

import { Link } from "react-router-dom";

import {
  IconArrowRight,
  IconArrowLeft
} from '@tabler/icons-react';


export default function Carousel() {
  const sliderRef = useRef(null);

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
//   return (
//     <>
//       <div className="container-fluid p-0 mb-5">
//         <div
//           id="header-carousel"
//           className="carousel slide"
//           data-bs-ride="carousel"
//         >
//           <div className="carousel-inner">
//             <Slider ref={sliderRef} {...settings}>
//               {carouselData.map((val, index) => (
//                 <div className="carousel-item" key={index}>
//                   <img className="w-100" src={val.img} alt="Image" />
//                   <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
//                     <div className="p-3" style={{ maxWidth: "700px" }}>
//                       <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown">
//                         {val.subtitle}
//                       </h6>
//                       <h1 className="display-3 text-white mb-4 animated slideInDown">
//                         {val.title}
//                       </h1>
//                       <a
//                         href=""
//                         className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
//                       >
//                         {val.btn1}
//                       </a>
//                       <a
//                         href=""
//                         className="btn btn-light py-md-3 px-md-5 animated slideInRight"
//                       >
//                         {val.btn2}
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//           <button
//             className="carousel-control-prev"
//             type="button"
//             onClick={previous}
//           >
//             <span
//               className="carousel-control-prev-icon"
//               aria-hidden="true"
//             ></span>
//             <span className="visually-hidden">Previous</span>
//           </button>
//           <button
//             className="carousel-control-next"
//             type="button"
//             onClick={next}
//           >
//             <span
//               className="carousel-control-next-icon"
//               aria-hidden="true"
//             ></span>
//             <span className="visually-hidden">Next</span>
//           </button>
//         </div>
//       </div>
//     </>
//   );
// }

return (
  <div className="relative overflow-hidden">

    {/* <div className="absolute inset-0 bg-black bg-opacity-50 z-10 pointer-events-none"></div> */}


    <div className="relative w-full">
      <Slider ref={sliderRef} {...settings} className="relative">
        {carouselData.map((val, index) => (
          <div className="relative" key={index}>
            {/* <img className="w-full h-auto object-cover" src={val.img} alt="Image" /> */}

            <div className="relative w-full h-full">
              <img className="w-full h-screen object-cover" src={val.img} alt="Image" />
              <div className="absolute inset-0 bg-black bg-opacity-50 z-10 pointer-events-none"></div>
            </div>
            
            <div className="z-50 absolute inset-0 flex flex-col items-center justify-center text-center gap-4 p-6 md:p-12">
              <div className="max-w-2xl mx-auto">
                <h6 className="text-white text-sm uppercase mb-2">{val.subtitle}</h6>
                <h1 className="text-white text-3xl md:text-5xl font-bold mb-4">{val.title}</h1>
                <div className="flex justify-center gap-4 my-4 mt-8">
                  <Link to="/hotel-interface/room-history">
                      <a href="" className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300">{val.btn1}</a>
                  </Link>
                  <Link to="/hotel-interface/add-new-reservation">
                      <a href="" className="bg-white text-gray-800 py-2 px-4 rounded-md hover:bg-gray-100 transition duration-300">{val.btn2}</a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <button
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-black hover:bg-opacity-70 transition duration-300"
        type="button"
        onClick={previous}
      >
        <span className="material-icons">
            <IconArrowLeft size={23} />
        </span>
        <span className="sr-only">Previous</span>
      </button>
      <button
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-black hover:bg-opacity-70 transition duration-300"
        type="button"
        onClick={next}
      >
        <span className="material-icons">
            <IconArrowRight size={23} />
        </span>
        <span className="sr-only">Next</span>
      </button>
    </div>
  </div>
);
};

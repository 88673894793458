import React from 'react';
import { ChevronLeft, ChevronRight, Search } from 'lucide-react';

interface Column {
  key: string;
  label: string;
}

interface BookingTableProps {
  columns: Column[];
  data: any[];
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
}

export const BookingTable: React.FC<BookingTableProps> = ({
  columns,
  data,
  onPageChange,
  currentPage,
  totalPages,
}) => {
  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden border border-grey-goose/30">
      {/* Table Controls */}
      <div className="px-6 py-4 bg-seashell border-b border-grey-goose/30">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <select
              className="text-xs px-3 py-1.5 border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green bg-white"
              defaultValue={10}
            >
              <option value={10}>10 rows</option>
              <option value={25}>25 rows</option>
              <option value={50}>50 rows</option>
              <option value={100}>100 rows</option>
            </select>
            <span className="text-xs text-bluish-grey">entries per page</span>
          </div>
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              className="text-xs pl-8 pr-4 py-1.5 border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green w-48"
            />
            <Search size={14} className="absolute left-2.5 top-1/2 -translate-y-1/2 text-bluish-grey" />
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto custom-scrollbar2">
        <table className="min-w-full divide-y divide-grey-goose/30">
          <thead>
            <tr className="bg-seashell">
              {columns.map((column) => (
                <th
                  key={column.key}
                  className="px-4 py-3 text-left text-xs font-medium text-bluish-grey tracking-wider whitespace-nowrap border-x first:border-l-0 last:border-r-0 border-grey-goose/30"
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-grey-goose/30">
            {data.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="px-4 py-8 text-center text-sm text-bluish-grey bg-tealish-blue/20"
                >
                  No data available
                </td>
              </tr>
            ) : (
              data.map((row, index) => (
                <tr
                  key={index}
                  className="hover:bg-tealish-blue/30 transition-colors duration-150"
                >
                  {columns.map((column) => (
                    <td
                      key={column.key}
                      className="px-4 py-2.5 text-xs text-bluish-grey whitespace-nowrap border-x first:border-l-0 last:border-r-0 border-grey-goose/30"
                    >
                      {column.key === 'status' ? (
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize
                          ${row[column.key]?.toLowerCase().includes('check') ? 'bg-sage/20 text-hazel-green' :
                          row[column.key]?.toLowerCase().includes('cancel') ? 'bg-salmon-pink/20 text-watermelon' :
                          'bg-heather/20 text-bluish-grey'}`}>
                          {row[column.key]}
                        </span>
                      ) : (
                        row[column.key]
                      )}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="px-6 py-3 bg-seashell border-t border-grey-goose/30 flex items-center justify-between">
        <div className="text-xs text-bluish-grey">
          Showing {data.length ? (currentPage - 1) * 10 + 1 : 0} to {Math.min(currentPage * 10, data.length)} of {data.length} entries
        </div>
        <div className="flex items-center space-x-1">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="p-1.5 rounded-md hover:bg-grey-goose/20 disabled:opacity-50 disabled:hover:bg-transparent transition-colors duration-150"
          >
            <ChevronLeft size={16} className="text-bluish-grey" />
          </button>
          {[...Array(totalPages)].map((_, idx) => (
            <button
              key={idx}
              onClick={() => onPageChange(idx + 1)}
              className={`px-3 py-1 text-xs rounded-md transition-colors duration-150
                ${currentPage === idx + 1
                  ? 'bg-hazel-green text-white'
                  : 'text-bluish-grey hover:bg-grey-goose/20'
                }`}
            >
              {idx + 1}
            </button>
          ))}
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="p-1.5 rounded-md hover:bg-grey-goose/20 disabled:opacity-50 disabled:hover:bg-transparent transition-colors duration-150"
          >
            <ChevronRight size={16} className="text-bluish-grey" />
          </button>
        </div>
      </div>
    </div>
  );
};

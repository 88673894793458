import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import Loading from '../../../components/loader';
import CustomTable from '../../../components/table-components/table';
import { getAllConferenceRoomReservations } from '../../../api/conferenceRoomReservation';

function ConferenceRoomReservationsList() {
  const [reservationList, setReservationList] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const getReservationList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getAllConferenceRoomReservations(currentPage + 1, rowsPerPage, searchText);
      console.log("Response data of the reservation", response.data);
      setReservationList(response.data || []);
      setTotalCount(response.data.count || 0);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, searchText]);

  useEffect(() => {
    getReservationList();
  }, [getReservationList]);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className='p-10'>
      <CustomTable
        rows={reservationList}
        columns={[
          {
            title: 'Reservation ID',
            renderValue: (item) => item?.id || 'N/A',
          },
          {
            title: 'Conference Room',
            renderValue: (item) => item?.conferenceRoom?.name || 'N/A',
          },
          {
            title: 'Menu',
            renderValue: (item) => item?.menu?.name || 'N/A',
          },
          {
            title: 'Menu Price',
            renderValue: (item) => item?.menu?.price ? `$${item.menu.price.toFixed(2)}` : 'N/A',
          },
          {
            title: 'Start Date',
            renderValue: (item) => item?.startDate ? dayjs(item.startDate).format('DD/MM/YYYY HH:mm') : 'N/A',
          },
          {
            title: 'End Date',
            renderValue: (item) => item?.endDate ? dayjs(item.endDate).format('DD/MM/YYYY HH:mm') : 'N/A',
          },
          {
            title: 'Number of Guests',
            renderValue: (item) => item?.numberOfGuests || 'N/A',
          },
          {
            title: 'Email',
            renderValue: (item) => item?.email || 'N/A',
          },
          {
            title: 'Phone Number',
            renderValue: (item) => item?.phoneNumber || 'N/A',
          },
          {
            title: 'Room Price',
            renderValue: (item) => item?.roomPrice ? `$${item.roomPrice.toFixed(2)}` : 'N/A',
          },
          {
            title: 'Discount',
            renderValue: (item) => item?.discount ? `${item.discount}%` : 'N/A',
          },
          {
            title: 'Deposit',
            renderValue: (item) => item?.deposit ? `$${item.deposit.toFixed(2)}` : 'N/A',
          },
          {
            title: 'Total Price',
            renderValue: (item) => item?.totalPrice ? `$${item.totalPrice.toFixed(2)}` : 'N/A',
          },
          {
            title: 'Paid Price',
            renderValue: (item) => item?.paidPrice ? `$${item.paidPrice.toFixed(2)}` : 'N/A',
          },
          {
            title: 'Payment Type',
            renderValue: (item) => item?.paymentType || 'N/A',
          },
          {
            title: 'Status',
            renderValue: (item) => item?.status || 'N/A',
          },
          {
            title: 'Products',
            renderValue: (item) => item?.products?.length > 0 ? item.products.map(p => `${p.name} (${p.quantity})`).join(', ') : 'None',
          },
          {
            title: 'Menu Orders',
            renderValue: (item) => item?.menuOrders?.length > 0 ? item.menuOrders.map(o => `${o.name} (${o.quantity})`).join(', ') : 'None',
          },
        ]}
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={false}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </div>
  );
}

export default ConferenceRoomReservationsList;

import React from 'react';
import { Menu } from 'lucide-react';

interface PageHeaderProps {
  title: string;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
    return (
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center space-x-4">
            <button className="text-bluish-grey hover:text-hazel-green">
              <Menu size={24} />
            </button>
            <h1 className="text-2xl font-semibold text-bluish-grey">{title}</h1>
          </div>
        </div>
      </div>
    );
  };
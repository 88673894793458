import React, { useState } from 'react';
import { HelpCircle, ChevronDown } from 'lucide-react';

const IncomeExpenseChart = () => {
  const [view, setView] = useState('Accrual');
  
  return (
    <div className="card p-6">
      <div className="flex justify-between items-center mb-8">
        <div className="card-header">
          <h2 className="text-xl">Income and Expense</h2>
          <HelpCircle className="help-icon" />
        </div>
        <div className="flex items-center gap-4">
          <div className="flex rounded-md overflow-hidden border border-mercury">
            <button
              className={`px-4 py-1.5 text-sm font-medium transition-colors ${
                view === 'Accrual' 
                  ? 'bg-tealish-blue text-hazel-green' 
                  : 'bg-white text-bluish-grey hover:text-hazel-green'
              }`}
              onClick={() => setView('Accrual')}
            >
              Accrual
            </button>
            <button
              className={`px-4 py-1.5 text-sm font-medium transition-colors ${
                view === 'Cash' 
                  ? 'bg-tealish-blue text-hazel-green' 
                  : 'bg-white text-bluish-grey hover:text-hazel-green'
              }`}
              onClick={() => setView('Cash')}
            >
              Cash
            </button>
          </div>
          <button className="dropdown-button">
            This Fiscal Year
            <ChevronDown className="w-4 h-4" />
          </button>
        </div>
      </div>

      <div className="h-64 flex items-end justify-between border-b border-mercury mb-8">
        {/* Chart placeholder */}
      </div>

      <div className="flex justify-between items-center">
        <div className="p-4 rounded-lg bg-tealish-blue">
          <p className="text-sage font-medium mb-1">Total Income</p>
          <p className="stat-value text-hazel-green">€0,00</p>
        </div>
        <div className="p-4 rounded-lg bg-dawn-pink text-right">
          <p className="text-salmon-pink font-medium mb-1">Total Expenses</p>
          <p className="stat-value text-watermelon">€0,00</p>
        </div>
      </div>
      
      <p className="text-xs text-bluish-grey mt-6">
        * Income and expense values displayed are exclusive of taxes.
      </p>
    </div>
  );
};

export default IncomeExpenseChart;
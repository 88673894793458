import React from 'react';
import Statistics from './Statistics';
import MaintenanceSchedule from './MaintenanceSchedule';
import CurrentConditions from './CurrentConditions';
import RecentActivity from './RecentActivity';

const Dashboard = () => {
  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <Statistics />
        <CurrentConditions />
        <RecentActivity />
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect } from 'react';
import { X, MessageSquareWarning } from 'lucide-react';
import { toast } from 'react-toastify';

import PaymentManagement from './tabs/PaymentManagement.tsx';
import CleaningTasks from './tabs/CleaningTasks.tsx';
import ExtraCharges from './tabs/ExtraCharges.tsx';
import FinalReport from './tabs/FinalReport.tsx';
import { getLatestCleaningReport } from '../../../../api/cleaning.js';
import { createCheckout } from '../../../../api/checkout';

interface CheckoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  roomNumber: string;
  fromDate: string;
  toDate: string;
  name: string;
  surname: string;
  totalPrice: number;
  paidPrice: number;
  cleaningReport?: {
    id: string;
    roomChecklist: any[];
    minibarChecklist: any[];
    inventoryChecklist: any[];
    cleaningDate: string;
    roomCondition: string;
    cleanlinessLevel: string;
    issuesFound: string[];
    issuesDescription: string;
    additionalNotes: string;
    cleaner: {
      name: string;
      surname: string;
    };
  };
}

const tabs = [
  { id: 'payment', label: 'Payment Management' },
  { id: 'cleaning', label: 'Cleaning Tasks' },
  { id: 'charges', label: 'Extra Charges' },
  { id: 'report', label: 'Final Report' },
];

export default function CheckoutModal({
  isOpen,
  onClose,
  roomNumber,
  fromDate,
  toDate,
  name,
  surname,
  totalPrice,
  paidPrice,
  cleaningReport,
}: CheckoutModalProps) {
  useEffect(() => {
    console.log("CheckOutModal - Received cleaning report-----------------:", cleaningReport);
  }, [cleaningReport]);

  const [activeTab, setActiveTab] = useState('payment');
  const [totalExtraCharges, setTotalExtraCharges] = useState(0);
  const [extraCharges, setExtraCharges] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentNote, setPaymentNote] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Calculate total nights stayed
  const calculateNightsStayed = () => {
    const checkIn = new Date(fromDate);
    const checkOut = new Date(toDate);
    const timeDiff = checkOut.getTime() - checkIn.getTime();
    const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return nights;
  };

  // Calculate final room price
  const calculateFinalRoomPrice = () => {
    const nightsStayed = calculateNightsStayed();
    const pricePerNight = totalPrice || 0;
    return nightsStayed * pricePerNight;
  };

  const finalRoomPrice = calculateFinalRoomPrice();

  // Calculate total charges from minibar and inventory items
  useEffect(() => {
    if (cleaningReport) {
      const minibarTotal = cleaningReport.minibarChecklist
        ?.filter((item: any) => !item.checked)
        .reduce((sum: number, item: any) => sum + ((item.quantity || 0) * 5), 0) || 0;

      const inventoryTotal = cleaningReport.inventoryChecklist
        ?.filter((item: any) => !item.checked)
        .reduce((sum: number, item: any) => sum + ((item.quantity || 0) * 10), 0) || 0;

      setTotalExtraCharges(minibarTotal + inventoryTotal);
    }
  }, [cleaningReport]);

  useEffect(() => {
    console.log("CheckOutModal - Received cleaning report:", cleaningReport);
  }, [cleaningReport]);

  useEffect(() => {
    console.log("CheckOutModal - Received props:", {
      roomNumber,
      fromDate,
      toDate,
      name,
      surname,
      totalPrice,
      paidPrice,
      cleaningReport
    });
  }, [roomNumber, fromDate, toDate, name, surname, totalPrice, paidPrice, cleaningReport]);

  const handleExtraChargesUpdate = (total: number) => {
    setExtraCharges(total);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      
      const checkoutData = {
        roomNumber,
        fromDate,
        toDate,
        guestName: `${name} ${surname}`,
        totalAmount: totalPrice,
        paidAmount: paidPrice,
        extraCharges,
        paymentMethod,
        paymentNote,
        cleaningReportId: cleaningReport?.id || null,
        customCharges: [], // Add custom charges from ExtraCharges component
      };

      await createCheckout(checkoutData);
      onClose();
      // Show success message
      toast.success('Checkout completed successfully');
    } catch (error) {
      console.error('Error during checkout:', error);
      toast.error('Failed to complete checkout');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  const today = new Date();
  const checkoutDate = new Date(toDate);
  const isCheckoutDate = today.toDateString() === checkoutDate.toDateString();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white w-full max-w-4xl rounded-lg shadow-xl">
      <div className="p-4 border-b border-grey-goose flex justify-between items-center bg-gradient-to-r from-hazel-green/10 to-transparent">
        <h2 className="text-xl font-semibold text-hazel-green flex items-center gap-2">
          Checkout Process - Room {roomNumber}
          <span className="text-sm px-2 py-0.5 bg-watermelon/10 text-watermelon rounded">
            Checking Out
          </span>
        </h2>

        {/* Warning message container */}
        {!isCheckoutDate && (
          <div className="flex flex-row items-center gap-2 text-sm px-2 py-0.5 bg-watermelon/10 text-watermelon rounded ml-auto">
            <MessageSquareWarning size={20} className="animate-pulse" />
            <span>Today's Not The Checkout Date</span>
          </div>
        )}

        <button
          onClick={onClose}
          className="text-bluish-grey hover:text-hazel-green transition-colors"
        >
          <X size={24} />
        </button>
      </div>

        <div className="border-b border-grey-goose bg-seashell">
          <nav className="flex px-4">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-6 py-3 text-sm font-medium transition-colors relative ${
                  activeTab === tab.id
                    ? 'text-hazel-green'
                    : 'text-bluish-grey hover:text-hazel-green'
                }`}
              >
                {tab.label}
                {activeTab === tab.id && (
                  <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-hazel-green" />
                )}
              </button>
            ))}
          </nav>
        </div>

        <div className="p-6 max-h-[calc(100vh-12rem)] overflow-y-auto">
          {activeTab === 'payment' && (
            <PaymentManagement
              roomNumber={roomNumber}
              totalPrice={finalRoomPrice}
              paidPrice={paidPrice}
              extraCharges={totalExtraCharges}
            />
          )}
          {activeTab === 'cleaning' && (
            <CleaningTasks
              roomNumber={roomNumber}
              cleaningReport={cleaningReport}
            />
          )}
          {activeTab === 'charges' && (
            <ExtraCharges
              roomNumber={roomNumber}
              cleaningReport={cleaningReport}
              onChargesUpdate={setTotalExtraCharges}
            />
          )}
          {activeTab === 'report' && (
            <FinalReport
              roomNumber={roomNumber}
              fromDate={fromDate}
              toDate={toDate}
              guestName={`${name} ${surname}`}
              cleaningReport={{
                roomCondition: cleaningReport?.roomCondition || '',
                cleanlinessLevel: cleaningReport?.cleanlinessLevel || '',
                issuesDescription: cleaningReport?.issuesDescription || '',
                additionalNotes: cleaningReport?.additionalNotes || ''
              }}
            />
          )}
        </div>

        <div className="flex justify-between my-2 py-4 px-6 border-t border-grey-goose">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm text-bluish-grey hover:text-watermelon transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={isSubmitting}
            className="px-4 py-2 text-sm bg-hazel-green text-white rounded-md hover:bg-sage transition-colors disabled:opacity-50"
          >
            {isSubmitting ? 'Processing...' : 'Complete Checkout'}
          </button>
        </div>
      </div>
    </div>
  );
}
import api from './api';

// Create a new item or service
export const createItemOrService = (itemData) => api.post('/api/items', itemData);

// Get all items or services
export const getAllItemsOrServices = () => api.get('/api/items');

// Get an item or service by ID
export const getItemOrServiceById = (id) => api.get(`/api/items/${id}`);

// Update an item or service
export const updateItemOrService = (id, itemData) => api.put(`/api/items/${id}`, itemData);

// Delete an item or service
export const deleteItemOrService = (id) => api.delete(`/api/items/${id}`);


import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getRoom } from '../../../api/hotelInterface';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWifi,
  faUtensils,
  faBed,
  faWaterLadder
} from '@fortawesome/free-solid-svg-icons';

// const iconMap = {
//   wifi: faCoffee, 
//   bed: faBed,
//   breakfast: faCoffee,
//   inventory: faHome
// };

import { getRoomTypes } from '../../../api/hotelInterface';

const icons = [
  {
    title: 'wifi',
    icon: <FontAwesomeIcon icon={faWifi} className='text-2xl text-hazel-green mr-2' />
  },
  {
    title: 'bed',
    icon: <FontAwesomeIcon icon={faBed} className='text-2xl text-hazel-green mr-2' />
  },
  {
    title: 'breakfast',
    icon: (
      <FontAwesomeIcon icon={faUtensils} className='text-2xl text-hazel-green mr-2' />
    )
  },
  {
    title: 'jakuzi',
    icon: (
      <FontAwesomeIcon icon={faWaterLadder} className='text-2xl text-hazel-green mr-2' />
    )
  }
];


const RoomDetails = () => {
  const { id } = useParams();
  const [room, setRoom] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);




  const [roomTypes, setRoomTypes] = useState([]);

  useEffect(() => {
    const fetchRoomTypes = async () => {
      try {
        const response = await getRoomTypes();
        setRoomTypes(response.data || []);
      } catch (error) {
        console.error('Failed to fetch room types:', error);
        setError('Failed to fetch room types');
      }
    };

    fetchRoomTypes();
  }, []);







  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        const response = await getRoom(id);
        setRoom(response.data);
        setLoading(false);
      } catch (error) {
        setError('Error fetching room details');
        setLoading(false);
      }
    };

    fetchRoomDetails();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;


  console.log(room?.roomType?.additionalInfo)

  const additionalInfo = room?.roomType?.additionalInfo
    ? (() => {
        try {
          return JSON.parse(room.roomType.additionalInfo) || [];
        } catch {
          return [];
        }
      })()
    : [];

  const inventory = room?.inventory
    ? (() => {
        try {
          return JSON.parse(room.inventory) || [];
        } catch {
          return [];
        }
      })()
    : [];

  console.log('Additional Info:', additionalInfo); 

  const roomType = roomTypes.find(type => type.id === room.roomTypeId) || {};

  return (
    <section className="relative">
      <div className="w-full mx-auto px-4 sm:px-6 lg:px-0">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 mx-auto">
          <div className="img">
            <div className="img-box h-full max-lg:mx-auto">
              <img
                src={`http://localhost:3001${room.roomType.imagePath}`} 
                alt={room.roomType.title || 'Room Image'}
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="data w-full lg:pr-8 pr-0 xl:justify-start justify-center flex items-center max-lg:pb-10 xl:my-2 lg:my-5 my-0">
            <div className="data w-full max-w-xl">
              <p className="text-lg font-medium leading-8 text-indigo-600 mb-4">
                {room.roomType.title || 'Room Type'} / {room.number || 'Room Number'}
              </p>
              <h2 className="font-manrope font-bold text-3xl leading-10 text-gray-900 mb-2 capitalize">
                {room.roomType.title || 'Room Title'}
              </h2>
              <div className="flex flex-col sm:flex-row sm:items-center mb-6">
                <h6 className="font-manrope font-semibold text-2xl leading-9 text-gray-900 pr-5 sm:border-r border-gray-200 mr-5">
                  {room.roomType.price ? `$${room.roomType.price.toFixed(2)}` : 'N/A'}
                </h6>
                <div className="flex items-center gap-2">
                  <span className="pl-2 font-normal leading-7 text-gray-500 text-sm">
                       Room Type: {room.roomType.title || 'No Title'}
                  </span>
                </div>
              </div>
              <p className="text-gray-500 text-base font-normal my-6 italic">
                {room.roomType.description || 'No Description'}
                <a href="#" className="mx-4 text-indigo-600">
                  More....
                </a>
              </p>
              <ul className="grid gap-y-4 mb-8">
                <li className="flex items-center gap-3">
                  <span className="font-normal text-base text-gray-900">Room Floor: <strong>{room.floor}</strong></span>
                </li>
                <li className="flex items-center gap-3">
                  <span className="font-normal text-base text-gray-900">Room status: <strong> {room.status} </strong> </span>
                </li>
                <li className="flex items-center gap-3">
                  <span className="font-normal text-base text-gray-900">Room created at: <strong> {new Date(room.createdAt).toLocaleDateString() || 'No date available'}</strong></span>
                </li>
                <li className="flex items-center gap-3">
                  <span className="font-normal text-base text-gray-900">Room capacity: <strong> {room.roomType.capacity}  people </strong></span>
                </li>
              </ul>
              <p className="text-gray-900 text-lg leading-8 font-medium mb-4">Inventory</p>

              <pre className="bg-gray-100 p-4 rounded">{JSON.stringify(room.inventory, null, 2) || 'No inventory information available'}</pre>
              


                   <div className="p-4 space-y-4">
                    {room?.roomType?.additionalInfo?.length ? (
                      <ul className="space-y-2">
                        {room.roomType.additionalInfo.map((info, index) => {
                          const iconObj = icons.find(icon => icon.title === info.icon);

                          return (
                            // <li key={index} className="flex items-center space-x-3 p-2 border rounded shadow-md">
                            //   {iconObj ? iconObj.icon : <span className="text-gray-500">No Icon</span>}
                            //   <div className="flex-1">
                            //     <h3 className="text-lg font-semibold">{info.title}</h3>
                            //     <p className="text-sm text-gray-700">{info.value}</p>
                            //   </div>
                            // </li>
                            <li key={index} className="flex items-center p-2  px-4 border rounded shadow-md hover:bg-slate-200 transition-all duration-200 ease-in-out">
                            {/* Icon */}
                            <div className="flex-shrink-0 flex items-center justify-center w-12 h-12">
                              {iconObj ? (
                                iconObj.icon
                              ) : (
                                <span className="text-gray-500">No Icon</span>
                              )}
                            </div>

                            {/* Title and Value */}
                            <div className="flex-1 flex flex-col items-end">
                              <h3 className="text-lg font-semibold">{info.title}</h3>
                              <p className="text-md text-gray-700">{info.value}</p>
                            </div>
                          </li>

                          );
                        })}
                      </ul>
                    ) : (
                      <p>No additional information available.</p>
                    )}
                  </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 py-8">
                {/* <button className="col-span-1 group py-4 px-5 rounded-full bg-indigo-50 text-indigo-600 font-semibold text-lg w-full flex items-center justify-center gap-2 transition-all duration-500 hover:bg-indigo-100">
                  EDIT ROOM
                </button> */}

                <Link 
                      className="col-span-1 group py-4 px-5 rounded-full bg-indigo-50 text-indigo-600 font-semibold text-lg w-full flex items-center justify-center gap-2 transition-all duration-500 hover:bg-indigo-100"
                      to={`/hotel-interface/rooms/edit-room/${room.id}`}
                      /* <IconPencil
                        className='text-bluish-grey'
                        size={18}
                      /> */
                >
                  EDIT ROOM
                </Link>
                

                <Link
                      className="col-span-1 group py-4 px-5 rounded-full bg-teal-50 text-teal-600 font-semibold text-lg w-full flex items-center justify-center gap-2 transition-all duration-500 hover:bg-teal-100"
                      to={`/hotel-interface/add-new-reservation/${room.id}`} 
                      state={{ roomType, room }}
                >
                        BOOK NOW
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoomDetails;

import cn from 'classnames'
import React from 'react'

import { useStateMachine } from 'little-state-machine'

const Screen = ({ screen }) => {
  const { state } = useStateMachine()
  const { calculator } = state

//   const screen = calculator.result || calculator.secondValue || calculator.firstValue

  return (
    <div className="bg-teal-700 rounded-lg p-3 h-16 flex items-center justify-end overflow-hidden">
      <span className="text-2xl font-mono text-hazel-green tracking-wider truncate">
        {screen || '0'}
      </span>
    </div>
  );
}

export default Screen

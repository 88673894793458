import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import { IconPlus, IconPencil, IconTrash } from '@tabler/icons-react';
import Modal from '../../components/modal';
import ConfirmMessage from '../../components/alerts/alert-message';
import Loading from '../../components/loader';
import PermissionCheck from '../../utils/PermissionCheck';
import { fetcher } from '../../api/api';
import { deleteEmployee, getEmployees } from '../../api/employee';
import { getJobTitles, createJobTitle, deleteJobTitle } from '../../api/jobTitles';

function EmployeeList() {
  const [activeJobTitle, setActiveJobTitle] = useState('');
  const [activeTab, setActiveTab] = useState('job-titles');
  const [employees, setEmployees] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
  const [newJobTitle, setNewJobTitle] = useState('');
//   const [showJobTitleModal, setShowJobTitleModal] = useState(false);
  const [showJobTitleModal, setShowJobTitleModal] = useState(false);

  const [jobTitleToDelete, setJobTitleToDelete] = useState(null);
  const [showDeleteJobTitleAlert, setShowDeleteJobTitleAlert] = useState(false);

//   const { data: jobTitles, error: jobTitlesError, isValidating: isValidatingJobTitles, mutate: mutateJobTitles } = useSWR('/api/job-titles', fetcher);
  const { data: jobTitles = [], error: jobTitlesError, isValidating: isValidatingJobTitles, mutate: mutateJobTitles } = useSWR('/api/job-titles', fetcher);

//   const { data: employeeData, error: employeeError, isValidating: isValidatingEmployees, mutate: mutateEmployees } = useSWR(
//     activeTab === 'job-titles' && activeJobTitle ? `/api/employees?jobTitle=${activeJobTitle}` : null,
//     fetcher
//   );
const { data: employeeData, error: employeeError, isValidating: isValidatingEmployees, mutate: mutateEmployees } = useSWR(
    activeTab === 'job-titles' && activeJobTitle ? `/api/employees?jobTitle=${activeJobTitle}` : null,
    fetcher
);


  useEffect(() => {
    console.log('Active Job Title:', activeJobTitle);
  }, [activeJobTitle]);
  

  // Set the default job title on initial load
  useEffect(() => {
    if (jobTitles) {
      setActiveJobTitle(jobTitles[0]?.id || '');
      console.log(jobTitles)
    }
  }, [jobTitles]);
  

  // Update employees whenever `employeeData` changes
  useEffect(() => {
    if (employeeData) {
      setEmployees(employeeData);
      console.log(employeeData)
    }
  }, [employeeData]);

  const handleDeleteEmployee = async () => {
    try {
      await deleteEmployee(selectedEmployeeId);
      setEmployees((prevEmployees) => prevEmployees.filter((emp) => emp.id !== selectedEmployeeId));
      setShowDeleteAlert(false);
      mutateEmployees(); // Ensure the SWR cache is updated
    } catch (err) {
      console.error('Failed to delete employee:', err);
    }
  };


  const handleCreateJobTitle = async () => {
    try {
      await createJobTitle(newJobTitle); // newJobTitle should be a string
      setNewJobTitle(''); // Reset input field
      setShowJobTitleModal(false); // Close modal
      mutateJobTitles(); // Re-fetch job titles
    } catch (err) {
      console.error('Failed to create job title:', err);
    }
  };
  

  const handleDeleteJobTitle = async () => {
    try {
      await deleteJobTitle(jobTitleToDelete);
      setJobTitleToDelete(null);
      setShowDeleteJobTitleAlert(false);
      mutateJobTitles(); // Re-fetch job titles
    } catch (err) {
      console.error('Failed to delete job title:', err);
    }
  };

  if (jobTitlesError || employeeError) return <div>Error loading data...</div>;
  if (isValidatingJobTitles || isValidatingEmployees) return <Loading />;

  const handleJobTitleClick = (jobTitleId) => {
    setActiveJobTitle(jobTitleId);
    setActiveTab('job-titles');
  };


  return (
    <div className='p-4 sm:p-6 md:p-10'>
      <div className='flex flex-col sm:flex-row justify-end gap-3 sm:gap-x-3 mb-8'>
        <PermissionCheck action='add_employees'>
          <Link to='/employees/create-employee' className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5 w-full sm:w-auto'>
            <IconPlus className='mr-2' size={20} />
            Add Employee
          </Link>
        </PermissionCheck>
        <PermissionCheck action='add_job_titles'>
          <button
            onClick={() => setShowJobTitleModal(true)}
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5 w-full sm:w-auto'
          >
            <IconPlus className='mr-2' size={20} />
            Add Job Title
          </button>
        </PermissionCheck>
      </div>
      <div className='border border-gray-200 rounded-xl shadow-lg px-2 sm:px-3 mt-5'>
        <div className='flex items-center justify-between w-full border-b border-gray-200 overflow-x-auto py-2 scrollbar-thin scrollbar-thumb-gray-300'>
          <div className='flex items-center gap-2'>
            {jobTitles?.map((jobTitle) => (
              <div
                key={jobTitle.id}
                className={`flex items-center px-3 sm:px-5 py-2.5 sm:py-3.5 whitespace-nowrap ${
                  jobTitle.id === activeJobTitle && activeTab === 'job-titles' 
                  ? 'bg-seashell bg-opacity-30 rounded-md' 
                  : ''
                } cursor-pointer`}
                onClick={() => handleJobTitleClick(jobTitle.id)}
              >
                <p className='text-bluish-grey capitalize text-sm sm:text-base ml-2'>{jobTitle.title}</p>
                <IconTrash
                  className='ml-2 text-bluish-grey cursor-pointer'
                  size={18}
                  onClick={() => {
                    setJobTitleToDelete(jobTitle.id);
                    setShowDeleteJobTitleAlert(true);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className='flex justify-end items-center bg-white py-2.5 px-3 sm:px-5 gap-x-5'>
          {activeTab === 'job-titles' && (
            <PermissionCheck action='edit_employees'>
              <Link to={`/employees/edit/${selectedEmployeeId}`}>
                <IconPencil className='text-sage' size={20} />
              </Link>
            </PermissionCheck>
          )}
        </div>
        {activeTab === 'job-titles' ? (
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-4 px-2 sm:px-3'>
            <PermissionCheck action='add_employees'>
              <Link 
                to={`/employees/add?jobTitle=${activeJobTitle}`} 
                className='flex justify-center items-center p-5 bg-seashell rounded-md'
              >
                <IconPlus className='text-bluish-grey font-bold cursor-pointer' size={24} />
              </Link>
            </PermissionCheck>
            
            {employees?.length ? (
              employees.map((employee) => (
                <div key={employee.id} className='flex flex-col items-center p-4 sm:p-5 rounded-md bg-seashell'>
                  <p className='text-bluish-grey text-lg sm:text-xl font-medium text-center'>{employee.name}</p>
                  <div className='flex mt-2 gap-x-3'>
                    <PermissionCheck action='edit_employees'>
                      <Link to={`/employees/edit-employee/${employee.id}`}>
                        <IconPencil className='text-bluish-grey cursor-pointer' size={18} />
                      </Link>
                    </PermissionCheck>
                    <PermissionCheck action='delete_employees'>
                      <IconTrash
                        className='text-bluish-grey cursor-pointer'
                        size={18}
                        onClick={() => {
                          setSelectedEmployeeId(employee.id);
                          setShowDeleteAlert(true);
                        }}
                      />
                    </PermissionCheck>
                  </div>
                </div>
              ))
            ) : (
              <p className='text-sage font-bold py-6 sm:py-10 px-4 sm:px-8 text-center col-span-full'>
                No employees available for this job title!
              </p>
            )}
          </div>
        ) : (
          <p className='text-sage font-bold py-6 sm:py-10 px-4 sm:px-8 text-center'>
            No employees available!
          </p>
        )}
      </div>
      
      {/* Modal for Adding Job Titles */}
      {showJobTitleModal && (
        <Modal onClose={() => setShowJobTitleModal(false)}>
          <div>
            <h2 className='text-lg font-bold mb-4'>Add New Job Title</h2>
            <input
              type='text'
              value={newJobTitle}
              onChange={(e) => setNewJobTitle(e.target.value)}
              className='border border-gray-300 p-2 w-full mb-4'
              placeholder='Enter job title'
            />
            <button
              onClick={handleCreateJobTitle}
              className='bg-sage text-white py-2 px-4 rounded-md'
            >
              Add
            </button>
          </div>
        </Modal>
      )}
      
      {/* Confirm Delete Employee */}
      {showDeleteAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this employee?'
          onCancel={() => setShowDeleteAlert(false)}
          onConfirm={handleDeleteEmployee}
        />
      )}

      {/* Confirm Delete Job Title */}
      {showDeleteJobTitleAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this job title?'
          onCancel={() => setShowDeleteJobTitleAlert(false)}
          onConfirm={handleDeleteJobTitle}
        />
      )}
    </div>
  );
}

export default EmployeeList;

import React from 'react';
import { IconCalendarMonth } from '@tabler/icons-react';
import PageTitle from '../../../components/page-title';
import ReservationForm from './ReservationForm';

function AddReservation() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconCalendarMonth className='text-hazel-green mr-2' size={22} />}
        title='Add new reservation'
      />
      <ReservationForm />
    </div>
  );
}

export default AddReservation;

import React from 'react';
import { IconFileDollar } from '@tabler/icons-react';
import PageTitle from '../../components/page-title';
import InvoiceForm from './InvoiceForm';

function AddInvoice() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconFileDollar className='text-hazel-green mr-2' size={22} />}
        title='Create a new invoice'
      />
      <InvoiceForm />
    </div>
  );
}

export default AddInvoice;

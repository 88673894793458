import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import DamageTable from '../../components/hotelInterfaceComponents/damageComponents/DamageTable.tsx';
import { mockDamages } from '../../components/data/damagesData.ts';
import Navbar from '../../components/hotelInterfaceComponents/Navbar.tsx';


export default function DamagesList() {
  const [activeTab, setActiveTab] = useState<'in_progress' | 'closed'>('in_progress');
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-tealish-blue">
        <Navbar />
      <div className="max-w-7xl mx-auto my-6">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold text-hazel-green">Damages Dashboard</h1>
          <button
            onClick={() => navigate('/hotelInterface/damages/new')}
            className="flex items-center gap-2 px-4 py-2 bg-hazel-green text-white rounded-lg hover:bg-sage transition-colors"
          >
            <Plus className="h-5 w-5" />
            Add New Damage
          </button>
        </div>

        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="border-b border-mercury">
            <nav className="flex" aria-label="Tabs">
              <button
                onClick={() => setActiveTab('in_progress')}
                className={`px-6 py-4 text-sm font-medium ${
                  activeTab === 'in_progress'
                    ? 'text-salmon-pink border-b-2 border-salmon-pink'
                    : 'text-bluish-grey hover:text-hazel-green'
                }`}
              >
                In Progress
              </button>
              <button
                onClick={() => setActiveTab('closed')}
                className={`px-6 py-4 text-sm font-medium ${
                  activeTab === 'closed'
                    ? 'text-sage border-b-2 border-sage'
                    : 'text-bluish-grey hover:text-hazel-green'
                }`}
              >
                Closed
              </button>
            </nav>
          </div>

          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-4">
                <label className="text-sm text-bluish-grey">Show</label>
                <select className="border border-mercury rounded px-2 py-1">
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                </select>
                <span className="text-sm text-bluish-grey">entries</span>
              </div>
              <div className="flex items-center gap-2">
                <label className="text-sm text-bluish-grey">Search:</label>
                <input
                  type="text"
                  className="border border-mercury rounded px-3 py-1 focus:outline-none focus:ring-2 focus:ring-hazel-green"
                />
              </div>
            </div>

            <DamageTable damages={mockDamages} status={activeTab} />

            <div className="mt-4 flex justify-between items-center text-sm text-bluish-grey">
              <div>Showing 1 to {mockDamages.length} of {mockDamages.length} entries</div>
              <div className="flex gap-2">
                <button className="px-3 py-1 border border-mercury rounded hover:bg-seashell">
                  Previous
                </button>
                <button className="px-3 py-1 border border-mercury rounded hover:bg-seashell">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
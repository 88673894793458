import { useState } from 'react';
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Plus, Filter } from 'lucide-react';
import { Button } from '../shadCN/Button.tsx';

interface Transaction {
  id: string;
  date: string;
  reference: string;
  amount: string;
  status: string;
  balanceDue?: string;
  orderNumber?: string;
}

interface AccordionProps {
  title: string;
  data: Transaction[];
  columns: string[];
  showBalanceDue?: boolean;
  showOrderNumber?: boolean;
}

export const TransactionAccordion = ({ 
  title, 
  data, 
  columns,
  showBalanceDue = false,
  showOrderNumber = false 
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4 border border-mercury rounded-lg overflow-hidden">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between p-4 bg-white hover:bg-seashell transition-colors"
      >
        <div className="flex items-center gap-2">
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.2 }}
          >
            <ChevronDown className="h-4 w-4 text-bluish-grey" />
          </motion.div>
          <span className="font-semibold text-hazel-green">{title}</span>
        </div>
        <div className="flex items-center gap-2">
          <Button variant="outline" size="sm" className="text-bluish-grey">
            <Filter className="h-4 w-4 mr-2" />
            Filter
          </Button>
          <Button className="bg-hazel-green hover:bg-sage text-white">
            <Plus className="h-4 w-4 mr-2" />
            New {title.slice(0, -1)}
          </Button>
        </div>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            <div className="border-t border-mercury">
              <div className="grid grid-cols-6 gap-4 p-4 bg-white">
                {columns.map((column) => (
                  <div key={column} className="text-sm font-medium text-bluish-grey">
                    {column}
                  </div>
                ))}
              </div>

              <div className="divide-y divide-mercury">
                {data.map((item) => (
                  <div
                    key={item.id}
                    className="bg-white grid grid-cols-6 gap-4 p-4 hover:bg-seashell transition-colors duration-200"
                  >
                    <div className="text-bluish-grey">{item.date}</div>
                    <div className="text-hazel-green font-medium">{item.id}</div>
                    {showOrderNumber && <div className="text-bluish-grey">{item.orderNumber}</div>}
                    <div className="text-bluish-grey">{item.reference}</div>
                    <div className="text-bluish-grey font-medium">{item.amount}</div>
                    {showBalanceDue && <div className="text-bluish-grey">{item.balanceDue}</div>}
                    <div>
                      <span
                        className={`px-3 py-1 rounded-full text-sm font-medium ${
                          item.status === 'Paid' || item.status === 'Accepted'
                            ? 'bg-[#EDF7ED] text-[#1E4620]'
                            : item.status === 'Pending'
                            ? 'bg-[#FFF4E5] text-[#663C00]'
                            : item.status === 'Draft'
                            ? 'bg-[#EDF2F7] text-[#2D3748]'
                            : 'bg-[#FDECEA] text-[#611A15]'
                        }`}
                      >
                        {item.status}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
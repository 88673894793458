import React, { useState } from 'react';
import { X } from 'lucide-react';

import BookingDetails from './tabs/BookingDetails.tsx';
import CreateCase from './tabs/CreateCase.tsx';
import AddPayment from './tabs/AddPayment.tsx';
import CreateTask from './tabs/CreateTask.tsx';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  roomNumber: string;
  fromDate: string; // Add this line
  toDate: string; // Add this line
  name: string; // Add this line
  surname: string; // Add this line
  email: string; // Add this line
  phoneNumber: string; // Add this line
  adults: number; // Add this line
  children: number; // Add this line
  totalPrice: number; // Add this line
  paidPrice: number; // Add this line
}


const tabs = [
  { id: 'booking', label: 'Booking Details' },
  { id: 'case', label: 'Create New Case' },
  { id: 'task', label: 'Create New Task' },
  { id: 'payment', label: 'Add Payment' },
];

export default function ReservationModal({ 
  isOpen,
    onClose,
    roomNumber,
    fromDate,
    toDate,
    name,
    surname,
    email,
    phoneNumber,
    adults,
    children,
    totalPrice,
    paidPrice,
 }: ModalProps) {
    const [activeTab, setActiveTab] = useState('booking');
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
        <div className="bg-white w-full max-w-3xl rounded-lg shadow-xl">
          <div className="p-3 border-b border-grey-goose flex justify-between items-center">
            <h2 className="text-lg font-semibold text-hazel-green">
              Room - {roomNumber} - Reservation Details - 
              <span className="text-watermelon">Reserved</span>
            </h2>
            <button
              onClick={onClose}
              className="text-bluish-grey hover:text-hazel-green transition-colors"
            >
              <X size={20} />
            </button>
          </div>
  
          <div className="border-b border-b border-grey-goose">
            <nav className="flex">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`px-4 py-2 text-sm font-medium transition-colors ${
                    activeTab === tab.id
                      ? 'text-hazel-green border-b-2 border-hazel-green bg-seashell'
                      : 'text-bluish-grey hover:text-hazel-green hover:bg-tealish-blue'
                  }`}
                >
                  {tab.label}
                </button>
              ))}
            </nav>
          </div>
  
          <div className="p-4 rounded-lg bg-seashell max-h-[calc(100vh-12rem)] overflow-y-auto">
            {activeTab === 'booking' &&  (
              <BookingDetails
              fromDate={fromDate} // Pass fromDate
              toDate={toDate} // Pass toDate
              name={name} // Pass guest name
              surname={surname} // Pass guest surname
              email={email} // Pass guest email
              phoneNumber={phoneNumber} // Pass guest phone number
              adults={adults} // Pass number of adults
              children={children} // Pass number of children
              totalPrice={totalPrice} // Pass total price
              paidPrice={paidPrice} // Pass paid price
            />
            ) }
            {activeTab === 'case' && <CreateCase roomNumber={roomNumber} />}
            {activeTab === 'task' && <CreateTask roomNumber={roomNumber} />}
            {activeTab === 'payment' && <AddPayment />}
          </div>
        </div>
      </div>
    );
  }
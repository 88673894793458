import api from './api';

export const getJobTitles = () => api.get('/api/job-titles');
export const getJobTitleById = (id) => api.get(`/api/job-titles/${id}`);

// export const createJobTitle = (data) => api.post('/api/job-titles', data);
export const createJobTitle = (title) => api.post('/api/job-titles', { title });


export const updateJobTitle = (id, data) => api.put(`/api/job-titles/${id}`, data);
export const deleteJobTitle = (id) => api.delete(`/api/job-titles/${id}`);

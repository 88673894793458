export const navList = [
  {
    id: 1,
    path: "/hotel-interface",
    text: "Home",
  },
  {
    id: 2,
    path: "/hotel-interface/about",
    text: "About",
  },
  {
    id: 3,
    path: "/hotel-interface/services",
    text: "Services",
  },
  {
    id: 4,
    path: "/hotel-interface/rooms",
    text: "Rooms",
  },
  
  {
    id: 6,
    path: "/hotel-interface/contact",
    text: "Contact",
  }
  
];
export const socialIcons = [
  {
    icon: <i className="fab fa-facebook-f"></i>,
  },
  {
    icon: <i className="fab fa-twitter"></i>,
  },
  {
    icon: <i className="fab fa-instagram"></i>,
  },

  {
    icon: <i className="fab fa-youtube"></i>,
  },
  
];

export const carouselData = [
  {
    img: "../assets/img/carousel-1.jpg",
    title: "Lake Side hotel",
    subtitle: "luxury living",
    btn1: "Room Reservations",
    btn2: "Book Room",
  },
  {
    img: "../assets/img/carousel-2.jpg",
    title: "Discover A Brand Luxurious Hotel",
    subtitle: "luxury living",
    btn1: "Room Reservations",
    btn2: "Book Room",
  },
  {
    img: "../images/carousel-5.jpg",
    title: "Amazing ambient and great hotel.",
    subtitle: "lake side",
    btn1: "Room Reservations",
    btn2: "Book Room",
  },
  {
    img: "../images/carousel-6.jpg",
    title: "Visit LakeSide hotel.",
    subtitle: "lake side",
    btn1: "Room Reservations",
    btn2: "Book Room",
  }
];
export const about = [
  {
    icon: <i class="fa fa-hotel fa-2x text-[#FEA116] mb-2"></i>,
    text: "Rooms",
    count: "7861",
  },
  {
    icon: <i class="fa fa-users-cog fa-2x text-[#FEA116] mb-2"></i>,
    text: "Clients",
    count: "4321",
  },
];

export const services = [
  {
    icon: <i class="fa fa-hotel fa-2x text-[#FEA116]"></i>,
    name: "Rooms & Appartment",
    discription: "Contrary to popular belief, ipsum is not simply random.",
  },
  {
    icon: <i class="fa fa-utensils fa-2x text-[#FEA116]"></i>,
    name: "Food & Restaurant",
    discription: "Contrary to popular belief, ipsum is not simply random.",
  },
  {
    icon: <i class="fa fa-spa fa-2x text-[#FEA116]"></i>,
    name: "Spa & Fitness",
    discription: "Contrary to popular belief, ipsum is not simply random.",
  },

  {
    icon: <i class="fa fa-swimmer fa-2x text-[#FEA116]"></i>,
    name: "Sports & Gaming",
    discription: "Contrary to popular belief, ipsum is not simply random.",
  },
  {
    icon: <i class="fa fa-glass-cheers fa-2x text-[#FEA116]"></i>,
    name: "Event & Party",
    discription: "Contrary to popular belief, ipsum is not simply random.",
  },

  {
    icon: <i class="fa fa-dumbbell fa-2x text-[#FEA116]"></i>,
    name: "GYM & Yoga",
    discription: "Contrary to popular belief, ipsum is not simply random.",
  },
];
export const team = [
  {
    image: "../assets/img/team-1.jpg",
    name: "Lake Side Hotel",
    designation: "Designation",
  },
  {
    image: "../assets/img/team-2.jpg",
    name: "Lake Side Hotel",
    designation: "Designation",
  },
  {
    image: "../assets/img/team-3.jpg",
    name: "Lake Side Hotel",
    designation: "Designation",
  },
  {
    image: "../assets/img/team-3.jpg",
    name: "Lake Side Hotel",
    designation: "Designation",
  },
];

export const footerItem = [
  {
    id: 1,
    header: "Company",
    UnitItem: [
      {
        name: "About Us",
      },
      {
        name: "Contact Us",
      },
      {
        name: "Privacy Policy",
      },
      {
        name: "Terms & Condition",
      },
      {
        name: "Support",
      },
    ],
  },
  {
    id: 2,
    header: "Services",
    UnitItem: [
      {
        name: "Spa & Fitness",
      },
      {
        name: "Sports & Gaming",
      },
      {
        name: "Event & Party",
      },
      {
        name: "GYM & Yoga",
      },
    ],
  },
];

export const footerContact = [
  {
    icon: <i className="fa fa-map-marker-alt me-3"></i>,
    name: "Vermice Prizren",
  },
  {
    icon: <i className="fa fa-phone-alt me-3"></i>,
    name: "+11111111111",
  },
  {
    icon: <i className="fa fa-envelope me-3"></i>,
    name: "lakeside@lakeside.com",
  },
];

export const contact = [
  {
    icon: <i class="fa fa-envelope-open text-[#FEA116] me-2"></i>,
    title: "Booking",
    email: "book@example.com",
  },
  {
    icon: <i class="fa fa-envelope-open text-[#FEA116] me-2"></i>,
    title: "Technical",
    email: "tech@example.com",
  },
  {
    icon: <i class="fa fa-envelope-open text-[#FEA116] me-2"></i>,
    title: "General",
    email: "info@example.com",
  },
];
export const testimonial = [
  {
    description:
      "Vendi i duhur për kujtimet më të mira Ambienti i bukur dhe shërbimi i kujdesshëm e bëjnë atë një destinacion të paharrueshëm .për kujtimet më të bukura të udhëtimit tuaj",
    name: "Lake Side Hotel",
    profession: "Profession",
    icon: (
      <i class="fa fa-quote-right fa-3x text-[#FEA116] position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-1.jpg",
  },
  {
    description:
      "Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet dolor amet diam stet. Est stet ea lorem amet est kasd kasd et erat magna eos",
    name: "Lake Side Hotel",
    profession: "Profession",
    icon: (
      <i class="fa fa-quote-right fa-3x text-[#FEA116] position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-2.jpg",
  },
  {
    description:
      "Vendi i duhur për kujtimet më të mira Ambienti i bukur dhe shërbimi i kujdesshëm e bëjnë atë një destinacion të paharrueshëm .për kujtimet më të bukura të udhëtimit tuaj",
    name: "Lake Side Hotel",
    profession: "Profession",
    icon: (
      <i class="fa fa-quote-right fa-3x text-[#FEA116] position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
];

export const roomItems = [
  {
    img: "../assets/img/room-1.jpg",
    price: "$110/night",
    name: "Junior Suit",
    star: [
      <small class="fa fa-star text-[#FEA116]"></small>,
      <small class="fa fa-star text-[#FEA116]"></small>,
      <small class="fa fa-star text-[#FEA116]"></small>,
      <small class="fa fa-star text-[#FEA116]"></small>,
      <small class="fa fa-star text-[#FEA116]"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },

  {
    img: "../assets/img/room-2.jpg",
    price: "$110/night",
    name: "Executive Suite",
    star: [
      <small class="fa fa-star text-[#FEA116]"></small>,
      <small class="fa fa-star text-[#FEA116]"></small>,
      <small class="fa fa-star text-[#FEA116]"></small>,
      <small class="fa fa-star text-[#FEA116]"></small>,
      <small class="fa fa-star text-[#FEA116]"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },
  {
    img: "../assets/img/room-3.jpg",
    price: "$110/night",
    name: "Super Deluxe",
    star: [
      <small class="fa fa-star text-[#FEA116]"></small>,
      <small class="fa fa-star text-[#FEA116]"></small>,
      <small class="fa fa-star text-[#FEA116]"></small>,
      <small class="fa fa-star text-[#FEA116]"></small>,
      <small class="fa fa-star text-[#FEA116]"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },
];

export const facility = [
  {
    icon: <i class="fa fa-bed text-[#FEA116] me-2"></i>,
    quantity: 3,
    facility: "bed",
  },
  {
    icon: <i class="fa fa-bath text-[#FEA116] me-2"></i>,
    quantity: 2,
    facility: "bath",
  },
  {
    icon: <i class="fa fa-wifi text-[#FEA116] me-2"></i>,
    facility: "Wifi",
  },
];

import { useState, useEffect } from 'react';
import { Check, ChevronsUpDown, Search, Filter } from 'lucide-react';
import { cn } from '../../../../../utils/cn.ts';
import { Button } from '../../../../../components/shadCN/Button.tsx';
import SimpleCommand from '../../../../../components/shadCN/SimpleCommand.tsx';
import { Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getAllInvoices } from '../../../../../api/invoiceSale.js';
import { getClientById } from '../../../../../api/accounting.js';
import Loading from '../../../../../components/loader.js';

const statuses = [
  { label: 'All Invoices', value: 'all' },
  { label: 'Paid', value: 'paid' },
  { label: 'Pending', value: 'pending' },
  { label: 'Overdue', value: 'overdue' },
  { label: 'Draft', value: 'draft' },
];

export default function SaleInvoiceList() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(statuses[0]); // Default to "All Invoices"
  const [isCommandOpen, setIsCommandOpen] = useState(false); // State to control command visibility
  const [invoices, setInvoices] = useState([]); // State for invoices
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for error handling
  const [clients, setClients] = useState({}); // State to hold client names
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await getAllInvoices();
        setInvoices(response.data); // Assuming the API returns { data: [invoices] }
        await fetchClients(response.data); // Fetch clients for the fetched invoices
      } catch (err) {
        setError(err.message); // Handle error
      } finally {
        setLoading(false); // Loading complete
      }
    };

    fetchInvoices();
  }, []);

  const fetchClients = async (invoices) => {
    const clientIds = [...new Set(invoices.map((invoice) => invoice.clientId))]; // Get unique client IDs
    const clientPromises = clientIds.map((id) => getClientById(id));
    
    try {
      const clientResponses = await Promise.all(clientPromises);
      const clientMap = clientResponses.reduce((acc, clientResponse) => {
        acc[clientResponse.data.id] = clientResponse.data.name; // Assuming response contains { data: { id, name } }
        return acc;
      }, {});
      setClients(clientMap);
    } catch (err) {
      console.error("Error fetching clients:", err);
    }
  };

  const filteredInvoices = invoices.filter((invoice) => {
    if (!selectedStatus) return false; // Guard against undefined selectedStatus

    const matchesStatus =
      selectedStatus.value === 'all' ||
      invoice.status.toLowerCase() === selectedStatus.value;

    const matchesSearch =
      invoice.invoiceNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (clients[invoice.clientId] && clients[invoice.clientId].toLowerCase().includes(searchQuery.toLowerCase())); // Use client name for search

    return matchesStatus && matchesSearch;
  });

  // Pagination logic
  const indexOfLastInvoice = currentPage * itemsPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - itemsPerPage;
  const currentInvoices = filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);
  const totalPages = Math.ceil(filteredInvoices.length / itemsPerPage);

  const handleClickButton = () => {
    navigate('/accounting/sales/new-invoice');
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error loading invoices: {error}</div>; // Show error message
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Generate page numbers to display
  const getPageNumbers = (currentPage, totalPages) => {
    const pageNumbers = [];
    const totalPagesToShow = 3;

    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

    if (endPage - startPage + 1 < totalPagesToShow) {
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className="p-8 max-w-7xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <button 
          onClick={handleClickButton}
          className="inline-flex items-center px-4 py-2 bg-hazel-green hover:bg-sage text-white rounded-lg transition-colors duration-200"
        >
          <Plus className="h-4 w-4 mr-2" />
          New Invoice
        </button>
        <div className="flex items-center gap-4">
          <div className="relative">
            <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" />
            <input
              type="text"
              placeholder="Search invoices..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-10 pr-4 py-2 border border-mercury rounded-lg text-sm focus:outline-none focus:border-hazel-green"
            />
          </div>

          <div className="relative">
            <Button
              variant="outline"
              onClick={() => setIsCommandOpen(prev => !prev)}
              className="w-[180px] justify-between border-grey-goose text-bluish-grey hover:bg-tealish-blue"
            >
              {selectedStatus.label}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
            {isCommandOpen && (
              <div className="absolute left-0 z-50 mt-1">
                <SimpleCommand
                  items={statuses}
                  selectedItem={selectedStatus}
                  onSelect={(status) => {
                    setSelectedStatus(status);
                    setIsCommandOpen(false);
                  }}
                />
              </div>
            )}
          </div>

          <Button variant="outline" className="border-grey-goose text-bluish-grey hover:bg-tealish-blue">
            <Filter className="h-4 w-4 mr-2" />
            Filter
          </Button>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm border border-mercury overflow-x-auto">
        <table className="w-full min-w-[600px]">
          <thead>
            <tr className="bg-tealish-blue">
              <th className="px-2 py-1 text-left text-xs font-semibold text-bluish-grey">INVOICE #</th>
              <th className="px-2 py-1 text-left text-xs font-semibold text-bluish-grey">CLIENT NAME</th>
              <th className="px-2 py-1 text-left text-xs font-semibold text-bluish-grey">DATE</th>
              <th className="px-2 py-1 text-left text-xs font-semibold text-bluish-grey">TOTAL AMOUNT</th>
              <th className="px-2 py-1 text-left text-xs font-semibold text-bluish-grey">STATUS</th>
              <th className="px-2 py-1 text-left text-xs font-semibold text-bluish-grey">NOTES</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-mercury">
            {currentInvoices.length === 0 ? (
              <tr>
                <td colSpan="6" className="p-8 text-center text-bluish-grey">
                  No invoices found matching your criteria.
                </td>
              </tr>
            ) : (
              currentInvoices.map((invoice) => (
                <tr key={invoice.id} className="hover:bg-seashell transition-colors duration-200">
                  <td className="px-2 py-1 text-sm font-medium text-hazel-green">
                    <a href={`/accounting/invoices/${invoice.id}`} className="hover:underline">
                      {invoice.invoiceNumber}
                    </a>
                  </td>
                  <td className="px-2 py-1 text-sm text-bluish-grey">{clients[invoice.clientId]}</td>
                  <td className="px-2 py-1 text-sm text-bluish-grey">{new Date(invoice.date).toLocaleDateString()}</td>
                  <td className="px-2 py-1 text-sm text-bluish-grey">{invoice.totalAmount.toFixed(2)}</td>
                  <td className="px-2 py-1 text-sm text-bluish-grey">{invoice.status}</td>
                  <td className="px-2 py-1 text-sm text-bluish-grey">{invoice.notes || 'N/A'}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between mt-4">
        <span className="text-sm text-bluish-grey">
          Showing {currentInvoices.length} of {filteredInvoices.length} invoices
        </span>
        <div className="flex space-x-2">
          {getPageNumbers(currentPage, totalPages).map((number) => (
            <button
              key={number}
              onClick={() => handlePageChange(number)}
              className={cn(
                'px-4 py-2 rounded-lg transition-colors duration-200',
                currentPage === number ? 'bg-hazel-green text-white' : 'bg-white text-hazel-green hover:bg-tealish-blue'
              )}
            >
              {number}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

import { Search, Filter } from 'lucide-react';

import { Button } from '../../../../../components/shadCN/Button.tsx';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';


const quotes = [
  { id: 'QT-001', customer: 'Future Tech', date: '2024-03-15', amount: '€4,200.00', status: 'Accepted' },
  { id: 'QT-002', customer: 'Innovation Labs', date: '2024-03-14', amount: '€2,800.00', status: 'Pending' },
  { id: 'QT-003', customer: 'Smart Systems', date: '2024-03-13', amount: '€1,500.00', status: 'Rejected' },
];

export default function QuotesList() {

  const navigate = useNavigate();

  const handleClickButton = () => {
    navigate('/accounting/sales/new-quote');
  }

  
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
      <button 
          onClick={handleClickButton}
          className="inline-flex items-center px-4 py-2 bg-hazel-green hover:bg-sage text-white rounded-lg transition-colors duration-200"
        >
          <Plus className="h-4 w-4 mr-2" />
          New Quote
        </button>
        <div className="flex items-center gap-4">
          <div className="relative">
            <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" />
            <input
              type="text"
              placeholder="Search quotes..."
              className="pl-10 pr-4 py-2 border border-mercury rounded-lg text-sm focus:outline-none focus:border-hazel-green"
            />
          </div>
          <Button variant="outline" className="border-grey-goose text-bluish-grey hover:bg-tealish-blue">
            <Filter className="h-4 w-4 mr-2" />
            Filter
          </Button>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm border border-mercury">
        <div className="grid grid-cols-5 gap-4 p-5 border-b border-mercury bg-tealish-blue">
          <div className="text-sm font-medium text-bluish-grey">QUOTE #</div>
          <div className="text-sm font-medium text-bluish-grey">CUSTOMER</div>
          <div className="text-sm font-medium text-bluish-grey">DATE</div>
          <div className="text-sm font-medium text-bluish-grey">AMOUNT</div>
          <div className="text-sm font-medium text-bluish-grey">STATUS</div>
        </div>

        <div className="divide-y divide-mercury">
          {quotes.map((quote) => (
            <div
              key={quote.id}
              className="grid grid-cols-5 gap-4 p-5 hover:bg-seashell transition-colors duration-200"
            >
              <div className="text-hazel-green font-medium">{quote.id}</div>
              <div className="text-bluish-grey">{quote.customer}</div>
              <div className="text-bluish-grey">{quote.date}</div>
              <div className="text-bluish-grey font-medium">{quote.amount}</div>
              <div>
                <span
                  className={`px-3 py-1 rounded-full text-sm font-medium ${
                    quote.status === 'Accepted'
                      ? 'bg-[#EDF7ED] text-[#1E4620]'
                      : quote.status === 'Pending'
                      ? 'bg-[#FFF4E5] text-[#663C00]'
                      : 'bg-[#FDECEA] text-[#611A15]'
                  }`}
                >
                  {quote.status}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TableRow, TableCell } from '@mui/material';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import TableMoreMenu from './table-more-menu';
import PermissionCheck from '../../utils/PermissionCheck';
export default function CustomTableRow({
  row,
  columns,
  entity,
  showMoreActions,
  actionsType,
  editLink,
  onDeleteRow
}) {
  const renderColumnValue = (column, item) =>
    column.renderValue ? column.renderValue(item) : item[column.key];

  return (
    <TableRow className='w-full' key={row.id} hover>
      {columns.map((column, index) => (
        <TableCell
          key={index}
          className='flex flex-col'
          sx={{ p: 2, textAlign: column.textAlign || 'left' }}
          padding='none'
        >
          {renderColumnValue(column, row)}
        </TableCell>
      ))}
      {showMoreActions && (
        <>
          {actionsType === 'dots' ? (
            <TableCell>
              <TableMoreMenu
                rowId={row.id}
                entity={entity}
                editLink={editLink}
                handleDelete={onDeleteRow}
              />
            </TableCell>
          ) : (
            <>
              <PermissionCheck action={`edit_${entity}`}>
                <TableCell>
                  <Link to={editLink}>
                    <IconPencil
                      className='text-sage cursor-pointer'
                      size={20}
                    />
                  </Link>
                </TableCell>
              </PermissionCheck>
              <PermissionCheck action={`delete_${entity}`}>
                <TableCell>
                  <IconTrash
                    className='text-sage cursor-pointer'
                    size={20}
                    onClick={onDeleteRow}
                  />
                </TableCell>
              </PermissionCheck>
            </>
          )}
        </>
      )}
    </TableRow>
  );
}

CustomTableRow.propTypes = {
  row: PropTypes.object,
  editLink: PropTypes.string,
  onDeleteRow: PropTypes.func
};

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IconCalendarMonth, IconConfetti,IconDoor } from '@tabler/icons-react';
import PageTitle from '../../components/page-title';
import ConferenceRoomForm from './ConferenceRoomForm';

function AddConferenceRoomForm() {
  const search = useLocation().search;
  const [loading, setLoading] = useState(true);


  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconDoor className='text-hazel-green mr-2' size={22} />}
        title='Conference Room'
      />
      <ConferenceRoomForm
        // room={{
        //   number: null,
        //   floor: 0,
        //   status: '',
        //   roomTypeId: roomType.id,
        //   minibar: []
        // }}
        // roomType={roomType}
        loading={loading}
      />
    </div>
  );
}

export default AddConferenceRoomForm;

import React from 'react';

const StatusBadgeElement = ({ label, color }) => {
  return (
    <div className={`flex items-center px-4 py-2 rounded-full text-white ${color} shadow-md`}>
      <span className="font-medium">{label}</span>
    </div>
  );
};

export default StatusBadgeElement;

import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import store from '../store';
import { setUser } from '../store/slices/user-slice';
import Loader from '../components/loader';
import api from '../api/api';
import { login as loginApi, logout as logoutApi } from '../api/auth.api';
import { getLatestOwnerPasscode } from '../api/auth.api';
import { getRole, getUnauthRole } from '../api/roles';

import axios from 'axios';


const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  userPermissions: []
};

const verifyToken = (accessToken) => {
  if (!accessToken) return false;
  const decoded = jwtDecode(accessToken);
  return decoded.exp > Date.now() / 1000;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('access_token', accessToken);
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('access_token');
    delete api.defaults.headers.common.Authorization;
  }
};

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  loginWithPasscode: () => Promise.resolve(),
  logout: () => {}
});

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user.user);


  const login = async (email, password, ownerPasscode = '') => {
    try {
      const response = await loginApi(email, password, ownerPasscode);
      console.log('API Response:', response); // Log the entire response
  
      // Determine if the role is owner
      const isOwnerLogin = response.data.message === "Login successful with owner passcode";
      console.log("Is Owner Login:", isOwnerLogin);

      let accessToken = '';

      // Extract the token based on the response type
      if (isOwnerLogin) {
        accessToken = response.data.token || '';
      } else {
        accessToken = response.data.token || response.data.message || '';
      }
      console.log('Token received:', accessToken);
  
      // Check token validity
      if (!accessToken || accessToken.split('.').length !== 3) {
        throw new Error('Invalid token format');
      }
  
      const decodedToken = jwtDecode(accessToken);
  
      // Set session and handle role-based logic
      setSession(accessToken);
  
      const { roleId, adminUserId } = decodedToken; // Extract adminUserId from the decoded token
    let roleData = {};

    if (roleId) {
      const roleResponse = await getRole(roleId);
      roleData = roleResponse.data?.result || {};
    }

    console.log("ROLE DATA", roleData); // LINE 138 

    if (roleData.title === 'Owner' && ownerPasscode) {
      console.log("Owner trying to add the login");
      try {
        console.log("Fetching latest passcode");
    
        const latestPasscodeResponse = await getLatestOwnerPasscode();
        console.log("Latest Passcode Response:", latestPasscodeResponse); // Log the entire response
    
        if (latestPasscodeResponse.status === 200) {
          const latestPasscode = latestPasscodeResponse.data;
    
          if (latestPasscode.passcode === ownerPasscode && new Date(latestPasscode.expiresAt) > new Date()) {
            console.log("Adding the permission");
            roleData.permissions = roleData.permissions || [];
            if (!roleData.permissions.includes('in_time')) {
              console.log("Added the permission on time");
              roleData.permissions.push('in_time');
            }
            console.log("It has the permission");
          } else {
            console.error('Invalid or expired owner passcode');
          }
        } else {
          console.error('Error fetching latest owner passcode:', latestPasscodeResponse.message);
        }
      } catch (error) {
        console.error('Error fetching latest owner passcode:', error);
      }
    }
    
    console.log("Permissions:", roleData.permissions); // lINE 170
    
      
      const payload = {
        email: decodedToken.email,
        id: decodedToken.id,
        jobRole: decodedToken.jobRole,
        name: decodedToken.name,
        surname: decodedToken.surname,
        userPermissions: roleData.permissions || [], // Ensure userPermissions is set correctly 855048
        userRole: roleData.title || ''
      };
  
      store.dispatch(setUser(payload));
    } catch (error) {
      console.error('Login error:', error);
      store.dispatch(setUser(null));
    }
  };
  


  

  // const loginWithPasscode = async (userData) => {
  //   try {

  //     if (!userData?.user) {
  //       throw new Error('Invalid user data received during login');
  //     }

  //     const { user } = userData;
  //     const { email, id, roleId, jobRole, name, surname } = user;

  //     const token = userData?.token; 
  //     if (token) {
  //       // Set the token in the session and in axios headers for future requests
  //       setSession(token);
  //     } else {
  //       throw new Error("Token not provided in passcode login response");
  //     }

  //   // Fetch role data using the roleId, ensure token is sent with the request
  //   let roleData = {};
  //   if (roleId) {
  //     try {
  //       const roleResponse = await getUnauthRole(roleId);
  //       console.log("ROLE RESPONSE", roleResponse);
  //       roleData = roleResponse.data?.result || {};  // Handle role data
  //     } catch (roleError) {
  //       console.error("Error fetching role:", roleError);
  //     }
  //   }

  //     const payload = {
  //       email,
  //       id,
  //       jobRole,
  //       name,
  //       surname,
  //       userPermissions: roleData.permissions || [],
  //       userRole: roleData.title || ''
  //     };
  //     store.dispatch(setUser(payload));
  //     localStorage.setItem('user', JSON.stringify(payload));
  //   } catch (error) {
  //     console.error('Login with passcode error:', error);
  //   }
  // };

  const loginWithPasscode = async (userData) => {
    try {
        if (!userData?.user) {
            throw new Error('Invalid user data received during login');
        }

        const { user } = userData;
        const { email, id, roleId, jobRole, name, surname } = user;

        const token = userData?.token; // Token should now be included in userData
        if (token) {
            setSession(token); // Set the session with the received token
        } else {
            throw new Error("Token not provided in passcode login response");
        }

        // Fetch role data using the roleId
        let roleData = {};
        if (roleId) {
            try {
                const roleResponse = await getUnauthRole(roleId);
                roleData = roleResponse.data?.result || {}; // Handle role data
            } catch (roleError) {
                console.error("Error fetching role:", roleError);
            }
        }

        const payload = {
            email,
            id,
            jobRole,
            name,
            surname,
            userPermissions: roleData.permissions || [],
            userRole: roleData.title || ''
        };
        store.dispatch(setUser(payload)); // Dispatch the user data
        localStorage.setItem('user', JSON.stringify(payload)); // Store user info in local storage
    } catch (error) {
        console.error('Login with passcode error:', error);
    }
};



  const logout = async () => {
    const token = localStorage.getItem('access_token'); // Fetch the stored token
  
    if (!token) {
      console.error('No token found, unable to log out');
      return;
    }
  
    try {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.id;
  
      
      await logoutApi(userId);
  
     
      await logoutApi(token);
  
      setSession(null); 
      store.dispatch(setUser(null)); 
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  
  
  

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const accessToken = window.localStorage.getItem('access_token');
        const savedUser = JSON.parse(window.localStorage.getItem('user'));

        if (accessToken && verifyToken(accessToken)) {
          setSession(accessToken);

          const decodedToken = jwtDecode(accessToken);
          const { email, id, roleId, jobRole, name, surname } = decodedToken;
          let roleData = {};

          if (roleId) {
            const roleResponse = await getRole(roleId);
            roleData = roleResponse.data?.result || {};
          }

          const payload = {
            email,
            id,
            jobRole,
            name,
            surname,
            userPermissions: roleData.permissions || [],
            userRole: roleData.title || ''
          };

          store.dispatch(setUser(payload));
        } else if (savedUser) {
          store.dispatch(setUser(savedUser));
        } else {
          store.dispatch(setUser(null));
        }
      } catch (error) {
        console.error('Initialization error:', error);
        store.dispatch(setUser(null));
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={{ ...user, login, userPermissions: user?.userPermissions || [], loginWithPasscode, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

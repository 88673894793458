import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { 
  Edit, 
  MoreHorizontal, 
  Plus, 
  ChevronDown, 
  Mail, 
  Phone
} from 'lucide-react';
import { Button } from '../../../../components/shadCN/Button.tsx';
import { Tabs, TabsList, TabsTrigger } from '../../../../components/shadCN/Tabs.tsx';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

import { TransactionsTab } from '../../../../components/detailsClientPageTransactionComponents/TransactionsTab.tsx';

// Mock data for the chart
const mockData = [
  { month: 'Apr 2024', value: 0 },
  { month: 'May 2024', value: 2000 },
  { month: 'Jun 2024', value: 1500 },
  { month: 'Jul 2024', value: 3000 },
  { month: 'Aug 2024', value: 2500 },
  { month: 'Sep 2024', value: 4000 },
  { month: 'Oct 2024', value: 3500 },
];

const activities = [
  { 
    date: '27 Oct 2024',
    time: '12:19 PM',
    type: 'Invoice added',
    description: 'Invoice INV-000001 of amount €0,00 created',
    by: 'enisgj1489'
  },
  {
    date: '27 Oct 2024',
    time: '12:17 PM',
    type: 'Contact person added',
    description: 'Contact person Enis has been created',
    by: 'enisgj1489'
  },
  {
    date: '27 Oct 2024',
    time: '12:17 PM',
    type: 'Contact added',
    description: 'Contact created',
    by: 'enisgj1489'
  }
];

const OverviewTab = () => (
  <div className="grid grid-cols-3 gap-6">
    {/* Left Column */}
    <div className="col-span-2 space-y-6">
      {/* Contact Info */}
      <div className="bg-white rounded-xl p-6 border border-mercury">
        <div className="flex items-start gap-4">
          <div className="w-12 h-12 bg-tealish-blue rounded-full flex items-center justify-center">
            <span className="text-hazel-green font-semibold text-lg">E</span>
          </div>
          <div className="flex-1">
            <h2 className="text-lg font-semibold text-hazel-green">Enis Gjocaj</h2>
            <div className="space-y-2 mt-2">
              <div className="flex items-center gap-2 text-bluish-grey">
                <Mail className="h-4 w-4" />
                <span>enisg1489@gmail.com</span>
              </div>
              <div className="flex items-center gap-2 text-bluish-grey">
                <Phone className="h-4 w-4" />
                <span>045226446</span>
              </div>
            </div>
            <div className="flex gap-3 mt-4">
              <Button variant="outline" size="sm" className="text-bluish-grey">
                Edit
              </Button>
              <Button variant="outline" size="sm" className="text-bluish-grey">
                Invite to Portal
              </Button>
              <Button variant="outline" size="sm" className="text-bluish-grey">
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Address Section */}
      <div className="bg-white rounded-xl p-6 border border-mercury">
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-semibold text-hazel-green">ADDRESS</h3>
          <Button variant="ghost" size="sm">
            <ChevronDown className="h-4 w-4 text-bluish-grey" />
          </Button>
        </div>
        <div className="space-y-4">
          <div>
            <h4 className="text-sm text-bluish-grey mb-1">Billing Address</h4>
            <div className="flex items-center gap-2">
              <span className="text-heather">No Billing Address</span>
              <Button variant="link" className="text-hazel-green p-0 h-auto">
                Add new address
              </Button>
            </div>
          </div>
          <div>
            <h4 className="text-sm text-bluish-grey mb-1">Shipping Address</h4>
            <div className="flex items-center gap-2">
              <span className="text-heather">No Shipping Address</span>
              <Button variant="link" className="text-hazel-green p-0 h-auto">
                Add new address
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Activity Timeline */}
      <div className="bg-white rounded-xl p-6 border border-mercury">
        <h3 className="font-semibold text-hazel-green mb-6">Recent Activity</h3>
        <div className="space-y-6">
          {activities.map((activity, index) => (
            <div key={index} className="flex gap-4">
              <div className="w-32 text-sm text-bluish-grey">
                <div>{activity.date}</div>
                <div>{activity.time}</div>
              </div>
              <div className="w-2 relative">
                <div className="absolute top-2 left-1/2 -translate-x-1/2 w-2 h-2 rounded-full bg-hazel-green"></div>
                {index !== activities.length - 1 && (
                  <div className="absolute top-4 left-1/2 -translate-x-1/2 w-px h-full bg-mercury"></div>
                )}
              </div>
              <div className="flex-1">
                <h4 className="font-medium text-hazel-green">{activity.type}</h4>
                <p className="text-bluish-grey mt-1">{activity.description}</p>
                <p className="text-sm text-heather mt-1">by {activity.by}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    {/* Right Column */}
    <div className="space-y-6">
      {/* Financial Overview */}
      <div className="bg-white rounded-xl p-6 border border-mercury">
        <h3 className="font-semibold text-hazel-green mb-4">Receivables</h3>
        <div className="space-y-4">
          <div className="grid grid-cols-3 gap-4 text-sm">
            <div className="text-bluish-grey">CURRENCY</div>
            <div className="text-bluish-grey">OUTSTANDING</div>
            <div className="text-bluish-grey">UNUSED CREDITS</div>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div className="font-medium text-hazel-green">EUR- Euro</div>
            <div className="font-medium">€0,00</div>
            <div className="font-medium">€0,00</div>
          </div>
        </div>
      </div>

      {/* Income Chart */}
      <div className="bg-white rounded-xl p-6 border border-mercury">
        <div className="flex justify-between items-center mb-6">
          <h3 className="font-semibold text-hazel-green">Income</h3>
          <Button variant="outline" size="sm" className="text-bluish-grey">
            Last 6 Months
          </Button>
        </div>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={mockData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#E3E7E7" />
              <XAxis 
                dataKey="month" 
                stroke="#7c8b93"
                fontSize={12}
                tickLine={false}
              />
              <YAxis 
                stroke="#7c8b93"
                fontSize={12}
                tickLine={false}
                axisLine={false}
              />
              <Line 
                type="monotone" 
                dataKey="value" 
                stroke="#61805b"
                strokeWidth={2}
                dot={{ fill: '#61805b', strokeWidth: 2 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="mt-4 text-center text-bluish-grey">
          Total Income (Last 6 Months) - €0,00
        </div>
      </div>
    </div>
  </div>
);

export default function DetailsPage() {
  const [activeTab, setActiveTab] = useState('overview');

  return (
    <div className="min-h-screen bg-seashell">
      {/* Header */}
      <div className="bg-white border-b border-mercury px-8 py-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-4">
            <h1 className="text-2xl font-semibold text-hazel-green">Client Name</h1>
            <Button variant="ghost" size="sm">
              <ChevronDown className="h-4 w-4 text-bluish-grey" />
            </Button>
          </div>
          <div className="flex items-center gap-3">
            <Button variant="outline" size="sm" className="text-bluish-grey">
              <Edit className="h-4 w-4 mr-2" />
              Edit
            </Button>
            <Button variant="outline" size="sm" className="text-bluish-grey">
              0
            </Button>
            <Button className="bg-hazel-green hover:bg-sage text-white">
              <Plus className="h-4 w-4 mr-2" />
              New Transaction
            </Button>
            <Button variant="ghost" size="sm">
              <MoreHorizontal className="h-4 w-4 text-bluish-grey" />
            </Button>
          </div>
        </div>

        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="border-b border-mercury">
            {['Overview', 'Transactions'].map((tab) => (
              <TabsTrigger
                key={tab}
                value={tab.toLowerCase()}
                className="px-4 py-2 -mb-px data-[state=active]:border-b-2 data-[state=active]:border-hazel-green data-[state=active]:text-hazel-green"
              >
                {tab}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
      </div>

      {/* Content */}
      <div className="container mx-auto py-8 px-4">
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            {activeTab === 'overview' ? <OverviewTab /> : <TransactionsTab />}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}
import React from 'react';
import { IconUserPlus } from '@tabler/icons-react';
import PageTitle from '../../components/page-title';
import EmployeeForm from './EmployeeForm';

function AddEmployee() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconUserPlus className='text-hazel-green mr-2' size={22} />}
        title='Add New Employee'
      />
      <EmployeeForm />
    </div>
  );
}

export default AddEmployee;

import React from 'react';

const Modal = ({ title, titleSize, setShowModal, children, onClose }) => {
  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-4 sm:p-0'>
        <div className='relative w-full sm:w-[85%] md:w-1/2 my-2 sm:my-6 mx-auto max-w-3xl'>
          <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
            <div className='flex items-start justify-between p-3 sm:p-5 border-b border-solid border-blueGray-200 rounded-t'>
              <h3
                className={`text-hazel-green ${
                  titleSize ? titleSize : 'text-xl sm:text-2xl md:text-3xl'
                } font-semibold mt-2`}
              >
                {title}
              </h3>
              <button
                className='p-1 ml-auto bg-transparent border-0 float-right leading-none font-semibold outline-none focus:outline-none'
                onClick={onClose}
              >
                <span className='bg-transparent text-hazel-green h-6 w-6 text-2xl block outline-none focus:outline-none'>
                  ×
                </span>
              </button>
            </div>
            <div className='relative p-4 sm:p-6 flex-auto overflow-y-auto max-h-[80vh] sm:max-h-[70vh]'>
              {children}
            </div>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
    </>
  );
};

export default Modal;

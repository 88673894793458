import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../../components/custom-input';
import SubmitButton from '../../components/buttons/submit-button';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { ReactTyped } from "react-typed";
import Passcode from "./Passcode.tsx";
import { useParams } from 'react-router-dom';
import {
    IconBuildingSkyscraper,
    IconKeyOff,
    IconChefHat,
    IconWaterpolo

} from '@tabler/icons-react';


const iconMap = {
    key: IconKeyOff,
    hotel: IconBuildingSkyscraper,
    restaurant: IconChefHat,
    pool: IconWaterpolo,
  };

function PasscodePage() {
    const { icon } = useParams();
    const SelectedIcon = iconMap[icon];

    return (
        <div className='flex flex-col gap-6 items-center justify-center bg-hazel-green bg-loginBgImage bg-no-repeat bg-center bg-cover min-h-screen p-4 sm:p-6'>
            <div className='bg-white rounded-lg w-full max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl shadow-md px-6 sm:px-10 pt-8 sm:pt-10 pb-6 sm:pb-8'>
                <div className="flex flex-col items-center justify-center gap-4">
                    <h3 className='text-hazel-green text-3xl sm:text-4xl font-bold'>
                        Lake Side{" "}
                        <ReactTyped
                            strings={["Hotel", "Restaurant", "Pool"]}
                            typeSpeed={150}
                            loop
                            backSpeed={30}
                            cursorChar="~"
                            showCursor={true}
                        />
                    </h3>
                    <p className='text-hazel-green font-light italic'>
                        Welcome
                    </p>

                    <div className="my-6 rounded-full bg-hazel-green flex items-center justify-center p-10 sm:p-14">
                        <SelectedIcon className="w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32" color="white" />
                    </div>

                    <Passcode />
                </div>
            </div>
        </div>
    );
}

export default PasscodePage;
let port;

export const getPort = async () => {

    port = await navigator.serial.requestPort();
    await port.open({ baudRate: 9600 });

  return port;
};

export const closePort = async () => {
  if (port) {
    await port.close(function (err) {
        console.log('port closed', err);
    })
    port = null;
  }
};

import api from "./api";

export const getRecentPoolReservations = () => api.get("/api/poolsInterface/recent-reservations");

export const getAllPoolReservations = () => api.get('/api/poolsInterface/reservations');

export const getPaginatedPoolReservations = (page = 1, limit = 10) => 
    api.get(`/api/poolsInterface/reservations?page=${page}&limit=${limit}`);


export const getReservationStats = () => {
    return api.get("/api/poolsInterface/reservations/stats");
  };

export const createPoolReservation = (values) => api.post('/api/poolsInterface/reservations', values);


export const getPools = () => api.get('/api/poolsInterface/pools');

export const updatePool = (id, values) => api.patch(`/api/poolsInterface/${id}`, values);

export const getEarnings = () => api.get('/api/poolsInterface/earnings');

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import Loading from '../../components/loader';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import CustomSelectInput from '../../components/custom-select-input';
import SelectInput from '../../components/select-input';
import { getDishSections, createOrUpdateDishSection, updateDishSection } from '../../api/dishSection';
import { getAvailableProducts } from '../../api/products';
import { getMenu } from '../../api/menus';
import { uploadImage } from '../../api/api';
import { IconUpload, IconX } from '@tabler/icons-react';
import { dishTypes, units } from '../../constants/entities';

function DishSectionForm({ isEdit, dishSection = {} }) {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(true);
  const fileInputRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const getProductsList = useCallback(async () => {
    try {
      const response = await getAvailableProducts();
      const formattedProducts = response.data.map((product) => ({
        id: product.id,
        name: product.name,
        unit: product.unit
      }));
      setProducts(formattedProducts);
    } catch (err) {
      console.error('Failed to fetch products:', err);
    }
  }, []);

  const getMenusList = useCallback(async () => {
    try {
      const response = await getMenu();
      const formattedMenus = response.data.map((menu) => ({
        id: menu.id,
        name: menu.name
      }));
      setMenus(formattedMenus);
    } catch (err) {
      console.error('Failed to fetch menus:', err);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getProductsList();
      await getMenusList();
      setLoading(false);
    };
    fetchData();
  }, [getProductsList, getMenusList]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required('Name is required*'),
    type: Yup.string().required('Type is required*'),
    price: Yup.number().required('Price is required*').positive(),
    description: Yup.string().min(4).required('Description is required*'),
    imageUrl: Yup.string().nullable(),
    menuId: Yup.string().required('Menu is required*'),
    products: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required('Product is required*'),
        quantity: Yup.number().required('Quantity is required*').positive(),
        unit: Yup.string().required('Unit is required*')
      })
    ).required('Products are required*')
  });

  const initialValues = {
    name: dishSection.name || '',
    type: dishSection.type || '',
    price: dishSection.price || '',
    description: dishSection.description || '',
    imageUrl: dishSection.imageUrl || '',
    menuId: dishSection.menuId || '',
    products: dishSection.products || [{ id: '', quantity: 1, unit: '' }]
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 p-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            let imageUrl = values.imageUrl;
            if (uploadedFile) {
              const formData = new FormData();
              formData.append('file', uploadedFile);
              const uploadResponse = await uploadImage(formData);
              imageUrl = uploadResponse.data.url;
            }

            if (!imageUrl && !uploadedFile) {
              imageUrl = null;
            }

            if (isEdit) {
              await updateDishSection(dishSection.id, { ...values, imageUrl });
            } else {
              await createOrUpdateDishSection({ ...values, imageUrl });
            }
            
            navigate('/dish-sections');
            setSubmitting(false);
          } catch (err) {
            setErrors({ submit: err?.response?.data?.message || 'An error occurred' });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} className='space-y-5'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
              <CustomInput
                type='text'
                name='name'
                label='Name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.name && touched.name}
                errorMessage={errors.name}
              />
              <CustomSelectInput
                name='type'
                value={values.type}
                label='Type'
                options={dishTypes.map(type => ({ id: type, title: type }))}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.type && touched.type}
                errorMessage={errors.type}
              />
              <CustomInput
                type='number'
                name='price'
                label='Price'
                value={values.price}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.price && touched.price}
                errorMessage={errors.price}
              />
              <CustomInput
                type='text'
                name='description'
                label='Description'
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.description && touched.description}
                errorMessage={errors.description}
              />
              <CustomSelectInput
                name='menuId'
                value={values.menuId}
                label='Menu'
                options={menus.map(menu => ({ id: menu.id, title: menu.name }))}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.menuId && touched.menuId}
                errorMessage={errors.menuId}
              />
            </div>

            <div className='space-y-5'>
              <div className='col-span-1'>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Image (Optional)
                </label>
                {uploadedFile || values.imageUrl ? (
                  <div className='relative mb-3'>
                    <img
                      src={uploadedFile ? URL.createObjectURL(uploadedFile) : `http://localhost:3001${values.imageUrl}`}
                      alt='dish-section'
                      className='w-full h-32 rounded-md object-cover'
                    />
                    <button
                      type='button'
                      className='absolute top-0 right-0 border-sage rounded-tr-md rounded-bl-md bg-white p-1'
                      onClick={() => {
                        setUploadedFile(null);
                        setFieldValue('imageUrl', '');
                      }}
                    >
                      <div className='absolute top-0 right-0 border border-sage rounded-tr-md rounded-bl-md bg-white p-1 flex items-center justify-center'>
                        <div className='rounded-full w-4 h-4 border border-sage flex items-center justify-center text-sage'>
                          <IconX />
                        </div>
                      </div>
                    </button>
                  </div>
                ) : (
                  <>
                    <input
                      type='file'
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        handleFileChange(event);
                        setFieldValue('imageUrl', URL.createObjectURL(event.target.files[0]));
                      }}
                    />
                    <div
                      className='flex flex-col items-center justify-center bg-mercury w-full h-32 rounded-md cursor-pointer mb-3'
                      onClick={() => fileInputRef.current.click()}
                    >
                      <IconUpload className='text-hazel-green' size={30} />
                      <span className="text-sm text-gray-500 mt-2">Click to upload (optional)</span>
                    </div>
                  </>
                )}
              </div>
              <FieldArray
                name="products"
                render={arrayHelpers => (
                  <div>
                    {values.products.map((product, index) => (
                      <div key={index} className="flex items-center space-x-4">
                        <SelectInput
                          value={product.id}
                          label="Product"
                          options={products.map(p => ({
                            id: p.id,
                            title: p.name
                          }))}
                          onChange={e => setFieldValue(`products.${index}.id`, e.target.value)}
                          onBlur={handleBlur}
                          showError={errors.products && touched.products && errors.products[index] && touched.products[index]?.id}
                          errorMessage={errors.products && touched.products && errors.products[index] && touched.products[index]?.id}
                        />
                        <CustomInput
                          type="number"
                          name={`products.${index}.quantity`}
                          label="Quantity"
                          value={product.quantity}
                          onChange={(event) => {
                            setFieldValue(`products.${index}.quantity`, event.target.value);
                          }}
                          onBlur={handleBlur}
                          showError={errors.products && touched.products && errors.products[index] && touched.products[index]?.quantity}
                          errorMessage={errors.products && touched.products && errors.products[index] && touched.products[index]?.quantity}
                        />
                        <CustomSelectInput
                          name={`products.${index}.unit`}
                          value={product.unit}
                          label="Unit"
                          options={units.map(unit => ({ id: unit, title: unit }))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          showError={errors.products && touched.products && errors.products[index] && touched.products[index]?.unit}
                          errorMessage={errors.products && touched.products && errors.products[index] && touched.products[index]?.unit}
                        />
                        <button
                          type="button"
                          className="text-red-500"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <IconX />
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="mt-3 text-sm text-blue-500"
                      onClick={() => arrayHelpers.push({ id: '', unit: '', quantity: 1 })}
                    >
                      Add product
                    </button>
                  </div>
                )}
              />
              {errors.submit && <p className='text-error'>{errors.submit}</p>}
            </div>

            <div className='flex justify-end items-center w-full mt-4 text-sm'>
              <CancelButton onCancel={() => navigate('/dish-sections')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/2 md:w-1/6'
                title={`${isEdit ? 'Edit Dish Section' : 'Create Dish Section'}`}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default DishSectionForm;




import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RoomForm from './RoomForm';
import { getRoom } from '../../api/rooms';

import Loading from '../../components/loader';

function EditRoom() {
  const { id } = useParams();
  const [room, setRoom] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchRoom = async () => {
  //     try {
  //       const response = await getRoom(id);
  //       setRoom(response.data);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error('Error fetching room:', error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchRoom();
  // }, [id]);
  useEffect(() => {
    const fetchRoom = async () => {
      try {
        const response = await getRoom(id);
        console.log('Fetched Room:', response.data); // Add this line
        setRoom(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching room:', error);
        setLoading(false);
      }
    };

    fetchRoom();
  }, [id]);

  if (loading) return <Loading />;

  return (
    <div className='p-10'>
      <RoomForm room={room} isEdit={true} loading={loading} />
    </div>
  );
}

export default EditRoom;

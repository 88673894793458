import api from "./api";



export const deleteMessage = (messageId) =>
    api.delete(`/api/chat/messages/${messageId}`);



export const getMessagesByGroup = (groupId) => 
    api.get(`/api/chat/messages/${groupId}`);


export const sendMessage = (content, senderId, receiverId, groupId) => 
    api.post('/api/chat/messages', { content, senderId, receiverId, groupId });


export const getUserGroups = (userId) => 
    api.get(`/api/chat/groups/${userId}`);


export const createGroup = (name, creatorId) => 
    api.post('/api/chat/groups', { name, creatorId });

export const inviteUserToGroup = (groupId, name) => api.post(`/api/chat/groups/${groupId}/invite`, { name });
import React, { useState, useEffect, useCallback } from 'react';
import { AddButton } from '../../components/buttons';
import CustomTable from '../../components/table-components/table';
import Loading from '../../components/loader';
import { getUsers, deleteUser } from '../../api/users';

function UsersList() {
  const [usersList, setUsersList] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const getUsersList = useCallback(async () => {
    try {
      const response = await getUsers(rowsPerPage, currentPage + 1, searchText);

      setUsersList(response.data.users);
      setTotalCount(response.data.count);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, searchText]);

  useEffect(() => {
    getUsersList();
  }, [getUsersList]);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await deleteUser(id);
      setUsersList((prevState) => [
        ...prevState.filter((item) => item.id !== id)
      ]);
      setTotalCount(totalCount - 1);
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className='p-10'>
      <AddButton entity='users' link='/users/add-user' title='New user' />
      <CustomTable
        rows={usersList}
        columns={[
          {
            title: 'Name',
            renderValue: (item) => item.name
          },
          {
            title: 'Surname',
            renderValue: (item) => item.surname
          },
          {
            title: 'Email',
            renderValue: (item) => item.email
          },
          {
            title: 'Phone Number',
            renderValue: (item) => item.phoneNumber
          },
          // {
          //   title: 'Is Checked in',
          //   renderValue: (item) => (
          //     <div
          //       className={`${
          //         item.isCheckedIn
          //           ? 'bg-seashell text-bluish-grey'
          //           : 'bg-dawn-pink text-salmon-pink'
          //       } rounded-md py-3`}
          //     >
          //       <p className='text-center'>{item.isCheckedIn ? 'Yes' : 'No'}</p>
          //     </div>
          //   ),
          //   textAlign: 'center'
          // },
          {
            title: 'Role',
            renderValue: (item) => item.role.title
          },
          {
            title: 'Phone Number',
            renderValue: (item) => item.secondaryPhoneNumber
          }
        ]}
        entity='users'
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={true}
        editLink='/users'
        onDelete={handleDelete}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </div>
  );
}

export default UsersList;

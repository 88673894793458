import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import DishSectionForm from './DishSectionForm';
import Loading from '../../components/loader';
import { getDishSectionById } from '../../api/dishSection';

function EditDishSection() {
  const { id } = useParams();
  const [dishSection, setDishSection] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDishSection = async () => {
      try {
        const response = await getDishSectionById(id);
        setDishSection(response.data);
      } catch (error) {
        console.error('Failed to fetch dish section:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDishSection();
  }, [id]);

  if (loading) return <Loading />;

  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faUtensils}
            className='text-hazel-green mr-2 w-12'
          />
        }
        title='Edit dish section'
      />
      <DishSectionForm isEdit={true} dishSection={dishSection} />
    </div>
  );
}

export default EditDishSection;
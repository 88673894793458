import React, { useState, useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  tableCellClasses,
  Paper,
  styled,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IconDownload } from "@tabler/icons-react";
import Loading from "../../components/loader";
import { getInvoice as getInvoiceApi } from "../../api/invoices";

const theme = createTheme({
  palette: {
    primary: {
      main: "#90a68c",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PdfDownload = ({ rowId }) => {
  const [invoice, setInvoice] = useState({});
  const [loading, setLoading] = useState(true);
  const pdfRef = useRef();

  useEffect(() => {
    getInvoice();
  }, []);

  const getInvoice = async () => {
    setLoading(true);
    try {
      const response = await getInvoiceApi(rowId);
      setInvoice(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const downloadPDF1 = (invoiceNumber) => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL(" image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save(`Invoice-${invoiceNumber}.pdf`);
    });
  };

  const downloadPDF = (invoiceNumber) => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF("p", "mm");
      doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "", "FAST");
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          canvas,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        heightLeft -= pageHeight;
      }
      doc.save(`Invoice-${invoiceNumber}.pdf`);
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div id="container" style={{ flexDirection: "row" }}>
      <ThemeProvider theme={theme}>
        <TableContainer ref={pdfRef} component={Paper} sx={{ padding: "12px" }}>
          <h3 className="text-hazel-green font-medium uppercase">
            INVOICE: {invoice.number}
          </h3>
          <div className="my-3 border-b boder-hazel-green" />

          <div className="grid justify-between items-center grid-cols-3">
            <div>
              <p className="break-words">
                <span className="text-hazel-green">Provider Info:</span>
                <br />
                Leshuar nga: {invoice.provider}, <br />
                NIF: {invoice.providerNumber}, <br />
                Phone: {invoice.providerPhoneNumber}, <br />
                Address:{invoice.providerAddress}
              </p>
            </div>
            <div className="text-center">
              <h3 className="text-hazel-green font-medium uppercase ">
                INVOICE
              </h3>
            </div>
            <div className="justify-right">
              <p>
                <span className="text-hazel-green">Details:</span>
                <br />
                Invoice Number: {invoice.number}, <br />
                Date:{" "}
                {invoice.date &&
                  new Date(
                    invoice.date.split("/").reverse().join("-")
                  ).toLocaleDateString("en-GB")}
                <br />
                Payment: {invoice.paymentType}, <br />
                Payment Date:
                {invoice.dueDate &&
                  new Date(
                    invoice.dueDate.split("/").reverse().join("-")
                  ).toLocaleDateString("en-GB")}
              </p>
            </div>
          </div>

          <Table
            sx={{ minWidth: 700 }}
            aria-label="customized table"
            className="mt-6"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>PRODUCT</StyledTableCell>
                <StyledTableCell align="right">QUANTITY | UNIT</StyledTableCell>
                <StyledTableCell align="right">TYPE</StyledTableCell>
                <StyledTableCell align="right">
                  UNIT PRICE&nbsp;(€)
                </StyledTableCell>
                <StyledTableCell align="right">SALE&nbsp;(%)</StyledTableCell>
                <StyledTableCell align="right">VAT&nbsp;(%)</StyledTableCell>
                <StyledTableCell align="right">
                  TOTAL PRICE&nbsp;(€)
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {console.log(invoice)}

            {invoice?.products?.length > 0 ? (
              <TableBody>
                {invoice.products.map((row) => (
                  <>
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.quantity} | {row.unit}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.type}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.unitPrice.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.discountPercentage}
                      </StyledTableCell>

                      <StyledTableCell align="right">{row.vat}</StyledTableCell>

                      <StyledTableCell align="right">
                        {row.totalPrice.toFixed(2)}
                      </StyledTableCell>
                    </StyledTableRow>
                    {row.description ? (
                      <StyledTableRow>
                        <StyledTableCell align="left" colSpan={7}>
                          {row.name}: {row.description}
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : null}
                  </>
                ))}
              </TableBody>
            ) : (
              <div></div>
            )}
          </Table>

          <div className="flex justify-between items-center mt-6">
            <div>
              <p>
                <span className="text-hazel-green">
                  TERMS & CONDITION/NOTES:
                </span>
                <br />
                Please note that this invoice is intended solely for internal
                use within Lakeside Hotel for administrative purposes.
              </p>
              <p>
                <span className="text-hazel-green"> LakeSide Hotel </span>
                <br />
                Phone: +38349520779, <br />
                address: dfeefew, <br />
                Email: dwfewfe@gmail.com <br />
              </p>
            </div>

            <div>
              <TableBody>
                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell colSpan={2}>Payment</TableCell>
                  <TableCell align="right">{invoice.paymentType}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Subtotal</TableCell>
                  <TableCell align="right">
                    {invoice.vatPrice.toFixed(2)}&nbsp;€
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Total</TableCell>
                  <TableCell align="right">
                    {invoice.totalPrice.toFixed(2)}&nbsp;€
                  </TableCell>
                </TableRow>
              </TableBody>
            </div>
          </div>
        </TableContainer>

        <div className="row items-center mt-5 text-sage cursor-pointer">
          <div
            className="flex items-center  justify-center"
            onClick={() => downloadPDF(invoice.number)}
          >
            <p>Download PDF</p>
            <IconDownload size={20} />
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default PdfDownload;

import React, { useState, useEffect } from 'react';
import {
  Cut,
  Line,
  Printer,
  Text,
  render,
  QRCode,
  Image
} from "react-thermal-printer";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { SubmitButton } from '../../components/buttons';
import { getPools, createPoolReservation } from "../../api/poolsInterface";
import { generateINPFile } from '../../api/inpFile';
import { getPort, closePort } from '../pools/serialPortManager';

const AddTicket = ({ poolType, poolId }) => {
  const [pools, setPools] = useState({});

  useEffect(() => {
    async function fetchPools() {
      try {
        const response = await getPools();
        const poolsData = response.data.reduce((acc, pool) => {
          acc[pool.type] = {
            id: pool.id,
            tickets: pool.tickets
          };
          return acc;
        }, {});
        setPools(poolsData);
      } catch (error) {
        console.error('Failed to fetch pools:', error);
      }
    }

    fetchPools();
  }, []);

  const getTicketImage = (type) => {
    if (type === 'WOMAN' || type === "woman") {
      return 'https://scontent.fprn4-1.fna.fbcdn.net/v/t1.15752-9/448838960_838378207849442_8537274201646011122_n.png?_nc_cat=101&ccb=1-7&_nc_sid=9f807c&_nc_ohc=B4WyjUUjFbwQ7kNvgEMJeak&_nc_ht=scontent.fprn4-1.fna&oh=03_Q7cD1QGXzq0LnRoAs-6-61QscPkNlKM54Sjzvm5sgRJeh8bRFQ&oe=66B74829';
    } else if (type === 'MAN' || type === "man") {
      return 'https://scontent.fprn4-1.fna.fbcdn.net/v/t1.15752-9/449683173_1667776560672670_756979275964704068_n.png?_nc_cat=110&ccb=1-7&_nc_sid=9f807c&_nc_ohc=c64DeVc3c6AQ7kNvgHF-kqo&_nc_ht=scontent.fprn4-1.fna&oh=03_Q7cD1QE-04KPjZjaryYqyQuEhL1j6M1ngdTVANefv77jVqzvGg&oe=66B734BC';
    } else return 'https://scontent.fprn4-1.fna.fbcdn.net/v/t1.15752-9/448905717_1245950660148007_8607451711283834830_n.png?_nc_cat=111&ccb=1-7&_nc_sid=9f807c&_nc_ohc=zv088r--zAUQ7kNvgEqAM54&_nc_ht=scontent.fprn4-1.fna&oh=03_Q7cD1QHLdf6zt-DKFI_9IXNwfmCxfHc_PqlKwVQnKq28n9_3hw&oe=66B73F87';
  };

  const handlePrint = async (data) => {
    const { id, price, ticketType } = data; 
    const reservationData = { id, price, ticketType };
    await generateINPFile(reservationData);

    const receiptContent = await render(
      <Printer type="epson">
        <Image align="center" src="https://scontent.fprn4-1.fna.fbcdn.net/v/t1.15752-9/448909287_2753223888169196_8294231871019942389_n.png?_nc_cat=107&ccb=1-7&_nc_sid=9f807c&_nc_ohc=kRZLE2CpIIoQ7kNvgG6yT1M&_nc_ht=scontent.fprn4-1.fna&oh=03_Q7cD1QF3sALjRmEr7_HfRKC4hW1sIF0FwdL1lkkFi49tCtB5ug&oe=66B71D73" />
        <Line />
        <Text bold={true} size={{ width: 1.8, height: 1.8 }}>ÇMIMI: {data.price} Euro</Text>
        <Text bold={true} size={{ width: 1.8, height: 1.8 }}>DATA:ORA:</Text>
        <Line />
        <Image align="center" src={getTicketImage(data.ticketType)} />
        <QRCode align="center" size={{ width: 1.8, height: 1.8 }} content="https://lakesideks.com/" />
        <Cut lineFeeds={7} />
      </Printer>
    );

    const port = await navigator.serial.requestPort();
    await port.open({ baudRate: 9600 });

    if (port.readable && port.writable) {
      const writer = port.writable.getWriter();
      const encodedData = new Uint8Array(receiptContent);
      await writer.write(encodedData);
      await writer.releaseLock();

      setTimeout(async () => {
        await closePort();
        await port.close();
      }, 2000);
    }
  };

  return (
    <div className="z-50 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-6">
      {pools[poolType]?.tickets?.map((item, index) => (
        <Formik
          key={index}
          initialValues={{
            ticketTitle: item.title,
            price: item.price
          }}
          validationSchema={Yup.object().shape({
            ticketTitle: Yup.string(),
            price: Yup.number()
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              const payload = {
                poolId,
                ticketType: values.ticketTitle.toUpperCase(),
                price: values.price
              };

              const response = await createPoolReservation(payload);
              const { data } = response;

              await handlePrint(data);

              setSubmitting(false);
            } catch (err) {
              setErrors({
                submit: err?.response?.data
              });
              setSubmitting(false);
            }
          }}
        >
          {({ values, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="z-50 flex flex-col items-center p-4 bg-white border border-gray-200 rounded-lg shadow-md">
                {/* <div className="mb-2 text-lg font-semibold text-gray-800">
                  {values.ticketTitle} Ticket
                </div> */}

                <Image align="center" src={getTicketImage(values.ticketTitle)} />
                <SubmitButton
                  isSubmitting={isSubmitting}
                  width="w-full"
                  title={`Add ${values.ticketTitle} ticket`}

                />
              </div>
            </form>
          )}
        </Formik>
      ))}
    </div>
  );
};

export default AddTicket;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IconBox } from '@tabler/icons-react'; // Assuming you have an icon for stock items
import StockItemForm from './StockItemForm';
import PageTitle from '../../components/page-title';
import Loading from '../../components/loader';
import { getStockItemById as getStockItem } from '../../api/stock';

function EditStockItem() {
  const params = useParams();
  const stockItemId = params.id;
  const [stockItem, setStockItem] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStockItemData();
  }, []);

  const getStockItemData = async () => {
    try {
      const response = await getStockItem(stockItemId);
      setStockItem(response.data);
      console.log('STOCK ID DATA', response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconBox className='text-hazel-green mr-2' size={22} />}
        title='Edit Stock Item'
      />
      <StockItemForm isEdit={true} stockItem={stockItem} /> 
    </div>
  );
}

export default EditStockItem;

import React from "react";

import { accountingBooks } from "../../../../../components/data/accountingCard.ts";
import AccountingCard from "../../../../../components/AccountingCard.tsx";

function JournalEntriePage() {

    return (
        <div className="min-h-screen bg-seashell p-6 md:p-8 lg:p-10">
            <div className="max-w-7xl mx-auto">
                <div className="mb-8">
                <h1 className="text-2xl md:text-3xl font-bold text-hazel-green">
                    Accounting Books
                </h1>
                <p className="mt-2 text-bluish-grey">
                    Manage and view all your hotel's financial records in one place
                </p>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {accountingBooks.map((book, index) => (
                        <AccountingCard
                            key={index}
                            title={book.title}
                            description={book.description}
                            icon={book.icon}
                            accentColor={book.accentColor}
                            borderColor={book.borderColor}
                            bgColor={book.bgColor}
                            link={book.link}
                        />
                    ))}
                </div>
            </div>
            </div>
    );
}

export default JournalEntriePage;
import React, { useState, useEffect , useRef} from 'react';
import ReactToPrint from 'react-to-print';

import useAuth from '../../hooks/useAuth';

import printJS from 'print-js';

import { useSelector } from 'react-redux';
import api, { createPoolReservation} from '../../api/pools';
import { generateINPFile } from "../../api/inpFile";

import { getPort, closePort } from './serialPortManager';






const checkAndClosePort = async (port) => {
  if (port && port.readable || port.writable) {
    try {
      await port.close();
      console.log('Port closed successfully');
    } catch (error) {
      console.error('Error closing the port:', error);
    }
  }
};























export function PrintButton({item , createReservation,small=false, gray=false, className="", ...props}) {

    const printRef = useRef();

    const { user } = useSelector((state) => state.user);

    const [reservation, setReservation] = useState(null);



   

    // const handleCreateReservation = async () => {
    //   try {
    //     const poolId = item.poolId;
    //     const ticketType = item.ticketType; 
    //     const price = item.price; 
  
    //     await createReservation(poolId, ticketType, price);
  
    //     console.log('Reservation created successfully');
    //   } catch (error) {
    //     console.error('Error creating reservation:', error);
    //   }
    // };




    const handleCreateReservation = async () => {


      try {
          const { poolId, ticketType, price } = item;
          const userId = user.id;
          const response = await createPoolReservation({
              poolId,
              userId,
              ticketType,
              price,
              userName: user.username,
          });
          console.log("Resercation created")

          const newReservation = response.data.price;
          console.log(newReservation)
          setReservation(newReservation);
          // await handleGenerateINPFile(newReservation);
          
          try {

      
            const port = await getPort();
            const writer = port.writable.getWriter();
      
            const encodedData = new Uint8Array([/* data to be written */]);
            await writer.write(encodedData);
            await writer.releaseLock();
            await checkAndClosePort(port);
          } catch (error) {
            console.error('Error generating INP file or handling port:', error);
          }

          
      } catch (error) {
          console.error('Error creating reservation:', error);
      }
  };

  const handleGenerateINPFile = async (reservation) => {
    if (!reservation) {
        console.error('No reservation found.');
        return;
    }

    const { price } = reservation;

    if (price === undefined) {
        console.error('Price is missing');
        return;
    }

    try {
        const reservationData = {
            price
        };
        const generateResponse = await generateINPFile(reservationData);
        console.log("INP file generated successfully:", generateResponse.data.message);
    } catch (error) {
        console.error('Error generating INP file:', error);
    }
};



    // const handleCreateReservation = async () => {
    //   try {
    //     const poolId = item.poolId; 
    //     const ticketType = item.ticketType; 
    //     const price = item.price; 
  
    //     await createReservation(poolId, ticketType, price);
  
    //     const userName = `${user.name} ${user.surname}`;
    //     const reservationId = item.reservationId; 
    //     const subtotal = item.woman.totalPrice + item.man.totalPrice + item.kid.totalPrice;
    //     const tax = subtotal * 0.18;
    //     const total = subtotal + tax;
  
    //     const userTicket = {
    //       userName,
    //       poolId,
    //       reservationId,
    //       ticketType,
    //       price,
    //       subtotal,
    //       total,
    //     };
  
    //   } catch (error) {
    //     console.error('Error creating reservation and user ticket:', error);
    //   }
    // };





    const sizeClasses = small ? "px-2 py-1" : "px-4 py-2 font-bold";
	  const colorClasses = gray ? "bg-gray-400 focus-visible:bg-gray-300  hover:bg-gray-300" : "bg-sage  hover:bg-teal-700"

    return (
        <>
        <ReactToPrint
          trigger={() => (



          <button onClick={handleCreateReservation} className={`rounded-full transition-colors duration-200 disabled:cursor-not-allowed disabled:opacity-50 text-white ${sizeClasses} ${colorClasses} ${className}`} {...props}></button>
          )}
          content={() => printRef.current}
        />

{/* <button onClick={handleGenerateINPFile } className={`rounded-full transition-colors duration-200 disabled:cursor-not-allowed disabled:opacity-50 text-white ${sizeClasses} ${colorClasses} ${className}`} {...props}></button> */}


          {/* <button onClick={handleCreateReservation} className={`rounded-full transition-colors duration-200 disabled:cursor-not-allowed disabled:opacity-50 text-white ${sizeClasses} ${colorClasses} ${className}`} {...props}></button> */}

        <div style={{ display: 'none' }}>
          <ComponentToPrint ref={printRef} item={item} createReservation={createReservation}  />
        </div>
      </>
      );
}


const ComponentToPrint = React.forwardRef(({ item }, ref) => {

    
    const { user } = useSelector((state) => state.user);


    const printRef = useRef();

    console.log(user)
      
    const subtotal = item.woman.totalPrice + item.man.totalPrice + item.kid.totalPrice;

    const taxPercentage = 0.18;
    const tax = subtotal * taxPercentage;

    const total = subtotal + tax;



    return (

    <div ref={ref} className="bg-white rounded-lg shadow-lg px-8 py-10 max-w-xl mx-auto">
    <div className="flex items-center justify-between mb-8">
      <div className="flex items-center">
        <img className="h-8 w-8 mr-2" src="/images/lake-side-logo.png" alt="Logo" style={{ maxWidth: '40px' }} />
        <div className="text-gray-700 font-semibold text-lg">LAKESIDE HOTEL</div>
      </div>
      <div className="text-gray-700">
        <div className="font-bold text-xl mb-2">INVOICE</div>
        <div className="text-sm">Date: {item.date}</div>

        <div className="text-sm">NR#: {item.invoiceNumber}</div>
      </div>
    </div>
    
    <div className="border-b-2 border-gray-300 pb-8 mb-8">
      <h2 className="text-2xl font-bold mb-4">Bill To:</h2>
      <div className="text-gray-700 mb-2">{user.name} {user.surname}</div>
      <div className="text-gray-700 mb-2">{user.email}</div>
      <div className="text-gray-700 mb-2">{item.customerCityStatePostal}</div>
      <div className="text-gray-700">{item.customerEmail}</div>
    </div>

    <table className="w-full text-left mb-8">
      <thead>
        <tr>
          <th className="text-gray-700 font-bold uppercase py-2">Description</th>
          <th className="text-gray-700 font-bold uppercase py-2">Quantity</th>
          <th className="text-gray-700 font-bold uppercase py-2">Price</th>
          <th className="text-gray-700 font-bold uppercase py-2">Total</th>
        </tr>
      </thead>
      <tbody>
        {/* Women */}
    <tr>
      <td className="py-4 text-gray-700">Women</td>
      <td className="py-4 text-gray-700">{item.woman.count}</td>
      <td className="py-4 text-gray-700">{item.woman.totalPrice}€</td>
      <td className="py-4 text-gray-700">{item.woman.totalPrice}€</td>
    </tr>
    
    {/* Men */}
    <tr>
      <td className="py-4 text-gray-700">Men</td>
      <td className="py-4 text-gray-700">{item.man.count}</td>
      <td className="py-4 text-gray-700">{item.man.totalPrice}€</td>
      <td className="py-4 text-gray-700">{item.man.totalPrice}€</td>
    </tr>
    
    {/* Kids */}
    <tr>
      <td className="py-4 text-gray-700">Kids</td>
      <td className="py-4 text-gray-700">{item.kid.count}</td>
      <td className="py-4 text-gray-700">{item.kid.totalPrice}€</td>
      <td className="py-4 text-gray-700">{item.kid.totalPrice}€</td>
    </tr>
      </tbody>
    </table>
    
    <div className="flex justify-end mb-8">
      <div className="text-gray-700 mr-2">Subtotal:</div>
      <div className="text-gray-700">{subtotal.toFixed(2)}€</div>
    </div>
    
    
    <div className="border-t-2 border-gray-300 pt-8 mb-8">
        <div className='my-6 text-2xl italic text-center'>THANK YOU</div>
      {/* <div className="text-gray-700 mb-2">Payment is due within 30 days. Late payments are subject to fees.</div> */}
      <div className="text-gray-700 mb-2">Please make checks payable to LAKESIDE HOTEL and mail to:</div>
      {/* <div className="text-gray-700">123 Main St., Anytown, USA 12345</div> */}
    </div>
  </div>
  );

});





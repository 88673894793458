import api from './api';
import unauthAPI from "./noAuthApi"

export const getRoles = (rowsPerPage, currentPage, searchText) =>
  api.get(
    `/api/roles?limit=${rowsPerPage}&page=${currentPage}&searchText=${searchText}`
  );

export const createRole = (values) => api.post(`/api/roles`, values);

export const getRole = (id) => api.get(`/api/roles/${id}`);

export const getUnauthRole = (id) => unauthAPI.get(`/api/roles-unauth/${id}`);

export const updateRole = (id, values) => api.patch(`/api/roles/${id}`, values);

export const deleteRole = (id) => api.delete(`/api/roles/${id}`);


export const getAllRoles = () => api.get('/api/roles-unauth/all-roles');
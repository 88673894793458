import React from 'react';
import { format } from 'date-fns'; // Import format from date-fns

interface BookingDetailsProps {
  fromDate: string;
  toDate: string;
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  adults: number;
  children: number;
  totalPrice: number;
  paidPrice: number;
}

const BookingDetails: React.FC<BookingDetailsProps> = ({
  fromDate,
  toDate,
  name,
  surname,
  email,
  phoneNumber,
  adults,
  children,
  totalPrice,
  paidPrice,
}) => {
  // Format the dates
  const formattedFromDate = format(new Date(fromDate), 'yyyy-MM-dd'); // Change format as needed
  const formattedToDate = format(new Date(toDate), 'yyyy-MM-dd'); // Change format as needed

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">Start</label>
          <input
            type="date"
            value={formattedFromDate} // Use formatted date
            className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
            readOnly
          />
        </div>
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">End</label>
          <input
            type="date"
            value={formattedToDate} // Use formatted date
            className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
            readOnly
          />
        </div>
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">Duration</label>
          <input
            type="text"
            value={`${Math.abs(new Date(toDate).getTime() - new Date(fromDate).getTime()) / (1000 * 3600 * 24)} Night(s)`}
            disabled
            className="w-full px-2 py-1.5 text-sm bg-tealish-blue border border-grey-goose rounded-md"
          />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">Adult</label>
          <select className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green" value={adults}>
            {[...Array(5)].map((_, i) => (
              <option key={i} value={i + 1}>{i + 1}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">Child</label>
          <select className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green" value={children}>
            {[...Array(4)].map((_, i) => (
              <option key={i} value={i}>{i}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">Name</label>
          <input
            type="text"
            value={`${name} ${surname}`}
            className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
            readOnly
          />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">Phone</label>
          <input
            type="tel"
            value={phoneNumber}
            className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
            readOnly
          />
        </div>
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">Email</label>
          <input
            type="email"
            value={email}
            className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
            readOnly
          />
        </div>
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">Enquiry No</label>
          <input
            type="text"
            value="XBB10001" // This can be passed as a prop if needed
            className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
          />
        </div>
      </div>

      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">Reserved By</label>
        <input
          type="text"
          value={`${name} ${surname}`} // Use name and surname from props
          className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
          readOnly
        />
      </div>

      <div className="flex justify-end space-x-3 pt-2">
        <button className="px-4 py-1.5 text-sm bg-hazel-green text-white rounded-md hover:bg-sage transition-colors">
          View Details
        </button>
      </div>
    </div>
  );
};

export default BookingDetails;
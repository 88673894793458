import React from 'react';
import Flatpickr from 'react-flatpickr';

import "flatpickr/dist/themes/material_green.css";


// function Datepicker({
//   align
// }) {

//   const options = {
//     mode: 'range',
//     static: true,
//     monthSelectorType: 'static',
//     dateFormat: 'M j, Y',
//     defaultDate: [new Date().setDate(new Date().getDate() - 6), new Date()],
//     prevArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
//     nextArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
//     onReady: (selectedDates, dateStr, instance) => {
//       instance.element.value = dateStr.replace('to', '-');
//       const customClass = (align) ? align : '';
//       instance.calendarContainer.classList.add(`flatpickr-${customClass}`);
//     },
//     onChange: (selectedDates, dateStr, instance) => {
//       instance.element.value = dateStr.replace('to', '-');
//     },
//   }

//   return (
//     <div className="z-2 relative">
//       <Flatpickr className="z-2 form-input pl-9 dark:bg-gray-800 text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-100 font-medium w-[15.5rem]" options={options} />
//       <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
//         <svg className="fill-current text-gray-400 dark:text-gray-500 ml-3" width="16" height="16" viewBox="0 0 16 16">
//           <path d="M5 4a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H5Z" />
//           <path d="M4 0a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4ZM2 4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4Z" />
//         </svg>
//       </div>
//     </div>
//   );
// }


function Datepicker({ align }) {
  const options = {
    mode: 'range',
    static: true,
    monthSelectorType: 'static',
    dateFormat: 'M j, Y',
    defaultDate: [new Date().setDate(new Date().getDate() - 6), new Date()],
    prevArrow: '<svg class="fill-current text-gray-600 dark:text-gray-300" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow: '<svg class="fill-current text-gray-600 dark:text-gray-300" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    onReady: (selectedDates, dateStr, instance) => {
      instance.element.value = dateStr.replace('to', '-');
      const customClass = align ? align : '';
      instance.calendarContainer.classList.add(`flatpickr-${customClass}`);
    },
    onChange: (selectedDates, dateStr, instance) => {
      instance.element.value = dateStr.replace('to', '-');
    },
  };

  return (
    <div className="relative z-10">
      <Flatpickr
        className="form-input pl-12 py-2 pr-4 text-gray-700 dark:bg-gray-800 dark:text-gray-300 bg-white border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:border-blue-500 dark:focus:border-blue-400 focus:ring focus:ring-blue-500 dark:focus:ring-blue-400 focus:ring-opacity-50 w-full"
        options={options}
      />
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg className="fill-current text-gray-400 dark:text-gray-500" width="16" height="16" viewBox="0 0 16 16">
          <path d="M5 4a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H5Z" />
          <path d="M4 0a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4ZM2 4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4Z" />
        </svg>
      </div>
    </div>
  );
}


export default Datepicker;

import axios from "axios";

const API_URL = 'http://localhost:3001/api/cleaning';

// Cleaning Reports
export const createCleaningReport = (values) => {
  if (!values.cleanerId) {
    throw new Error('cleanerId is required');
  }
  return axios.post(`${API_URL}/reports`, values);
};

export const getCleaningReports = (limit = 10, page = 1) => {
  return axios.get(`${API_URL}/reports`, {
    params: { limit, page },
  });
};
//the api to get the latest repairt for a sopeicfic room

// export const getLatestCleaningReport = (roomNumber) => {
//   return axios.get(`${API_URL}/reports/room/${roomNumber}/latest`);
// };

// lakeside-admin-client-mainv2/src/api/cleaning.js

export const getLatestCleaningReport = async (roomNumber) => {
  try {
    const response = await axios.get(`${API_URL}/reports/room/${roomNumber}/latest`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.warn(`No cleaning report found for room ${roomNumber}. Returning default data.`);
      return null; // Return null or default data
    }
    throw error; // Re-throw other errors
  }
};

export const getCleaningReportById = (id) => {
  return axios.get(`${API_URL}/reports/${id}`);
};

export const getCleaningReportsByRoom = (roomNumber) => {
  return axios.get(`${API_URL}/reports/room/${roomNumber}`);
};

export const updateCleaningReport = (id, values) => {
  return axios.put(`${API_URL}/reports/${id}`, values);
};

export const deleteCleaningReport = (id) => {
  return axios.delete(`${API_URL}/reports/${id}`);
};

// Major Issues
export const createMajorIssue = (values) => {
  return axios.post(`${API_URL}/major-issues`, values);
};

export const getMajorIssuesByRoom = (roomNumber) => {
  return axios.get(`${API_URL}/major-issues/${roomNumber}`);
};

// Formatted Report Data
export const getFormattedReportData = (reportId) => {
  return axios.get(`${API_URL}/reports/${reportId}/formatted`);
};
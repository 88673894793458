import React from "react";
import Heading from "../common/Heading";

import Header from "../common/Header";
import Footer from "../common/Footer";

export default function Booking() {
  return (
    <>
      <Header />
      <Heading heading="Booking" title="Home" subtitle="Booking" />
      <Footer />
    </>
  );
}

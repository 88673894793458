import React from 'react';

const ConfirmMessage = ({ title, text, onCancel, onConfirm }) => {
  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative w-auto my-6 mx-auto max-w-3xl'>
          <div className='rounded-lg shadow-lg relative flex flex-col w-full bg-white'>
            <div className='flex flex-col items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t'>
              {title && (
                <h3 className='text-hazel-green text-xl font-semibold'>
                  {title}
                </h3>
              )}{' '}
              {text && <p className='text-bluish-grey mt-2'>{text}</p>}
            </div>
            <div className='relative p-6 flex justify-between'>
              <div className='flex justify-end w-full mb-4 gap-x-5'>
                {onCancel && (
                  <button
                    className='flex justify-center items-center text-sage rounded-md border border-sage px-4 py-2.5'
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                )}
                <button
                  className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5'
                  onClick={onConfirm}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
    </>
  );
};

export default ConfirmMessage;

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { updateTable, deleteTable } from '../../api/restaurantInterface';
import Table from './Table';
import { mergeTables, unmergeTable } from "../../api/restaurant";
import { getAssignedTables } from '../../api/waiter';

function TableGrid({ environment }) {
    const [tablesDisplay, setTablesDisplay] = useState([]);
    const [containerWidth, setContainerWidth] = useState(window.innerWidth);
    const [assignedTables, setAssignedTables] = useState([]);
    
    const user = useSelector((state) => state?.auth?.user || null);

    console.log(user);

    useEffect(() => {
        if (user && user.id) {
            getAssignedTables(user.id)
                .then(response => {
                    if (response && response.data) {
                        const assignedTableIds = response.data.map(table => 
                            table.table ? table.table.id : table.id
                        );
                        setAssignedTables(assignedTableIds);
                    }
                })
                .catch(error => console.error('Failed to fetch assigned tables:', error));
        }
    }, [user]);

    // Responsive grid calculation
    const calculateInitialPositions = useCallback((tables) => {
        const BASE_TABLE_SIZE = 128; // 8rem (w-32)
        const GRID_GAP = 20; // Smaller gap for mobile
        const PADDING = 16; // 1rem
        
        // Calculate tables per row based on container width
        const availableWidth = containerWidth - (PADDING * 2);
        const TABLES_PER_ROW = Math.max(2, Math.floor(availableWidth / (BASE_TABLE_SIZE + GRID_GAP)));
        
        return tables.map((table, index) => {
            if (table.position && (table.position.x !== 0 || table.position.y !== 0)) {
                return table;
            }
            
            const row = Math.floor(index / TABLES_PER_ROW);
            const col = index % TABLES_PER_ROW;
            
            return {
                ...table,
                position: {
                    x: col * (BASE_TABLE_SIZE + GRID_GAP) + PADDING,
                    y: row * (BASE_TABLE_SIZE + GRID_GAP) + PADDING
                }
            };
        });
    }, [containerWidth]);

    // Handle window resize
    useEffect(() => {
        const handleResize = () => {
            setContainerWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (environment.tables) {
            const tablesWithPositions = calculateInitialPositions(environment.tables);
            setTablesDisplay(tablesWithPositions);
        }
    }, [environment, calculateInitialPositions]);

    // Save positions when tables are moved
    const handleTableMove = async (tableId, newPosition) => {
        try {
            await updateTable(tableId, { position: newPosition });
            setTablesDisplay(prevTables =>
                prevTables.map(table =>
                    table.id === tableId
                        ? { ...table, position: newPosition }
                        : table
                )
            );
        } catch (error) {
            console.error('Failed to update table position:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteTable(id);
            setTablesDisplay((prevTables) => 
                prevTables.filter((table) => table.id !== id)
            );
        } catch (error) {
            console.error('Failed to delete table:', error);
        }
    };

    const handleMerge = async (table, data) => {
        const droppedTable = tablesDisplay.find((t) => t.id === table.id);
        const overlappedTable = tablesDisplay.find((t) => t.id !== table.id && isOverlap(data, t));

        if (overlappedTable) {
            try {
                const response = await mergeTables(droppedTable.id, overlappedTable.id);
                if (response.status === 200) {
                    const mergedTable = response.data;
                    if (mergedTable && mergedTable.id) {
                        setTablesDisplay((prevTables) =>
                            prevTables
                                .filter((t) => t.id !== overlappedTable.id && t.id !== droppedTable.id)
                                .concat(mergedTable)
                        );
                    } else {
                        console.error("Merged table is invalid:", mergedTable);
                    }
                } else {
                    console.error("Failed to merge tables:", response.message);
                }
            } catch (error) {
                console.error("Failed to merge tables:", error);
            }
        } else {
            console.warn("No overlapping table found for merging.");
        }
    };

    const handleUnmerge = async (mergedTableId) => {
        try {
            const response = await unmergeTable(mergedTableId);
            if (response.status === 200 && Array.isArray(response.data.originalTables)) {
                const originalTables = response.data.originalTables;
                setTablesDisplay(prevTables => {
                    const filteredTables = prevTables.filter(table => table.id !== mergedTableId);
                    return [...filteredTables, ...originalTables];
                });
            } else {
                console.error("Unmerge failed:", response.message);
                if (response.data.originalTables) {
                    console.error("Original Tables:", response.data.originalTables);
                } else {
                    console.error("Original Tables not found in response");
                }
            }
        } catch (error) {
            console.error("Error in unmerging table:", error);
        }
    };

    const isOverlap = (data, table) => {
        const draggedRect = data.node.getBoundingClientRect();
        const tableElement = document.querySelector(`[data-table-id='${table.id}']`);

        if (!tableElement) {
            console.warn(`Element with data-table-id ${table.id} not found`);
            return false;
        }

        const tableRect = tableElement.getBoundingClientRect();

        return !(
            draggedRect.right < tableRect.left ||
            draggedRect.left > tableRect.right ||
            draggedRect.bottom < tableRect.top ||
            draggedRect.top > tableRect.bottom
        );
    };

    return (
        <div className="relative w-full h-[calc(100vh-100px)] overflow-hidden bg-transparent p-2 sm:p-4">
            <div className="absolute inset-0 p-2 sm:p-4 min-h-[500px]">
                {tablesDisplay.map((table) => (
                    <Table
                        key={table.id}
                        table={table}
                        onDelete={handleDelete}
                        onMerge={handleMerge}
                        onUnmerge={handleUnmerge}
                        onMove={handleTableMove}
                        position={table.position}
                        isAssigned={assignedTables.includes(table.id)}
                        containerWidth={containerWidth}
                    />
                ))}
            </div>
        </div>
    );
}

export default TableGrid;


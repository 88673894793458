import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IconAlertTriangle } from '@tabler/icons-react';
import StockLossItemForm from './StockLossItemForm';
import PageTitle from '../../../components/page-title';
import Loading from '../../../components/loader';

import { getStockLossItemById } from '../../../api/stockLoss';

function EditStockLossItem() {
  const params = useParams();
  const stockLossItemId = params.id;
  const [stockLossItem, setStockLossItem] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStockLossItemData();
  }, []);

  const getStockLossItemData = async () => {
    try {
      const response = await getStockLossItemById(stockLossItemId);
      setStockLossItem(response.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={<IconAlertTriangle className='text-hazel-green mr-2' size={22} />}
        title='Edit Stock Loss Item'
      />
      <StockLossItemForm isEdit={true} stockLossItem={stockLossItem} />
    </div>
  );
}

export default EditStockLossItem;
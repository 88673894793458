// import React, { useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Formik, FieldArray } from 'formik';
// import * as Yup from 'yup';
// import { IconUpload, IconX } from '@tabler/icons-react';
// import CustomInput from '../../components/custom-input';
// import SelectInput from '../../components/select-input';
// import { SubmitButton, CancelButton } from '../../components/buttons';
// import {
//   dishTypes,
//   restaurants,
//   productTypes,
//   units
// } from '../../constants/entities';

// function DishForm({ isEdit, dish }) {
//   const navigate = useNavigate();
//   const fileInputRef = useRef(null);
//   const [uploadedFile, setUploadedFile] = useState(null);
//   const [errorMessage, setErrorMessage] = useState(null);

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     setUploadedFile(file);
//   };

//   const handleDeleteRow = async (id) => {};

//   Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
//     return this.test('unique', message, function (list) {
//       return list.length === new Set(list.map(mapper)).size;
//     });
//   });

//   return (
//     <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
//       <Formik
//         initialValues={{
//           name: isEdit ? dish.name : '',
//           type: isEdit ? dish.type : 'Restaurant 1',
//           location: isEdit ? dish.location : '',
//           price: isEdit ? dish.price : '',
//           author: isEdit ? dish.author : '',
//           imagePath: isEdit ? dish.imagePath : null,
//           description: isEdit ? dish.description : '',
//           products: isEdit
//             ? dish.products[0].type
//             : [{ name: '', type: '', quantity: null, unit: '' }]
//         }}
//         validationSchema={Yup.object().shape({
//           name: Yup.string().min(3).required('Name is required*'),
//           type: Yup.string().required('Type is required*'),
//           location: Yup.string().required('Location is required*'),
//           price: Yup.number().required('Price is required*'),
//           author: Yup.string().required('Author is required*'),
//           imagePath: Yup.string().required('Dish image is required*'),
//           description: Yup.string().min(4).required('Description is required*'),
//           products: Yup.array().of(
//             Yup.object().shape({
//               name: Yup.string().required('Product name is required'),
//               type: Yup.string().required('Product type is required'),
//               quantity: Yup.number().required('Quantity is required'),
//               unit: Yup.string().required('Unit is required')
//             })
//           )
//         })}
//         onSubmit={console.log('dish form')}
//       >
//         {({
//           errors,
//           values,
//           touched,
//           isSubmitting,
//           handleBlur,
//           handleChange,
//           handleSubmit,
//           setFieldValue
//         }) => (
//           <form onSubmit={() => console.log('')}>
//             <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-3'>
//               <CustomInput
//                 type='text'
//                 name='name'
//                 label='Name'
//                 value={values.name}
//                 onChange={handleChange}
//                 onBlur={handleBlur}
//                 showError={errors.name && touched.name}
//                 errorMessage={errors.name}
//               />
//               <SelectInput
//                 value={values.type}
//                 label='Type'
//                 options={dishTypes}
//                 onChange={handleChange('type')}
//                 onBlur={handleBlur}
//                 showError={errors.type && touched.type}
//                 errorMessage={errors.type}
//                 isEntity={true}
//               />
//               <SelectInput
//                 value={values.location}
//                 label='Location'
//                 options={restaurants}
//                 onChange={handleChange('location')}
//                 onBlur={handleBlur}
//                 showError={errors.location && touched.location}
//                 errorMessage={errors.location}
//                 isEntity={true}
//               />
//               <CustomInput
//                 type='number'
//                 name='price'
//                 label='Price'
//                 value={values.price}
//                 onChange={handleChange}
//                 onBlur={handleBlur}
//                 showError={errors.price && touched.price}
//                 errorMessage={errors.price}
//               />
//               <CustomInput
//                 type='text'
//                 name='author'
//                 label='Author'
//                 value={values.author}
//                 onChange={handleChange}
//                 onBlur={handleBlur}
//                 showError={errors.author && touched.author}
//                 errorMessage={errors.author}
//               />
//             </div>
//             <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-3'>
//               <div className='col-span-1'>
//                 {uploadedFile || values.imagePath ? (
//                   <div className='relative mr-2 mb-3'>
//                     <img
//                       src={
//                         uploadedFile
//                           ? URL.createObjectURL(uploadedFile)
//                           : `http://3.79.80.215/${values.imagePath}`
//                       }
//                       alt='room'
//                       className='w-full h-32 rounded-md'
//                     />
//                     <button
//                       type='button'
//                       className='absolute top-0 right-0 border-sage rounded-tr-md rounded-bl-md bg-white p-1'
//                       onClick={() => setUploadedFile(null)}
//                     >
//                       <div className='absolute top-0 right-0 border border-sage rounded-tr-md rounded-bl-md bg-white p-1 flex items-center justify-center'>
//                         <div className='rounded-full w-4 h-4 border border-sage flex items-center justify-center text-sage'>
//                           <IconX />
//                         </div>
//                       </div>
//                     </button>
//                   </div>
//                 ) : (
//                   <>
//                     <input
//                       type='file'
//                       ref={fileInputRef}
//                       style={{ display: 'none' }}
//                       onChange={handleFileChange}
//                     />
//                     <div
//                       className='flex items-center justify-center bg-mercury w-full h-32 rounded-md cursor-pointer mb-3'
//                       onClick={() => fileInputRef.current.click()}
//                     >
//                       <IconUpload className='text-hazel-green' size={30} />
//                     </div>
//                   </>
//                 )}
//               </div>
//               <div className='lg:col-span-2 xl:col-span-5'>
//                 <CustomInput
//                   type='text'
//                   name='description'
//                   label='Description'
//                   value={values.description}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   multiline={true}
//                   showError={errors.description && touched.description}
//                   errorMessage={errors.description}
//                 />
//               </div>
//             </div>
//             <h3 className='text-bluish-grey font-medium uppercase mt-3'>
//               Products
//             </h3>
//             <div className='border-b boder-hazel-green my-2' />
//             <FieldArray name='products'>
//               {({ push, remove }) => (
//                 <div>
//                   {values.products.map((product, index) => {
//                     return (
//                       <div
//                         key={index}
//                         className='grid md:grid-cols-4 xl:grid-cols-8 gap-x-3'
//                       >
//                         <div className='col-span-2'>
//                           <CustomInput
//                             type='text'
//                             name='name'
//                             label='Product Name'
//                             value={product.name}
//                             onChange={(event) => {
//                               setFieldValue(
//                                 `products[${index}].name`,
//                                 event.target.value
//                               );
//                             }}
//                             onBlur={handleBlur}
//                             showError={
//                               errors[index]?.name && touched[index]?.name
//                             }
//                             errorMessage={errors[index]?.name}
//                           />
//                         </div>
//                         <div className='col-span-2'>
//                           <SelectInput
//                             value={product.type}
//                             label='Type'
//                             options={productTypes}
//                             onChange={(event) => {
//                               setFieldValue(
//                                 `products[${index}].type`,
//                                 event.target.value
//                               );
//                             }}
//                             onBlur={handleBlur}
//                             showError={
//                               errors[index]?.type && touched[index]?.type
//                             }
//                             errorMessage={errors[index]?.type}
//                             isEntity={true}
//                           />
//                         </div>
//                         <div className='col-span-2'>
//                           <CustomInput
//                             type='number'
//                             name='quantity'
//                             label='Quantity'
//                             value={product.quantity}
//                             onChange={(event) => {
//                               const newValue = parseFloat(event.target.value);
//                               setFieldValue(
//                                 `products[${index}].quantity`,
//                                 newValue
//                               );
//                             }}
//                             onBlur={handleBlur}
//                             showError={
//                               errors[index]?.quantity &&
//                               touched[index]?.quantity
//                             }
//                             errorMessage={errors[index]?.quantity}
//                           />
//                         </div>
//                         <SelectInput
//                           value={product.unit}
//                           label='Unit'
//                           options={units}
//                           onChange={(event) => {
//                             setFieldValue(
//                               `products[${index}].unit`,
//                               event.target.value
//                             );
//                           }}
//                           onBlur={handleBlur}
//                           showError={
//                             errors[index]?.unit && touched[index]?.unit
//                           }
//                           errorMessage={errors[index]?.unit}
//                           isEntity={true}
//                         />
//                         <button
//                           type='button'
//                           className='text-error bg-dawn-pink rounded-md py-3.5 mb-3 w-full'
//                           onClick={() =>
//                             isEdit ? handleDeleteRow(product.id) : remove(index)
//                           }
//                         >
//                           x
//                         </button>
//                       </div>
//                     );
//                   })}
//                   <div className='flex justify-center items-center'>
//                     <button
//                       type='button'
//                       className='border-dashed border-2 border-sage w-1/2 text-sage rounded-md mt-4 py-2'
//                       onClick={() =>
//                         push({
//                           name: '',
//                           type: '',
//                           quantity: null,
//                           unit: ''
//                         })
//                       }
//                     >
//                       Add product
//                     </button>
//                   </div>
//                 </div>
//               )}
//             </FieldArray>
//             {errors.submit && (
//               <p className='text-error px-7'>{errors.submit}</p>
//             )}
//             {errorMessage && <p className='text-error px-7'>{errorMessage}</p>}
//             <div className='flex justify-end items-center w-full mt-7 text-sm'>
//               <CancelButton onCancel={() => navigate('/restaurants')} />
//               <SubmitButton
//                 isSubmitting={isSubmitting}
//                 width='w-1/5'
//                 title='Save'
//               />
//             </div>
//           </form>
//         )}
//       </Formik>
//     </div>
//   );
// }

// export default DishForm;





// import React, { useEffect, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Formik, FieldArray } from 'formik';
// import * as Yup from 'yup';
// import { IconUpload, IconX } from '@tabler/icons-react';
// import CustomInput from '../../components/custom-input';
// import SelectInput from '../../components/select-input';
// import { SubmitButton, CancelButton } from '../../components/buttons';
// import {createOrUpdateDish } from '../../api/dishes';
// import { dishTypes } from '../../constants/entities';
// import { getAvailableProducts } from "../../api/products";

// function DishForm({ isEdit, dish }) {
//   const navigate = useNavigate();
//   const fileInputRef = useRef(null);
//   const [uploadedFile, setUploadedFile] = useState(null);
//   const [availableProducts, setAvailableProducts] = useState([]);
//   const [errorMessage, setErrorMessage] = useState(null);

//   useEffect(() => {
//     async function fetchProducts() {
//       try {
//         const response = await getAvailableProducts();
//         setAvailableProducts(response.data);
//       } catch (error) {
//         console.error("Failed to fetch products:", error);
//       }
//     }

//     fetchProducts();
//   }, []);

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     setUploadedFile(file);
//   };

  // const handleDeleteRow = async (id) => {};

//   return (
//     <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
//       <Formik
//         initialValues={{
//           name: isEdit ? dish.name : '',
//           type: isEdit ? dish.type : '',
//           price: isEdit ? dish.price : '',
//           imagePath: isEdit ? dish.imagePath : null,
//           description: isEdit ? dish.description : '',
//           products: isEdit ? dish.products : [{ id: '', quantity: 0 }]
//         }}
//         validationSchema={Yup.object().shape({
//           name: Yup.string().min(3).required('Name is required*'),
//           type: Yup.string().required('Type is required*'),
//           price: Yup.number().required('Price is required*'),
//           imagePath: Yup.string().required('Dish image is required*'),
//           description: Yup.string().min(4).required('Description is required*'),
//           products: Yup.array().of(
//             Yup.object().shape({
//               id: Yup.string().required('Product is required'),
//               quantity: Yup.number().min(1).required('Quantity is required')
//             })
//           )
//         })}
//         onSubmit={async (values, { setSubmitting, setErrors }) => {
//           try {
//             await createOrUpdateDish(values);
//             navigate('/dishes');
//           } catch (error) {
//             setErrors({ submit: error.message });
//           } finally {
//             setSubmitting(false);
//           }
//         }}
//       >
//         {({
//           errors,
//           values,
//           touched,
//           isSubmitting,
//           handleBlur,
//           handleChange,
//           handleSubmit,
//           setFieldValue
//         }) => (
//           <form onSubmit={handleSubmit}>
//             <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-3'>
//               <CustomInput
//                 type='text'
//                 name='name'
//                 label='Name'
//                 value={values.name}
//                 onChange={handleChange}
//                 onBlur={handleBlur}
//                 showError={errors.name && touched.name}
//                 errorMessage={errors.name}
//               />
//               <SelectInput
//                 value={values.type}
//                 label='Type'
//                 options={dishTypes}
//                 onChange={handleChange('type')}
//                 onBlur={handleBlur}
//                 showError={errors.type && touched.type}
//                 errorMessage={errors.type}
//                 isEntity={true}
//               />
//               <CustomInput
//                 type='number'
//                 name='price'
//                 label='Price'
//                 value={values.price}
//                 onChange={handleChange}
//                 onBlur={handleBlur}
//                 showError={errors.price && touched.price}
//                 errorMessage={errors.price}
//               />
//             </div>
//             <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-3'>
//               <div className='col-span-1'>
//                 {uploadedFile || values.imagePath ? (
//                   <div className='relative mr-2 mb-3'>
//                     <img
//                       src={
//                         uploadedFile
//                           ? URL.createObjectURL(uploadedFile)
//                           : values.imagePath
//                       }
//                       alt='dish'
//                       className='w-full h-32 rounded-md'
//                     />
//                     <button
//                       type='button'
//                       className='absolute top-0 right-0 border-sage rounded-tr-md rounded-bl-md bg-white p-1'
//                       onClick={() => setUploadedFile(null)}
//                     >
//                       <div className='absolute top-0 right-0 border border-sage rounded-tr-md rounded-bl-md bg-white p-1 flex items-center justify-center'>
//                         <div className='rounded-full w-4 h-4 border border-sage flex items-center justify-center text-sage'>
//                           <IconX />
//                         </div>
//                       </div>
//                     </button>
//                   </div>
//                 ) : (
//                   <>
//                     <input
//                       type='file'
//                       ref={fileInputRef}
//                       style={{ display: 'none' }}
//                       onChange={handleFileChange}
//                     />
//                     <div
//                       className='flex items-center justify-center bg-mercury w-full h-32 rounded-md cursor-pointer mb-3'
//                       onClick={() => fileInputRef.current.click()}
//                     >
//                       <IconUpload className='text-hazel-green' size={30} />
//                     </div>
//                   </>
//                 )}
//               </div>
//               <div className='lg:col-span-2 xl:col-span-5'>
//                 <CustomInput
//                   type='text'
//                   name='description'
//                   label='Description'
//                   value={values.description}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   multiline={true}
//                   showError={errors.description && touched.description}
//                   errorMessage={errors.description}
//                 />
//               </div>
//             </div>
//             <h3 className='text-bluish-grey font-medium uppercase mt-3'>
//               Products
//             </h3>
//             <div className='border-b boder-hazel-green my-2' />

          //   <FieldArray name='products'>
          //   {({ push, remove }) => (
          //     <div>
          //       {values.products.map((product, index) => {
          //         return (
          //           <div key={index} className='grid md:grid-cols-4 xl:grid-cols-8 gap-x-3'>
          //             <div className='col-span-2'>
          //               <SelectInput
          //                 value={product.id}
          //                 label='Product'
                          // options={availableProducts.map(p => p.name)}
          //                 onChange={(event) => {
          //                   // Assuming `event.target.value` directly provides the product name
          //                   setFieldValue(`products[${index}].id`, event.target.value);
          //                 }}
          //                 onBlur={handleBlur}
          //                 showError={errors.products?.[index]?.id && touched.products?.[index]?.id}
          //                 errorMessage={errors.products?.[index]?.id}
          //                 isEntity={true}
          //               />
          //             </div>
          //             <div className='col-span-2'>
          //               <CustomInput
          //                 type='number'
          //                 name={`products[${index}].quantity`}
          //                 label='Quantity'
          //                 value={product.quantity}
          //                 onChange={(event) => {
          //                   setFieldValue(`products[${index}].quantity`, event.target.value);
          //                 }}
          //                 onBlur={handleBlur}
          //                 showError={errors.products?.[index]?.quantity && touched.products?.[index]?.quantity}
          //                 errorMessage={errors.products?.[index]?.quantity}
          //               />
          //             </div>
          //             <button
          //               type='button'
          //               className='text-error bg-dawn-pink rounded-md py-3.5 mb-3 w-full'
          //               onClick={() => isEdit ? handleDeleteRow(product.id) : remove(index)}
          //             >
          //               x
          //             </button>
          //           </div>
          //         );
          //       })}
          //       <div className='flex justify-center items-center'>
          //         <button
          //           type='button'
          //           className='border-dashed border-2 border-sage w-1/2 text-sage rounded-md mt-4 py-2'
          //           onClick={() => push({ id: '', quantity: 0 })}
          //         >
          //           Add product
          //         </button>
          //       </div>
          //     </div>
          //   )}
          // </FieldArray>

//             {/* <FieldArray name='products'>
//               {({ push, remove }) => (
//                 <div>
//                   {values.products.map((product, index) => {
//                     return (
//                       <div
//                         key={index}
//                         className='grid md:grid-cols-4 xl:grid-cols-8 gap-x-3'
//                       >
//                         <div className='col-span-2'>
//                           <SelectInput
//                             value={product.id}
//                             label='Product'
//                             options={availableProducts.map(p => ({
//                               id: p.id,
//                               title: p.name
//                             }))}
//                             onChange={(event) => {
//                               setFieldValue(`products[${index}].id`, event.target.value);
//                             }}
//                             onBlur={handleBlur}
//                             showError={errors.products?.[index]?.id && touched.products?.[index]?.id}
//                             errorMessage={errors.products?.[index]?.id}
//                             isEntity={true}
//                           />
//                         </div>
//                         <div className='col-span-2'>
//                           <CustomInput
//                             type='number'
//                             name={`products[${index}].quantity`}
//                             label='Quantity'
//                             value={product.quantity}
//                             onChange={(event) => {
//                               setFieldValue(`products[${index}].quantity`, event.target.value);
//                             }}
//                             onBlur={handleBlur}
//                             showError={errors.products?.[index]?.quantity && touched.products?.[index]?.quantity}
//                             errorMessage={errors.products?.[index]?.quantity}
//                           />
//                         </div>
//                         <button
//                           type='button'
//                           className='text-error bg-dawn-pink rounded-md py-3.5 mb-3 w-full'
//                           onClick={() =>
//                             isEdit ? handleDeleteRow(product.id) : remove(index)
//                           }
//                         >
//                           x
//                         </button>
//                       </div>
//                     );
//                   })}
//                   <div className='flex justify-center items-center'>
//                     <button
//                       type='button'
//                       className='border-dashed border-2 border-sage w-1/2 text-sage rounded-md mt-4 py-2'
//                       onClick={() =>
//                         push({ id: '', quantity: 0 })
//                       }
//                     >
//                       Add product
//                     </button>
//                   </div>
//                 </div>
//               )}
//             </FieldArray> */}
//             {errors.submit && (
//               <p className='text-error px-7'>{errors.submit}</p>
//             )}
//             {errorMessage && <p className='text-error px-7'>{errorMessage}</p>}
//             <div className='flex justify-end items-center w-full mt-7 text-sm'>
//               <CancelButton onCancel={() => navigate('/dishes')} />
//               <SubmitButton
//                 isSubmitting={isSubmitting}
//                 width='w-1/5'
//                 title='Save'
//               />
//             </div>
//           </form>
//         )}
//       </Formik>
//     </div>
//   );
// }

// export default DishForm;


import { IconUpload, IconX } from '@tabler/icons-react';


import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import Loading from '../../components/loader';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import CustomSelectInput from '../../components/custom-select-input';
import { createOrUpdateDish } from '../../api/dishes';
import { dishTypes, units } from '../../constants/entities';
import { getAvailableProducts } from '../../api/products';



function DishForm({ isEdit, dish }) {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchProducts = useCallback(async () => {
    try {
      const response = await getAvailableProducts();
      const formattedProducts = response.data.map((product) => ({
        id: product.id,
        title: `${product.name} (${product.unit})`,
        unit: product.unit,
      }));
      setAvailableProducts(formattedProducts);
    } catch (error) {
      console.error('Failed to fetch products:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const initialValues = isEdit
    ? dish
    : {
        name: '',
        type: '',
        price: '',
        imagePath: null,
        description: '',
        products: [{ id: '', quantity: 0, unit: '' }],
      };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required('Name is required*'),
    type: Yup.string().required('Type is required*'),
    price: Yup.number().required('Price is required*'),
    imagePath: Yup.string().required('Dish image is required*'),
    description: Yup.string().min(4).required('Description is required*'),
    products: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required('Product is required'),
        quantity: Yup.number().min(1).required('Quantity is required'),
        unit: Yup.string().required('Unit is required')
      })
    )
  });

  if (loading) {
    return <Loading />;
  }


  const handleDeleteRow = async (id) => {};


  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            await createOrUpdateDish(values);
            navigate('/dishes');
            setSubmitting(false);
          } catch (err) {
            setErrors({ submit: err?.response?.data });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-3'>
              <CustomInput
                type='text'
                name='name'
                label='Name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.name && touched.name}
                errorMessage={errors.name}
              />
              <CustomSelectInput
                name='type'
                value={values.type}
                label='Type'
                options={dishTypes.map(type => ({ id: type, title: type }))}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.type && touched.type}
                errorMessage={errors.type}
              />
              <CustomInput
                type='number'
                name='price'
                label='Price'
                value={values.price}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.price && touched.price}
                errorMessage={errors.price}
              />
            </div>
            <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-3'>
              <div className='col-span-1'>
                {uploadedFile || values.imagePath ? (
                  <div className='relative mr-2 mb-3'>
                    <img
                      src={
                        uploadedFile
                          ? URL.createObjectURL(uploadedFile)
                          : values.imagePath
                      }
                      alt='dish'
                      className='w-full h-32 rounded-md'
                    />
                    <button
                      type='button'
                      className='absolute top-0 right-0 border-sage rounded-tr-md rounded-bl-md bg-white p-1'
                      onClick={() => setUploadedFile(null)}
                    >
                      <div className='absolute top-0 right-0 border border-sage rounded-tr-md rounded-bl-md bg-white p-1 flex items-center justify-center'>
                        <div className='rounded-full w-4 h-4 border border-sage flex items-center justify-center text-sage'>
                          <IconX />
                        </div>
                      </div>
                    </button>
                  </div>
                ) : (
                  <>
                    <input
                      type='file'
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    <div
                      className='flex items-center justify-center bg-mercury w-full h-32 rounded-md cursor-pointer mb-3'
                      onClick={() => fileInputRef.current.click()}
                    >
                      <IconUpload className='text-hazel-green' size={30} />
                    </div>
                  </>
                )}
              </div>
              <div className='lg:col-span-2 xl:col-span-5'>
                <CustomInput
                  type='text'
                  name='description'
                  label='Description'
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  multiline={true}
                  showError={errors.description && touched.description}
                  errorMessage={errors.description}
                />
              </div>
            </div>
            <h3 className='text-bluish-grey font-medium uppercase mt-3'>
              Products
            </h3>
            <div className='border-b boder-hazel-green my-2' />

            <FieldArray name='products'>
              {({ push, remove }) => (
                <div>
                  {values.products.map((product, index) => (
                    <div key={index} className='grid md:grid-cols-4 xl:grid-cols-8 gap-x-3'>
                      <div className='col-span-2'>
                        <CustomSelectInput
                          name={`products[${index}].id`}
                          value={product.id}
                          label='Product'
                          options={availableProducts.map(product => ({
                            id: product.id,
                            title: product.title,
                          }))}
                          onChange={(event) => {
                            const selectedProduct = availableProducts.find(p => p.id === event.target.value);
                            setFieldValue(`products[${index}].id`, selectedProduct.id);
                            setFieldValue(`products[${index}].unit`, selectedProduct.unit);
                          }}
                          onBlur={handleBlur}
                          showError={errors.products?.[index]?.id && touched.products?.[index]?.id}
                          errorMessage={errors.products?.[index]?.id}
                        />
                      </div>
                      <div className='col-span-2'>
                        <CustomInput
                          type='number'
                          name={`products[${index}].quantity`}
                          label='Quantity'
                          value={product.quantity}
                          onChange={(event) => {
                            setFieldValue(`products[${index}].quantity`, event.target.value);
                          }}
                          onBlur={handleBlur}
                          showError={errors.products?.[index]?.quantity && touched.products?.[index]?.quantity}
                          errorMessage={errors.products?.[index]?.quantity}
                        />
                      </div>
                      <div className='col-span-2'>
                        <CustomSelectInput
                          name={`products[${index}].unit`}
                          value={product.unit}
                          label='Unit'
                          options={units.map(unit => ({ id: unit, title: unit }))}
                          onChange={(event) => {
                            setFieldValue(`products[${index}].unit`, event.target.value);
                          }}
                          onBlur={handleBlur}
                          showError={errors.products?.[index]?.unit && touched.products?.[index]?.unit}
                          errorMessage={errors.products?.[index]?.unit}
                        />
                      </div>
                      <button
                        type='button'
                        className='text-error bg-dawn-pink rounded-md py-3.5 mb-3 w-full'
                        onClick={() => isEdit ? handleDeleteRow(product.id) : remove(index)}
                      >
                        x
                      </button>
                    </div>
                  ))}
                  <div className='flex justify-center items-center'>
                    <button
                      type='button'
                      className='border-dashed border-2 border-sage w-1/2 text-sage rounded-md mt-4 py-2'
                      onClick={() => push({ id: '', quantity: 0, unit: '' })}
                    >
                      Add product
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>

            {errors.submit && (
              <p className='text-error px-7'>{errors.submit}</p>
            )}
            {errorMessage && <p className='text-error px-7'>{errorMessage}</p>}
            <div className='flex justify-end items-center w-full mt-7 text-sm'>
              <CancelButton onCancel={() => navigate('/dishes')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/5'
                title='Save'
              />
              <button width='w-1/5' type='submit' onClick={handleSubmit}>Save</button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default DishForm;












import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Search } from 'lucide-react';

interface Item {
  id: number;
  name: string;
  description: string;
  sellingPrice: number;
}

interface ItemSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  items: Item[];
  onSelectItem: (item: Item) => void;
}

export default function ItemSelectionModal({ isOpen, onClose, items, onSelectItem }: ItemSelectionModalProps) {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10; // Set how many items you want to show per page

  // Filter items based on the search query
  const filteredItems = items.filter(item => 
    item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.description?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  // Calculate items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/50"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="bg-white rounded-xl shadow-xl w-full max-w-2xl max-h-[80vh] flex flex-col"
          >
            <div className="p-4 border-b border-grey-goose flex justify-between items-center">
              <h2 className="text-lg font-medium text-bluish-grey">Select Item</h2>
              <button
                onClick={onClose}
                className="text-silver hover:text-bluish-grey transition-colors"
              >
                <X size={20} />
              </button>
            </div>

            <div className="p-4 flex-1 overflow-hidden">
              <div className="relative mb-4">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-silver" size={18} />
                <input
                  type="text"
                  placeholder="Search items..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-grey-goose rounded-lg focus:outline-none focus:border-sage"
                />
              </div>

              <div className="overflow-y-auto max-h-[50vh]">
                <table className="w-full">
                  <thead className="bg-gray-50 sticky top-0">
                    <tr>
                      <th className="text-left p-3 text-sm font-medium text-bluish-grey">Name</th>
                      <th className="text-left p-3 text-sm font-medium text-bluish-grey">Description</th>
                      <th className="text-right p-3 text-sm font-medium text-bluish-grey">Price</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-grey-goose">
                    {currentItems.map((item) => (
                      <tr
                        key={item.id}
                        onClick={() => onSelectItem(item)}
                        className="hover:bg-gray-50 cursor-pointer transition-colors"
                      >
                        <td className="p-3 text-sm text-bluish-grey">{item.name}</td>
                        <td className="p-3 text-sm text-silver">{item.description}</td>
                        <td className="p-3 text-sm text-bluish-grey text-right">
                          €{item.sellingPrice.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Pagination Controls in the Footer */}
            <div className="p-4 border-t border-grey-goose flex justify-between items-center">
              <button 
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
                disabled={currentPage === 1}
                className="rounded-md bg-sage px-4 py-1 font-semibold text-xs text-white transition-all hover:bg-hazel-green duration-200"
              >
                Previous
              </button>
              <span className='text-hazel-green text-md font-bold'>{`Page ${currentPage} of ${totalPages}`}</span>
              <button 
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} 
                disabled={currentPage === totalPages}
                className="rounded-md bg-sage px-4 py-1 font-semibold text-xs text-white transition-all hover:bg-hazel-green duration-200"
              >
                Next
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

import api from './api';

export const getAllConferenceRoomReservations = () => api.get('/api/conferenceRoomReservation');
export const getConferenceRoomReservation = (id) => api.get(`/api/conferenceRoomReservation/reservations/${id}`);

export const createConferenceRoomReservation = (values) => {
    console.log('Creating reservation with values:', values);
    return api.post('/api/conferenceRoomReservation/create-reservation', {
        ...values,
        conferenceRoomId: values.roomId,
    });
};

export const updateConferenceRoomReservation = (id, values) => 
  api.patch(`/api/conferenceRoomReservation/reservations/${id}`, {
    ...values,
    startDate: new Date(values.startDate).toISOString(), 
    endDate: new Date(values.endDate).toISOString(),    
    products: values.products ? values.products.map(p => ({
      ...p,
      productId: parseInt(p.productId, 10),
      quantity: parseInt(p.quantity, 10)   
    })) : []
  });

export const deleteConferenceRoomReservation = (id) => api.delete(`/api/conferenceRoomReservation/${id}`);

// export const checkConferenceRoomAvailability = (conferenceRoomId, startDate, endDate) => 
//     api.get('/api/conferenceRoomReservation/reservations/availability', {
//       params: {
//         conferenceRoomId,
//         startDate,
//         endDate
//       }
//     });


export const checkConferenceRoomAvailability = (roomId, startDate, endDate) => {
  const numericRoomId = Number(roomId);

  console.log('Sending availability check request:', { numericRoomId, startDate, endDate });

  return api.get('/api/conferenceRoomReservation/reservations/availability', {
      params: {
          conferenceRoomId: numericRoomId,  
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
      }
  });
};


export const getActiveReservations = () => api.get('/api/conferenceRoomReservation/active');

export const checkRoomReservation = (roomId, startDate, endDate) => {
  console.log('Sending room reservation check request:', { roomId, startDate, endDate });
  return api.get('/api/conferenceRoomReservation/is-room-reserved', {
    params: {
      roomId,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    }
  });
};

export const createConferenceRoomReservationWithRooms = (values) => {
  console.log('Creating conference room reservation with rooms:', values);
  return api.post('/api/conferenceRoomReservation/create-reservation-with-rooms', values);
};

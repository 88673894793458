// import React from "react";


// export function InvoiceDocument() {
//     return (
//       <div className="bg-white p-8 rounded-lg border border-mercury">
//         {/* Draft Watermark */}
//         <div className="relative">
//           <div className="absolute -top-4 -left-4 bg-[#EDF2F7] text-[#2D3748] px-4 py-1 rounded-br-lg transform -rotate-12">
//             Draft
//           </div>
  
//           {/* Header */}
//           <div className="flex justify-between mb-8">
//             <div>
//               <h2 className="text-xl font-bold text-hazel-green mb-2">Dolphins Group</h2>
//               <p className="text-bluish-grey">Peje</p>
//               <p className="text-bluish-grey">Kosova Republic</p>
//               <p className="text-bluish-grey">enisgjocaj1@hotmail.com</p>
//             </div>
//             <div className="text-right">
//               <h1 className="text-3xl font-bold text-hazel-green mb-2">INVOICE</h1>
//               <p className="text-bluish-grey">Invoice# INV-000001</p>
//               <p className="text-lg font-medium mt-4">Balance Due</p>
//               <p className="text-2xl font-bold text-hazel-green">€0,00</p>
//             </div>
//           </div>
  
//           {/* Invoice Details */}
//           <div className="grid grid-cols-2 gap-8 mb-8">
//             <div>
//               <h3 className="text-lg font-medium text-hazel-green mb-2">Bill To</h3>
//               <p className="text-bluish-grey">sdsjkfsd,f</p>
//             </div>
//             <div className="grid grid-cols-2 gap-4">
//               <div>
//                 <p className="text-sm text-bluish-grey">Invoice Date:</p>
//                 <p className="font-medium">27 Oct 2024</p>
//               </div>
//               <div>
//                 <p className="text-sm text-bluish-grey">Terms:</p>
//                 <p className="font-medium">Due On Receipt</p>
//               </div>
//               <div>
//                 <p className="text-sm text-bluish-grey">Due Date:</p>
//                 <p className="font-medium">27 Oct 2024</p>
//               </div>
//               <div>
//                 <p className="text-sm text-bluish-grey">P.O.#:</p>
//                 <p className="font-medium">1</p>
//               </div>
//             </div>
//           </div>
  
//           {/* Invoice Table */}
//           <table className="w-full mb-8">
//             <thead>
//               <tr className="bg-[#2D3748] text-white">
//                 <th className="py-2 px-4 text-left">#</th>
//                 <th className="py-2 px-4 text-left">Item & Description</th>
//                 <th className="py-2 px-4 text-right">Qty</th>
//                 <th className="py-2 px-4 text-right">Rate</th>
//                 <th className="py-2 px-4 text-right">Amount</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr className="border-b border-mercury">
//                 <td className="py-3 px-4">1</td>
//                 <td className="py-3 px-4">item</td>
//                 <td className="py-3 px-4 text-right">1,00</td>
//                 <td className="py-3 px-4 text-right">0,00</td>
//                 <td className="py-3 px-4 text-right">0,00</td>
//               </tr>
//             </tbody>
//           </table>
  
//           {/* Totals */}
//           <div className="flex justify-end">
//             <div className="w-64">
//               <div className="flex justify-between py-2">
//                 <span className="text-bluish-grey">Sub Total</span>
//                 <span className="font-medium">€0,00</span>
//               </div>
//               <div className="flex justify-between py-2 border-t border-mercury">
//                 <span className="text-bluish-grey">Total</span>
//                 <span className="font-bold text-lg">€0,00</span>
//               </div>
//               <div className="flex justify-between py-2 bg-seashell mt-2 px-3 rounded">
//                 <span className="font-medium">Balance Due</span>
//                 <span className="font-bold text-lg">€0,00</span>
//               </div>
//             </div>
//           </div>
  
//           {/* Notes */}
//           <div className="mt-8">
//             <h3 className="text-lg font-medium text-hazel-green mb-2">Notes</h3>
//             <p className="text-bluish-grey">Thanks for your business.</p>
//           </div>
//         </div>
//       </div>
//     );
//   }

import React from "react";

interface Invoice {
  id: string;
  customer: string;
  date: string;
  amount: string;
  status: string;
}

interface InvoiceDocumentProps {
  invoice: Invoice;
}

export function InvoiceDocument({ invoice }: InvoiceDocumentProps) {
  return (
    <div className="bg-white p-8 rounded-lg shadow-md border border-mercury">
      {/* Status Watermark */}
      <div className="relative">
        {/* <div className="absolute -top-4 -left-4 bg-[#EDF2F7] text-[#2D3748] px-4 py-1 rounded-br-lg transform -rotate-12">
          {invoice.status}
        </div> */}

        {/* Header */}
        <div className="flex justify-between items-start mb-8">
          <div>
            <h2 className="text-xl font-bold text-hazel-green mb-1">Dolphins Group</h2>
            <p className="text-bluish-grey">Peje</p>
            <p className="text-bluish-grey">Kosova Republic</p>
            <p className="text-bluish-grey">enisgjocaj1@hotmail.com</p>
          </div>
          <div className="text-right">
            <h1 className="text-3xl font-bold text-hazel-green mb-1">INVOICE</h1>
            <p className="text-bluish-grey">Invoice# {invoice.id}</p>
            <p className="text-lg font-medium mt-4">Balance Due</p>
            <p className="text-2xl font-bold text-hazel-green">{invoice.amount}</p>
          </div>
        </div>

        {/* Invoice Details */}
        <div className="grid grid-cols-2 gap-8 mb-8">
          <div>
            <h3 className="text-lg font-medium text-hazel-green mb-2">Bill To</h3>
            <p className="text-bluish-grey">{invoice.customer}</p>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-bluish-grey">Invoice Date:</p>
              <p className="font-medium">{invoice.date}</p>
            </div>
            <div>
              <p className="text-sm text-bluish-grey">Terms:</p>
              <p className="font-medium">Due On Receipt</p>
            </div>
            <div>
              <p className="text-sm text-bluish-grey">Due Date:</p>
              <p className="font-medium">{invoice.date}</p>
            </div>
            <div>
              <p className="text-sm text-bluish-grey">P.O.#:</p>
              <p className="font-medium">1</p>
            </div>
          </div>
        </div>

        {/* Invoice Table */}
        <table className="w-full mb-8">
          <thead>
            <tr className="bg-[#2D3748] text-white">
              <th className="py-2 px-4 text-left">#</th>
              <th className="py-2 px-4 text-left">Item & Description</th>
              <th className="py-2 px-4 text-right">Qty</th>
              <th className="py-2 px-4 text-right">Rate</th>
              <th className="py-2 px-4 text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-mercury">
              <td className="py-3 px-4">1</td>
              <td className="py-3 px-4">Sample Item</td>
              <td className="py-3 px-4 text-right">1</td>
              <td className="py-3 px-4 text-right">€0,00</td>
              <td className="py-3 px-4 text-right">€0,00</td>
            </tr>
            {/* Additional items can be dynamically added here */}
          </tbody>
        </table>

        {/* Totals */}
        <div className="flex justify-end">
          <div className="w-64">
            <div className="flex justify-between py-2">
              <span className="text-bluish-grey">Sub Total</span>
              <span className="font-medium">€0,00</span>
            </div>
            <div className="flex justify-between py-2 border-t border-mercury">
              <span className="text-bluish-grey">Total</span>
              <span className="font-bold text-lg">{invoice.amount}</span>
            </div>
            <div className="flex justify-between py-2 bg-seashell mt-2 px-3 rounded">
              <span className="font-medium">Balance Due</span>
              <span className="font-bold text-lg">{invoice.amount}</span>
            </div>
          </div>
        </div>

        {/* Notes */}
        <div className="mt-8">
          <h3 className="text-lg font-medium text-hazel-green mb-2">Notes</h3>
          <p className="text-bluish-grey">Thanks for your business.</p>
        </div>
      </div>
    </div>
  );
}

import React, { useRef } from 'react';
import { IconCash } from '@tabler/icons-react';
import CashFlow from './CashFlow';
import { IconPdf, IconFileSpreadsheet } from '@tabler/icons-react';
import PageTitleWithButtons from '../../../../../components/PageTitleWithButtons';
import html2pdf from 'html2pdf.js';  // For PDF export
import * as XLSX from 'xlsx'; // For Excel export

function CashFlowContainer() {

  // Create a reference to the cash flow component
  const cashFlowRef = useRef(null);

  // Function to handle PDF export
  const handleExportPDF = () => {
    const element = cashFlowRef.current;  // Reference the CashFlow component
    const options = {
      margin: 0.5,
      filename: 'cash-flow.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    // Generate the PDF from the HTML content
    html2pdf().from(element).set(options).save();
  };

  // Function to handle Excel export
  const handleExportExcel = () => {
    // The data you want to export to Excel
    const data = [
      ["Category", "Name", "Previous Quarter", "Current Quarter"],
      ...dynamicCashFlowData.flatMap(section => [
        [section.category, "", "", ""],
        ...section.items.map(item => [
          "", item.name, item.prev, item.current
        ]),
        ["", section.total.name, section.total.prev, section.total.current] // Add totals
      ]),
      ["", "Gjithsej Rrjedha e Parasë", "400,000", "420,000"] // Final total
    ];

    // Create a new workbook and worksheet
    const ws = XLSX.utils.aoa_to_sheet(data);  // Convert array to sheet
    const wb = XLSX.utils.book_new();  // Create new workbook
    XLSX.utils.book_append_sheet(wb, ws, "Cash Flow");  // Append worksheet

    // Export to Excel file
    XLSX.writeFile(wb, "cash-flow.xlsx");
  };

  // Example cash flow data (similar to the balance sheet format)
  const dynamicCashFlowData = [
    {
      category: "Veprimtari Operative",
      items: [
        { name: "Të ardhura nga shitja", prev: "200,000", current: "210,000" },
        { name: "Shpenzime operative", prev: "-100,000", current: "-110,000" }
      ],
      total: { name: "Net nga veprimtaria operative", prev: "100,000", current: "100,000" }
    },
    {
      category: "Veprimtari Investuese",
      items: [
        { name: "Investime kapitale", prev: "-50,000", current: "-45,000" },
        { name: "Shitja e pajisjeve", prev: "20,000", current: "30,000" }
      ],
      total: { name: "Net nga veprimtaria investuese", prev: "-30,000", current: "-15,000" }
    },
    {
      category: "Veprimtari Financiare",
      items: [
        { name: "Kredi bankare", prev: "80,000", current: "90,000" },
        { name: "Pagesa e dividendit", prev: "-20,000", current: "-25,000" }
      ],
      total: { name: "Net nga veprimtaria financiare", prev: "60,000", current: "65,000" }
    }
  ];

  return (
    <div className='p-10 bg-seashell'>
      <PageTitleWithButtons
        title="Cash Flow"
        icon={<IconCash className='text-hazel-green font-bold mx-4' size={24} />}
        buttons={[
          <div key="1" className='flex gap-x-3'>
            <button
              className='flex items-center text-white rounded-md bg-sage px-4 py-2.5'
              onClick={handleExportPDF}  // Call the PDF export function
            >
              <IconPdf className='mr-2' size={20} />
              Download PDF
            </button>
            <button
              onClick={handleExportExcel}  // Call the Excel export function
              className='flex items-center text-white rounded-md bg-sage px-4 py-2.5'
            >
              <IconFileSpreadsheet className='mr-2' size={20} />
              Excel
            </button>
          </div>
        ]}
      />

      <div ref={cashFlowRef}>
        {/* Rendering the CashFlow component and passing dynamic data */}
        <CashFlow data={dynamicCashFlowData} date="31.12.2014" />
      </div>
    </div>
  );
}

export default CashFlowContainer;

// export const checkUserHasPermission = (
//   predefinedRole,
//   permissions,
//   allowedPermissions
// ) =>
//   predefinedRole.toLowerCase() === 'admin' ||
//   permissions.some((p) => allowedPermissions.includes(p));


export const checkUserHasPermission = (
  predefinedRole,
  permissions,
  allowedPermissions = []
) => {
  if (!Array.isArray(allowedPermissions)) {
    console.error('allowedPermissions should be an array:', allowedPermissions);
    return false;
  }
  return predefinedRole.toLowerCase() === 'admin' ||
    permissions.some((p) => allowedPermissions.includes(p));
};

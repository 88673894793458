import React, { useState, useEffect } from 'react';
import { createReservation } from '../../api/reservationsv2'; // Import the createReservation API
import { X, ArrowLeft, ArrowRight } from 'lucide-react';


import { createBlockedRoom } from '../../api/blockedRooms';
import { adjustAccountValue, getIncomeAccounts } from '../../api/account';
import { createInvoice } from '../../api/invoiceSale';

import api from "../../api/api.js";
import { createTransaction } from '../../api/accounting.js';

import { invoiceCount as invoiceCountAPI } from '../../api/invoiceSale.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getDishSections as getRestaurantProducts} from '../../api/dishSection.js';
import { getBarProducts  } from '../../api/barProduct.js';

interface ReservationModalProps {
  isOpen: boolean;
  onClose: () => void;
  roomName: string;
  selectedDate: Date;
  roomNumber: string;
  roomType: string;
  price: number;
  roomId: string;
  minibar: { id: string; name: string; quantity: number }[];
  inventory: any[]; // Add inventory prop with default empty array
}


interface Product {
  id: string;
  name: string;
  description: string;
  price: number;
  type: string;
  categoryOrderProductId: string;
}

type BookingType = 'book' | 'hold' | 'block';
type BookingStep = 'details' | 'extras';

const BAR_ITEMS = [
  { id: 1, name: 'House Wine', price: 25 },
  { id: 2, name: 'Craft Beer', price: 8 },
  { id: 3, name: 'Cocktail', price: 12 },
];

const RESTAURANT_ITEMS = [
  { id: 1, name: 'Breakfast Buffet', price: 20 },
  { id: 2, name: 'Lunch Set Menu', price: 30 },
  { id: 3, name: 'Dinner À la carte', price: 45 },
];

const INVENTORY_ITEMS = [
  { id: 1, name: 'Extra Towels', price: 5 },
  { id: 2, name: 'Bathrobe', price: 15 },
  { id: 3, name: 'Slippers', price: 8 },
];

const MINIBAR_ITEMS = [
  { id: 1, name: 'Soft Drinks', price: 4 },
  { id: 2, name: 'Snacks', price: 6 },
  { id: 3, name: 'Water', price: 3 },
];

interface InventoryItem {
  item: string;
  quantity: number;
  goodCondition: boolean;
  price?: number; // Add default price for inventory items
}

const INVENTORY_PRICE = 5; // Default price per inventory item

const ReservationModal = ({ isOpen, onClose, roomName, selectedDate, roomNumber, roomType, price , roomId, minibar = [], inventory = []}: ReservationModalProps) => {
  const [bookingType, setBookingType] = useState<BookingType>('book');
  const [currentStep, setCurrentStep] = useState<BookingStep>('details');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedExtras, setSelectedExtras] = useState({
    barItems: [],
    restaurantItems: [],
    inventoryItems: inventory
      .filter(item => item.item.trim() !== '') // Filter out empty items
      .map(item => ({
        id: item.item,
        quantity: item.quantity, // Set initial quantity from inventory
        baseQuantity: item.quantity, // Store original quantity for reference
        goodCondition: item.goodCondition,
        price: INVENTORY_PRICE
      })),
    minibarItems: minibar.map(item => ({
      id: item.id,
      name: item.product?.name || '',
      quantity: item.quantity || 0
    }))
  });
  const [minibarTotal, setMinibarTotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState(price);
  const [extrasTotalPrice, setExtrasTotalPrice] = useState(price);
  const [invoiceCount, setInvoiceCount] = useState(0);

  const [barProducts, setBarProducts] = useState<any[]>([]);
  const [restaurantProducts, setRestaurantProducts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  console.log("MINIBAR",minibar);


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        const [barResponse, restaurantResponse] = await Promise.all([
          getBarProducts(),
          getRestaurantProducts()
        ]);
        setBarProducts(Array.isArray(barResponse.data) ? barResponse.data : []);
        setRestaurantProducts(Array.isArray(restaurantResponse.data) ? restaurantResponse.data : []);
        
        console.log('Bar Products:', barResponse.data);
        console.log('Restaurant Products:', restaurantResponse.data);
      } catch (error) {
        console.error('Error fetching products:', error);
        toast.error('Failed to load products');
        setBarProducts([]);
        setRestaurantProducts([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchProducts();
    }
  }, [isOpen]);
  
  useEffect(() => {
    const fetchInvoiceCount = async () => {
        try {
            const response = await invoiceCountAPI(); // Adjust the endpoint as necessary
            console.log("INVOICE COUNT", response);
            setInvoiceCount(response.data.count); // Set the invoice count
        } catch (error) {
            console.error('Error fetching invoice count:', error);
            toast.error('Failed to load invoice count.');
        }
    };

    fetchInvoiceCount();
}, []);

  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    phoneNumber: '',
    adults: 0,
    children: 0,
    discount: 0,
    paymentType: null,
    deposit: 0,
    totalPrice: price,
    paidPrice: price,
    additionaPay: 0,
    cardNumber: null,
    selectedExtras: [],
    minibarItems: [],
    restaurantItems: [],
    inventoryItems: [],
    barItems: [],
    account: null,
    rentPerNight: 0,
    tax: null,
    holdBookingTill: null,
    enquiryAppId: null,
    checkInDate: selectedDate.toISOString().split('T')[0],
    checkOutDate: '',
})

    console.log('Room Number:', roomNumber);
    console.log('Room Type:', roomType);
    console.log('Room Price:', price); 


    const [blockFormData, setBlockFormData] = useState({
      fromDate: '',
      toDate: '',
      reason: '',
      notes: '',
  });
  

    const taxRates = {
      VAT: 0.20, // 20%
      GST: 0.18, // 18%
      ServiceTax: 0.15, // 15%
  };

  const [basePrice, setBasePrice] = useState(price);

  // Initialize the fixed total price that includes room and minibar
  const [initialTotal, setInitialTotal] = useState(price);

  const calculateInitialMinibarTotal = () => {
    return minibar.reduce((total, item) => {
      return total + (item.soldPrice || 0) * (item.quantity || 1);
    }, 0);
  };

  useEffect(() => {
    if (currentStep === 'details') {
      // In details step, only show room price without minibar
      setFormData(prev => ({
        ...prev,
        totalPrice: price, // Just the room price
        paidPrice: price  // Just the room price, removing minibar calculation
      }));
    } else if (currentStep === 'extras') {
      // In extras step, show the grand total for both fields
      const grandTotal = calculateTotalPrice();
      setFormData(prev => ({
        ...prev,
        totalPrice: grandTotal,
        paidPrice: grandTotal
      }));
    }
  }, [currentStep, price]);

  const calculateTotalPrice = () => {
    // Start with the initial paid price from booking page
    const basePrice = price;
    console.log('Base Price:', basePrice);

    // Calculate bar items total
    const barTotal = selectedExtras.barItems.reduce((total, item) => {
      const barItem = barProducts.find(i => i.id === item.id);
      if (!barItem) return total;
      console.log(`Bar item ${barItem.name}:`, {
        quantity: item.quantity,
        price: barItem.price,
        subtotal: item.quantity * barItem.price
      });
      return total + (item.quantity * Number(barItem.price));
    }, 0);
    console.log('Bar Total:', barTotal);

    // Calculate restaurant items total
    const restaurantTotal = selectedExtras.restaurantItems.reduce((total, item) => {
      const restaurantItem = restaurantProducts.find(i => i.id === item.id);
      if (!restaurantItem) return total;
      console.log(`Restaurant item ${restaurantItem.name}:`, {
        quantity: item.quantity,
        price: restaurantItem.price,
        subtotal: item.quantity * restaurantItem.price
      });
      return total + (item.quantity * Number(restaurantItem.price));
    }, 0);
    console.log('Restaurant Total:', restaurantTotal);

    // Calculate minibar total
    const minibarTotal = selectedExtras.minibarItems.reduce((total, item) => {
      const minibarItem = minibar.find(i => i.id === item.id);
      if (!minibarItem) return total;
      return total + (item.quantity * minibarItem.soldPrice);
    }, 0);
    console.log('Minibar Total:', minibarTotal);

    // Calculate inventory total
    const inventoryTotal = selectedExtras.inventoryItems.reduce((total, item) => {
      if (item.quantity > 0) {
        return total + (item.quantity * INVENTORY_PRICE);
      }
      return total;
    }, 0);
    console.log('Inventory Total:', inventoryTotal);

    const grandTotal = basePrice + barTotal + restaurantTotal + minibarTotal + inventoryTotal;
    console.log('Grand Total:', grandTotal);
    return grandTotal;
  };


  const handleAccountChange = (selectedAccountId: any) => {
    setFormData((prevData) => ({
        ...prevData,
        account: selectedAccountId, // Ensure this is set correctly
    }));
};
  // const generateInvoiceNumber = () => {
  //   currentInvoiceCount += 1; // Increment the count
  //   const invoiceNumber = `INV-Room-${String(currentInvoiceCount).padStart(3, '0')}`; // Format as INV-001, INV-002, etc.
  //   return invoiceNumber;
  // };

  const generateInvoiceNumber = () => {
    return `INV-Room-${String(invoiceCount + 1).padStart(3, '0')}`; // Increment the count for the new invoice
};
  
  useEffect(() => {
    const calculatePaidPrice = () => {
      const discountAmount = formData.discount || 0;
      const depositAmount = formData.deposit || 0;
  
      let taxAmount = 0;
      if (formData.tax && formData.tax !== "NoTax") {
        // Use appropriate base price depending on step
        const basePrice = currentStep === 'details' ? 
          price : // Just room price in details step
          formData.totalPrice;
        taxAmount = (taxRates[formData.tax] || 0) * (basePrice - discountAmount);
      }
  
      const newPaidPrice = (currentStep === 'details' ? 
        price : // Just room price in details step
        formData.totalPrice) - discountAmount + depositAmount + taxAmount;
  
      setFormData(prev => ({ 
        ...prev, 
        paidPrice: newPaidPrice,
      }));
    };
  
    calculatePaidPrice();
  }, [formData.discount, formData.deposit, formData.tax, currentStep, price]);

  const [incomeAccounts, setIncomeAccounts] = useState([]);

  useEffect(() => {
    const fetchIncomeAccounts = async () => {
      try {
        const response = await getIncomeAccounts();
        setIncomeAccounts(response.data); // Assuming the response data is an array of accounts
      } catch (error) {
        console.error('Error fetching income accounts:', error);
        toast.error('Failed to load income accounts.');
      }
    };

    fetchIncomeAccounts();
  }, []);

  useEffect(() => {
    calculateTotalPrice(); // Calculate total price on mount
  }, [selectedExtras]);

  const calculateMinibarTotal = () => {
    const total = selectedExtras.minibarItems.reduce((total, item) => {
      const itemData = minibar.find(i => i.id === item.id);
      return total + (itemData?.soldPrice || 0) * item.quantity; // Use sold price
    }, 0);
    setMinibarTotal(total); // Update the minibar total state
  };

  const updateMinibarQuantity = (itemId: string, newQuantity: number) => {
    console.log('Updating minibar quantity:', { itemId, newQuantity });
    
    setSelectedExtras(prev => {
      const updatedMinibarItems = [...prev.minibarItems];
      const existingItemIndex = updatedMinibarItems.findIndex(item => item.id === itemId);
      
      if (existingItemIndex >= 0) {
        // Update existing item
        updatedMinibarItems[existingItemIndex] = {
          ...updatedMinibarItems[existingItemIndex],
          quantity: newQuantity
        };
      } else if (newQuantity > 0) {
        // Add new item
        const minibarItem = minibar.find(item => item.id === itemId);
        if (minibarItem) {
          updatedMinibarItems.push({
            id: itemId,
            quantity: newQuantity,
            name: minibarItem.name,
            price: minibarItem.soldPrice
          });
        }
      }

      const newExtras = {
        ...prev,
        minibarItems: updatedMinibarItems.filter(item => item.quantity > 0)
      };

      // Calculate new total
      const newTotal = calculateTotalPrice();
      setFormData(prevForm => ({
        ...prevForm,
        totalPrice: newTotal
      }));

      return newExtras;
    });
  };

  useEffect(() => {
    calculateMinibarTotal(); // Calculate minibar total on mount
  }, [selectedExtras]);

  useEffect(() => {
    if (currentStep === 'extras') {
      calculateTotalPrice();
    }
  }, [selectedExtras, currentStep]);

  if (!isOpen) return null;

  
  const handleBlockFormChange = (e) => {
    const { name, value } = e.target;
    setBlockFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));
};


  // const handleQuantityChange = (
  //   category: keyof typeof selectedExtras,
  //   itemId: number,
  //   quantity: number
  // ) => {
  //   setSelectedExtras(prev => ({
  //     ...prev,
  //     [category]: prev[category].some(item => item.id === itemId)
  //       ? prev[category].map(item =>
  //           item.id === itemId ? { ...item, quantity } : item
  //         )
  //       : [...prev[category], { id: itemId, quantity }],
  //   }));
  // };

  const handleQuantityChange = (category: string, itemId: string, newQuantity: number) => {
    console.log('Handling quantity change:', { category, itemId, newQuantity });

    if (!itemId) return;

    // Ensure newQuantity is not negative
    const validQuantity = Math.max(0, newQuantity);

    setSelectedExtras(prev => {
      const categoryItems = prev[category];
      const existingItemIndex = categoryItems.findIndex(item => item.id === itemId);
      
      let updatedItems;
      if (existingItemIndex >= 0) {
        // Update existing item with the new quantity
        updatedItems = categoryItems.map(item => 
          item.id === itemId 
            ? { ...item, quantity: validQuantity }
            : item
        );
      } else if (validQuantity > 0) {
        // Add new item only if quantity is greater than 0
        const itemDetails = category === 'barItems' 
          ? barProducts.find(item => item.id === itemId)
          : restaurantProducts.find(item => item.id === itemId);

        if (itemDetails) {
          updatedItems = [
            ...categoryItems,
            {
              id: itemId,
              name: itemDetails.name,
              quantity: validQuantity,
              price: Number(itemDetails.price)
            }
          ];
        } else {
          updatedItems = categoryItems;
        }
      } else {
        // Remove item if quantity is 0
        updatedItems = categoryItems.filter(item => item.id !== itemId);
      }

      const newExtras = {
        ...prev,
        [category]: updatedItems
      };

      // Calculate new total
      const newTotal = calculateTotalPrice();
      console.log('New Total after quantity change:', newTotal);
      
      setFormData(prevForm => ({
        ...prevForm,
        totalPrice: newTotal
      }));

      return newExtras;
    });
  };

  const renderExtrasStep = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {/* Bar Orders Section */}
      <div className="bg-seashell rounded-lg p-4 max-w-xs mx-auto">
        <h3 className="text-base font-medium text-hazel-green mb-2 flex items-center">
          <span className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center mr-2">
            <span className="text-sm text-hazel-green">1</span>
          </span>
          Bar Orders
        </h3>
        <div className="grid grid-cols-1 gap-2">
          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">
              Select Item
            </label>
            <select 
            className="w-full border border-grey-goose rounded-md px-2 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-hazel-green bg-white"
            onChange={(e) => handleQuantityChange('barItems', e.target.value, 1)}
            disabled={isLoading}
          >
            <option value="">Choose a bar item</option>
            {barProducts.map(item => (
              <option key={item.id} value={item.id}>
                {item.name} - €{Number(item.price).toFixed(2)}
              </option>
            ))}
          </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">
              Selected Items
            </label>
            <div className="space-y-1">
              {selectedExtras.barItems.map(item => {
                const barItem = barProducts.find(i => i.id === item.id);
                return (
                  <div key={item.id} className="flex items-center justify-between bg-white p-1 rounded-md border border-grey-goose">
                    <span className="text-sm text-bluish-grey">{barItem?.name}</span>
                    <div className="flex items-center space-x-1">
                      <input
                        type="number"
                        min="1"
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(
                          'barItems',
                          item.id,
                          parseInt(e.target.value) || 0
                        )}
                        className="w-12 px-1 py-1 text-sm border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
                      />
                      <button
                        onClick={() => removeItem('barItems', item.id)}
                        className="text-watermelon hover:text-watermelon/80 p-1"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
  
      {/* Restaurant Orders Section */}
      <div className="bg-seashell rounded-lg p-4 max-w-xs mx-auto">
        <h3 className="text-base font-medium text-hazel-green mb-2 flex items-center">
          <span className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center mr-2">
            <span className="text-sm text-hazel-green">2</span>
          </span>
          Restaurant Orders
        </h3>
        <div className="grid grid-cols-1 gap-2">
          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">
              Select Item
            </label>
            <select 
              className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-hazel-green bg-white"
              onChange={(e) => handleQuantityChange('restaurantItems', e.target.value, 1)}
              disabled={isLoading}
            >
              <option value="">Choose a restaurant item</option>
              {!isLoading && restaurantProducts && restaurantProducts.map(item => (
                <option key={item.id} value={item.id}>
                  {item.name} - €{Number(item.price).toFixed(2)}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-bluish-grey mb-1">
              Selected Items
            </label>
            <div className="space-y-2">
              {selectedExtras.restaurantItems.map(item => {
                const restaurantItem = restaurantProducts.find(i => i.id === item.id);
                return (
                  <div key={item.id} className="flex items-center justify-between bg-white p-2 rounded-md border border-grey-goose">
                    <span className="text-sm text-bluish-grey">{restaurantItem?.name}</span>
                    <div className="flex items-center space-x-2">
                      <input
                        type="number"
                        min="1"
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(
                          'restaurantItems',
                          item.id,
                          parseInt(e.target.value) || 0
                        )}
                        className="w-16 px-2 py-1 text-sm border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
                      />
                      <button
                        onClick={() => removeItem('restaurantItems', item.id)}
                        className="text-watermelon hover:text-watermelon/80 p-1"
                      >
                        <X size={16} />
                        {/* Here we should modify this to somehting else I thin this is not entirely correct wh */}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
  
      {/* Extra Inventory Section */}
      <div className="bg-seashell rounded-lg p-4 max-w-xs mx-auto">
        <h3 className="text-base font-medium text-hazel-green mb-2 flex items-center">
          <span className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center mr-2">
            <span className="text-sm text-hazel-green">3</span>
          </span>
          Room Inventory
        </h3>
        <div className="grid grid-cols-1 gap-2">
          {inventory.filter(item => item.item.trim() !== '').map(item => (
            <div key={item.item} className="flex items-center justify-between bg-white p-2 rounded-md border border-grey-goose">
              <div className="flex flex-col">
                <span className="text-sm text-bluish-grey">{item.item}</span>
                <div className="flex items-center space-x-2">
                  <span className="text-xs text-gray-500">
                    Included: {item.quantity}
                  </span>
                  {item.goodCondition ? (
                    <span className="text-xs text-green-500">Good Condition</span>
                  ) : (
                    <span className="text-xs text-red-500">Poor Condition</span>
                  )}
                </div>
                {(selectedExtras.inventoryItems.find(i => i.id === item.item)?.quantity || 0) > item.quantity && (
                  <span className="text-xs text-orange-500">
                    Additional items: {(selectedExtras.inventoryItems.find(i => i.id === item.item)?.quantity || 0) - item.quantity} 
                    (€{((selectedExtras.inventoryItems.find(i => i.id === item.item)?.quantity || 0) - item.quantity) * INVENTORY_PRICE})
                  </span>
                )}
              </div>
              <div className="flex items-center space-x-2">
                {/* <button
                  type="button"
                  onClick={() => updateInventoryQuantity(
                    item.item,
                    Math.max(0, (selectedExtras.inventoryItems.find(i => i.id === item.item)?.quantity || 0) - 1)
                  )}
                  className="bg-gray-200 rounded-full p-1 hover:bg-gray-300 transition disabled:opacity-50"
                  disabled={!item.goodCondition}
                >
                  -
                </button> */}
                <input
                  type="number"
                  min="0"
                  value={selectedExtras.inventoryItems.find(i => i.id === item.item)?.quantity || 0}
                  onChange={(e) => updateInventoryQuantity(item.item, parseInt(e.target.value) || 0)}
                  className="w-16 px-2 py-1 text-sm border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
                  // disabled={!item.goodCondition}
                  disabled={true}
                />
                {/* <button
                  type="button"
                  onClick={() => updateInventoryQuantity(
                    item.item,
                    (selectedExtras.inventoryItems.find(i => i.id === item.item)?.quantity || 0) + 1
                  )}
                  className="bg-gray-200 rounded-full p-1 hover:bg-gray-300 transition disabled:opacity-50"
                  disabled={!item.goodCondition}
                >
                  +
                </button> */}
              </div>
            </div>
          ))}
        </div>
      </div>
  
      {/* Minibar Items Section */}
      <div className="bg-seashell rounded-lg p-4 max-w-xs mx-auto">
      <h3 className="text-base font-medium text-hazel-green mb-2 flex items-center">
        <span className="w-6 h-6 rounded-full bg-hazel-green/10 flex items-center justify-center mr-2">
          <span className="text-sm text-hazel-green">4</span>
        </span>
        Minibar Items
      </h3>
      <div className="grid grid-cols-1 gap-2 max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent pr-2">
        {minibar.map(item => {
          const selectedItem = selectedExtras.minibarItems.find(si => si.id === item.id);
          return (
            <div key={item.id} className="flex items-center justify-between bg-white p-2 rounded-md border border-grey-goose">
              <div className="flex flex-col mx-4">
                <span className="text-sm text-bluish-grey">{item.name}</span>
                <span className="text-xs text-gray-500">€{Number(item.soldPrice).toFixed(2)} each</span>
              </div>
              <div className="flex items-center space-x-2">
                {/* <button
                  type="button"
                  onClick={() => updateMinibarQuantity(item.id, Math.max(0, (selectedItem?.quantity || 0) - 1))}
                  className="bg-gray-200 rounded-full p-1 hover:bg-gray-300 transition"
                >
                  -
                </button> */}
                <input
                  type="number"
                  min="0"
                  value={selectedItem?.quantity || 0}
                  onChange={(e) => updateMinibarQuantity(item.id, parseInt(e.target.value) || 0)}
                  className="w-16 px-2 py-1 text-sm border border-grey-goose rounded-md focus:outline-none focus:ring-1 focus:ring-hazel-green"
                  disabled={true}
                />
                {/* <button
                  type="button"
                  onClick={() => updateMinibarQuantity(item.id, (selectedItem?.quantity || 0) + 1)}
                  className="bg-gray-200 rounded-full p-1 hover:bg-gray-300 transition"
                >
                  +
                </button> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>

  
      {/* Order Summary */}
      <div className="bg-tealish-blue rounded-lg p-6">
        <h3 className="text-base font-medium text-hazel-green mb-4">Order Summary</h3>
        <div className="space-y-2">
          {/* Base Room Price */}
          <div className="flex justify-between text-sm">
            <span className="text-bluish-grey">Room Total:</span>
            <span className="font-medium text-hazel-green">€{basePrice.toFixed(2)}</span>
          </div>

          {/* Bar Items Total */}
          {selectedExtras.barItems.length > 0 && (
            <div className="flex justify-between text-sm">
              <span className="text-bluish-grey">Bar Items:</span>
              <span className="font-medium text-hazel-green">
                €{selectedExtras.barItems.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2)}
              </span>
            </div>
          )}

          {/* Restaurant Items Total */}
          {selectedExtras.restaurantItems.length > 0 && (
            <div className="flex justify-between text-sm">
              <span className="text-bluish-grey">Restaurant Items:</span>
              <span className="font-medium text-hazel-green">
                €{selectedExtras.restaurantItems.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2)}
              </span>
            </div>
          )}

          {/* Grand Total */}
          <div className="pt-2 border-t border-tealish-blue/20">
            <div className="flex justify-between text-sm font-medium">
              <span className="text-bluish-grey">Total Amount:</span>
              <span className="text-hazel-green">€{formData.totalPrice.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
  const removeItem = (category: keyof typeof selectedExtras, itemId: number) => {
    setSelectedExtras(prev => ({
      ...prev,
      [category]: prev[category].filter(item => item.id !== itemId)
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isSubmitting) return;

    if (bookingType === 'book' && currentStep === 'details') {
      const initialPaidPrice = formData.paidPrice || 0;
      console.log('Initial Paid Price:', initialPaidPrice); // Debugging log
      setCurrentStep('extras');
      setFormData(prev => ({
        ...prev,
        totalPrice: initialPaidPrice, // Set totalPrice to initial paidPrice
        paidPrice: initialPaidPrice
      }));
      return;
    }

    if (bookingType === 'block') {
      const { fromDate, toDate, reason, notes } = blockFormData;

      
      // Validate the form data
      if (!fromDate || !toDate || !reason || !notes) {
        toast.error('Please fill all required fields.');
        return;
      }

      const blockRoomData = {
        startDate: fromDate,
        endDate: toDate,
        reason,
        notes,
        roomId,
    };
    

        try {
          const response = await createBlockedRoom(blockRoomData);
          console.log('Blocked room created:', response.data);
          toast.success('Room blocked successfully');
          onClose();
      } catch (error) {
          console.error('Error creating blocked room:', error);
          toast.error(error.response?.data || 'Failed to block the room. Please try again.');
      }
      return;
    }
    
    // Prepare data for reservation creation
    const reservationData = {
        fromDate: new Date(formData.checkInDate).toISOString(), // Convert to ISO string
        toDate: new Date(formData.checkOutDate).toISOString(), // Convert to ISO string
        name: formData.name,
        surname: formData.surname,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        adults: formData.adults,
        children: formData.children,
        discount: formData.discount || 0,
        paymentType: formData.paymentType || null,
        deposit: formData.deposit || 0,
        totalPrice: formData.totalPrice || 0,
        paidPrice: formData.paidPrice || 0,
        additionaPay: formData.additionaPay || 0,
        cardNumber: formData.cardNumber || null,
        selectedExtras: selectedExtras,
        minibarItems: selectedExtras.minibarItems || [],
        restaurantItems: selectedExtras.restaurantItems || [],
        inventoryItems: selectedExtras.inventoryItems || [],
        barItems: selectedExtras.barItems || [],
        account: formData.account || null,
        rentPerNight: formData.rentPerNight || 0,
        // tax: formData.tax || null,
        // tax: formData.tax ? taxRates[formData.tax] : 0,
        tax: formData.tax === "VAT" ? 0.20 : formData.tax === "GST" ? 0.18 : formData.tax === "ServiceTax" ? 0.15 : parseFloat(formData.tax) || 0, // Convert to percentage or use the provided value


        holdBookingTill: formData.holdBookingTill ? new Date(formData.holdBookingTill) : null,
        enquiryAppId: formData.enquiryAppId || null,
        status: "CONFIRMED",
        rooms: [
            {
                id: roomId, // Use the room id for the rooms
                adults: formData.adults,
                children: formData.children,
            },
        ],
    };

    let clientId:any;
    try {
        const response = await api.post('/api/accounting/clients/check-or-create', {
            name: formData.name,
            phone: formData.phoneNumber,
            email: formData.email,
            address: formData.address, // Assuming you have an address field in formData
        });
        clientId = response.data.id; // Get the client ID from the response
    } catch (error) {
        console.error('Error checking or creating client:', error);
        toast.error('Failed to check or create client.');
        return;
    }

    //invoice creation
    const invoiceNumber = generateInvoiceNumber();

    const invoiceData = {
      invoiceNumber: invoiceNumber, // Use the generated invoice number
      clientId: clientId, // Set client as name and surname
      invoiceDate: new Date(),
      reference: invoiceNumber, // Use the same invoice number as reference
      terms: 'Payment due upon receipt',
      notes: 'Reservation Invoice',
      paymentMethod: reservationData.paymentType || 'Credit Card', // Set payment method from reservation
      status: 'Completed', // Customize as needed
      items: [
          {
              details: 'Room Reservation', // Ensure this field is populated
              quantity: 1,
              rate: reservationData.totalPrice, // Use totalPrice from reservationData
              discount: reservationData.discount || 0, // Set discount from reservationData
              tax: reservationData.tax || 0, // Set tax from reservationData
              amount: reservationData.totalPrice - (reservationData.discount || 0), // Calculate amount after discount
          },
      ],
  };
    try {
        const response = await createReservation(reservationData);
        console.log("INVOICE DATA", invoiceData)
        await createInvoice(invoiceData);
        
        const adjustmentAmount = reservationData.totalPrice;
        console.log("amount", adjustmentAmount)
        await adjustAccountValue(formData.account, adjustmentAmount); // Call the API to update the account// Implement this function to update the account


        //transaction
        console.log("Selected Account ID:", formData.account);

        
        const transactionData = {
          acceptedBy: "Lake Side Hotel",
          amount: reservationData.totalPrice,
          paymentType: reservationData.paymentType,
          transactionType: "RESERVATION",
          clientId,
          accountId: parseInt(formData.account, 10),
          description: `Room reservation for ${formData.name} ${formData.surname}`,
          TransactionProduct: [
            {
              item: 'Room Reservation',
              type: 'Service',
              unit: 'Night',
              quantity: 1,
              unitPrice: reservationData.totalPrice,
              soldPrice: reservationData.totalPrice - (reservationData.discount || 0),
            },
          ],
        };
        
        
        console.log("Transaction data", transactionData);
      // Call the API to create the transaction
      await createTransaction(transactionData);
      
        toast.success('Reservation created successfully!');

        console.log('Reservation created:', response);
        onClose();
    } catch (error) {
          toast.error('An error occurred while creating the reservation');

        console.error('Error creating reservation:', error);
        // Display the toast notification with the error message
        if (error.response && error.response.data) {
            toast.error(error.response.data); // Display the error message
        } else {
            toast.error("An unexpected error occurred."); // Fallback error message
        }
    }
};



const renderBookForm = () => (
  <div className="grid grid-cols-2 gap-6 space-y-6">
    {/* Personal Information Section */}
    <div className="bg-gray-50 p-4 rounded-lg">
      <h3 className="text-sm font-semibold text-gray-700 mb-3">Personal Information</h3>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1">
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Title <span className="text-watermelon">*</span>
          </label>
          <select
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            required
          >
            <option value="">Select Title</option>
            <option value="Mr.">Mr.</option>
            <option value="Mrs.">Mrs.</option>
            <option value="Dr.">Dr.</option>
          </select>
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Name <span className="text-watermelon">*</span>
          </label>
          <input
            type="text"
            placeholder="Enter Name"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
          />
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Surname <span className="text-watermelon">*</span>
          </label>
          <input
            type="text"
            placeholder="Enter Surname"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.surname}
            onChange={(e) => setFormData({ ...formData, surname: e.target.value })}
            required
          />
        </div>
        <div className="col-span-2">
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Email <span className="text-watermelon">*</span>
          </label>
          <input
            type="email"
            placeholder="Enter Email"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            required
          />
        </div>
        <div className="col-span-1">
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Phone <span className="text-watermelon">*</span>
          </label>
          <input
            type="tel"
            placeholder="Enter Phone"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.phoneNumber}
            onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
            required
          />
        </div>
      </div>
    </div>

    {/* Booking Details Section */}
    <div className="bg-gray-50 p-4 rounded-lg">
      <h3 className="text-sm font-semibold text-gray-700 mb-3">Booking Details</h3>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Check-In <span className="text-watermelon">*</span>
          </label>
          <input
            type="date"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.checkInDate}
            onChange={(e) => setFormData({ ...formData, checkInDate: e.target.value })}
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Check-Out <span className="text-watermelon">*</span>
          </label>
          <input
            type="date"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.checkOutDate}
            onChange={(e) => setFormData({ ...formData, checkOutDate: e.target.value })}
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Status <span className="text-watermelon">*</span>
          </label>
          <select
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.status}
            onChange={(e) => setFormData({ ...formData, status: e.target.value })}
            required
          >
            <option value="">Select Status</option>
            <option value="Confirmed">Confirmed</option>
            <option value="Pending">Pending</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Adults <span className="text-watermelon">*</span>
          </label>
          <input
            type="number"
            placeholder="Number of Adults"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.adults}
            onChange={(e) => setFormData({ ...formData, adults: parseInt(e.target.value) })}
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Children
          </label>
          <input
            type="number"
            placeholder="Number of Children"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.children}
            onChange={(e) => setFormData({ ...formData, children: parseInt(e.target.value) })}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Account
          </label>
          <select
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.account}
            onChange={(e) => setFormData({ ...formData, account: e.target.value })}
          >
            <option value="">Select Account</option>
            {incomeAccounts.map(account => (
              <option key={account.id} value={account.id}>
                {account.name} {/* Adjust this based on the actual structure of your account object */}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>

    {/* Payment Section */}
    <div className="bg-gray-50 p-4 rounded-lg">
      <h3 className="text-sm font-semibold text-gray-700 mb-3">Payment Information</h3>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">Payment Type <span className="text-watermelon">*</span></label>
          <select
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            onChange={(e) => setFormData({ ...formData, paymentType: e.target.value })}
            required
          >
            <option value="">Select Payment Type</option>
            <option value="CREDIT_CARD">Credit Card</option>
            <option value="DEBIT_CARD">Debit Card</option>
            <option value="CASH">Cash</option>
            <option value="BANK_TRANSFER">Bank Transfer</option>
            <option value="PAYPAL">PayPal</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">Deposit</label>
          <input
            type="number"
            placeholder="Enter Deposit"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.deposit}
            // onChange={(e) => setFormData({ ...formData, deposit: parseFloat(e.target.value) || 0 })}
            onChange={(e) => setFormData({ ...formData, deposit: Math.max(0, parseFloat(e.target.value) || 0) })}

          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">Discount</label>
          <input
            type="number"
            placeholder="Enter Discount"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.discount}
            // onChange={(e) => setFormData({ ...formData, discount: parseFloat(e.target.value) || 0 })}
            onChange={(e) => setFormData({ ...formData, discount: Math.max(0, parseFloat(e.target.value) || 0) })}

          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">Total Price</label>
          <input
            type="number"
            placeholder="Total Price"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm bg-seashell"
            value={formData.totalPrice}
            readOnly
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">Paid Price</label>
          <input
            type="number"
            placeholder="Enter Paid Price"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.paidPrice}
            readOnly
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">Select Tax</label>
          <select
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            value={formData.tax}
            onChange={(e) => setFormData({ ...formData, tax: e.target.value })}
          >
            <option value="">Select</option>
            <option value="NoTax">No Tax</option>
            <option value="VAT">VAT (20%)</option>
            <option value="GST">GST (18%)</option>
            <option value="ServiceTax">Service Tax (15%)</option>
          </select>
        </div>
      </div>
    </div>
  </div>
);


  const renderHoldForm = () => (
    <>
      {/* First row */}
      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Check-In <span className="text-watermelon">*</span>
          </label>
          <input
            type="date"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            defaultValue={selectedDate.toISOString().split('T')[0]}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Check-Out <span className="text-watermelon">*</span>
          </label>
          <input
            type="date"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Duration <span className="text-watermelon">*</span>
          </label>
          <input
            type="text"
            value="1 Nights"
            readOnly
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm bg-seashell"
          />
        </div>
      </div>

      {/* Guest Information */}
      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Name <span className="text-watermelon">*</span>
          </label>
          <select className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon">
            <option>Select or Add</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Phone <span className="text-watermelon">*</span>
          </label>
          <input
            type="tel"
            placeholder="Enter Phone Number"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Email <span className="text-watermelon">*</span>
          </label>
          <input
            type="email"
            placeholder="Enter Email"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
          />
        </div>
      </div>

      {/* Hold Details */}
      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Account
          </label>
          <select className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon">
            <option>Select</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Hold Booking Till <span className="text-watermelon">*</span>
          </label>
          <div className="grid grid-cols-2 gap-2">
            <input
              type="date"
              className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            />
            <input
              type="time"
              className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Enquiry App Id
          </label>
          <input
            type="text"
            placeholder="Enter Enquiry ID"
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
          />
        </div>
      </div>
    </>
  );


  const renderBlockForm = () => (
    <div className="space-y-6">
      {/* First Row */}
      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            From <span className="text-watermelon">*</span>
          </label>
          <input
            type="date"
            name="fromDate"
            value={blockFormData.fromDate}
            onChange={handleBlockFormChange}
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            To <span className="text-watermelon">*</span>
          </label>
          <input
            type="date"
            name="toDate"
            value={blockFormData.toDate}
            onChange={handleBlockFormChange}
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-bluish-grey mb-1">
            Duration
          </label>
          <input
            type="text"
            value={`${getDuration(blockFormData.fromDate, blockFormData.toDate)} Nights`}
            readOnly
            className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm bg-seashell"
          />
        </div>
      </div>

      {/* Block Reason */}
      <div>
        <label className="block text-sm font-medium text-bluish-grey mb-1">
          Select Type <span className="text-watermelon">*</span>
        </label>
        <select
          name="reason"
          value={blockFormData.reason}
          onChange={handleBlockFormChange}
          className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
          required
        >
          <option value="">Select Reason</option>
          <option value="maintenance">Maintenance</option>
          <option value="renovation">Renovation</option>
          <option value="cleaning">Deep Cleaning</option>
          <option value="other">Other</option>
        </select>
      </div>

      {/* Notes */}
      <div>
        <label className="block text-sm font-medium text-bluish-grey mb-1">
          Notes <span className="text-watermelon">*</span>
        </label>
        <textarea
          name="notes"
          rows={4}
          value={blockFormData.notes}
          onChange={handleBlockFormChange}
          placeholder="Enter DNR Notes or Reason"
          className="w-full border border-grey-goose rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-watermelon/20 focus:border-watermelon"
          required
        />
      </div>
    </div>
  );

  // Function to calculate the duration between two dates
  const getDuration = (fromDate, toDate) => {
    const start = new Date(fromDate);
    const end = new Date(toDate);
    const timeDiff = end.getTime() - start.getTime();
    return timeDiff > 0 ? timeDiff / (1000 * 3600 * 24) : 0; // Calculate duration in days
  };

  // Update inventory quantity handler
  const updateInventoryQuantity = (itemId: string, newQuantity: number) => {
    console.log('Updating inventory quantity:', { itemId, newQuantity });
    
    setSelectedExtras(prev => {
      const updatedInventoryItems = [...prev.inventoryItems];
      const existingItemIndex = updatedInventoryItems.findIndex(item => item.id === itemId);
      
      if (existingItemIndex >= 0) {
        // Update existing item
        updatedInventoryItems[existingItemIndex] = {
          ...updatedInventoryItems[existingItemIndex],
          quantity: newQuantity
        };
      } else if (newQuantity > 0) {
        // Add new item
        updatedInventoryItems.push({
          id: itemId,
          quantity: newQuantity,
          price: INVENTORY_PRICE
        });
      }

      const newExtras = {
        ...prev,
        inventoryItems: updatedInventoryItems.filter(item => item.quantity > 0)
      };

      // Calculate new total
      const newTotal = calculateTotalPrice();
      setFormData(prevForm => ({
        ...prevForm,
        totalPrice: newTotal
      }));

      return newExtras;
    });
  };

  const incrementQuantity = (itemId: string, category: 'barItems' | 'restaurantItems' | 'minibar' | 'inventory') => {
    const items = selectedExtras[category];
    const currentQuantity = items.find(item => item.id === itemId)?.quantity || 0;
    
    if (category === 'barItems' || category === 'restaurantItems') {
      handleQuantityChange(category, itemId, currentQuantity + 1);
    } else if (category === 'minibar') {
      updateMinibarQuantity(itemId, currentQuantity + 1);
    } else {
      updateInventoryQuantity(itemId, currentQuantity + 1);
    }
  };

  const decrementQuantity = (itemId: string, category: 'barItems' | 'restaurantItems' | 'minibar' | 'inventory') => {
    const items = selectedExtras[category];
    const currentQuantity = items.find(item => item.id === itemId)?.quantity || 0;
    
    if (currentQuantity > 0) {
      if (category === 'barItems' || category === 'restaurantItems') {
        handleQuantityChange(category, itemId, currentQuantity - 1);
      } else if (category === 'minibar') {
        updateMinibarQuantity(itemId, currentQuantity - 1);
      } else {
        updateInventoryQuantity(itemId, currentQuantity - 1);
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-[600px] max-h-[90vh] overflow-auto">
        <div className="p-4 border-b border-grey-goose flex items-center justify-between">
          <h2 className="text-lg font-semibold text-bluish-grey">
            {roomName} - Add Reservation {bookingType === 'book' && currentStep === 'extras' ? '- Additional Services' : ''}
          </h2>
          <button onClick={onClose} className="text-bluish-grey hover:text-watermelon">
            <X size={20} />
          </button>
        </div>
        
        <div className="p-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Booking Type Tabs - Only show in first step */}
            {(bookingType !== 'book' || currentStep === 'details') && (
              <div className="flex space-x-4 border-b border-grey-goose">
                <button
                  type="button"
                  onClick={() => setBookingType('block')}
                  className={`px-4 py-2 text-sm font-medium border-b-2 transition-colors ${
                    bookingType === 'block'
                      ? 'border-watermelon text-watermelon'
                      : 'border-transparent text-bluish-grey hover:text-watermelon'
                  }`}
                >
                  Block
                </button>
                <button
                  type="button"
                  onClick={() => setBookingType('hold')}
                  className={`px-4 py-2 text-sm font-medium border-b-2 transition-colors ${
                    bookingType === 'hold'
                      ? 'border-watermelon text-watermelon'
                      : 'border-transparent text-bluish-grey hover:text-watermelon'
                  }`}
                >
                  Hold
                </button>
                <button
                  type="button"
                  onClick={() => setBookingType('book')}
                  className={`px-4 py-2 text-sm font-medium border-b-2 transition-colors ${
                    bookingType === 'book'
                      ? 'border-watermelon text-watermelon'
                      : 'border-transparent text-bluish-grey hover:text-watermelon'
                  }`}
                >
                  Book
                </button>
              </div>
            )}

            {/* Form Content based on booking type and step */}
            {bookingType === 'book' && currentStep === 'details' && renderBookForm()}
            {bookingType === 'book' && currentStep === 'extras' && renderExtrasStep()}
            {bookingType === 'hold' && renderHoldForm()}
            {bookingType === 'block' && renderBlockForm()}
          </form>
        </div>

        <div className="p-4 border-t border-grey-goose flex justify-between">
          {bookingType === 'book' && currentStep === 'extras' && (
            <button
              type="button"
              onClick={() => setCurrentStep('details')}
              className="flex items-center px-4 py-2 text-sm font-medium text-bluish-grey hover:text-watermelon"
            >
              <ArrowLeft size={16} className="mr-2" />
              Back to Details
            </button>
          )}
          <div className="ml-auto flex space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-bluish-grey hover:text-watermelon"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              disabled={isSubmitting}
              className="px-4 py-2 text-sm font-medium text-white bg-watermelon rounded-md hover:bg-watermelon/90 flex items-center disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? (
                <div className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Processing...
                </div>
              ) : bookingType === 'book' && currentStep === 'details' ? (
                <>
                  Next Step
                  <ArrowRight size={16} className="ml-2" />
                </>
              ) : (
                'Save Reservation'
              )}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ReservationModal;
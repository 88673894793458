import React from 'react';

const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div className="border border-gray-300 rounded-lg overflow-hidden">
      <div className="flex">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`flex-1 py-2 text-sm font-medium transition-colors duration-200 focus:outline-none ${
              activeTab === tab
                ? 'bg-hazel-green text-white shadow-md'
                : 'bg-white text-gray-700 hover:bg-gray-100'
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="border-t border-gray-300">
        <div className="p-4">
          {activeTab === 'Day' && <div>Day Content</div>}
          {activeTab === 'Month' && <div>Month Content</div>}
          {activeTab === 'Year' && <div>Year Content</div>}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
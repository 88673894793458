import React, { useState, useEffect, useCallback } from "react";
import useAuth from '../../hooks/useAuth';
import { useSelector } from 'react-redux';
import PermissionCheck from '../../utils/PermissionCheck';
import { FunctionButton } from "./FunctionButtons";
import TableGrid  from './TableGrid';
import Loading from '../../components/loader';
import ReservationList from './ReservationList';

import { AddButton } from '../../components/buttons';
import { getEnvironments } from '../../api/restaurant';


export default function MainRestaurantPage() {
    const [loading, setLoading] = useState(false);

    const [activeButtonReservation, setActiveButtonReservation] = useState("buttonReservation1");
    const [activeButtonFloor, setActiveButtonFloor] = useState(null);

    const [environments, setEnvironments] = useState([]);


    const [restaurant, setRestaurant] = useState(null);

    const user = useSelector((state) => state.user.user);
    const waiterId = user?.id;
    console.log("waiter id", waiterId);

    console.log("waiter id", waiterId)

    // Fetch environments
    const fetchEnvironments = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getEnvironments();
            setEnvironments(response.data);
            if (response.data.length > 0 && !activeButtonFloor) {
                setActiveButtonFloor(response.data[0].id);
            }
        } catch (err) {
            console.error('Failed to fetch environments:', err);
        } finally {
            setLoading(false);
        }
    }, [activeButtonFloor]);

    useEffect(() => {
        fetchEnvironments();
    }, [fetchEnvironments]);

    // Refresh data periodically
    useEffect(() => {
        const intervalId = setInterval(fetchEnvironments, 30000); // Refresh every 30 seconds
        return () => clearInterval(intervalId);
    }, [fetchEnvironments]);

    if (loading) {
        return <Loading />;
    }
    

    const activeEnvironment = environments.find(env => env.id === activeButtonFloor);
    
    return (
        <div>
            <div className="flex flex-col w-full items-start justify-center gap-10 my-6 p-6">

                <div className="w-full flex flex-row justify-between items-center">
                    <div className="flex flex-col gap-6">
                        <h1 className="text-2xl font-bold text-hazel-green">Table Reservation</h1>
                        <div className="flex flex-row items-start justify-start space-x-4">
                            <FunctionButton
                                small={false}
                                green={true}
                                className="rounded-full"
                                isActive={activeButtonReservation === "buttonReservation1"}
                                onClick={() => setActiveButtonReservation("buttonReservation1")}
                            >
                                Reservation Grid
                            </FunctionButton>

                            <FunctionButton 
                                small={false} 
                                green={true} 
                                className="rounded-full"
                                isActive={activeButtonReservation === "buttonReservation2"}
                                onClick={() => setActiveButtonReservation("buttonReservation2")}
                            >
                                Reservation List
                            </FunctionButton>

                        </div>
                    </div>

                    <div className="flex flex-row gap-4">
                        <AddButton
                            // entity='users'
                            link='/restaurant/add-environment'
                            title='New enviroment'

                        />

                        <AddButton
                            // entity='users'
                            link='/restaurant/add-table'
                            title='New table'

                        />
                    </div>
                </div>

                


                <div className="flex flex-col gap-4">
                    <h3 className="font-semibold text-lg text-gray-800">
                        Floors Plan
                    </h3>
                    
                    <div className="flex flex-row items-start justify-start space-x-4">
                            {environments.map((environment) => (
                                    <FunctionButton 
                                        key={environment.id} 
                                        small={true} 
                                        green={true} 
                                        className="rounded-full"
                                        isActive={activeButtonFloor === environment.id}
                                        onClick={() => setActiveButtonFloor(environment.id)}
                                    >
                                        {environment.name}
                                    </FunctionButton>
                            ))}
                    </div>
                </div>


                {/* <div>
                    {restaurant.floors.map((floor) => (
                        <TableGrid key={floor.id} floor={floor} />
                    ))}
                </div> */}
                {activeButtonReservation === "buttonReservation1" ? (
                    activeEnvironment ? (
                        <TableGrid environment={activeEnvironment} waiterId={waiterId} />
                    ) : (
                        <p>No environment selected.</p>
                    )
                ) : (
                    <ReservationList />
                )}
            </div>
        </div>
    );
}
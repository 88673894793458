import React, { useState, useEffect } from 'react';
import BarCard from '../views/restaurantInterface/BarCard';
import { useDrop } from 'react-dnd';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';

const BarOrderAccordion = ({ order, onBarItemDrop, onPay }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [droppedBarItems, setDroppedBarItems] = useState(order.BarOrderProduct || []);

  useEffect(() => {
    setDroppedBarItems(order.BarOrderProduct || []);
  }, [order.BarOrderProduct]);

  const [{ isOver }, drop] = useDrop({
    accept: 'barItem',
    drop: item => {
      const newItem = { ...item, uniqueId: uuidv4() };
      setDroppedBarItems(prevBarItems => [...prevBarItems, newItem]);
      onBarItemDrop(order.id, newItem);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleDelete = id => {
    const updatedBarItems = droppedBarItems.filter(barItem => barItem.uniqueId !== id);
    setDroppedBarItems(updatedBarItems);
    onBarItemDrop(order.id, null, id);
  };

  const handlePay = () => {
    onPay(order.id);
  };

  return (
    <div className="mb-2 border border-gray-300 rounded-lg shadow-md">
      <div
        className="p-3 flex flex-col justify-between items-center text-center bg-gray-100 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-sm font-semibold">
            Bar Order <Link to={`/bar-interface/bar-orders/${order.id}`}> #{order.id} </Link>
            - {' '}
            {order.bar ? order.bar.name : 'No bar info'}

            {/* Add any additional details you want to display */}
        </h3>
        <div className="text-xs text-gray-700">
          {droppedBarItems.length > 0
            ? droppedBarItems.map(barItem => barItem.barProduct.name).join(', ')
            : 'No bar items yet'}
        </div>
        <span className="text-lg">{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && (
        <div className="p-4" ref={drop}>
          <div className={isOver ? 'bg-green-100 p-2' : 'bg-white p-2'}>
            {droppedBarItems.length === 0 ? (
              <p className="text-gray-600">Drag bar items here</p>
            ) : (
              <div className="grid grid-cols-1 gap-2">
                {droppedBarItems.map(barItem => (
                  <div key={barItem.uniqueId} className="relative">
                    <button
                      onClick={() => handleDelete(barItem.uniqueId)}
                      className="absolute top-0 right-0 p-1 text-red-600 hover:text-red-800"
                    >
                      ❌
                    </button>
                    <BarCard
                      name={barItem.barProduct.name}
                      imageUrl={barItem.barProduct.imageUrl}
                      description={barItem.barProduct.description}
                      price={barItem.barProduct.price}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <button
            onClick={handlePay}
            className="mt-2 bg-green-500 text-white p-2 rounded"
          >
            Pay Order
          </button>
        </div>
      )}
    </div>
  );
};

export default BarOrderAccordion;

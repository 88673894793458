import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import Loading from '../../../components/loader';
import SelectInput from '../../../components/select-input';
import { SubmitButton, CancelButton } from '../../../components/buttons';
import CustomInput from '../../../components/custom-input';
import { roomStatus } from '../../../constants/entities';
import ConfirmMessage from '../../../components/alerts/alert-message';
import Modal from '../../../components/modal';
import MinibarForm from '../../../components/products/minibarForm';
import useAuth from '../../../hooks/useAuth';
import { createRoom, updateRoom } from '../../../api/hotelInterface';
import { updateInventory } from '../../../api/inventory';
import { giveReservationFeedback } from '../../../api/reservations';
import { getRoomTypes } from '../../../api/hotelInterface';

function RoomForm({ isEdit, room, roomType, loading }) {
  const { userRole } = useAuth();
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [showMinibar, setShowMinibar] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({});

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [roomTypes, setRoomTypes] = useState([]);


  const fetchRoomTypes = useCallback(async () => {
    try {
      const response = await getRoomTypes();
      const formattedRoomTypes = response.data.map((type) => ({
        id: type.id,
        title: type.title, // Format the data as { id, title }
      }));
      setRoomTypes(formattedRoomTypes);
    } catch (err) {
      console.error('Failed to fetch room types:', err);
    }
  }, []);

  useEffect(() => {
    fetchRoomTypes();
  }, [fetchRoomTypes]);

  const handleSelectAllChange = (event, values, setFieldValue) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);

    const updatedInventory = values.inventory.map((item) => ({
      ...item,
      goodCondition: isChecked
    }));
    setFieldValue('inventory', updatedInventory);
  };

  const deleteMinibarItem = async (values, setFieldValue) => {
    try {
      const response = await updateInventory(room.minibar.id, {
        increaseProducts: [],
        decreaseProducts: [{ productId: currentProduct.id }]
      });

      if (response.status === 201) {
        const updatedMinibarProducts = values.minibar.products.filter(
          (item) => item.id !== currentProduct.id
        );
        setFieldValue('minibar.products', updatedMinibarProducts);
      }

      setShowAlert(false);
    } catch (err) {
      console.error('Error deleting minibar item:', err);
    }
  };

  Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
    return this.test('unique', message, function (list) {
      return list.length === new Set(list.map(mapper)).size;
    });
  });

  if (loading) {
    return <Loading />;
  }

  const canEditInventory = ['pastruse', 'admin'].includes(
    userRole.toLowerCase()
  );

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
      <Formik
        initialValues={room}
        validationSchema={Yup.object().shape({
          number: Yup.number().min(1).required('Room Number is required*'),
          floor: Yup.number().min(0).required('Floor is required*'),
          status: Yup.string().required('Status is required*'),
          roomTypeId: Yup.string(),
          inventory: isEdit
            ? Yup.array()
                .of(
                  Yup.object().shape({
                    item: Yup.string(),
                    quantity: Yup.number(),
                    goodCondition: Yup.boolean()
                  })
                )
                .unique('Option already used', (a) => a.item)
            : Yup.string().nullable()
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            if (isEdit) {
              const dataToSend = {
                ...values
              };
              await updateRoom(room.id, dataToSend);
              if (canEditInventory) {
                const reservationFeedback = {
                  review: values.comment,
                  inventory: JSON.stringify(values.inventory)
                };
                await giveReservationFeedback(room.id, reservationFeedback)
                  .then((res) =>
                    toast.success('Feedback updated successfully!')
                  )
                  .catch((err) =>
                    toast.error('An error occurred while updating.')
                  );
              }
            } else {
              const { minibar, ...addValues } = values;
              await createRoom(addValues);
              navigate('/hotel-interface/rooms');
            }
            setSubmitting(false);
          } catch (err) {
            setErrors({
              submit: err?.response?.data
            });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <div>
            <form onSubmit={handleSubmit}>
              <div className='grid grid-cols-2 gap-x-6 gap-y-3'>
                <CustomInput
                  type='number'
                  name='number'
                  label='Room Number'
                  value={values.number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.number && touched.number}
                  errorMessage={errors.number}
                  disabled={canEditInventory}
                />
                {userRole !== 'Pastruse' && (
                  <>
                    {/* <TextField
                      id='outlined-basic'
                      label='Room Type'
                      value={isEdit ? values.roomTypeId : roomType.title}
                      variant='outlined'
                      className='border boder-hazel-green rounded-md w-full'
                      disabled={true}
                    /> */}
                    <SelectInput
                        name='roomTypeId'
                        label='Room Type'
                        value={values.roomTypeId}
                        options={roomTypes} // Set room types options here
                        onChange={handleChange('roomTypeId')}
                        onBlur={handleBlur}
                        showError={errors.roomTypeId && touched.roomTypeId}
                        errorMessage={errors.roomTypeId}
                        className='border boder-hazel-green rounded-md w-full'
                    />
                    <CustomInput
                      type='number'
                      name='floor'
                      label='Floor Number'
                      value={values.floor}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      showError={errors.floor && touched.floor}
                      errorMessage={errors.floor}
                    />
                  </>
                )}
                <SelectInput
                  value={values.status}
                  label='Status'
                  options={roomStatus}
                  onChange={handleChange('status')}
                  onBlur={handleBlur}
                  showError={errors.status && touched.status}
                  errorMessage={errors.status}
                  isEntity
                />
              </div>
              {(canEditInventory ||
                (isEdit &&
                  values?.inventory?.some((item) => item.item !== ''))) && (
                <>
                  <div className='py-3 border-b boder-hazel-green' />
                  <h3 className='text-bluish-grey font-medium my-3'>
                    Inventory
                  </h3>
                  {canEditInventory && (
                    <div className='flex justify-end items-center text-sage font-bold'>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectAllChecked}
                              onChange={(event) =>
                                handleSelectAllChange(
                                  event,
                                  values,
                                  setFieldValue
                                )
                              }
                              inputProps={{ 'aria-label': 'controlled' }}
                              color='success'
                            />
                          }
                          label='Select all'
                        />
                      </FormGroup>
                    </div>
                  )}
                  <FieldArray name='inventory'>
                    <div className='text-center mt-5'>
                      {values?.inventory?.map((option, index) => {
                        return (
                          <div
                            key={index}
                            className='grid grid-cols-8 gap-x-6 mt-4'
                          >
                            <div className='col-span-6'>
                              <CustomInput
                                type='text'
                                name={`inventory[${index}].item`}
                                label='Item'
                                value={option.item}
                                onChange={async (event) => {
                                  setFieldValue(
                                    `inventory[${index}].item`,
                                    event.target.value
                                  );
                                }}
                                onBlur={handleBlur}
                                disabled={!canEditInventory}
                              />
                            </div>
                            <div className='col-span-1'>
                              <CustomInput
                                type='number'
                                name={`inventory[${index}].quantity`}
                                label='Quantity'
                                value={option.quantity}
                                onChange={async (event) => {
                                  setFieldValue(
                                    `inventory[${index}].quantity`,
                                    event.target.value
                                  );
                                }}
                                onBlur={handleBlur}
                                disabled={!canEditInventory}
                              />
                            </div>
                            {canEditInventory && (
                              <div className='flex justify-center items-center mb-3'>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={option.goodCondition}
                                        onChange={(event) => {
                                          setFieldValue(
                                            `inventory[${index}].goodCondition`,
                                            event.target.checked
                                          );
                                        }}
                                        inputProps={{
                                          'aria-label': 'controlled'
                                        }}
                                        color='success'
                                      />
                                    }
                                  />
                                </FormGroup>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </FieldArray>
                </>
              )}
              {canEditInventory && (
                <div className='w-full'>
                  <CustomInput
                    type='text'
                    name='comment'
                    label='Comment'
                    value={values.comment}
                    onChange={handleChange('comment')}
                    onBlur={handleBlur}
                    multiline={true}
                    showError={errors.comment && touched.comment}
                    errorMessage={errors.comment}
                  />
                </div>
              )}
              {isEdit && (
                <>
                  <div className='py-3 border-b boder-hazel-green' />
                  <h3 className='text-bluish-grey font-medium my-3'>Minibar</h3>
                  <div className='grid grid-cols-4 gap-x-3 my-4'>
                    <div
                      className='flex items-center p-3.5 bg-seashell rounded-md cursor-pointer'
                      onClick={() => setShowMinibar(true)}
                    >
                      <p className='text-bluish-grey cursor-pointer'>
                        Add item
                      </p>
                    </div>
                    {values.minibar?.products.map((item, index) => (
                      <div
                        key={index}
                        className='grid grid-cols-3 items-center bg-seashell rounded-md'
                      >
                        <p className='col-span-2 text-bluish-grey border-r-4 p-3.5'>
                          {item.name}
                        </p>
                        <div className='relative py-3.5 text-center'>
                          <p className='text-bluish-grey'>{item.quantity}</p>
                          <div
                            className='absolute rounded-full bg-watermelon w-3 h-3 top-2 right-2 cursor-pointer'
                            onClick={() => {
                              setCurrentProduct(item);
                              setShowAlert(true);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {errors.submit && <p className='text-error'>{errors.submit}</p>}
              <div className='flex justify-end items-center w-full mt-7 text-sm'>
                <CancelButton onCancel={() => navigate('/rooms')} />
                <SubmitButton
                  isSubmitting={isSubmitting}
                  width='w-1/5'
                  title='Save'
                />
              </div>
            </form>
            {showAlert && (
              <ConfirmMessage
                title='Are you sure you want to delete this minibar item?'
                onCancel={() => setShowAlert(!showAlert)}
                onConfirm={() => deleteMinibarItem(values, setFieldValue)}
              />
            )}
            {showMinibar && (
              <Modal
                title='Add item'
                titleSize='text-xl'
                setShowModal={() => setShowMinibar(!showMinibar)}
                children={
                  <MinibarForm
                    roomMinibar={values.minibar}
                    setShowModal={() => setShowMinibar(!showMinibar)}
                    setFieldValue={setFieldValue}
                  />
                }
              />
            )}
          </div>
        )}
      </Formik>
    </div>
  );
}

export default RoomForm;

import React from 'react';
import { HelpCircle, ChevronDown } from 'lucide-react';

const TopExpenses = () => {
  return (
    <div className="card p-6">
      <div className="flex justify-between items-center mb-8">
        <div className="card-header">
          <h2 className="text-xl">Top Expenses</h2>
          <HelpCircle className="help-icon" />
        </div>
        <button className="dropdown-button">
          This Fiscal Year
          <ChevronDown className="w-4 h-4" />
        </button>
      </div>

      <div className="flex items-center justify-center h-64 text-bluish-grey bg-tealish-blue/50 rounded-lg">
        No Expense recorded for this fiscal year
      </div>
    </div>
  );
};

export default TopExpenses;
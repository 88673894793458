import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import Loading from '../../components/loader';
import { AddButton } from '../../components/buttons';
import CustomTable from '../../components/table-components/table';
import { getInvoices, deleteInvoice } from '../../api/invoices';

function InvoicesList() {
  const [invoicesList, setInvoicesList] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getInvoicesList();
  }, []);

  const getInvoicesList = useCallback(async () => {
    try {
      const response = await getInvoices(
        rowsPerPage,
        currentPage + 1,
        searchText
      );
      setInvoicesList(response.data);
      setTotalCount(response.data.length);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, searchText]);

  useEffect(() => {
    getInvoicesList();
  }, [getInvoicesList]);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await deleteInvoice(id);
      setInvoicesList((prevState) => [
        ...prevState.filter((item) => item.id !== id)
      ]);
      setTotalCount(totalCount - 1);
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className='p-10'>
      <AddButton
        entity='users'
        link='/invoices/add-invoice'
        title='New invoice'
      />
      <CustomTable
        rows={invoicesList}
        columns={[
          {
            title: 'Invoice Number',
            renderValue: (item) => item.number
          },
          {
            title: 'Date',
            renderValue: (item) => dayjs(item.date).format('DD/MM/YYYY')
          },
          {
            title: 'Invoice Type',
            renderValue: (item) => item.type
          },
          {
            title: 'Total Price',
            renderValue: (item) => item.totalPrice
          },
          {
            title: 'Provider',
            renderValue: (item) => item.provider
          },
          {
            title: 'NIF',
            renderValue: (item) => item.providerNumber
          },
          {
            title: 'Is Paid',
            renderValue: (item) => (
              <div
                className={`${
                  item.isPaid
                    ? 'bg-seashell text-bluish-grey'
                    : 'bg-dawn-pink text-salmon-pink'
                } rounded-md py-3`}
              >
                <p className='text-center'>{item.isPaid ? 'Yes' : 'No'}</p>
              </div>
            )
          }
        ]}
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={true}
        actionsType='dots'
        editLink='/invoices'
        onDelete={handleDelete}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </div>
  );
}

export default InvoicesList;

import React from "react";
import { useEffect, useState } from "react";

import GridPattern from "./GridPattern";

import { cn } from "../../utils/cn.ts";

import { getPaginatedPoolReservations } from "../../api/poolsInterface";


function PoolInterfaceHistory() {


    const [reservations, setReservations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // const [currentPage, setCurrentPage] = useState(1);
    // const itemsPerPage = 5;
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalEntries, setTotalEntries] = useState(0);
    const limit = 10

    useEffect(() => {
        const fetchReservations = async () => {
            try {
                setLoading(true);
                const response = await getPaginatedPoolReservations(page, limit);
                const data = response.data ? response.data : [];
                console.log('Component - Fetched Paginated Reservations:', data);
                setReservations(data.reservations);
                setTotalPages(Math.ceil(data.totalCount / limit));
            } catch (error) {
                setError('Failed to fetch reservations.');
                console.error('Component - Failed to fetch reservations:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchReservations();
    }, [page]);

    const handleFirstPage = () => {
        setPage(1);
    };

    const handlePreviousPage = () => {
        setPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handleLastPage = () => {
        setPage(totalPages);
    };

    const startEntry = (page - 1) * limit + 1;
    const endEntry = Math.min(page * limit, totalEntries);
        
        // const indexOfLastReservation = currentPage * itemsPerPage;
        // const indexOfFirstReservation = indexOfLastReservation - itemsPerPage;
        // const currentReservations = reservations.slice(indexOfFirstReservation, indexOfLastReservation);

        // const pageNumbers = [];
        // for (let i = 1; i <= Math.ceil(reservations.length / itemsPerPage); i++) {
        //     pageNumbers.push(i);
        // }

    return (

        <div className="p-6 relative bg-slate-100 min-h-screen w-full flex flex-col items-center justify-center gap-6">

            <div className="absolute inset-0 z-1">
                <GridPattern
                    width={20}
                    height={20}
                    x={-1}
                    y={-1}
                    className={cn(
                    "[mask-image:linear-gradient(to_bottom_right,white,transparent,transparent)]"
                    )}
                />
            </div>
            
            <h1 className="font-bold text-2xl text-gray-800">Pools Registration History</h1>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-3">
                                ID
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Pool
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Pool ID
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Ticket Type
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Price
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Created At
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {reservations.length > 0 ? (
                            reservations.map((reservation) => (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="w-4 p-4">
                                        <div className="flex items-center">
                                            <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                        </div>
                                    </td>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {reservation.id}
                                    </th>
                                    <td className="px-6 py-4">
                                        {reservation.pool.type}
                                    </td>
                                    <td className="px-6 py-4">
                                        {reservation.poolId}
                                    </td>
                                    <td className="px-6 py-4">
                                        {reservation.ticketType}
                                    </td>
                                    <td className="px-6 py-4">
                                        {reservation.price}
                                    </td>
                                    <td className="px-6 py-4">
                                        {reservation.createdAt}
                                    </td>
                                    <td className="flex items-center px-6 py-4">
                                        <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                                        <a href="#" className="font-medium text-red-600 dark:text-red-500 hover:underline ms-3">Remove</a>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                            <td colSpan="5" className="py-2 px-4 border-b text-center text-gray-500">No reservations found</td>
                            </tr>
                        )}

                        
                    </tbody>
                </table>
            </div>


            {/* <div className="mt-4">
                <nav aria-label="Page navigation">
                <ul className="inline-flex items-center -space-x-px">
                    <li>
                    <button
                        onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
                        className="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:text-white"
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    </li>
                    {pageNumbers.map(number => (
                    <li key={number}>
                        <button
                        onClick={() => setCurrentPage(number)}
                        className={`px-3 py-2 leading-tight ${number === currentPage ? 'text-blue-600 bg-blue-50' : 'text-gray-500 bg-white'} border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:text-white`}
                        >
                        {number}
                        </button>
                    </li>
                    ))}
                    <li>
                    <button
                        onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, pageNumbers.length))}
                        className="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-700 dark:hover:bg-gray-700 dark:hover:text-white"
                        disabled={currentPage === pageNumbers.length}
                    >
                        Next
                    </button>
                    </li>
                </ul>
                </nav>
            </div> */}


<div className="flex flex-col items-center z-10 mt-4">
                <div className="inline-flex space-x-4 mt-2 xs:mt-0">
                    <button
                        onClick={handleFirstPage}
                        disabled={page === 1}
                        className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                        First
                    </button>
                    <button
                        onClick={handlePreviousPage}
                        disabled={page === 1}
                        className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                        <svg className="w-3.5 h-3.5 me-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5H1m0 0 4 4M1 5l4-4"/>
                        </svg>
                        Prev
                    </button>
                    <span className="flex items-center justify-center px-4 h-10 text-base font-medium text-gray-900 dark:text-gray-400">
                        Page {page} of {totalPages}
                    </span>
                    <button
                        onClick={handleNextPage}
                        disabled={page === totalPages}
                        className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                        Next
                        <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </button>
                    <button
                        onClick={handleLastPage}
                        disabled={page === totalPages}
                        className="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                        Last
                    </button>
                </div>
            </div>

        </div>
    );
}


export default PoolInterfaceHistory;
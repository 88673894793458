import React from 'react';
import Earnings from './Earnings';
const ticketTypes = [
  { type: 'Men', indoor: 20, outdoor: 15 },
  { type: 'Women', indoor: 18, outdoor: 14 },
  { type: 'Kids', indoor: 10, outdoor: 8 },
  // Add more ticket types here
];

const TicketTypes = () => {
  return (
    <div id="tickets" className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="font-bold text-xl mb-4">Ticket Types & Prices</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Type</th>
              <th className="py-2 px-4 border-b">Indoor Price ($)</th>
              <th className="py-2 px-4 border-b">Outdoor Price ($)</th>
            </tr>
          </thead>
          <tbody>
            {ticketTypes.map(ticket => (
              <tr key={ticket.type}>
                <td className="py-2 px-4 border-b">{ticket.type}</td>
                <td className="py-2 px-4 border-b">{ticket.indoor}</td>
                <td className="py-2 px-4 border-b">{ticket.outdoor}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TicketTypes;

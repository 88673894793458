import React from 'react';
import { LinearProgress } from '@mui/material';

export default function Loading() {
  return (
    <div className='w-full'>
      <LinearProgress color='success' />
    </div>
  );
}

import api from "./api";

// Get all statistics
export const getAllStatistics = () => api.get('/api/inTime/statistics');

// Individual endpoints can remain for specific needs
export const getOrdersCountToday = () => api.get('/api/inTime/orders/today');
export const getBarOrdersCountToday = () => api.get('/api/inTime/bar-orders/today');
export const getRoomReservationsCountToday = () => api.get('/api/inTime/room-reservations/today');
export const getConferenceRoomReservationsCountToday = () => api.get('/api/inTime/conference-room-reservations/today');
export const getPoolReservationsCountToday = () => api.get('/api/inTime/pool-reservations/today');
export const getTableReservationsCountToday = () => api.get('/api/inTime/table-reservations/today');

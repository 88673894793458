import React from 'react';
import Header from './header';
import Sidebar from './sidebar';
import { Outlet } from 'react-router-dom';

function Layout({ children }) {
  return (
    <div>
      <Header />
      <div className='flex h-screen'>
        <Sidebar />
        <main className='w-full'>
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default Layout;

// CategoryForm.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../components/loader';
import CustomInput from '../../../components/custom-input';
import { SubmitButton, CancelButton } from '../../../components/buttons';
import { addCategory, updateCategory, getCategoryById } from '../../../api/stockCategories'; // Adjust path as necessary

function CategoryForm({ isEdit, categoryId }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
  });

  useEffect(() => {
    if (isEdit) {
      fetchCategoryData();
    }
  }, [isEdit, categoryId]);

  const fetchCategoryData = async () => {
    setLoading(true);
    try {
      const response = await getCategoryById(categoryId);
      setInitialValues({
        name: response.data.name,
        description: response.data.description,
      });
    } catch (err) {
      console.error('Error fetching category data:', err);
    }
    setLoading(false);
  };

  const handleSubmit = async (values, { setErrors, setSubmitting }) => {
    try {
      if (isEdit) {
        await updateCategory(categoryId, values);
      } else {
        await addCategory(values);
      }
      navigate('/categories'); // Adjust to the correct path
    } catch (err) {
      setErrors({ submit: err?.response?.data });
      setSubmitting(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Category name is required*'),
          description: Yup.string(),
        })}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 gap-y-4'>
              <CustomInput
                type='text'
                name='name'
                label='Category Name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.name && touched.name}
                errorMessage={errors.name}
              />
              <CustomInput
                type='text'
                name='description'
                label='Description'
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.description && touched.description}
                errorMessage={errors.description}
              />
            </div>

            {errors.submit && <p className='text-error'>{errors.submit}</p>}
            <div className='flex justify-end items-center w-full mt-7 text-sm'>
              <CancelButton onCancel={() => navigate('/stock')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/5'
                title='Save'
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default CategoryForm;

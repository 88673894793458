import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../components/loader';
import { SubmitButton, CancelButton } from '../../components/buttons';
import CustomInput from '../../components/custom-input';
import SelectInput from '../../components/select-input';
import { getEnvironments, createTable, updateTable } from '../../api/restaurant';

function TableForm({ isEdit, table }) {
  const navigate = useNavigate();
  const [environments, setEnvironments] = useState([]);
  const [loading, setLoading] = useState(true);

  const getEnvironmentList = useCallback(async () => {
    try {
      const response = await getEnvironments();
      const formattedEnvironments = response.data.map((env) => ({
        id: env.id,
        name: env.name
      }));
      setEnvironments(formattedEnvironments);
    } catch (err) {
      console.error('Failed to fetch environments:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getEnvironmentList();
  }, [getEnvironmentList]);

  const validationSchema = Yup.object().shape({
    number: Yup.number().positive('Table number must be a positive number').required('Table number is required*'),
    floorId: Yup.string().required('Environment is required*'),
    isAvailable: Yup.boolean().required('Availability is required*'),
    isStaffTable: Yup.boolean().required('Staff Table status is required*'),
    capacity: Yup.number()
      .typeError('Capacity must be a number')
      .positive('Capacity must be a positive number')
      .nullable()
  });

  if (loading) {
    return <Loading />;
  }

  const initialValues = isEdit
    ? table
    : {
        number: '',
        floorId: '',
        isAvailable: true,
        isStaffTable: false,
        capacity: '',
      };

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            if (isEdit) {
              await updateTable(table.id, values);
            } else {
              await createTable(values);
            }
            navigate('/restaurant');
            setSubmitting(false);
          } catch (err) {
            setErrors({ submit: err?.response?.data });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} className='px-7 py-5'>
            <div className='grid md:grid-cols-2 gap-x-6 gap-y-3'>
              <CustomInput
                type='number'
                name='number'
                label='Table Number'
                value={values.number}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.number && touched.number}
                errorMessage={errors.number}
              />
              <SelectInput
                value={values.floorId}
                label='Environment'
                options={environments.map(env => ({
                  id: env.id,
                  title: env.name
                }))}
                onChange={(e) => setFieldValue('floorId', e.target.value)}
                onBlur={handleBlur}
                showError={errors.floorId && touched.floorId}
                error
                Message={errors.floorId}
              />

              <CustomInput
                type='number'
                name='capacity'
                label='Capacity (Optional)'
                value={values.capacity}
                onChange={handleChange}
                onBlur={handleBlur}
                showError={errors.capacity && touched.capacity}
                errorMessage={errors.capacity}
                placeholder="Enter table capacity"
              />

              <div className='col-span-1'>
                <div className='flex items-center'>
                  <input
                    type='checkbox'
                    name='isAvailable'
                    checked={values.isAvailable}
                    onChange={(e) => setFieldValue('isAvailable', e.target.checked)}
                    onBlur={handleBlur}
                    className='mr-2'
                  />
                  <label htmlFor='isAvailable' className='text-gray-700'>
                    Available
                  </label>
                </div>
                {errors.isAvailable && touched.isAvailable && (
                  <div className='text-error'>{errors.isAvailable}</div>
                )}
              </div>

              <div className='col-span-1'>
                <div className='flex items-center'>
                  <input
                    type='checkbox'
                    name='isStaffTable'
                    checked={values.isStaffTable}
                    onChange={(e) => setFieldValue('isStaffTable', e.target.checked)}
                    onBlur={handleBlur}
                    className='mr-2'
                  />
                  <label htmlFor='isStaffTable' className='text-gray-700'>
                    Is Staff Table
                  </label>
                </div>
                {errors.isStaffTable && touched.isStaffTable && (
                  <div className='text-error'>{errors.isStaffTable}</div>
                )}
              </div>
              {errors.submit && <p className='text-error'>{errors.submit}</p>}
            </div>
            <div className='flex justify-end items-center w-full mt-4 text-sm'>
              <CancelButton onCancel={() => navigate('/restaurant')} />
              <SubmitButton
                isSubmitting={isSubmitting}
                width='w-1/2 md:w-1/6'
                title={`${isEdit ? 'Edit table' : 'Create table'}`}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default TableForm;


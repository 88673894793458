import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';  // Icon for assets
import PageTitle from '../../../../../components/page-title';
import AssetForm from './AssetsForm';

function AddAsset() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faBoxOpen}
            className='text-hazel-green mr-2'
          />
        }
        title='Create a new asset'
      />
      <AssetForm
        isEdit={false}
        asset={{
          name: '',
          value: 0,
          dateAcquired: ''
        }}
      />
    </div>
  );
}

export default AddAsset;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../../../components/loader';
import PageTitle from '../../../../../components/page-title';
import SupplierForm from './SupplierForm';
import { getSupplierById } from '../../../../../api/accounting';

function EditSupplier() {
  const { id } = useParams();
  const [supplier, setSupplier] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSupplier();
  }, []);

  const fetchSupplier = async () => {
    try {
      const response = await getSupplierById(id);
      setSupplier(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <PageTitle
        icon={
          <FontAwesomeIcon
            icon={faBuilding}
            className='text-hazel-green mr-2'
          />
        }
        title='Edit Supplier'
      />
      <SupplierForm
        isEdit
        supplier={{
          id: supplier.id,
          name: supplier.name,
          email: supplier.email,
          phone: supplier.phone,
          address: supplier.address || '',
          representative: supplier.representative || '',
          ubin: supplier.ubin || ''
        }}
      />
    </div>
  );
}

export default EditSupplier;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IconPlus, IconPencil, IconTrash } from '@tabler/icons-react';
import ConfirmMessage from '../../components/alerts/alert-message';
import Loading from '../../components/loader';
import PermissionCheck from '../../utils/PermissionCheck';
import { getBarProducts } from '../../api/barProduct';
import { deleteBarProduct } from '../../api/barProduct';


function BarProductsList() {
  const [barProducts, setBarProducts] = useState([]);
  const [hoveredProduct, setHoveredProduct] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBarProducts = async () => {
      try {
        const response = await getBarProducts();
        setBarProducts(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch bar products:', error);
        setLoading(false);
      }
    };

    fetchBarProducts();
  }, []);


  const handleDelete = async (productId) => {
    try {
      await deleteBarProduct(productId);
      setBarProducts(barProducts.filter(product => product.id !== productId));
      setShowDeleteAlert(false);
      setSelectedProduct('');
    } catch (error) {
      console.error('Failed to delete bar product:', error);
    }
  };


  if (loading) {
    return <Loading />;
  }

  return (
    <div className='p-10'>
      <div className='flex justify-end gap-x-3 mb-8'>
        <PermissionCheck action='edit_rooms'>
          <Link
            to='/barProducts/add-bar-product'
            className='flex justify-center items-center text-white rounded-md bg-sage px-4 py-2.5'
          >
            <IconPlus className='mr-2' size={20} />
            New Bar Product
          </Link>
        </PermissionCheck>
      </div>
      <div className='border border-gray-200 rounded-xl shadow-lg px-3 mt-5'>
        {barProducts.length > 0 ? (
          <div className='grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 my-4'>
            <PermissionCheck action='edit_room_types'>
              <Link
                to={`/bar-products/add-bar-product`}
                className='flex justify-center items-center p-5 bg-seashell rounded-md h-32'
              >
                <IconPlus
                  className='text-bluish-grey font-bold cursor-pointer'
                  size={24}
                />
              </Link>
            </PermissionCheck>
            {barProducts.map((barProduct) => (
              <div
                key={barProduct.id}
                className={`flex ${
                  hoveredProduct === barProduct.id
                    ? 'justify-center'
                    : 'justify-between'
                } items-center rounded-md bg-seashell w-full h-32`}
                onMouseEnter={() => setHoveredProduct(barProduct.id)}
                onMouseLeave={() => setHoveredProduct(null)}
              >
                {hoveredProduct === barProduct.id ? (
                  <div className='flex justify-center items-center bg-white py-2.5 px-5 gap-x-5 rounded'>
                    <PermissionCheck action='edit_rooms'>
                      <Link to={`/bar-products/${barProduct.id}`}>
                        <IconPencil className='text-bluish-grey' size={18} />
                      </Link>
                    </PermissionCheck>
                    <PermissionCheck action='delete_rooms'>
                      <IconTrash
                        className='text-bluish-grey cursor-pointer'
                        size={18}
                        onClick={() => {
                          setSelectedProduct(barProduct.id);
                          setShowDeleteAlert(true);
                        }}
                      />
                    </PermissionCheck>
                  </div>
                ) : (
                  <>
                    <img
                      src={`http://localhost:3001${barProduct.imageUrl}`}
                      alt='bar-product'
                      className='w-36 h-32 rounded-md'
                    />
                    <p className='text-bluish-grey font-medium w-full text-center'>
                      {barProduct.name}
                    </p>
                  </>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className='text-sage font-bold py-10 px-8'>
            There is no data to show!
          </p>
        )}
      </div>
      {showDeleteAlert && (
        <ConfirmMessage
          title='Are you sure you want to delete this product?'
          text='Once you delete this product, it cannot be undone'
          onCancel={() => setShowDeleteAlert(!showDeleteAlert)}
          //   onConfirm={() => deleteBarProduct(selectedProduct)}
          onConfirm={() => handleDelete(selectedProduct)}

        />
      )}
    </div>
  );
}

export default BarProductsList;

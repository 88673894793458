// PaymentsList.js
import { Search, Filter } from 'lucide-react';

import { Button } from '../../../../../components/shadCN/Button.tsx';

const payments = [
  { id: 'PAY-001', supplier: 'Supplier A', date: '2024-03-15', amount: '€1,200.00', status: 'Completed' },
  { id: 'PAY-002', supplier: 'Supplier B', date: '2024-03-14', amount: '€950.00', status: 'Pending' },
  { id: 'PAY-003', supplier: 'Supplier C', date: '2024-03-13', amount: '€3,500.00', status: 'Failed' },
  { id: 'PAY-004', supplier: 'Supplier D', date: '2024-03-12', amount: '€1,100.00', status: 'Completed' },
];

export default function PaymentsList() {
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-4">
          <div className="relative">
            <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-bluish-grey" />
            <input
              type="text"
              placeholder="Search payments..."
              className="pl-10 pr-4 py-2 border border-mercury rounded-lg text-sm focus:outline-none focus:border-hazel-green"
            />
          </div>
          <Button variant="outline" className="border-grey-goose text-bluish-grey hover:bg-tealish-blue">
            <Filter className="h-4 w-4 mr-2" />
            Filter
          </Button>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm border border-mercury">
        <div className="grid grid-cols-5 gap-4 p-5 border-b border-mercury bg-tealish-blue">
          <div className="text-sm font-medium text-bluish-grey">PAYMENT #</div>
          <div className="text-sm font-medium text-bluish-grey">SUPPLIER</div>
          <div className="text-sm font-medium text-bluish-grey">DATE</div>
          <div className="text-sm font-medium text-bluish-grey">AMOUNT</div>
          <div className="text-sm font-medium text-bluish-grey">STATUS</div>
        </div>

        <div className="divide-y divide-mercury">
          {payments.map((payment) => (
            <div
              key={payment.id}
              className="grid grid-cols-5 gap-4 p-5 hover:bg-seashell transition-colors duration-200"
            >
              <div className="text-hazel-green font-medium">{payment.id}</div>
              <div className="text-bluish-grey">{payment.supplier}</div>
              <div className="text-bluish-grey">{payment.date}</div>
              <div className="text-bluish-grey font-medium">{payment.amount}</div>
              <div>
                <span
                  className={`px-3 py-1 rounded-full text-sm font-medium ${
                    payment.status === 'Completed'
                      ? 'bg-[#EDF7ED] text-[#1E4620]'
                      : payment.status === 'Pending'
                      ? 'bg-[#FFF4E5] text-[#663C00]'
                      : 'bg-[#FDECEA] text-[#611A15]'
                  }`}
                >
                  {payment.status}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import EnvironmentCard from './EnvironmentCard'; // Adjust the path according to your project structure
import Loading from '../../components/loader';
import { getEnvironments } from '../../api/restaurant';
import { ThemeProvider } from '@material-tailwind/react';


const EnvironmentsPage = () => {
  const [environments, setEnvironments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEnvironments = async () => {
      try {
        const response = await getEnvironments();
        setEnvironments(response.data); // Make sure this is correct
      } catch (err) {
        console.error('Failed to fetch environments:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchEnvironments();
  }, []);

  if (loading) {
    return <Loading />;
  }

  const handleDelete = (id) => {
    setEnvironments((prevEnvironments) =>
      prevEnvironments.filter((environment) => environment.id !== id)
    );
  };

  if (!environments || environments.length === 0) {
    return <p>No environments available.</p>; // Add a message when no environments are available
  }

  return (

    <div className="container mx-auto p-6">
      <h1 className="text-xl text-gray-600 font-bold mb-8">Environments</h1>
      <div className="flex flex-wrap justify-center gap-6">
        {Array.isArray(environments) && environments.map((environment) => (
          <EnvironmentCard 
                key={environment.id}
                environment={environment}
                onDelete={handleDelete}
          />
        ))}
      </div>
    </div>

  );
};

export default EnvironmentsPage;

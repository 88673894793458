
import api from './api';

export const createEmployee = (data) => api.post('/api/employees', data);

// Updated getEmployees to accept search, page, and pageSize parameters
export const getEmployees = (jobTitle = '', search = '', page = 1, pageSize = 10) => 
  api.get('/api/employees', {
    params: {jobTitle, search, page, pageSize }
  });

export const getEmployeeById = (id) => api.get(`/api/employees/${id}`);

export const updateEmployee = (id, data) => api.put(`/api/employees/${id}`, data);

export const deleteEmployee = (id) => api.delete(`/api/employees/${id}`);

export const updateEmployeeStatus = (id, isActive) => 
  api.post(`/api/employees/${id}/status`, { isActive });

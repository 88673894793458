import React from 'react';

function BarMenu({ barProducts }) {
  return (
    <div className='m-auto px-6 py-12'>
      <h1 className='italic text-hazel-green font-bold text-4xl text-center'>
        Bar Menu
      </h1>

      {/* Display bar products */}
      <div className='grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4'>
        {barProducts.map((product) => (
          <div
            key={product.id}
            className='border shadow-lg rounded-lg hover:scale-105 duration-300'
          >
            <img
              src={
                product.imageUrl
                  ? `http://localhost:3001${product.imageUrl}`
                  : '/images/default-placeholder.png'
              }
              alt={product.name || 'Bar Product'}
              className='w-full h-[200px] object-cover rounded-t-lg'
            />
            <div className='flex flex-col px-4 py-4 gap-2'>
              <p className='font-bold text-xl'>{product.name}</p>
              <p className='text-gray-600 text-sm'>{product.description}</p>
              <p className='text-gray-600 text-sm'><strong>Type:</strong> {product.type}</p>
              <p className='text-gray-600 text-sm'><strong>Available:</strong> {product.isAvailable ? 'Yes' : 'No'}</p>
              <p className='font-bold'>
                <span className='bg-hazel-green text-white p-1 px-4 rounded-full'>
                  {product.price}$
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BarMenu;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
// import  EnvironmentForm  from './EnvironmentForm';
import UserInvitationForm from './UserInvitationForm';
import GroupCreationForm from './GroupCreationForm';
import {
    IconUsersGroup
} from '@tabler/icons-react';


function AddGroupCreationForm() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={
        //   <FontAwesomeIcon
        //     icon={faBuilding}
        //     className='text-hazel-green mr-2'
        //   />
        <IconUsersGroup size={22} /> }
        title='Create a Chat Group'
      />
      <GroupCreationForm
        // isEdit={false}
        // environment={{
        //   name: '',
        //   restaurantId: ''
        // }}
      />
    </div>
  );
}

export default AddGroupCreationForm;

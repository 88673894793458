import React, {useState} from "react";

import Navigation from "../../../../components/accountingDashboardComponents/Tabs.tsx";
import FinancialCard from "../../../../components/accountingDashboardComponents/FinancialCard.tsx";
import CashFlowChart from "../../../../components/accountingDashboardComponents/CashFlowChart.tsx";
import ProjectsCard from "../../../../components/accountingDashboardComponents/ProjectsCard.tsx";
import IncomeExpenseChart from "../../../../components/accountingDashboardComponents/IncomeExpenseCard.tsx";
import TopExpenses from "../../../../components/accountingDashboardComponents/TopExpenses.tsx";
import BankCard from "../../../../components/accountingDashboardComponents/BankCard.tsx";

import { HelpCircle, Plus, ChevronDown } from 'lucide-react';

export default function AccountingDashboard() {
 
const [fiscalYear, setFiscalYear] = useState('This Fiscal Year');

  return (
    <div className="min-h-screen bg-seashell">
      <Navigation />
      
      <main className="container mx-auto px-4 py-8 space-y-6">
        {/* Financial Overview Cards */}
        <div className="grid md:grid-cols-2 gap-6">
          <FinancialCard 
            title="Total Receivables"
            total="€0,00"
            current="€0,00"
            overdue="€0,00"
            subtitle="Total Unpaid Invoices €0,00"
          />
          <FinancialCard 
            title="Total Payables"
            total="€0,00"
            current="€0,00"
            overdue="€0,00"
            subtitle="Total Unpaid Bills €0,00"
          />
        </div>

        {/* Cash Flow Section */}
        <div className="card p-6">
          <div className="flex justify-between items-center mb-8">
            <div className="card-header">
              <h2 className="text-xl">Cash Flow</h2>
              <HelpCircle className="help-icon" />
            </div>
            <button className="dropdown-button">
              {fiscalYear}
              <ChevronDown className="w-4 h-4" />
            </button>
          </div>
          <CashFlowChart />
        </div>

        {/* Income/Expense and Top Expenses */}
        <div className="grid md:grid-cols-2 gap-6">
          <IncomeExpenseChart />
          <TopExpenses />
        </div>

        {/* Projects and Bank Cards */}
        <div className="grid md:grid-cols-2 gap-6">
          <ProjectsCard />
          <BankCard />
        </div>
      </main>
    </div>
  );
}
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Select, MenuItem } from '@mui/material';
import { IconUpload, IconX } from '@tabler/icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import CustomInput from '../../../../components/custom-input';
import { SubmitButton, CancelButton } from '../../../../components/buttons';
import { icons } from '../../../../constants/additional-info-icons';
import { createRoomType, updateRoomType, fileUpload } from '../../../../api/hotelInterface';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { uploadImage } from '../../../../api/api';

import {
  faWifi,
  faUtensils,
  faBed,
  faWaterLadder
} from '@fortawesome/free-solid-svg-icons';

// function RoomTypeForm({ isEdit, roomTypeData }) {
//   const navigate = useNavigate();
//   const fileInputRef = useRef(null);
//   const [uploadedFile, setUploadedFile] = useState(null);
//   const [errorMessage, setErrorMessage] = useState(null);
//   // const [uploadedFiles, setUploadedFiles] = useState([]);

//   // const handleMultipleChange = (event) => {
//   //   const files = Array.from(event.target.files);
//   //   setUploadedFiles(files.map((file) => URL.createObjectURL(file)));
//   // };

//   // const handleDeleteImage = (index) => {
//   //   const updatedFiles = [...uploadedFiles];
//   //   updatedFiles.splice(index, 1);
//   //   setUploadedFiles(updatedFiles);
//   // };

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     setUploadedFile(file);
//   };

//   Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
//     return this.test('unique', message, function (list) {
//       return list.length === new Set(list.map(mapper)).size;
//     });
//   });

//   const theme = createTheme({
//     palette: {
//       primary: {
//         main: '#90a68c'
//       }
//     }
//   });

//   return (
//     <ThemeProvider theme={theme}>
//       <div className='border border-gray-200 rounded-xl shadow-lg mt-5 py-5'>
//         <Formik
//           initialValues={{
//             title: isEdit ? roomTypeData.title : '',
//             price: isEdit ? roomTypeData.price : null,
//             description: isEdit ? roomTypeData.description : '',
//             imagePath: isEdit ? roomTypeData.imagePath : null,
//             capacity: isEdit ? roomTypeData.capacity : 2,
//             additionalInfo: isEdit
//               ? JSON.parse(roomTypeData.additionalInfo)
//               : [{ icon: '', title: '', value: '' }],
//             inventory: isEdit
//               ? JSON.parse(roomTypeData.inventory)
//               : [{ item: '', quantity: 1, goodCondition: true }]
//           }}
//           validationSchema={Yup.object().shape({
//             title: Yup.string().min(3).required('Title is required*'),
//             price: Yup.number().required('Price is required*'),
//             description: Yup.string()
//               .min(4)
//               .required('Description is required*'),
//             imagePath: Yup.string().nullable(),
//             capacity: Yup.string().min(1).required('Capacity is required*'),
//             additionalInfo: Yup.array()
//               .of(
//                 Yup.object().shape({
//                   title: Yup.string(),
//                   value: Yup.string(),
//                   icon: Yup.string()
//                 })
//               )
//               .unique('Option already used', (a) => a.title),
//             inventory: Yup.array()
//               .of(
//                 Yup.object().shape({
//                   item: Yup.string(),
//                   quantity: Yup.number(),
//                   goodCondition: Yup.boolean()
//                 })
//               )
//               .unique('Option already used', (a) => a.item)
//           })}
//           onSubmit={async (values, { setErrors, setSubmitting }) => {
//             try {
//               const formData = new FormData();
//               if (uploadedFile) {
//                 formData.append('image', uploadedFile);

//                 const response = await fileUpload(formData);
//                 values.imagePath = response.data;
//               }
//               const dataToSend = {
//                 ...values,
//                 additionalInfo: JSON.stringify(values.additionalInfo),
//                 inventory: JSON.stringify(values.inventory)
//               };
//               if (isEdit) {
//                 await updateRoomType(roomTypeData.id, dataToSend);
//               } else {
//                 await createRoomType(dataToSend);
//                 navigate('/hotel-interface/');
//               }
//               setSubmitting(false);
//             } catch (err) {
//               setErrors({
//                 submit: err?.response?.data
//               });
//               setSubmitting(false);
//             }
//           }}
//         >
//           {({
//             errors,
//             values,
//             touched,
//             isSubmitting,
//             handleBlur,
//             handleChange,
//             handleSubmit,
//             setFieldValue
//           }) => (
//             <form onSubmit={handleSubmit}>
//               <h3 className='text-bluish-grey font-medium uppercase px-7'>
//                 General info
//               </h3>
//               <div className='grid grid-cols-2 gap-x-6 gap-y-3 mt-3 px-7'>
//                 <CustomInput
//                   type='text'
//                   name='title'
//                   label='Title'
//                   value={values.title}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   showError={errors.title && touched.title}
//                   errorMessage={errors.title}
//                 />
//                 <CustomInput
//                   type='number'
//                   name='price'
//                   label='Price per night'
//                   value={values.price}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   showError={errors.price && touched.price}
//                   errorMessage={errors.price}
//                 />
//               </div>
//               <div className='px-7'>
//                 <CustomInput
//                   type='text'
//                   name='description'
//                   label='Description'
//                   value={values.description}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   multiline={true}
//                   showError={errors.description && touched.description}
//                   errorMessage={errors.description}
//                 />
//                 <p className='text-bluish-grey my-3'>Image</p>
//                 <div>
//                   <input
//                     type='file'
//                     ref={fileInputRef}
//                     style={{ display: 'none' }}
//                     onChange={handleFileChange}
//                     // onChange={handleMultipleChange}
//                     // multiple
//                   />
//                   <div className='flex flex-wrap gap-x-4'>
//                     <div
//                       className='flex items-center justify-center bg-mercury w-32 h-32 rounded-md cursor-pointer'
//                       onClick={() => fileInputRef.current.click()}
//                     >
//                       <IconUpload className='text-hazel-green' size={30} />
//                     </div>
//                     {(uploadedFile || values.imagePath) && (
//                       <div className='relative mr-2 mb-2'>
//                         <img
//                           src={
//                             uploadedFile
//                               ? URL.createObjectURL(uploadedFile)
//                               : `http://3.79.80.215/${values.imagePath}`
//                           }
//                           alt='room'
//                           className='w-32 h-32 rounded-md'
//                         />
//                         <button
//                           type='button'
//                           className='absolute top-0 right-0 border-sage rounded-tr-md rounded-bl-md bg-white p-1'
//                           onClick={() => setUploadedFile(null)}
//                           // onClick={() => handleDeleteImage(index)}
//                         >
//                           <div className='absolute top-0 right-0 border border-sage rounded-tr-md rounded-bl-md bg-white p-1 flex items-center justify-center'>
//                             <div className='rounded-full w-4 h-4 border border-sage flex items-center justify-center text-sage'>
//                               <IconX />
//                             </div>
//                           </div>
//                         </button>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               </div>
//               <div className='py-3 border-b boder-hazel-green' />
//               <div className='text-center px-7 mt-5'>
//                 <h3 className='text-bluish-grey font-medium uppercase text-left'>
//                   Additional info
//                 </h3>
//                 <div className='grid grid-cols-11 gap-x-6 mt-4'>
//                   <div className='col-span-1 flex justify-center items-center border border-silver rounded mb-3'>
//                     <FontAwesomeIcon
//                       icon={faUsers}
//                       className='text-hazel-green mr-2'
//                     />
//                   </div>
//                   <div className='col-span-4'>
//                     <CustomInput
//                       type='text'
//                       name='title'
//                       label='Capacity'
//                       disabled={true}
//                     />
//                   </div>
//                   <div className='col-span-5'>
//                     <CustomInput
//                       type='number'
//                       name='capacity'
//                       label='Value'
//                       value={values.capacity}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       showError={errors.capacity && touched.capacity}
//                       errorMessage={errors.capacity}
//                     />
//                   </div>
//                 </div>
//               </div>
              // <FieldArray name='additionalInfo'>
              //   {({ push, remove }) => (
              //     <div className='text-center px-7 mt-5'>
              //       {values.additionalInfo.map((option, index) => {
              //         return (
              //           <div
              //             key={index}
              //             className='grid grid-cols-11 gap-x-6 mt-4'
              //           >
              //             <div className='col-span-1'>
              //               {' '}
              //               <Select
              //                 className='w-full mb-3'
              //                 value={option.icon}
              //                 onChange={async (event) => {
              //                   setFieldValue(
              //                     `additionalInfo[${index}].icon`,
              //                     event.target.value
              //                   );
              //                 }}
              //                 displayEmpty
              //                 inputProps={{ 'aria-label': 'Without label' }}
              //                 sx={{
              //                   borderColor: 'primary.main'
              //                 }}
              //               >
              //                 <MenuItem value=''>Icon</MenuItem>
              //                 {icons.map((icon) => (
              //                   <MenuItem key={icon.title} value={icon.title}>
              //                     {icon.icon}
              //                   </MenuItem>
              //                 ))}
              //               </Select>
              //             </div>
              //             <div className='col-span-4'>
              //               <CustomInput
              //                 type='text'
              //                 name={`additionalInfo[${index}].title`}
              //                 label='Title'
              //                 value={option.title}
              //                 onChange={async (event) => {
              //                   setFieldValue(
              //                     `additionalInfo[${index}].title`,
              //                     event.target.value
              //                   );
              //                 }}
              //                 onBlur={handleBlur}
              //               />
              //             </div>
              //             <div className='col-span-5'>
              //               <CustomInput
              //                 type='text'
              //                 name={`additionalInfo[${index}].value`}
              //                 label='Value'
              //                 value={option.value}
              //                 onChange={async (event) => {
              //                   setFieldValue(
              //                     `additionalInfo[${index}].value`,
              //                     event.target.value
              //                   );
              //                 }}
              //                 onBlur={handleBlur}
              //               />
              //             </div>
              //             <button
              //               type='button'
              //               className='col-span-1 flex justify-center items-center bg-dawn-pink text-salmon-pink rounded mb-3'
              //               onClick={() => remove(index)}
              //             >
              //               x
              //             </button>
              //           </div>
              //         );
              //       })}
              //       <button
              //         type='button'
              //         className='border-dashed border-2 border-sage w-1/2 text-sage rounded-md mt-4 py-2'
              //         onClick={() =>
              //           push({
              //             icon: '',
              //             title: '',
              //             value: ''
              //           })
              //         }
              //       >
              //         Add more
              //       </button>
              //       {errors.additionalInfo && (
              //         <p className='text-sm text-error'>
              //           {errors.additionalInfo}
              //         </p>
              //       )}
              //     </div>
              //   )}
              // </FieldArray>
//               <div className='py-3 border-b boder-hazel-green' />
//               <div className='text-center px-7 mt-5'>
//                 <h3 className='text-bluish-grey font-medium uppercase text-left'>
//                   Inventory
//                 </h3>
//               </div>
//               <FieldArray name='inventory'>
//                 {({ push, remove }) => (
//                   <div className='text-center px-7 mt-5'>
//                     {values.inventory.map((option, index) => {
//                       return (
//                         <div
//                           key={index}
//                           className='grid grid-cols-11 gap-x-6 mt-4'
//                         >
//                           <div className='col-span-5'>
//                             <CustomInput
//                               type='text'
//                               name={`inventory[${index}].item`}
//                               label='Item'
//                               value={option.item}
//                               onChange={async (event) => {
//                                 setFieldValue(
//                                   `inventory[${index}].item`,
//                                   event.target.value
//                                 );
//                               }}
//                               onBlur={handleBlur}
//                             />
//                           </div>
//                           <div className='col-span-5'>
//                             <CustomInput
//                               type='number'
//                               name={`inventory[${index}].quantity`}
//                               label='Quantity'
//                               value={option.quantity}
//                               onChange={async (event) => {
//                                 setFieldValue(
//                                   `inventory[${index}].quantity`,
//                                   event.target.value
//                                 );
//                               }}
//                               onBlur={handleBlur}
//                             />
//                           </div>
//                           <button
//                             type='button'
//                             className='col-span-1 flex justify-center items-center bg-dawn-pink text-salmon-pink rounded mb-3'
//                             onClick={() => remove(index)}
//                           >
//                             x
//                           </button>
//                         </div>
//                       );
//                     })}
//                     <button
//                       type='button'
//                       className='border-dashed border-2 border-sage w-1/2 text-sage rounded-md mt-4 py-2'
//                       onClick={() =>
//                         push({
//                           item: '',
//                           quantity: 1,
//                           goodCondition: true
//                         })
//                       }
//                     >
//                       Add more
//                     </button>
//                     {errors.inventory && (
//                       <p className='text-sm text-error'>{errors.inventory}</p>
//                     )}
//                   </div>
//                 )}
//               </FieldArray>
//               {/* {errors.submit && (
//                 <p className='text-error px-7'>{errors.submit}</p>
//               )} */}
//               {errors.submit && (
//                 <p className='text-error px-7'>
//                   {typeof errors.submit === 'object' ? errors.submit.error : errors.submit}
//                 </p>
//               )}
//               {/* {errorMessage && (
//                 <p className='text-error px-7'>{errorMessage}</p>
//               )} */}

//               {errorMessage && (
//                 <p className='text-error px-7'>
//                   {typeof errorMessage === 'object' ? errorMessage.error : errorMessage}
//                 </p>
//               )}
//               <div className='flex justify-end items-center w-full mt-7 text-sm px-7'>
//                 <CancelButton onCancel={() => navigate('/rooms')} />
//                 <SubmitButton
//                   isSubmitting={isSubmitting}
//                   width='w-1/5'
//                   title='Save'
//                 />
//               </div>
//             </form>
//           )}
//         </Formik>
//       </div>
//     </ThemeProvider>
//   );
// }

// export default RoomTypeForm;




function RoomTypeForm({ isEdit, roomTypeData }) {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
    return this.test('unique', message, function (list) {
      return list.length === new Set(list.map(mapper)).size;
    });
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: '#90a68c'
      }
    }
  });


  const parseJSON = (data) => {
    try {
      return JSON.parse(data);
    } catch (e) {
      console.error('Error parsing JSON:', e);
      return []; 
    }
  };

  const arrayToString = (array) => {
    return array.map(item => {
      const entries = Object.entries(item).map(([key, value]) => `${key}:${value}`);
      return entries.join('|');
    }).join('||');
  };

  const parseString = (str) => {
    return str.split('||').map(item => {
      const entries = item.split('|').map(pair => pair.split(':'));
      return Object.fromEntries(entries);
    });
  };



  return (
    <ThemeProvider theme={theme}>
      <div className='border border-gray-200 rounded-xl shadow-lg mt-5 py-5'>
        <Formik
          initialValues={{
            title: isEdit ? roomTypeData.title : '',
            price: isEdit ? roomTypeData.price : null,
            description: isEdit ? roomTypeData.description : '',
            imagePath: isEdit ? roomTypeData.imagePath : null,
            capacity: isEdit ? roomTypeData.capacity : 2,
            // additionalInfo: isEdit
            //   ? JSON.parse(roomTypeData.additionalInfo)
            //   : [{ icon: '', title: '', value: '' }],
            // inventory: isEdit
            //   ? JSON.parse(roomTypeData.inventory)
            //   : [{ item: '', quantity: 1, goodCondition: true }]

            additionalInfo: isEdit ? parseString(roomTypeData.additionalInfo) : [{ icon: '', title: '', value: '' }],
            inventory: isEdit ? parseString(roomTypeData.inventory) : [{ item: '', quantity: 1, goodCondition: true }]
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().min(3).required('Title is required*'),
            price: Yup.number().required('Price is required*'),
            description: Yup.string()
              .min(4)
              .required('Description is required*'),
            imagePath: Yup.string().nullable(),
            capacity: Yup.string().min(1).required('Capacity is required*'),
          //   additionalInfo: Yup.array()
          //     .of(
          //       Yup.object().shape({
          //         title: Yup.string(),
          //         value: Yup.string(),
          //         icon: Yup.string()
          //       })
          //     )
          //     .unique('Option already used', (a) => a.title),
          //   inventory: Yup.array()
          //     .of(
          //       Yup.object().shape({
          //         item: Yup.string(),
          //         quantity: Yup.number(),
          //         goodCondition: Yup.boolean()
          //       })
          //     )
          //     .unique('Option already used', (a) => a.item)
          // })}
          additionalInfo: Yup.array()
              .of(
                Yup.object().shape({
                  title: Yup.string(),
                  value: Yup.string(),
                  icon: Yup.string()
                })
              )
              .unique('Option already used', (a) => a.title),
            inventory: Yup.array()
              .of(
                Yup.object().shape({
                  item: Yup.string(),
                  quantity: Yup.number(),
                  goodCondition: Yup.boolean()
                })
              )
              .unique('Option already used', (a) => a.item)
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              let imagePath = values.imagePath;
              if (uploadedFile) {
                const formData = new FormData();
                formData.append('file', uploadedFile);
                const uploadResponse = await uploadImage(formData);
                imagePath = uploadResponse.data.url; // Assuming response contains the URL
              }

              // const dataToSend = {
              //   ...values,
              //   imagePath,
              //   additionalInfo: JSON.stringify(values.additionalInfo),
              //   // inventory: JSON.stringify(values.inventory)
              //   inventory: JSON.stringify(values.inventory.map(item => ({
              //     ...item,
              //     goodCondition: item.goodCondition.toString() 
              //   })))
              // };
              const dataToSend = {
                ...values,
                imagePath,
                additionalInfo: arrayToString(values.additionalInfo),
                inventory: arrayToString(values.inventory.map(item => ({
                  ...item,
                  goodCondition: item.goodCondition.toString()
                })))
              };

              if (isEdit) {
                await updateRoomType(roomTypeData.id, dataToSend);
              } else {
                await createRoomType(dataToSend);
                navigate('/hotel-interface/');
              }
              setSubmitting(false);
            } catch (err) {
              setErrors({
                submit: err?.response?.data?.message || 'An error occurred'
              });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            values,
            touched,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <h3 className='text-bluish-grey font-medium uppercase px-7'>
                General info
              </h3>
              <div className='grid grid-cols-2 gap-x-6 gap-y-3 mt-3 px-7'>
                <CustomInput
                  type='text'
                  name='title'
                  label='Title'
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.title && touched.title}
                  errorMessage={errors.title}
                />
                <CustomInput
                  type='number'
                  name='price'
                  label='Price per night'
                  value={values.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showError={errors.price && touched.price}
                  errorMessage={errors.price}
                />
              </div>
              <div className='px-7'>
                <CustomInput
                  type='text'
                  name='description'
                  label='Description'
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  multiline={true}
                  showError={errors.description && touched.description}
                  errorMessage={errors.description}
                />
                <p className='text-bluish-grey my-3'>Image</p>
                <div>
                  <input
                    type='file'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <div className='flex flex-wrap gap-x-4'>
                    <div
                      className='flex items-center justify-center bg-mercury w-32 h-32 rounded-md cursor-pointer'
                      onClick={() => fileInputRef.current.click()}
                    >
                      <IconUpload className='text-hazel-green' size={30} />
                    </div>
                    {(uploadedFile || values.imagePath) && (
                      <div className='relative mr-2 mb-2'>
                        <img
                          src={
                            uploadedFile
                              ? URL.createObjectURL(uploadedFile)
                              : `http://localhost:3001${values.imagePath}`
                          }
                          alt='room'
                          className='w-32 h-32 rounded-md'
                        />
                        <button
                          type='button'
                          className='absolute top-0 right-0 border-sage rounded-tr-md rounded-bl-md bg-white p-1'
                          onClick={() => setUploadedFile(null)}
                        >
                          <div className='absolute top-0 right-0 border border-sage rounded-tr-md rounded-bl-md bg-white p-1 flex items-center justify-center'>
                            <div className='rounded-full w-4 h-4 border border-sage flex items-center justify-center text-sage'>
                              <IconX />
                            </div>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='py-3 border-b boder-hazel-green' />
              <div className='text-center px-7 mt-5'>
                <h3 className='text-bluish-grey font-medium uppercase text-left'>
                  Additional info
                </h3>
                <div className='grid grid-cols-11 gap-x-6 mt-4'>
                  <div className='col-span-1 flex justify-center items-center border border-silver rounded mb-3'>
                    <FontAwesomeIcon
                      icon={faUsers}
                      className='text-hazel-green mr-2'
                    />
                  </div>
                  <div className='col-span-4'>
                    <CustomInput
                      type='text'
                      name='title'
                      label='Capacity'
                      disabled={true}
                    />
                  </div>
                  <div className='col-span-5'>
                    <CustomInput
                      type='number'
                      name='capacity'
                      label='Value'
                      value={values.capacity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      showError={errors.capacity && touched.capacity}
                      errorMessage={errors.capacity}
                    />
                  </div>
                </div>
              </div>
              <FieldArray name='additionalInfo'>
                {({ push, remove }) => (
                  <div className='text-center px-7 mt-5'>
                    {values.additionalInfo.map((option, index) => {
                      return (
                        <div
                          key={index}
                          className='grid grid-cols-11 gap-x-6 mt-4'
                        >
                          <div className='col-span-1'>
                            {' '}
                            <Select
                              className='w-full mb-3'
                              value={option.icon}
                              onChange={async (event) => {
                                setFieldValue(
                                  `additionalInfo[${index}].icon`,
                                  event.target.value
                                );
                              }}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              sx={{
                                borderColor: 'primary.main'
                              }}
                            >
                              <MenuItem value=''>Icon</MenuItem>
                              {icons.map((icon) => (
                                <MenuItem key={icon.title} value={icon.title}>
                                  {icon.icon}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                          <div className='col-span-4'>
                            <CustomInput
                              type='text'
                              name={`additionalInfo[${index}].title`}
                              label='Title'
                              value={option.title}
                              onChange={async (event) => {
                                setFieldValue(
                                  `additionalInfo[${index}].title`,
                                  event.target.value
                                );
                              }}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className='col-span-5'>
                            <CustomInput
                              type='text'
                              name={`additionalInfo[${index}].value`}
                              label='Value'
                              value={option.value}
                              onChange={async (event) => {
                                setFieldValue(
                                  `additionalInfo[${index}].value`,
                                  event.target.value
                                );
                              }}
                              onBlur={handleBlur}
                            />
                          </div>
                          <button
                            type='button'
                            className='col-span-1 flex justify-center items-center bg-dawn-pink text-salmon-pink rounded mb-3'
                            onClick={() => remove(index)}
                          >
                            x
                          </button>
                        </div>
                      );
                    })}
                    <button
                      type='button'
                      className='border-dashed border-2 border-sage w-1/2 text-sage rounded-md mt-4 py-2'
                      onClick={() =>
                        push({
                          icon: '',
                          title: '',
                          value: ''
                        })
                      }
                    >
                      Add more
                    </button>
                    {errors.additionalInfo && (
                      <p className='text-sm text-error'>
                        {errors.additionalInfo}
                      </p>
                    )}
                  </div>
                )}
              </FieldArray>
              <div className='py-3 border-b boder-hazel-green' />
              <FieldArray name='inventory'>
                {({ push, remove }) => (
                  <div className='text-center px-7 mt-5'>
                    {values.inventory.map((item, index) => (
                      <div key={index} className='grid grid-cols-11 gap-x-6 mt-4'>
                        <div className='col-span-4'>
                          <CustomInput
                            type='text'
                            name={`inventory.${index}.item`}
                            label='Item'
                            value={item.item}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showError={
                              errors.inventory &&
                              errors.inventory[index] &&
                              errors.inventory[index].item &&
                              touched.inventory &&
                              touched.inventory[index] &&
                              touched.inventory[index].item
                            }
                            errorMessage={
                              errors.inventory &&
                              errors.inventory[index] &&
                              errors.inventory[index].item
                            }
                          />
                        </div>
                        <div className='col-span-3'>
                          <CustomInput
                            type='number'
                            name={`inventory.${index}.quantity`}
                            label='Quantity'
                            value={item.quantity}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showError={
                              errors.inventory &&
                              errors.inventory[index] &&
                              errors.inventory[index].quantity &&
                              touched.inventory &&
                              touched.inventory[index] &&
                              touched.inventory[index].quantity
                            }
                            errorMessage={
                              errors.inventory &&
                              errors.inventory[index] &&
                              errors.inventory[index].quantity
                            }
                          />
                        </div>
                        {/* <div className='col-span-3'>
                          <CustomInput
                            type='checkbox'
                            name={`inventory.${index}.goodCondition`}
                            label='Good Condition'
                            checked={item.goodCondition}
                            onChange={() => setFieldValue(`inventory.${index}.goodCondition`, !item.goodCondition)}
                            onBlur={handleBlur}
                            showError={
                              errors.inventory &&
                              errors.inventory[index] &&
                              errors.inventory[index].goodCondition &&
                              touched.inventory &&
                              touched.inventory[index] &&
                              touched.inventory[index].goodCondition
                            }
                            errorMessage={
                              errors.inventory &&
                              errors.inventory[index] &&
                              errors.inventory[index].goodCondition
                            }
                          />
                        </div> */}
                         <div className='col-span-3 flex items-center space-x-2'>
                        <input
                          type='checkbox'
                          name={`inventory.${index}.goodCondition`}
                          checked={item.goodCondition}
                          onChange={() => setFieldValue(`inventory.${index}.goodCondition`, !item.goodCondition)}
                          onBlur={handleBlur}
                          className='form-checkbox h-5 w-5 text-blue-500'
                        />
                        <label
                          htmlFor={`inventory.${index}.goodCondition`}
                          className='text-sm text-gray-700'
                        >
                          Good Condition
                        </label>
                        {errors.inventory && errors.inventory[index] && errors.inventory[index].goodCondition &&
                          touched.inventory && touched.inventory[index] && touched.inventory[index].goodCondition && (
                            <span className='text-red-500 text-xs'>{errors.inventory[index].goodCondition}</span>
                        )}
                      </div>
                        <div className='col-span-1 flex justify-center items-center'>
                          <button
                            type='button'
                            onClick={() => remove(index)}
                          >
                            <IconX />
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      type='button'
                      className='mt-3 text-sm text-blue-500'
                      onClick={() => push({ item: '', quantity: 1, goodCondition: true })}
                    >
                      Add Inventory
                    </button>
                  </div>
                )}
              </FieldArray>
              {errors.submit && (
                <div className='px-7 text-center mt-5'>
                  <p className='text-error'>{errors.submit}</p>
                </div>
              )}
              <div className='flex justify-end items-center w-full mt-4 text-sm px-7'>
                <CancelButton onCancel={() => navigate('/hotel-interface')} />
                <SubmitButton
                  isSubmitting={isSubmitting}
                  width='w-1/2 md:w-1/6'
                  title={`${isEdit ? 'Edit Room Type' : 'Create Room Type'}`}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </ThemeProvider>
  );
}

export default RoomTypeForm;
import React from 'react';

const CashFlowChart = () => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  return (
    <div className="space-y-8">
      <div className="h-64 flex items-end justify-between border-b border-mercury">
        {months.map((month) => (
          <div key={month} className="flex flex-col items-center w-full">
            <div className="w-full h-full flex items-end justify-center">
              <div className="w-1/2 bg-sage/20 rounded-t h-0 hover:bg-sage/30 transition-all duration-200"></div>
            </div>
            <div className="text-xs font-medium text-bluish-grey mt-2">{month}</div>
            <div className="text-xs text-heather">2024</div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-2 gap-8">
        <div className="p-4 rounded-lg bg-tealish-blue">
          <p className="text-sm text-bluish-grey mb-1">Cash as on 01 Jan 2024</p>
          <p className="stat-value text-sage">€0,00</p>
        </div>
        <div className="p-4 rounded-lg bg-tealish-blue text-right">
          <p className="text-sm text-bluish-grey mb-1">Cash as on 31 Dec 2024</p>
          <p className="stat-value text-sage">€0,00</p>
        </div>
      </div>
    </div>
  );
};

export default CashFlowChart;
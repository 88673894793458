import React, { useState, useEffect } from 'react';
import StatisticCard from './StatisticCard';
import { IconBed, IconTable, IconSwimming, IconSoup, IconGlass, IconHeart, IconBuildingSkyscraper, IconCurrencyDollar } from '@tabler/icons-react'; // Import Tabler icons
import {
  getOrdersCountToday,
  getBarOrdersCountToday,
  getRoomReservationsCountToday,
  getConferenceRoomReservationsCountToday,
  getPoolReservationsCountToday,
  getTableReservationsCountToday,
} from "../../api/inTime";

const InTime = () => {
  const [statistics, setStatistics] = useState({
    reservedRooms: 0,
    reservedTables: 0,
    poolTickets: 0,
    restaurantOrders: 0,
    barOrders: 0,
    weddingVenueReservations: 0,
    conferenceRoomBookings: 0,
    tableReservations: 0,
    earningsToday: "$0", // Placeholder for earnings
  });

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const ordersCountTodayResponse = await getOrdersCountToday();
        const barOrdersCountTodayResponse = await getBarOrdersCountToday();
        const roomReservationsCountTodayResponse = await getRoomReservationsCountToday();
        const conferenceRoomReservationsCountTodayResponse = await getConferenceRoomReservationsCountToday();
        const poolReservationsCountTodayResponse = await getPoolReservationsCountToday();
        const tableReservationsCountTodayResponse = await getTableReservationsCountToday();
        
        // Assuming you have other APIs or static data for the rest of the statistics.
        // Replace these with actual API calls or calculations as needed.
        setStatistics({
          reservedRooms: 20, // Replace with actual data
          reservedTables: 15, // Replace with actual data
          poolTickets: poolReservationsCountTodayResponse.data.count, // Updated to use pool reservations count
          restaurantOrders: ordersCountTodayResponse.data.count,
          barOrders: barOrdersCountTodayResponse.data.count,
          weddingVenueReservations: 5, // Replace with actual data
          conferenceRoomBookings: conferenceRoomReservationsCountTodayResponse.data.count,
          tableReservations: tableReservationsCountTodayResponse.data.count, // Added new stat
          earningsToday: "$5,000", // Replace with actual data
        });
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };

    fetchStatistics();
  }, []);

  return (
    <div className="p-10 min-h-screen">
      <h1 className="text-3xl font-semibold text-sage mb-6">In Time</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <StatisticCard title="Reserved Rooms" value={statistics.reservedRooms} Icon={IconBed} />
        <StatisticCard title="Reserved Tables" value={statistics.reservedTables} Icon={IconTable} />
        <StatisticCard title="Pool Tickets Today" value={statistics.poolTickets} Icon={IconSwimming} />
        <StatisticCard title="Restaurant Orders" value={statistics.restaurantOrders} Icon={IconSoup} />
        <StatisticCard title="Bar Orders" value={statistics.barOrders} Icon={IconGlass} />
        <StatisticCard title="Wedding Venue Reservations" value={statistics.weddingVenueReservations} Icon={IconHeart} />
        <StatisticCard title="Conference Room Bookings" value={statistics.conferenceRoomBookings} Icon={IconBuildingSkyscraper} />

        {/* Special card with custom colors */}
        <div className="col-span-1 lg:col-span-2">
          <StatisticCard
            title="Earnings Today"
            value={statistics.earningsToday}
            Icon={IconCurrencyDollar}
            bgColor="bg-hazel-green"  
            textColor="text-white"    
          />
        </div>
      </div>
    </div>
  );
};

export default InTime;

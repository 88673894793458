import React from 'react';

interface Option {
  id: string;
  title: string;
}

interface SelectInputProps {
  label: string;
  value: string;
  options: Option[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLSelectElement>) => void;
  showError?: boolean;
  errorMessage?: string;
}

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  value,
  options,
  onChange,
  onBlur,
  showError,
  errorMessage,
}) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-hazel-green mb-1">
        {label}
      </label>
      <select
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={`block w-full px-4 py-3 rounded-lg border ${
          showError ? 'border-red-500' : 'border-gray-300'
        } focus:ring-2 focus:ring-hazel-green focus:border-hazel-green transition-colors`}
      >
        <option value="">Select {label}</option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.title}
          </option>
        ))}
      </select>
      {showError && (
        <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
      )}
    </div>
  );
}

export default SelectInput;
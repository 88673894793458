import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../../components/loader';
import SelectInput from '../../../../components/select-input';
import CustomInput from '../../../../components/custom-input';
import DateInput from '../../../../components/date-input';
import { SubmitButton, CancelButton } from '../../../../components/buttons';
import { getAllRooms } from '../../../../api/hotelInterface';
import {
  titleTypes,
  reservationStatus,
  paymentMethods
} from '../../../../constants/entities';

import { getRoomTypes as getRoomTypesApi, getRoom } from '../../../../api/hotelInterface';
import {
  createReservation,
  updateReservation
} from '../../../../api/hotelInterface';
import CustomSelectInput from '../../../../components/custom-select-input';
import { Room } from '../../pages';

function ReservationForm({ isEdit, reservation, roomNumber, roomType }) {

  const location = useLocation();
  const defaultReservationPage = location.pathname === '/hotel-interface/add-new-reservation';

  console.log("Default reservation page: " + defaultReservationPage);


  console.log("ROOM TYPE", roomType);
  console.log("ROOM NUMBER", roomNumber);

  const navigate = useNavigate();
  const [roomTypes, setRoomTypes] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRoomTypes();
  }, []);

  const getRoomTypes = async () => {
    try {
      const response = await getRoomTypesApi();
      setRoomTypes(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const getRooms = (roomType) => {
    const selectedType = roomTypes.find((item) => item.id === roomType);
    setRooms(selectedType.rooms);
  };
  const calculateTotalPrice = (values, setFieldValue) => {
    const discountAmount = values.roomPrice * (values.discount / 100);
    const totalPrice = values.roomPrice - discountAmount + values.deposit;
    setFieldValue('totalPrice', totalPrice);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='border border-gray-200 rounded-xl shadow-lg mt-5 px-7 py-5'>
      <Formik
        initialValues={{

          roomType: defaultReservationPage ? isEdit ? reservation?.rooms[0]?.roomTypeId: '' : roomType?.id,
          roomId: defaultReservationPage ? isEdit ? reservation?.rooms[0]?.id : '' :roomNumber || '',

          // roomType: isEdit ? reservation?.rooms[0]?.roomTypeId : '',

          // roomId: isEdit ? reservation?.rooms[0]?.id : '',

          // title: isEdit ? reservation.title : '',
          // name: isEdit ? reservation.name : '',
          surname: isEdit ? reservation.surname : '',
          fromDate: isEdit ? dayjs(reservation.fromDate) : null,
          toDate: isEdit ? dayjs(reservation.toDate) : null,
          adults: isEdit ? reservation.adults : 0,
          children: isEdit ? reservation.children : 0,
          status: isEdit ? reservation.status : '',
          email: isEdit ? reservation.email : '',
          phoneNumber: isEdit ? reservation.phoneNumber : '',
          discount: isEdit ? reservation.discount : 0,

          // roomPrice: isEdit ? reservation.roomPrice : 0,

          roomPrice: defaultReservationPage ? isEdit ? reservation.roomPrice : 0 :roomType.price,

          deposit: isEdit ? reservation.deposit : 0,

          // totalPrice: isEdit ? reservation.totalPrice : 0,
          totalPrice: defaultReservationPage ? isEdit ? reservation.totalPrice : 0 :roomType.price,

          paymentType: isEdit ? reservation.paymentType : '',
          paidPrice: isEdit ? reservation.paidPrice : 0
        }}
        validationSchema={Yup.object().shape({
          roomType: Yup.string().required('Room Type is required*'),
          roomId: Yup.string().required('Room Number is required*'),
          title: Yup.string().required('Title is required*'),
          name: Yup.string().required('Name is required*'),
          surname: Yup.string().required('Surname is required*'),
          fromDate: Yup.string().required('Checkin date is required*'),
          toDate: Yup.string().required('Checkout date is required*'),
          adults: Yup.number(),
          children: Yup.number(),
          status: Yup.string().required('Status is required*'),
          email: Yup.string()
            .email('Invalid email format')
            .required('Email is required*'),
          phoneNumber: Yup.string().required('Phone Number is required*'),
          discount: Yup.number().nullable(),
          roomPrice: Yup.number(),
          deposit: Yup.number().nullable(),
          totalPrice: Yup.number(),
          paymentType: Yup.string().required('Payment Type is required*'),
          paidPrice: Yup.number().nullable()
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            const rooms = {
              id: values.roomId,
              adults: parseInt(values.adults, 10),
              children: parseInt(values.children, 10)
            };
            values.rooms = [rooms];
            if (isEdit) {
              const { id, roomId, ...updatedValues } = values;
              await updateReservation(reservation.id, updatedValues);
            } else {
              const { roomType, roomId, ...addValues } = values;
              await createReservation(addValues);
              navigate('/rooms');
            }
            setSubmitting(false);
          } catch (err) {
            setErrors({
              submit: err?.response?.data
            });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <div>
            <form onSubmit={handleSubmit}>
              <div className='grid grid-cols-2 gap-x-6 gap-y-3'>
              <SelectInput
                value={values.roomType}
                label='Room Type'
                options={roomTypes}
                onChange={(event) => {
                  const selectedRoomType = roomTypes.find(
                    (item) => item.id === event.target.value
                  );
                  setFieldValue('roomType', event.target.value);
                  setFieldValue('roomPrice', selectedRoomType.price);
                  setFieldValue('totalPrice', selectedRoomType.price);
                  getRooms(event.target.value);
                }}
                onBlur={handleBlur}
                showError={errors.roomType && touched.roomType}
                errorMessage={errors.roomType}
              /> 
                {/* <SelectInput
                  value={values.roomType}
                  label='Room Type'
                  options={roomTypes}
                  onChange={(event) => {
                    setFieldValue('roomType', event.target.value);
                    const selectedRoomType = roomTypes.find(
                      (item) => item.id === event.target.value
                    );
                    setFieldValue('roomPrice', selectedRoomType.price);
                    setFieldValue('totalPrice', selectedRoomType.price);
                    getRooms(event.target.value);
                  }}
                  onBlur={handleBlur}
                  showError={errors.roomType && touched.roomType}
                  errorMessage={errors.roomType}
                /> */}
                
                {defaultReservationPage ? (
                  <SelectInput
                      value={values.roomId}
                      label='Room'
                      options={rooms}
                      onChange={handleChange('roomId')}
                      onBlur={handleBlur}
                      showError={errors.roomId && touched.roomId}
                      errorMessage={errors.roomId}
                  />
            ) : (
                <CustomInput
                  value={values.roomId}
                  label="Room Id"
                  onChange={handleChange("roomId")}
                  onBlur={handleBlur}
                  showError={errors.roomId && touched.roomId}
                  errorMessage={errors.roomId}
                />
            )}

                
              </div>
              <h3 className='text-bluish-grey font-medium uppercase mt-3'>
                Reservation info
              </h3>
              <div className='border-b boder-hazel-green py-1' />
              <div className='grid grid-cols-9 gap-3 mt-4'>
                <div className='col-span-1'>
                  <SelectInput
                    value={values.title}
                    label='Title'
                    options={titleTypes}
                    onChange={handleChange('title')}
                    onBlur={handleBlur}
                    showError={errors.title && touched.title}
                    errorMessage={errors.title}
                    isEntity
                  />
                </div>
                <div className='col-span-2'>
                  <CustomInput
                    type='text'
                    name='name'
                    label='Name'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.name && touched.name}
                    errorMessage={errors.name}
                  />
                </div>
                <div className='col-span-2'>
                  <CustomInput
                    type='text'
                    name='surname'
                    label='Surname'
                    value={values.surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.surname && touched.surname}
                    errorMessage={errors.surname}
                  />
                </div>
                <div className='col-span-2'>
                  <DateInput
                    name='fromDate'
                    label='From Date'
                    value={values.fromDate}
                    onChange={(date) => {
                      setFieldValue('fromDate', date);
                    }}
                    onBlur={handleBlur}
                    showError={errors.fromDate && touched.fromDate}
                    errorMessage={errors.fromDate}
                    disablePast
                  />
                </div>
                <div className='col-span-2'>
                  <DateInput
                    name='toDate'
                    label='To Date'
                    value={values.toDate}
                    onChange={(date) => {
                      setFieldValue('toDate', date);
                    }}
                    onBlur={handleBlur}
                    showError={errors.toDate && touched.toDate}
                    errorMessage={errors.toDate}
                    disablePast
                  />
                </div>
              </div>
              <div className='grid grid-cols-9 gap-3'>
                <div className='col-span-1'>
                  <CustomInput
                    type='number'
                    name='adults'
                    label='Adults'
                    value={values.adults}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.adults && touched.adults}
                    errorMessage={errors.adults}
                  />
                </div>
                <div className='col-span-1'>
                  <CustomInput
                    type='number'
                    name='children'
                    label='Children'
                    value={values.children}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.children && touched.children}
                    errorMessage={errors.children}
                  />
                </div>
                <div className='col-span-3'>
                  <SelectInput
                    value={values.status}
                    label='Rezervation Status'
                    options={reservationStatus}
                    onChange={handleChange('status')}
                    onBlur={handleBlur}
                    showError={errors.status && touched.status}
                    errorMessage={errors.status}
                    isEntity
                  />
                </div>
                {values.status === 'CHECKIN' && (
                  <div className='col-span-2'>
                    <CustomInput
                      type='text'
                      name='cardNumber'
                      label='Card Number'
                      value={values.cardNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      showError={errors.cardNumber && touched.cardNumber}
                      errorMessage={errors.cardNumber}
                    />
                  </div>
                )}
              </div>
              <h3 className='text-bluish-grey font-medium uppercase mt-3'>
                Contact info
              </h3>
              <div className='border-b boder-hazel-green py-1' />
              <div className='grid grid-cols-9 gap-3 mt-4'>
                <div className='col-span-3'>
                  <CustomInput
                    type='text'
                    name='email'
                    label='Email'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.email && touched.email}
                    errorMessage={errors.email}
                  />
                </div>
                <div className='col-span-3'>
                  <CustomInput
                    type='text'
                    name='phoneNumber'
                    label='Phone Number'
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    showError={errors.phoneNumber && touched.phoneNumber}
                    errorMessage={errors.phoneNumber}
                  />
                </div>
              </div>
              <h3 className='text-bluish-grey font-medium uppercase mt-3'>
                Payment info
              </h3>
              <div className='border-b boder-hazel-green py-1' />
              <div className='grid grid-cols-6 gap-3 mt-4'>
                <CustomInput
                  type='number'
                  name='discount'
                  label='Discount'
                  value={values.discount}
                  onChange={(event) => {
                    const newValue = parseFloat(event.target.value);
                    setFieldValue('discount', newValue);
                    calculateTotalPrice(values, setFieldValue);
                  }}
                  onBlur={handleBlur}
                  showError={errors.discount && touched.discount}
                  errorMessage={errors.discount}
                />
                <CustomInput
                  type='number'
                  name='roomPrice'
                  label='Room Price'
                  value={values.roomPrice}
                  disabled
                />
                <CustomInput
                  type='number'
                  name='deposit'
                  label='Deposit'
                  value={values.deposit}
                  onChange={(event) => {
                    const newValue = parseFloat(event.target.value);
                    setFieldValue('deposit', newValue);
                    calculateTotalPrice(values, setFieldValue);
                  }}
                  onBlur={handleBlur}
                  showError={errors.discount && touched.discount}
                  errorMessage={errors.discount}
                />
                <SelectInput
                  value={values.paymentType}
                  label='Payment Type'
                  options={paymentMethods}
                  onChange={handleChange('paymentType')}
                  onBlur={handleBlur}
                  showError={errors.paymentType && touched.paymentType}
                  errorMessage={errors.paymentType}
                  isEntity
                />
                <CustomInput
                  type='number'
                  name='totalPrice'
                  label='Total Price'
                  value={values.totalPrice}
                  disabled
                />
                <CustomInput
                  type='number'
                  name='paidPrice'
                  label='Paid Price'
                  value={values.paidPrice}
                  onChange={handleChange('paidPrice')}
                  onBlur={handleBlur}
                  showError={errors.paidPrice && touched.paidPrice}
                  errorMessage={errors.paidPrice}
                />
              </div>

              {errors.submit && <p className='text-error'>{errors.submit}</p>}
              <div className='flex justify-end items-center w-full mt-7 text-sm'>
                <CancelButton onCancel={() => navigate('/rooms')} />
                <SubmitButton
                  isSubmitting={isSubmitting}
                  width='w-1/5'
                  title='Save'
                />
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default ReservationForm;


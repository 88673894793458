

import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import useAuth from '../../hooks/useAuth';
import { checkUserHasPermission } from '../../helpers/user-helper';
import { getRoomHistory as getRoomHistoryApi } from '../../api/reservations';
import Loading from '../../components/loader';
import { Link } from 'react-router-dom';

function RoomHistory({ roomId }) {
  const { userPermissions, userRole } = useAuth();
  const [loading, setLoading] = useState(true);
  const [reservationsHistory, setReservationsHistory] = useState({});

  useEffect(() => {
    getRoomHistory();
  }, []);

  
  const getRoomHistory = async () => {
    try {
      const response = await getRoomHistoryApi(roomId);
      console.log("Full API Response:", response);
      console.log("Response Data:", JSON.stringify(response.data, null, 2));
  
      // Set reservationsHistory directly with response.data since it's already grouped by status
      setReservationsHistory(response.data);
  
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error fetching room history:", err);
    }
  };
  if (loading) {
    return <Loading />;
  }

  const renderReservation = (item) => (
    <div className='flex justify-between items-center text-hazel-green py-2 border-b'>
      <p>
        {item.name} {item.surname}
      </p>
      <div>{`${dayjs(item.fromDate).format('DD/MM/YYYY')} - ${dayjs(
        item.toDate
      ).format('DD/MM/YYYY')}`}</div>
    </div>
  );

  return (
    <div>
      {Object.keys(reservationsHistory).length > 0 ? (
        <>
          {Object.entries(reservationsHistory).map(([status, reservations]) => (
            <div key={status}>
              <p className='text-bluish-grey capitalize mb-3'>{status.toLowerCase()}</p>
              {reservations.map((item) => (
                <div key={item.id}>
                  {checkUserHasPermission(userRole, userPermissions, ['edit_rooms']) ? (
                    <Link to={`/reservations/${item.id}`}>
                      {renderReservation(item)}
                    </Link>
                  ) : (
                    <div>{renderReservation(item)}</div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </>
      ) : (
        <p className='text-sage font-bold py-6 px-4'>
          There is no data to show!
        </p>
      )}
    </div>
  );
}

export default RoomHistory;

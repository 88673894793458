import { motion } from 'framer-motion';
import { StarIcon as OutlineStarIcon } from '@heroicons/react/24/outline';
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid';

export default function ReportList({ reports, category, toggleFavorite, favorites }) {
  return (
    <div className="flex-1">
      <div className="bg-white rounded-lg shadow-sm">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h2 className="text-xl font-semibold text-hazel-green">{category}</h2>
              <p className="text-sm text-bluish-grey">{reports.length} reports</p>
            </div>
            <motion.button
              className="px-4 py-2 bg-sage text-white rounded-lg hover:bg-hazel-green"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Create Custom Report
            </motion.button>
          </div>

          <table className="w-full">
            <thead>
              <tr className="text-left text-sm text-bluish-grey">
                <th className="pb-4">REPORT NAME</th>
                <th className="pb-4">TYPE</th>
                <th className="pb-4">CREATED BY</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-grey-goose">
              {reports.map((report, index) => (
                <motion.tr
                  key={report.name}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="hover:bg-seashell"
                >
                  <td className="py-4">
                    <div className="flex items-center space-x-2">
                      <button onClick={() => toggleFavorite(report.name)}>
                        {favorites.includes(report.name) ? (
                          <SolidStarIcon className="h-5 w-5 text-yellow-500" />
                        ) : (
                          <OutlineStarIcon className="h-5 w-5 text-heather" />
                        )}
                      </button>
                      <span className="text-sage hover:text-hazel-green cursor-pointer">
                        {report.name}
                      </span>
                    </div>
                  </td>
                  <td className="py-4 text-bluish-grey">{report.type}</td>
                  <td className="py-4 text-bluish-grey">{report.createdBy}</td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

import React from 'react';

interface CreateTaskProps {
  roomNumber: string;
}

export default function CreateTask({ roomNumber }: CreateTaskProps) {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">
          Task Title <span className="text-watermelon">*</span>
        </label>
        <input
          type="text"
          value={`Apartment - Room - ${roomNumber} // `}
          className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">
            Source <span className="text-watermelon">*</span>
          </label>
          <select className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green">
            <option value="">Select Source</option>
          </select>
        </div>
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">
            Priority <span className="text-watermelon">*</span>
          </label>
          <select className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green">
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">
            Due Date <span className="text-watermelon">*</span>
          </label>
          <input
            type="date"
            className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
          />
        </div>
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">Add Images</label>
          <input
            type="file"
            multiple
            className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">Source Name</label>
          <input
            type="text"
            placeholder="Enter Source Name"
            className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
          />
        </div>
        <div>
          <label className="block text-xs font-medium text-bluish-grey mb-1">Source Phone</label>
          <input
            type="tel"
            placeholder="Enter Source Phone"
            className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
          />
        </div>
      </div>

      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">Source Email</label>
        <input
          type="email"
          placeholder="Enter Source Email"
          className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
        />
      </div>

      <div>
        <label className="block text-xs font-medium text-bluish-grey mb-1">
          Description <span className="text-watermelon">*</span>
        </label>
        <textarea
          rows={3}
          className="w-full px-2 py-1.5 text-sm border border-grey-goose rounded-md focus:ring-1 focus:ring-hazel-green focus:border-hazel-green"
          placeholder="Describe Task Details"
        ></textarea>
      </div>

      <div className="flex justify-end space-x-3 pt-2">
        <button className="px-4 py-1.5 text-sm bg-hazel-green text-white rounded-md hover:bg-sage transition-colors">
          Create Task
        </button>
      </div>
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import { Wine, ShoppingBag, Plus, X, DollarSign } from 'lucide-react';
import './ExtraCharges.css';

interface ExtraChargesProps {
  roomNumber: string;
  cleaningReport?: {
    minibarChecklist: Array<{
      id: string;
      name: string;
      checked: boolean;
      quantity: number;
      status: string;
    }>;
    inventoryChecklist: Array<{
      id: string;
      name: string;
      checked: boolean;
      quantity: number;
      status: string;
    }>;
  };
  onChargesUpdate: (total: number) => void;
}

interface CustomCharge {
  description: string;
  amount: string;
}

export default function ExtraCharges({ roomNumber, cleaningReport, onChargesUpdate }: ExtraChargesProps) {
  const [customCharges, setCustomCharges] = useState<CustomCharge[]>([
    { description: '', amount: '' },
  ]);

  console.log("CLEANING REPORT FOR THE CHARGES",cleaningReport);

  useEffect(() => {
    const minibarTotal = cleaningReport?.minibarChecklist
      ?.filter(item => item.status === 'Missing')
      .reduce((sum, item) => sum + (item.quantity || 0) * 5, 0) || 0;

    const inventoryTotal = cleaningReport?.inventoryChecklist
      ?.filter(item => item.status === 'Missing')
      .reduce((sum, item) => sum + (item.quantity || 0) * 10, 0) || 0;

    const customTotal = customCharges
      .reduce((sum, charge) => sum + (Number(charge.amount) || 0), 0);

    onChargesUpdate(minibarTotal + inventoryTotal + customTotal);
  }, [cleaningReport, customCharges, onChargesUpdate]);

  const addCustomCharge = () => {
    setCustomCharges([...customCharges, { description: '', amount: '' }]);
  };

  const removeCustomCharge = (index: number) => {
    setCustomCharges(customCharges.filter((_, i) => i !== index));
  };

  const services = [
    {
      title: 'Minibar',
      icon: Wine,
      items: cleaningReport?.minibarChecklist || [],
      type: 'minibar'
    },
    {
      title: 'Inventory',
      icon: ShoppingBag,
      items: cleaningReport?.inventoryChecklist || [],
      type: 'inventory'
    }
  ];

  return (
    <div className="p-6 space-y-6 bg-tealish-blue rounded-lg shadow-md">
      <div className="flex items-center justify-between pb-4 border-b border-mercury">
        <div>
          <h2 className="text-lg font-semibold text-hazel-green">
            Extra Charges <span className="text-bluish-grey font-medium">• Room {roomNumber}</span>
          </h2>
        </div>
      </div>

      {services.map((service) => (
        <div key={service.title} className="space-y-4">
          <h3 className="text-sm font-semibold text-hazel-green flex items-center gap-2">
            <service.icon size={16} className="text-sage" />
            {service.title}
          </h3>
          <div className="space-y-2">
            {service.items.map((item) => (
              <div
                key={item.id}
                className="flex items-center justify-between bg-seashell px-3 py-2 rounded-md shadow-sm"
              >
                <label className="text-xs text-bluish-grey flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={item.checked}
                    disabled
                    className="text-hazel-green rounded"
                  />
                  <span>{item.name}</span>
                  {item.quantity > 0 && (
                    <span className="text-xs text-sage">(Qty: {item.quantity})</span>
                  )}
                </label>
                <span className={`text-xs ${item.status === 'Present' ? 'text-hazel-green' : 'text-salmon-pink'}`}>
                  {item.status}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}

      <div className="border-t border-mercury pt-4">
        <div className="flex items-center justify-between mb-3">
          <h3 className="text-sm font-semibold text-hazel-green flex items-center gap-2">
            <Plus size={16} className="text-sage" />
            Custom Charges
          </h3>
          <button
            onClick={addCustomCharge}
            className="text-xs font-medium text-hazel-green hover:text-sage transition-colors"
          >
            Add Charge
          </button>
        </div>
        <div className="max-h-[160px] overflow-y-auto pr-2 space-y-2 custom-scrollbar">
          {customCharges.map((charge, index) => (
            <div
              key={index}
              className="flex items-center gap-2 bg-seashell p-2 rounded-md shadow-sm"
            >
              <input
                type="text"
                placeholder="Description"
                value={charge.description}
                onChange={(e) => {
                  const updatedCharges = [...customCharges];
                  updatedCharges[index].description = e.target.value;
                  setCustomCharges(updatedCharges);
                }}
                className="flex-1 px-3 py-1 text-xs rounded-md border border-grey-goose focus:ring-2 focus:ring-sage focus:border-sage bg-white placeholder-heather"
              />
              <div className="relative">
                <DollarSign size={12} className="absolute left-2 top-1/2 -translate-y-1/2 text-heather" />
                <input
                  type="number"
                  placeholder="0.00"
                  value={charge.amount}
                  onChange={(e) => {
                    const updatedCharges = [...customCharges];
                    updatedCharges[index].amount = e.target.value;
                    setCustomCharges(updatedCharges);
                  }}
                  className="w-24 pl-6 pr-2 py-1 text-xs rounded-md border border-grey-goose focus:ring-2 focus:ring-sage focus:border-sage bg-white placeholder-heather"
                />
              </div>
              <button
                onClick={() => removeCustomCharge(index)}
                className="p-1 text-salmon-pink hover:bg-dawn-pink rounded-full transition-colors"
              >
                <X size={14} />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
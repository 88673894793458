import React, { useState, useCallback, useEffect } from 'react';
import Loading from '../../../../components/loader';
import CustomTable from '../../../../components/table-components/table';
import CustomMenuBar from '../../../../components/CustomMenuBar';
import { useParams, useLocation } from 'react-router-dom';

function AccountingTable() {

  const { category, tableName } = useParams(); 
  const location = useLocation();
  
  const [balances, setBalances] = useState([]);
  const [fetchingInitialData, setFetchingInitialData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const getBalancesList = useCallback(async () => {
    
    // Simulate fetching data
    try {
      // Replace this with your actual data fetching logic
      const dummyData = [
        { account: 'Cash', balance: 12000, lastUpdated: '2024-09-01' },
        { account: 'Accounts Receivable', balance: 8500, lastUpdated: '2024-09-05' },
        { account: 'Inventory', balance: 5300, lastUpdated: '2024-09-08' },
        { account: 'Accounts Payable', balance: -3000, lastUpdated: '2024-09-10' },
        // Add more rows as needed
      ];
      setBalances(dummyData);
      setTotalCount(dummyData.length);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || err.message);
    } finally {
      setFetchingInitialData(false);
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, searchText]);

  useEffect(() => {
    getBalancesList();
  }, [getBalancesList]);

  useEffect(() => {
    setLoading(true);
  }, [currentPage, rowsPerPage, searchText]);

  if (fetchingInitialData) {
    return <Loading />;
  }

  if (!fetchingInitialData && errorMessage) {
    return (
      <div className='text-center'>
        <h1>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div>
            <CustomMenuBar category={category} />
    <div className='p-10'>


      <CustomTable
        rows={balances}
        columns={[
          {
            title: 'Account',
            renderValue: (item) => item.account,
          },
          {
            title: 'Balance',
            renderValue: (item) => (
              <div
                className={`${
                  item.balance >= 0
                    ? 'bg-seashell text-bluish-grey'
                    : 'bg-dawn-pink text-salmon-pink'
                } rounded-md py-3`}
              >
                <p className='text-center'>{item.balance.toFixed(2)}</p>
              </div>
            ),
          },
          {
            title: 'Last Updated',
            renderValue: (item) => item.lastUpdated,
          },
          // Add more columns as needed
        ]}
        setSearchText={setSearchText}
        loading={loading}
        showMoreActions={false} // Adjust based on your needs
        editLink='/accounting' // Link to an editing page or details view
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </div>
  </div>
  );
}

export default AccountingTable;
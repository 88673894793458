// import React , { useState }from "react";
// import { useSelector } from "react-redux";
// import Chat from "./Chat";
// import GroupList from "./GroupList";
// import GroupCreationForm from './GroupCreationForm';
// import UserInvitationForm from './UserInvitationForm';
// import { AddButton } from "../../components/buttons";

// const MainChatPage = () => {
//   const [selectedGroupId, setSelectedGroupId] = useState(null);
//   const [isCreatingGroup, setIsCreatingGroup] = useState(false);
//   const [isInvitingUser, setIsInvitingUser] = useState(false);
//   const user = useSelector((state) => state.user.user);

//   const handleGroupCreated = (group) => {
//     console.log('Group created:', group);
//     setIsCreatingGroup(false);
//     // Additional logic if needed, such as updating the group list
//   };

//   if (!user) {
//     return <div>Loading...</div>; 
//   }

//   return (
//     <div className="flex h-screen">
//       <div className="flex flex-col w-1/4 p-4 border-r border-gray-300 h-full">
//         <GroupList userId={user.id} onSelectGroup={setSelectedGroupId} />
//       </div>

//       <div className="flex flex-col flex-1">
//         <div className="flex justify-end p-4 bg-gray-100 border-b border-gray-300">
//           <AddButton
//             link="/chat/group-creation-form/"
//             title="Create a Group"
//             onClick={() => setIsCreatingGroup(true)}
//           />
//           <AddButton
//             link="/chat/user-invitation-form/"
//             title="Invite User"
//             onClick={() => setIsInvitingUser(true)}
//           />
//         </div>

//         <div className="flex-1 p-4">
//           {isCreatingGroup && (
//             <GroupCreationForm onGroupCreated={handleGroupCreated} />
//           )}
//           {selectedGroupId && !isCreatingGroup && (
//             <>
//               <button
//                 onClick={() => setIsInvitingUser(!isInvitingUser)}
//                 className="mt-4 p-2 bg-yellow-500 text-white rounded"
//               >
//                 {isInvitingUser ? 'Cancel Invitation' : 'Invite User'}
//               </button>
//               {isInvitingUser && (
//                 <UserInvitationForm
//                   groupId={selectedGroupId}
//                   onUserInvited={() => setIsInvitingUser(false)}
//                 />
//               )}
//               <Chat groupId={selectedGroupId} userId={user.id} />
//             </>
//           )}
//         </div>
//       </div>
//     </div>
    
//   );
// };

// export default MainChatPage;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import Chat from "./Chat";
import GroupList from "./GroupList";
import GroupCreationForm from './GroupCreationForm';
import UserInvitationForm from './UserInvitationForm';
import { AddButton } from "../../components/buttons";

const MainChatPage = () => {
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const [isInvitingUser, setIsInvitingUser] = useState(false);
  const [isInitialSelection, setIsInitialSelection] = useState(true);

  const user = useSelector((state) => state.user.user);

  const handleGroupCreated = (group) => {
    console.log('Group created:', group);
    setIsCreatingGroup(false);
  };

  const handleGroupSelection = (groupId) => {
    setSelectedGroupId(groupId);
    setIsInitialSelection(false); // User has manually selected a group
  };

  const handleFirstGroupSelected = (groupId) => {
    if (isInitialSelection && groupId) {
      setSelectedGroupId(groupId);
      setIsInitialSelection(false); // Initial selection done
    }
  };

  if (!user) {
    return <div>Loading...</div>; 
  }


  return (
    <div className="flex h-screen overflow-hidden">
      <div className="flex flex-col w-1/4 p-4 border-r border-gray-300 bg-gray-50">
        <GroupList 
          userId={user.id} 
          onSelectGroup={setSelectedGroupId} 
          onFirstGroupSelected={handleFirstGroupSelected}
        />
      </div>

      <div className="flex flex-col flex-1">
        <div className="flex justify-end p-4 bg-gray-100 border-b border-gray-300 space-x-3">
          <AddButton
            link="/chat/group-creation-form"
            title="Create a Group"
            onClick={() => setIsCreatingGroup(true)}
            className="bg-blue-500 hover:bg-blue-600 text-white"
          />
          <AddButton
            link="/chat/user-invitation-form"
            title="Invite User"
            onClick={() => setIsInvitingUser(true)}
            className="bg-green-500 hover:bg-green-600 text-white"
          />
        </div>

        <div className="flex-1 flex flex-col overflow-hidden">
          {isCreatingGroup && (
            <GroupCreationForm onGroupCreated={handleGroupCreated} />
          )}
          {selectedGroupId && !isCreatingGroup && (
            <>
              <button
                onClick={() => setIsInvitingUser(!isInvitingUser)}
                className="mt-4 mb-2 p-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600"
              >
                {isInvitingUser ? 'Cancel Invitation' : 'Invite User'}
              </button>
              {isInvitingUser && (
                <UserInvitationForm
                  groupId={selectedGroupId}
                  onUserInvited={() => setIsInvitingUser(false)}
                />
              )}
              <div className="flex-1 overflow-y-auto bg-white shadow-lg">
                <Chat groupId={selectedGroupId} userId={user.id} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
  // return (
  //   <div className="flex h-screen overflow-hidden">
  //   <div className="flex flex-col w-1/4 p-4 border-r border-gray-300 h-full">
  //     <GroupList 
  //         userId={user.id} 
  //         onSelectGroup={setSelectedGroupId} 
  //         onFirstGroupSelected={handleFirstGroupSelected}
  //     />
  //   </div>

  //   <div className="flex flex-col flex-1">
  //     <div className="flex justify-end p-4 bg-gray-100 border-b border-gray-300 space-x-3">
  //       <AddButton
  //         link="/chat/group-creation-form"
  //         title="Create a Group"
  //         onClick={() => setIsCreatingGroup(true)}
  //       />
  //       <AddButton
  //         link="/chat/user-invitation-form"
  //         title="Invite User"
  //         onClick={() => setIsInvitingUser(true)}
  //       />
  //     </div>


  //     <div className="flex-1 flex flex-col overflow-hidden">
  //       {isCreatingGroup && (
  //         <GroupCreationForm onGroupCreated={handleGroupCreated} />
  //       )}
  //       {selectedGroupId && !isCreatingGroup && (
  //         <>
  //           <button
  //             onClick={() => setIsInvitingUser(!isInvitingUser)}
  //             className="mt-4 p-2 bg-yellow-500 text-white rounded"
  //           >
  //             {isInvitingUser ? 'Cancel Invitation' : 'Invite User'}
  //           </button>
  //           {isInvitingUser && (
  //             <UserInvitationForm
  //               groupId={selectedGroupId}
  //               onUserInvited={() => setIsInvitingUser(false)}
  //             />
  //           )}
  //           <div className="flex-1 overflow-y-auto">
  //             <Chat groupId={selectedGroupId} userId={user.id} />
  //           </div>
  //         </>
  //       )}
  //     </div>
  //   </div>
  // </div>
 
  // );
};

export default MainChatPage;


import React from 'react';
import { Info } from 'lucide-react';

interface FormFieldProps {
  label: string;
  required?: boolean;
  info?: boolean;
  children: React.ReactNode;
}

export function FormField({ label, required, info, children }: FormFieldProps) {
  return (
    <div className="flex items-center space-x-4">
      <label className={`text-sm font-medium w-32 ${required ? 'text-watermelon' : 'text-bluish-grey'}`}>
        {label}
        {info && <Info size={14} className="inline ml-1 text-silver" />}
      </label>
      <div className="flex-1">{children}</div>
    </div>
  );
}
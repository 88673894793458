// import React, { useState } from 'react';
// import { X, Plus, HelpCircle } from 'lucide-react';
// import { ItemRowReceipt as ItemRow } from '../../gobal';
// import { motion, AnimatePresence } from 'framer-motion';

// interface ItemTableProps {
//   items: ItemRow[];
//   onItemChange: (items: ItemRow[]) => void;
// }

// export function ItemTable({ items, onItemChange }: ItemTableProps) {
//   const [showTaxOnShipping, setShowTaxOnShipping] = useState(false);
//   const [shippingCharges, setShippingCharges] = useState(0);
//   const [adjustment, setAdjustment] = useState(0);

//   const calculateSubTotal = () => items.reduce((sum, item) => sum + item.amount, 0);
//   const calculateTotal = () => calculateSubTotal() + shippingCharges + adjustment;

//   const addNewRow = () => {
//     onItemChange([...items, {
//       id: String(items.length + 1),
//       details: '',
//       quantity: 1.00,
//       rate: 0.00,
//       discount: 0,
//       tax: '',
//       amount: 0.00
//     }]);
//   };

//   const removeRow = (id: string) => {
//     onItemChange(items.filter(item => item.id !== id));
//   };

//   return (
//     <motion.div 
//       className="bg-seashell rounded-lg p-4"
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       transition={{ delay: 0.2 }}
//     >
//       <div className="flex justify-between items-center mb-4">
//         <h2 className="text-sm font-medium text-bluish-grey">Item Table</h2>
//         <button className="text-hazel-green text-sm flex items-center hover:text-sage transition-colors">
//           <HelpCircle size={14} className="mr-1" />
//           Bulk Actions
//         </button>
//       </div>

//       <div className="overflow-x-auto rounded-lg bg-white">
//         <table className="w-full">
//           <thead className="bg-seashell border-b border-grey-goose">
//             <tr>
//               <th className="p-3 text-left text-xs font-medium text-bluish-grey">ITEM DETAILS</th>
//               <th className="p-3 text-left text-xs font-medium text-bluish-grey w-24">QUANTITY</th>
//               <th className="p-3 text-left text-xs font-medium text-bluish-grey w-24">RATE</th>
//               <th className="p-3 text-left text-xs font-medium text-bluish-grey w-24">DISCOUNT</th>
//               <th className="p-3 text-left text-xs font-medium text-bluish-grey w-32">TAX</th>
//               <th className="p-3 text-right text-xs font-medium text-bluish-grey w-24">AMOUNT</th>
//               <th className="p-3 w-8"></th>
//             </tr>
//           </thead>
//           <tbody>
//             <AnimatePresence>
//               {items.map((item) => (
//                 <motion.tr 
//                   key={item.id} 
//                   className="border-b border-grey-goose"
//                   initial={{ opacity: 0, y: -10 }}
//                   animate={{ opacity: 1, y: 0 }}
//                   exit={{ opacity: 0, y: -10 }}
//                 >
//                   <td className="p-2">
//                     <input 
//                       type="text"
//                       placeholder="Type or click to select an item"
//                       className="w-full p-2 text-sm border border-grey-goose rounded focus:outline-none focus:ring-1 focus:ring-sage focus:border-sage"
//                     />
//                   </td>
//                   <td className="p-2">
//                     <input 
//                       type="number"
//                       value={item.quantity}
//                       min="0"
//                       step="0.01"
//                       className="w-full p-2 text-sm border border-grey-goose rounded focus:outline-none focus:ring-1 focus:ring-sage focus:border-sage"
//                       onChange={(e) => {
//                         const newItems = [...items];
//                         const index = newItems.findIndex(i => i.id === item.id);
//                         newItems[index] = { ...item, quantity: Number(e.target.value) };
//                         onItemChange(newItems);
//                       }}
//                     />
//                   </td>
//                   <td className="p-2">
//                     <input 
//                       type="number"
//                       value={item.rate}
//                       min="0"
//                       step="0.01"
//                       className="w-full p-2 text-sm border border-grey-goose rounded focus:outline-none focus:ring-1 focus:ring-sage focus:border-sage"
//                       onChange={(e) => {
//                         const newItems = [...items];
//                         const index = newItems.findIndex(i => i.id === item.id);
//                         newItems[index] = { ...item, rate: Number(e.target.value) };
//                         onItemChange(newItems);
//                       }}
//                     />
//                   </td>
//                   <td className="p-2">
//                     <div className="flex items-center">
//                       <input 
//                         type="number"
//                         value={item.discount}
//                         min="0"
//                         max="100"
//                         className="w-16 p-2 text-sm border border-grey-goose rounded focus:outline-none focus:ring-1 focus:ring-sage focus:border-sage"
//                         onChange={(e) => {
//                           const newItems = [...items];
//                           const index = newItems.findIndex(i => i.id === item.id);
//                           newItems[index] = { ...item, discount: Number(e.target.value) };
//                           onItemChange(newItems);
//                         }}
//                       />
//                       <span className="ml-1 text-xs text-silver">%</span>
//                     </div>
//                   </td>
//                   <td className="p-2">
//                     <select className="w-full p-2 text-sm border border-grey-goose rounded focus:outline-none focus:ring-1 focus:ring-sage focus:border-sage">
//                       <option value="">Select a Tax</option>
//                       <option value="vat">VAT (20%)</option>
//                       <option value="gst">GST (18%)</option>
//                     </select>
//                   </td>
//                   <td className="p-2 text-right text-sm font-medium">{item.amount.toFixed(2)}</td>
//                   <td className="p-2">
//                     <button 
//                       onClick={() => removeRow(item.id)}
//                       className="text-silver hover:text-watermelon transition-colors"
//                     >
//                       <X size={14} />
//                     </button>
//                   </td>
//                 </motion.tr>
//               ))}
//             </AnimatePresence>
//           </tbody>
//         </table>
//       </div>

//       <div className="mt-4 space-x-4">
//         <button 
//           onClick={addNewRow}
//           className="text-hazel-green text-sm flex items-center hover:text-sage transition-colors"
//         >
//           <Plus size={14} className="mr-1" />
//           Add New Row
//         </button>
//         <button className="text-hazel-green text-sm flex items-center hover:text-sage transition-colors">
//           <Plus size={14} className="mr-1" />
//           Add Items in Bulk
//         </button>
//       </div>

//       <div className="mt-6 flex justify-end">
//         <div className="w-80 space-y-4">
//           <div className="flex justify-between text-sm">
//             <span className="text-bluish-grey">Sub Total</span>
//             <span className="font-medium">€ {calculateSubTotal().toFixed(2)}</span>
//           </div>

//           <div className="space-y-1">
//             <div className="flex justify-between items-center">
//               <span className="text-sm text-bluish-grey">Shipping Charges</span>
//               <input 
//                 type="number"
//                 value={shippingCharges}
//                 min="0"
//                 step="0.01"
//                 onChange={(e) => setShippingCharges(Number(e.target.value))}
//                 className="w-32 p-2 text-sm border border-grey-goose rounded text-right focus:outline-none focus:ring-1 focus:ring-sage focus:border-sage"
//               />
//             </div>
//             <button 
//               onClick={() => setShowTaxOnShipping(!showTaxOnShipping)}
//               className="text-xs text-sage hover:text-hazel-green transition-colors"
//             >
//               Apply Tax on Shipping Charge
//             </button>
//           </div>

//           <div className="flex justify-between items-center">
//             <span className="text-sm text-bluish-grey">Adjustment</span>
//             <input 
//               type="number"
//               value={adjustment}
//               step="0.01"
//               onChange={(e) => setAdjustment(Number(e.target.value))}
//               className="w-32 p-2 text-sm border border-grey-goose rounded text-right focus:outline-none focus:ring-1 focus:ring-sage focus:border-sage"
//             />
//           </div>

//           <div className="flex justify-between pt-4 border-t border-grey-goose">
//             <span className="font-medium text-bluish-grey">Total ( € )</span>
//             <span className="font-medium text-lg">€ {calculateTotal().toFixed(2)}</span>
//           </div>
//         </div>
//       </div>
//     </motion.div>
//   );
// }
import React from 'react';
import { Trash2 } from 'lucide-react';
import ItemSelectionModal from '../quotes/ItemSelectionModal.tsx';

interface ItemRow {
  id: string;
  details: string;
  quantity: number;
  rate: number;
  discount: number;
  tax: string;
  amount: number;
  itemId?: number;
}

interface ItemTableProps {
  items: ItemRow[];
  onItemChange: (items: ItemRow[]) => void;
  availableItems: any[]; // You can replace `any` with a more specific type if available
  isModalOpen: boolean;
  onModalOpen: (open: boolean) => void;
  selectedRowIndex: number;
  onRowSelect: (index: number) => void;
  onItemSelect: (item: any) => void; // You can replace `any` with a more specific type if available
}

export function ItemTable({
  items,
  onItemChange,
  availableItems,
  isModalOpen,
  onModalOpen,
  selectedRowIndex,
  onRowSelect,
  onItemSelect,
}: ItemTableProps) {
  const handleItemSelect = (selectedItem: any) => {
    const newItems = [...items];
    newItems[selectedRowIndex] = {
      ...newItems[selectedRowIndex],
      details: selectedItem.name,
      rate: selectedItem.sellingPrice,
      amount: selectedItem.sellingPrice * newItems[selectedRowIndex].quantity,
      itemId: selectedItem.id,
    };
    onItemChange(newItems);
    onModalOpen(false);
  };

  const handleUpdateItem = (index: number, field: keyof ItemRow, value: any) => {
    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      [field]: value,
    };

    if (field === 'quantity' || field === 'rate' || field === 'discount') {
      const quantity = field === 'quantity' ? value : newItems[index].quantity;
      const rate = field === 'rate' ? value : newItems[index].rate;
      const discount = field === 'discount' ? value : newItems[index].discount;
      newItems[index].amount = quantity * rate * (1 - discount / 100);
    }

    onItemChange(newItems);
  };

  const handleDeleteItem = (index: number) => {
    onItemChange(items.filter((_, i) => i !== index));
  };

  const handleAddRow = () => {
    onItemChange([
      ...items,
      {
        id: `${Date.now()}`,
        details: '',
        quantity: 1.0,
        rate: 0.0,
        discount: 0,
        tax: '',
        amount: 0.0,
      },
    ]);
  };

  return (
    <div className="space-y-4">
      <div className="border border-grey-goose rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full min-w-[800px]">
            <thead className="bg-gray-50">
              <tr>
                <th className="text-left p-4 text-sm font-medium text-bluish-grey">ITEM DETAILS</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-24">QUANTITY</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-32">RATE</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-24">DISCOUNT</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-32">TAX</th>
                <th className="text-right p-4 text-sm font-medium text-bluish-grey w-32">AMOUNT</th>
                <th className="w-16"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-grey-goose">
              {items.map((item, index) => (
                <tr key={item.id}>
                  <td className="p-4">
                    <input
                      type="text"
                      value={item.details}
                      onClick={() => {
                        onRowSelect(index);
                        onModalOpen(true);
                      }}
                      readOnly
                      placeholder="Click to select an item"
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage cursor-pointer"
                    />
                  </td>
                  <td className="p-4">
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleUpdateItem(index, 'quantity', Number(e.target.value))}
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage text-right"
                    />
                  </td>
                  <td className="p-4">
                    <input
                      type="number"
                      value={item.rate}
                      onChange={(e) => handleUpdateItem(index, 'rate', Number(e.target.value))}
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage text-right"
                    />
                  </td>
                  <td className="p-4">
                    <input
                      type="number"
                      value={item.discount}
                      onChange={(e) => handleUpdateItem(index, 'discount', Number(e.target.value))}
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage text-right"
                    />
                  </td>
                  <td className="p-4">
                    <select
                      value={item.tax}
                      onChange={(e) => handleUpdateItem(index, 'tax', e.target.value)}
                      className="w-full p-2 text-sm border border-grey-goose rounded-md focus:outline-none focus:border-sage"
                    >
                      <option value="">No Tax</option>
                      <option value="VAT@20">VAT@20%</option>
                    </select>
                  </td>
                  <td className="p-4 text-right text-sm text-bluish-grey font-medium">
                    €{item.amount.toFixed(2)}
                  </td>
                  <td className="p-4">
                    <button
                      onClick={() => handleDeleteItem(index)}
                      className="text-red-500 hover:text-red-600 transition-colors"
                    >
                      <Trash2 size={16} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex gap-2">
        <button
          type="button"
          onClick={handleAddRow}
          className="text-blue-500 hover:text-blue-600 text-sm px-4 py-2 bg-blue-50 rounded-md transition-colors"
        >
          + Add New Row
        </button>
      </div>

      {isModalOpen && (
        <ItemSelectionModal
          isOpen={isModalOpen}
          onClose={() => onModalOpen(false)}
          items={availableItems}
          onSelectItem={handleItemSelect}
        />
      )}
    </div>
  );
}

export default ItemTable;


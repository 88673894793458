import React from 'react';
import { useState, useEffect } from 'react';

import { getRecentPoolReservations } from '../../api/poolsInterface';


const RecentActivity = () => {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        setLoading(true);
        const response = await getRecentPoolReservations();
        const data = response.data ? response.data.slice(0, 10) : [];
        console.log('Component - Fetched Reservations:', data);
        setReservations(data);
      } catch (error) {
        setError('Failed to fetch reservations.');
        console.error('Component - Failed to fetch reservations:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReservations();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    // <div className="bg-white p-4 rounded-lg shadow-md">
    //   <h2 className="text-lg font-semibold mb-4 text-gray-800 border-b border-gray-200 pb-2">
    //     Recent Reservations
    //   </h2>
    //   <ul className="space-y-2">
    //     {reservations.length > 0 ? (
    //       reservations.map((reservation) => (
    //         <li key={reservation.id} className="bg-gray-50 p-3 rounded-lg shadow-sm flex items-center justify-between">
    //           <div>
    //             <p className="text-sm font-bold text-gray-800">{reservation.pool?.type || 'No pool information'}</p>
    //             <p className="text-sm text-gray-600">{reservation.ticketType}</p>
    //             <p className="text-xs text-gray-500">{new Date(reservation.createdAt).toLocaleDateString()}</p>
    //           </div>
    //           <span className="text-sm text-gray-900 font-semibold">${reservation.price.toFixed(2)}</span>
    //         </li>
    //       ))
    //     ) : (
    //       <li className="text-gray-500">No recent reservations</li>
    //     )}
    //   </ul>
    // </div>

    <div className="bg-white p-3 rounded-lg shadow-sm">
  <h2 className="text-base font-medium mb-3 text-gray-800 border-b border-gray-200 pb-1">
    Recent Reservations
  </h2>
  <ul className="space-y-1">
    {reservations.length > 0 ? (
      reservations.map((reservation) => (
        <li key={reservation.id} className="bg-gray-50 p-2 rounded-lg shadow-xs flex items-center justify-between">
          <div>
            <p className="text-xs font-medium text-gray-800">{reservation.pool?.type || 'No pool information'}</p>
            <p className="text-xs text-gray-600">{reservation.ticketType}</p>
            <p className="text-xs text-gray-500">{new Date(reservation.createdAt).toLocaleDateString()}</p>
          </div>
          <span className="text-xs text-gray-900 font-medium">${reservation.price.toFixed(2)}</span>
        </li>
      ))
    ) : (
      <li className="text-gray-500 text-xs">No recent reservations</li>
    )}
  </ul>
</div>

  );
};

// const RecentActivity = () => {

//   const [reservations, setReservations] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);


//   useEffect(() => {
//     const fetchReservations = async () => {
//       try {
//         setLoading(true);
//         const response = await getRecentPoolReservations();
//         console.log(response.data)
//         const data = response.data ? response.data.slice(0, 10) : [];
//         console.log(data);
//         setReservations(data);
//       } catch (error) {
//         setError('Failed to fetch reservations.');
//         console.error('Failed to fetch reservations:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchReservations();
//   }, []);

//   // return (
//   //   <div className="bg-white p-4 rounded shadow-md">
//   //     <h2 className="font-bold text-lg mb-2">Recent Activity</h2>

//   //     <div className="text-gray-700">Recent activity...</div>
//   //   </div>
//   // );


//   if (loading) {
//     return <p>Loading...</p>;
//   }

//   if (error) {
//     return <p>{error}</p>;
//   }

//   return (
//     <div className="bg-white p-4 rounded-lg shadow-md">
//     <h2 className="text-lg font-semibold mb-4 text-gray-800 border-b border-gray-200 pb-2">
//       Recent Reservations
//     </h2>
//     <ul className="space-y-2">
//       {reservations.length > 0 ? (
//         reservations.map((reservation) => (
//           <li key={reservation.id} className="bg-gray-50 p-3 rounded-lg shadow-sm flex items-center justify-between">
//             <div>
//               <p className="text-sm font-bold text-gray-800">{reservation.pool.type}</p>
//               <p className="text-sm text-gray-600">{reservation.ticketType}</p>
//               <p className="text-xs text-gray-500">{new Date(reservation.createdAt).toLocaleDateString()}</p>
//             </div>
//             <span className="text-sm text-gray-900 font-semibold">${reservation.price.toFixed(2)}</span>
//           </li>
//         ))
//       ) : (
//         <li className="text-gray-500">No recent reservations</li>
//       )}
//     </ul>
//   </div>
//   );
// };

export default RecentActivity;

export const reportCategories = {
    'Business Overview': [
      { name: 'Profit and Loss', type: 'Profit and Loss', createdBy: 'System Generated' },
      { name: 'Cash Flow Statement', type: 'Cash Flow Statement', createdBy: 'System Generated' },
      { name: 'Balance Sheet', type: 'Balance Sheet', createdBy: 'System Generated' },
      { name: 'Business Performance Ratios', type: 'Business Performance Ratios', createdBy: 'System Generated' },
      { name: 'Movement of Equity', type: 'Movement of Equity', createdBy: 'System Generated' }
    ],
    'Sales': [
      { name: 'Sales by Customer', type: 'Sales by Customer', createdBy: 'System Generated' },
      { name: 'Sales by Item', type: 'Sales by Item', createdBy: 'System Generated' },
      { name: 'Sales Analysis', type: 'Sales Analysis', createdBy: 'System Generated' }
    ],
    'Receivables': [
      { name: 'Customer Balances', type: 'Customer Balances', createdBy: 'System Generated' },
      { name: 'AR Aging Summary', type: 'AR Aging Summary', createdBy: 'System Generated' },
      { name: 'AR Aging Details', type: 'AR Aging Details', createdBy: 'System Generated' },
      { name: 'Invoice Details', type: 'Invoice Details', createdBy: 'System Generated' },
      { name: 'Quote Details', type: 'Quote Details', createdBy: 'System Generated' },
      { name: 'Customer Balance Summary', type: 'Customer Balance Summary', createdBy: 'System Generated' },
      { name: 'Receivable Summary', type: 'Receivable Summary', createdBy: 'System Generated' },
      { name: 'Receivable Details', type: 'Receivable Details', createdBy: 'System Generated' }
    ],
    'Payments Received': [
      { name: 'Payments Received', type: 'Payments Received', createdBy: 'System Generated' },
      { name: 'Time to Get Paid', type: 'Time to Get Paid', createdBy: 'System Generated' },
      { name: 'Credit Note Details', type: 'Credit Note Details', createdBy: 'System Generated' },
      { name: 'Refund History', type: 'Refund History', createdBy: 'System Generated' }
    ],
    'Recurring Invoices': [
      { name: 'Recurring Invoice Details', type: 'Recurring Invoice Details', createdBy: 'System Generated' }
    ],
    'Payables': [
      { name: 'Vendor Balances', type: 'Vendor Balances', createdBy: 'System Generated' },
      { name: 'Vendor Balance Summary', type: 'Vendor Balance Summary', createdBy: 'System Generated' },
      { name: 'AP Aging Summary', type: 'AP Aging Summary', createdBy: 'System Generated' },
      { name: 'AP Aging Details', type: 'AP Aging Details', createdBy: 'System Generated' },
      { name: 'Bills Details', type: 'Bills Details', createdBy: 'System Generated' },
      { name: 'Vendor Credits Details', type: 'Vendor Credits Details', createdBy: 'System Generated' },
      { name: 'Payments Made', type: 'Payments Made', createdBy: 'System Generated' },
      { name: 'Refund History', type: 'Refund History', createdBy: 'System Generated' },
      { name: 'Payable Summary', type: 'Payable Summary', createdBy: 'System Generated' },
      { name: 'Payable Details', type: 'Payable Details', createdBy: 'System Generated' }
    ]
  };
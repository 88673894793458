import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../components/page-title';
import MenuForm from './MenuForm';

function MenuFormContainer() {
  return (
    <div className='p-10'>
      <PageTitle
        icon={
        //   <FontAwesomeIcon
        //     icon={faBuilding}
        //     className='text-hazel-green mr-2'
        //   />

        <FontAwesomeIcon icon="fa-solid fa-utensils" className='text-hazel-green mr-2'/>

        }
        title='Create the menu'
      />
      <MenuForm
        isEdit={false}
        // environment={{
        //   name: '',
        //   restaurantId: ''
        // }}
      />
    </div>
  );
}

export default MenuFormContainer;
